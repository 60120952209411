import React from 'react'
import { ActionButton } from '@ec/ui-controls-react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { TextInput, DatePicker, HiddenField, TextArea } from 'application/components/controls/form'
import useStatefulForm from 'application/components/hooks/use_stateful_form'
import { DAMAGE_TYPE } from 'application/constants'
import { FormContainer } from './fragments'
import { useQuery } from 'application/components/hooks/use_query'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ROUTE_PARAMETERS } from './route_parameters'

export const ExtCreateDamageForm = ({onCreateDamage}) => {
    const {t} = useTranslation()
    const query = useQuery()

    const defaultValues = {
        type: DAMAGE_TYPE.UNKNOWN, //hidden field
        licensePlate: query.getStringParam(ROUTE_PARAMETERS.LICENSE_PLATE_PARAM),
        incidentDate: new Date(),
        description: ''
    }

    const setForm = () => {
        updateFormState()
    }

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        control, 
        updateFormState
    } = useStatefulForm({ defaultValues })

    const onSubmitSuccess = () => onCreateDamage(getValues())

    return <FormContainer>
        <HiddenField
            name='type'
            register={register} 
        />

        <TextInput 
            label={t('License plate')}
            name='licensePlate'
            register={register}
            validate={{notEmpty: validationMethods.notEmpty}}
            error={errors.licensePlate}
            onChange={updateFormState}
        />

        <DatePicker 
            label={t('Damage date')}
            name='incidentDate'
            validate={{notEmpty: validationMethods.notEmpty}}
            error={errors.incidentDate}
            onDateChange={setForm}
            control={control}
            disabledDays={{after: new Date()}}
        />

        <TextArea
            label={t('Damage description')}
            name='description'
            register={register}
            validate={null}
            error={errors.description}
            onChange={setForm}
            placeholder='Bitte geben Sie hier eine kurze Beschreibung des Schadens an ihrem Fahrzeug ein.'
        />
    
        <FlexBox justifyContent='flex-end' cssRules='margin-bottom: 60px;'>
            <ActionButton 
                buttonText='Schadenmeldung senden'
                onButtonClick={handleSubmit(onSubmitSuccess)}
            />
        </FlexBox>
    </FormContainer>
}