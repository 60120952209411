import styled, {keyframes, css} from 'styled-components'

export const StyledIndicator = styled.div`
    position: relative;
    cursor: pointer;
    padding: 2px 12px 0;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        background-color: ${props => props.theme.color.gray15};
    }
`

const ring = keyframes`
    0% { transform: rotateZ(0); }
    1% { transform: rotateZ(30deg); }
    3% { transform: rotateZ(-28deg); }
    5% { transform: rotateZ(34deg); }
    7% { transform: rotateZ(-32deg); }
    9% { transform: rotateZ(30deg); }
    11% { transform: rotateZ(-28deg); }
    13% { transform: rotateZ(26deg); }
    15% { transform: rotateZ(-24deg); }
    17% { transform: rotateZ(22deg); }
    19% { transform: rotateZ(-20deg); }
    21% { transform: rotateZ(18deg); }
    23% { transform: rotateZ(-16deg); }
    25% { transform: rotateZ(14deg); }
    27% { transform: rotateZ(-12deg); }
    29% { transform: rotateZ(10deg); }
    31% { transform: rotateZ(-8deg); }
    33% { transform: rotateZ(6deg); }
    35% { transform: rotateZ(-4deg); }
    37% { transform: rotateZ(2deg); }
    39% { transform: rotateZ(-1deg); }
    41% { transform: rotateZ(1deg); }
    43% { transform: rotateZ(0); }
    100% { transform: rotateZ(0); }
`
const animation = () => css`
    ${ring} 4s .7s ease-in-out;
`

export const StyledIconContainer = styled.div`
    position: relative; 
    z-index: 1;
    animation: ${props => props.hasNewNotifications ? animation : 'none'};
`

export const StyledCounter = styled.div`
    position: absolute;
    z-index: 2;
    top: 6px;
    right: 6px;
    min-width: 16px;
    height: 16px;
    border-radius: 11px;
    background-color: ${props => props.theme.color.error};
    color: ${props => props.theme.color.white};
    font-size:  ${props => props.theme.fontSize.xSmall};
    font-weight:  ${props => props.theme.fontWeight.standardBold};
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
`