import { connect } from 'react-redux'
import CreateDamageDekraComponent from './component'
import { getUsersForDialogRequestDebounced } from 'application/redux/actions/common'
import { closeDialogByName, openDialogByName, sendPayloadRequest } from 'application/redux/actions/dialogs'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => ({
    currentBranch: state.auth.currentBranch
})

const mapDispatchToProps = dispatch => {
    return {
        onCreateDamage: (name, payload) => dispatch(
            sendPayloadRequest(name, payload)
        ),
        onSearchUser: (dialogName, search) => dispatch(
            getUsersForDialogRequestDebounced(dialogName, 0, 10, new ListFilter(filters.users.name).setValue(search).getQuery())
        ),
        onOpenChangeBranchDialog : (name) => {
            dispatch(closeDialogByName(name))
            dispatch(
                openDialogByName(
                    DIALOG.NAME.BRANCH_MANAGEMENT_CHANGE_BRANCH,
                    true, 
                    {}, 
                    'Change branch'
                )
            )
        },
    }
}

export const CreateDamageDekra = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateDamageDekraComponent)