import React from 'react'
import {
    Switch,
    Route,
    withRouter,
    Redirect
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LayoutCurrentBranch } from 'application/components/pages/_layout'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { ROUTES } from 'application/constants'
import AllDamages from './all_damages'
import MyDamages from './my_damages'
import { getBranchLinkHref } from 'application/common/route_helpers'


const DamagesOverview = (
    { 
        match ,
        currentBranch
    }
) => {
    const {t} = useTranslation()

    const currentBranchId = currentBranch !== null ? currentBranch.id : null

    if(currentBranchId === null){
        return null
    }

    if (match.path === ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW) {
        return <Redirect to={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES, currentBranchId)} />
    }


    return <LayoutCurrentBranch
        titleSegments={['Damages']}
        headline = {t('Damages')}
    >

        <TabsLinks
            items={[
                {
                    label: t('My damages'),
                    link: getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES, currentBranchId),
                    isActive: match.path === ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES
                },
                {
                    label: t('All damages'),
                    link: getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES, currentBranchId),
                    isActive: match.path === ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES
                }
            ]}
        />

        <Switch>
            <Route path={ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES}>
                <MyDamages />
            </Route>
            <Route path={ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES}>
                <AllDamages />
            </Route>
        </Switch>          
               
    </LayoutCurrentBranch>
}

export default withRouter(DamagesOverview)