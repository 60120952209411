import {ACTIONS} from 'application/constants'

export const requestDamage = (damageId) => ({
    type: ACTIONS.DAMAGE_MANAGER_REQUEST_DAMAGE, 
    damageId
})

export const resetDamage = () => ({
    type: ACTIONS.DAMAGE_MANAGER_SHARED_RESET_DAMAGE
})

export const populateDamage = (damage) => ({
    type: ACTIONS.DAMAGE_MANAGER_POPULATE_DAMAGE, 
    damage
})

export const populateSharedLawfirm = (hasLawfirm) => ({
    type: ACTIONS.DAMAGE_MANAGER_POPULATE_SHARED_LAWFIRM, 
    hasLawfirm
})

export const updateDamage = (damageId, damageData) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_DAMAGE,
    damageId,
    damageData
})