import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'


const StyledIconLinkWrapper = styled.div`
    width: 48px;
    height: 48px;
`

const StyledIconLink = styled(NavLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;
    text-decoration: none;
    color: ${props => props.activated === 'true' ? props.primarycolor : props.theme.color.white };
    background: ${props => props.activated === 'true' ? props.theme.color.white : props.theme.color.transparent};

    :hover {
        background: ${props => props.activated === 'true' ? props.theme.color.white : props.hoverbackground};
    }

    :active {
        color: ${props => props.activated === 'true' ? props.primarycolor : props.theme.color.white };
    }

    & i.material-icons {
        font-size: 18px;
    }
`


const IconLink = (
    { 
        to,
        primaryColor,
        hoverBackground,
        iconKey = '',
        isActive = false, 
        onLinkClick = () => {},
        toolTip=''
    }
) => <StyledIconLinkWrapper>
    <StyledIconLink 
        to={to} 
        onClick={onLinkClick}
        activated={isActive.toString()}
        primarycolor={primaryColor}
        hoverbackground={hoverBackground}
        title={toolTip}
    >
        <i className="material-icons">
            {iconKey ?  iconKey : 'perm_identity'}
        </i>
    </StyledIconLink>
</StyledIconLinkWrapper>




IconLink.propTypes = {
    to: PropTypes.string.isRequired,
    primaryColor: PropTypes.string.isRequired,
    hoverBackground: PropTypes.string.isRequired,
    iconKey: PropTypes.string,
    isActive: PropTypes.bool,
    onLinkClick: PropTypes.bool,
    toolTip: PropTypes.string
}


export default withRouter(IconLink) 