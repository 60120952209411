import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { call, takeLatest, put } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { populateSharedResource } from 'application/redux/actions/shared_resource'
import { loginRequestSuccess } from 'application/redux/actions/auth'

function* fetchSharedResourceSaga(action) {
    try {
        const result = yield call(
            api.gateway.sharedResource.getSharedResourceById,
            action.sharedResourceId,
        )

        yield put(
            populateSharedResource(result)
        )

        if(!result.passwordProtected){
            yield put(
                loginRequestSuccess()
            )
        }

    } catch (e) {
        yield put( 
            populateSharedResource(null) 
        )
        yield handleError(e, action)
    }
}

export function* fetchSharedResourceWatcher() {
    yield takeLatest(
        ACTIONS.SHARED_RESOURCE_GET_SHARED_RESOURCE_BY_ID, 
        fetchSharedResourceSaga
    )
}