import {
    StringEqualsFilter, StringContainsFilter,
    OrOperator,
} from '@ec/filter-sort-page-query-js'

export const customers = {
    isCompany: {
        filterName: 'customers_isCompany',
        filterKey: 'cuic',
        label: 'Customer is business customer',
        defaultValue: null,
        getFilterQuery: (value) => ([
            new StringEqualsFilter('isCompany', value, true)
        ])
    },
    name: {
        filterName: 'customers_name',
        filterKey: 'cun',
        label: 'Name',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('firstName', value, false),
            new OrOperator(),
            new StringContainsFilter('lastName', value, false),
            new OrOperator(),
            new StringContainsFilter('companyName', value, false)
        ])
    },
    businessCustomers: {
        filterName: 'customers_businessCustomers',
        filterKey: null,
        label: 'Business customers',
        defaultValue: null,
        isStatic: true,
        getFilterQuery: () => ([
            new StringEqualsFilter('isCompany', 'true', true)
        ])
    },
    privateCustomers: {
        filterName: 'customers_privateCustomers',
        filterKey: null,
        label: 'Private customers',
        defaultValue: null,
        isStatic: true,
        getFilterQuery: () => ([
            new StringEqualsFilter('isCompany', 'false', true)
        ])
    }
}