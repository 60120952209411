import React from 'react'

import { GlobalStyle } from 'application/style'
import * as Fragments from './fragments'
import Toaster from 'application/components/building_blocks/toaster'
import Gallery from 'application/components/building_blocks/attachments/gallery'
import WaitingScreen from 'application/components/building_blocks/waiting_screen'
import EarlyAccessNote from 'application/components/controls/early_access_note'
import Dialogs from 'application/components/building_blocks/dialog/xdialogs'
import { withCategoriesLoader, withInsurancesLoader, withAccessibleBranchesLoader, withAccountsLoader } from 'application/components/higher_order_components'
import { compose } from 'redux'
import ErrorDisplay from 'application/components/building_blocks/error_display'

const LayoutBase = ({ 
    waitingScreen,
    children,
    dialogsOpen
}) => {
    return <React.Fragment>
        <GlobalStyle />
        <Fragments.Wrapper 
            blurred={
                waitingScreen.active 
                && !dialogsOpen 
                && !waitingScreen.inDialog
            }
        >
            {children}
        </Fragments.Wrapper>
        <Dialogs />
        <ErrorDisplay />
        <Toaster />
        <WaitingScreen />
        <Gallery />
        <EarlyAccessNote />
    </React.Fragment>
}

export default compose(
    withAccountsLoader(),
    withAccessibleBranchesLoader,
    withInsurancesLoader,
    withCategoriesLoader
)(LayoutBase)