import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextArea } from 'application/components/controls/form'
import { Footer } from 'application/components/dialogs/_common/footer'
import { Headline3 } from 'application/components/fragments/typography'
import InvoicesName from 'application/components/name_mappings/invoices'
import { FlexBox } from 'application/components/fragments/flex_box'
import PaymentSelect from 'application/components/controls/form/payment_select'
import { useStatefulForm } from 'application/components/hooks'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { isCancelled } from 'application/common/payment'


const CancelPayment = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged, 
        onCancelPayment, 
        dialog 
    }
) => {
    const { t } = useTranslation()

    const { 
        register,
        errors,
        handleSubmit,
        getValues, 
        setValue,
        updateFormState
    } = useStatefulForm()

    const { 
        payments, 
        parentId, 
        type
    } = dialog.payload

    
    const activePayments = payments.filter( payment => !isCancelled(payment) )

    
    const [selectedPayment, setSelectedPayment] = useState(null)

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
        updateFormState()
    }

    const setPayment = () => {
        const payment = payments.find(p => p.id === getValues().paymentId)
        setSelectedPayment(payment)
    }

    const successMsg = t('Payment is cancelled')

    const onSubmitSuccess = () => {
        onCancelPayment(
            dialog.name, {
                ...selectedPayment,
                reason: getValues().reason
            }, 
            parentId, 
            dialog.payload.id, 
            selectedPayment.id, 
            successMsg
        )
    }

    const onError = (errors, e) => console.log(errors, e)


    return <React.Fragment>
        <DialogMainContent>
            <FlexBox 
                flexDirection='column' 
            >
                <Headline3>
                    <InvoicesName invoicesType={type} />
                </Headline3>
                <PaymentSelect
                    name='paymentId'
                    register={register}
                    setValue={setValue}
                    payments={activePayments}
                    error={errors.paymentId}
                    onChange={() => {
                        onDataChange()
                        setPayment()
                    }}
                />
                <TextArea 
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    label={t('Description')}
                    name='reason'
                    error={errors.reason}
                    onChange={onDataChange}
                />
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess, onError)}
            submitText='Cancel payment'
        />
    </React.Fragment>

}

export default withDialogDataLoader(CancelPayment)