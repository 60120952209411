import {ACTIVITY_TYPE} from 'application/constants'
import {AccountDetails} from './account'
import {BranchDetails} from './branch'

import {
    DamageCreatedDetails, 
    DamageDeletedDetails,
    DamageAssigneeChangedDetails,
    DamageJobnumberChangedDetails,
    DamageTypeChangedDetails,
    DamageStateChangedDetails,
    ComprehensiveDamageTypeChangedDetails,
    DamageDescriptionChangedDetails,
    DamageIncidentDateChangedDetails,
    DamageNoteChangedDetails
} from './damage'

import {
    DamageStateDetails
} from './damage_state'

import {
    ComprehensiveDamageTypeDetails,
    ComprehensiveDamageTypeDeletedDetails
} from './comprehensive_damage_type'

import {
    InvoiceCreatedDetails, 
    InvoiceDeletedDetails
} from './invoice'

import {
    PaymentCreatedDetails,
    PaymentCanceledDetails
} from './payment'

import {
    LawfirmCommunicationInitiation,
    LawfirmCommunicationAbortion
} from './lawfirm_communication'

import { 
    UserDetails,
    UserDeletedDetails
} from './user'

import { 
    UserInvitationDetails
} from './user_invitation'

import {
    TaskDetails,
    TaskStateChangedDetails,
    TaskCommentCreatedDetails
} from './task'

import {
    VehicleUpdatedDetails,
    VehicleKeeperChangedDetails
} from './vehicle'

import {
    DocumentCreatedDetails,
    DocumentDeletedDetails
} from './document'

import {
    MessageCreatedDetails,
    MessageDeletedDetails
} from './message'

import {
    ExpertCommunicationDekra,
    ExpertCommunicationOther
} from './expert_communication'

import {DefaultDetails} from './default'





export const getActivityDetailsByType = (activityType) => {
    switch (activityType) {
    case ACTIVITY_TYPE.ACCOUNT_CREATED:
    case ACTIVITY_TYPE.ACCOUNT_INVITATION_CREATED:
    case ACTIVITY_TYPE.ACCOUNT_LOCKED:
    case ACTIVITY_TYPE.ACCOUNT_UNLOCKED:
        return AccountDetails        
    case ACTIVITY_TYPE.BRANCH_CREATED:
    case ACTIVITY_TYPE.BRANCH_DELETED:
        return BranchDetails   
    case ACTIVITY_TYPE.COMPREHENSIVE_DAMAGE_TYPE_CHANGED:
        return ComprehensiveDamageTypeChangedDetails
    case ACTIVITY_TYPE.COMPREHENSIVE_DAMAGE_TYPE_CREATED:
    case ACTIVITY_TYPE.COMPREHENSIVE_DAMAGE_TYPE_RENAMED:
        return ComprehensiveDamageTypeDetails   
    case ACTIVITY_TYPE.COMPREHENSIVE_DAMAGE_TYPE_DELETED:
        return ComprehensiveDamageTypeDeletedDetails 
    case ACTIVITY_TYPE.DAMAGE_ASSIGNEE_CHANGED:
        return DamageAssigneeChangedDetails
    case ACTIVITY_TYPE.DAMAGE_CREATED:
        return DamageCreatedDetails
    case ACTIVITY_TYPE.DAMAGE_DELETED:
        return DamageDeletedDetails
    case ACTIVITY_TYPE.DAMAGE_JOB_NUMBER_CHANGED:
        return DamageJobnumberChangedDetails 
    case ACTIVITY_TYPE.DAMAGE_STATE_CREATED:
    case ACTIVITY_TYPE.DAMAGE_STATE_DELETED:
        return DamageStateDetails 
    case ACTIVITY_TYPE.DAMAGE_STATE_CHANGED:
        return DamageStateChangedDetails 
    case ACTIVITY_TYPE.DAMAGE_TYPE_CHANGED:
        return DamageTypeChangedDetails
    case ACTIVITY_TYPE.DAMAGE_DESCRIPTION_CHANGED:
        return DamageDescriptionChangedDetails
    case ACTIVITY_TYPE.DAMAGE_INCIDENT_DATE_CHANGED:
        return DamageIncidentDateChangedDetails
    case ACTIVITY_TYPE.DAMAGE_NOTE_CHANGED:
        return DamageNoteChangedDetails
    case ACTIVITY_TYPE.INVOICE_CREATED:
        return InvoiceCreatedDetails
    case ACTIVITY_TYPE.INVOICE_DELETED:
        return InvoiceDeletedDetails
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_INITIATED:
        return LawfirmCommunicationInitiation
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_ABORTED:
        return LawfirmCommunicationAbortion
    case ACTIVITY_TYPE.PAYMENT_CANCELLED:
        return PaymentCanceledDetails
    case ACTIVITY_TYPE.PAYMENT_CREATED:
        return PaymentCreatedDetails
    case ACTIVITY_TYPE.USER_CREATED:
    case ACTIVITY_TYPE.USER_DATA_CHANGED:
    case ACTIVITY_TYPE.USER_LINKED:
    case ACTIVITY_TYPE.USER_LOCKED:
    case ACTIVITY_TYPE.USER_UNLOCKED:
        return UserDetails
    case ACTIVITY_TYPE.USER_DELETED:
        return UserDeletedDetails
    case ACTIVITY_TYPE.USER_INVITATION_CREATED:
        return UserInvitationDetails
    case ACTIVITY_TYPE.TASK_CREATED:
    case ACTIVITY_TYPE.TASK_DELETED:
        return TaskDetails
    case ACTIVITY_TYPE.TASK_STATE_CHANGED:
        return TaskStateChangedDetails
    case ACTIVITY_TYPE.TASK_COMMENT_CREATED:
        return TaskCommentCreatedDetails
    case ACTIVITY_TYPE.VEHICLE_UPDATED:
        return VehicleUpdatedDetails
    case ACTIVITY_TYPE.VEHICLE_KEEPER_CHANGED:
        return VehicleKeeperChangedDetails
    case ACTIVITY_TYPE.DOCUMENT_CREATED:
        return DocumentCreatedDetails
    case ACTIVITY_TYPE.DOCUMENT_DELETED:
        return DocumentDeletedDetails
    case ACTIVITY_TYPE.MESSAGE_CREATED:
        return MessageCreatedDetails
    case ACTIVITY_TYPE.MESSAGE_DELETED:
        return MessageDeletedDetails
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_INITIATED:
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_ESTABLISHED:
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_FAILED:
        return ExpertCommunicationDekra
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_OTHER_INITIATED:
        return ExpertCommunicationOther
    default:
        return DefaultDetails
    }
}