import { connect } from 'react-redux'

import CategorySelectWithUploaderComponent from './component'
import { openDialogByName, sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = (state, props) => ({
    dialog: state.dialogs.registeredDialogs.find(
        d => d.name === props.dialog.name
    )
})

const mapDispatchToProps = dispatch => {
    return {
        //hook potential
        onSubmitUpload: (
            dialogName,
            payload,
            metaData
        ) => dispatch(
            sendPayloadRequest(
                dialogName,
                payload,
                metaData
            )
        ),
        onOpenExportToLawfirmConfirmationDialog: (
            dialogName,
            name,
            initiallyLoaded, 
            payload,
            title
        ) => dispatch(
            openDialogByName(
                dialogName,
                name,
                initiallyLoaded, 
                payload,
                title
            )
        )
        //
    }
}

export const CategorySelectWithUploader = connect(
    mapStateToProps,
    mapDispatchToProps
)(CategorySelectWithUploaderComponent)