import React, {useEffect} from 'react'

import { GlobalStyle } from 'application/style'
import * as Fragments from './fragments'
import Toaster from 'application/components/building_blocks/toaster'
import WaitingScreen from 'application/components/building_blocks/waiting_screen'
import { withRouter } from 'react-router-dom'
import ErrorDisplay from 'application/components/building_blocks/error_display'

const LayoutBaseSharedResource = ({ 
    waitingScreen,
    onSaveSharedResourceData,
    children,
    match,
    dialogsOpen,
    sharedResourceType    
}) => {

    const sharedResourceId = match.params.sharedResourceId

    useEffect(()=>{
        onSaveSharedResourceData(sharedResourceId, sharedResourceType)  
    },[onSaveSharedResourceData, sharedResourceId, sharedResourceType])

    return <React.Fragment>
        <GlobalStyle />
        <Fragments.Wrapper blurred={waitingScreen.active && !dialogsOpen && !waitingScreen.inDialog}>
            {children}
        </Fragments.Wrapper>
        <ErrorDisplay />
        <Toaster />
        <WaitingScreen />
    </React.Fragment>
}

export default withRouter(LayoutBaseSharedResource)