import React from 'react'
import { withRouter } from 'react-router-dom'

import * as Fragments from './fragments'
import LayoutBase from './layout_base_container'
import { TitleBarMain } from 'application/components/pages/internal/_title_bars'
import RealtimeNotificationsSidebar from 'application/components/building_blocks/realtime_notifications_sidebar'
import MainNavigationSidebar from 'application/components/building_blocks/main_navigation_sidebar'

const LayoutMain = ({ children, cssPaddingLeft }) => {
    return <React.Fragment>
        <RealtimeNotificationsSidebar />
        <LayoutBase>
            <Fragments.Header>
                <TitleBarMain />
            </Fragments.Header>
            <Fragments.Main>
                <MainNavigationSidebar/>
                <Fragments.Content 
                    cssPaddingLeft={cssPaddingLeft}
                >
                    {children}
                </Fragments.Content>
            </Fragments.Main>
        </LayoutBase>
    </React.Fragment>
}

export default withRouter(LayoutMain)