import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import RemoveUserFromGroup from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onRemoveUser: (dialogName, userId, groupId) => {
            dispatch(sendPayloadRequest(dialogName, { userId, groupId }))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RemoveUserFromGroup)