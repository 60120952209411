import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import AttachmentGallery from './component'

const AttachmentGalleryEffects = (props) => {
    const { onLoadDocuments, documentsLoading, loggedIn, match, currentDamageId, gallery, documentsLoadingWP, onLoadDocumentsWorkflowPanel } = props
    const { damageId } = match.params
    const isExternal = damageId === undefined

    const shouldLoad = !isExternal ? (!documentsLoading && currentDamageId !== damageId && damageId !== undefined && gallery.isVisible) : (!documentsLoadingWP && match.params.sharedResourceId !== undefined && gallery.isVisible)

    useEffect(() => {
        (loggedIn && shouldLoad) && onLoadDocuments(damageId, gallery.taskId, match)
    }, [gallery.taskId, isExternal, loggedIn, match, onLoadDocuments, onLoadDocumentsWorkflowPanel, damageId, shouldLoad])
    return <AttachmentGallery {...props}/>
}

export default withRouter(AttachmentGalleryEffects)