import { put } from 'redux-saga/effects'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { branchPOSTMapper, branchDataMapper, branchAddressDataMapper, branchContactDataMapper } from 'application/api/data_mappers/export/branches'
import { addBranchToList } from 'application/redux/actions/common/branches'

export function* createBranchSaga(action) {
    yield put(
        showWaitingScreen('Creating branch')
    )  

    const postPayload = branchPOSTMapper(action.payload)
    const response = yield resourceCall(
        api.gateway.branches.postCreateBranch, 
        null, 
        postPayload
    )

    const addressPayload = branchAddressDataMapper(action.payload)
    yield resourceCall(
        api.gateway.branches.putUpdateBranchAddress, 
        {
            branchId: response.id
        }, 
        addressPayload
    )    

    const contactPayload = branchContactDataMapper(action.payload.contact)
    yield resourceCall(
        api.gateway.branches.putUpdateBranchContact, 
        {
            branchId: response.id
        }, 
        contactPayload
    )

    yield put(
        addBranchToList(
            branchDataMapper(
                {
                    ...action.payload, 
                    id: response.id
                }
            )
        )
    )
    
    yield put(
        hideWaitingScreen()
    )
    yield put(
        showToast('Branch created')
    )
}