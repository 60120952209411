import React from 'react'
import { withRouter } from 'react-router-dom'
import { SubPageLayout, PushBottom16 } from 'application/components/pages/_common'
import {TableMyTasksOverview} from 'application/components/tables/tasks/current_branch/my_tasks'
import { configuration } from 'application/components/tables/tasks/current_branch/my_tasks/configuration'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import { USER_PERMISSONS } from 'application/constants'
import { ExternalFilterCheckboxGroup, TaskStateFilterCheckboxGroup } from 'application/components/building_blocks/filters/checkboxes'
import { FlexBox } from 'application/components/fragments/flex_box'

const MyTasks = (
    { 
        tasksList, 
        tasksListLoading, 
        tasksListLoaded, 
        tasksListTotal,
        moName,
        onLoadTasksList,
        updateConstraint
    }
) => {

    return <SubPageLayout>
        <FlexBox>
            <FlexBox width='400px'>
                <TaskStateFilterCheckboxGroup
                    pagination={configuration.pagination}
                    disabled={tasksListLoading}
                />
            </FlexBox>
            <ExternalFilterCheckboxGroup
                pagination={configuration.pagination}
                disabled={tasksListLoading}
                spaceLeft={true}
            /> 
        </FlexBox>
        <PushBottom16/>
        <ForCurrentBranchPermissions 
            permissions={
                [
                    USER_PERMISSONS.TASK.DELETE
                ]
            } 
            allNeeded={false}
        >
            {/* <MassOperationPanel
                moName={moName}
                label={'Edit multiple tasks'}
                itemDesignation={'Task'}
                itemsDesignation={'Tasks'}
            >
                <MassOperationsTasksSelect
                    permissionScope={PERMISSION_SCOPE.CURRENT_BRANCH}
                    moName={moName}
                />
            </MassOperationPanel>   */}
        </ForCurrentBranchPermissions>
        <PushBottom16/>
        <TableMyTasksOverview 
            tasksListLoading={tasksListLoading}
            tasksListLoaded={tasksListLoaded}
            tasksListTotal={tasksListTotal}
            tasksList={tasksList}
            moName={moName}
            onLoadTasks={onLoadTasksList}
            updateConstraint={updateConstraint}
        />
    </SubPageLayout>
}

export default withRouter(MyTasks)