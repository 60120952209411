import { connect } from 'react-redux'

import { getFullAttachmentById, downloadAttachment } from 'application/redux/actions/attachments'
import AttachmentViewerComponent from './load_attachment_hoc'


const mapStateToProps = (state) => {
    return {
        attachments: state.attachments
    }
}

const mapDispatchToProps = dispatch => ({
    onLoadAttachment: (id, name, damageId, taskId, match) => dispatch(getFullAttachmentById(id, name, { damageId, match, taskId })),
    onAttachmentDownload: (id, damageId, name, match) => dispatch(downloadAttachment(id, name, damageId))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttachmentViewerComponent)