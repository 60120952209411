import { FlexBox } from 'application/components/fragments/flex_box'
import React from 'react'
import { StyledCustomerQrCodeElement, QrCodeWrapper } from './fragments'
import {QRCodeCanvas} from 'qrcode.react'
import { useCreateDamageUrl } from 'application/components/dialogs/customer/create_qr_code/step_1/use_create_damage_url'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label'
import { useTranslation } from 'react-i18next'
import { getFullName } from 'application/common/name_helpers'
import CustomerDisplay from 'application/components/building_blocks/customer_display'

export const CustomersQrCodeElement = ({
    customer, 
    currentAccountName, 
    categoryId,
    onQRCodeDownload,
    onEditCustomer
}) => {
    const qrCodeElementId = `customerQRCode-${customer.id}`
    const {t} = useTranslation() 

    const getQrCodeObjectUrl = () => {
        return document.getElementById(qrCodeElementId)
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
    }

    const handleDownload = () => {
        onQRCodeDownload(
            getQrCodeObjectUrl(),
            `QR-Code-Schadenabwicklung-${getFullName(customer)}.png`    
        )
    }

    const imageSrc = '/images/logos/esy360_logo_qr.png'

    const {
        createDamageUrl
    } = useCreateDamageUrl(
        customer, 
        '', 
        currentAccountName, 
        categoryId, 
        customer.sharedResource.proxyShareId
    )

    return <StyledCustomerQrCodeElement>
        <FlexBox flexGrow='1' flexBasis='auto' flexDirection='column' cssRules='padding: 16px;' justifyContent='space-between'>
            <div>
                <CustomerDisplay 
                    customer={customer}
                    onEditCustomer={()=>{onEditCustomer(customer)}}
                    hideLabels={true}
                />
            </div>
            <IconButtonWithLabel 
                onButtonClick={handleDownload} 
                hoverBackground='#d9d9d9'
                label={t('Download QR Code')} 
                iconKey='get_app' />
        </FlexBox>
        <FlexBox>
            <QrCodeWrapper>
                <QRCodeCanvas
                    id={qrCodeElementId}
                    value={createDamageUrl}
                    size={1000}
                    includeMargin={true}
                    imageSettings={
                        { 
                            src: imageSrc,
                            width: 200,
                            height: 242
                        }
                    }
                    level="M"
                />
            </QrCodeWrapper>
        </FlexBox>
    </StyledCustomerQrCodeElement>
}