import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import MessageDetails from 'application/components/building_blocks/message/details'
import {withMessageDetailsLoader} from 'application/components/higher_order_components/with_messages_details_loader'
import { ROUTES } from 'application/constants'



const WFPMessageDetailsWidgetC = (
    { 
        loadingFailed, 
        messageDetails = null
    }
) => {

    const { t } = useTranslation()
    if (loadingFailed) {
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    return <React.Fragment>
        <MessageDetails 
            messageDetails={messageDetails}
            showDamageLink={false}
        />
    </React.Fragment>
}

WFPMessageDetailsWidgetC.propTypes = {
    loadingFailed: PropTypes.bool.isRequired,
    messageDetails: PropTypes.object
}

export const WFPMessageDetailsWidged =  withMessageDetailsLoader( 
    ROUTES.EXT_WORKFLOW_PANEL_MESSAGE
)(WFPMessageDetailsWidgetC)