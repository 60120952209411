import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { populateStatusList }  from 'application/redux/actions/common'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* getStatusListSaga(/*action*/) {
    try {
        const { states } = yield resourceCall(
            api.gateway.damageState.getCustomStates
        )
        yield put(populateStatusList(states))
    } catch (e) {
        yield handleError(e)
    }
}