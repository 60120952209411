import { setSharedResourceData } from 'application/redux/actions/shared_resource'
import { connect } from 'react-redux'
import LayoutBaseWorkflowPanel from './layout_base_shared_resource'

const mapDispatchToProps = dispatch => {
    return {
        onSaveSharedResourceData: (id, type) => {
            dispatch(setSharedResourceData(id, type))
        }
    }
}

export default connect(
    state => ({
        sharedResource: state.sharedResource,
        waitingScreen: state.waitingScreen,
        dialogsOpen: state.dialogs.dialogsOpen
    }),
    mapDispatchToProps
)(LayoutBaseWorkflowPanel)