import React from 'react'
import { ROUTE_PARAMETERS, USER_PERMISSONS } from 'application/constants'
import { SubPageLayout, PushBottom32 } from 'application/components/pages/_common'
import DamageSearchFilter from 'application/components/building_blocks/filters/damage_search_filter'
import UserFilter from 'application/components/building_blocks/filters/user_filter'
import { MassOperationPanel } from 'application/components/building_blocks/mass_operations'
import {MassOperationsDamagesSelect} from './../_common/mass_operations/mass_operation_select'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import {TableAllDamagesOverview} from 'application/components/tables/damages/table_all_damages'
import DamageCustomerIdFilter from 'application/components/building_blocks/filters/damage_customer_id_filter'
import { FlexBox } from 'application/components/fragments/flex_box'
import { DamageStateFilterCheckboxGroup } from 'application/components/building_blocks/filters/checkboxes'
import { configuration } from 'application/components/tables/damages/table_all_damages/configuration'





const DamagesOverviewAllDamages = (
    {
        allDamages,
        moName,
        moType,
        onLoadDamagesList,
        updateConstraint
    }
) => {
    const { 
        damagesList, 
        damagesListLoading, 
        damagesListLoaded, 
        damagesListTotal
    } = allDamages

    return <SubPageLayout>
        <DamageSearchFilter
            filterKey={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.SEARCH_BY.KEY}
        />
        <DamageCustomerIdFilter
            routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.CUSTOMER}
        />
        <ForAnyBranchPermissions 
            permissions={[USER_PERMISSONS.USER.READ]}
        >
            <UserFilter
                routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.USER}
                filterTitle='Showing damages assigned to'
            />
        </ForAnyBranchPermissions>
        <FlexBox>
            <FlexBox width='400px'>
                <DamageStateFilterCheckboxGroup
                    pagination = {configuration.pagination}
                    disabled = {damagesListLoading}
                />
            </FlexBox>
        </FlexBox>
        <ForAnyBranchPermissions 
            permissions={
                [
                    USER_PERMISSONS.DAMAGE.UPDATE, 
                    USER_PERMISSONS.DAMAGE.DELETE
                ]
            } 
            allNeeded={false}
        >
            <MassOperationPanel
                moName={moName}
                moType={moType}
                label={'Edit multiple damages'}
                itemDesignation={'damage'}
                itemsDesignation={'damages'}
            >
                <MassOperationsDamagesSelect
                    moName={moName}
                />
            </MassOperationPanel> 
        </ForAnyBranchPermissions>
        <PushBottom32 />
        <TableAllDamagesOverview
            damagesList={damagesList}
            damagesListLoaded={damagesListLoaded}
            damagesListLoading={damagesListLoading}
            damagesListTotal={damagesListTotal}
            onLoadDamages={onLoadDamagesList}
            updateConstraint={updateConstraint}
            moName={moName}
        />
    </SubPageLayout>
}

export default DamagesOverviewAllDamages