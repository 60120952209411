import React from 'react'
import { useTranslation } from 'react-i18next'
import { LIABILITY_INQUIRY_INFO } from 'application/constants'

export default (
    { liabilityInquiryInfo }
) => {
    const { t } = useTranslation()
    const mapLiabilityRequestTypeName = (type) => {
        const names = new Map([
            [LIABILITY_INQUIRY_INFO.MISSING_WRITTEN_REPORT_OF_OPPONENT, 'Missing written notification of the opponent'],
            [LIABILITY_INQUIRY_INFO.PENDING_VERIFICATION_BY_OPPOSING_INSURANCE, 'Check by Opposing Insurance Not concluded'],
            [LIABILITY_INQUIRY_INFO.INVESTIGATION_FILE_REQUESTED, 'Investigation file has been requested'],
            [LIABILITY_INQUIRY_INFO.LIABILITY_DENIED, 'Liability Rejected'],
            [LIABILITY_INQUIRY_INFO.LIABILITY_RATIO, 'Liability quota'],
            [LIABILITY_INQUIRY_INFO.GREEN_CARD, 'Green card'],
            [LIABILITY_INQUIRY_INFO.MISSING_ACCIDENT_REPORT, 'Missing accident report'],
            [LIABILITY_INQUIRY_INFO.LIABILITY_CONFIRMED, 'Confirmation of liability'],
            [LIABILITY_INQUIRY_INFO.INQUIRIES_DENIED, 'Information refused'],
            [LIABILITY_INQUIRY_INFO.FOREIGN_INVOLVEMENT, 'Foreign participation'],
            [LIABILITY_INQUIRY_INFO.GERMAN_INSURANCE, 'VU in DE'],
            [LIABILITY_INQUIRY_INFO.FOREIGN_INSURANCE, 'VU abroad'],
        ])
        return names.get(type)
    }

    return <React.Fragment>
        {t(mapLiabilityRequestTypeName(liabilityInquiryInfo))}
    </React.Fragment>
}