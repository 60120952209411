import { WIDGET_TYPES } from 'application/constants'

const KEYS = {
    CUSTOMERS_DASHBOARD_LAST_CUSTOMERS:'WIDGET_CUSTOMERS_DASHBOARD_LAST_CUSTOMERS'
}

export const layoutBranchSpecificCustomersDashboard= {
    LARGE: [
        {i: KEYS.CUSTOMERS_DASHBOARD_LAST_CUSTOMERS, x: 0, y: 0, w: 4, h: 4}
    ]
}

export const widgetsBranchSpecificCustomersDashboard = [
    {
        key: KEYS.CUSTOMERS_DASHBOARD_LAST_CUSTOMERS,
        type: WIDGET_TYPES.BRANCH_SPECIFIC_LAST_CUSTOMERS,
        title: 'last customers',
        hideHeadline: true,
        accountDependent: true
    }
]
