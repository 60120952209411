import { useState } from 'react'
import { useForm } from 'react-hook-form'

export const useStatefulForm = (options) => {
    const defaultValues = options?.defaultValues || {}
    const [formStateValues, setFormStateValues] = useState(defaultValues)
    const form = useForm(options)

    const updateFormState = () => {
        setFormStateValues(
            {
                ...formStateValues, 
                ...form.getValues()
            }
        )
    }

    return {
        formStateValues,
        setFormStateValues,
        updateFormState,
        ...form
    }
}

export default useStatefulForm