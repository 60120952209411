import React , { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { PureSelect } from 'application/components/controls/form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { DIALOG, USER_PERMISSONS } from 'application/constants'
import { isPermittedInAnyBranch } from 'application/components/context/permissions'



const SelectWrapper = styled.div`
  & .css-yk16xz-control {
      max-height: 35px;
      height: 35px;
      min-height: 35px;
    width: 200px;
  }

  & .css-1pahdxg-control {
    max-height: 35px;
    height: 35px;
    min-height: 35px;
    width: 200px;
}
`

const HoSpacer = styled.div`
    width: ${ props => props.space ? props.space : '8px'};
    min-width: ${ props => props.space ? props.space : '8px'};
`


 
const MassOperationsDamagesSelect = (
    {
        mo,
        onOpenMoDialog,
        allPermissions = []
    }
) => {
    const { t } = useTranslation()


    const moOperations = [
        {
            dialogName: DIALOG.NAME.MO_DAMAGES_DELETE,
            initiallyLoaded: true,
            dialogTitle: t('Delete'),
            dialogPayload: {
                selectedItems: mo.selectedItems,
                moName: mo.moName
            },
            permitted: isPermittedInAnyBranch(
                allPermissions, 
                [ USER_PERMISSONS.DAMAGE.DELETE ], 
                true
            )
        },
        {
            dialogName: DIALOG.NAME.MO_DAMAGES_CHANGE_STATE,
            initiallyLoaded: true,
            dialogTitle: t('Change state'),
            dialogPayload: {
                selectedItems: mo.selectedItems,
                moName: mo.moName
            },
            permitted: isPermittedInAnyBranch( 
                allPermissions, 
                [ USER_PERMISSONS.DAMAGE.UPDATE ], 
                true
            )
        }
    ]

    const options = moOperations
        .filter(
            o => o.permitted
        )
        .map(
            o => ({
                label: o.dialogTitle,
                value: o.dialogName
            })
        )


    const getDialogParams = (selectedDialogName) => {
        return moOperations.find(
            o => o.dialogName === selectedDialogName 
        )
    }  


    const  openMoDialog = () => {

        const {
            dialogName, 
            initiallyLoaded,                    
            dialogTitle,
            dialogPayload
        } = getDialogParams(selectedOption.value)

        onOpenMoDialog(            
            dialogName,
            initiallyLoaded,
            dialogPayload,
            dialogTitle
        )
    }

    const [selectedOption, setselectedDialogName] = useState(null)
    const selectEnabled = mo.selectedItems.length > 0 
    const actionEnabled = selectEnabled && selectedOption !== null 

    const handleSelect = (dialogName) =>{
        setselectedDialogName(dialogName)
    }

    return <React.Fragment>
        <SelectWrapper>
            <PureSelect
                options={options}
                value={selectedOption}
                onChange={handleSelect}
                isClearable={true}
                placeholder={t('Choose action')}
                isDisabled={!selectEnabled}
                cssWidth='200px'
            />
        </SelectWrapper> 
        <HoSpacer/>
        <ActionButton
            buttonText={t('Apply')}
            onButtonClick={openMoDialog}
            disabled={!actionEnabled}
            cssHeight={'50px'}
        />
    </React.Fragment>
}

MassOperationsDamagesSelect.propTypes = {
    mo: PropTypes.object.isRequired,
    onOpenMoDialog: PropTypes.func.isRequired,
    permissions: PropTypes.array
}

export  {MassOperationsDamagesSelect}