import defaultStore from '../default_store'
import {ACTIONS} from 'application/constants/actions'
import { sharedResourceId, sharedResourcePin } from 'application/storage'

export const sharedResourceReducer = (
    state = defaultStore.sharedResource, 
    action
) => {
    switch (action.type) {
    case ACTIONS.SHARED_RESOURCE_SET_SHARED_RESOURCE_DATA: {
        sharedResourceId.store(action.sharedResourceId)
        return {
            ...state,
            id: action.sharedResourceId,
            resourceType: action.sharedResourceType
        }
    }
    case ACTIONS.SHARED_RESOURCE_GET_SHARED_RESOURCE_BY_ID: {
        return {
            ...state,
            resourceIsLoaded: false,
            resourceData: null
        }
    }
    case ACTIONS.SHARED_RESOURCE_POPULATE_SHARED_RESOURCE: {
        return {
            ...state,
            resourceIsLoaded: true,
            resourceData: action.sharedResource,
            resourceIsValid: true
        }
    }
    case ACTIONS.SHARED_RESOURCE_SET_RETURN_URL: {
        return {
            ...state,
            returnUrl: action.returnUrl
        }
    }  
    case ACTIONS.SHARED_RESOURCE_LOGOUT: {
        sharedResourcePin.delete(action.sharedResourceId)
        return {
            ...defaultStore.sharedResource
        }
    }   
    case ACTIONS.SHARED_RESOURCE_UPDATE_PIN: {
        return {
            ...state,
            pin: action.pin,
            pinIsValidated: false
        }}
    case ACTIONS.SHARED_RESOURCE_SKIP_VALIDATION: {
        return {
            ...state,
            pin: null,
            pinIsValidated: true,
            pinIsValid: true,
            pinValidationPending: false,
            loggedIn: true,
        }}
    case ACTIONS.SHARED_RESOURCE_VALIDATE_PIN_REQUEST: {
        return {
            ...state,
            pinValidationPending: true
        }
    }
    case ACTIONS.SHARED_RESOURCE_VALIDATE_PIN_SUCCESS: {
        return {
            ...state,
            pinIsValid: true,
            pinIsValidated: true,
            loggedIn: true,
            pinValidationPending: false
        }
    }
    case ACTIONS.SHARED_RESOURCE_VALIDATE_PIN_FAILED: {
        return {
            ...state,
            pinIsValid: false,
            pinIsValidated: true,
            pinValidationPending: false
        }
    }
    default:
        return state
    }
}