import React from 'react'
import { withRouter } from 'react-router-dom'
import { ROUTE_PARAMETERS } from 'application/constants'
import { useQuery } from 'application/components/hooks'
import IconLink from 'application/components/controls/icon_link/component'
import {StyledPagination, StyledPlaceholder, StyledLink, Divider} from './fragments'
import PropTypes from 'prop-types'
import {routeHelpers} from 'application/common'




const paramDefaults = {
    offset: ROUTE_PARAMETERS.DEFAULT.OFFSET,
    limit: ROUTE_PARAMETERS.DEFAULT.LIMIT,
}



const generateLink = (
    params, 
    offSet, 
    currentOffset, 
    limit, 
    foreignParams = []
) => {
    return {
        params:[
            {
                key: params.offset,
                value:  currentOffset
            },
            {
                key: params.limit,
                value: limit
            },
            ...foreignParams,
        ],
        isActive: offSet === currentOffset
    }
}



const generateLinks = (
    pageCount, 
    params, 
    offSet, 
    limit, 
    foreignParams = []
) => {
    return Array.from(
        Array(pageCount).keys(), i => {

            let currentOffset = i * limit
            
            return generateLink (
                params, 
                offSet, 
                currentOffset, 
                limit, 
                foreignParams
            )
        }
    )
}



const RoutedPagination = (
    {
        parameterNames = {},
        totalSize = 0, 
        itemsPerPage,
        onPaginationChange = (o, l) => {},
        match
    }
) => {
    const {buildQueryString} = routeHelpers

    //get parameter names necessary for pagination
    const params = {...paramDefaults, ...parameterNames}
     
    //get query object for current url
    const query = useQuery()
    const offSet = query.getParsedParam(params.offset)

    const limit = query.getParsedParam(params.limit) === 0 
        ? itemsPerPage 
        : query.getStringParam(params.limit) // > 0 to avoid divide by zero

    const pageCount = Math.ceil(totalSize / limit)


    //generate links
    const foreignParams = query.all.filter(
        l => l.key !== params.offset
    ).filter(
        l => l.key !== params.limit
    )


    const linkSkipLeft = generateLink( 
        params, 
        0, 
        0, 
        limit,
        foreignParams
    )

    const links = generateLinks(
        pageCount, 
        params, 
        offSet,
        limit, 
        foreignParams
    )
    
    const linkSkipRight = generateLink( 
        params, 
        (pageCount-2)*limit, 
        (pageCount-1)*limit,
        limit, 
        foreignParams
    )


    const isLastPage = links.indexOf(
        links.filter(
            l => l.isActive
        )[0]
    ) === links.length - 1

    const isFirstPage = links.indexOf(
        links.filter(
            l => l.isActive
        )[0]
    ) === 0


    //don't show all links, use dividers
    const currentIndex = links.indexOf(
        links.find(
            l => l.isActive
        )
    )

    const currentIndexRange = [
        currentIndex - 1, 
        currentIndex, 
        currentIndex + 1
    ]

    const edgeIndexesStart = currentIndex === 0 
        ? [0 , 1] 
        : [0, 1, 2]

    const edgeIndexesEnd = [
        links.length - 1, 
        links.length - 2, 
        links.length - 3
    ]

    let dividersAfter = []
    
    if( 
        !edgeIndexesStart.includes(currentIndex) 
    && !edgeIndexesStart.includes(currentIndex - 1) 
    && !edgeIndexesStart.includes(currentIndex - 2)
    ){
        dividersAfter.push(2)
    }

    if(
        !edgeIndexesEnd.includes(currentIndex)
        && !edgeIndexesEnd.includes(currentIndex + 1) 
        && !edgeIndexesEnd.includes( currentIndex + 2) ){
        dividersAfter.push(currentIndex + 1)
    }

    const showIndexes = [
        ...new Set(
            [
                ...edgeIndexesStart, 
                ...edgeIndexesEnd, 
                ...currentIndexRange
            ]
        )
    ]


    if(totalSize <= itemsPerPage){
        return <React.Fragment />
    }

    return (
        <StyledPagination>
            {
                isFirstPage && <StyledPlaceholder />
            }
            {
                !isFirstPage && <IconLink
                    to = { buildQueryString(linkSkipLeft.params) }
                    className='link-icon-arrow-left-2345'
                    iconKey="first_page"
                    cssHeight='30px'
                    onLinkClick={() => {onPaginationChange(0, limit)}}
                />
            }
            {
                links.map(
                    (link, i) => {
                    
                        const queryString = buildQueryString(link.params)
                        const currentOffSet = link.params.find(p => p.key === params.offset).value
                    
                        if(!showIndexes.includes(i)){
                            return <React.Fragment key={i} />
                        }

                        return <React.Fragment key={i}>
                            <StyledLink 
                                to={`${match.url}${queryString}`} 
                                active={link.isActive ? 'active' : ''}
                                onClick={
                                    () => {
                                        onPaginationChange(currentOffSet, limit)
                                    }
                                }
                            > 
                                {i + 1} 
                            </StyledLink>
                            {
                                dividersAfter.includes(i) &&  <Divider />
                            }
                        </React.Fragment>
                    })
            }            
            {
                !isLastPage && <IconLink
                    to = { buildQueryString(linkSkipRight.params) }
                    className='link-icon-arrow-right-2345'
                    iconKey="last_page"
                    cssHeight='30px'
                    textAlign='right'
                    onLinkClick={
                        () => {
                            onPaginationChange(
                                linkSkipRight.params.find(
                                    p => p.key === params.offset
                                ).value,
                                limit
                            )
                        }
                    }
                />
            }
        </StyledPagination>
    )
}

RoutedPagination.propTypes = {
    parameterNames: PropTypes.object,
    totalSize: PropTypes.number, 
    itemsPerPage: PropTypes.number.isRequired,
    match: PropTypes.object,
    onPaginationChange: PropTypes.func
}


export default withRouter(RoutedPagination)