import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { populateSharedLawfirm } from 'application/redux/actions/pages/damage_manager'

export function* dialogCreateLawyersSaga(action) {
    const { damageId } = action.metaData.match.params
    yield put(showWaitingScreen('Assigning lawyer'))
    yield resourceCall(
        api.gateway.damage.updateDamageLawfirm, 
        {
            damageId:  damageId
        },
        {
            name: action.payload.name,
            webakteId: action.payload.id
        }
    )
    
    yield put(populateSharedLawfirm(true))
    yield put(showToast(action.metaData.successMsg))
    yield put(hideWaitingScreen())
}