import { clearUsersList, getUsersRequestDebounced } from 'application/redux/actions/common'
import { updateDamageAssignee } from 'application/redux/actions/pages/damage_manager/shared'
import { connect } from 'react-redux'
import DamageAssigneeSelect from './component'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'

const mapStateToProps = (state) => {
    const { shared } = state.pages.damageManager
    return {
        damage: shared.damage,
        damageLoaded: shared.damageLoaded,
        usersList: state.common.usersList,
        usersListLoading: state.common.usersListLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSearchUser: (search) => dispatch(
            getUsersRequestDebounced(0, 10, new ListFilter(filters.users.name).setValue(search).getQuery())
        ),
        onAssignUser: (
            damageId, 
            userId
        ) => {
            dispatch(clearUsersList())
            dispatch(
                updateDamageAssignee(
                    damageId, 
                    userId
                )
            )
        } 
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageAssigneeSelect)