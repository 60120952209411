import { put, select } from 'redux-saga/effects'
import { getCurrentBranchId } from 'application/redux/selectors'
import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { branchAddressDataMapper, branchDataMapper, branchContactDataMapper } from 'application/api/data_mappers/export/branches'
import { resetAccessibleBranches, updateBranchInList } from 'application/redux/actions/common/branches'
import { toggleBranchSidebar } from 'application/redux/actions/pages/branch_management'
import { populateCurrentBranch } from 'application/redux/actions/auth'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'



export function* editBranchSaga(action) {
    yield put(
        showWaitingScreen('Saving branch')
    )
    const currentBranchId = yield select(
        getCurrentBranchId
    )
    const payload = branchDataMapper(
        action.payload
    )
    const branchId = action.metaData.branchId
    yield resourceCall(
        api.gateway.branches.putUpdateBranch, 
        { branchId }, 
        payload
    ) 

    const addressPayload = branchAddressDataMapper(action.payload)
    yield resourceCall(
        api.gateway.branches.putUpdateBranchAddress, 
        { branchId },
        addressPayload
    )

    const contactPayload = branchContactDataMapper(action.payload.contact)
    yield resourceCall(
        api.gateway.branches.putUpdateBranchContact, 
        { branchId }, 
        contactPayload
    )

    yield currentBranchId === branchId && put(
        populateCurrentBranch(
            {
                id: branchId,
                ...payload
            }
        )
    )
    yield put(
        toggleBranchSidebar(false)
    )
    yield put(
        resetAccessibleBranches()
    )
    yield put(
        updateBranchInList(
            action.metaData.branchId, 
            payload
        )
    )
    yield put(
        hideWaitingScreen()
    )
    yield put(
        showToast('Branch saved')
    )
}