import React from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextInput, TextArea, DatePicker } from 'application/components/controls/form'
import PrioritySelect from 'application/components/controls/form/priority_select'
import { Footer } from 'application/components/dialogs/_common/footer'
import { useStatefulForm } from 'application/components/hooks'
import TwoColumns from 'application/components/forms/_common'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'



const EditTaskWorkflowPanel = (
    {
        abort, 
        setAbortBlocked,
        setDataChanged, 
        onSaveTask, 
        dialog, 
        formData 
    }
) => {
    const damageIncidentDate = dialog.payload.damage?.incidentDate
    const { t } = useTranslation()
    const {
        register,
        errors, 
        handleSubmit, 
        getValues,
        setValue,
        control,
        updateFormState,
        formStateValues
    } = useStatefulForm({ defaultValues: formData })

    const { sharedResourceId, task } = dialog.payload

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
        updateFormState()
    }

    const onSubmitSuccess = () => {
        onSaveTask(dialog.name, sharedResourceId, task, {
            ...getValues()
        })
    }

    const onError = (errors, e) => console.log(errors, e)

    return <React.Fragment>
        <DialogMainContent>

            <TwoColumns 
                columnLeft={
                    <React.Fragment>
                        <TextInput
                            label={t('Title')}
                            name='title'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.title}
                            onChange={onDataChange}
                        />
                        <TextArea
                            label={t('Description')}
                            name='description'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.description}
                            onChange={onDataChange}
                        />
                    </React.Fragment>
                }

                columnRight={
                    <React.Fragment>
                        <DatePicker
                            label={t('Deadline')}
                            name='deadline'
                            validate={null}
                            error={errors.deadline}
                            onDateChange={onDataChange}
                            control={control}
                            disabledDays={{before: new Date(damageIncidentDate)}}
                        />
                        <PrioritySelect
                            register={register}
                            setValue={setValue}
                            error={errors.damageTyp}
                            onChange={onDataChange}
                            initialValue={formStateValues.priority}
                        />
                    </React.Fragment>
                }
            />

        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess, onError)}
            submitText='Edit task'
        />
    </React.Fragment>

}

export default withDialogDataLoader(EditTaskWorkflowPanel) 