import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import RepairClearanceName from 'application/components/name_mappings/repair_clearance'
import { REPAIR_AUTHORIZATION_TYPE } from 'application/constants'

const RepairClearanceTypeSelect = ({
    name = 'repairAuthorization',
    register,
    setValue,
    validate = null,
    initialValue,
    error,
    menuPlacement = 'bottom',
    onChange
}) => {
    const { t } = useTranslation()
    const repairClearanceTypeOptions = [
        {
            value: REPAIR_AUTHORIZATION_TYPE.NOT_SET,
            label: <RepairClearanceName repairClearanceType={REPAIR_AUTHORIZATION_TYPE.NOT_SET} />
        },
        {
            value: REPAIR_AUTHORIZATION_TYPE.GRANTED,
            label: <RepairClearanceName repairClearanceType={REPAIR_AUTHORIZATION_TYPE.GRANTED} />
        },
        {
            value: REPAIR_AUTHORIZATION_TYPE.REFUSED,
            label: <RepairClearanceName repairClearanceType={REPAIR_AUTHORIZATION_TYPE.REFUSED} />
        },
    ]

    const initialOption = repairClearanceTypeOptions.find(o => o.value === initialValue)

    return <Select
        label={t('Repair clearance type')}
        error={error}
        name={name}
        register={register}
        setValue={setValue}
        options={repairClearanceTypeOptions}
        defaultValue={initialOption}
        validate={validate} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
    />
}

RepairClearanceTypeSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}

export default RepairClearanceTypeSelect