import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { WIDGET_CONTEXTS } from 'application/constants'
import DamageManagerOverviewSidebar from './component'

const DamageManagerOverviewSidebarEffects = (props) => {
    const { onRefreshWidgetContext, match } = props
    const { damageId } = match.params

    useEffect(() => {
        onRefreshWidgetContext(WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW_SIDEBAR)
    }, [onRefreshWidgetContext, damageId])

    return <DamageManagerOverviewSidebar {...props} />
}

DamageManagerOverviewSidebarEffects.propTypes = {
    onRefreshWidgetContext: PropTypes.func.isRequired
}

export default withRouter(DamageManagerOverviewSidebarEffects)