import React, {useState} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { validationMethods } from 'application/common'
import { setQuery, useQuery } from 'application/components/hooks/use_query'
import { Spacer } from 'application/components/pages/_common'
import { DatePicker } from 'application/components/controls/form'
import { dateTimeHelpers } from 'application/common'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { STATISTICS } from 'application/constants'
import { timerangeConverter } from 'application/common/date_time_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Label } from 'application/components/fragments/typography'
import IconButton from 'application/components/controls/icon_button'
import { exists } from 'application/common/data_helpers'
import InfoBox from 'application/components/controls/info_box'


export default ({
    routeParameters, 
    onChange = ()=>{}, 
    maxPeriod = STATISTICS.MAXIMUM_STATISTICS_RANGE, 
    label = 'Show created damages from',
    keepQueryOnChange = true
}) => {
    const [isValid, setIsValid] = useState(true)
    const [isBefore, setIsBefore] = useState(true)
    const {t} = useTranslation()
    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const { FILTERS, OFFSET, LIMIT} = routeParameters
    const { TIME_RANGE } = FILTERS

    const today = new Date()
    const timerange = query.getStringParam(TIME_RANGE)

    const { startDate, endDate } = timerangeConverter.getDateTimesFromRange(timerange)

    const defaultValues = {
        startDate,
        endDate
    }
    
    const {errors, control, getValues, setValue} = useForm({ defaultValues })

    const handleChange = () => {
        const {startDate, endDate} = getValues()
        const before = dateTimeHelpers.dateIsBefore(startDate, endDate)
        const inRange = maxPeriod === null || dateTimeHelpers.datesRange(startDate, endDate) <= maxPeriod

        setIsValid(before && inRange)
        setIsBefore(before)

        if(before && inRange){
            setQuery(history, location, location.pathname, 
                [
                    {
                        key: TIME_RANGE,
                        value: timerangeConverter.getRangeFromDateTimes(startDate, endDate)
                    }
                ], 
                keepQueryOnChange,
                [
                    OFFSET,
                    LIMIT
                ]
            )
            onChange(startDate, endDate)
        }
    }
 
    const resetFilter = () => {
        setQuery(history, location, location.pathname, 
            [], 
            keepQueryOnChange,
            [
                OFFSET,
                LIMIT,
                TIME_RANGE
            ]
        )
        setValue('startDate', null)
        setValue('endDate', null)
        onChange(null, null)
    }

    return <FlexBox 
        flexDirection='row'
        alignItems='baseline'
    >
        <Label cssMargin='0 16px 0 0'>
            {t(label)}
        </Label>

        <FlexBox flexBasis='441px'>
            <DatePicker 
                name='startDate'
                validate={{notEmpty: validationMethods.notEmpty}}
                error={errors.startDate}
                control={control}
                onDateChange={handleChange}
                isClearable={false}
                disabledDays={{after: today}}
            />
        </FlexBox>
        <FlexBox flexBasis='441px'>
            <DatePicker 
                name='endDate'
                validate={{notEmpty: validationMethods.notEmpty}}
                error={errors.endDate}
                control={control}
                onDateChange={handleChange}
                isClearable={false}
                disabledDays={{after: today}}
            />
        </FlexBox>
        <FlexBox 
            flexBasis='100%'
            paddingLeft='6px'
        >
            {
                !isValid &&  <WidgetErrorMessage cssPadding='10px'>
                    {
                        !isBefore
                            ? t('Start date must be before end date')
                            : <Trans i18nKey='Period must be smaller'>
                                    Period must be smaller than {{maxPeriod}} days
                            </Trans>
                    }
                </WidgetErrorMessage>
            }
            {
                exists(timerange) && <InfoBox direction='row' cssMargin='0'>
                    <span>{t('Timerange filter active')}</span>
                        
                    <Spacer />
                    <IconButton 
                        iconKey='close' 
                        width='12px' 
                        height='16px' 
                        onButtonClick={resetFilter} 
                    />
                </InfoBox>
            }
        </FlexBox>
    </FlexBox>
    
}