import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../_common/commission_fragments'
import { 
    WidgetSubHeadline, 
    WidgetErrorMessage } from 'application/components/widgets/_common'
import { FontIcon } from '@ec/ui-controls-react'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { withRouter } from 'react-router-dom'
import { INFOBOX, COMMISSION_STATE } from 'application/constants'
import { Spacer } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'
import { FlexBox } from 'application/components/fragments/flex_box'
import { CommissionDekraExpertButton } from './commission_dekra_expert_button'
import { exists } from 'application/common/data_helpers'
import { useState } from 'react'






const CommissionExpertWidgetComponent = (
    { 
        widgetKey, 
        match, 
        payload, 
        loadingFailed, 
        openDialogCommissionDekraExpert,
        openDialogCommissionOtherExpert
    }
) => {
    const {expert, expertFound} = payload

    const { t } = useTranslation()
    const {damageId} = match.params
    const [showStructuredCreation, setShowStructuredCreation] = useState(false)

    if(loadingFailed){
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    const handleOpenDialogCommissionDekraExpert = () => {
        openDialogCommissionDekraExpert(damageId)
    }

    const handleOpenDialogCommissionOtherExpert = () => {
        openDialogCommissionOtherExpert(damageId)
    }

    return <React.Fragment>
        <FlexBox 
            justifyContent='space-between'
            alignItems='center'
            flexGrow={'1'}
        >
            <WidgetSubHeadline>{t('Commissioned expert')}</WidgetSubHeadline>
        </FlexBox>
        <FlexBox flexDirection='column'>
            {
                expertFound 
                    ? <Fragments.ParticipantsWrapper>
                        <Fragments.StyleButtonToggle>
                            <Fragments.ParticipantsName>
                                {
                                    !exists(expert.email)
                                        ? <React.Fragment>
                                            {expert.name}
                                        </React.Fragment>
                                        : <React.Fragment>
                                            {expert.name} {'<'}{expert.email}{'>'}
                                        </React.Fragment>
                                }
                            </Fragments.ParticipantsName>
                        </Fragments.StyleButtonToggle>
                        {
                            expert.state === COMMISSION_STATE.FAILED 
                            && <InfoBox type={INFOBOX.TYPES.ALERT}
                                direction='row' 
                                cssMargin='0'
                            >
                                <FontIcon icon='info_outline' />
                                <Spacer basis='10px' />
                                <span>{t('Commissioning failed')}</span>
                                <Spacer />
                            </InfoBox>
                        }                       
                    </Fragments.ParticipantsWrapper>
                    : <React.Fragment>
                        {
                            !showStructuredCreation && <IconButtonWithLabel
                                iconKey='add'
                                label={t('Structured assignment')}
                                onButtonClick={() => setShowStructuredCreation(true)}
                                cssPadding='0'
                            />
                        }
                        {
                            showStructuredCreation && <CommissionDekraExpertButton
                                onButtonClick={handleOpenDialogCommissionDekraExpert}
                                cssMargin='0 0 8px 0'
                            />
                        }
                        <IconButtonWithLabel
                            iconKey='add'
                            label={t('Simple assignment')}
                            onButtonClick={handleOpenDialogCommissionOtherExpert}
                            cssPadding='0'
                        />
                    </React.Fragment>
            }    
        </FlexBox>
    </React.Fragment>
}


CommissionExpertWidgetComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export const CommissionExpert = withRouter(CommissionExpertWidgetComponent)

export default CommissionExpert