import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const getLawfirm = (params) => ({
    authenticated: () => {
        const request = fetchRequestBuilder(`damages/${params.damageId}/communications/lawfirm`, HTTP_METHOD.GET)
        return apiCall(request)
    },
    sharedResource: () => null,
    qrpoc: () => {
        const request = fetchRequestBuilderPin(`external/${params.sharedResourceType}/${params.sharedResourceId}/account/damages/${params.damageId}/communications/lawfirm`, params.pin, HTTP_METHOD.GET)
        return apiCall(request)
    }
})