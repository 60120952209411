import React from 'react'
import { withRouter } from 'react-router'
import { SHARED_RESOURCE_TYPE } from 'application/constants'
import { LayoutSharedResource } from 'application/components/pages/_layout'
import { PageContainer, PreloaderContainer } from './sub_components/fragments'
import { ExtCreateDamageForm } from './sub_components/form'
import { ExtCreateDamageSuccess } from './sub_components/success'
import { ExtCreateDamageHeader } from './sub_components/header'
import Preloader from 'application/components/controls/themed_preloader'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withSharedResourceValidation } from 'application/components/higher_order_components/shared_resource_validation'

export const CreateDamageExternal = withSharedResourceValidation()(withRouter((
    {        
        sharedResource,
        onCreateDamage,
        damageDataSent, 
        damageDataSending, 
        onSendDocuments,
        damageId, 
        documentsSent,
        documentsSending
    }
) => {
    return <LayoutSharedResource
        sharedResourceType={SHARED_RESOURCE_TYPE.QR}
    >        
        <PageContainer>
            <ExtCreateDamageHeader />
            {
                !damageDataSending && !damageDataSent && <ExtCreateDamageForm 
                    onCreateDamage={onCreateDamage} 
                />
            }

            {
                damageDataSending && <FlexBox>
                    <PreloaderContainer>
                        <Preloader preloaderText='Sende Schadenmeldung' />
                    </PreloaderContainer>
                </FlexBox>
            }
            {
                damageDataSent && <ExtCreateDamageSuccess 
                    onSendDocuments={onSendDocuments} 
                    damageId={damageId} 
                    documentsSent={documentsSent}
                    documentsSending={documentsSending}
                />
            }
        </PageContainer>
    </LayoutSharedResource>
}))