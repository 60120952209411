import {
    StringEqualsFilter,
    GreaterOrEqualThanFilter,
    LesserOrEqualThanFilter,
    AndOperator
} from '@ec/filter-sort-page-query-js'

export const controlling = {
    vacantPositionsByType: {
        filterName: 'controlling_vacantPositionsByType',
        filterKey: 'cvpbt',
        label: 'Vacant Position',
        defaultValue: null,
        getFilterQuery: (value) => ([
            new StringEqualsFilter('type', value, true)
        ])
    },
    vacantPositionsByComprehensiveDamageType: {
        filterName: 'controlling_vacantPositionsByComprehensiveDamageType',
        filterKey: 'cvpbcdt',
        label: 'Vacant Position',
        defaultValue: null,
        getFilterQuery: (value) => ([ //value is id of the account specific comprehensive damage types
            new StringEqualsFilter('comprehensiveType.id', value, true)
        ])
    },
    damageCreationCount: {
        filterName: 'controlling_damageCreationCount',
        filterKey: 'cdcc',
        label: 'Created damages',
        defaultValue: null,
        getFilterQuery: (value) => {
            const { startDate, endDate } = value
            return [
                new GreaterOrEqualThanFilter('startDate', startDate, false),
                new AndOperator(),
                new LesserOrEqualThanFilter('endDate', endDate, false)
            ]
        }
    }
}