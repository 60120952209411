import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { Paragraph } from 'application/components/fragments/typography'
import Preloader from 'application/components/controls/themed_preloader'

const StyledWaitingScreen = styled.div`
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255,255,255,0.7);
    box-sizing: border-box;
    overflow: hidden;
    padding: 40px;
    z-index: 7000;
`

export const HeadlineWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const Headline = styled.span`
  font-size: ${props => props.theme.fontSize.headline1};
  font-weight: ${props => props.theme.fontWeight.headLine1};
  margin-left: 8px;
  position: relative;
  top: 4px;
`

const WaitingScreen = ({
    waitingScreen,
    dialogsOpen
})=> {  
    const { t } = useTranslation()
    if(!waitingScreen.active || (dialogsOpen && waitingScreen.inDialog)) return null

    return <StyledWaitingScreen>
        <HeadlineWrapper>
            <Preloader />
            <Headline>
                {
                    i18n.exists(waitingScreen.message)
                        ? t(waitingScreen.message)
                        : waitingScreen.message
                }
            </Headline>
        </HeadlineWrapper>
    
        <Paragraph />
        {
            waitingScreen.subMessages.map((message, i) => {
                return <Paragraph key={i} cssPadding='0 0 0 34px'>
                    {
                        i18n.exists(message)
                            ? t(message)
                            : message
                    }
                </Paragraph>
            })
        }
    </StyledWaitingScreen>
}

export default WaitingScreen