import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import DeleteTaskWorkflowPanelComponent from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onDeleteTaskWorkflowPanel: (name, taskId, successMsg) => {
            dispatch(sendPayloadRequest(name, { taskId, successMsg } ))
        },
    }
}

export const DeleteTaskWorkflowPanel =  connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteTaskWorkflowPanelComponent)