import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.pages.workflowPanel.inbox, action) => {
    switch (action.type) {
    case ACTIONS.INBOX_GET_MESSAGE_BY_ID: return {
        ...state,
        details: null,
        detailsLoaded: false,
    }
    case ACTIONS.INBOX_RESET_MESSAGE_DETAILS: return {
        ...state,
        details: null,
        detailsLoaded: false,
    }
    case ACTIONS.INBOX_POPULATE_MESSAGE_DETAILS: return {
        ...state,
        details: action.messageDetails,
        detailsLoaded: true,
    }
    case ACTIONS.INBOX_MESSAGE_NOT_FOUND: return {
        ...state,
        messageLoadedAndNotFound: true
    }
    default:
        return state
    }
}
