import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, Cell, Group } from 'application/components/pages/internal/_title_bars/_common'
import SearchComboBox from 'application/components/controls/search_combo_box'
import RealtimeNotificationsIndicator from 'application/components/building_blocks/realtime_notifications_indicator'
import  UserMenu  from 'application/components/building_blocks/user_menu'
import * as filters from 'application/filters'

import { USER_PERMISSONS, IMAGES } from 'application/constants'
import { ForCurrentBranchPermissions, ForAnyBranchPermissions } from 'application/components/context/permissions'
import Image from 'application/components/controls/image'
import { DekraButton } from './dekra_button'
import ActionButton from 'application/components/controls/action_button'

const TitleBar = (
    { 
        onCreateDamage,  
        onCreateDamageDekra,
        onCreateDamageQuickStart,
        currentAccount, 
        onConfigureDashboard,
        hasAccount
    }
) => {
    const {t} = useTranslation()
    
    const accountImage = useMemo(() => {
        if(currentAccount.name.toLowerCase().includes('egger')){
            return IMAGES.ACCOUNT_EGGER
        }
        if(currentAccount.name.toLowerCase().includes('könig')){
            return IMAGES.ACCOUNT_KOENIG

        }
        if(currentAccount.name.toLowerCase().includes('nahetal')){
            return IMAGES.ACCOUNT_NAHETAL

        }
        return IMAGES.ACCOUNT_ZYRULL
    }, [currentAccount.name])

    return (
        <Bar>
            <Group>
                <RealtimeNotificationsIndicator />
                <Cell width='142px'>
                    <Image data={accountImage} />
                </Cell>
            </Group>


            <Group>
                <Cell>
                    <ForCurrentBranchPermissions
                        permissions={[
                            USER_PERMISSONS.DAMAGE.CREATE, 
                            USER_PERMISSONS.CUSTOMER.CREATE, 
                            USER_PERMISSONS.CUSTOMER.READ
                        ]}
                        allNeeded={true} 
                    >
                        <DekraButton  
                            onButtonClick={onCreateDamageDekra}
                            buttonText={t('Damage')} 
                            cssMargin='0 8px 0 0'
                        />
                        <ActionButton 
                            marginRight='8px' 
                            onButtonClick={onCreateDamageQuickStart} 
                            buttonText={t('Quick start')} 
                            icon='auto_fix_normal' 
                            buttonClassName='jestCreateDamageQuickStartButton'  
                        />
                    
                        <ActionButton 
                            onButtonClick={onCreateDamage} 
                            buttonText={t('Damage')} 
                            icon='add' 
                            className='jestCreateDamageButton'   
                        />
                    </ForCurrentBranchPermissions>
                </Cell>
                <Cell width='400px'>
                    <ForAnyBranchPermissions
                        permissions={[
                            USER_PERMISSONS.DAMAGE.READ
                        ]}
                    >
                        <SearchComboBox
                            filters={[
                                filters.damages.vehicleLicensePlate,
                                filters.damages.jobNumber,
                                filters.damages.claimNumber,
                                filters.damages.customerName
                            ]}
                        />
                    </ForAnyBranchPermissions>
                </Cell>
            </Group>
            
            
            <Group>
                <Cell>
                    <UserMenu image={IMAGES.USER_KE}/>  
                </Cell>  
            </Group>
        </Bar>
    )
}


export default TitleBar