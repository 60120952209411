import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { call, takeLatest, put } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { connectUserCallback } from 'application/redux/actions/registration'

function* registrationConnectUserSaga(action) {
    try {
        yield call(api.gateway.registration.connectUser, action.sharedResourceId, action.identityToken)
        yield put(connectUserCallback(action.ssoAccessToken))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* registrationConnectUserWatcher() {
    yield takeLatest(ACTIONS.REGISTRATION_CONNECT_USER_REQUEST, registrationConnectUserSaga)
}