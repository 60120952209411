import { connect } from 'react-redux'

import DamageManagerInboxTabbarButtons from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => {
    return {
        damageLoaded: state.pages.damageManager.shared.damageLoaded,
        damage: state.pages.damageManager.shared.damage,
        hasLawfirm: state.pages.damageManager.shared.hasLawfirm
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateMessage: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_MESSAGE_FOR_LAWFIRM,
                true,
                payload,
                'New message for lawfirm'
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerInboxTabbarButtons)