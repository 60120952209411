import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import TaskDetailsWorkflowPanel from './component'

const TaskDetailsWorkflowPanelEffects = (props) => {
    const { onLoadTask, selectedTaskLoading, selectedTaskLoaded, task, hasTaskList, contextLoaded, onRegisterWidgetContext, match } = props
    const { sharedResourceId, taskId } = match.params
    const currentTaskId = task !== null ? task.id : null
    
    const widgetList = task?.formConfiguration?.components !== undefined ? task.formConfiguration.components.sort((a, b) => a.index - b.index).map(w=>({
        ...w,
        key: `${w.type}_${task.id}`,
        title: `${w.type}_${task.id}`, //empty later
        hideHeadline: true,
        initialPayload: {
            taskId: task.id,
            sharedResourceId: sharedResourceId,
            damageId: task.damageId,
        }
    })) : []

    // useEffect(() => {
    //     taskLoadedAndNotFound && onTaskNotFound()
    // }, [
    //     taskLoadedAndNotFound,
    //     onTaskNotFound
    // ])

    useEffect(() => {
        taskId && (!selectedTaskLoaded || taskId !== currentTaskId) && !selectedTaskLoading && taskId !== '' && hasTaskList && onLoadTask(taskId, sharedResourceId)
    }, [hasTaskList, match.path, onLoadTask, sharedResourceId, selectedTaskLoaded, selectedTaskLoading, taskId, currentTaskId])

    useEffect(() => {
        selectedTaskLoaded && !contextLoaded && taskId !== '' && onRegisterWidgetContext(taskId, widgetList) //just use the taskId as context
    }, [contextLoaded, onRegisterWidgetContext, selectedTaskLoaded, taskId, widgetList])

    return <TaskDetailsWorkflowPanel {...props} taskId={taskId} sharedResourceId={sharedResourceId}/>
}

export default withRouter(TaskDetailsWorkflowPanelEffects)