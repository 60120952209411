import React from 'react'
import { connect } from 'react-redux'

import DamageStatusSelect from './component'

const mapStateToProps = (state) => {
    const { shared } = state.pages.damageManager
    const { statusList } = state.common


    return {
        statusList: statusList.data,
        statusListLoaded: statusList.loaded,
        damage: shared.damage,
        damageLoaded: shared.damageLoaded,
    }
}

const Wrapper = (props) => props.damage.state !== undefined 
    ? <DamageStatusSelect {...props} /> 
    : null

export default connect(
    mapStateToProps
)(Wrapper)