import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledIconButtonWithLabel = styled.div`
    flex: 0 0 auto;    
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${props => props.cssUnderlined ? props.theme.color.gray70 : props.theme.color.transparent};
    padding: ${props => props.cssPadding};
    color: ${props => props.theme.color.gray70};
    :hover {
        background: ${props => props.hoverBackground ? props.hoverBackground : props.theme.color.transparent};
        color: ${props => props.theme.color.black};
        &>i{
            color: ${props => props.theme.color.black};
        }
    }
    
    :active {
        background: ${props => props.activeBackground ? props.activeBackground : props.theme.color.transparent};
        color: ${props => props.theme.color.black}
    }

    &>i{
        align-self: center;
        font-size: ${props => props.iconSize !== '' ? props.iconSize : props.theme.fontSize.medium};
        color: ${props => props.theme.color.gray70};
    }
    &>span{
        align-self: center;
        font-size: ${ props => props.fontSize !== '' ? props.fontSize : props.theme.fontSize.standard};
        padding-left: ${ props => props.paddingLeft !== '' ? props.paddingLeft : '8px'};
    }
    cursor: ${props => !props.cssDisabled ? 'pointer' : 'default'};
    opacity: ${props => !props.cssDisabled ? '1' : '0.3'}; 
    font-weight: ${props => props.theme.fontWeight.normal};
`

const IconButtonWithLabel = (
    { 
        className = '', 
        cssPadding='2px 8px', 
        onButtonClick, 
        iconKey, 
        label = '', 
        underlined = false, 
        disabled = false, 
        fontSize = '', 
        iconSize = '', 
        paddingLeft = '', 
        hoverBackground = '', 
        activeBackground = '', 
        priority, 
        title='' 
    }
) => {

    return <StyledIconButtonWithLabel 
        cssPadding={cssPadding} 
        title={title} 
        className={className} 
        cssUnderlined={underlined} 
        onClick={ !disabled && typeof onButtonClick !== 'undefined' ? onButtonClick : () => {}} 
        cssDisabled={disabled} 
        fontSize={fontSize} 
        iconSize={iconSize} 
        paddingLeft={paddingLeft} 
        hoverBackground={hoverBackground} 
        activeBackground={activeBackground} 
        priority={priority}
    >
        <i className="material-icons">{iconKey}</i>
        {
            label !== '' && <span>{label}</span>
        }
    </StyledIconButtonWithLabel>
}

IconButtonWithLabel.propTypes = {
    className: PropTypes.string,
    onButtonClick: PropTypes.func,
    iconKey: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    underlined: PropTypes.bool,
    disabled: PropTypes.bool,
    fontSize: PropTypes.string,
    iconSize: PropTypes.string,
    paddingLeft: PropTypes.string,
    hoverBackground: PropTypes.string,
    activeBackground: PropTypes.string
}

export default IconButtonWithLabel