import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useStatefulForm } from 'application/components/hooks'
import { PartialForms } from 'application/components/forms'
import { isDefined, notNull } from 'application/common/data_helpers'
import { useState } from 'react'
import {liabilityDataStructure} from 'application/components/forms/_configurations'



const DamageLiabilityInsuranceDataForm = (
    {
        formData = {}, 
        damageId, 
        onSave
    }
) => {
    const {t} = useTranslation()

    const {
        emptyValues,
        mapDefaultValues,
        mapFormValuesToLiabilityPayload
    } = liabilityDataStructure

    const defaultValues = mapDefaultValues(formData)
    const liabilityValidationHook = useStatefulForm({defaultValues})
    const noInsuranceGiven = !isDefined(formData.insurance)
    const initialInsuranceIsFromList = isDefined(formData.insurance?.company?.id) && notNull(formData.insurance.company.id)
    const [insuranceSelectionFromList, setInsuranceSelectionFromList] = useState(initialInsuranceIsFromList || noInsuranceGiven)

    const resetForm = () => {
        liabilityValidationHook.reset(emptyValues)
        liabilityValidationHook.setFormStateValues(emptyValues)
    }

    const onSubmitSuccess = () => { 
        const payload =  mapFormValuesToLiabilityPayload(liabilityValidationHook.getValues())   
        const metaData = {damageId}
        onSave(
            payload,
            metaData
        )
    }

    return <React.Fragment>
        <PartialForms.LiabilitaInsurancePartialForm
            stateFulFormHook={liabilityValidationHook}
            setInsuranceSelectionFromList={setInsuranceSelectionFromList}
            insuranceSelectionFromList={insuranceSelectionFromList}
            resetForm={resetForm}
        />
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save liability insurance data')} 
                onButtonClick={
                    liabilityValidationHook.handleSubmit(
                        onSubmitSuccess, (e) => {
                            console.log('e', e)
                        }
                    )
                } />
        </FlexBox>
    </React.Fragment>
}

export { DamageLiabilityInsuranceDataForm }