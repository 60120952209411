import React from 'react'
// import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { WIDGET_CONTEXTS } from 'application/constants'
import WidgetArea from 'application/components/building_blocks/widget_area'
import { LayoutCurrentBranch } from 'application/components/pages/_layout'


const Inbox = ({
    match, 
 
}) => {
    return <LayoutCurrentBranch
        titleSegments={['Inbox']}
    >
        <WidgetArea 
            widgetContext={WIDGET_CONTEXTS.BRANCH_SPECIFIC_INBOX} 
            direction='row' 
        />
    </LayoutCurrentBranch>
}

Inbox.propTypes = {

}

export default withRouter(Inbox)