import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import { Paragraph } from 'application/components/fragments/typography'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label'

const StyledFallbackViewer = styled.div`
    width: auto;
    margin: auto;
    max-width: 90%;
`

const StyledSpan = styled.span`
    display: inline-block;
    width: auto;
`

const FallbackViewerComponent = ({fileId, damageId, fileName, onAttachmentDownload, theme}) => {
    const { t } = useTranslation()
    return <StyledFallbackViewer className='jestFallbackAttachmentViewer'>
        <Paragraph cssAlign='center'>
            {t('No preview possible for')}:
        </Paragraph>
        <Paragraph cssAlign='center'>
            <strong>{fileName}</strong>
        </Paragraph>
        <Paragraph cssAlign='center'>
            <StyledSpan>
                <IconButtonWithLabel fontSize={theme.fontSize.headLine2} iconKey='save_alt' label={t('Download file')} onButtonClick={()=>{onAttachmentDownload(fileId, damageId, fileName)}} />
            </StyledSpan>
        </Paragraph>
    </StyledFallbackViewer>
}

FallbackViewerComponent.propTypes = {
    fileType: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    onAttachmentDownload: PropTypes.func.isRequired,
    errorComponent: PropTypes.element,
}

export default withTheme(FallbackViewerComponent)