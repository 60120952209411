import React from 'react'
import { withTheme } from 'styled-components'
import { PushBottom8, PushBottom32, PushBottom16 } from 'application/components/pages/_common'
import Letter from 'application/components/controls/letter'
import { InvoiceDocuments, InvoiceInfo, InvoiceItems } from 'application/components/building_blocks/invoice_meta'
import { FlexBox } from 'application/components/fragments/flex_box'
import { exists } from 'application/common/data_helpers'

const Invoice = ({
    invoiceData,
    children
}) => {
    return <Letter>
        <FlexBox flexDirection='column'>
            <InvoiceInfo invoiceData={invoiceData} />
            {
                exists(invoiceData.documents) && <React.Fragment>
                    <PushBottom16 />
                    <InvoiceDocuments invoiceData={invoiceData} />
                </React.Fragment>
            }
            <PushBottom32 />
            <InvoiceItems
                invoiceData={invoiceData}
                payments={invoiceData.payments}
            />
            {
                children
            }
        </FlexBox>
        <PushBottom8 />
    </Letter>
}

export default withTheme(Invoice)