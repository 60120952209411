import React, { useEffect } from 'react'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'
import { Redirect, withRouter } from 'react-router-dom'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { ROUTES } from 'application/constants'
import { exists } from 'application/common/data_helpers'


export const PreferredAccountPreloaderComponent =  withRouter(
    (
        {
            preferredAccount,
            onLoadPreferredAccount
        }
    ) => {
        const isLocked = exists(preferredAccount.data) && (preferredAccount.data.isLocked || preferredAccount.data.user.isLocked)

        useEffect(
            () => {
                !preferredAccount.loaded
                && !preferredAccount.found
            && onLoadPreferredAccount()
            }, [
                onLoadPreferredAccount, 
                preferredAccount.found, 
                preferredAccount.loaded
            ]
        )

        if( 
            preferredAccount.loaded
            && preferredAccount.found
            && !isLocked
        ){
            return <Redirect 
                to={
                    getAccountLinkHref(
                        {
                            params:{
                                accountId: preferredAccount.data.id
                            }
                        }, 
                        ROUTES.ACCOUNT_PRELOADER
                    )
                } 
            />
        }

        if( 
            preferredAccount.loaded
            && (
                !preferredAccount.found
                || isLocked
            )
        ){
            return <Redirect
                to={ROUTES.CHOOSE_ACCOUNT} 
            />
        }

        return <PagePreloader text='loading company data' />
    }
)