import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { TextInput, CheckboxWithLabel} from 'application/components/controls/form'
import TwoColumns from 'application/components/forms/_common'
import PropTypes from 'prop-types'


const GeoCodingComponent = ( 
    {
        setValue,
        register,
        useGeoCoding,
        defaultLatitude,
        defaultLongitude,
        setUseGeoCoding = () => {},
        errors = {},
        latitude = '',
        longtitude = '',
        markerMoved = false
    }

) => {
    const {t} = useTranslation()

    
    const [stateCoordinates, setStateCoordinates] = useState(
        {
            lat: defaultLatitude, 
            lon: defaultLongitude
        }
    )

    useEffect(
        ()=>{
            setValue('latitude', stateCoordinates.lat)
            setValue('longitude', stateCoordinates.lon)
        }, [latitude, longtitude, setValue, stateCoordinates, stateCoordinates.lat, stateCoordinates.lon]
    )

    useEffect(
        ()=>{
            setUseGeoCoding(!markerMoved)
        }, [markerMoved, setUseGeoCoding]
    )

    const handleOnLongitudeChange = (e) => {
        setStateCoordinates(
            {
                ...stateCoordinates, 
                lon: e.target.value
            }
        )
    }

    const handleOnLatitudeChange = (e)=>{
        setStateCoordinates(
            {
                ...stateCoordinates, 
                lat: e.target.value
            }
        )
    }

    return <React.Fragment>
        <CheckboxWithLabel
            name='useGeoCoding'
            label={t('Determine coordinates automatically')}
            isRequired={false} 
            isSelected={useGeoCoding}
            onCheckboxChange={
                ()=>{
                    setUseGeoCoding(!useGeoCoding)
                }
            } 
        />
        <TwoColumns 
            columnLeft={
                <TextInput
                    label={t('Latitude')}
                    name='latitude'
                    register={register}
                    validate={
                        {
                            latitude: validationMethods.mustBeValidLatitude
                        }
                    }
                    error={errors.latitude}
                    readOnly={useGeoCoding}
                    onChange={handleOnLatitudeChange}
                />
            }
            columnRight={
                <TextInput
                    label={t('Longitude')}
                    name='longitude'
                    register={register}
                    validate={
                        {
                            longitude: validationMethods.mustBeValidLongitude
                        }
                    }
                    error={errors.longitude}
                    readOnly={useGeoCoding}
                    onChange={handleOnLongitudeChange}
                />
            }
        />
    </React.Fragment>
}

GeoCodingComponent.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    useGeoCoding: PropTypes.bool.isRequired,
    defaultLatitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    defaultLongitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    setUseGeoCoding: PropTypes.func,
    errors: PropTypes.object, 
    latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    longtitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    markerMoved: PropTypes.bool,
}

export  {GeoCodingComponent}