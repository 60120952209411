import React from 'react'
import InfoBox from 'application/components/controls/info_box'
import CustomerDisplay from 'application/components/building_blocks/customer_display'
import { INFOBOX } from 'application/constants'



const CustomerCard = (
    { 
        customer = null, 
        cssMargin='0', 
        infoBoxType = INFOBOX.TYPES.SECONDARY
    }
) => {
    if(customer === null){
        return null
    }
    return <InfoBox 
        type={infoBoxType} 
        cssMargin={cssMargin} 
        cssMinHeight='unset'
    >
        <CustomerDisplay customer={customer} />
    </InfoBox>
}

export default CustomerCard