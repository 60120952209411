import {mapDispatchToProps, mapStateToProps} from './container'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import {withExportToLawfirmConfirmationComponent} from './component'
import { connect } from 'react-redux'

export const withExportToLawfirmConfirmationDialog = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withExportToLawfirmConfirmationComponent
)