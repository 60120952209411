import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'
import { initialContextsBuilder } from './initial_contexts_builder.js'
import { getWidgetContextMeta, widgetLayoutStore, widgetListStore, widgetBoardConfigStore } from 'application/widget_configuration'

export default (state = {
    ...defaultStore.widgetContexts,
    contexts: initialContextsBuilder()
}, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return {
            ...defaultStore.widgetContexts,
            contexts: initialContextsBuilder()
        }
    }
    case ACTIONS.WIDGETCONTEXT_REGISTER_CONTEXT: 
        return {
            ...state,
            contexts: state.contexts.some(
                c => c.key === action.contextKey)
                ? state.contexts
                : [
                    ...state.contexts,
                    {
                        key: action.contextKey,
                        widgetList: action.widgetList
                    }
                ]
        }
    case ACTIONS.WIDGETCONTEXT_UPDATE_CONTEXT: 
        return {
            ...state,
            contexts: state.contexts.map( 
                c => c.key === action.contextKey 
                    ? {
                        ...c,
                        widgetLayout: action.widgetLayout,
                        widgetList: action.widgetList,
                        boardConfiguration: action.boardConfiguration
                    } 
                    : c
            )
        }
    case ACTIONS.WIDGETCONTEXT_UNREGISTER_CONTEXT:
        return {
            ...state,
            contexts: state.contexts.filter(
                c => c.key !== action.contextKey
            )
        }
    case ACTIONS.WIDGETCONTEXT_SAVE_WIDGET_LAYOUT: {
        widgetLayoutStore(action.contextKey)
            .store(action.widgetLayout)
        return {
            ...state,
            contexts: state.contexts.map( 
                c => c.key === action.contextKey 
                    ? {
                        ...c,
                        widgetLayout: action.widgetLayout
                    } 
                    : c
            )
        }
    }   
    case ACTIONS.WIDGETCONTEXT_SAVE_WIDGET_LIST: {
        widgetListStore(action.contextKey)
            .store(action.widgetList)

        return {
            ...state,
            contexts: state.contexts.map( 
                c => c.key === action.contextKey 
                    ? {
                        ...c,
                        widgetList: action.widgetList
                    } 
                    : c
            )
        }
    }   
    case ACTIONS.WIDGETCONTEXT_SAVE_WIDGET_BOARD_CONFIG: {
        widgetBoardConfigStore(action.contextKey)
            .store(action.boardConfiguration)

        return {
            ...state,
            contexts: state.contexts.map( 
                c => c.key === action.contextKey 
                    ? {
                        ...c,
                        boardConfiguration: {
                            ...c.boardConfiguration,
                            ...action.boardConfiguration
                        }
                    } 
                    : c
            )
        }
    }   
    case ACTIONS.WIDGETCONTEXT_SET_STATIC_MODE: {
        return {
            ...state,
            contexts: state.contexts.map( 
                c => c.key === action.contextKey 
                    ? {
                        ...c,
                        boardConfiguration: {
                            ...c.boardConfiguration,
                            isStatic: action.isStatic
                        }
                    } 
                    : c
            )
        }
    }  
    case ACTIONS.WIDGETCONTEXT_REMOVE_WIDGET: {
        return {
            ...state,
            contexts: state.contexts.map( 
                c => {
                    return c.key === action.contextKey 
                        ? {
                            ...c,
                            widgetList: c.widgetList.filter(
                                w => w.key !== action.key
                            )
                        } 
                        : c
                }
            )
        }
    }       
    case ACTIONS.WIDGETCONTEXT_ADD_WIDGET: {
        return {
            ...state,
            contexts: state.contexts.map( 
                c => {
                    return c.key === action.contextKey 
                        ? {
                            ...c,
                            widgetList: [
                                ...c.widgetList, 
                                {
                                    key: action.widgetKey,
                                    type: action.widgetType,
                                    permissions: action.permissions,
                                    title: action.title,
                                    initialPayload: action.initialPayload
                                }
                            ]
                        }
                        : c
                }
            )
        }
    }
    case ACTIONS.WIDGETCONTEXT_RESET_LAYOUT: {
        const {layout, list} = getWidgetContextMeta(action.contextKey).defaults
        if(layout === null || list.length === 0){
            return state
        }
        widgetLayoutStore(action.contextKey).store(layout)
        widgetListStore(action.contextKey).store(list)
        return {
            ...state,
            contexts: state.contexts.map( 
                c => c.key === action.contextKey
                    ? {
                        ...c,
                        widgetLayout: layout,
                        widgetList: list
                    } 
                    : c
            )
        }
    }
    default:
        return state
    }
}
