import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.pages.workflowPanel.documentCenter, action) => {
    switch (action.type) {
    case ACTIONS.WORKFLOW_PANEL_DOCUMENTS_GET_ALL_DOCUMENTS_REQUEST: {
        return {
            ...state,
            documents: [],
            documentsLoading: true,
            documentsLoaded: false,
            sharedResourceId: null,
        }
    }
    case ACTIONS.WORKFLOW_PANEL_DOCUMENTS_POPULATE_DOCUMENTS: {
        return {
            ...state,
            documents: action.documents,
            documentsLoading: false,
            documentsLoaded: true,
            sharedResourceId: action.sharedResourceId,
        }
    }
    case ACTIONS.WORKFLOW_PANEL_DOCUMENTS_SHOW_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                visible: true,
                attachment: {
                    id: action.attachmentId,
                    fileName: action.attachmentName,
                    isExportedToLawFirm: action.isExportedToLawFirm
                },
                sharedResourceId: action.sharedResourceId,
                taskId: action.taskId
            }
        }
    }
    case ACTIONS.WORKFLOW_PANEL_DOCUMENTS_HIDE_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                visible: false,
                attachmentId: null,
                attachmentName: '',
                sharedResourceId: null
            }
        }
    }
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_UPDATE_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                ...state.attachmentDetails,
                attachment: {
                    ...state.attachmentDetails.attachment,
                    ...action.payload
                }
            }
        }
    }  
    case ACTIONS.WORKFLOW_PANEL_DOCUMENTS_REMOVE_ATTACHMENT: {
        return {
            ...state,
            documents: state.documents.filter(d => d.id !== action.attachmentId)
        }
    } 
    case ACTIONS.WORKFLOW_PANEL_DOCUMENTS_SET_ARCHIVE_DOWNLOADING: {
        return {
            ...state,
            archiveDownloading: action.archiveDownloading
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_REQUEST_UPDATE_DOCUMENT: {
        return {
            ...state,
            documents: state.documents.map(document => 
                document.id === action.attachmentId
                    ? {
                        ...document,
                        ...action.payload
                    } 
                    : document
            )
        }
    }   
    default:
        return state
    }
}