import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import Collapsable from 'application/components/controls/collapsable'
import { DamageForms } from 'application/components/forms'




const TaskDamageBasicDataForm = (
    {
        payload, 
        loadingFailed, 
        onSave
    }
) => {

    const {t} = useTranslation()
    const { formData } = payload
    const { createdAt } = formData

    const handleOnSave = (id, values) => { 
        onSave(id, values)
    }

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }
    
    return <React.Fragment>
        <Collapsable 
            headline={t('Damage information')} 
            initiallyCollapsed={true}
        >
            <DamageForms.DamageBasicDamageInformationForm 
                onSave={handleOnSave}  
                damageId={formData.id}
                formData={formData}
                damageCreatedAt={createdAt}
            />
        </Collapsable>
    </React.Fragment>
}

TaskDamageBasicDataForm.propTypes = {
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { TaskDamageBasicDataForm }