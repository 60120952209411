import { connect } from 'react-redux'

import CreateTaskComponent from './component'
import { getUsersForDialogRequestDebounced } from 'application/redux/actions/common'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'

const mapStateToProps = state => ({
    accountId: state.auth.currentAccount.id
})

const mapDispatchToProps = dispatch => {
    return {
        onCreateTask: (
            name, 
            payload, 
            metaData
        ) => dispatch(
            sendPayloadRequest(
                name, 
                payload, 
                metaData
            )
        ),
        onSearchUser: (name, search) => dispatch(getUsersForDialogRequestDebounced(name, 0, 10, new ListFilter(filters.users.name).setValue(search).getQuery()))
    }
}

export const CreateTask =  connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTaskComponent)