import { connect } from 'react-redux'

import CreateTaskworkflowPanelComponent from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onCreateTaskWorkflowPanel: (name, sharedResourceId, payload) => dispatch(sendPayloadRequest(name, payload, { sharedResourceId})),
    }
}

export const CreateTaskworkflowPanel =  connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTaskworkflowPanelComponent)