import { connect } from 'react-redux'
import { downloadAttachment } from 'application/redux/actions/attachments'
import InvoiceDocumentsComponent from './component'

const mapStateToProps = () => { }

const mapDispatchToProps = dispatch => ({
    onDownloadAttachment: (
        id,
        attachmentName,
        damageId
    ) => dispatch(
        downloadAttachment(
            id,
            attachmentName,
            damageId
        )
    )
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceDocumentsComponent)