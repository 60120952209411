import * as importMappers from './import'
import * as exportMappers from './export'

const getMailBody = (salutation, text1, text2 = '', text3 = '') => {
    return `<html><head></head><body style="font-family: arial, sans-serif;"><p>Guten Tag ${salutation},</p><br /><p>${text1}</p><br /><br /><p>${text2}</p><br />${text3}</p><br /><p>Viele Grüße</p><p>Ihr e.sy 360 Team</p></body></html>`
}

export {
    importMappers,
    exportMappers,
    getMailBody
}