import React from 'react'
import styled from 'styled-components'
import Select, { components } from 'react-select'


const StyledPureSelect = styled.div`
      width: ${props => props.cssWidth};
      flex: ${props => props.cssFlexBasis === 'auto' ? '1' : '0'} 0 ${props => props.cssFlexBasis};
      height: ${props => props.cssHeight};
      font-size: ${props => props.theme.fontSize.standard};
      
      & div.css-1wa3eu0-placeholder { 
        color: ${props => props.theme.color.anthracite50};
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props => props.theme.color.anthracite50}
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props => props.theme.color.anthracite50}
      }

      /* // select icon customisation */

      & i.material-icons {
        font-size: ${props => props.theme.fontSize.standart};
      }

      &  span.css-1okebmr-indicatorSeparator {
          margin-top: 2px;
          height: 20px;
      }

      & div.css-1hb7zxy-IndicatorsContainer,
       div.css-tlfecz-indicatorContainer {
          height: 35px;
          padding: 4px;
      }

      & div.css-1oai5dw-control {
          border-color: ${props =>  props.theme.color.gray15};
          height: auto;
          align-content:center;
      }
      & div.css-ypr86o-control,
      & div.css-zbnekv-control{
        align-content:center;
        }
      & * {
        border-radius: 0px !important;
      }

      & div.css-26l3qy-menu{
          margin-top:-1px;
          left: 1px;
          width: calc(100% - 2px);
      }

      & div.css-1w9j89e-menu { 
        margin-bottom: 0px; 
      }

`

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="material-icons">keyboard_arrow_down</i>
        </components.DropdownIndicator>
    )
}

const ClearIndicator = props => {
    return (
        <components.ClearIndicator {...props}>
            <i className="material-icons">close</i>
        </components.ClearIndicator>
    )
}

export const PureSelect = (props) => {
    const {
        cssWidth = 'auto', 
        cssFlexBasis = 'auto', 
        ...rest
    } = props
    
    return <StyledPureSelect 
        cssWidth={cssWidth} 
        cssFlexBasis={cssFlexBasis}
    >
        <Select 
            components={
                { 
                    DropdownIndicator, 
                    ClearIndicator 
                }
            }
            {...rest} 
        />
    </StyledPureSelect>
}