import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.pages.extCreateDamage, action) => {
    switch (action.type) {
    case ACTIONS.EXT_CREATE_DAMAGE_REQUEST:
        return {
            ...state,
            damageDataSending: true,
            damageDataSent: false
        }
    case ACTIONS.EXT_CREATE_DAMAGE_SUCCESS:
        return {
            ...state,
            damageDataSending: false,
            damageDataSent: true,
            damageId: action.damageId
        }
    case ACTIONS.EXT_SEND_DOCUMENTS_REQUEST:
        return {
            ...state,
            documentsSending: true,
            documentsSent: false
        }
    case ACTIONS.EXT_SEND_DOCUMENTS_SUCCESS:
        return {
            ...state,
            documentsSending: false,
            documentsSent: true
        }
    default:
        return state
    }
}
