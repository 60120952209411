import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledActionButtonBar = styled.div`
    display: flex; 
    flex-direction: ${props => props.inline ? 'row' : 'column'};
    justify-content:${props => props.buttonsAlign};
    min-height: 24px;
`

const ActionButtonBar = ({children, buttonsAlign = 'flex-start', inline = false}) => <StyledActionButtonBar buttonsAlign={buttonsAlign} inline={inline}>
    {children}
</StyledActionButtonBar> 

ActionButtonBar.propTypes = {
    buttonsAlign: PropTypes.string,
    inline: PropTypes.bool
}

export default ActionButtonBar