import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import Collapsable from 'application/components/controls/collapsable'
import { DamageForms } from 'application/components/forms'
import { withRouter } from 'react-router-dom'
import { DAMAGE_TYPE } from 'application/constants'
import { Paragraph } from 'application/components/fragments/typography'
import { withTheme } from 'styled-components'
import { FlexBox } from 'application/components/fragments/flex_box'

const TaskDamagePoliceDataForm = withTheme
(
    withRouter(
        ({widgetKey, payload, loadingFailed, onSave, match, currentDamage, theme}) => {
            const {t} = useTranslation()
            const {damageId} = match.params

            if(loadingFailed){
                return <WidgetErrorMessage>{t('Police data could not be loaded')}</WidgetErrorMessage>
            }

            return <FlexBox flexDirection='column'>
                <Collapsable 
                    headline={t('Police')} 
                    initiallyCollapsed={true}
                >
                    {
                        currentDamage.type !== DAMAGE_TYPE.LIABILITY && <Paragraph>
                            <FontIcon 
                                icon='warning' 
                                color={theme.color.warning} 
                                fontSize={theme.fontSize.standard} 
                            /> 
                            {t('Police data only available for liability cases')}
                        </Paragraph>
                    }
                    <DamageForms.DamagePoliceForm 
                        onSave={onSave}  
                        damageId={damageId}
                        formData={payload.formData}
                        disabled={currentDamage.type !== DAMAGE_TYPE.LIABILITY}
                    />
                </Collapsable>
            </FlexBox>
        }
    )
)

TaskDamagePoliceDataForm.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { TaskDamagePoliceDataForm }