import { connect } from 'react-redux'

import DamageManagerDocumentsTabbarButtons from './component'
import { downloadDamageDocuments } from 'application/redux/actions/attachments'

const mapStateToProps = state => {
    return {
        documentCenterArchiveDownloading: state.pages.damageManager.documentCenter.archiveDownloading,
        hasAttachments: state.pages.damageManager.documentCenter.documents.length > 0,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDownloadDamageDocuments: (damageId) => dispatch(
            downloadDamageDocuments(
                damageId, 
                `damage-${damageId}.zip`
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerDocumentsTabbarButtons)