import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { Bar, Cell, Group } from 'application/components/pages/internal/_title_bars/_common'
import LanguageSwitch from 'application/components/building_blocks/language_switch/hoc'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'

const TitleBar = ({
    onLogout = () => {},
    theme
}) => {
    const {t} = useTranslation()
    return (
        <Bar>
            <Group>
                <Cell>
                    <LanguageSwitch />
                </Cell>
            </Group>
            <Group>
            </Group>
            <Group>
                <Cell>
                    <IconButtonWithLabel onButtonClick={onLogout} iconKey='exit_to_app' label={t('Log out')} iconSize={theme.fontSize.headline3}  />
                </Cell>  
            </Group>
        </Bar>
    )
}


export default withTheme(TitleBar)