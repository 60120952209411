import React from 'react'
import { withRouter } from 'react-router'
import { getAccountLinkHref, getBranchLinkHref } from 'application/common/route_helpers'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { exists } from 'application/common/data_helpers'
import { TextNavLink } from 'application/components/fragments/typography'
import { useTranslation } from 'react-i18next'

export const AllDamagesPageLink = withRouter(({
    match,
    orderBy = ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.VALUES.LAST_CHANGE
}) => {
    const {t} = useTranslation()
    const linkToCurrentBranch = exists(match.params.branchId)
    const linkText = linkToCurrentBranch
        ? 'show all damage cases in this branch'
        : 'show all damage cases'

    const urlParameters = `${ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.KEY}=${orderBy}&${ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.DIRECTION}=${ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING}`
    const url = linkToCurrentBranch
        ? getBranchLinkHref(match, `${ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES}?${urlParameters}`)
        : getAccountLinkHref(match, `${ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES}?${urlParameters}`)

    return  <TextNavLink to={url}>
        &raquo; {t(linkText)}
    </TextNavLink>
})