import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getFirstBranchRequest } from 'application/redux/actions/common/branches'

const mapStateToProps = state => ({
    firstBranch: state.common.firstBranch,
    firstBranchLoading: state.common.firstBranchLoading,
    firstBranchLoaded: state.common.firstBranchLoaded,
    sharedResource: state.sharedResource
})

const mapDispatchToProps = dispatch => ({ 
    onGetFirstBranch: () => dispatch(getFirstBranchRequest()),
})

const withFirstBranchLoaderComponent = Component => (props) => {
    const {
        firstBranchLoading, 
        firstBranchLoaded,
        onGetFirstBranch,
        sharedResource
    } = props

    useEffect(
        () => { 
            sharedResource.resourceIsValid
        && !firstBranchLoading 
        && !firstBranchLoaded 
        && onGetFirstBranch() 
        }, [firstBranchLoaded, firstBranchLoading, onGetFirstBranch, sharedResource.resourceIsValid]
    )

    return <Component {...props} />
}

export const withFirstBranchLoader = compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    withFirstBranchLoaderComponent
)