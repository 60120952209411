import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { nameHelpers } from 'application/common'
import { FontIcon, ActionButton } from '@ec/ui-controls-react'
import { withTheme } from 'styled-components'
import { Paragraph } from 'application/components/fragments/typography'
import { Spacer, PushBottom32 } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { dataMappers } from 'application/api'
import { DIALOG } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'

const DeleteUser = withTheme(
    (
        { 
            abort, 
            dialog, 
            onDeleteUser,
            onEditUser, 
            theme 
        }
    ) => {
        const {t} = useTranslation()
        const { payload } = dialog
        const { adminUsers } = payload
    
        const fullName = nameHelpers.getFullName(payload)
        const successMsg = fullName + ' ' + t('has been successfully deleted')

        const userIsLastAdmin = adminUsers.total === 1 && adminUsers.users[0].id === payload.id


        const handleEditUser = () => {
            onEditUser(
                dialog.name,
                DIALOG.NAME.EDIT_USER_FROM_LIST, 
                true,
                dataMappers.importMappers.user.userFormDataMapper(payload),
                'Edit user'              
            )
        }


        if(userIsLastAdmin){
            return <React.Fragment>
                <DialogMainContent cssHeight='130px'>
                    <Spacer basis='40px' />
                    <InfoBox 
                        type={INFOBOX.TYPES.WARNING} 
                        direction='row' 
                        cssMargin='0'
                    >
                        <FontIcon icon='error_outline' />
                        <Spacer basis='10px' />
                        <span>{t('User is the only admin in this account and cannot be deleted.')}</span>
                        <Spacer />
                    </InfoBox>
                </DialogMainContent>
                <Footer
                    onAbort={abort}
                    submitText='Delete user'
                    disabled={true}
                />
            </React.Fragment>
        }
    

        return <React.Fragment>
            <DialogMainContent>
                <Paragraph cssPadding='16px 0'>
                    <Trans i18nKey='do you want to delete this user?'>
                    Do you want to delete the user "{{fullName}}" ?
                    </Trans>
                </Paragraph>
                <InfoBox 
                    type={INFOBOX.TYPES.WARNING} 
                    direction='row' 
                    cssMargin='0' 
                >
                    <FontIcon icon='info_outline' />
                    <Spacer basis='10px' />
                    <span>
                        {t('If you delete this user, all existing connections to damage cases will be removed.')}
                    </span>
                    <Spacer />
                </InfoBox>
                <PushBottom32/>
                <FlexBox width='auto'>
                    <ActionButton 
                        buttonText={t('Edit user data instead')} 
                        onButtonClick={handleEditUser}
                    />
                </FlexBox>
                <PushBottom32/>
            </DialogMainContent>
            <Footer
                onAbort={abort}
                onSubmit={ 
                    () => onDeleteUser(
                        payload.id, 
                        payload, 
                        successMsg
                    )
                }
                submitText='Delete user'
            />
        </React.Fragment>

    })

export default withDialogDataLoader(DeleteUser)