import React from 'react'
import { TableCompanies } from 'application/components/tables/companies'
import { PushBottom32 } from 'application/components/pages/_common'
import { withCustomersLoader } from 'application/components/higher_order_components/with_customers_loader'

export const CompaniesContentComponent = (
    {
        customersList,
        customersListLoaded,
        customersListLoading,
        customersListTotal,
        updateConstraint,
        onLoadCustomersList
    }
) => {
    return <React.Fragment>
        <PushBottom32 />
        <TableCompanies 
            companiesList={customersList}
            companiesListLoaded={customersListLoaded}
            companiesListLoading={customersListLoading}
            companiesListTotal={customersListTotal}
            onLoadCustomersList={onLoadCustomersList}
            updateConstraint={updateConstraint}
        />
        <PushBottom32 />
    </React.Fragment>
}

export const CompaniesContent = withCustomersLoader(
    CompaniesContentComponent
)