import {ACTIONS} from 'application/constants'

export const getAllDocumentsRequest = (damageId, taskId, match) => ({
    type: ACTIONS.DAMAGE_MANAGER_DOCUMENTS_GET_ALL_DOCUMENTS_REQUEST,
    damageId,
    taskId,
    match,
})

export const resetDocumentCenter = () => ({
    type: ACTIONS.DAMAGE_MANAGER_DOCUMENTS_RESET
})

export const populateAllDocuments = (documents, damageId) => ({
    type: ACTIONS.DAMAGE_MANAGER_DOCUMENTS_POPULATE_DOCUMENTS,
    documents,
    damageId
})

export const showAttachmentDetails = (attachment, damageId) => ({
    type: ACTIONS.DAMAGE_MANAGER_DOCUMENTS_SHOW_ATTACHMENT_DETAILS,
    attachment,
    damageId
})

export const hideAttachmentDetails = () => ({
    type: ACTIONS.DAMAGE_MANAGER_DOCUMENTS_HIDE_ATTACHMENT_DETAILS
})

export const removeAttachmentFromDocumentCenter = (attachmentId) => ({
    type: ACTIONS.DAMAGE_MANAGER_DOCUMENTS_REMOVE_ATTACHMENT,
    attachmentId
})

export const setArchiveDownloading = (archiveDownloading = true) => ({
    type: ACTIONS.DAMAGE_MANAGER_DOCUMENTS_SET_ARCHIVE_DOWNLOADING,
    archiveDownloading
})

export const updateDocumentCenterAttachmentDetails = (payload) => ({
    type: ACTIONS.DAMAGE_MANAGER_DOCUMENTS_UPDATE_ATTACHMENT_DETAILS,
    payload
})