import React from 'react'
import { ROUTES } from 'application/constants'
import DamageLicenseplate from 'application/components/controls/damage_licenseplate'
import DamageTypeName from 'application/components/name_mappings/damage_type_name'
import {JobNumber} from 'application/components/controls/job_number'
import FullName from 'application/components/controls/full_name'
import DamageStatus from 'application/components/controls/damage_status'
import DateComponent from 'application/components/controls/date'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'
import { LawFirmName } from 'application/components/name_mappings/lawfirm_name'

export const configuration = {
    //no pagination (hide=true) -> no tableName needed
    sorting: {
        sortParamKey: '',
        directionParamKey: '',
        defaultSortParamValue: '',
        defaultDirectionParamValue: ''
    },
    pagination: {
        hidePagination: true
    },
    href: (element, match) => getAccountLinkHref(match, ROUTES.DAMAGE_MANAGER_BASE + element.id),
    columns: [

        {
            width: 15,
            label: 'A.-Nr.',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <JobNumber number={element.jobNumber} />
        },
        {
            width: 15,
            label: 'License plate',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <DamageLicenseplate damage={element}/>
        },
        {
            width: 15,
            label: 'Damage Type',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element, isSelected}) => <DamageTypeName damageType={element.type} isSelected={isSelected} />
        },
        {
            width: 15,
            label: 'Status',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element, isSelected}) => <DamageStatus status={element.state} isSelected={isSelected} />
        },
        {
            width: 15,
            label: 'Assignee',
            secondLabel: 'Lawfirm',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <FlexBox flexDirection='column'>
                <FullName person={element.assignee} />
                <LawFirmName lawFirm={element.lawFirm} />
            </FlexBox>
        },
        {
            width: 15,
            label: 'Release date',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <DateComponent date={element.modifiedAt} />
        }
    ],
    ContextMenu: () => null,
    contextMenuWidth: '0px',
    massOperations: {
        available: false //are mass operations possible for this table?
    },
    rowHeight: '36px'
}
