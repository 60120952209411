import {ACTIONS} from 'application/constants'


export const updateVehicle = (
    damageId, 
    vehicleData
) => ({
    type: ACTIONS.DAMAGE_MANAGER_SHARED_UPDATE_VEHICLE,
    damageId, 
    vehicleData
})

export const populateVehicle = (
    vehicleData
) => ({
    type: ACTIONS.DAMAGE_MANAGER_SHARED_POPULATE_VEHICLE,
    vehicleData
})

export const updateDamageAssignee = (damageId, userId) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_DAMAGE_ASSIGNEE,
    damageId,
    userId,
})

export const requestUpdateDocument = (
    damageId,
    attachmentId,
    payload
) => ({
    type: ACTIONS.DAMAGE_MANAGER_REQUEST_UPDATE_DOCUMENT,
    damageId,
    attachmentId,
    payload
})