import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.pages.customers, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.customers
    }
    case ACTIONS.CUSTOMERS_GET_CUSTOMERS_REQUEST:
        return {
            ...state,
            customersListLoading: true,
            customersListLoaded: false
        }
    case ACTIONS.CUSTOMERS_POPULATE_CUSTOMERS_LIST:
        return {
            ...state,
            customersListLoading: false,
            customersListLoaded: true,
            customersList: action.customersList,
            customersListTotal: action.customersListTotal
        }

    default:
        return state
    }
}
