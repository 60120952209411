import { updateWidget, updateWidgetLoadedState } from 'application/redux/actions/widgets'
import { connect } from 'react-redux'

import BranchSpecificInboxMessageList from './component'

const mapStateToProps = state => {
    return {
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPaginationChange: (key, offset, limit) =>{
            dispatch(updateWidget(key, [], {offset, limit}))
            dispatch(updateWidgetLoadedState(key, false))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchSpecificInboxMessageList)