import React from 'react'
import PropTypes from 'prop-types'
import { ActivityDetail } from 'application/components/controls/activity_details/common'
import { withBranches } from 'application/components/higher_order_components'
import { withRouter } from 'react-router-dom'
import { exists } from 'application/common/data_helpers'

export const BranchDetail = withBranches(withRouter((
    {
        activity,
        primary = true,
        branches,
        match
    }
) => {
    if(branches.length === 0) {
        return null
    }

    const branch = branches.find(b => b.id === activity.branchId)

    if(!exists(branch)){
        return null
    }
    
    const branchName = branch.name

    if(branches.length < 2 || match.params.branchId !== undefined){
        return null
    }

    return <ActivityDetail label='Branch' value={branchName} primary={primary} />
}))

BranchDetail.propTypes = {
    activity: PropTypes.object.isRequired
}