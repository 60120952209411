import { connect } from 'react-redux'

import DamageManagerOverviewTabbarButtons from './component'
import { exists } from 'application/common/data_helpers'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => {
    return {
        damageLoaded: state.pages.damageManager.shared.damageLoaded,
        damage: state.pages.damageManager.shared.damage,
        hasCaseNote: exists(state.pages.damageManager.shared.damage.note)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateCaseNote: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_CASE_NOTES, 
                true, 
                payload, 
                'Store case notes'
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerOverviewTabbarButtons)