import { put, select } from 'redux-saga/effects'
import { api, dataMappers } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage } from 'application/redux/actions/waiting_screen'
import { getCurrentBranchId } from 'application/redux/selectors'
import { exists } from 'application/common/data_helpers'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { closeAllDialogs, openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'


export function* dialogCreateDamageDekraSaga(action) {
    const currentBranchId = yield select(getCurrentBranchId)

    const createCustomerPayload = dataMappers.exportMappers.damage.createCustomerDataMapper(action.payload)

    yield put(
        showWaitingScreen('Creating damage')
    )
    yield put(
        addNewSubMessage(action.payload.licenseplate)
    )

    let customerId = action.payload.customerId

    if(!exists( action.payload.customerId ) ){
        const createCustomerResponse = yield resourceCall(
            api.gateway.customer.postCustomer, 
            null,
            createCustomerPayload
        )

        customerId = createCustomerResponse.id
    }

    const payload = dataMappers.exportMappers.damage.createDamageDataMapper(
        action.payload, 
        customerId
    )
    const response = yield resourceCall(

        api.gateway.damage.createDamage, 
        {
            branchId: currentBranchId
        },
        payload
    )

    if(exists(action.payload.assignedTo)){
        yield resourceCall(
            api.gateway.damage.updateDamageAssignee, 
            {
                damageId: response.id
            }, 
            {
                id: action.payload.assignedTo
            }
        )
    }

    yield put(hideWaitingScreen())
    yield put(closeAllDialogs())

    yield put(openDialogByName(
        DIALOG.NAME.COMMISSION_DEKRA_EXPERT,
        false,
        {
            damageId: response.id
        },
        'Commission expert'
    ))

    yield put(showToast('Damage created'))
}