import { all } from 'redux-saga/effects'
import { showNotificationWatcher } from './sagas/notifications'
import * as dialogs from './sagas/dialogs'
import * as widgets from './sagas/widgets'
import * as damageManager from './sagas/pages/damage_manager'
import * as workflowPanel from './sagas/pages/workflow_panel'
import * as damagesOverview from './sagas/pages/damages_overview'
import * as common from './sagas/common'
import * as attachments from './sagas/attachments'
import * as inbox from './sagas/pages/inbox'
import * as tasksOverview from './sagas/pages/tasks_overview'
import * as customers from './sagas/pages/customers'
import * as registration from './sagas/registration'
import * as administration from './sagas/pages/administration'
import * as realtimeNotifications from './sagas/realtime_notifications'
import * as activityLog from './sagas/pages/activity_log'
import * as sharedResource from './sagas/shared_resource'
import * as auth from './sagas/auth'
import * as branchManagement from './sagas/pages/branch_management'
import { sendCreateDamageExternalWatcher } from './sagas/pages/ext_create_damage'
import { sendCreateDamageKickstartExternalWatcher } from './sagas/pages/ext_create_damage_kickstart'
import { qrCodeDownloadWatcher } from './sagas/qr_code'

export default function* rootSaga() {
    yield all([
        showNotificationWatcher(),
        auth.ssoWatcher(),
        auth.accountsWatcher(),
        auth.branchesWatcher(),
        dialogs.sendPayloadWatcher(),
        dialogs.fetchDataDialogWatcher(),
        dialogs.switchDialogWatcher(),
        widgets.fetchWidgetDataWatcher(),
        damageManager.damageManagerWatcher(),
        damageManager.damageManagerTasksWatcher(),
        damageManager.damageManagerSelectedTaskWatcher(),
        damageManager.damageManagerDocumentsWatcher(),
        damageManager.updateTaskWatcher(),
        damageManager.assignTaskWatcher(),
        damageManager.changeTaskStateWatcher(),
        damageManager.changeCategoryOfDocumentWatcher(),
        damageManager.createCommentWatcher(),
        damageManager.loadMoreCommentsWatcher(),
        damageManager.updateWorkflowPanelFormWatcher(),
        damageManager.updateDamageWatcher(),
        damageManager.updateAccidentReportWatcher(),
        damageManager.updateOpponentWatcher(),
        damageManager.updatePoliceWatcher(),
        damageManager.updateWitnessWatcher(),
        damageManager.shared.updateVehicleWatcher(),
        damageManager.shared.updateDocumentWatcher(),
        damagesOverview.getAllDamagesWatcher(),
        common.getUsersWatcher(),
        common.updateAccountWatcher(),
        common.categories.getCategoriesWatcher(),
        common.categories.moveDocumentCategoryWatcher(),
        common.getInsurancesWatcher(),
        common.getWebakteLawfirmWatcher(),
        common.getCustomersWatcher(),
        common.getAccessibleBranchesWatcher(),
        common.getComprehensiveDamageTypeWatcher(),
        common.statusListWatcher(),
        inbox.messageDetailsWatcher(),
        attachments.fetchAttachmentMetaByIdWatcher(),
        attachments.fetchThumbnailByIdWatcher(),
        attachments.downloadAttachmentWatcher(),
        attachments.downloadDamageDocumentsWatcher(),
        attachments.downloadTaskDocumentsWatcher(),
        attachments.fetchFullAttachmentByIdWatcher(),
        customers.getCustomersWatcher(),
        activityLog.getActivityLogWatcher(),
        workflowPanel.workflowPanelTasksWatcher(),
        workflowPanel.workflowPanelSelectedTaskWatcher(),
        workflowPanel.workflowPanleActivityLogWatcher(),
        workflowPanel.workflowPanelDocumentsWatcher(),
        workflowPanel.workflowPanelCategoriesWatcher(),
        workflowPanel.workflowPanelVehicleWatcher(),
        workflowPanel.workflowPanelLawfirmWatcher(),
        registration.registrationConnectUserWatcher(),
        registration.createAccountWatcher(),
        administration.sendInvitationWatcher(),
        realtimeNotifications.realtimeNotificationsWatcher(),
        tasksOverview.getAllTasksWatcher(),
        tasksOverview.getMyTasksWatcher(),
        sharedResource.validatePinWatcher(),
        sharedResource.fetchSharedResourceWatcher(),
        common.getUserGroupsWatcher(),
        common.getRolesWatcher(),
        branchManagement.getGroupMembersWatcher(),
        branchManagement.connectedGroupBranchesWatcher(),
        branchManagement.connectedGroupRolesWatcher(),
        branchManagement.removeGroupRolesConnectionWatcher(),
        branchManagement.removeGroupBranchesConnectionWatcher(),
        branchManagement.updateGroupWatcher(),
        sendCreateDamageExternalWatcher(),
        qrCodeDownloadWatcher(),
        sendCreateDamageKickstartExternalWatcher()
    ])
}