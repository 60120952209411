import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import {DeleteAttachmentComponent} from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onDeleteAttachment: (dialogName, damageId, attachmentId, taskId, match) => {
            dispatch(sendPayloadRequest(dialogName, { damageId, attachmentId, taskId, match }))
        },
    }
}

export const DeleteAttachment = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteAttachmentComponent)