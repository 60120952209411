import React from 'react'
import { Table } from 'application/components/tables/_common'
import { configuration } from './configuration'
import { withRouter } from 'react-router-dom'
import { useMassOperationsClose, useTableLoader } from 'application/components/hooks'
import { withMassOperations } from 'application/components/context/mass_operations'

const TableAllDamagesOverview = (
    {
        damagesListLoading,
        damagesListLoaded,
        damagesListTotal,
        damagesList,
        moName,
        closeMoByName,
        onLoadDamages,
        updateConstraint = null,
        match
    }
) => {
    
    useTableLoader(
        onLoadDamages, 
        match, 
        configuration, 
        updateConstraint
    )

    useMassOperationsClose(
        moName,
        closeMoByName, 
        damagesList
    )

    return <Table 
        configuration={configuration}
        elementsLoading={damagesListLoading}
        elementsTotal={damagesListTotal}
        elementsLoaded={damagesListLoaded}
        elements={damagesList}
        emptyMessage='No damages found'
        moName={moName}
    />
}

export default withMassOperations(withRouter(TableAllDamagesOverview))