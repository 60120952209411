import { connect } from 'react-redux'
import ForCurrentBranchPermissions from './component'

const mapStateToProps = (state) => {
    return {
        branchPermissions: state.auth.permissions
    }
}

export default connect(
    mapStateToProps
)(ForCurrentBranchPermissions)