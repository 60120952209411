import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogSelectWithUploaderDialogLayout } from 'application/components/dialogs/_common/select_with_uploader_layout'
import { DialogMainContent } from 'application/components/fragments/dialog'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from '@ec/ui-controls-react'
import TaskUploader from 'application/components/building_blocks/task_uploader'
import { FlexBox } from 'application/components/fragments/flex_box'




export const CreateInvoiceStep1 = ({
    getStepsState,
    goToNextStep,
    goToPreviousStep,
    files,
    setFlaggedFiles,
    setShowValidation,
    onDataChange
}) => {
    const { t } = useTranslation()
     
    const handleFileValidation = () => {
        goToNextStep()
    } 

    return <DialogSelectWithUploaderDialogLayout
        getStepsState={getStepsState}
        footerElements={ 
            <React.Fragment>
                <IconButtonWithLabel
                    iconKey='arrow_back' 
                    label={t('Back')} 
                    onButtonClick={() => {
                        goToPreviousStep()
                    }} 
                />
                <ActionButton 
                    buttonText={t('Next')} 
                    onButtonClick={handleFileValidation} 
                />
            </React.Fragment>
        }
    >
        <DialogMainContent>
            <FlexBox
                flexGrow='0'
                flexBasis='200px'
            >
                <TaskUploader
                    onFileAdded={() => { }}
                    disableActiveState={true}
                    files={files}
                    setFiles={setFlaggedFiles}
                    setShowValidation={setShowValidation}
                    onDataChange={onDataChange}
                />
            </FlexBox>               
        </DialogMainContent>
    </DialogSelectWithUploaderDialogLayout>
}
