export const userFormDataMapper = (user) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    company: user.company,
    phoneNumber: user.phoneNumber,
    groups: user.groups[0],
    isRegistered: user.isRegistered,
    preferredBranch: user.preferredBranch,
    isLocked: user.isLocked
})