import React from 'react'
import { TextInput } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'
import { useStatefulForm } from 'application/components/hooks'
import { validationMethods } from 'application/common'
import CategorySelect from 'application/components/controls/form/category_select'
import { FlexBox } from 'application/components/fragments/flex_box'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from '@ec/ui-controls-react'
import { BranchSelect } from 'application/components/controls/form/branch_select'


export const  Step0Component = ({
    abort,
    onDataChange,
    goToNextStep,
    dialog,
    onQRCodeCreation
}) => {


    const {t} = useTranslation()

    const fieldNames = {
        licensePlate: 'licensePlate',
        branch: 'branchId',
        category: 'categoryId'
    }

    const {
        licensePlate,
        branchId,
        categoryId = null,
        customer
    } = dialog.payload

    const defaultValues = {
        [fieldNames.licensePlate]: licensePlate,
        [fieldNames.branch]: branchId,
        [fieldNames.category]: categoryId
    }


    const {
        register, 
        errors, 
        handleSubmit, 
        getValues,
        setValue, 
        updateFormState
    } = useStatefulForm({defaultValues})

    const onSubmitSuccess = () => {
        const payload = {
            customer,
            ...getValues()
        }
        onQRCodeCreation(
            dialog.name,
            payload
        )
        goToNextStep()
    }


    return <React.Fragment>
        <TextInput
            label = {t('License plate')}
            name = {fieldNames.licensePlate}
            register = {register}
            validate = {{
                notEmpty: validationMethods.notEmpty, 
                max50Characters: validationMethods.max50Characters
            }}
            error = {errors[fieldNames.licensePlate]}
            onChange = {
                () => {
                    onDataChange()
                    updateFormState()
                }
            } 
        />
        <FlexBox height='71px'>
            <CategorySelect 
                name={fieldNames.category}
                onChange={updateFormState} 
                register={register} 
                setValue={setValue} 
                initialValue={defaultValues.categoryId}
            />
        </FlexBox>
        <BranchSelect
            onChange={updateFormState} 
            register={register} 
            setValue={setValue} 
            initialBranchId={defaultValues.branchId}
        />
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={abort} 
            />
            <ActionButton
                buttonText={t('Generate QR-Code')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </StepsLayoutFooter>
    </React.Fragment>
}