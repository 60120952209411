import React from 'react'
import { useTranslation } from 'react-i18next'
import ContextMenu from 'application/components/controls/context_menu'
import ContextMenuItem from 'application/components/controls/context_menu_item'
import { ContextMenuWrapper } from 'application/components/tables/_common/fragments'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { USER_PERMISSONS } from 'application/constants'
import { getFullName } from 'application/common/name_helpers'

const ContextMenuUsers = ({
    user,
    onEditUser,
    onDeleteUser,
    onInviteUser,
    onEditUserGroups
}) => {
    const { t } = useTranslation()
    
    const handleClick = (e, handle) => {
        e.preventDefault()
        e.stopPropagation()
        handle()
    }

    return <ForAnyBranchPermissions
        permissions={[
            USER_PERMISSONS.USER.UPDATE,
            USER_PERMISSONS.USER.DELETE,
            USER_PERMISSONS.USER_INVITATION.CREATE
        ]}
        allNeeded={false}
    >
        <ContextMenuWrapper>
            <ContextMenu iconKey='edit'>
                <ForAnyBranchPermissions
                    permissions={[USER_PERMISSONS.USER.UPDATE]}
                >
                    <ContextMenuItem
                        onListElementClick={(event) => {
                            handleClick(event, () => {
                                onEditUser(user)
                            })
                        }}
                        iconKey='create'
                        title={t('Edit user')}
                    >
                        {t('Edit user')}
                    </ContextMenuItem>
                </ForAnyBranchPermissions>
                <ForAnyBranchPermissions
                    permissions={[USER_PERMISSONS.USER.UPDATE]}
                >
                    <ContextMenuItem
                        onListElementClick={(event) => {
                            handleClick(event, () => {
                                const dialogTitle = `${t('Edit group memberships')}: ${getFullName(user)}`
                                onEditUserGroups(user, dialogTitle)
                            })
                        }}
                        iconKey='group'
                        title={t('Edit group memberships')}
                    >
                        {t('Edit group memberships')}
                    </ContextMenuItem>
                </ForAnyBranchPermissions>
                <ForAnyBranchPermissions
                    permissions={[USER_PERMISSONS.USER.DELETE]}
                >
                    <ContextMenuItem
                        onListElementClick={(event) => {
                            handleClick(event, () => {
                                onDeleteUser(user)
                            })
                        }}
                        iconKey='delete_outline'
                        title={t('Delete user')}
                    >
                        {t('Delete user')}
                    </ContextMenuItem>
                </ForAnyBranchPermissions>
                <ForAnyBranchPermissions
                    permissions={[USER_PERMISSONS.USER_INVITATION.CREATE]}
                >
                    {
                        !user.isRegistered && <ContextMenuItem
                            onListElementClick={(event) => {
                                handleClick(event, () => {
                                    onInviteUser(user.id)
                                })
                            }}
                            iconKey='redo'
                            title={t('Send invitation again')}
                        >
                            {t('Send invitation again')}
                        </ContextMenuItem>
                    }
                </ForAnyBranchPermissions>
            </ContextMenu>
        </ContextMenuWrapper>
    </ForAnyBranchPermissions>
}

export default ContextMenuUsers