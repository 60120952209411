import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Link = styled(NavLink)`
    padding: 6px 0;
    width: 100%;
    text-decoration: none;
    color: ${props => props.theme.color.primary};
    &:hover{
        text-decoration: underline;
    }
`

const Hyperlink = (props) => {
    const {children, ...rest} = props
    return <Link {...rest}>&raquo; {children}</Link> 
}

export default Hyperlink

