import React from 'react'
import { Sidebar, Fragments } from '../_common'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { Paragraph } from 'application/components/fragments/typography'
import { PushBottom32 } from 'application/components/pages/_common'

const HelpSidebarContent = withTheme(({onSidebarClose, theme}) => {
    const {t} = useTranslation()

    return <Sidebar onSidebarClose={onSidebarClose} title={t('Description')} sidebarTitleColor={theme.color.gray70}>
        <Fragments.ContentSidebar>

            <PushBottom32>
                <Paragraph><strong>Rechtesammlungen</strong></Paragraph>
                <Paragraph>Als Rechtesammlung bezeichnet man ein Sortiment von <strong>einem oder mehreren Rechten</strong>. Ein Recht steuert, welche Funktionalitäten einem Benutzer in e.sy 360 zur Verfügung stehen.</Paragraph>
                <Paragraph>Unterschieden werden <strong>globale Rechte</strong> und <strong>filialspezifische Rechte</strong>. Globale Rechte gelten filialübergreifend, werden also unabhängig davon, ob die Benutzergruppe Zugang zu einer oder mehreren Filialen hat, angewendet.</Paragraph>
            </PushBottom32>
            <PushBottom32>
                <Paragraph><strong>Benutzergruppen</strong></Paragraph>
                <Paragraph>Einer Benutzergruppe werden <strong>Benutzer</strong>, <strong>Rechtesammlungen</strong> und <strong>Filialen</strong> zugeordnet.</Paragraph>
                <Paragraph>Durch diesen Zusammenhang wird gesteuert, zu welchen Filialen einzelne Benutzer Zugang haben und welche Rechte sie in ihr besitzen.</Paragraph>
            </PushBottom32>
            <PushBottom32>
                <Paragraph><strong>Filialen</strong></Paragraph>
                <Paragraph>Filialen repräsentieren geographische Niederlassungen das Unternehmens.</Paragraph>
            </PushBottom32>
        </Fragments.ContentSidebar>
    </Sidebar>
})

export const HelpSidebar = props => {
    if(!props.isVisible) {
        return null
    }
    return <HelpSidebarContent {...props} />
}