import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FlexBox } from 'application/components/fragments/flex_box'
import { DASHBOARD_CONFIGURATION, DIALOG, IMAGES } from 'application/constants'
import Image from 'application/components/controls/image'
import { useTranslation } from 'react-i18next'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

export const StyledDashboardConfigurationOption = styled.div`
    flex: 0 0 26px;
    height: 26px;
    margin-right: 4px;
    background-color: ${props => props.isActive ? props.theme.color.primaryBackground : props.theme.color.gray5};
    border: 1px solid ${props => props.isActive ? props.theme.color.primary : props.theme.color.gray15};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover{
        background-color: ${props => props.theme.color.gray15};   
    }

    & img{
        width: 20px !important;
        height: 20px !important;
    }
`


export const ConfigureDashboardComponent = ({onChangeConfiguration}) => {
    const {t} = useTranslation()
    const [dashboardConfiguration, setDashboardConfiguration] = useState(null)

    useEffect(()=>{
        if(dashboardConfiguration !== null) {
            onChangeConfiguration(dashboardConfiguration)
        }
    }, [dashboardConfiguration, onChangeConfiguration])

    return <FlexBox flexDirection='row' flexShrink='1' width='auto'>
        <StyledDashboardConfigurationOption 
            isActive={dashboardConfiguration === DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES} 
            onClick={()=>{setDashboardConfiguration(DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES)}}
            title={t('Damages & releases')}
        >
            <Image data={IMAGES.DASHBOARD_CONFIGURATION_DAMAGES} />
        </StyledDashboardConfigurationOption>
        <StyledDashboardConfigurationOption 
            isActive={dashboardConfiguration === DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CONTROLLING} 
            onClick={()=>{setDashboardConfiguration(DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CONTROLLING)}}
            title={t('Controlling & statistics')}
        >
            <Image data={IMAGES.DASHBOARD_CONFIGURATION_CONTROLLING} />
        </StyledDashboardConfigurationOption>
        <StyledDashboardConfigurationOption 
            isActive={dashboardConfiguration === DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CUSTOMERS} 
            onClick={()=>{setDashboardConfiguration(DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CUSTOMERS)}}
            title={t('Customers & tracking')}
        >
            <Image data={IMAGES.DASHBOARD_CONFIGURATION_CUSTOMERS} />
        </StyledDashboardConfigurationOption>
    </FlexBox>
} 




const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => {
    return {
        onChangeConfiguration: (dashboardConfiguration) => {
            dispatch(sendPayloadRequest(DIALOG.NAME.CONFIGURE_DASHBOARD, { dashboardConfiguration }))
        },
    }
}


export const ConfigureDashboard = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigureDashboardComponent)