import { connect } from 'react-redux'
import AddRole from './component'
import {addRole} from 'application/redux/actions/pages/branch_management'
import { closeAllDialogs } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onAddRole: (roleName, roleId) => {
            dispatch(addRole(roleName, roleId))
            dispatch(closeAllDialogs())
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddRole)