import React from 'react'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { ROUTES, USER_PERMISSONS } from 'application/constants'
import PropTypes from 'prop-types'
import SideBarIconLink from 'application/components/building_blocks/main_navigation_sidebar/_common/icon_link'
import { useTranslation } from 'react-i18next'
import { buildToolTip } from '../helper_functions'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'

const AllBranchesAccordionClosed = (
    {
        match,
        primaryColor,
        primaryHoverColor,
        menuName=''
    }
) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <SideBarIconLink 
            toolTip={ buildToolTip(menuName,'Overview',t) }
            primaryColor={primaryColor}
            hoverBackground={primaryHoverColor}
            to={ getAccountLinkHref(match, ROUTES.DASHBOARD) }
            iconKey={'widgets'}
            isActive={
                match.path === ROUTES.DASHBOARD 
            }
        />
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.DAMAGE.READ]}
        >
            <SideBarIconLink 
                toolTip={ buildToolTip(menuName,'Damages',t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={ getAccountLinkHref(match, ROUTES.DAMAGES_OVERVIEW) }
                iconKey={'directions_car'}
                isActive={
                    match.path === ROUTES.DAMAGES_OVERVIEW 
                    || match.path === ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES 
                    || match.path === ROUTES.DAMAGES_OVERVIEW_ARCHIVED_DAMAGES 
                    || match.path === ROUTES.DAMAGES_OVERVIEW_MY_DAMAGES
                }
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.TASK.READ]} 
        >
            <SideBarIconLink
                toolTip={ buildToolTip(menuName,'Tasks',t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={ getAccountLinkHref(match, ROUTES.TASKS_OVERVIEW) }
                iconKey={'description'}
                isActive={
                    match.path === ROUTES.TASKS_OVERVIEW 
                    || match.path === ROUTES.TASKS_OVERVIEW_ALL_TASKS 
                    || match.path === ROUTES.TASKS_OVERVIEW_MY_TASKS
                }
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.MESSAGE.READ]} 
        >
            <SideBarIconLink 
                toolTip={ buildToolTip(menuName,'Inbox',t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={ getAccountLinkHref(match, ROUTES.ALL_BRANCHES_INBOX) }
                iconKey={'mail_outline'}
                isActive={
                    match.path === ROUTES.ALL_BRANCHES_INBOX 
                    || match.path === ROUTES.INBOX_PRESELECTED
                }
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions
            permissions={[USER_PERMISSONS.CONTROLLING.READ]}
        >
            <SideBarIconLink 
                toolTip={buildToolTip(menuName,'Controlling',t)}
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={ getAccountLinkHref(match, ROUTES.CONTROLLING) }
                iconKey={'bar_chart'}
                isActive={
                    match.path === ROUTES.CONTROLLING 
                    || match.path === ROUTES.CONTROLLING_STATISTICS 
                    || match.path === ROUTES.CONTROLLING_INVOICES
                }
            />
        </ForAnyBranchPermissions>
        <SideBarIconLink 
            toolTip={ buildToolTip(menuName, 'Activities', t) }
            primaryColor={primaryColor}
            hoverBackground={primaryHoverColor}
            to={getAccountLinkHref(match, ROUTES.ACTIVITY_LOG)}
            iconKey={'list'}
            isActive={ match.path === ROUTES.ACTIVITY_LOG }
        />
    </React.Fragment>

}



  

AllBranchesAccordionClosed.propTypes = {
    match: PropTypes.object.isRequired,
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired,
    menuName: PropTypes.string
}

export default AllBranchesAccordionClosed