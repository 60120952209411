import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage, WidgetHeadline } from 'application/components/widgets/_common'
import { LawfirmForms } from 'application/components/forms'
import CustomerCard from 'application/components/building_blocks/customer_card'
import { INFOBOX } from 'application/constants'
import { PushBottom24 } from 'application/components/pages/_common'
import { FlexBox } from 'application/components/fragments/flex_box'


const LawfirmReceiptConfirmation = (
    {
        widgetKey, 
        payload, 
        loadingFailed, 
        onSave,
        damageType
    }
) => {
    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('Receipt confirmation data could not be loaded')}
        </WidgetErrorMessage>
    }



    const { formData, customer } = payload
    


    const handleSave = (payload) => {
        onSave(
            widgetKey, 
            payload
        )
    } 

    return <FlexBox flexDirection='column'>
        <PushBottom24 />
        <WidgetHeadline>{t('Customer')}</WidgetHeadline>
        <CustomerCard 
            customer={customer} 
            infoBoxType={INFOBOX.TYPES.WARNING}
            cssMargin='0 0 24px' 
        />
        <LawfirmForms.LawfirmReceiptConfirmationForm
            onSave={handleSave}
            formData={formData}
            damageType={damageType}
        />
    </FlexBox>
}

LawfirmReceiptConfirmation.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { LawfirmReceiptConfirmation }