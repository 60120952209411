import { connect } from 'react-redux'

import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { getTasks, resetSelectedTask } from 'application/redux/actions/pages/damage_manager/tasks'
import DamageManagerTasksEffects from './effects'

const mapStateToProps = state => {
    const {tasksList, tasksLoaded, tasksLoading, tasksListDamageId} = state.pages.damageManager.tasks
    return {
        tasksList,
        tasksLoaded,
        tasksLoading,
        tasksListDamageId,
        loggedIn: state.auth.loggedIn,
        tasksAttachmentDetails: state.pages.damageManager.tasks.attachmentDetails,
        selectedTaskLoaded: state.pages.damageManager.tasks.selectedTaskLoaded,
        damageLoaded: state.pages.damageManager.shared.damageLoaded,
        damage: state.pages.damageManager.shared.damage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddTask: (damageId, damage) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_TASK_FROM_DAMAGE_MANAGER, 
                true, 
                {
                    damageId, 
                    damage, 
                    usersList: [], 
                    usersListLoading: false
                }, 
                'Create Task'
            )
        ),
        onLoadTasks: (damageId) => dispatch(getTasks(damageId)),
        onDeleteTasks: (taskIds) => {console.log('deleting: ', taskIds)},
        onForwardTasks: (taskIds) => {console.log('forwarding: ', taskIds)},
        onDownloadTasks: (taskIds) => {console.log('downloading: ', taskIds)},
        onResetSelectedTask: () => dispatch(resetSelectedTask()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerTasksEffects)