import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@ec/ui-controls-react'
import { FormatCurrencyDE } from 'application/common/currency_helpers'
import { PushBottom16, PushBottom8 } from 'application/components/pages/_common'
import DateComponent from 'application/components/controls/date'
import LocalTime from 'application/components/controls/local_time'
import * as Fragments from './fragments'
import { getPaymentSum} from 'application/common/invoice_helpers'
import { MoneySubstraction } from 'application/common/currency_helpers'
import { exists } from 'application/common/data_helpers'
import { Spacer } from 'application/components/pages/_common'
import { FlexBox } from 'application/components/fragments/flex_box'
import { isCancelled } from 'application/common/payment'




const InvoiceItems = (
    {
        invoiceData, 
        payments 
    }
) => {
    const { t } = useTranslation()

    const [paymentsState, setPaymentsState] = useState(
        payments.map(
            p => (
                {
                    ...p, 
                    infoVisible: false
                }
            )
        )
    )

    const betragInvoice = Number(invoiceData.grossTotal)
    const paymentTotal = payments.length > 0 
        ? getPaymentSum(
            payments.filter(
                v=> !exists(v.cancellation)
            )
        ) 
        : 0

    const isOpen = betragInvoice >= paymentTotal
    const isTooMuch = betragInvoice < paymentTotal
    const rest = isOpen 
        ? MoneySubstraction(invoiceData.grossTotal, paymentTotal) 
        : MoneySubstraction(paymentTotal, invoiceData.grossTotal)

    const labelStatusPaid = isOpen 
        ? t('Open amount') 
        : isTooMuch 
            ? t('Too much') 
            : t('No open amount')


    const handlePaymentInfoVisibility = payment => {
        setPaymentsState(paymentsState.map(p => 
            p.id !== payment.id
                ? p
                : {
                    ...p,
                    infoVisible: !p.infoVisible
                }
        ))
    }

    return <React.Fragment>
        <FlexBox justifyContent='space-between'>
            <span>
                {t('Invoice amount')}
            </span>
            <span>
                {
                    FormatCurrencyDE(betragInvoice)
                }
            </span>
        </FlexBox>
        <PushBottom16 />
        <FlexBox justifyContent='space-between'>
            <span>
                {t('Payments')}
            </span>
            <span>
                { !payments.length && '/'}
            </span>
        </FlexBox>
        {
            paymentsState.map((payment, index) => {
                const paymentIsCancelled = isCancelled(payment)

                return <React.Fragment key={index}>
                    <PushBottom8 />
                    <FlexBox justifyContent='space-between'>
                            
                        <Fragments.PaymentInformation>
                            <Fragments.PaymentName>
                                <span>{payment.creator.name}</span>

                                {
                                    ( exists(payment.description) 
                                        || (paymentIsCancelled && exists(payment.cancellation.reason))
                                    ) && <Fragments.PaymentInfoButton>
                                        <IconButton 
                                            iconKey='info_outline' 
                                            isClickable={true}
                                            iconSize='16px' 
                                            width='16px'
                                            height='16px' 
                                            onButtonClick={
                                                () => {
                                                    handlePaymentInfoVisibility(payment)
                                                }
                                            } 
                                        />
                                    </Fragments.PaymentInfoButton>
                                }

                                <Spacer dotted cssMargin='0 8px' />
                                <Fragments.PaymentAmount 
                                    canceled={paymentIsCancelled}
                                >
                                        - {FormatCurrencyDE(payment.amount)}
                                </Fragments.PaymentAmount>
                            </Fragments.PaymentName>

                            {
                                (
                                    exists(payment.description) 
                                    || (exists(payment.cancellation) 
                                    && exists(payment.cancellation.reason)))
                                    && <Fragments.PaymentInfo isVisible={payment.infoVisible}>
                                        {
                                            exists(payment.description) && <React.Fragment>
                                                <strong>{t('Comment')}:</strong>
                                                &nbsp;
                                                {
                                                    payment.description
                                                }
                                                <br/><br/>
                                            </React.Fragment>
                                        }
                                        {
                                            paymentIsCancelled && exists(payment.cancellation.reason) && <React.Fragment>
                                                <strong>{t('Cancellation reason')}:</strong>
                                                &nbsp;
                                                {
                                                    payment.cancellation.reason
                                                }
                                            </React.Fragment>
                                        }
                                        {
                                            paymentIsCancelled && <React.Fragment>
                                                <br/>
                                                <strong>{t('Cancelled by')}:</strong>
                                                &nbsp;
                                                {
                                                    payment.cancellation.creator.name
                                                }
                                            </React.Fragment>
                                        }
                                    </Fragments.PaymentInfo>
                            }                               
                            <Fragments.PaymentDate>
                                <DateComponent date={payment.createdAt} />
                                {
                                    paymentIsCancelled 
                                        && <Fragments.PaymentCanceled>
                                            (
                                            {t('canceled at')} 
                                            <DateComponent date={payment.cancellation.createdAt} />, 
                                            <LocalTime date={payment.cancellation.createdAt}/>
                                            )
                                        </Fragments.PaymentCanceled>
                                }
                            </Fragments.PaymentDate>                                
                        </Fragments.PaymentInformation>                           
                    </FlexBox>
                </React.Fragment>         
            })
        }
        <Fragments.Divider />
        <FlexBox justifyContent='space-between'>
            <span>
                {labelStatusPaid}
            </span>
            {
                isOpen 
                    ? <Fragments.TextOA>
                        { FormatCurrencyDE(rest) }
                    </Fragments.TextOA> 
                    : 
                    isTooMuch && <Fragments.TextTM>
                        { FormatCurrencyDE(rest) }
                    </Fragments.TextTM>
            }
        </FlexBox>
    </React.Fragment>
}


export default InvoiceItems