import { connect } from 'react-redux'
import MessageDetails from './component'
import { downloadAttachment } from 'application/redux/actions/attachments/index'
import { openDialogByName } from 'application/redux/actions/dialogs'

const mapStateToProps = state => {
    return {
        detailsLoaded: state.pages.inbox.detailsLoaded,
        messageDetails: state.pages.inbox.details,
        messageLoadedAndNotFound: state.pages.inbox.messageLoadedAndNotFound,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteMessage: (
            name,
            initiallyLoaded, 
            payload, 
            title
        ) => {
            dispatch(
                openDialogByName(
                    name,
                    initiallyLoaded, 
                    payload, 
                    title
                )
            )
        },
        downloadAttachment: (
            attId, 
            attName, 
            damageId
        ) => {
            dispatch(
                downloadAttachment(
                    attId, 
                    attName, 
                    damageId
                )
            )
        }
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(MessageDetails)