import React, { useMemo } from 'react'
import { HeaderContainer } from './fragments'
import { useQuery } from 'application/components/hooks/use_query'
import { Headline2, FormHeadline } from 'application/components/fragments/typography'
import { ROUTE_PARAMETERS } from './route_parameters'
import { IMAGES } from 'application/constants'
import Image from 'application/components/controls/image'


const getIsCompany = (query) => {
    const isCompanyStringValue = query.getStringParam(ROUTE_PARAMETERS.IS_COMPANY_PARAM)
    return isCompanyStringValue === 'true'
}

const getCustomerName = (query, isCompany) => {
    if (isCompany) {
        return query.getStringParam(ROUTE_PARAMETERS.COMPANY_NAME_PARAM)
    } else {
        const firstName = query.getStringParam(
            ROUTE_PARAMETERS.FIRST_NAME_PARAM
        )
        const lastName = query.getStringParam(
            ROUTE_PARAMETERS.LAST_NAME_PARAM
        )
        return `${firstName} ${lastName}`
    }
}


export const ExtCreateDamageHeader = ({children}) => {
    const query = useQuery()
    const accountName = query.getStringParam(
        ROUTE_PARAMETERS.ACCOUNT_NAME_PARAM
    )    
    const isCompany = getIsCompany(query)
    const customerName = getCustomerName(query, isCompany)

    const accountImage = useMemo(() => {
        if(accountName.toLowerCase().includes('egger')){
            return IMAGES.ACCOUNT_EGGER
        }
        if(accountName.toLowerCase().includes('könig')){
            return IMAGES.ACCOUNT_KOENIG

        }
        if(accountName.toLowerCase().includes('nahetal')){
            return IMAGES.ACCOUNT_NAHETAL

        }
        return IMAGES.ACCOUNT_ZYRULL
    }, [accountName])
    
    return <HeaderContainer>
        <Headline2>
            <Image data={{...accountImage, height:'68px'}} />
        </Headline2>
        <Headline2>
            {accountName}
        </Headline2>
        
        <FormHeadline>
            Schadenmeldung von {customerName}
        </FormHeadline>
        {children}
    </HeaderContainer>
}