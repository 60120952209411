import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogCreateMessageWorkflowPanelSaga(action) {
    yield put(showWaitingScreen('Creating message'))
    yield resourceCall(
        api.gateway.messages.postMessage, 
        { damageId : action.metaData.damageId },
        action.payload
    )
    yield put(showToast('Message has been successfully sent'))
    yield put(hideWaitingScreen())
}