import React from 'react'
import ActionButton from './action_button'
import styled from 'styled-components'

const StyledActionButton = styled.div`
    button {
        background-color: ${props => props.secondary ? 'transparent' : props.theme.color.gray70};
        padding: 0 16px ;
        margin-right: ${props => props.marginRight};
        :hover {
            background: ${props => props.hoverBackground ? props.hoverBackground : props.secondary ? props.theme.color.gray5 : props.theme.color.anthracite};
        }
        
        :active {
            background: ${props => props.hoverBackground ? props.hoverBackground : props.secondary ? props.theme.color.gray10 : props.theme.color.black};
                outline: none;
        }
        
        :focus {
            outline: none;
        }
    }   
    display: flex;
`

const OverridedActionButton = ({
    hoverBackground = '',
    activeBackground = '',
    buttonClassName = '',
    buttonText = '',
    strechted = false,
    disabled = false,
    onButtonClick,
    secondary = false,
    icon = '',
    marginRight = '0'
}) => {
    return <StyledActionButton 
        marginRight={marginRight} 
        className="action-button" 
        hoverBackground={hoverBackground} 
        activeBackground={activeBackground}
        secondary={secondary}
    >
        <ActionButton
            className={buttonClassName}
            buttonText={buttonText}
            strechted={strechted}
            disabled={disabled}
            onButtonClick={onButtonClick}
            secondary={secondary} 
            icon={icon}
        />
    </StyledActionButton>
}

export default OverridedActionButton