import React from 'react'
// import IconButtonWithLabel from 'application/components/controls/icon_button_with_label'
// import { useTranslation } from 'react-i18next'
import ThemedPreloader from 'application/components/controls/themed_preloader'
import { withRouter } from 'react-router-dom'

export default withRouter(({documentCenterArchiveDownloading, onDownloadDamageDocuments, hasAttachments, match}) => {
    // const {t} = useTranslation()
    return <React.Fragment>
        {
            documentCenterArchiveDownloading && <ThemedPreloader small={true} paddingTop='0' />
        }
        {/* <IconButtonWithLabel onButtonClick={() => onDownloadDamageDocuments(match.params.damageId)} label={t('Download all')} iconKey='get_app' disabled={documentCenterArchiveDownloading || !hasAttachments} />  */}
    </React.Fragment>
})