import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'




export function* dialogGetLawfirmSaga(action) {

    const { 
        dialogName, 
        metaData
    } = action
    
    const { 
        damageId, 
        changeMode
    } = metaData
    
    let lawfirm = null


    const customer = yield resourceCall(
        api.gateway.damage.getVehicleKeeperByDamageId, 
        {
            damageId
        }
    )

    // const preloadedLawyersList = yield resourceCall(
    //     api.gateway.participants.getLawyers,
    //     {
    //         limit: 5
    //     }
    // )

    //needed to check state of old lawfirm if lawfirm is changed
    if(changeMode){
        lawfirm = yield resourceCall(
            api.gateway.lawyerCommunication.getLawfirm, 
            {
                damageId
            }
        )
    }


    yield put(
        updateDialogDataByName(
            dialogName, 
            {
                lawfirm,
                customer,
                // preloadedLawyersList
            }
        )
    )
}