import React from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextInput } from 'application/components/controls/form'
import { Footer } from 'application/components/dialogs/_common/footer'
import PhoneNumberInput from 'application/components/controls/form/phone_number_input'
import { PartialForms } from 'application/components/forms'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import TwoColumns from 'application/components/forms/_common'
import YesNoSelect from 'application/components/controls/form/yes_no_select/component'
import { GravatarComponent } from 'application/components/building_blocks/gravatar'
import {useGravatar} from 'application/components/hooks/use_gravatar'
import { notNull } from 'application/common/data_helpers'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { useCustomerDataPlausibility, useStatefulForm } from 'application/components/hooks'





const EditCustomer = ( 
    { 
        abort, 
        setAbortBlocked,
        setDataChanged, 
        onEditCustomer, 
        dialog,
    }
) => {
    const { t } = useTranslation()

    const emptyCustomer = {
        isEntitledToTaxDeduction: false,
        firstName: '',
        lastName: '',
        address: {
            line1: '',
            postalCode: '',
            locality: ''
        },
        email: '',
        iban: '',
        externalReferenceId: '',
        mobilePhoneNumber: '',
        isCompany: false,
    }

    const {
        customer = null
    } = dialog.payload

    const customerData = notNull(customer) 
        ? customer
        : emptyCustomer

    const {
        setFirstName,
        setLastName,
        setCompanyName,
        getInitials,
        getFullName 
    } = useGravatar(
        customerData.firstName,
        customerData.lastName,
        customerData.isCompany,
        customerData.companyName
    )

    const defaultValues = {
        ...emptyCustomer,
        ...customerData
    }

    const { 
        register,
        errors, 
        handleSubmit, 
        getValues, 
        control, 
        setValue, 
        formStateValues,
        updateFormState
    } = useStatefulForm({defaultValues})
    
    const onDataChange = () => {
        updateFormState()
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const successMsg = getFullName() + ' ' + t('has been saved')

    const onSubmitSuccess = () => {

        const payloadToSend = {
            ...getValues(),
            isCompany: customerData.isCompany
        }

        const metaData = {
            customerId: customerData.customerId,
            isCompany: customerData.isCompany,
            damageId: dialog.payload?.damageId || null,
            fromDialogName: dialog.payload?.fromDialogName || null,
            successMsg
        }

        onEditCustomer(
            dialog.name, 
            payloadToSend, 
            metaData
        )
    }

    const {
        infoBox = null,
        recommendContactData = false
    } = dialog.payload

    const { 
        customerDataIsPlausible 
    } = useCustomerDataPlausibility(formStateValues)

    const showRecommendContactDataInfoBox = recommendContactData && !customerDataIsPlausible

    return <React.Fragment>
        <DialogMainContent flexDirection='row'>
            <GravatarComponent
                getInitials = {getInitials}
                getFullName = {getFullName}
                cssWidth='40%' 
            >
                {                    
                    notNull(infoBox) && <FlexBox 
                        paddingLeft='16px'
                        paddingRight='32px'
                        paddingTop='16px'
                    >
                        <InfoBox
                            type={infoBox.type} 
                            direction='row' 
                        >
                            <FontIcon icon='info_outline' />
                            <Spacer basis='10px' />
                            <FlexBox flexDirection='column'>
                                <span>
                                    {t(infoBox.text)}
                                </span>
                            </FlexBox>
                        </InfoBox>
                    </FlexBox>
                }

                {                    
                    showRecommendContactDataInfoBox && <FlexBox 
                        paddingLeft='16px'
                        paddingRight='32px'
                        paddingTop='16px'
                    >
                        <InfoBox
                            type={INFOBOX.TYPES.WARNING} 
                            direction='row' 
                        >
                            <FontIcon icon='info_outline' />
                            <Spacer basis='10px' />
                            <FlexBox flexDirection='column'>
                                <span>
                                    {t('Please provide at least email or phone number.')}
                                </span>
                            </FlexBox>
                        </InfoBox>
                    </FlexBox>
                }
            </GravatarComponent>



            <FlexBox 
                flexBasis='60%' 
                flexDirection='column'
            >
                {
                    !customerData.isCompany 
                        ? <React.Fragment>
                            <TextInput
                                label={t('Firstname')}
                                name='firstName'
                                register={register}
                                validate={{notEmpty: validationMethods.notEmpty}}
                                error={errors.firstName}
                                onChange={(e) => {
                                    onDataChange()
                                    setFirstName(e.target.value)
                                }}
                            />
                            <TextInput
                                label={t('Lastname')}
                                name='lastName'
                                register={register}
                                validate={{notEmpty: validationMethods.notEmpty}}
                                error={errors.lastName}
                                onChange={(e) => {
                                    onDataChange()
                                    setLastName(e.target.value)
                                }}
                            />
                        </React.Fragment>
                        : 
                        <TextInput
                            label={ t('Company name')}
                            name='companyName'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.companyName}
                            onChange={(e) => {
                                onDataChange()
                                setCompanyName(e.target.value)
                            }}
                        />
                }
                        
                <PartialForms.AddressDataPartialForm
                    register={register}
                    errors={errors}
                    onChange={onDataChange}
                />

                <TextInput
                    label={t('Email')}
                    name='email'
                    register={register}
                    validate={{mustBeEmail: validationMethods.mustBeEmail}}
                    error={errors.email}
                    onChange={(e) => {
                        onDataChange()
                    }}
                />
                <PhoneNumberInput
                    defaultValue={customerData.mobilePhoneNumber}
                    validate={null}
                    control={control}
                    label={t('Mobile phone number')}
                    name='mobilePhoneNumber'
                    register={register}
                    error={errors.mobilePhoneNumber}
                    onChange={onDataChange}
                    setValue={setValue}
                />
                <TextInput
                    label={t('IBAN')}
                    name='iban'
                    register={register}
                    validate={ {mustBeIBAN: validationMethods.mustBeIBAN} }
                    error={errors.iban}
                    onChange={onDataChange}
                />
                <TwoColumns
                    columnLeft={
                        <TextInput
                            label={t('Customer number')}
                            name='externalReferenceId'
                            register={register}
                            validate={null}
                            error={errors.externalReferenceId}
                            onChange={onDataChange}
                        />
                    }
                    columnRight={
                        <YesNoSelect
                            register={register}
                            setValue={setValue}
                            initialValue={defaultValues.isEntitledToTaxDeduction}
                            name='isEntitledToTaxDeduction'
                            label={t('Is entitled to tax deduction')}
                            onChange={onDataChange}
                            menuPlacement='top'
                        />
                    }
                />

            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Save'
        />
    </React.Fragment>
}


export default withDialogDataLoader(
    EditCustomer
) 