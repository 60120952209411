import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { DialogMainContent } from 'application/components/fragments/dialog' 
import { Paragraph } from 'application/components/fragments/typography'
import { Footer } from 'application/components/dialogs/_common/footer'
import { Spacer,PushBottom32 } from 'application/components/pages/_common'
import { INFOBOX } from 'application/constants'
import InfoBox from 'application/components/controls/info_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'


const DeleteCustomState =
(
    { 
        abort, 
        dialog, 
        onDeleteCustomState
    }
) => {
    const { 
        customStateName, 
        customStateId, 
        systemStateName 
    } = dialog.payload
        
    const {t} = useTranslation()

    const localizedSystemStateName = t(systemStateName)

    return <React.Fragment>
        <DialogMainContent>

            <Paragraph cssPadding='16px 0'>
                <Trans i18nKey='Are you sure you want to delete this state?'>
                    Do you want to delete <strong>{{customStateName}}</strong> ?
                </Trans>
            </Paragraph>
            <InfoBox 
                type={INFOBOX.TYPES.WARNING} 
                direction='row' 
                cssMargin='0' 
            >
                <FontIcon icon='info_outline' />
                <Spacer basis='10px' />
                <span>
                    <Trans i18nKey='If you delete this custom state, all damage cases with this state will fall back to the associated system state.'>
                                    If you delete this custom state, all damage cases with this state will fall back to the system state <strong>{{localizedSystemStateName}}</strong>.
                    </Trans>
                    <br />
                    {t('This process is not reversible.')}
                </span>
            </InfoBox>
            <PushBottom32/>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            submitText='Delete custom state'
            onSubmit={
                () => onDeleteCustomState(
                    dialog.name, 
                    customStateId, 
                    customStateName
                )
            }
        />
    </React.Fragment>

}

export default withDialogDataLoader(DeleteCustomState) 