import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { GroupSearchSelect } from 'application/components/building_blocks/group_search_select'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'

const EditUserGroups = (
    { 
        abort, 
        dialog, 
        onSaveUserGroups
    }
) => {
    const {t} = useTranslation()
    const currentUserGroups = dialog.payload.groups
    const [selectedGroups, setSelectedGroups] = useState(currentUserGroups)

    const { payload } = dialog
    const { adminUsers } = payload

    const userIsLastAdmin = adminUsers.total === 1 && adminUsers.users[0].id === payload.id

    if(userIsLastAdmin){
        return <React.Fragment>
            <DialogMainContent cssHeight='130px'>
                <Spacer basis='40px' />
                <InfoBox type={INFOBOX.TYPES.WARNING} direction='row' cssMargin='0'>
                    <FontIcon icon='error_outline' />
                    <Spacer basis='10px' />
                    <span>{t('User groups cannot be edited for the only admin user in this account.')}</span>
                    <Spacer />
                </InfoBox>
            </DialogMainContent>
            <Footer
                onAbort={abort}
                submitText='Save group memberships'
                disabled={true}
            />
        </React.Fragment>
    }

    return <React.Fragment>
        <DialogMainContent cssHeight='600px'>
            <GroupSearchSelect
                initialSelectedGroups={currentUserGroups}
                onSelectedGroupsChange={setSelectedGroups}
            />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={() => onSaveUserGroups(dialog.name, payload.id, {groups: selectedGroups} )}
            submitText='Save group memberships'
            disabled={selectedGroups.length === 0}
        />
    </React.Fragment>

}

export default withDialogDataLoader(EditUserGroups)