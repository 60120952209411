import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from 'application/components/controls/hyperlink'
import * as Fragments from './fragments'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { withRouter } from 'react-router-dom'
import { withStatuslistLoader } from 'application/components/higher_order_components'

export const DamageStateChangedMetaContent = withStatuslistLoader(withRouter(
    (
        {
            notification, 
            onLinkClick, 
            inline, 
            statusList,
            match
        }
    ) => {
        const {t} = useTranslation()

        const {payload} = notification
        const damageLink =  getAccountLinkHref(
            match, 
            `/damages/${payload.damage.id}`
        )

        const oldStateName = statusList.data.find(state => state.id === payload.oldStateId).name
        const newStateName = statusList.data.find(state => state.id === payload.newStateId).name
        
        return <Fragments.MetaContentContainer inline={inline}>
            <Fragments.ActivityDescription 
                label='Damage'
                value={payload.damage.vehicle.licensePlate}
            />
            <Fragments.ActivityDescription 
                label='State'
                value={`${oldStateName} → ${newStateName}`}
            />
            <Fragments.ActivityLink inline={inline}>
                <Link 
                    onClick={onLinkClick} 
                    to={damageLink}
                >
                    {t('jump to damage')}
                </Link>
            </Fragments.ActivityLink>
        </Fragments.MetaContentContainer>
    }))