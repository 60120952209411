export const en = {
    translation: {
        'login': 'Login',
        'username': 'Username',
        'password': 'Password',
        'legal notice' : 'Legal Notice',
        'privacy' : 'Privacy Policy',
        'conditions' : 'Term & Conditions',
        'aktualisiert': 'updated',
        'erstellt': 'created',
        'Nachricht': 'Message',
        'Aufgabe': 'Task',
        'Dokument': 'Document',
        'Schaden': 'Damage',
        'Polizei': 'Police',
        'Zeuge': 'Witness',
        'GeschaedigterHalter': 'Vehicle keeper',
        'Bearbeiter': 'Assignee',
        'Anwalt': 'Lawyer',
        'UnfallgegnerHalter': 'Accident opponent',
        'Unfallhergang': 'Accident description',
        'Fahrzeug': 'Vehicle',
        'DamageAssigneeChangedNotificationTitle': 'Damage was assigned',
        'DamageCreatedNotificationTitle': 'Damage was created',
        'DamageDeletedNotificationTitle': 'Damage was deleted',
        'DamageDescriptionChangedNotificationTitle': 'Damage report changed',
        'DamageIncidentDateChangedNotificationTitle': 'Damage incident date changed',
        'DamageJobNumberChangedNotificationTitle': 'Damage job number changed',
        'DamageNoteChangedNotificationTitle': 'Case note changed',
        'DamageStateChangedNotificationTitle': 'Damage state changed',
        'DamageTypeChangedNotificationTitle': 'Damage type changed',
        'DocumentCreatedNotificationTitle': 'New document was created',
        'DocumentDeletedNotificationTitle': 'Document was deleted',
        'InvoiceCreatedNotificationTitle': 'New invoice was created',
        'InvoiceDeletedNotificationTitle': 'Invoice was deleted',
        'TaskCommentCreatedNotificationTitle': 'New comment',
        'TaskCreatedNotificationTitle': 'Task was created',
        'TaskDeletedNotificationTitle': 'Task was deleted',
        'TaskStateChangedNotificationTitle': 'Task state changed',
        'VehicleKeeperChangedNotificationTitle': 'Vehicle keeper changed',
        'VehicleUpdatedNotificationTitle': 'Vehicle data changed',
        'groupAccount AdminsDescription': 'Users in this groups have administrative permissions in ALL branches.',
        'groupBenutzerDescription': 'Users in this groups have regular permissions in ALL branches.',
        'EmailSettingTitle': 'Email',
        'RealtimeNotificationSettingTitle': 'Real time'
    }
}