import React, {useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import ForActiveBranch from './container'
import { requestCurrentBranchChange } from 'application/redux/actions/auth'
import * as storage from 'application/storage'
import { exists } from 'application/common/data_helpers'

const mapStateToProps = state => ({
    currentBranch: state.auth.currentBranch,
    currentBranchLoading: state.auth.currentBranchLoading,
    loggedIn: state.auth.loggedIn
})
const mapDispatchToProps = dispatch => ({ 
    onLoadBranch: (branchId) => dispatch(
        requestCurrentBranchChange(branchId)
    )
})

const withActiveBranchComponent = Component => ({ ...props }) => {
    const {
        currentBranch, 
        onLoadBranch, 
        loggedIn, 
        match, 
        currentBranchLoading
    } = props
    const accountId = match.params.accountId
    const branchId = match.params.branchId || storage.currentBranch.get(accountId)

    const shouldLoadBranch = //only load branch if...
        loggedIn && //... user is logged in and account data is loaded
        !currentBranchLoading && //... there is currently no request for the branch running
        exists(branchId) && //... the url contains a branch id
        (currentBranch === null || currentBranch.id !== branchId) //... the branch id in the url is different from the id of the current branch

    useEffect(()=>{
        if(shouldLoadBranch){
            onLoadBranch(branchId)
            storage.currentBranch.store(accountId, branchId)
        }
    },[accountId, branchId, onLoadBranch, shouldLoadBranch])


    return <ForActiveBranch>
        <Component {...props} />
    </ForActiveBranch>
}

export const withActiveBranch = compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    compose(
        withRouter,
        withActiveBranchComponent
    )
)