
import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { toggleBranchSidebar } from 'application/redux/actions/pages/branch_management'
import { connect } from 'react-redux'
import {BranchSidebar} from './component'

const mapStateToProps = state =>  {

    const branchData = state.common.allBranches.find(
        b => b.id === state.pages.branchManagement.branchSidebarDataId
    )

    return {
        isVisible: state.pages.branchManagement.branchSidebarVisible,
        currentBranchId: state.auth.currentBranchLoaded 
            ? state.auth.currentBranch.id 
            : null,
        branchData
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onSidebarClose: () => dispatch(
            toggleBranchSidebar(false)
        ),
        onEditBranch: (branchData) => dispatch(
            openDialogByName(
                DIALOG.NAME.EDIT_BRANCH, 
                true, 
                branchData, 
                'Edit branch')
        ),
        onRemoveBranch: (id) => dispatch(
            openDialogByName(
                DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_BRANCH, 
                false,
                {
                    branchId:id
                }, 
                'Delete branch'
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchSidebar)