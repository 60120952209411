import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import TimeRangeFilter from 'application/components/building_blocks/filters/time_range_filter'
import DamageCountChart from 'application/components/charts/damage_count'
import { ROUTE_PARAMETERS } from 'application/constants'
import { Spacer } from 'application/components/pages/_common'
import { FlexBox } from 'application/components/fragments/flex_box'

const ControllingStatisticsDamageCount = (
    {
        loadingFailed, 
        widgetKey, 
        onRefresh, 
        payload
    }
) => {
    const {t} = useTranslation()
    
    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    const {chartData} = payload
    
    return <FlexBox flexDirection='column'>
        <TimeRangeFilter 
            routeParameters={ROUTE_PARAMETERS.WIDGET_CONTROLLING_STATISTICS_DAMAGES_COUNT.FILTERS}
            onChange={() => {onRefresh(widgetKey)}}
        />
        <Spacer basis='20px' />
        <DamageCountChart
            chartData={chartData}
        />
    </FlexBox>
}

ControllingStatisticsDamageCount.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { ControllingStatisticsDamageCount }