import React from 'react'
import DocumentsSidebar from 'application/components/building_blocks/document_sidebar'

export default ({atLeastOneMoIsActive, documentCenterAttachmentDetails, onHideAttachmentDetails, onChangeCategory}) => {
    return !atLeastOneMoIsActive 
        && documentCenterAttachmentDetails.visible 
        && <DocumentsSidebar
            attachmentDetails={documentCenterAttachmentDetails}
            onClose={onHideAttachmentDetails}
            showCategorySelect={true}
            onChangeCategory={onChangeCategory}
        />
}