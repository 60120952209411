import React, {useState, useRef } from 'react'
import styled, {withTheme} from 'styled-components'

import { useEscapeKey, useClickOutside } from 'application/components/hooks'
import IconButton from 'application/components/controls/icon_button/component'
import { exists } from 'application/common/data_helpers'
import Image from 'application/components/controls/image'

const DisplayContext = styled.div`
    display: flex;
    flex-direction: column;
`

const DisplayTrigger = styled.div`
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-right: ${props => props.isLabel ? '8px' : '0px'};
    align-items: center;
    height: ${props => exists(props.height) ? props.height: 'auto'};
    background: ${props => props.menuOpen ? props.theme.color.gray70 : 'transparent'};

    i {
        color: ${props => props.menuOpen ? props.theme.color.white : props.theme.color.gray70};
    }

    span {
        color: ${props => props.menuOpen ? props.theme.color.white : props.theme.color.gray70};
    }

    :hover i, 
    :hover span {
        color: ${props => props.shouldHoverAsUserMenu ?  props.theme.color.gray70 : props.theme.color.white };
    }

    :hover {
        background: ${props => props.shouldHoverAsUserMenu ?  props.theme.color.black10 : props.theme.color.gray70 };
        color: ${props => props.shouldHoverAsUserMenu ? props.theme.color.gray70 : props.theme.color.white};
    }

    :active {
            background: ${props => props.shouldHoverAsUserMenu ? props.theme.color.black20 : props.theme.color.gray70};
            color: ${props => props.theme.color.black};
    }

`

const StyledContextMenu = styled.div`
    position: relative;
`

const StyledContextMenuList = styled.ul`
    position: absolute;
    top: ${props => props.top};
    bottom: ${props => props.bottom};
    right: 0;
    display: inline;
    list-style-type: none;
    z-index: 2002;
    margin: 0;
`

export const StyledNameLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.menuOpen ? props.theme.color.white : props.theme.color.gray70};
    padding-left: 8px;
    cursor: pointer;
`

const ImageIconButton = styled.div`
    cursor: pointer;
    height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px;

    & span {
        margin-right: 4px;
    }

    &:hover{
        background: #e6e6e6;
    }
`




const ContextMenu = (
    {
        children, 
        isInDialog, 
        isTop = false,
        onMenuOpen = () => {}, 
        onMenuClose = () => {}, 
        label = '', 
        theme, 
        iconKey,
        image = null,
        isUserMenu = false,
        userName = '',
        width = '32px',
        height = '32px',
        iconSize='20px',
        greyHover = false,
        top = '34px'
    }
) => {


    const topCssValue = isTop 
        ? 'unset' 
        : top 

    const bottomCssValue = isTop
        ? '16px'
        : 'unset'

    const [menuOpen, setMenuOpen] = useState(false)

    const closeMenu = () => {
        setMenuOpen(false)
        onMenuClose()
    }
    
    const handleMenu = () => {
        if(menuOpen){
            closeMenu()
        } else{
            setMenuOpen(true)
            onMenuOpen()
        }
    }
    
    const handleParent = () => {
        if(menuOpen){
            closeMenu()
        }
    }

    const ref = useRef()
    useClickOutside(ref, closeMenu)
    useEscapeKey(closeMenu)


    const shouldHoverAsUserMenu = () => {
        return ( isUserMenu && !menuOpen ) || ( greyHover && !menuOpen )
    }


    return <StyledContextMenu
        isInDialog={isInDialog}  
        ref={ref} 
        onClick={handleParent}
    >
        <DisplayContext>
            <DisplayTrigger 
                className='context-menu'
                onClick={handleMenu} 
                menuOpen={menuOpen}
                strechted={label !== ''} 
                isLabel={label !== ''}
                height={height}
                shouldHoverAsUserMenu={shouldHoverAsUserMenu()}
            >               
               
                {
                    image !== null ? <ImageIconButton>
                        <span>{isUserMenu ? userName : label}</span><Image data={image} />
                    </ImageIconButton> : <React.Fragment>
                        {
                            isUserMenu && <StyledNameLabel
                                menuOpen={menuOpen}
                            >
                                {userName}
                            </StyledNameLabel>
                        }
                        <IconButton
                            onButtonClick={handleMenu}
                            iconKey={iconKey ? iconKey : 'more_horiz'}
                            width={width}
                            height={height}
                            iconSize={iconSize}
                            color={theme.color.anthracite}
                            hoverBackground='none'
                            activeBackground='none'
                        /> {
                            ! isUserMenu &&
                    label !== '' && <span>
                                {label}
                            </span>
                        }
                    </React.Fragment>
                }

                
            </DisplayTrigger>
            {
                menuOpen && <StyledContextMenuList                    
                    top={topCssValue}
                    bottom={bottomCssValue}
                    isInDialog={isInDialog}
                >
                    {children}
                </StyledContextMenuList>
            }
        </DisplayContext>
        
    </StyledContextMenu>
}

export default withTheme(ContextMenu)