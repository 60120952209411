import React, {useState} from 'react'
import { withRouter } from 'react-router'
import { SHARED_RESOURCE_TYPE } from 'application/constants'
import { LayoutSharedResource } from 'application/components/pages/_layout'
import { PageContainer } from './sub_components/fragments'
import { ExtServices } from './sub_components/services'
import { ExtCreateDamageDocuments } from './sub_components/documents'
import { ExtCreateDamageHeader } from './sub_components/header'
import { withSharedResourceValidation } from 'application/components/higher_order_components/shared_resource_validation'
import { ExtCreateDamageSuccess } from './sub_components/success'

export const CreateServiceRequest = withSharedResourceValidation()(withRouter((
) => {
    const [damageData, setDamageData] = useState({})
    const [files, setFiles] = useState([])
    const [currentStep, setCurrentStep] = useState(0)
    const [selectedService, setSelectedService] = useState(null)

    const [accordions, setAccordions] = useState([
        {
            menuState: {
                accordionOpen: true,
                name: 'Reifen / Räder'
            },
            id: 'acc1',
            menuIconKey: 'adjust',
            primaryColor: '#666',
            items: [
                {
                    label: 'Reifenkauf',
                    id: 'acc1i1'
                },
                {
                    label: 'Reifentausch',
                    id: 'acc1i2'
                },
                {
                    label: 'Reifenwechsel',
                    id: 'acc1i3'
                },
                {
                    label: 'Auswuchten / Spureinstellung',
                    id: 'acc1i4'
                },
                {
                    label: 'Andere • Reifen / Räder',
                    id: 'acc1i5'
                }
            ]
        },
        {
            menuState: {
                accordionOpen: false,
                name: 'Außen'
            },
            id: 'acc2',
            menuIconKey: 'directions_car',
            primaryColor: '#666',
            items: [
                {
                    label: 'Türgriffe & Schlösser',
                    id: 'acc2i1'
                },
                {
                    label: 'Frontscheinwerfer & Schlussleuchten',
                    id: 'acc2i2'
                },
                {
                    label: 'Frontscheibenwischer',
                    id: 'acc2i3'
                },
                {
                    label: 'Außenverkleidung',
                    id: 'acc2i4'
                },
                {
                    label: 'Andere • Außenseite',
                    id: 'acc2i5'
                }
            ]
        },
        {
            menuState: {
                accordionOpen: false,
                name: 'Innenraum'
            },
            id: 'acc3',
            menuIconKey: 'directions_car',
            primaryColor: '#999',
            items: []
        },
        {
            menuState: {
                accordionOpen: false,
                name: 'Glas'
            },
            id: 'acc4',
            menuIconKey: 'grid_view',
            primaryColor: '#999',
            items: []
        },
        {
            menuState: {
                accordionOpen: false,
                name: 'Lärm / Vibration'
            },
            id: 'acc5',
            menuIconKey: 'campaign',
            primaryColor: '#999',
            items: []
        },
        {
            menuState: {
                accordionOpen: false,
                name: 'Infotainment'
            },
            id: 'acc6',
            menuIconKey: 'sports_soccer',
            primaryColor: '#999',
            items: []
        },
        {
            menuState: {
                accordionOpen: false,
                name: 'Fahrassistenz-Funktionen'
            },
            id: 'acc7',
            menuIconKey: 'edit_road',
            primaryColor: '#999',
            items: []
        },
        {
            menuState: {
                accordionOpen: false,
                name: 'Batterie'
            },
            id: 'acc8',
            menuIconKey: 'battery_charging_full',
            primaryColor: '#999',
            items: []
        },
        {
            menuState: {
                accordionOpen: false,
                name: 'Upgrades & Zubehör'
            },
            id: 'acc9',
            menuIconKey: 'construction',
            primaryColor: '#999',
            items: []
        },
        {
            menuState: {
                accordionOpen: false,
                name: 'Sonstiges'
            },
            id: 'acc10',
            menuIconKey: 'add',
            primaryColor: '#999',
            items: []
        }
    ])
    return <LayoutSharedResource
        sharedResourceType={SHARED_RESOURCE_TYPE.KICKSTART}
    >        
        <PageContainer>
            <ExtCreateDamageHeader />

            {
                currentStep === 0 && <ExtServices 
                    selectedService={selectedService}
                    setSelectedService={setSelectedService}
                    setCurrentStep={setCurrentStep}
                    accordions={accordions}
                    setAccordions={setAccordions}
                />

            }

            {
                currentStep === 1 && <ExtCreateDamageDocuments 
                    files={files}
                    setFiles={setFiles}
                    setCurrentStep={setCurrentStep}
                    selectedService={selectedService}
                    onSubmit={(data) => {
                        setDamageData(data)
                    }}
                />
            }

            {
                currentStep === 2 && <ExtCreateDamageSuccess 
                    damageData={damageData}
                />
            }

        </PageContainer>
    </LayoutSharedResource>
}))