import React from 'react'
import styled, { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { exists } from 'application/common/data_helpers'

const MakeModelContainer = styled.div`
    font-weight: bold;
    color: ${props => props.theme.color.gray70};
    border-radius: 2px;
    border: 1px solid ${props => props.theme.color.gray20};
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
`

export default withTheme(({make = null, model = null, theme}) => {
    if(!exists(make) && !exists(model)){
        return null
    }
    return <MakeModelContainer>
        <FontIcon icon='drive_eta' fontSize={theme.fontSize.headline3} color={theme.color.gray70}  />
        &nbsp;&nbsp;{make !== '' && ` ${make}`} {model}
    </MakeModelContainer>
})