import { useState } from 'react'
import {STEPS_BAR_STATE} from 'application/constants/steps_bar'
import PropTypes from 'prop-types'





/**
 * handles StepsBar 
 * @param {array} steps [ { label: "labelText"}, { label: "labeltext2"}, ... ]
 * @param {number} initialIndex 
 * @returns array state  [ { label: "labelText", state: 'state'}, ... ]
 */
const useSteps = (
    steps, 
    initialIndex = 0
) => {
    const [currentIndex, setCurrentIndex] = useState(initialIndex)

    const mapState = (stateOfSteps, currentIndex) => {
        return stateOfSteps.map(
            (step, i) => {
                switch (true) {
                case (i < currentIndex): 
                    return {
                        ...step,
                        state: STEPS_BAR_STATE.DONE
                    }
                case (i === currentIndex): 
                    return {
                        ...step,
                        state: STEPS_BAR_STATE.CURRENT
                    }
                case (i > currentIndex): 
                default: 
                    return {
                        ...step,
                        state: STEPS_BAR_STATE.UNDONE
                    }
                }
            }
        )
    }

    const goToNextStep = () => {
        const couldMoveForward = currentIndex < steps.length - 1
        const newCurrentIndex =  couldMoveForward
            ? currentIndex + 1
            : currentIndex       
        setCurrentIndex(newCurrentIndex)
    }


    const goToPreviousStep = () => {
        const couldMoveBackward = currentIndex > 0
        const newCurrentIndex =  couldMoveBackward
            ? currentIndex - 1
            : currentIndex
        setCurrentIndex(newCurrentIndex)
    }

    const getStepsState = () => {
        return   mapState(steps, currentIndex) 
    }

    const getCurrentStep = () => {
        return mapState(steps, currentIndex)[currentIndex]
    }


    return {
        getStepsState,
        goToNextStep,
        goToPreviousStep,
        getCurrentStep
    }
}

useSteps.propTypes = {
    steps: PropTypes.array.isRequired, 
    initialIndex: PropTypes.number
}

export  { useSteps }