import { connect } from 'react-redux'
import Lawfirm from './component'
import { getDamageDataWorkflowPanelRequest, getLawfirmWorkflowPanelRequest } from 'application/redux/actions/pages/workflow_panel/shared'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => {
    return {
        selectedTaskLoaded: state.pages.workflowPanel.tasks.selectedTaskLoaded,
        tasksAttachmentDetails: state.pages.workflowPanel.tasks.attachmentDetails,
        documentCenterAttachmentDetails: state.pages.workflowPanel.documentCenter.attachmentDetails,
        hasAttachments: state.pages.workflowPanel.documentCenter.documents.length > 0,
        contactPerson: state.pages.workflowPanel.userData.ansprechpartner,
        documentCenterArchiveDownloading: state.pages.workflowPanel.documentCenter.archiveDownloading,
        damageLoaded: state.pages.workflowPanel.shared.damageLoaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => {
            dispatch(getDamageDataWorkflowPanelRequest())
            dispatch(getLawfirmWorkflowPanelRequest())
        },
        sendMessage: () => {
            dispatch(openDialogByName(DIALOG.NAME.CREATE_MESSAGE_FROM_TRACKING, true, {}, 'Nachricht an Autohaus'))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Lawfirm)