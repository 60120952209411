import React, {useEffect} from 'react'

import ComprehensiveDamageTypeSelect from './component'

const ComprehensiveDamageTypeEffects = (props) => {
    const { comprehensiveDamageTypesLoaded, comprehensiveDamageTypesLoading, onGetComprehensiveDamageTypes } = props
    const shouldLoad = !comprehensiveDamageTypesLoaded && !comprehensiveDamageTypesLoading

    useEffect(() => {
        shouldLoad && onGetComprehensiveDamageTypes()
    }, [onGetComprehensiveDamageTypes, shouldLoad])

    return <ComprehensiveDamageTypeSelect {...props} />
}

export default ComprehensiveDamageTypeEffects