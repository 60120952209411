import React from 'react'
import { Sidebar, Fragments } from '../_common'
import { useTranslation } from 'react-i18next'
import { BulletList, BulletListItem } from 'application/components/widgets/_common'
import { ActionButton, FontIcon } from '@ec/ui-controls-react'
import styled, { withTheme }  from 'styled-components'
import InfoBox from 'application/components/controls/info_box'
import { GeocodedMap } from 'application/components/building_blocks/open_street_map'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { INFOBOX } from 'application/constants'
import { PushBottom32 } from 'application/components/pages/_common'
import { getLatLongForMap } from 'application/common/address_helper'



const Contact = styled.span`
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.anthraciteLight};
`

export const BranchSidebarContent = withTheme(
    (
        {
            onSidebarClose, 
            branchData, 
            theme, 
            onEditBranch,
            onRemoveBranch,
            currentBranchId
        }
    ) => {
        const {t} = useTranslation()

        const branchIsCurrentBranch = branchData.id === currentBranchId

        return <Sidebar 
            onSidebarClose={onSidebarClose} 
            title={t('Branch')} 
            sidebarTitleColor={theme.color.branchPrimary}
        >
            <Fragments.HeadlineSidebar>
                {branchData.name}
            </Fragments.HeadlineSidebar>

            {
                branchIsCurrentBranch &&  <InfoBox
                    type={INFOBOX.TYPES.WARNING} 
                    cssMinHeight='unset'
                    direction='row'
                >
                    <FontIcon icon='info_outline' />  
                    {t('Current active branch could not be deleted')}
                </InfoBox>
            }
            <Fragments.ContentSidebar>
                <BulletList>
                    {
                    branchData.address?.line1 && <BulletListItem hideBullet={true}>
                            {branchData.address.line1}
                        </BulletListItem>
                    }
                    {
                    branchData.address?.locality && <BulletListItem hideBullet={true}>
                            { 
                            branchData.address?.postalCode && <React.Fragment>
                                    {branchData.address.postalCode}&nbsp;
                                </React.Fragment> 
                            }
                            { branchData.address.locality }
                        </BulletListItem>
                    }
                    {
                        branchData?.contact && <React.Fragment>
                            <BulletListItem hideBullet={true}> 
                                <Contact>
                                    { branchData.contact.email }
                                </Contact> 
                            </BulletListItem>
                            <BulletListItem hideBullet={true}>
                                <Contact>{ branchData.contact.phoneNumber } </Contact>  
                            </BulletListItem>
                        </React.Fragment>
                    }
                </BulletList>
                <PushBottom32 />
                <GeocodedMap 
                    addressQuery={branchData.address} 
                    latLong={getLatLongForMap(branchData.address)}
                />
                <PushBottom32 />
            </Fragments.ContentSidebar>
            <Fragments.FooterSidebar>
                <ActionButton 
                    buttonText={t('Edit branch')} 
                    onButtonClick={
                        ()=>{
                            onEditBranch(branchData)
                        }
                    } 
                />
                <IconButtonWithLabel 
                    label={t('Delete branch')} 
                    onButtonClick={
                        ()=>{
                            onRemoveBranch(branchData.id)
                        }
                    }
                    disabled={branchIsCurrentBranch}
                    iconKey='delete'
                />
            </Fragments.FooterSidebar>
        </Sidebar>
    })

export const BranchSidebar = props => {
    if(!props.isVisible) {
        return null
    }
    return <BranchSidebarContent {...props} />
}