import { connect } from 'react-redux'
import { updatePin, validatePinRequest, skipValidation } from 'application/redux/actions/shared_resource'
import SharedResourceLogin from './component'

const mapStateToProps = state => {
    return {
        sharedResource: state.sharedResource
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onValidatePin: (pin, sharedResourceId, storePin) => dispatch(validatePinRequest(pin, sharedResourceId, storePin)),
        onUpdatePin: (pin) => dispatch(updatePin(pin)),
        onSkipValidation: () => dispatch(skipValidation())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SharedResourceLogin)