import styled from 'styled-components'


export const Bar = styled.div`  
    display: flex;
    flex: 100%;
    flex-direction: row;
    height: 48px;
    background: ${props => props.theme.color.gray10};
    justify-content: space-between;
    border-bottom: 1px solid ${props => props.theme.color.gray20};
`

export const Group = styled.div`
    display: flex;
    flex-direction: row;
    height: inherit;
    justify-content: ${ props => props.justifyContent ? props.justifyContent : 'flex-start' } ;
`

export const Cell = styled.div`
    display: flex;
    height: inherit;
    align-items: center;
    padding-left: ${ props => props.paddingLeft ? props.paddingLeft : '8px' };
    padding-right: ${ props => props.paddingRight ? props.paddingRight : '8px' };
    width: ${ props => props.width ? props.width : 'auto' };
`

export const AccountName = styled.span`
    font-size: 12px;
`
