import { api } from 'application/api'
import { call, put, takeLeading, select } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { 
    writeApplicationToken, 
    populateAccounts, 
    setNoPermissionsFound, 
    populateCurrentAccount, 
    populateUserData, 
    loginRequestSuccess, 
    populateDefaultBranchInfo, 
    requestCurrentBranchChange, 
    setCurrentAccountId, 
    setSessionExpired,
    populatePreferredAccount,
    preferredAccountFound,
    refreshPreferredAccount,
    preferredAccountNotFound,
    populatePermissions,
    populateCurrentBranchPermissions
} from 'application/redux/actions/auth'

import { populateAccountsTotal, setNoAccountsFound, setAccountsFound } from 'application/redux/actions/auth'
import { getAccessToken, getCurrentBranchInfo } from 'application/redux/selectors'
import { AccountMismatchError } from 'application/api/errors/account_mismatch_error'
import { showToast } from 'application/redux/actions/notifications'
import * as storage from 'application/storage'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { PermissionError } from 'application/api/errors/permission_error'

function* getAccountsSaga(action) {
    const accessToken = yield select(getAccessToken)
    
    const { 
        offset, 
        limit, 
        orderBy, 
        direction, 
        filterQuery
    } = action
    
    const { 
        accounts, 
        total 
    } = yield call(
        api.gateway.auth.getAccounts, 
        accessToken, 
        offset, 
        limit, 
        orderBy, 
        direction, 
        filterQuery
    )

    if (total > 0) {
        yield put(
            populateAccountsTotal(
                total, 
                accounts[0].id,
                accounts[0].isLocked
            )
        )

        yield put(setAccountsFound())

        yield put(
            populateAccounts(
                accounts, 
                total
            )
        )
    }else{
        yield put(setNoAccountsFound())
    }
}


function* getPreferredAccountSaga() {
    try {
        const accessToken = yield select(getAccessToken)

        const preferredAccountResult = yield call(
            api.gateway.auth.getPreferredAccount,
            accessToken
        )
         
        yield put(
            populatePreferredAccount(preferredAccountResult)
        )
    } catch (e) {
        yield put(
            preferredAccountNotFound()
        )
    }
}


function* setPreferredAccountSaga(action) {
    try {
        const accessToken = yield select(getAccessToken)
        yield call(
            api.gateway.auth.putPreferredAccount,
            {
                id: action.account.id
            },
            accessToken
        )
        yield put(
            populatePreferredAccount(action.account)
        )
        yield put(
            preferredAccountFound()
        )
        yield put(
            showToast('Account set as preferred')
        )
    } catch (error) {
        console.error('error: ', error)
    }
}


function* unsetPreferredAccountSaga(action) {
    try {
        const accessToken = yield select(getAccessToken)

        yield call(
            api.gateway.auth.putPreferredAccount,
            {},
            accessToken
        )
        yield put(
            showToast('Account unset as preferred')
        )
        yield put( 
            refreshPreferredAccount()
        )
    } catch (error) {
        console.error('setPreferredAccountSaga error: ', error)
    }
}


function* chooseAccountSaga(action) {
    yield put(setCurrentAccountId(action.accountId))

    try{
        const accessToken = yield select(getAccessToken)

        //get application token
        const { access_token } = yield call(api.gateway.auth.getApplicationToken, accessToken, action.accountId)
        // write application exist token
        yield put(writeApplicationToken(access_token, action.accountId))

        //set current account
        const account = yield resourceCall(
            api.gateway.auth.getAccountById, 
            {
                accountId: action.accountId
            }
        )
        yield put(populateCurrentAccount(account))

        //get all permissions
        const { permissions } = yield resourceCall(
            api.gateway.auth.getAllPermissions
        )

        const permissionsArray = Object.keys(permissions).map(key => ({
            branchId: key,
            permissions: permissions[key]
        }))

        if(permissionsArray.length === 0){
            throw new PermissionError()
        }
        yield put(populatePermissions(permissionsArray))

        //get user data
        const userDataResponse = yield resourceCall(api.gateway.auth.getUserData)
        yield put(populateUserData(userDataResponse))

        //set branches default branch
        const { branches } = account
        yield put(populateDefaultBranchInfo(branches))

        //if no branch is selected (eg after refresh), load the default branch
        const { currentBranchLoaded, currentBranchLoading } = yield select(getCurrentBranchInfo)
        const proposedBranchId = storage.currentBranch.get(action.accountId) || branches[0]

        const proposedBranchValid = permissionsArray.some(p => p.branchId === proposedBranchId)
        const branchId = proposedBranchValid
            ? proposedBranchId
            : branches[0]

        
        yield put(populateCurrentBranchPermissions(permissionsArray, branchId))

        if(!currentBranchLoaded && !currentBranchLoading){
            yield put(requestCurrentBranchChange(branchId))
            yield storage.currentBranch.store(action.accountId, branchId)
        }

        yield put(loginRequestSuccess())
    }catch(e){
        if(e instanceof PermissionError){
            yield put(setNoPermissionsFound())
        }else if(e instanceof AccountMismatchError){
            yield put(setSessionExpired())
        }else if (e.response.status === 403) {
            yield put(setNoPermissionsFound())
        }
    }

}

export function* accountsWatcher() {
    yield takeLeading(
        ACTIONS.AUTH_REQUEST_CURRENT_ACCOUNT_BY_ID, 
        chooseAccountSaga
    )
    yield takeLeading(
        ACTIONS.AUTH_REQUEST_ACCOUNTS, 
        getAccountsSaga
    )
    yield takeLeading(
        ACTIONS.AUTH_REQUEST_PREFERRED_ACOUNT, 
        getPreferredAccountSaga
    )
    yield takeLeading(
        ACTIONS.AUTH_PREFERRED_ACOUNT_SET_ACCOUNT_AS_PREFERRED, 
        setPreferredAccountSaga
    )
    yield takeLeading(
        ACTIONS.AUTH_PREFERRED_ACOUNT_UNSET_ACCOUNT_AS_PREFERRED, 
        unsetPreferredAccountSaga
    )
}