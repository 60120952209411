import React from 'react'
import styled from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'

const StyledLink = styled.a`
    color: ${props => props.secondary ? props.theme.color.gray70 : props.theme.color.white};
    & i {
        color: ${props => props.secondary ? props.theme.color.gray70 : props.theme.color.white} !important;
        margin-right: 4px;
        margin-left: -4px;
    }
    background-color: ${props => props.secondary ? 'transparent' : props.theme.color.gray70};
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
    padding: 9px 16px;
    border: 0;
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    min-width: 80px;
    width:${props => props.strechted ? '100%' : 'auto'};
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    opacity: ${props => props.disabled ? props.theme.disabledOpacity : '1'};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    text-decoration: none;
    justify-content: center;
`

const ActionLinkComponent = ({ linkText, className = '', strechted = false, disabled = false, to, secondary = false, icon = '' }) => {
    return <StyledLink href={to} secondary={secondary} className={className} strechted={strechted} disabled={disabled}>{icon !== '' && <FontIcon icon={icon} isClickable={true} />} <span>{linkText}</span> </StyledLink>
}

export default ActionLinkComponent
