import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { WIDGET_CONTEXTS } from 'application/constants'
import DamageManagerLawyerCommunicationSidebar from './component'


const DamageManagerLawyerCommunicationSidebarEffects = withRouter(
    (props) => {

        const { 
            onRefreshWidgetContext, 
            match 
        } = props
    
        const { damageId } = match.params
    
        useEffect(
            () => {
                onRefreshWidgetContext(
                    WIDGET_CONTEXTS.DAMAGE_MANAGER_ANWALT_KOMMUNIKATION_INVOICES
                )
            }, [onRefreshWidgetContext, damageId]
        )
    
        return <DamageManagerLawyerCommunicationSidebar {...props} />
    }
) 

DamageManagerLawyerCommunicationSidebarEffects.propTypes = {
    onRefreshWidgetContext: PropTypes.func.isRequired
}

export { DamageManagerLawyerCommunicationSidebarEffects }