import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import {ClearableList} from 'application/components/building_blocks/clearable_list'
import { getFullName } from 'application/common/name_helpers'
import { Select } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'




const EditUsersInGroup = (
    { 
        abort, 
        dialog, 
        onSaveGroup,
        onSearchUser,
        setAbortBlocked, 
        setDataChanged
    }
) => {
    const {usersList, groupId} = dialog.payload
    const {t} = useTranslation()
    const [existingUsersList, setExistingUsersList] = useState(dialog.payload.existingUsersList)
    const [availableUsersList, setAvailableUsersList] = useState(usersList.map((u, i) => ({
        value: u.id,
        label: getFullName(u),
        firstName: u.firstName,
        lastName: u.lastName
    })))
    const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls
    const {register, errors, handleSubmit, setValue} = useForm()

    useEffect(()=>{
        setAvailableUsersList(usersList.map((u, i) => ({
            value: u.id,
            label: getFullName(u),
            firstName: u.firstName,
            lastName: u.lastName
        })))
    },[usersList])

    useEffect(()=>{
        if(dialog.payload.existingUsersList !== existingUsersList){
            setAbortBlocked(true)
            setDataChanged(true)
        }
    }, [dialog.payload.existingUsersList, existingUsersList, setAbortBlocked, setDataChanged])

    const handleSelection = (userOption) => {

        const containsUserOption = existingUsersList.find(
            existingUserOption => existingUserOption.value === userOption.value
        )
        
        if (containsUserOption) return 

        const updatedAvailableUserOptions = availableUsersList.filter(
            availableUser => availableUser.value !== userOption.value
        )

        setAvailableUsersList(updatedAvailableUserOptions)

        setExistingUsersList(
            [
                ...existingUsersList,
                {
                    ...userOption,
                    id: userOption.value
                }
            ]
        )
    }

    const removeUserFromList = user => {
        setExistingUsersList(existingUsersList.filter(u => u.id !== user.id))
    }

    const handleSearchUser = search => {
        if(userSearchActive && search.length > 2){
            onSearchUser(dialog.name, search)
        }  
    }
    const onSubmitSuccess = () => {
        onSaveGroup(dialog.name, groupId, existingUsersList)
    }

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox
                flexDirection='column'
            >
                <Select 
                    label={t('Assign employee')}
                    name='assignedTo'
                    register={register}
                    setValue={setValue}
                    options={availableUsersList}
                    error={errors.assignedTo}
                    validate={null}
                    onInputChange={handleSearchUser}
                    onBlur={()=>{setUserSearchActive(false)}}
                    onFocus={()=>{setUserSearchActive(true)}}
                    noOptionsMessage={t('No users found')}
                    placeholder={t('Enter at least 3 characters')}
                    isLoading={dialog.payload.usersListLoading}
                    defaultValue={null}
                    onChange={(option)=> {
                        if(option !== null && !existingUsersList.find(u => u.id === option.value)){
                            handleSelection(option)
                        }
                    }}
                />
                <ClearableList list={existingUsersList.map(u => ({...u, label: getFullName(u)}))} onClear={removeUserFromList} />
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            disabled={existingUsersList.length === 0}
            submitText='Confirm'
        />
    </React.Fragment>

}

export default withDialogDataLoader(EditUsersInGroup) 