import { getFirstObjectByOwnPropKey } from 'application/common/list_helpers'
import { MASS_OPERATIONS } from 'application/constants'


const getMoName = (ownProps) => {
    return  ownProps.moName 
        ? ownProps.moName 
        : null
}


const getMoByName = (
    moName,
    registeredMassOperations
) => {
    return getFirstObjectByOwnPropKey(
        registeredMassOperations,
        MASS_OPERATIONS.KEY.NAME,
        moName
    )
}


/**
 * 
 * @param {object} state state.massOperations.registeredMassOperations
 * @param {object} ownProps props object from container - must contain moName {string} 
 * @returns {object} success: currentMassOperation as object, fail null
 */

export const fetchMoByName = (
    state,
    ownProps
) => {
    const moName = getMoName(ownProps)

    return moName !== null 
        ? getMoByName( moName, state.massOperations.registeredMassOperations)
        : null
}