import React from 'react'
import { useTranslation } from 'react-i18next'
import TruncateString from 'react-truncate-string'
import { IconButton } from '@ec/ui-controls-react'
import * as Fragments from './fragments'
import AttachmentViewer from 'application/components/building_blocks/attachments/attachment_viewer'
import ThumbnailSlider from 'application/components/building_blocks/attachments/slider'
import GalleryTree from './tree'
import { FlexBox } from 'application/components/fragments/flex_box'


export default (
    {
        gallery, 
        documents, 
        categories, 
        onHideGallery,
        downloadAttachment, 
        onSelectAttachment, 
        documentsWP
    }
) => {
    const {t} = useTranslation()
    if(!gallery.isVisible){
        return null
    }

    const isExternal = gallery.match.params.sharedResourceId !== undefined
    
    const handleDownload = () => {
        downloadAttachment(
            gallery.selectedAttachmentId,
            gallery.selectedAttachmentName, 
            gallery.damageId
        )
    }

    const handleSelectAttachment = (att) => {
        onSelectAttachment(
            gallery.damageId, 
            att.id, 
            att.fileName, 
            gallery.match
        )
    }

    return <Fragments.StyledOverlayDimmer>
        <Fragments.StyledOverlayContent>

            <Fragments.StyledHeaderRow>
                <Fragments.StyledHeader>
                    <Fragments.StyledSidebarHeaderCaption>
                        <TruncateString text={gallery.selectedAttachmentName} truncateAt={40}/>
                    </Fragments.StyledSidebarHeaderCaption>
                    <Fragments.StyledSidebarHeaderDownloadButton>
                        <IconButton 
                            className='downloadBtn' 
                            iconKey='file_download' 
                            onButtonClick={handleDownload} 
                            width='auto' 
                            height='auto' 
                        />
                    </Fragments.StyledSidebarHeaderDownloadButton>
                    <Fragments.StyledSidebarHeaderCloseButton>
                        <IconButton 
                            className='closeBtn' 
                            iconKey='close' 
                            onButtonClick={onHideGallery} 
                            width='auto' 
                            height='auto'
                        />
                    </Fragments.StyledSidebarHeaderCloseButton>
                </Fragments.StyledHeader>
            </Fragments.StyledHeaderRow>

            <Fragments.StyledContent overflow='hidden'>
                <FlexBox height='100%'>
                    {
                        !isExternal && <FlexBox 
                            marginLeft='4px' 
                            flexBasis='30%' 
                            flexShrink='0' 
                            flexDirection='column'
                            cssRules='overflow:hidden;' 
                        >
                            {
                                categories.map((c, i) => {
                                    return <GalleryTree
                                        key={i}
                                        category={c}
                                        documents={documents}
                                        onAttachmentClick={handleSelectAttachment}
                                        damageId={gallery.damageId}
                                        selectedAttachmentId={gallery.selectedAttachmentId}
                                    />
                                })
                            }
                            <GalleryTree
                                category={{
                                    name: t('Without category'),
                                    id: '0'
                                }}
                                documents={documents}
                                onAttachmentClick={handleSelectAttachment}
                                damageId={gallery.damageId}
                                selectedAttachmentId={gallery.selectedAttachmentId}
                            />
                        </FlexBox>
                    }
                    <FlexBox 
                        overflow='hidden' 
                        flexBasis='calc(70% - 4px)' 
                        flexShrink='0'
                    >
                        <AttachmentViewer 
                            damageId={gallery.damageId}
                            attachmentId={gallery.selectedAttachmentId}
                            attachmentName={gallery.selectedAttachmentName}
                            match={gallery.match}
                            taskId={gallery.taskId}
                        />
                    </FlexBox>
                </FlexBox>
            </Fragments.StyledContent>
            <Fragments.StyledFooterRow>
                <ThumbnailSlider
                    thumbnailsForSlider={isExternal ? documentsWP : documents}
                    onThumbnailClick={handleSelectAttachment}
                    selectedThumbnailId={gallery.selectedAttachmentId}
                    match={gallery.match}
                />
            </Fragments.StyledFooterRow>

        </Fragments.StyledOverlayContent>
    </Fragments.StyledOverlayDimmer>
}