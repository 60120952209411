import React from 'react'
import { HeaderContainer } from './fragments'
import TaskUploaderComponent from 'application/components/building_blocks/task_uploader'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PushBottom16 } from 'application/components/pages/_common'
import { StepsNavigation } from './steps_navigation'
import { Paragraph } from 'application/components/fragments/typography'
import TwoColumns from 'application/components/forms/_common'
import { TextInput, DatePicker } from 'application/components/controls/form'
import useStatefulForm from 'application/components/hooks/use_stateful_form'
import { validationMethods } from 'application/common'

export const ExtCreateDamageDocuments = (
    {
        files, 
        setFiles,
        setCurrentStep,
        selectedService,
        onSubmit = () => {}
    }
) => {
    const defaultValues = {
        customer: {
            firstName: '',
            lastName: '',
        },
        licensePlate: '',
        desiredDate: new Date(),
    }

    const {
        register, 
        errors, 
        handleSubmit, 
        updateFormState, 
        control,
        getValues
    } = useStatefulForm({ defaultValues })

    return <HeaderContainer>
        <Paragraph>
            Bitte machen Sie folgende Angabe, damit wir ihnen einen Termin für <strong>{selectedService.label}</strong> vorschlagen können:
        </Paragraph>
        <PushBottom16 />
        <TwoColumns
            columnLeft={
                <TextInput 
                    label='KFZ Kennzeichen'
                    name='licensePlate'
                    register={register}
                    error={errors.licensePlate}
                    onChange={updateFormState}
                    validate={{notEmpty: validationMethods.notEmpty}}
                />
            }
            columnRight={
                <DatePicker 
                    label='Wunschtermin'
                    name='desiredDate'
                    validate={null}
                    error={errors.desiredDate}
                    onDateChange={updateFormState}
                    control={control}
                />  
            }
        />

        <TwoColumns
            columnLeft={
                <TextInput 
                    label='Vorname'
                    name='customer.firstName'
                    register={register}
                    error={errors.customer?.firstName}
                    onChange={updateFormState}
                />
            }
            columnRight={
                <TextInput 
                    label='Nachname'
                    name='customer.lastName'
                    register={register}
                    onChange={updateFormState}
                />  
            }
        />

        <PushBottom16 />

        <FlexBox>
            <TaskUploaderComponent
                title='Fotos'
                description=''
                label='Foto machen'
                labelMore='Weitere Fotos hinzufügen'
                onFileAdded={() => { }}
                files={files}
                setFiles={setFiles}
            />
        </FlexBox>
        <PushBottom16 />
        <StepsNavigation
            onClickPrevious={()=>{
                setCurrentStep(0)
            }}
            onClickNext={
                handleSubmit(                
                    () => {
                        onSubmit(getValues())
                        setCurrentStep(2)
                    }
                )
            }
            nextText='Anfrage senden'
        />   
    </HeaderContainer>
}