import { ACTIVITY_TYPE, ACTIVITY_RELATED_ENTITY_TYPES } from 'application/constants'


export const FILTERS = {
    QUERY_KEYS: {
        DAMAGE: {
            STATE: 'lifeCycleState'
        }
    },
    URL: {
        VALUES: {
            DAMAGES: {
                STATE: {
                    IN_PROGRESS: 'InProgress',
                    FINISHED: 'Finished',
                    ALL: 'All'
                }
            }
        }
    },
    VALUES: {
        DAMAGES: {
            STATE: {
                IN_PROGRESS: 'InProgress',
                DONE: 'Done',
                ALL: 'All'
            },
            REPAIR_AUTHORIZATION: {
                NOT_SET: 'NotSet',
                GRANTED: 'Granted',
                REFUSED: 'Refused'
            },
            COVERAGE: {
                NONE: 'None',
                PARTIAL: 'Partial',
                FULL: 'Full'
            },
            PROPRIETARY_REASON: {
                NOT_SET: 'NotSet',
                LEASING_CONTRACT: 'LeasingContract',
                FINANCING_CONTRACT: 'FinancingContract'
            }
        },
        TASKS: {
            STATE: {
                OPEN: 'todo', 
                DONE: 'done',
                ALL: 'all'
            },
            IS_EXTERNAL: {
                TRUE: 'true',
                FALSE: 'false',
                ALL: 'all'
            }
        },
        ACTIVITIES: {
            TYPE: {
                ALL: 'All',
                ...ACTIVITY_TYPE
            },
            ENTITY_TYPE: {
                ALL: 'All',
                ...ACTIVITY_RELATED_ENTITY_TYPES
            }
        }
    }

}