import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getAccessibleBranchesRequest } from 'application/redux/actions/common/branches'

const mapStateToProps = state => ({
    accessibleBranchesLoaded: state.common.accessibleBranchesLoaded,
    accessibleBranchesLoading: state.common.accessibleBranchesLoading,
    auth: state.auth,
    sharedResource: state.sharedResource
})

const mapDispatchToProps = dispatch => ({ 
    onGetAccessibleBranches: () => dispatch(getAccessibleBranchesRequest()),
})

const withAccessibleBranchesLoaderComponent = Component => (props) => {
    const {
        auth, 
        accessibleBranchesLoaded, 
        accessibleBranchesLoading, 
        onGetAccessibleBranches,
        sharedResource
    } = props

    const currentAccountId =  auth.currentAccount?.id

    useEffect(
        () => { 
            (auth.loggedIn || sharedResource.resourceIsValid)
        && !accessibleBranchesLoaded 
        && !accessibleBranchesLoading 
        && onGetAccessibleBranches() 
        }, [accessibleBranchesLoaded, accessibleBranchesLoading, auth.loggedIn, onGetAccessibleBranches, currentAccountId, sharedResource.resourceIsValid]
    )

    return <Component {...props} />
}

export const withAccessibleBranchesLoader = compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    withAccessibleBranchesLoaderComponent
)