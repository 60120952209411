import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { TextInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'




const EditDocumentCategory = (
    { 
        abort, 
        dialog, 
        onSaveDocumentCategory 
    }
) => {
    const { categoryName, categoryId } = dialog.payload
    const {t} = useTranslation()

    const defaultValues = {
        name: categoryName
    }


    const { 
        register,
        errors, 
        handleSubmit, 
        getValues
    } = useForm({defaultValues})

    const onSubmitSuccess = () => {
        onSaveDocumentCategory(
            dialog.name, 
            categoryId, 
            getValues().name
        )
    }
    
    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <TextInput
                    label={t('Category name')}
                    name='name'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.name}
                    autoFocus={true}
                    onKeyPressEnter={handleSubmit(onSubmitSuccess)}
                />              
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Save category'
        />
    </React.Fragment>

}

export default withDialogDataLoader(EditDocumentCategory)