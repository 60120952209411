import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Preloader from 'application/components/controls/themed_preloader'
import ImageViewer from './viewers_by_type/image_viewer'
import PdfViewer from './viewers_by_type/pdf_viewer'
import FallbackViewer from './viewers_by_type/fallback_viewer'

const StyledFileViewer = styled.div`
    overflow: auto;
    height: 100%;
    width: 100%;
    text-align: center;
    & .pg-viewer-wrapper{
        overflow-y: auto;
    }
    & .pdf-canvas canvas{
        border: 1px solid #aaa;        
        margin-bottom: 20px;
        max-width: 100%;
    }
    & .pdf-viewer{
        text-align: center;
        max-height: calc(100% - 150px);
    }
`

const getAttachmentViewer = extension => {
    switch (extension.toLowerCase()) {     
    case 'jpg': case 'jpeg': case 'jpe': case 'png': case 'gif':
        return ImageViewer
    case 'pdf':
        return PdfViewer            
    default:
        return FallbackViewer
    }
}

const AttachmentViewerComponent = ({ 
    attachmentId,
    attachmentName,
    onAttachmentDownload,
    damageId,
    attachment,
    extension,
    isLoading = true,
    match,
    taskId = null,
}) => {
    const { t } = useTranslation()
    const AttachmentViewer = getAttachmentViewer(extension)
    if(isLoading){
        return <StyledFileViewer><Preloader preloaderText={t('Loading') + '...'}/></StyledFileViewer>
    }
    return <StyledFileViewer>
        <AttachmentViewer
            fileType={extension}
            filePath={attachment.data}
            fileName={attachmentName}
            fileId={attachmentId}
            damageId={damageId}
            onAttachmentDownload={onAttachmentDownload}
            errorComponent={<p>error!</p>}/>
    </StyledFileViewer> 
}

AttachmentViewerComponent.propTypes = {
    attachmentId: PropTypes.string.isRequired,
    damageId: PropTypes.string,
    attachmentName: PropTypes.string.isRequired,
    onAttachmentDownload: PropTypes.func.isRequired,
    extension: PropTypes.string.isRequired
}

export default AttachmentViewerComponent