import styled from 'styled-components'

export const PageContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    flex-direction: column;
`

export const HeaderContainer = styled.div`
    display: flex;
    width: 500px;
    padding: 20px;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;

    @media only screen and (max-width: ${props => props.theme.breakpoint.small}px){
        width: 100%;
        padding: 20px 8px;
    }
`

export const FormContainer = styled.div`
    display: flex;
    width: 500px;
    padding: 20px;
    flex-direction: column;
    margin: 0 auto;

    @media only screen and (max-width: ${props => props.theme.breakpoint.small}px){
        width: 100%;
        padding: 20px 8px;
    }
`

export const PreloaderContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100px;
    flex-direction: column;
    margin: 0 auto;
`

export const FooterContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    padding: 0 20px;
    height: 64px;
    position: fixed; 
    background: ${props => props.theme.color.white};
    bottom: 0;
`