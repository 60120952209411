import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import CommissionExpert from './component'

const mapDispatchToProps = dispatch => {
    return {
        openDialogCommissionDekraExpert: (
            damageId) => dispatch(
            openDialogByName(
                DIALOG.NAME.COMMISSION_DEKRA_EXPERT, 
                false, 
                { damageId }, 
                'Commission expert'
            )
        ),
        openDialogCommissionOtherExpert: (
            damageId) => dispatch(
            openDialogByName(
                DIALOG.NAME.COMMISSION_OTHER_EXPERT, 
                false, 
                { damageId }, 
                'Commission expert'
            )
        )
    }
}

export default connect(
    null,
    mapDispatchToProps
)(CommissionExpert)