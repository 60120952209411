import { withLoggedInUser } from 'application/components/context/logged_in'
import { DIALOG, UPDATE_CONSTRAINTS } from 'application/constants'
import { getUsersRequest } from 'application/redux/actions/common'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { getUpdateConstraint } from 'application/redux/selectors'
import { connect } from 'react-redux'

import AdministrationUsers from './component'

const mapStateToProps = state => {
    return {
        usersListLoaded: state.common.usersListLoaded,
        usersListLoading: state.common.usersListLoading,
        usersList: state.common.usersList,
        updateConstraint: getUpdateConstraint(state, UPDATE_CONSTRAINTS.USERS_TABLES)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadUsersList: (
            offset, 
            limit, 
            orderBy, 
            direction,
            filterQuery
        ) => dispatch(
            getUsersRequest(
                offset, 
                limit, 
                orderBy,
                direction,
                filterQuery
            )
        ),
        onCreateUser: () => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_USER, 
                false, 
                {}, 
                'Create User'
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withLoggedInUser(AdministrationUsers) )