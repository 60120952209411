import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import Image from 'application/components/controls/image'
import IndicatorWithColor from 'application/components/controls/indicator/override_color_indicator'
import ContextMenu from 'application/components/controls/context_menu'
import ContextMenuItem from 'application/components/controls/context_menu_item'
import ActionButton from 'application/components/controls/action_button'
import { NavLink, withRouter } from 'react-router-dom'
import { IMAGES, ROUTES } from 'application/constants'
import LanguageSwitchHoc from 'application/components/building_blocks/language_switch/hoc'
import {VehicleInfo} from 'application/components/controls/vehicle_info'
import { Bar, Cell, Group } from 'application/components/pages/internal/_title_bars/_common'
import { MetaInfo } from './meta_info'


const TitleBarWorkflowPanel = ({
    damageLoaded,
    lawfirm,
    lawfirmLoaded,
    onLogoutRequest,
    onCreateMessage,
    onLoad,
    match,
    vehicle = null,
    damage,
    account,
    branch
}) => {
    const {t} = useTranslation()

    const closeOptions = [
        {
            iconkey: 'widgets',
            label: t('Logout'),
            isLinked: false,
            onClick: () => {
                onLogoutRequest(match.params.sharedResourceId)
            }
        },
    ]

    useEffect(() => {
        onLoad()
    }, [match.params.sharedResourceId, onLoad])

    return (
        <Bar>
            <Group>
                <Cell>
                    <NavLink to={ROUTES.EXT_WORKFLOW_PANEL_BASE + match.params.sharedResourceId + '/form'}>
                        <Image data={IMAGES.LOGO_36} />
                    </NavLink>
                </Cell>
                
                <Cell>
                    <LanguageSwitchHoc />
                </Cell>
                <Cell>
                    <VehicleInfo 
                        vehicle={vehicle} 
                        damageLoaded={damageLoaded} 
                    />
                    {
                        damageLoaded && <MetaInfo
                            damage={damage}
                            account={account}
                            branch={branch}
                        />
                    }
                </Cell>
            </Group>
            <Group>
                <Cell cssWidth='400px'>
                    <ActionButton 
                        marginRight='16px' 
                        onButtonClick={onCreateMessage} 
                        buttonText={t('Message')} 
                        icon='add' 
                    />
                    {
                        lawfirmLoaded && <IndicatorWithColor 
                            iconKey='person' 
                            text={lawfirm.name} 
                            overrideColorWith='gray70' 
                        />
                    }
                    <ContextMenu iconKey="close">
                        {
                            closeOptions.map(
                                (option, index) => {
                                    return (
                                        <ContextMenuItem
                                            key={index}
                                            iconKey={option.iconkey}
                                            title={option.label}
                                            isLinked={option.isLinked}
                                            onListElementClick={option.onClick}
                                        >
                                            {option.label}
                                        </ContextMenuItem>
                                    )
                                }
                            )
                        }
                    </ContextMenu>

                </Cell>
            </Group>
        </Bar>
    )
}


export default withRouter(TitleBarWorkflowPanel)