import React from 'react'
import PropTypes from 'prop-types'
import CheckboxWithLabel from '../checkbox_with_label/component'





const RadioCheckboxWithLabel = (
    {
        register,
        name,
        isSelected, 
        onCheckboxChange,
        value,
        isRequired = false,
        label = '',
        fontSize='',
        activeLabel = false,
        isSmall = false,
        disabled = false,
        activeColor = false,
        hideValidationMessage = true,
        error = null,
        errorMessageOverride = '',
        validate = null,
    }
) =>{
  

    return <CheckboxWithLabel
        register={register}
        name={name}
        isSelected={isSelected}
        onCheckboxChange={onCheckboxChange}
        value={value}
        isRequired = {isRequired}
        label = {label}
        fontSize = {fontSize}
        activeLabel = {activeLabel}
        isSmall = {isSmall}
        disabled = {disabled}
        activeColor = {activeColor}
        hideValidationMessage = {hideValidationMessage}
        error = {error}
        errorMessageOverride = {errorMessageOverride}
        validate = {validate}
        deselectable = {false}
    />
}

RadioCheckboxWithLabel.propTypes = {
    register: PropTypes.func,
    name: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
    isRequired:  PropTypes.bool,
    label: PropTypes.string,
    fontSize: PropTypes.string, 
    isSmall: PropTypes.bool, 
    disabled: PropTypes.bool,
    activeColor: PropTypes.bool,
    hideValidationMessage: PropTypes.bool,
    activeLabel: PropTypes.bool,
    error:  PropTypes.object,
    errorMessageOverride: PropTypes.string,
    value: PropTypes.string,
    validate: PropTypes.object
}

export {RadioCheckboxWithLabel}
