import { connect } from 'react-redux'
import { updateVehicle } from 'application/redux/actions/pages/damage_manager/shared'
import { TaskDamageVehicleDataForm } from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onSave: (
            damageId, 
            vehicleData
        ) => dispatch(
            updateVehicle(
                damageId, 
                vehicleData
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageVehicleDataForm)