import React from 'react'
import { DAMAGE_TYPE, ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'
import DamageLicenseplate from 'application/components/controls/damage_licenseplate'
import { CustomerNameDamage } from 'application/components/controls/customer_name_damage'
import {ClaimNumber} from 'application/components/controls/claim_number'
import DamageTypeName from 'application/components/name_mappings/damage_type_name'
import {JobNumber} from 'application/components/controls/job_number'
import FullName from 'application/components/controls/full_name'
import DamageStatus from 'application/components/controls/damage_status'
import DateComponent from 'application/components/controls/date'
import RepairAuthorizationIndicator from 'application/components/controls/repair_authorization_indicator'
import { DamageTableContextMenu } from '../_common/context_menu'
import { getAccountLinkHref } from 'application/common/route_helpers'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { STORAGE } from 'application/constants'
import * as storage from 'application/storage'
import { LawFirmName } from 'application/components/name_mappings/lawfirm_name'



const tableName = STORAGE.TABLE_NAME.DAMAGES.ALL

export const configuration = {
    name: tableName,
    filters: {
        match: [
            new ListFilter(filters.common.branchId)
        ],
        query: [
            new ListFilter(filters.damages.assigneeId),
            new ListFilter(filters.damages.vehicleLicensePlate),
            new ListFilter(filters.damages.jobNumber),
            new ListFilter(filters.damages.claimNumber),
            new ListFilter(filters.damages.customerName),
            new ListFilter(filters.damages.customerId),
            new ListFilter(filters.damages.state)
        ],
        property: [],
        static: []
    },
    sorting: {
        sortParamKey: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.KEY,
        directionParamKey: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.DIRECTION,
        defaultSortParamValue: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.VALUES.LICENSE_PLATE,
        defaultDirectionParamValue: ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING
    },
    pagination: {
        offset: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.OFFSET,
        limit: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.LIMIT,
        getItemsPerPage: () => {
            return storage.tableConfig.getItemsPerPage(tableName)
        }
    },
    href: (element, match) => getAccountLinkHref(match, ROUTES.DAMAGE_MANAGER_BASE + element.id),
    columns: [
        {
            width: 10,
            label: 'License plate',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.VALUES.LICENSE_PLATE,
            CellContent: ({element}) => <DamageLicenseplate damage={element}/>
        },
        {
            width: 13,
            label: 'Customer / owner',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <CustomerNameDamage damage={element} />
        },
        {
            width: 14,
            label: 'Claim number', //Damagenummer
            secondLabel: 'Damagetype',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element, isSelected}) => {
                const claimNumber = element.type === DAMAGE_TYPE.COMPREHENSIVE 
                    ? element?.comprehensiveData?.claim?.number
                    : element?.liabilityData?.claim?.number

                return <FlexBox flexDirection='column'>
                    <div>
                        <ClaimNumber 
                            number={claimNumber} 
                            isSelected={isSelected}
                        />
                    </div>
                    <div>
                        <DamageTypeName 
                            damageType={element.type} 
                            isSelected={isSelected}
                        />
                    </div>
                </FlexBox>
            }
        },
        {
            width: 12,
            label: 'Job number', 
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({
                element, 
                isSelected
            }) => <JobNumber 
                number={element.jobNumber} 
                isSelected={isSelected}
            />
        },
        {
            width: 15,
            label: 'Assignee',
            secondLabel: 'Lawfirm',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <FlexBox flexDirection='column'>
                <FullName person={element.assignee} />
                <LawFirmName lawFirm={element.lawFirm} />
            </FlexBox>
        },
        {
            width: 15,
            label: 'Status',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element, isSelected}) => <DamageStatus status={element.state} isSelected={isSelected} />
        },
        {
            width: 11,
            label: 'last change',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.VALUES.LAST_CHANGE,
            CellContent: ({element}) => <DateComponent date={element.modifiedAt} />
        },
        {
            width: 5,
            label: 'Authorization',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <RepairAuthorizationIndicator repairAuthorization={element.repairAuthorization} />
        }
    ],
    ContextMenu: ({element, moIsActive}) => moIsActive ? <React.Fragment /> : <DamageTableContextMenu damage={element} />,
    contextMenuWidth: '34px',
    massOperations: {
        available: true //are mass operations possible for this table?
    }
}
