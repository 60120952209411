import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { WIDGET_CONTEXTS } from 'application/constants'
import WorkflowPanelTasks from './component'

const WorkflowPanelTasksTasksEffects = (props) => {
    const { onRefreshWidgetContexts, onLoadTasks, loggedIn, match } = props
    const { sharedResourceId } = match.params

    useEffect(() => {
        onRefreshWidgetContexts([WIDGET_CONTEXTS.WORKFLOW_PANEL_TASKS_SIDEBAR])
    }, [onRefreshWidgetContexts, sharedResourceId])

    useEffect(() => {
        loggedIn && onLoadTasks(sharedResourceId)
    }, [loggedIn, onLoadTasks, sharedResourceId])

    return <WorkflowPanelTasks {...props} />
}

WorkflowPanelTasksTasksEffects.propTypes = {
    onRefreshWidgetContexts: PropTypes.func.isRequired,
    tasksLoaded: PropTypes.bool.isRequired,
    tasksLoading: PropTypes.bool.isRequired,
    onLoadTasks: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
}

export default withRouter(WorkflowPanelTasksTasksEffects)