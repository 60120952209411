import { connect } from 'react-redux'

import { getComprehensiveDamageTypesRequest } from 'application/redux/actions/common'
import ComprehensiveDamageTypeEffects from './effects'

const mapStateToProps = state => ({
    comprehensiveDamageTypes: state.common.comprehensiveDamageTypes,
    comprehensiveDamageTypesLoaded: state.common.comprehensiveDamageTypesLoaded,
    comprehensiveDamageTypesLoading: state.common.comprehensiveDamageTypesLoading,
})

const mapDispatchToProps = dispatch => {
    return {
        onGetComprehensiveDamageTypes: () => dispatch(getComprehensiveDamageTypesRequest())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComprehensiveDamageTypeEffects)