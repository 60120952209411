import { isDefined } from 'application/common/data_helpers'
import { getDamageManagerLinkHref } from 'application/common/route_helpers'
import { ACTIVITY_RELATED_ENTITY_TYPES, ROUTES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { StyledActivityLink } from '../link'
import PropTypes  from 'prop-types'





export const TaskDamageLink = withRouter((
    {
        activity,
        match
    }
) => {
    const {t} = useTranslation()

    const damageId= activity.relatedEntities.find(
        re => re.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    ).id

    if( !isDefined(damageId) ){
        return null
    }

    const damageUrl = getDamageManagerLinkHref(
        match, 
        ROUTES.DAMAGE_MANAGER, 
        damageId
    ) 

    const damageUrlToTaskSection = damageUrl + '/tasks'

    return <StyledActivityLink
        to={damageUrlToTaskSection}
        match={match}
    >
        &raquo; {t('View damage')}
    </StyledActivityLink>
})


TaskDamageLink.propTypes = {
    activity: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
}