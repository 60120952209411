import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'


const StyledIconCounter = styled.div`
    height: 24px;
    padding: 8px 8px 8px 0;
    display: ${props => props.displayAdjust ? 'block' : 'flex'};
    flex: 0 0 20px;
    flex-direction: row;
    align-items: center;
    cursor: ${props => props.isFuncClicked !== null ? 'pointer' : ''};
    &>i{
        font-size: ${props => props.iconSize !== '' ? props.iconSize : props.theme.fontSize.medium};
        color: ${props => props.cssDisabled ? props.theme.color.gray20 : props.theme.color.primary};
    }
    &>span{
        font-size: ${ props => props.fontSize !== '' ? props.fontSize : props.theme.fontSize.standard};
        padding-left: 4px;
        color: ${props => props.cssDisabled ? props.theme.color.gray20 : props.theme.color.primary};
        };
`

const Indicator = ({ className, onButtonClick = null, iconKey, text = '', disabled = false, label = '', fontSize = '', iconSize = '', displayAdjust= false}) => {
    return <StyledIconCounter onClick={onButtonClick === null || disabled ? () => {} : onButtonClick} title={label} cssDisabled={disabled} isFuncClicked={onButtonClick} fontSize={fontSize} iconSize={iconSize} className={className} displayAdjust={displayAdjust}>
        <i className="material-icons">
            {iconKey}
        </i>
        {
            text !== '' && 
        <span>
            {text}
        </span>
        }
    </StyledIconCounter>
    
}

Indicator.propTypes = {
    onButtonClick: PropTypes.func,
    iconKey: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    label: PropTypes.string
}

export default Indicator