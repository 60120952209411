import { connect } from 'react-redux'

import DamageInvoices from './component'

const mapStateToProps = state => ({
    accountId: state.auth.currentAccount.id
})

export default connect(
    mapStateToProps
)(DamageInvoices)