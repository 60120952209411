import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import DamageCaseNotes from './component'

const mapStateToProps = state => ({
    damage: state.pages.damageManager.shared.damage,
    damageLoaded: state.pages.damageManager.shared.damageLoaded
})

const mapDispatchToProps = dispatch => {
    return {
        onEditCaseNotes: (payload) => dispatch(openDialogByName(DIALOG.NAME.EDIT_CASE_NOTES, true, payload, 'Edit case notes')),
        onCreateCaseNotes: (payload) => dispatch(openDialogByName(DIALOG.NAME.CREATE_CASE_NOTES, true, payload, 'Store case notes')),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DamageCaseNotes)