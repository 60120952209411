import React from 'react'
import { FilterRadioCheckboxGroup }  from '../radio_checkbox_group'
import PropTypes from 'prop-types'
import * as filters from 'application/filters'
import { FILTERS } from 'application/constants/filters'


const TaskStateFilterCheckboxGroup = (
    {
        pagination,
        disabled = false,
        spaceLeft = false
    }
) => {
    const TASKFILTERS = FILTERS.VALUES.TASKS
    const filter = filters.tasks.state

    const checkboxDefinitions = [
        {
            label: 'Opened',
            value:  TASKFILTERS.STATE.OPEN
        },
        {
            label: 'Done',
            value:  TASKFILTERS.STATE.DONE
        },
        {
            label: 'All',
            value:  TASKFILTERS.STATE.ALL
        }
    ]

    return <FilterRadioCheckboxGroup
        pagination={pagination}
        filter={filter}
        disabled={disabled}
        checkboxDefinitions={checkboxDefinitions}
        spaceLeft={spaceLeft}
    />                
}

TaskStateFilterCheckboxGroup.propTypes = {
    pagination: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    spaceLeft: PropTypes.bool
}

export {TaskStateFilterCheckboxGroup}