import React from 'react'
import { useTranslation } from 'react-i18next'
import ContextMenu from 'application/components/controls/context_menu'
import ContextMenuItem from 'application/components/controls/context_menu_item'
import { ContextMenuWrapper } from 'application/components/tables/_common/fragments'
import { ForSpecificBranchPermissions } from 'application/components/context/permissions'
import { USER_PERMISSONS } from 'application/constants'
// import { exists } from 'application/common/data_helpers'

const DamageTableContextMenu = ({
    damage, 
    onDeleteDamage, 
    onDownloadDamageDocuments
}) => {
    const {t} = useTranslation()
    const handleClick = (e, handle) => {
        e.preventDefault()
        e.stopPropagation()
        handle()
    }

    return <ForSpecificBranchPermissions
        branchId={damage.branch.id}
        permissions={[
            USER_PERMISSONS.DAMAGE.DELETE,
            USER_PERMISSONS.DOCUMENT.READ
        ]}
        allNeeded={false}
    >
        <ContextMenuWrapper>
            <ContextMenu iconKey='create'>
                <ForSpecificBranchPermissions
                    branchId={damage.branch.id}
                    permissions={[USER_PERMISSONS.DAMAGE.DELETE]}
                >
                    <ContextMenuItem
                        className='deleteButton'
                        onListElementClick={(event) => {
                            handleClick(event, () => {
                                onDeleteDamage(damage)
                            })
                        }}
                        iconKey='delete_outline'
                        title={t('Delete damage')}
                    >
                        {t('Delete damage')}
                    </ContextMenuItem>
                </ForSpecificBranchPermissions>
                {/* <ForSpecificBranchPermissions
                    branchId={damage.branch.id}
                    permissions={[USER_PERMISSONS.DOCUMENT.READ]}
                >
                    <ContextMenuItem
                        className='downloadButton context-menu'
                        onListElementClick={(event) => {
                            handleClick(event, () => {
                                onDownloadDamageDocuments(damage.id)
                            })
                        }}
                        iconKey='save_alt'
                        title={t('Download attachments')}
                        disabled={damage.attachments === 0 || !exists(damage.attachments)}
                    >
                        {t('Download attachments')}
                    </ContextMenuItem>
                </ForSpecificBranchPermissions> */}
            
            </ContextMenu>
        </ContextMenuWrapper> 
    </ForSpecificBranchPermissions>
}

export default DamageTableContextMenu