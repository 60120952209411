import React from 'react'

export default ({entity}) => {
    const address = {
        postalCode: '',
        locality: '',
        ...entity.address
    }

    return <React.Fragment>{`${address.postalCode} ${address.locality}`}</React.Fragment>
}