import styled from 'styled-components'

export const SelectWrapper = styled.div`

  & .css-yk16xz-control {
      max-height: 35px;
      height: 35px;
      min-height: 35px;
      width: 100%;
  }

  & .css-1pahdxg-control {
    max-height: 35px;
    height: 35px;
    min-height: 35px;
}
`