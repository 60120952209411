import { compose } from 'redux'
import { connect } from 'react-redux'
import {mapStateToProps, mapDispatchToProps} from './container'
// import {withMOComponent} from './component'


// ---------------- ..... if component.js is used:
//component.js:
// import React from 'react'
// export const withMOComponent = 
// Component => 
//     props => 
//         <Component {...props} />
//-------------------------------------------


/**
 * returns selectItemByMOName( moName, item)
 *         deselectItemByMOName( moName, itemId)
 *         setSelectedItemsByMOName: (moName, items)
 *         closeMoByName: (moName),
 *         openMoByName: (moName),
 *         mo
 */
export const withMassOperations = compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    // withMOComponent
)