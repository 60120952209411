import React from 'react'
import { Table } from 'application/components/tables/_common'
import { configuration } from './configuration'
import { useMassOperationsClose, useTableLoader } from 'application/components/hooks'
import { withMassOperations } from 'application/components/context/mass_operations'
import { withRouter } from 'react-router-dom'

const TableCompanies = withMassOperations(withRouter((
    {
        companiesListLoading,
        companiesListLoaded,
        companiesListTotal,
        companiesList,
        moName,
        emptyMessage='No companies found',
        match,
        closeMoByName,
        onLoadCustomersList,
        updateConstraint
    }
) => {
    useTableLoader(onLoadCustomersList, match, configuration, updateConstraint)
    useMassOperationsClose(moName, closeMoByName, companiesList)
    
    return <Table 
        configuration={configuration}
        elementsLoading={companiesListLoading}
        elementsLoaded={companiesListLoaded}
        elementsTotal={companiesListTotal}
        elements={companiesList}
        emptyMessage={emptyMessage}
        moName={moName}
    />
}))

export {TableCompanies}