import React, {useEffect, useState} from 'react'
import { FontIcon } from '@ec/ui-controls-react'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Preloader from 'application/components/controls/themed_preloader'
import { MapContainer, TileLayer, Marker,  } from 'react-leaflet'
import { useGeoCoding } from 'application/components/hooks'
import { TextLink } from 'application/components/fragments/typography'
import { getMapAddress } from 'application/common/address_helper'
import { Spacer } from 'application/components/pages/_common'
import { StyledMapContainer, StyledMapPreloader, StyledLinkBox, StyledGeoCodingError} from './fragments'

export const ExternalMapLink = ({position, linkText, zoom}) => {
    return <StyledLinkBox>
        <TextLink target='_blank' href={`https://www.openstreetmap.org/?mlat=${position[0]}&mlon=${position[1]}#map=${zoom}/${position[0]}/${position[1]}`}>&raquo; {linkText}</TextLink>
    </StyledLinkBox>
}

export default withTheme(({
    mapWidth = '324px', 
    mapHeight = '324px', 
    zoom = 13, 
    addressQuery = null, 
    latLong = null,
    mapLinkText = 'Show on bigger map',
    theme
}) => {
    const {t} = useTranslation()
    const [query, setQuery] = useState(getMapAddress(addressQuery))

    useEffect(()=>{
        setQuery(getMapAddress(addressQuery))
    }, [addressQuery])

    const {
        geoCodingFinished,
        geoCodingSuccessful,
        geoCodingError, //can be used to show error message
        position
    } = useGeoCoding(query, latLong)

    return <StyledMapContainer mapWidth={mapWidth} mapHeight={`calc(${mapWidth} + 20px)`} >
        {
            geoCodingFinished && geoCodingError && <StyledMapPreloader>
                <StyledGeoCodingError>
                    <FontIcon icon='location_on' color={theme.color.anthraciteLight}></FontIcon>
                    <Spacer basis='8px' />
                    <span>{t('Map data not found')}</span>
                </StyledGeoCodingError>
            </StyledMapPreloader>
        }
        {
            !geoCodingFinished && <StyledMapPreloader>
                <Preloader />
            </StyledMapPreloader>
        }
      
        {
            geoCodingFinished && position !== null && geoCodingSuccessful && <React.Fragment>
                <MapContainer
                    center={position}
                    zoom={zoom}
                    style={{ width: mapWidth, height: mapHeight }}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position}  />
                </MapContainer>
                <ExternalMapLink position={position} zoom={zoom} linkText={t(mapLinkText)} />
            </React.Fragment>
        }
    </StyledMapContainer>
})