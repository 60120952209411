import ACTIONS from 'application/constants'

export const requestActivityLogWorkflowPanel = (offset = 0, limit = 10, direction = 'desc') => ({
    type: ACTIONS.WORKFLOW_PANEL_ACTIVITY_LOG_REQUEST,
    offset, limit, direction
})

export const requestActivityLogMoreWorkflowPanel = (offset = 0, limit = 10, direction = 'desc') => ({
    type: ACTIONS.WORKFLOW_PANEL_ACTIVITY_LOG_MORE_REQUEST,
    offset, limit, direction
})

export const populateActivityLogWorkflowPanel = (activities, total) => ({
    type: ACTIONS.WORKFLOW_PANEL_ACTIVITY_LOG_POPULATE_DATA,
    activities, total
})

