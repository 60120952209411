import React from 'react'
import { useStatefulForm } from 'application/components/hooks'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { TextInput, DatePicker } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { useTranslation } from 'react-i18next'
import { AddressDataPartialForm } from 'application/components/forms/_partial_forms'
import { FormHeadline } from 'application/components/fragments/typography'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import TwoColumns from 'application/components/forms/_common'

const CommissionExpertDialog = (({
    onCommissionExpert,
    dialog,
    setAbortBlocked, 
    setDataChanged,
    abort
})=>{
    const {t} = useTranslation()
    const { damage, branch } = dialog.payload
    const { notEmpty, mustBeEmail } = validationMethods

    const defaultValues = {
        name: '',
        email: '',
        inspectionInfoName: branch?.name || '',
        inspectionSite: {
            ...branch.address
        },
        inspectionDate: null
    }

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues,
        control,
        updateFormState
    } = useStatefulForm({defaultValues})

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const handleOnCommissionExpert = () => {
        onCommissionExpert(
            dialog.name, 
            getValues(),
            damage.id
        )
    }

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>

                <TwoColumns 
                    columnLeft={
                        <DatePicker
                            label={t('Inspection date')}
                            name='inspectionDate'
                            validate={
                                {
                                    notEmpty: validationMethods.notEmpty
                                }
                            }
                            error={errors.inspectionDate}
                            onDateChange={updateFormState}
                            control={control}
                        />
                    }
                />

                <TwoColumns 
                    columnLeft={
                        <TextInput
                            label={t('Name')} 
                            name='name'
                            register={register}
                            validate={{notEmpty}}
                            error={errors.name}
                            autoFocus={true}
                            onChange={onDataChange}
                        />
                    }
                    columnRight={
                        <TextInput
                            label={t('E-Mail')} 
                            name='email'
                            register={register}
                            validate={{notEmpty, mustBeEmail}}
                            error={errors.email}
                            onChange={onDataChange}
                        />
                    }
                />              
                <FormHeadline>{t('Inspection site')}</FormHeadline>
                <TextInput
                    label={t('Site name')}
                    name='inspectionInfoName'
                    register={register}
                    validate={{ notEmpty: validationMethods.notEmpty }}
                    error={errors.inspectionInfoName}
                    onChange={updateFormState}
                />
                <AddressDataPartialForm
                    register={register}
                    errors={errors}
                    disabled={false}
                    nameStreet = 'inspectionSite.line1'
                    namePostalCode = 'inspectionSite.postalCode'
                    nameCity = 'inspectionSite.locality'
                />
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(handleOnCommissionExpert)}
            submitText='Send commission'
        />
    </React.Fragment>
})

export default withDialogDataLoader(CommissionExpertDialog)