import { useState } from 'react'
import { geoCode, getMapAddress, isCompleteAddress } from 'application/common/address_helper'
import PropTypes from 'prop-types'


const useGeoDataSubmit = (
    formStateValues,
    setValue,
    onSubmit,
    markerMoved = false
) => {
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [useGeoCoding, setUseGeoCoding] = useState(markerMoved)

    const onSubmitSuccess = () => {
        setSubmitDisabled(true)
        if ( !isCompleteAddress(formStateValues)  || !useGeoCoding ) {
            onSubmit()
            setSubmitDisabled(false)
        } else {
            geoCode(
                getMapAddress(formStateValues), 
                ({
                    lat='', 
                    lon=''
                }) => {
                    setValue('latitude', lat)
                    setValue('longitude', lon)
                    onSubmit(lat, lon)
                    setSubmitDisabled(false)
                }
            )
        }
    
    }


    return {
        onSubmitSuccess,
        submitDisabled,
        useGeoCoding,
        setUseGeoCoding
    } 
}

useGeoDataSubmit.propTypes = {
    formStateValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    markerMoved: PropTypes.bool
}

export  { useGeoDataSubmit }