import React from 'react'
import { withRouter } from 'react-router-dom'

import * as Fragments from './fragments'
import LayoutBaseWorkflowPanel from './layout_base_workflow_panel_container'
import { TitleBarWorkflowPanel } from 'application/components/pages/external/title_bar'
import Dialogs from 'application/components/building_blocks/dialog/xdialogs'

const LayoutWorkflowPanel = ({ children }) => {
    return <LayoutBaseWorkflowPanel>
        <Fragments.Header>
            <TitleBarWorkflowPanel />
        </Fragments.Header>
        <Fragments.Main>
            <Fragments.Content cssPaddingLeft='0' cssPaddingTop='0' cssPaddingRight='0'>
                {children}
            </Fragments.Content>
            <Dialogs />
        </Fragments.Main>
    </LayoutBaseWorkflowPanel>
}

export default withRouter(LayoutWorkflowPanel)