import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage, addToLastSubMessage } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogCreateMessageTrackingSaga(action) {
    yield put(showWaitingScreen('Creating message'))
    yield resourceCall(
        api.gateway.messages.postMessage, 
        { damageId : action.metaData.damageId },
        action.payload.message
    )
    if(action.payload.files.length){
        for(const file of action.payload.files){
            yield put( addNewSubMessage(file.metaData.name + ' ... ') )
            
            yield resourceCall(
                api.gateway.attachments.postAttachment, 
                {
                    categoryId: action.metaData.categoryId
                },
                {
                    file: file.metaData
                }
            )
            
            yield put(addToLastSubMessage('✓'))
        }
    }
    yield put(showToast('Message has been successfully sent'))
    yield put(hideWaitingScreen())
}