import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { ROUTES } from 'application/constants'
import { withSharedResourceValidationComponent } from './component'
import { withSharedResourceValidationContainer } from './container'

export const withSharedResourceValidation = (redirectUrl = ROUTES.SHARED_RESOURCE_INVALID) => compose(
    withRouter,
    withSharedResourceValidationContainer(redirectUrl),
    withSharedResourceValidationComponent
)