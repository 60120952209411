import { takeLeading, put } from 'redux-saga/effects'
import { api, dataMappers } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { createDamageSuccess } from 'application/redux/actions/ext_create_damage'
import { getFullName } from 'application/common/name_helpers'
import { exists } from 'application/common/data_helpers'

function* sendCreateDamageKickstartExternalSaga(action) {
    try {

        const {
            damageData,
            lawfirmData,
            expertData,
            files,
            accountName
        } = action.payload

        const createCustomerPayload = dataMappers.exportMappers.damage.createCustomerDataMapper({...action.payload.damageData.customer, isCompany: false})

        const customersResponse = yield resourceCall(
            api.gateway.customer.postCustomer,
            null,
            createCustomerPayload
        )

        const damagePayload = dataMappers.exportMappers.damage.createDamageDataMapper(
            action.payload.damageData, 
            customersResponse.id,
            null
        )

        const createDamageResponse = yield resourceCall(
            api.gateway.damage.createDamage,
            {
                branchId: action.payload.branch.id
            },
            damagePayload
        )

        if(files.length > 0) {
            for(const file of files){
                yield resourceCall(
                    api.gateway.attachments.postAttachment, 
                    {
                        damageId: createDamageResponse.id,
                        categoryId: null
                    },
                    {
                        file: file.metaData
                    }
                )
            }
        }


        if(lawfirmData.commissionLawyer){

            yield resourceCall(
                api.gateway.damage.updateDamageLawfirm, 
                {
                    damageId: createDamageResponse.id
                },
                {
                    name: lawfirmData.lawfirm.name,
                    webakteId: lawfirmData.lawfirm.id
                }
            )
            const lawfirmResponse = yield resourceCall(
                api.gateway.damage.getLawfirm,
                {
                    damageId: createDamageResponse.id
                }
            )

            const mailSubject = 'e.sy360-Anwaltsbeauftragung von ' + accountName + ' / ' + damageData.licensePlate
            const mailAddress = 'karsten.egger@e-consult.de'
            const mailName = 'Karsten Egger'
            const sharedResourceLawyer = lawfirmResponse.proxyShare.id
            var host = window.location.protocol + '//' + window.location.hostname
            const wfpLink = `${host}/external/workflow/${sharedResourceLawyer}`
            const mailBody = dataMappers.getMailBody(
                '',
                accountName + ' hat Sie mit der Abwicklung dieses Schadenfalls beauftragt:',
                `KFZ Kennzeichen: ${damageData.licensePlate} \
                <br /> Geschädigter: ${getFullName(damageData.customer)} \
                <br /> Wer ist schuld: ${damageData.whosAtFault} \
                <br /> Polizei vor Ort: ${damageData.policeOnSite ? 'ja' : 'nein'} \
                <br /> Schadenort: ${damageData.incidentSite} \
                <br /> Schadendatum: ${(new Date(damageData.incidentDate)).toLocaleDateString('de-DE')} \
                <br /> Unfallgegner: ${damageData.accidentOpponent.replace(/(?:\r\n|\r|\n)/g, ' / ')} \
                <br /> KFZ Kennzeichen Unfallgegner: ${damageData.licensePlateOpponent} \
                <br /> Fahrzeug Unfallgegner: ${damageData.vehicleTypeOpponent} \
                <br /> Versicherung Unfallgegner: ${exists(damageData.insuranceOponentId) ? damageData.insuranceOponentId : ''} ${exists(damageData.insuranceOponentName) ? damageData.insuranceOponentName : ''} \
                <br /> Unfallhergang / Schadenbeschreibung: ${damageData.accidentReport} \
                <br /><br /> Klicken Sie hier, um mit der Schadenabwicklung zu beginnen:\
                <br/><a href="${wfpLink}">${wfpLink}</a>`
            )
            yield resourceCall(
                api.gateway.mails.sendMail,
                null, 
                {
                    to: [{
                        name: mailName,
                        address: mailAddress
                    }],
                    subject: mailSubject,
                    body: mailBody
                }
            )
        }

        if(expertData.commissionExpert){

            const mailSubject = 'e.sy360: Gutachten angefordert von ' + accountName + ' für ' + damageData.licensePlate
            const mailAddress = 'karsten.egger@e-consult.de'
            const mailName = 'Karsten Egger'
            const mailBody = dataMappers.getMailBody(
                expertData.selectedExpert.name,
                accountName + ' hat Sie mit der Erstellung eines Gutachtens für diesen Schadensfall beauftragt:',
                `KFZ Kennzeichen: ${damageData.licensePlate} \
                <br /> Geschädigter: ${getFullName(damageData.customer)} \
                <br /> Wer ist schuld: ${damageData.whosAtFault} \
                <br /> Polizei vor Ort: ${damageData.policeOnSite ? 'ja' : 'nein'} \
                <br /> Schadenort: ${damageData.incidentSite} \
                <br /> Schadendatum: ${(new Date(damageData.incidentDate)).toLocaleDateString('de-DE')} \
                <br /> Unfallgegner: ${damageData.accidentOpponent.replace(/(?:\r\n|\r|\n)/g, ' / ')} \
                <br /> KFZ Kennzeichen Unfallgegner: ${damageData.licensePlateOpponent} \
                <br /> Fahrzeug Unfallgegner: ${damageData.vehicleTypeOpponent} \
                <br /> Versicherung Unfallgegner: ${exists(damageData.insuranceOponentId) ? damageData.insuranceOponentId : ''} ${exists(damageData.insuranceOponentName) ? damageData.insuranceOponentName : ''} \
                <br /> Unfallhergang / Schadenbeschreibung: ${damageData.accidentReport} \
                <br /><br /> \
                <br /> Besichtigungstermin: ${(new Date(expertData.selectedExpert.inspectionDate)).toLocaleDateString('de-DE')} \
                <br /><br /> Besichtigungsort:  \
                <br /><br /> ${expertData.selectedExpert.inspectionName}\
                <br /> ${expertData.selectedExpert.inspectionSite.line1}\
                <br /> ${expertData.selectedExpert.inspectionSite.postalCode} ${expertData.selectedExpert.inspectionSite.locality}`
            )
            yield resourceCall(
                api.gateway.mails.sendMail,
                null, 
                {
                    to: [{
                        name: mailName,
                        address: mailAddress
                    }],
                    subject: mailSubject,
                    body: mailBody
                }
            )
        }


        yield put(createDamageSuccess('id'))
    } catch (e) {
        yield handleError(e)
    }
}

export function* sendCreateDamageKickstartExternalWatcher() {
    yield takeLeading(ACTIONS.EXT_CREATE_DAMAGE_KICKSTART_REQUEST, sendCreateDamageKickstartExternalSaga)
}