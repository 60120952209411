import { connect } from 'react-redux'

import Uploader from './component'
import { showToast } from 'application/redux/actions/notifications'
import { NOTIFICATION } from 'application/constants'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        onFilesRejected: (files) => {
            dispatch(showToast('Invalid files have been removed', NOTIFICATION.LEVEL.WARNING))
            for(const file of files){
                dispatch(showToast(file.name, NOTIFICATION.LEVEL.WARNING))
            }
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Uploader)