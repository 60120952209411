import { connect } from 'react-redux'
import { DamageInsurances } from './component'

const mapStateToProps = state => ({
    damage: state.pages.damageManager.shared.damage,
    damageLoaded: state.pages.damageManager.shared.damageLoaded
})

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DamageInsurances)