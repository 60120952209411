import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const sendMail = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(
                'mails', 
                HTTP_METHOD.POST,
                payload
            )
            return apiCall(request)
        },
        sharedResource: null,
        qrpoc: (payload) => {
            const request = fetchRequestBuilderPin(`external/${params.sharedResourceType}/${params.sharedResourceId}/mail/mails`, params.pin, HTTP_METHOD.POST, payload)
            return apiCall(request)
        }
    }
)