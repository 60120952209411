import { put } from 'redux-saga/effects'

import { api } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogEditTaskSaga(action) {
    yield put(
        showWaitingScreen('Saving task')
    )
    yield resourceCall(
        api.gateway.tasks.updateTask, 
        action.metaData, 
        action.payload
    )

    yield put(
        hideWaitingScreen()
    )
    yield put(
        showToast('Task saved')
    )
}