import { DIALOG } from 'application/constants'
import { assignTask, changeTaskState } from 'application/redux/actions/pages/damage_manager/tasks'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import TaskOperationsComponent from './component'


const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onTaskStateChange: (
            damageId, 
            taskId, 
            state
        ) => dispatch(
            changeTaskState(
                damageId, 
                taskId, 
                state
            )
        ),
        onAssignTask: (
            damageId, 
            taskId, 
            userId,
            userFirstName, 
            userLastName
        ) => dispatch(
            assignTask(
                damageId, 
                taskId, 
                userId, 
                userFirstName, 
                userLastName
            )
        ),
        onReopenTask: (damageId, task) => dispatch(
            openDialogByName(
                DIALOG.NAME.REOPEN_TASK, 
                true, 
                {
                    damageId, 
                    task, 
                    usersList: [], 
                    usersListLoading: false
                }, 
                'Reopen task'
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskOperationsComponent)