import React from 'react'
import Sticky from 'react-sticky-el'
import { Redirect, withRouter } from 'react-router-dom'

import { ROUTES } from 'application/constants'
import {TaskHighlightBar} from 'application/components/building_blocks/task_highlight_bar'
import ThemedPreloader from 'application/components/controls/themed_preloader'
import WidgetArea from 'application/components/building_blocks/widget_area'
import * as Fragments from './fragments'
import TaskOperationsInternal from 'application/components/building_blocks/task_operations_internal'
import { TaskDetailsContextMenu } from './context_menu'
import { exists } from 'application/common/data_helpers'
import { getDamageManagerLinkHref } from 'application/common/route_helpers'

const TaskDetails = ({
    selectedTaskLoaded,
    selectedTaskLoading,
    task,
    onAssignTask,
    damageId,
    onSearchUser,
    userList,
    userListLoading,
    onDeleteTask,
    onEditTask,
    damage,
    match
}) => {
    const handleAssignTask = (
        userId, 
        userFirstName, 
        userLastname
    ) => {
        onAssignTask(
            match.params.damageId, 
            task.id, 
            userId, 
            userFirstName, 
            userLastname
        )
    }

    const taskExternal = task !== null && exists(task.creator) && !exists(task.creator.userId)

    if (selectedTaskLoading) {
        return <ThemedPreloader />
    }
    if (!selectedTaskLoaded || task === null) {
        return <React.Fragment />
    }
    if (task.taskWasDeleted && match.params.taskId === task.id) {
        return <Redirect to={
            getDamageManagerLinkHref(
                match, 
                ROUTES.DAMAGE_MANAGER_TASKS, 
                match.params.damageId
            )
        } />
    }
    return <React.Fragment>
        <Sticky scrollElement=".taskScrollArea">
            <React.Fragment>
                <Fragments.Row>
                    <Fragments.TaskHeadline zIndex='1'>
                        {task.title} 
                        <TaskDetailsContextMenu 
                            task={task}
                            damageId={damageId}
                            onDeleteTask={onDeleteTask}
                            onEditTask={onEditTask}
                            damage={damage}
                        />
                    </Fragments.TaskHeadline>
                </Fragments.Row>
                <Fragments.Row>
                    <TaskHighlightBar
                        state={task.state}
                        priority={task.priority}
                        deadline={null}
                        onAssignUser={handleAssignTask}
                        onSearchUser={onSearchUser}
                        userList={userList}
                        userListLoading={userListLoading}
                        assignee={task.assignee}
                    />
                </Fragments.Row>
            </React.Fragment>
        </Sticky>

        <Fragments.Row>
            <Fragments.Description>{task.description}</Fragments.Description>
        </Fragments.Row>
        <Fragments.Row>
            <WidgetArea widgetContext={task.id} successively={true} />
        </Fragments.Row>
        <Fragments.Filler />
        <Fragments.Footer>
            <TaskOperationsInternal
                task={task}
                taskExternal={taskExternal}
                damageId={match.params.damageId}
            />
        </Fragments.Footer>
    </React.Fragment>

}

export default withRouter(TaskDetails)