import React from 'react'
import { withRouter } from 'react-router-dom'
import WidgetBoard from './component'

const WidgetBoardEffects = (props) => {
    const { registeredWidgets, widgetList, widgetContext, onRegisterWidgets, onWidgetLayoutChange } = props

    const handleLayoutChange = (layout) => {
        const registeredWidgetKeys = registeredWidgets.map(w => w.key)
        const widgetsToRegister = widgetList.filter(w => !registeredWidgetKeys.includes(w.key)).map(w => ({
            ...w,
            initialPayload: w.routeParameters !== undefined ? {
                ...w.initialPayload,
                routeParameters: w.routeParameters
            } : {
                ...w.initialPayload
            }
        }))
        
        onRegisterWidgets(widgetContext, widgetsToRegister)
        onWidgetLayoutChange(widgetContext, layout, widgetList)
    }

    return <WidgetBoard {...props} onWidgetLayoutChange={handleLayoutChange} />
}

export default withRouter(WidgetBoardEffects)