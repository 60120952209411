import React from 'react'
import { useTranslation } from 'react-i18next'
import { SubPageLayout } from 'application/components/pages/_common'
import ActionButtonBar from 'application/components/controls/action_button_bar'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label'
import WidgetBoard from 'application/components/building_blocks/widget_board'
import { WIDGET_CONTEXTS } from 'application/constants'



const ControllingInvoices = ({
    onSetStaticMode,
    onResetWidgetBoard,
    isStatic
}) => {
    const {t} = useTranslation()

    //set to false to get the widgetboard controls
    const widgetBoardIsReadonly = true

    return <SubPageLayout>
        {
            !widgetBoardIsReadonly && <ActionButtonBar inline={true} buttonsAlign='flex-end'>
                {
                    !isStatic && <IconButtonWithLabel
                        onButtonClick={()=>{onResetWidgetBoard(WIDGET_CONTEXTS.CONTROLLING_VACANT_POSTIONS)}}
                        iconKey='settings_backup_restore'
                        label={t('Reset layout')}
                    />
                }

                {
                    !isStatic && <IconButtonWithLabel
                        onButtonClick={
                            ()=> onSetStaticMode(
                                WIDGET_CONTEXTS.CONTROLLING_VACANT_POSTIONS, 
                                !isStatic
                            )
                        }
                        iconKey={isStatic ? 'edit' : 'check'}
                        label={isStatic ? t('Edit widgets') : t('Done')}
                    />
                }

            </ActionButtonBar>
        }
        <WidgetBoard 
            widgetContext={WIDGET_CONTEXTS.CONTROLLING_VACANT_POSTIONS} 
            widgetMargin={[16, 48]} 
        />
    </SubPageLayout>
}

export default ControllingInvoices