import { call, select } from 'redux-saga/effects'
import { getPin, getSharedResourceId, getSharedResourceType} from 'application/redux/selectors'
import { exists } from 'application/common/data_helpers'
import { SHARED_RESOURCE_TYPE } from 'application/constants'

/**
 * 
 * @param {function} endpointSwitch both authenticated and sharedResource request promises 
 * @param {object} params all parameters for the url or the request header, sometimes null for GET or POST requests
 * @param {object} payload the request body for PUT or POST requests
 * @returns server response if successfull
 */
export function* resourceCall (
    endpointSwitch, 
    params, 
    payload = {}
) {
    const pin = yield select(getPin)
    const sharedResourceId = yield select(getSharedResourceId)
    let sharedResourceType = yield select(getSharedResourceType)

    //workaround
    if(sharedResourceType === SHARED_RESOURCE_TYPE.DAMAGE_TRACKING){
        sharedResourceType = SHARED_RESOURCE_TYPE.LAWFIRM
    }

    if(sharedResourceType === SHARED_RESOURCE_TYPE.KICKSTART){
        sharedResourceType = SHARED_RESOURCE_TYPE.QR_POC
    }

    const endpoints = endpointSwitch({
        pin,
        sharedResourceId,
        sharedResourceType,
        ...params
    })

    const sharedResource = endpoints.sharedResource || (() => {})
    const authenticated = endpoints.authenticated || (() => {})

    const endpoint = exists(sharedResourceId) 
        ? exists(endpoints[sharedResourceType])
            ? endpoints[sharedResourceType] 
            : sharedResource
        : authenticated

    const response = yield call(endpoint, payload)

    return response
}