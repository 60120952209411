import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { withMassOperations } from 'application/components/context/mass_operations'
import DamageManagerDocuments from './component'

const DamageManagerDocumentsEffects = (props) => {
    const { 
        onLoadDocuments, 
        documentsLoading, 
        documentsLoaded, 
        match, 
        currentDamageId, 
        onHideAttachmentDetails, 
        sidebarDamageId 
    } = props

    const { damageId } = match.params
    const shouldLoad = (!documentsLoading && !documentsLoaded) || (currentDamageId !== damageId)
    const shouldHideSidebar = damageId !== sidebarDamageId

    useEffect(() => {
        shouldLoad && onLoadDocuments(damageId)
    }, [onLoadDocuments, damageId, shouldLoad])

    useEffect(() => {
        shouldHideSidebar && onHideAttachmentDetails() //hide sidebar when new document center data is loaded
    }, [onHideAttachmentDetails, shouldHideSidebar])

    const {closeMoByName} = props
    const mo = props.mo
    const moExists = mo !== null

    const  moName = moExists
        ? mo.moName 
        : ''

    useEffect(
        () => {
            moExists 
            && closeMoByName(
                moName
            )    
              
        },
        [closeMoByName, moExists, moName] 
    ) 

    return <DamageManagerDocuments 
        {...props}
        damageId={props.match.params.damageId}
        onHideAttachmentDetails={onHideAttachmentDetails}
    />
}

export default withMassOperations( 
    withRouter(DamageManagerDocumentsEffects)
)