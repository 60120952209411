import { getDamageManagerLinkHref } from 'application/common/route_helpers'
import { ROUTES } from 'application/constants'
import React, { useEffect } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import DamageManagerMessageDetailsWidget from './component'
import { useTranslation } from 'react-i18next'
import ThemedPreloader from 'application/components/controls/themed_preloader'
import { exists } from 'application/common/data_helpers'

const DamageManagerMessageDetailsWidgetEffects = (props) => {
    const {t} = useTranslation()
    const messageId = props.match.params.messageId === undefined ? null : props.match.params.messageId
    const damageId = props.match.params.damageId === undefined ? null : props.match.params.damageId
    const { loadMessage, messageLoadedAndNotFound, onMessageNotFound, detailsLoaded } = props

    useEffect(() => {
        messageId !== undefined && messageId !== null && loadMessage(damageId, messageId)
    }, [damageId, loadMessage, messageId])

    useEffect(() => {
        messageLoadedAndNotFound && onMessageNotFound()
    }, [
        messageLoadedAndNotFound,
        onMessageNotFound
    ])

    if (messageLoadedAndNotFound) {
        return <Redirect to={getDamageManagerLinkHref(props.match, ROUTES.DAMAGE_MANAGER_INBOX, damageId)} />
    } 

    if(exists(messageId) && !detailsLoaded){
        return <ThemedPreloader preloaderText={t('Loading')} />
    }
    
    return <DamageManagerMessageDetailsWidget {...props} />
}

export default withRouter(DamageManagerMessageDetailsWidgetEffects)