import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { TextArea, TextInput, PhoneNumberInput, HiddenField } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { Headline3 } from 'application/components/fragments/typography'
import { PushBottom8 } from 'application/components/pages/_common'
import { useStatefulForm } from 'application/components/hooks'
import { CASE_STATUS } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'
import TwoColumns from 'application/components/forms/_common'

const LawfirmReceiptConfirmationForm = (
    { 
        formData = {},
        onSave,
    }
) => {
    const {t} = useTranslation()

    const receiptDefaultValues = {
        referenceId: formData.generalCommunication?.caseFileInfo?.referenceId || '',
        status: formData.generalCommunication?.caseFileInfo?.status || CASE_STATUS.NOT_SET,
        caseNote: formData.generalCommunication?.caseFileInfo?.note || '',
        name: formData.generalCommunication?.contactInfo?.name || '',
        phoneNumber: formData.generalCommunication?.contactInfo?.phoneNumber || '',
        note: formData.generalCommunication?.acknowledgement?.note || ''
    }

    const receiptForm = useStatefulForm(
        { defaultValues: receiptDefaultValues }
    )

    const { 
        register,
        errors, 
        getValues, 
        control, 
        setValue,
        updateFormState,
        handleSubmit
    } = receiptForm

    const onSubmitSuccess = () => { 
        const payload = {
            ...getValues()
        }
        onSave(payload)
    }

    return <React.Fragment>
        <HiddenField 
            name='caseNote' 
            register={register}
        />
        <HiddenField 
            name='status' 
            register={register}
        />
        <Headline3>
            {t('Details of the firm')}
        </Headline3>

        <PushBottom8 />

        <TwoColumns
            columnLeft={
                <React.Fragment>
                    <TextInput
                        label={t('File number')}
                        name='referenceId'
                        register={register}
                        validate={{notEmpty: validationMethods.notEmpty}}
                        error={errors.referenceId}
                        onChange={updateFormState}
                    />
                    <PhoneNumberInput
                        defaultValue={receiptDefaultValues.phoneNumber}
                        validate={null}
                        control={control}
                        label={t('Phone number (mobile or landline)')}
                        name='phoneNumber'
                        register={register}
                        error={errors.phoneNumber}
                        onChange={updateFormState}
                        setValue={setValue}
                        allowLandLine={true}
                    />
                </React.Fragment>
            }
            columnRight={
                <TextInput
                    label={t('Contact person')}
                    name='name'
                    register={register}
                    validate={null}
                    error={errors.name}
                    onChange={updateFormState}

                />
            }
        />
        <TextArea
            label={t('Comment')}
            placeholder={t('Comment')}
            name='note'
            register={register}
            validate={null}
            error={errors.note}
            hideValidationBox={true}
            onChange={updateFormState}
        />
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save receipt confirmation')}
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </React.Fragment>
}

export { LawfirmReceiptConfirmationForm }