import React from 'react'
import uuidv4 from 'uuid/v4'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/components/controls/form/_common'
import { FormElementWrapper, TextInputWrapper } from '../_common'



export default ({ 
    label, 
    name, 
    register, 
    onKeyPress = () => {}, 
    onKeyPressEnter = () => {}, 
    validate = {},
    error, 
    hideValidationMessage = false, 
    defaultValue='', 
    inputType='text', 
    errorMessageOverride='',
    onChange=()=>{}, 
    cssWidth = '100%', 
    cssMargin='0', 
    autoFocus=false, 
    disabled = false,
    readOnly = false,
} ) => {
    const htmlId = uuidv4()
    const handleKeyPress = e => {
        onKeyPress(e)
        if (e.key === 'Enter') {
            onKeyPressEnter(e)
        }
    }
    return <FormElementWrapper 
        cssWidth={cssWidth}
        cssMargin={cssMargin} 
        disabled={disabled}
    >
        <label 
            className='label' 
            htmlFor={htmlId}
        >
            {label}
            <RequiredFieldMarker 
                validate={validate}
            />
        </label>
        <TextInputWrapper disabled={disabled}>
            <input 
                autoFocus={autoFocus} 
                onKeyPress={handleKeyPress}
                id={htmlId} 
                name={name} 
                defaultValue={defaultValue} 
                ref={register({validate})} 
                type={inputType} 
                onChange={onChange} 
                disabled={disabled} 
                readOnly={readOnly}
            />
        </TextInputWrapper>
        <ValidationErrorMessage 
            hideValidationMessage={hideValidationMessage} 
            error={error} 
            errorMessageOverride={errorMessageOverride} 
        />
    </FormElementWrapper>
}