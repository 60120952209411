import { EXPERT_TYPE } from 'application/constants'


const mapInspectionInfo = (
    inspectionSite, 
    inspectionDate,
    inspectionInfoName
) => {
    return {
        site: inspectionSite,
        date: inspectionDate,
        name: inspectionInfoName
    }
}

export const commissionOtherExpertDataMapper = (
    email, 
    name, 
    inspectionSite, 
    inspectionDate,
    inspectionInfoName
) => {
    return {
        'inspectionInfo': mapInspectionInfo(
            inspectionSite, 
            inspectionDate,
            inspectionInfoName
        ),
        'type': EXPERT_TYPE.OTHER, 
        'email': email,
        'name': name,
        'documents': []
    }
}