export const createUserDataMapper = userFormData => {
    const payload  = {
        email: userFormData.email,
        firstName: userFormData.firstName,
        lastName: userFormData.lastName,
        company: userFormData.company
    }

    if ( userFormData.phoneNumber !== '' ) {
        return {
            ...payload,
            phoneNumber: userFormData.phoneNumber
        }
    }

    return payload
}

export const updateUserGroupsDataMapper = selectedGroups => {
    return {
        groups: selectedGroups.map(g => ({id: g.id}))
    }
}