import React  from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { 
    setQuery, 
} from 'application/components/hooks/use_query'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useRadioCheckBoxesByValue } from 'application/components/hooks'
import PropTypes from 'prop-types'
import { RadioCheckboxWithLabel }  from 'application/components/controls/form/checkboxes/radio_checkbox_with_label'




const FilterRadioCheckboxGroup = (
    {
        pagination,
        filter,
        spaceLeft = false,
        disabled = false,
        checkboxDefinitions = []
    }
) => {
    const location = useLocation()
    const history = useHistory()
    const { t } = useTranslation()

    const {
        isChecked, 
        handleCheckboxChange, 
    } = useRadioCheckBoxesByValue(checkboxDefinitions)

    
    const preHandleCheckBoxChange = (event) => {

        handleCheckboxChange(event)

        setQuery(
            history, 
            location, 
            location.pathname,
            [
                {
                    key: filter.filterKey,
                    value: event.target.name 
                }
            ],
            true,
            [
                pagination.offset,
                pagination.limit
            ]
        )
    }


    return <FlexBox 
        flexDirection='row'
        width='auto'
        cssRules='border: 1px solid #ccc;'
        marginLeft={ spaceLeft ? '16px' : '0'}
        height='40px'
        paddingLeft='10px'
        paddingRight='10px'     
    >
        {
            checkboxDefinitions.map(
                (definition, i) => {

                    const isActive = isChecked(definition.value)

                    return <FlexBox 
                        key={definition.label}
                        width='auto'
                        marginLeft={ i > 0 ? '16px' : '0' }
                        justifyContent='center'
                        alignItems='center'    
                    >
                        <RadioCheckboxWithLabel
                            name={definition.value}
                            label={t(definition.label)}
                            isRequired={false} 
                            isSelected={isActive}
                            activeColor={isActive}
                            onCheckboxChange={preHandleCheckBoxChange}
                            isSmall={true}
                            disabled={disabled}
                            fontSize={'12px'}
                        />
                    </FlexBox>
                } 
            )
        }
    </FlexBox>
}

FilterRadioCheckboxGroup.propTypes = {
    pagination: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    spaceLeft: PropTypes.bool,
    disabled: PropTypes.bool,
    checkboxDefinitions: PropTypes.array
}


export { FilterRadioCheckboxGroup }