import styled from 'styled-components'

export const Content = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding-left: ${props => props.cssPaddingLeft !== undefined ? props.cssPaddingLeft : '16px'};
    padding-right: ${props => props.cssPaddingRight !== undefined ? props.cssPaddingRight : '16px'};
    padding-top: ${props => props.cssPaddingTop !== undefined ? props.cssPaddingTop : '16px'};
    padding-bottom: ${props => props.cssPaddingBottom !== undefined ? props.cssPaddingBottom : '0'};
    overflow: auto;
`
export const ContentRow = styled.div`
    display: flex;
    flex-direction: ${props => props.isColumn ? 'column' : 'row'};
    flex: ${props => typeof props.cssHeight !== undefined ? '0 0 ' + props.cssHeight : '1 1 auto'};
    /* overflow: auto; */
`
export const Main = styled.div`
    display: flex;
    flex: 1 1 100%;
    overflow-y: hidden;
`

export const Spacer = styled.div`
    flex: 1 1 auto;
`