import React from 'react'
import * as Fragments from '../../fragments'
import Image from 'application/components/controls/image'
import { ICONS } from 'application/constants'
import { PushBottom16 } from 'application/components/pages/_common'
import { ContactButton } from '../contact_button'
import { FlexBox } from 'application/components/fragments/flex_box'



export const IconBarBottom = () => {

    return  <Fragments.InfoMainWelcomeWrapper>
        <FlexBox
            flexBasis={'60%'}
        >
            <Fragments.IconBar>
                <Fragments.IconWrapper>
                    <Fragments.LandingPageIcon>
                        <Image data={ICONS.API} />
                    </Fragments.LandingPageIcon>
                    <Fragments.ImageTitle>
                            API
                    </Fragments.ImageTitle>
                    <Fragments.ImageDescription>
                            offene Schnittstellen für die Verknüpfung mit weiterer Software
                    </Fragments.ImageDescription>
                </Fragments.IconWrapper>
    
                <Fragments.IconWrapper>
                    <Fragments.LandingPageIcon>
                        <Image data={ICONS.CLOUD_LOESUNG} />
                    </Fragments.LandingPageIcon>
                    <Fragments.ImageTitle>
                            Cloud Lösung
                    </Fragments.ImageTitle>
                    <Fragments.ImageDescription>
                            Von jedem Device an jedem Ort nutzbar, ohne Eingriff in die eigene IT-Infrastruktur
                    </Fragments.ImageDescription>
                </Fragments.IconWrapper>
    
                <Fragments.IconWrapper>
                    <Fragments.LandingPageIcon>
                        <Image data={ICONS.DATENSCHUTZ} />
                    </Fragments.LandingPageIcon>
                    <Fragments.ImageTitle>
                                 Datenschutz
                    </Fragments.ImageTitle>
                    <Fragments.ImageDescription>
                            100 % DSGVO konforme in Deutschland gehostete Online-Plattform
                    </Fragments.ImageDescription>
                </Fragments.IconWrapper>
            </Fragments.IconBar>
        </FlexBox>
        <FlexBox 
            flexBasis={'40%'}
        >
            <Fragments.TextWrapper>
                <FlexBox flexDirection='column'>
                    <Fragments.SuperTitle>Schadenabwicklung 4.0</Fragments.SuperTitle>
                    <Fragments.Title>
                                SAAS für die effektive Schadenabwicklung
                    </Fragments.Title>
                    <Fragments.Description>
                            Tauchen Sie ein in eine beschleunigte und transparente Dimension der Schadenabwicklung. 
                            Ohne Ihre bestehende IT-Infrastrukturen zu verändern. 
                            Sicherer Austausch von hoch sensiblen Kundendaten garantiert. 
                    </Fragments.Description>
                    <PushBottom16/>
                    <FlexBox >
                        <ContactButton />
                    </FlexBox>
                    <PushBottom16/>
                </FlexBox>
            </Fragments.TextWrapper>
        </FlexBox>
    </Fragments.InfoMainWelcomeWrapper>


}