import React from 'react'
import PropTypes from 'prop-types'
import TruncateString from 'react-truncate-string'
import { FontIcon } from '@ec/ui-controls-react'
import { contains } from 'application/common/data_helpers'
import Preloader from 'application/components/controls/themed_preloader'
import FallbackThumbnail from 'application/components/controls/fallback_thumbnail'
import * as Fragments from './fragments'
import { useTranslation } from 'react-i18next'


const DocCenterThumbnailComponent = (
    { 
        attachment, 
        damageId,
        onAttachmentClick = () => {},
        onMoAddSelectedItemByName = () => {},
        onMoRemoveSelectedItemByName = () => {},
        showCaption = true,
        mo = null
    }
) => {
    const {t} = useTranslation()
    const moIsActive = mo !== null && mo.isActive

    const isSelectedByMo =  moIsActive
        ? contains(
            mo.selectedItems,
            attachment
        )
        : false    


    const chooseAndFireMoActionBySelection = (isSelected) => {
        isSelected
            ? onMoRemoveSelectedItemByName(
                mo.moName,
                attachment.id
            )
            : onMoAddSelectedItemByName(
                mo.moName,
                attachment
            )
    }

    const handleOnClick = () => {
        moIsActive
            ?
            chooseAndFireMoActionBySelection(isSelectedByMo)
            :
            onAttachmentClick(
                attachment,
                damageId
            )
    }

    return (
        <Fragments.StyledAttachment 
            onClick={handleOnClick} 
            isActive={isSelectedByMo} 
            className='jest-attachment-thumbnail'
        >
            <Fragments.StyledThumbnail>
                {
                    attachment.hasThumbnail 
                    && !attachment.thumbnailLoadingFailed 
                        ? attachment.thumbnailIsLoading 
                            ? <Preloader />
                            : <Fragments.StyledThumbnailImage 
                                src={attachment.thumbnail} 
                                alt={attachment.fileName} 
                            />
                        : <FallbackThumbnail 
                            fileExtension={attachment.fileExtension} 
                            isActive={isSelectedByMo} 
                        />
                }                
            </Fragments.StyledThumbnail>
            {
                showCaption && <Fragments.StyledCaption
                    isActive={isSelectedByMo}
                >
                    <TruncateString 
                        text={attachment.fileName} 
                        truncateAt={40}
                    />
                </Fragments.StyledCaption>
            }
            {
                attachment.isExportedToLawFirm && <Fragments.StyledExportMarker>
                    <FontIcon
                        icon='sync'
                        title={t('Available for legal software')}
                    />
                </Fragments.StyledExportMarker>
            }
            
        </Fragments.StyledAttachment>
    )

}

DocCenterThumbnailComponent.propTypes = {
    damageId: PropTypes.string.isRequired,
    attachment: PropTypes.object.isRequired,
    onAttachmentClick: PropTypes.func,
    onMoAddSelectedItemByName: PropTypes.func,
    onMoRemoveSelectedItemByName: PropTypes.func, 
    showCaption: PropTypes.bool,
    mo: PropTypes.object
}

export default DocCenterThumbnailComponent