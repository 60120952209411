import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'


const TwoColumns = (
    {
        columnLeft = null,
        columnRight = null,
        spacing = '12px',
        breakPoint = null
    }
) => {

    const breakPointCssRules = breakPoint !== null ? 'flex-direction:column;' : ''
    const dividerBreakPointCssRules = breakPoint !== null ? 'display: none;' : ''


    return <FlexBox breakPoint={breakPoint} breakPointCssRules={breakPointCssRules}>
        <FlexBox 
            flexDirection='column'
        >
            {columnLeft}
        </FlexBox>
        <FlexBox 
            flexBasis={spacing} 
            flexGrow='0' 
            flexShrink='0' 
            breakPoint={breakPoint} 
            breakPointCssRules={dividerBreakPointCssRules}
        />
        <FlexBox 
            flexDirection='column'
        >
            {columnRight}
        </FlexBox>
    </FlexBox>
}

export default TwoColumns