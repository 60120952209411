import React, {useEffect} from 'react'
import * as Fragment from './fragments'
import LanguageSwitchHoc from 'application/components/building_blocks/language_switch/hoc'
import { useTranslation } from 'react-i18next'
import { GlobalStyle } from 'application/style'
import { IMAGES } from 'application/constants'
import Image from 'application/components/controls/image'
import { applicationToken } from 'application/storage'
import { SubParagraph } from 'application/components/fragments/typography'
import { defaultUserManager } from 'application/common/user_manager'

const RegistrationLayout = ({leftAreaWidth = '40%', rightAreaWidth = '60%', children}) => {
    const {t} = useTranslation()

    useEffect(()=>{
        defaultUserManager.removeUser()
        defaultUserManager.clearStaleState()
        applicationToken.deleteStorage()
        localStorage.clear()
    })

    return <Fragment.LayoutWrapper>
        <GlobalStyle />
        <Fragment.LayoutInputWrapper cssWidth={leftAreaWidth}>
            <Fragment.LayoutInput cssWidth={leftAreaWidth}>
                <LanguageSwitchHoc />
                <Fragment.FormLoginWrapper>
                    {children}
                </Fragment.FormLoginWrapper>
                <Fragment.LogoWrapper>
                    <Fragment.StyledBigLogo>
                        <Image data={IMAGES.LOGO_72} />
                    </Fragment.StyledBigLogo>
                    <SubParagraph cssAlign='center' cssPadding='8px 0 0'>
                        {t('e.sy 360 is a product of e.Consult AG')}
                    </SubParagraph>
                </Fragment.LogoWrapper>

                <Fragment.PolicyWrapper>
                    <a target='_blank' rel="noopener noreferrer" href={process.env.REACT_APP_IMPRINT_LINK}>
                        {t('legal notice')}
                    </a>
                    <a target='_blank' rel="noopener noreferrer" href={process.env.REACT_APP_PRIVACY_LINK}>
                        {t('privacy')}
                    </a>
                    <a target='_blank' rel="noopener noreferrer" href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}>
                        {t('conditions')}
                    </a>
                </Fragment.PolicyWrapper>
            </Fragment.LayoutInput>
        </Fragment.LayoutInputWrapper>
        <Fragment.LayoutInfoWrapper cssWidth={rightAreaWidth} src='/images/sample-login-anwalt_2.jpg' />
    </Fragment.LayoutWrapper>
}

export default RegistrationLayout