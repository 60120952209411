import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import MenuIcon from '../menu_icon'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const LinkWrapper = styled.div`
    display: flex;
    width: 100%;
    height: inherit;
    align-items: center;
    max-height: 48px;
    font-size: ${props => props.theme.fontSize.small };
`

const Link = styled(NavLink)`
    display: flex;
    height: inherit;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    color: ${props => props.$active ? props.primarycolor : props.theme.color.white };
    background: ${props => props.$active ? props.theme.color.white : props.theme.color.transparent};

    &:hover{
        background: ${props => props.$active ? props.theme.color.white : props.primaryhovercolor};
    }
`

const ButtonLink = (
    { 
        pathDestination,
        primaryColor,
        primaryHoverColor,
        label='', 
        isActive = false, 
        iconKey = ''
    }
) => {

    const {t} = useTranslation()

    return <React.Fragment>
        <LinkWrapper>    
            <Link 
                to={pathDestination}
                primarycolor={primaryColor}
                primaryhovercolor={primaryHoverColor}
                $active={isActive} 
            >
                <FlexBox
                    height='inherit'
                    flexBasis='32px'
                />
                { 
                    iconKey !== '' && <MenuIcon
                        onClick={()=>{}}
                        iconKey={iconKey} 
                        isClickable={true}
                    />
                }
                <FlexBox
                    height='inherit'
                    alignItems='center'
                    flexBasis='1'
                    isDisabled={false}
                >
                    {t(label)}
                </FlexBox>

            </Link>
        </LinkWrapper>
    </React.Fragment>
}

ButtonLink.propTypes = {
    pathDestination: PropTypes.string.isRequired,
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired,
    label: PropTypes.string,
    isActive: PropTypes.bool, 
    iconKey: PropTypes.string
}

export default ButtonLink