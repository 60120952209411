import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DAMAGE_TYPE } from 'application/constants'
import { Select } from 'application/components/controls/form'
import DamageTypeName from 'application/components/name_mappings/damage_type_name'

const DamageTypeSelect = ({
    name='type',
    register,
    setValue,
    initialValue,
    menuPlacement='top',
    onChange = () => {},
    validate = null, //usually no validation needed, no empty value possible
    error = null
}) => {
    const {t} = useTranslation()

    const damagetypOptions = [
        {
            value: DAMAGE_TYPE.UNKNOWN,
            label: <DamageTypeName damageType={DAMAGE_TYPE.UNKNOWN} />
        },
        {
            value: DAMAGE_TYPE.LIABILITY,
            label: <DamageTypeName damageType={DAMAGE_TYPE.LIABILITY} />
        },
        {
            value: DAMAGE_TYPE.COMPREHENSIVE,
            label: <DamageTypeName damageType={DAMAGE_TYPE.COMPREHENSIVE} />
        }
    ]

    const defaultValue = damagetypOptions.find(o => o.value === initialValue)

    return <Select 
        label={t('Damage type')}
        name={name}
        register={register}
        setValue={setValue}
        options={damagetypOptions}
        defaultValue={defaultValue}
        validate={validate} 
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
        error={error}
    />
}

DamageTypeSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}

export default DamageTypeSelect