import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetDamageCommissionedLawfirmSaga(action) {
    const damageId = action.match.params.damageId
    let data = {
        lawfirmFound: false,
        lawfirm: null
    }
    try {
        const response = yield resourceCall(
            api.gateway.damage.getLawfirm,
            {
                damageId
            }
        )
        data = {
            lawfirmFound: true,
            lawfirm: response
        }
    } catch (error) {
    
    }
    yield put(updateWidget(action.key, action.dependentWidgetKeys, data))
}