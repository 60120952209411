import { put, all, call } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { customer } from 'application/api/data_mappers/export'

export function* dialogCommissionDekraExpertSaga(action) {

    const { 
        damageId, 
        categoryId 
    } = action.metaData
    
    const { 
        damageData, 
        commissionExpertPayload,
        customerData,
        documents
    } = action.payload
    
    //update damage
    yield put(showWaitingScreen('Saving damage data'))
    yield resourceCall(
        api.gateway.damage.updateDamage,
        {
            damageId 
        },
        damageData
    )
    yield put(hideWaitingScreen())

    
    //update customer
    yield put(showWaitingScreen('Saving customer data'))
    const mappedPayload = customer.removeEmptyStringProperties(customerData)
    yield resourceCall(
        api.gateway.customer.putCustomer, 
        {
            customerId: customerData.customerId
        }, 
        mappedPayload
    )
    yield put(hideWaitingScreen())



    //upload files
    yield put(showWaitingScreen('Uploading files'))

    const postAttachmentsServerCalls = documents.map(
        file => function* () {        
            yield put(
                addNewSubMessage(file.metaData.name)
            )
            const response = yield resourceCall(
                api.gateway.attachments.postAttachment, 
                {
                    damageId,
                    categoryId
                },
                {
                    file: file.metaData
                }
            )
            return response
        }
    )

    const attachmentsResponses = yield all(postAttachmentsServerCalls.map(sc => call(sc)))
    commissionExpertPayload.documents = attachmentsResponses.map(r => r.id)
    yield put( hideWaitingScreen() )
    yield put( showWaitingScreen('Assigning expert') )
    yield resourceCall(
        api.gateway.expert.updateCommissionExpert,
        {
            damageId 
        },
        commissionExpertPayload
    )

    yield put( hideWaitingScreen() )
    yield put( showToast('DEKRA expert commissioned') )
}