import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { TextArea } from 'application/components/controls/form'
import LiabilityInquiryStatusSelect from 'application/components/controls/form/liability_inquiry_status_select'
import LiabilityInquiryInfoSelect from 'application/components/controls/form/liability_inquiry_info_select'
import { validationMethods } from 'application/common'
import { useStatefulForm } from 'application/components/hooks'
import TwoColumns from '../_common'
import { FlexBox } from 'application/components/fragments/flex_box'





const LawfirmLiabilityInquiryForm = (
    { 
        formData = {}, 
        widgetKey, 
        onSave
    }
) => {

    const {t} = useTranslation()
    const { notEmpty }= validationMethods

    const defaultValues = {
        status: formData.generalCommunication?.liabilityInquiry?.status || null,
        info: formData.generalCommunication?.liabilityInquiry?.info || null,
        description: formData.generalCommunication?.liabilityInquiry?.description || ''
    }

    const {
        register, 
        errors, 
        handleSubmit, 
        setValue, 
        getValues, 
        formStateValues, 
        updateFormState
    } = useStatefulForm({ defaultValues })

    const onSubmitSuccess = () => { 
        onSave({
            ...formStateValues, //include all values
            ...getValues()
        })
    }

    return <FlexBox 
        flexDirection='column' 
        flexBasis='100%'
    >
        <FlexBox flexGrow='1'>
            <TwoColumns
                columnLeft={                  
                    <LiabilityInquiryStatusSelect
                        error={errors.status}
                        register={register}
                        setValue={setValue}
                        initialValue={formStateValues.status}
                        onChange={updateFormState}
                        validate={{
                            notEmpty
                        }}
                    />
                }
                columnRight={
                    <LiabilityInquiryInfoSelect
                        error={errors.info}
                        register={register}
                        setValue={setValue}
                        initialValue={formStateValues.info}
                        onChange={updateFormState}
                        validate={{
                            notEmpty
                        }}
                    />
                }
            />
        </FlexBox>
        <FlexBox flexGrow='1'>
            <TextArea
                label=''
                placeholder={t('Comment')}
                name='description'
                register={register}
                validate={null}
                error={errors.description}
                hideValidationBox={true}
            />
        </FlexBox>


        <FlexBox flexGrow='1' justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save liability inquiry')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </FlexBox>
}

export { LawfirmLiabilityInquiryForm }