import React, {useCallback} from 'react'
import { useDropzone } from 'react-dropzone'
import { fileHelpers } from 'application/common'
import * as Fragments from './fragments'

const Uploader = ({
    onFileAdded, 
    onFilesRejected,
    children,
    disabled=false,
    onFilesAdded,
}) => {
    const options = {
        disabled: disabled,
        accept: fileHelpers.getAccept(),
        onDropRejected: onFilesRejected,
        minSize: 1,
        onDrop: useCallback(acceptedFiles => { 
            fileHelpers.readFiles(acceptedFiles, onFileAdded, onFilesAdded)
        }, [onFileAdded, onFilesAdded])
    }
    const {getRootProps, getInputProps/*, isDragActive*/} = useDropzone({...options})
    const {onClick, ...rootProps} = getRootProps()
    return <Fragments.DropZone disabled={disabled} {...rootProps}>
        <input {...getInputProps()} />
        {children}
    </Fragments.DropZone>
}

export default Uploader