export const REALTIME_NOTIFICATION_TYPE = {
    DAMAGE_ASSIGNEE_CHANGED: 'DamageAssigneeChangedNotification',
    DAMAGE_CREATED: 'DamageCreatedNotification',
    DAMAGE_DELETED: 'DamageDeletedNotification',
    DAMAGE_DESCRIPTION_CHANGED: 'DamageDescriptionChangedNotification',
    DAMAGE_INCIDENT_DATE_CHANGED: 'DamageIncidentDateChangedNotification',
    DAMAGE_JOB_NUMBER_CHANGED: 'DamageJobNumberChangedNotification',
    DAMAGE_NOTE_CHANGED: 'DamageNoteChangedNotification',
    DAMAGE_STATE_CHANGED: 'DamageStateChangedNotification',
    DAMAGE_TYPE_CHANGED: 'DamageTypeChangedNotification',
    DOCUMENT_CREATED: 'DocumentCreatedNotification',
    DOCUMENT_DELETED: 'DocumentDeletedNotification',
    INVOICE_CREATED: 'InvoiceCreatedNotification',
    INVOICE_DELETED: 'InvoiceDeletedNotification',
    LAWFIRM_COMMUNICATION_ABORTED: 'LawFirmCommunicationAbortedNotification',
    LAWFIRM_COMMUNICATION_INITIATED: 'LawFirmCommunicationInitiatedNotification',
    MESSAGE_CREATED: 'MessageCreatedNotification',
    MESSAGE_DELETED: 'MessageDeletedNotification',
    TASK_COMMENT_CREATED: 'TaskCommentCreatedNotification',
    TASK_CREATED: 'TaskCreatedNotification',
    TASK_DELETED: 'TaskDeletedNotification',
    TASK_STATE_CHANGED: 'TaskStateChangedNotification',
    VEHICLE_KEEPER_CHANGED: 'VehicleKeeperChangedNotification',
    VEHICLE_UPDATED: 'VehicleUpdatedNotification',
    EXPERT_COMMUNICATION_DEKRA_ESTABLISHED : 'ExpertCommunicationDekraEstablishedNotification',
    EXPERT_COMMUNICATION_DEKRA_FAILED : 'ExpertCommunicationDekraFailedNotification',
    EXPERT_COMMUNICATION_DEKRA_INITIATED : 'ExpertCommunicationDekraInitiatedNotification',
    EXPERT_COMMUNICATION_OTHER_INITIATED : 'ExpertCommunicationOtherInitiatedNotification',
}

export const REALTIME_NOTIFICATION_CHANNEL = 'notification'