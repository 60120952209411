//reducer for the generic title bar
import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.common, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.common
    }
    case ACTIONS.COMMON_SET_USERS_LOADING: {
        return {
            ...state,
            usersList: {
                users: [],
                total: 0
            },
            usersListLoaded: false,
            usersListLoading: true
        }

    }
    case ACTIONS.COMMON_CLEAR_USERS_LIST: {
        return {
            ...state,
            usersList: {
                users: [],
                total: 0
            },
            usersListLoaded: false,
            usersListLoading: false
        }
    }
    case ACTIONS.COMMON_POPULATE_USERS:
        return {
            ...state,
            usersList: action.usersList,
            usersListLoaded: true,
            usersListLoading: false
        }
    case ACTIONS.COMMON_RESET_USERS:  //redundant to COMMON_CLEAR_USERS_LIST ?
        return {
            ...state,
            usersList: {
                users: [],
                total: 0
            },
            usersListLoaded: false,
            usersListLoading: false
        }        
    case ACTIONS.COMMON_GET_CATEGORIES_REQUEST:
        return {
            ...state,
            categories: [],
            categoriesLoaded: false,
            categoriesLoading: true,
            categoriesAccountId: null
        }
    case ACTIONS.COMMON_RESET_CATEGORIES:
        return {
            ...state,
            categories: [],
            categoriesLoaded: false,
            categoriesLoading: false,
            categoriesAccountId: null
        }
    case ACTIONS.COMMON_POPULATE_CATEGORIES:
        return {
            ...state,
            categories: action.categories,
            categoriesLoaded: true,
            categoriesLoading: false,
            categoriesAccountId: action.accountId
        }
    case ACTIONS.COMMON_GET_INSURANCES_REQUEST:
        return {
            ...state,
            insurances: [],
            insurancesLoaded: false,
            insurancesLoading: true
        }
    case ACTIONS.COMMON_POPULATE_INSURANCES:
        return {
            ...state,
            insurances: action.insurances,
            insurancesLoaded: true,
            insurancesLoading: false
        }
    case ACTIONS.COMMON_GET_ACCESSIBLE_BRANCHES_REQUEST:
        return {
            ...state,
            accessibleBranches: [],
            allBranches: [],
            accessibleBranchesLoaded: false,
            accessibleBranchesLoading: true
        }
    case ACTIONS.COMMON_GET_FIRST_BRANCH_REQUEST:
        return {
            ...state,
            firstBranch: null,
            firstBranchLoaded: false,
            firstBranchLoading: true
        }
    case ACTIONS.COMMON_RESET_ACCESSIBLE_BRANCHES:
        return {
            ...state,
            accessibleBranches: [],
            allBranches: [],
            accessibleBranchesLoaded: false,
            accessibleBranchesLoading: false
        }
    case ACTIONS.COMMON_POPULATE_ACCESSIBLE_BRANCHES:
        return {
            ...state,
            accessibleBranches: action.accessibleBranches,
            allBranches:  action.allBranches,
            accessibleBranchesLoaded: true,
            accessibleBranchesLoading: false
        }
    case ACTIONS.COMMON_POPULATE_FIRST_BRANCH:
        return {
            ...state,
            firstBranch: action.branch,
            firstBranchLoaded: true,
            firstBranchLoading: false
        }
    case ACTIONS.COMMON_UPDATE_BRANCH_IN_LIST:
        return {
            ...state,
            allBranches: state.allBranches.map(
                branch => {
                    return branch.id !== action.branchId
                        ? branch
                        : action.branchData
                }
            ),
        }
    case ACTIONS.COMMON_ADD_BRANCH_TO_LIST:
        return {
            ...state,
            allBranches: [
                ...state.allBranches, 
                action.branch
            ]
        }
    case ACTIONS.COMMON_GET_COMPREHENSIVE_DAMAGE_TYPES_REQUEST:
        return {
            ...state,
            comprehensiveDamageTypes: [],
            comprehensiveDamageTypesLoaded: false,
            comprehensiveDamageTypesLoading: true
        }
    case ACTIONS.COMMON_POPULATE_COMPREHENSIVE_DAMAGE_TYPES:
        return {
            ...state,
            comprehensiveDamageTypes: action.comprehensiveDamageTypes,
            comprehensiveDamageTypesLoaded: true,
            comprehensiveDamageTypesLoading: false
        }
    case ACTIONS.COMMON_RESET_BRANCHES:
        return {
            ...state,
            accessibleBranches: [],
            allBranches: [],
            accessibleBranchesLoaded: false,
            accessibleBranchesLoading: false
        }
    case ACTIONS.COMMON_GET_USER_GROUPS_REQUEST:
        return {
            ...state,
            userGroupsLoaded: false,
            userGroupsLoading: true
        }
    case ACTIONS.COMMON_POPULATE_USER_GROUPS:
        return {
            ...state,
            userGroupsLoaded: true,
            userGroupsLoading: false,
            userGroups: action.groups
        }
    case ACTIONS.COMMON_RESET_USER_GROUPS:
        return {
            ...state,
            userGroupsLoaded: false,
            userGroupsLoading: false,
            userGroups: []
        }
    case ACTIONS.COMMON_GET_ROLES_REQUEST:
        return {
            ...state,
            rolesLoaded: false,
            rolesLoading: true
        }
    case ACTIONS.COMMON_POPULATE_ROLES:
        return {
            ...state,
            rolesLoaded: true,
            rolesLoading: false,
            roles: action.roles
        }
    case ACTIONS.COMMON_RESET_ROLES:
        return {
            ...state,
            rolesLoaded: false,
            rolesLoading: false,
            roles: []
        }

    //branch management constraints
    case ACTIONS.BRANCH_MANAGEMENT_REMOVE_GROUP_ROLE_RELATIONSHIP:
        return {
            ...state,
            userGroups: state.userGroups.map(
                u => {
                    return u.id !== action.groupId
                        ? u
                        : {
                            ...u,
                            roles: u.roles.filter(r => r.id !== action.roleId)
                        }
                }
            )
        }
    case ACTIONS.BRANCH_MANAGEMENT_REMOVE_GROUP_BRANCH_RELATIONSHIP:
        return {
            ...state,
            userGroups: state.userGroups.map(u => {
                return u.id !== action.groupId
                    ? u
                    : {
                        ...u,
                        branches: u.branches.filter(b => b.id !== action.branchId)
                    }
            })
        }
    case ACTIONS.BRANCH_MANAGEMENT_ADD_GROUP_ROLE_RELATIONSHIP:
        return {
            ...state,
            userGroups: state.userGroups.map(u => {
                return u.id !== action.groupId
                    ? u
                    : {
                        ...u,
                        roles: [...u.roles, {
                            id: action.roleId,
                            name: state.roles.find(r => r.id === action.roleId).name
                        }]
                    }
            })
        }
    case ACTIONS.BRANCH_MANAGEMENT_ADD_GROUP_BRANCH_RELATIONSHIP:
        return {
            ...state,
            userGroups: state.userGroups.map(u => {
                return u.id !== action.groupId
                    ? u
                    : {
                        ...u,
                        branches: [...u.branches, {
                            id: action.branchId,
                            name: state.allBranches.find(b => b.id === action.branchId).name
                        }]
                    }
            })
        }
    case ACTIONS.COMMON_REQUEST_STATUS_LIST: {
        return {
            ...state,
            statusList: {
                ...state.statusList,
                loaded: false,
                loading: true
            }
        }
    }   
    case ACTIONS.COMMON_POPULATE_STATUS_LIST: {
        return {
            ...state,
            statusList: {
                ...state.statusList,
                data: action.statusListData,
                loaded: true,
                loading: false
            }
        }
    }   
        
    default:
        return state
    }
}
