import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserDataForm } from './form'
import { ActionButton } from '@ec/ui-controls-react'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label'
import { useForm } from 'react-hook-form'
import {CreateUserDialogLayout} from 'application/components/dialogs/users/create/_common/layout'




const EnterUserDataContentComponent = (
    {
        onDataChange,
        abort,
        setUserFormData,
        getStepsState,
        goToNextStep,
        setFirstName,
        setLastName,
        firstName,
        lastName,

        defaultValues = {
            email: '',
            firstName: '',
            lastName: '',
            company: '',
            phoneNumber: ''
        }
    }
) => {
    const { t } = useTranslation()

    const { 
        register, 
        errors,
        handleSubmit, 
        control, 
        getValues, 
        setValue 
    } = useForm({defaultValues})




    const onSubmitSuccess = () => {
        const payload =  getValues()
        setUserFormData(payload)
        goToNextStep()
    }



    return <CreateUserDialogLayout
        getStepsState={getStepsState}
        firstName={firstName}
        lastName={lastName}
    
        form={
            <UserDataForm 
                defaultPhoneNumber = {defaultValues.phoneNumber}
                register={register} 
                errors={errors}
                control={control}
                setValue={setValue}
                onDataChange={onDataChange}
                setFirstName={setFirstName}
                setLastName={setLastName}
                t={t}
            />
        }
        footerElements={
            <React.Fragment>
                <IconButtonWithLabel 
                    iconKey='not_interested' 
                    label={t('Cancel')} 
                    onButtonClick={abort} 
                />
                <ActionButton 
                    buttonText={t('Next')} 
                    onButtonClick={
                        handleSubmit(
                            onSubmitSuccess
                        )
                    } 
                />
            </React.Fragment>
        }
    />

}


export {EnterUserDataContentComponent}