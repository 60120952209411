import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { TASK_PRIORITIES } from 'application/constants'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextInput, Select, TextArea, DatePicker } from 'application/components/controls/form'
import PrioritySelect from 'application/components/controls/form/priority_select'
import { Footer } from 'application/components/dialogs/_common/footer'
import { nameHelpers } from 'application/common'
import { FlexBox } from 'application/components/fragments/flex_box'
import TwoColumns from 'application/components/forms/_common'
import { exists } from 'application/common/data_helpers'
import { useStatefulForm } from 'application/components/hooks'
import { isBeforeToday } from 'application/common/date_time_helpers'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'


const CreateTask = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged, 
        onCreateTask, 
        dialog,
        onSearchUser
    }
) => {
    const damageIncidentDate = dialog.payload.damage?.incidentDate
    const { t } = useTranslation()

    const defaultValues = {
        priority: TASK_PRIORITIES.MEDIUM,
    }

    const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        setValue, 
        control,
        formStateValues,
        updateFormState
    } = useStatefulForm(
        {defaultValues}
    )
    
    const {usersList, damageId} = dialog.payload

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
        updateFormState()
    }


    
    const onSubmitSuccess = () => { 

        const payload = {
            ...getValues()
        }

        const hasAssignee = exists(getValues().assigneeId)

        const assignee = hasAssignee 
            ? usersList.find(
                user => user.id === getValues().assigneeId 
            )
            : null

        const metaData = {
            damageId: damageId,
            hasAssignee,
            assignee

        }

        onCreateTask(
            dialog.name, 
            payload,
            metaData
        )
    }

    const handleSearchUser = search => {
        if(userSearchActive && search.length > 2){
            onSearchUser(dialog.name, search)
        }  
    }

    const onError = (errors, e) => console.log(errors, e)

    const usersOptions = usersList.map(
        (u, i) => ({
            value: u.id,
            label: nameHelpers.getFullName(u),
            firstName: u.firstName,
            lastName: u.lastName
        })
    )

    return <React.Fragment>
        <DialogMainContent flexDirection={'row'} cssHeight='330px'>
            <FlexBox flexDirection='column'>
                <TwoColumns
                    columnLeft={
                        <TextInput 
                            label={t('Title')}
                            name='title'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.title}
                            onChange={onDataChange}
                        />
                    }
                    columnRight={
                        <DatePicker 
                            label={t('Deadline')}
                            name='deadline'
                            validate={null}
                            error={errors.deadline}
                            disabledDays={{before: new Date(damageIncidentDate)}}
                            onDateChange={()=>{
                                onDataChange()
                            }}
                            control={control}
                        />
                    }
                />
                <TwoColumns
                    columnLeft={
                        <Select 
                            label={t('Assign employee')}
                            name='assigneeId'
                            register={register}
                            setValue={setValue}
                            options={usersOptions}
                            error={errors.assignedTo}
                            validate={null}
                            menuPlacement='bottom'
                            onInputChange={handleSearchUser}
                            onBlur={()=>{setUserSearchActive(false)}}
                            onFocus={()=>{setUserSearchActive(true)}}
                            noOptionsMessage={t('No users found')}
                            placeholder={t('Enter at least 3 characters')}
                            isLoading={dialog.payload.usersListLoading}
                            onChange={onDataChange}
                        />
                    }
                    columnRight={
                        <PrioritySelect 
                            register={register}
                            setValue={setValue}
                            initialValue={formStateValues.priority}
                            error={errors.priority}
                            onChange={onDataChange}
                        />
                    }
                />
                <TwoColumns
                    columnLeft={
                        <TextArea 
                            label={t('Description')}
                            name='description'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.description}
                            onChange={onDataChange}
                        />
                    }
                />
            </FlexBox>
        </DialogMainContent>
        <Footer
            showDeadlineInfo={isBeforeToday(formStateValues.deadline)}
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess, onError)}
            submitText='Create Task'
        />
    </React.Fragment>
}

export default withDialogDataLoader(CreateTask)