import React from 'react'
import { ActionButton } from '@ec/ui-controls-react'
import { FlexBox } from 'application/components/fragments/flex_box'

export const StepsNavigation = ({
    onClickNext,
    onClickPrevious,
    previousVisible = true,
    nextText = 'weiter'
}) => {

    return <FlexBox flexDirection='row' justifyContent='center'>
        {
            previousVisible && <ActionButton buttonText='zurück' secondary={true} onButtonClick={onClickPrevious} />
        }
        <ActionButton buttonText={nextText} onButtonClick={onClickNext} />
    </FlexBox>
}