import React from 'react'
// import PropTypes from 'prop-types'
// import { useTranslation } from 'react-i18next'

import { WIDGET_CONTEXTS } from 'application/constants'
import { SubPageLayout } from 'application/components/pages/_common'
import WidgetBoard from 'application/components/building_blocks/widget_board'

const FormWorkflowPanel = ({damageLoaded}) => {
    // const { t } = useTranslation()
    return <SubPageLayout paddingLeft='16px' paddingRight='16px'>
        { 
            damageLoaded &&  <WidgetBoard widgetContext={WIDGET_CONTEXTS.WORKFLOW_PANEL_FORM}/>
        }
    </SubPageLayout>
}

FormWorkflowPanel.propTypes = {
}

export default FormWorkflowPanel