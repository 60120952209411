import React, { useState } from 'react'
import { TextInput } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { REASONS_FOR_PAYMENT_REDUCTION } from 'application/constants/payment'
import { validationMethods } from 'application/common'
import { CheckBoxGroup } from '../checkbox_group'


const CheckBoxGroupWithFreetextField = (
    {
        options,
        register,
        errors, 
        onDataChange = () => {},
        checkBoxGroupName='checkboxGroup',
        freeTextName='freetextField'
    }
) => {
    const {t} = useTranslation()

    const optionsToRender = [
        ...options, 
        {
            label: t(REASONS_FOR_PAYMENT_REDUCTION.OTHER),
            value: REASONS_FOR_PAYMENT_REDUCTION.OTHER
        },
    ]

    const [freetextFieldSelected, setFreetextFieldSelected] = useState(false)

    const handleOnChange = (e, isChecked) => {
        if(e.target.value === REASONS_FOR_PAYMENT_REDUCTION.OTHER){
            setFreetextFieldSelected(isChecked)
        }
    }


    return <React.Fragment>
        <CheckBoxGroup
            options = {optionsToRender}
            register = {register}
            errors = {errors}
            name = {checkBoxGroupName}
            onChange={handleOnChange}
        />
        {
            freetextFieldSelected && <TextInput
                label = {t('Free designation')}
                name = {freeTextName}
                register = {register}
                validate = {{
                    notEmpty: validationMethods.notEmpty, 
                    max50Characters: validationMethods.max50Characters
                }}
                error = {errors.description}
                onChange = {onDataChange} 
            /> 
        }
    </React.Fragment>
}

CheckBoxGroupWithFreetextField.propTypes = {
    options: PropTypes.array.isRequired,
    validate: PropTypes.object,
    name: PropTypes.string, 
    register: PropTypes.func,
    errors: PropTypes.object.isRequired, 
    onDataChange: PropTypes.func,
    couldBeEmpty: PropTypes.bool
}

export { CheckBoxGroupWithFreetextField } 