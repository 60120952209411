import { combineReducers } from 'redux'
import { reducer as oidcReducer } from 'redux-oidc'

import auth from './auth'
import notifications from './notifications'
import dialogs  from './dialogs'
import titleBar from './title_bar'
import pages from './pages'
import widgets from './widgets'
import widgetContexts from './widget_contexts'
import common from './common'
import attachments from './attachments'
import waitingScreen from './waiting_screen'
import gallery from './gallery'
import ACTIONS from 'application/constants'
import default_store from './default_store'
import registration from './registration'
import realtimeNotifications from './realtime_notifications'
import {massOperationsReducer as massOperations} from './mass_operations'
import {sharedResourceReducer as sharedResource} from './shared_resource'
import mainNavigationSidebar from './main_navigation_sidebar'
import updateScheduler from './update_scheduler'
import errors from './errors'
import {dialogStepsWaitingScreen} from './dialog_steps_waiting_screen'

const reducers = combineReducers({
    auth,
    attachments,
    notifications,
    dialogs,
    titleBar,
    pages,
    widgets,
    widgetContexts,
    common,
    waitingScreen,
    gallery,
    registration,
    realtimeNotifications,
    oidc: oidcReducer,
    massOperations,
    sharedResource,
    mainNavigationSidebar,
    updateScheduler,
    errors,
    dialogStepsWaitingScreen
})
    
const appReducer = (state, action) => { 
    switch (action) {
    case ACTIONS.AUTH_LOGOUT_REQUEST:
        return {
            ...default_store, //reset the complete store on logout
            //except:
            auth: state.auth, //is handled by auth reducer -> case ACTIONS.AUTH_LOGOUT_REQUEST:
            notifications: state.notifications //notifications should survive logout
        }
    default:
        return reducers(state, action)
    }
}

export default appReducer
