import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { validationMethods } from 'application/common'
import { TextInput, TextArea } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'
import YesNoSelect from 'application/components/controls/form/yes_no_select/component'
import { useStatefulForm } from 'application/components/hooks'
import TwoColumns from '../_common'

const DamageAccidentReportForm = ({formData = {}, damageId, onSave}) => {
    const {t} = useTranslation()
    
    const defaultValues = {
        locationDescription: formData?.accidentReport?.location?.description || null,
        circumstances: formData?.accidentReport?.circumstances || null,
        damageToPersons: formData?.accidentReport?.damageToPersons
    }

    const {register, errors, handleSubmit, getValues, setValue, formStateValues, updateFormState} = useStatefulForm({ defaultValues })

    const onSubmitSuccess = () => { 
        onSave(damageId, getValues())
    }
    
    return <React.Fragment>
        <TwoColumns
            columnLeft={
                <TextInput 
                    label={t('Accident location')}
                    name='locationDescription'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.locationDescription}
                />
            }
            columnRight={
                <YesNoSelect 
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.damageToPersons}
                    name='damageToPersons'
                    label={t('Damage to person')}
                    onChange={updateFormState}
                />
            }
          
        />
        <TextArea 
            label={t('Accident description')}
            name='circumstances'
            register={register}
            validate={{notEmpty: validationMethods.notEmpty}}
            error={errors.circumstances}
        />             
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save accident data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)}
            />
        </FlexBox>
    </React.Fragment>
}

export { DamageAccidentReportForm }