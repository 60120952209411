import { connect } from 'react-redux'

import {BranchSelectComponent} from './component'

const mapStateToProps = state => ({
    branches: state.common.accessibleBranches
})

export const BranchSelect =  connect(
    mapStateToProps
)(BranchSelectComponent)