import styled from 'styled-components'

export const CellRight = styled.div`
    display: flex;   
    flex: 1;
    justify-content: flex-end;
    color: ${props => props.theme.color.anthracite};
    background-color: ${props => props.theme.color.white};
    padding-left: 8px;
    &>div.label-button-bar{
        display: flex;
        margin-right: 8px;
        &>div {
            margin-right: 8px;
        }
    }
    border: 1px solid ${props => props.theme.color.gray15};
    border-left: 0;
    position: relative;
    max-width: 320px;
`