import { connect } from 'react-redux'
import MassOperationsSwitchWithLabel from './component'
import {
    moSetActiveByName,
    moSetInactiveByName,
    moClearAllItemsByName 

} from 'application/redux/actions/mass_operations'

const mapStateToProps = state => state


const mapDispatchToProps = dispatch => {
    return {
        onResetSelectedTasks: (name) => {
            dispatch(
                moClearAllItemsByName (name)
            )
        },
        onSetActive: (name) => {
            dispatch(
                moSetActiveByName(name)
            )
        },
        onSetInActive:(name) => {
            dispatch(
                moSetInactiveByName(name)
            )
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MassOperationsSwitchWithLabel)