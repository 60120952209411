import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { WIDGET_CONTEXTS } from 'application/constants'
import ActionButtonBar from 'application/components/controls/action_button_bar'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label'
import WidgetBoard from 'application/components/building_blocks/widget_board'

const DashboardContent = ({
    onSetStaticMode,
    onAddWidget,
    isStatic,
}) => {
    const {t} = useTranslation()
    return <React.Fragment>
        <ActionButtonBar inline={true} buttonsAlign='flex-end'>
            {
                !isStatic && <IconButtonWithLabel 
                    onButtonClick={()=>{onAddWidget(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD)}}
                    iconKey='add'
                    label={t('add repair authorization widget')}
                />
            }
            {
                !isStatic && <IconButtonWithLabel 
                    onButtonClick={()=> onSetStaticMode(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD, !isStatic)} 
                    iconKey={isStatic ? 'edit' : 'check'} 
                    label={isStatic ? t('Edit widgets') : t('Done')}
                />
            }
        </ActionButtonBar>

        <WidgetBoard widgetContext={WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD} />
    </React.Fragment>
}

DashboardContent.propTypes = {
    onSetStaticMode: PropTypes.func.isRequired,
    onAddWidget: PropTypes.func.isRequired,
    isStatic: PropTypes.bool.isRequired,
}

export default DashboardContent