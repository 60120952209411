import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.pages.damageManager.tasks, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.damageManager.tasks
    }
    case ACTIONS.DAMAGE_MANAGER_TASKS_REQUEST_TASKS: {
        return {
            ...state,
            tasksList: [],
            tasksLoaded: false,
            tasksLoading: true,
            tasksListDamageId: null,
            selectedTask: null,
            selectedTaskLoading: false,
            selectedTaskLoaded: false
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_TASKS_UPDATE_TASK_IN_LIST: {
        return {
            ...state,
            tasksList: state.tasksList.map(task => {
                return task.id === action.taskId ? {
                    ...task,
                    ...action.payload
                } : task
            }) 
        }
    }
    case ACTIONS.DAMAGE_MANAGER_TASKS_REMOVE_TASK_FROM_LIST: {
        return {
            ...state,
            tasksList: state.tasksList.filter(task => task.id !== action.taskId) 
        }
    }
    case ACTIONS.DAMAGE_MANAGER_TASKS_INCREMENT_COMMENTS_TOTAL: {
        return {
            ...state,
            tasksList: state.tasksList.map(
                task => {
                    return task.id === action.taskId ? {
                        ...task,
                        comments: {
                            total: task.comments.total + 1 
                        }
                    } : task
                }
            ) 
        }
    }
    case ACTIONS.DAMAGE_MANAGER_TASKS_POPULATE_TASKS: {
        return {
            ...state,
            tasksList: action.tasksList,
            tasksLoaded: true,
            tasksLoading: false,
            tasksListDamageId: action.damageId
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_TASKS_REQUEST_SELECTED_TASK: {
        return {
            ...state,
            selectedTask: null,
            selectedTaskDocuments: null,
            selectedTaskLoaded: false,
            selectedTaskLoading: true
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_TASKS_POPULATE_SELECTED_TASK: {
        return {
            ...state,
            selectedTask: {...action.task, taskWasDeleted: false},
            selectedTaskLoaded: true,
            selectedTaskLoading: false,
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_TASKS_POPULATE_SELECTED_TASK_DOCUMENTS: {
        return {
            ...state,
            selectedTaskDocuments: {
                documents: action.documents,
                total: action.total
            }
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_TASKS_UPDATE_SELECTED_TASK: {
        return {
            ...state,
            selectedTask: {
                ...state.selectedTask,
                ...action.task
            }
        }
    }
    case ACTIONS.DAMAGE_MANAGER_TASKS_UPDATE_SELECTED_TASK_DOCUMENT_CATEGORY: {
        return {
            ...state,
            selectedTaskDocuments: {
                ...state.selectedTaskDocuments,
                documents: state.selectedTaskDocuments.documents.map(d => d.id === action.attachmentId ? {
                    ...d,
                    category: action.category
                }: d)
            }
        }
    }
    case ACTIONS.DAMAGE_MANAGER_TASKS_LOAD_USERLIST_DEBOUNCED: {
        return {
            ...state,
            selectedTaskUserList: defaultStore.pages.damageManager.tasks.selectedTaskUserList,
            selectedTaskUserListLoading: true
        }
    } 
    case ACTIONS.DAMAGE_MANAGER_TASKS_POPULATE_USERLIST: {
        return {
            ...state,
            selectedTaskUserList: action.userList,
            selectedTaskUserListLoading: false
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_TASKS_RESET_SELECTED_TASK: {
        return {
            ...state,
            selectedTask: null,
            selectedTaskLoaded: false,
            selectedTaskLoading: false
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_SHOW_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                visible: true,
                attachment: action.attachment,
                damageId: action.damageId, 
            }
        }
    } 
    case ACTIONS.DAMAGE_MANAGER_ATTACHMENT_DETAILS_SET_CATEGORY: {
        return {
            ...state,
            attachmentDetails: {
                ...state.attachmentDetails,
                category: {
                    loaded: true,
                    id: action.categoryId
                }
            }
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_SHOW_ATTACHMENT_DETAILS: //hide details in task view if details in document center is shown
    case ACTIONS.DAMAGE_MANAGER_HIDE_ATTACHMENT_DETAILS:{
        return {
            ...state,
            attachmentDetails: {
                visible: false,
                attachmentId: null,
                attachmentName: '',
                damageId: null,
                taskId: null
            }
        }
    }  
    default:
        return state
    }
}