import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { TASK_STATES } from 'application/constants'
import IndicatorWithColor from 'application/components/controls/indicator/override_color_indicator'
import Deadlinebox from 'application/components/controls/deadline'

import { StyledTaskHighlightBar, SelectStyling, StyledAssignMeButton } from './fragments'
import { Select } from 'application/components/controls/form'
import StateName from 'application/components/name_mappings/state_name'
import { LinkButton } from 'application/components/fragments/typography'
import { nameHelpers } from 'application/common'
import { exists } from 'application/common/data_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'

import { TaskPriorityDisplay } from 'application/components/controls/task_priority_display'

const TaskHighlightBarComponent = ({
    state,
    priority,
    deadline,
    colorIndicator = 'gray70',
    canAssign = true,
    onAssignUser,
    onSearchUser,
    userList = [],
    userListLoading,
    assignee,
    currentUser
}) => {
    const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls

    const {t} = useTranslation()
    //ToDo: set current user as useForm() initial value
    const {
        register, 
        errors, 
        setValue
    } = useForm()

    const [defaultUserOption, setDefaultUserOption] = useState(
        exists(assignee) 
            ? {
                value: assignee.userId,
                label: nameHelpers.getFullName(assignee),
                firstName: assignee.firstName,
                lastName: assignee.lastName
            } 
            : null
    )

    const usersOptions = userList.length > 0
        ? 
        userList.map((u) => ({
            value: u.id,
            label: nameHelpers.getFullName(u),
            firstName: u.firstName,
            lastName: u.lastName
        })) 
        : 
        []

    const handleSearchUser = search => {
        if(userSearchActive && search.length > 2){
            onSearchUser(search)
        }  
    }

    const handleAssign = user => {
        onAssignUser(
            user?.value || null, 
            user?.firstName || null, 
            user?.lastName || null
        )
    }

    const showAssignMeButton = canAssign && (assignee === undefined || assignee.userId !== currentUser.id)
   
    const handleAssignMe = () => {
        onAssignUser(
            currentUser.id, 
            currentUser.firstName, 
            currentUser.lastName
        )
        setDefaultUserOption({
            value: currentUser.id,
            label: nameHelpers.getFullName(currentUser),
            firstName: currentUser.firstName,
            lastName: currentUser.lastName
        })
    }

    useEffect(
        () => {   
      
            setDefaultUserOption(
                !exists(assignee)
                    ? null
                    : {
                        value: assignee.userId,
                        label: nameHelpers.getFullName(assignee),
                        firstName: assignee.firstName,
                        lastName: assignee.lastName
                    }
            )
        }, [assignee])

    return <StyledTaskHighlightBar>
        <FlexBox 
            alignItems='center'
            width='auto' 
            paddingLeft='8px'
            paddingRight='16px'
        >
            <IndicatorWithColor 
                text={
                    <StateName state={state}
                    />} 
                iconKey={'move_to_inbox'} 
                overrideColorWith={colorIndicator} 
            />
        </FlexBox>
        <FlexBox
            width='auto'
            flexBasis='200px'
            flexGrow='3'
            paddingLeft='16px'
            paddingRight='16px'
        >
            {
                state === TASK_STATES.DONE
                    ? 
                    <IndicatorWithColor 
                        iconKey='person' 
                        text={defaultUserOption.label} 
                        overrideColorWith={colorIndicator} 
                    />
                    : canAssign 
                        ? 
                        <FlexBox flexDirection='column'>
                            <SelectStyling>
                                <Select 
                                    name='assignedTo'
                                    register={register}
                                    setValue={setValue}
                                    options={usersOptions}
                                    error={errors.assignedTo}
                                    validate={null}
                                    onInputChange={handleSearchUser}
                                    onBlur={()=>{setUserSearchActive(false)}}
                                    onFocus={()=>{setUserSearchActive(true)}}
                                    onChange={handleAssign}
                                    noOptionsMessage={t('No users found')}
                                    placeholder={t('Search employee')}
                                    isLoading={userListLoading}
                                    hideValidationBox={true}
                                    defaultValue={defaultUserOption}
                                />
                            </SelectStyling>
                            {
                                showAssignMeButton && 
                                <StyledAssignMeButton>
                                    <LinkButton 
                                        isActive={true} 
                                        onClick={handleAssignMe}
                                    >
                                        {t('assign to me')}
                                    </LinkButton>
                                </StyledAssignMeButton>
                            }
                        </FlexBox> 
                        : 
                        state !== TASK_STATES.TO_DO 
                        && <IndicatorWithColor 
                            iconKey='person' 
                            text={defaultUserOption.label} 
                            overrideColorWith={colorIndicator} 
                        />
            }
        </FlexBox>


        <FlexBox
            width='auto' 
            paddingLeft='16px'
            paddingRight='16px'
        >
            {
                deadline !== null && 
                    <Deadlinebox deadlineFromTask={deadline} />
            }
            <TaskPriorityDisplay priority={priority} />
        </FlexBox>

    </StyledTaskHighlightBar>
}


export {TaskHighlightBarComponent}