import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FontIcon, ActionButton } from '@ec/ui-controls-react'
import { useForm } from 'react-hook-form'

import { PushBottom16 } from 'application/components/pages/_common'
import { validationMethods } from 'application/common'
import { WidgetSubHeadline, WidgetErrorMessage } from 'application/components/widgets/_common'
import { TextArea } from 'application/components/controls/form'
import Comment from 'application/components/controls/comment'
import { withRouter } from 'react-router-dom'

const LawfirmTaskCommentsComponent = (
    {
        //widgetKey,
        loadingFailed,
        onSaveComment,
        taskLoaded,
        match,
        payload = null,
        lawfirmLoaded = false,
        lawfirm = null
    }
) => {
    const {t} = useTranslation()

    const {
        sharedResourceId, 
        taskId
    } = match.params

    const [comments, setComments] = useState(
        payload.commentsResponse?.comments || []
    )

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        setValue
    } = useForm()

    const onSubmitSuccess = () => { 
        onSaveComment(
            sharedResourceId, 
            taskId, 
            getValues().content
        )
        setComments(
            [
                {
                    content: getValues().content,
                    createdAt: new Date(Date.now()),
                    creator: {
                        name: lawfirm?.name || ''
                    }
                }, 
                ...comments
            ]
        )
        setValue('content', '')
    }

    if(!taskLoaded){
        return <React.Fragment />
    }

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    return <React.Fragment>
        <WidgetSubHeadline>
            <FontIcon 
                icon='chat_bubble_outline' 
                fontSize='16px'
            /> 
             &nbsp; {t('Do you have a comment?')}
        </WidgetSubHeadline>
        <PushBottom16 />
        <TextArea 
            label=''
            placeholder={t('Write an information for this task')}
            name='content'
            register={register}
            validate={{notEmpty: validationMethods.notEmpty}}
            error={errors.content}
            hideValidationBox={true}
        />
        {
            lawfirmLoaded && <ActionButton 
                buttonText={t('Save comment')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        }

        <PushBottom16 />

        {
            comments.sort(
                function compare(a, b) {
                    var dateA = new Date(a.createdAt)
                    var dateB = new Date(b.createdAt)
                    return dateB - dateA
                }
            ).map(
                (c, i) => {
                    return <Comment 
                        key={i}
                        creatorName={c.creator.name}
                        creationDate={c.createdAt}
                        text={c.content}

                    />
                }
            )
        }
    </React.Fragment>
}

LawfirmTaskCommentsComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    loadingFailed: PropTypes.bool.isRequired,
    onSaveComment: PropTypes.func.isRequired,
    taskLoaded: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    lawfirmLoaded: PropTypes.bool,
    payload: PropTypes.object,
    lawfirm: PropTypes.object,
}

const LawfirmTaskComments = withRouter(LawfirmTaskCommentsComponent)

export { LawfirmTaskComments }