import React, { useState, useRef } from 'react'
import TruncateString from 'react-truncate-string'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { ActionButton, FontIcon } from '@ec/ui-controls-react'
import { 
    useEscapeKey, 
    //useClickOutside 
} from 'application/components/hooks'
import IndicatorWithColor from 'application/components/controls/indicator/override_color_indicator'
import * as Fragments from './fragments'
import { Paragraph } from 'application/components/fragments/typography'
import { FlexBox }  from 'application/components/fragments/flex_box'

const SecondaryActionButton = styled(ActionButton)`
    border: 1px solid ${props => props.theme.color.gray70};

    &:hover {
        color: ${props => props.theme.color.smokewhite};
        background-color: ${props => props.theme.color.gray70}
    }
`

const PrimaryActionButton = styled(ActionButton)`
    color: ${props => props.theme.color.smokewhite};
    &:hover {
        color: ${props => props.theme.color.gray70};
        background-color: ${props => props.theme.color.smokewhite};
        border: 1px solid ${props => props.theme.color.gray70};
    }
`

const Dialog = ({
    dialog,
    title = '',
    dialogIndex = 0, //needed?
    isHighest,
    onAbort,
    abortConfirmationMessage = '',
    dialogSize,
    abortConfirmCondition = false,
    children,
    AdditionalHeader,
    DialogFooter,
    DialogSubHeader=null,
    galleryVisible,
    dialogName,
    theme,
    iconKey=null,
    color=null,
    isHeaderWithBackgroundColor=false,
    waitingScreen,
    dataLoaded //new property, can be used to show/hide a preloader
}) => {
    const widgetContexts = dialog.payload.widgetContexts !== undefined ? dialog.payload.widgetContexts : []
    const { t } = useTranslation()
    const [dataChanged, setDataChanged] = useState(false)
    const [abortBlocked, setAbortBlocked] = useState(false)
    const [abortConfirm, setAbortConfirm] = useState(abortConfirmCondition)
    const abort = () => (dataChanged ? setAbortConfirm(true) : onAbort(dialogName, widgetContexts))
    const ref = useRef()
    // useClickOutside(ref, abort, !abortBlocked && !galleryVisible && isHighest && !waitingScreen.active)
    useEscapeKey(abort, !abortBlocked && !galleryVisible && isHighest && !waitingScreen.active)
    const blurred = abortConfirm || (waitingScreen.active && waitingScreen.inDialog)
    return (
        <React.Fragment>
            <Fragments.Overlay dialogIndex={dialogIndex}>
                <Fragments.Dialog borderColor={color} dialogSize={dialogSize} ref={ref} dialogIndex={dialogIndex}>
                    <FlexBox flexDirection='column'>
                        <Fragments.DialogHeader 
                            withBackgroundColor={isHeaderWithBackgroundColor} 
                            blurred={blurred} 
                            dialogIndex={dialogIndex}
                        >
                            {
                                AdditionalHeader && <AdditionalHeader 
                                    setAbortBlocked={setAbortBlocked} 
                                    title={title} 
                                    dialog={dialog}
                                />
                            }
                            {
                                AdditionalHeader === undefined && <React.Fragment>
                                    {
                                        iconKey !== null && <FontIcon 
                                            icon={iconKey} 
                                            color={color} 
                                            fontSize={theme.fontSize.headline1}
                                        />
                                    }
                                    {
                                        i18n.exists(title)
                                            ? <TruncateString text={t(title)} truncateAt={100}/>
                                            : <TruncateString text={title} truncateAt={100}/>
                                    }
                                </React.Fragment>
                            }
                            <IndicatorWithColor 
                                onButtonClick={abort} 
                                iconKey='clear' 
                                iconSize='20px' 
                                overrideColorWith='anthracite' 
                            
                            />
                        </Fragments.DialogHeader>
                        {
                            DialogSubHeader !== null && <Fragments.DialogSubHeader blurred={blurred}>
                                <DialogSubHeader />
                            </Fragments.DialogSubHeader>
                            
                        }
                    </FlexBox>
                    
                    <Fragments.DialogContent blurred={blurred}>
                        {
                            React.cloneElement( 
                                children, 
                                {
                                    setDataChanged, 
                                    abort, 
                                    setAbortBlocked, 
                                    dialog
                                }
                            )
                        }
                    </Fragments.DialogContent>
                    {
                        //in use????
                        DialogFooter && <DialogFooter onCancel={abort} />
                    }
                    {abortConfirm && (
                        <Fragments.DialogAbortConfirm dialogIndex={dialogIndex}>
                            <Fragments.DialogAbortConfirmHeader>
                                {
                                    abortConfirmationMessage !== '' ? abortConfirmationMessage : t('Discard changes?')}
                            </Fragments.DialogAbortConfirmHeader>
                            <Fragments.DialogAbortConfirmButtonWrapper>
                                <PrimaryActionButton 
                                    className='jest-primary-button' 
                                    onButtonClick={() => onAbort(dialogName, widgetContexts)} 
                                    buttonText={t('Yes')}
                                />
                                <SecondaryActionButton 
                                    className='jest-secondary-button' 
                                    onButtonClick={
                                        () => {
                                            setAbortConfirm(false)
                                        }
                                    } 
                                    secondary={true} 
                                    buttonText={t('No')} 
                                />
                            </Fragments.DialogAbortConfirmButtonWrapper>
                            
                        </Fragments.DialogAbortConfirm>
                    )}
                    {
                        waitingScreen.active && waitingScreen.inDialog && <Fragments.DialogWaitingScreen 
                            dialogIndex={dialogIndex}
                        >
                            <Fragments.DialogAbortConfirmHeader>
                                {t(waitingScreen.message)}
                            </Fragments.DialogAbortConfirmHeader>
                            {
                                waitingScreen.subMessages.map((m, i)=>{
                                    return <Paragraph 
                                        key={i}
                                        cssAlign='center'
                                    >
                                        {m}
                                    </Paragraph>
                                })
                            }
                        </Fragments.DialogWaitingScreen>
                    }
                </Fragments.Dialog>
            </Fragments.Overlay>
        </React.Fragment>
    )
}

export default withTheme(Dialog)

Dialog.propTypes = {
    title: PropTypes.string,
    dataChanged: PropTypes.bool,
    abortConfirmationMessage: PropTypes.string,
    dialogSize: PropTypes.string,
    abortConfirmCondition: PropTypes.bool,
    AdditionalHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func, PropTypes.object]),
    isHighest: PropTypes.bool.isRequired
}
