import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ToggleSwitch from 'application/components/controls/toggle-switch'

export const StyledSwitchWrapper = styled.div`
    height: 35px;

    &>div {
        margin-bottom: 0;
        & label {
               & span {
                padding-left: 0px;
            }
        }
    }
`

const MassOperationsSwitchWithLabel = (
    {
        moName,
        onSetActive,
        onSetInActive,
        onResetSelectedTasks,
        isActive = false,
        label='',
        moOpenAdditionalAction=()=>{}
    }
) => {

    const handleMoOpen = (moName) => {
        moOpenAdditionalAction()
        onSetActive(moName) 
    }

    const handleChange = (isActive) => {
        onResetSelectedTasks(moName)
        isActive 
            ? handleMoOpen(moName) 
            : onSetInActive(moName)
    }

    return <StyledSwitchWrapper>
        <ToggleSwitch
            label={label}
            initiallyActive={isActive}
            onChange={handleChange}
        />
    </StyledSwitchWrapper>
}

MassOperationsSwitchWithLabel.propTypes = {
    moName: PropTypes.string.isRequired,
    onSetActive: PropTypes.func.isRequired,
    onSetInActive: PropTypes.func.isRequired,
    onResetSelectedTasks: PropTypes.func.isRequired,
    isActive: PropTypes.bool, 
    label: PropTypes.string,
    moOpenAdditionalAction: PropTypes.func
}

export default MassOperationsSwitchWithLabel