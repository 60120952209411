import { api } from 'application/api'
import ACTIONS, { WIDGET_TYPES } from 'application/constants'
import { populateMessageDetails, messageNotFound } from 'application/redux/actions/pages/inbox'
import { updateWidgetLoadedState } from 'application/redux/actions/widgets'
import { handleError } from 'application/redux/saga/errors'
import { 
    // call, 
    put, 
    takeEvery 
} from 'redux-saga/effects'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'






function* getMessageDetailsRequestSaga(action) {
    try {

        const response = yield resourceCall(
            api.gateway.messages.getDamageManagerMessageDetailsById, 
            {
                damageId: action.damageId, 
                messageId: action.messageId
            }
        )
        yield put(
            populateMessageDetails(response)
        )
    } catch (e) {
        if (e.response.status === 404){
            yield put(
                messageNotFound()
            )
            yield put(
                updateWidgetLoadedState(
                    WIDGET_TYPES.INBOX_MESSAGE_LIST, 
                    false
                )
            )
        }
        handleError(e, action)
    }
}

export function* messageDetailsWatcher() {
    yield takeEvery(
        ACTIONS.INBOX_GET_MESSAGE_BY_ID, 
        getMessageDetailsRequestSaga
    )
}