import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const getRepairAuthorization = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder(`damages/${params.damageId}/repairAuthorization`, HTTP_METHOD.GET)
            return apiCall(request)
        },
        sharedResource: () => null
    }
)