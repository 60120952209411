import React from 'react'
import { exists } from 'application/common/data_helpers'
import i18next from 'i18next'

export const getGroupTextInfo = (group) => {
    const permissions = group.roles.length === 0
        ? i18next.t('no permissions connected')
        : group.roles.map((role, i) => i18next.t(`role${role.name}`)).join(', ')

    const branches = group.canAccessAllBranches
        ? i18next.t('all branches')
        : group.branches.length === 0
            ? i18next.t('no branches connected')
            : group.branches.map(b => b.name).join(', ')

    return `${i18next.t('Permissions')}: ${permissions} / ${i18next.t('Branches')}: ${branches}`
}

const GroupTextInfo = ({
    group
}) => {
    if(!exists(group)){
        return null
    }

    return <React.Fragment>
        {getGroupTextInfo(group)}
    </React.Fragment>
}

export default GroupTextInfo