import { PushBottom8 } from 'application/components/pages/_common'
import React from 'react'
import styled, { withTheme } from 'styled-components'
import LocalTime from 'application/components/controls/local_time'
import DateComponent from 'application/components/controls/date'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ACTIVITY_TYPE } from 'application/constants'
import GravatarWithInitial from 'application/components/controls/gravatar'
import { useTranslation } from 'react-i18next'
import { Paragraph } from 'application/components/fragments/typography'
import {getActivityInfoByType} from './content'




const Card = styled.div`
    transition: 0.3s;
    width: 100%;
    border-radius: 0px;
    min-width: 550px;
    background: ${props => props.theme.color.gray10};
    padding: 8px 0;

    @media only screen and (max-width: ${props => props.theme.breakpoint.small}px){
        min-width: unset;
    }
`

const CardContainer = styled.div`
    padding: 2px 16px;
`

const HighlightSubline = styled.p`
    margin: 0;
    font-style: ${props => props.theme.fontStyle.italic};
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite50};
`

const TextName = styled.span`
    font-size: ${props => props.theme.fontSize.medium18};
    font-weight: ${props => props.theme.fontWeight.headLine2};
`


//DEV360-666 IconkeyComponent?

const mapIconKey = (activityType) => {
    switch (activityType) {     
    case ACTIVITY_TYPE.PAYMENT_CREATED:
        return 'monetization_on'
    case ACTIVITY_TYPE.PAYMENT_CANCELLED:
        return 'money_off' 
    case ACTIVITY_TYPE.DAMAGE_ASSIGNEE_CHANGED:
    case ACTIVITY_TYPE.DAMAGE_CREATED:
    case ACTIVITY_TYPE.DAMAGE_JOB_NUMBER_CHANGED:
    case ACTIVITY_TYPE.DAMAGE_STATE_CHANGED:
    case ACTIVITY_TYPE.DAMAGE_TYPE_CHANGED:
    case ACTIVITY_TYPE.DAMAGE_NOTE_CHANGED:
    case ACTIVITY_TYPE.DAMAGE_DESCRIPTION_CHANGED:
    case ACTIVITY_TYPE.VEHICLE_KEEPER_CHANGED:
        return 'directions_car'
    case ACTIVITY_TYPE.INVOICE_CREATED:
        return 'attachment'
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_INITIATED:
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_ABORTED:
        return 'gavel' 
    case ACTIVITY_TYPE.DOCUMENT_CREATED:
    case ACTIVITY_TYPE.DOCUMENT_DELETED:
        return 'article'
    case ACTIVITY_TYPE.MESSAGE_CREATED:
    case ACTIVITY_TYPE.MESSAGE_DELETED:
        return 'mail_outline'
    default:
        return 'notifications'
    }
}


const mapTitle = (activityType) => {
    switch (activityType) {     
    case ACTIVITY_TYPE.PAYMENT_CREATED:
        return 'Payment created'
    case ACTIVITY_TYPE.PAYMENT_CANCELLED:
        return 'Payment cancelled' 
    case ACTIVITY_TYPE.DAMAGE_ASSIGNEE_CHANGED:
        return 'Assignee changed' 
    case ACTIVITY_TYPE.DAMAGE_CREATED:
        return 'Damage created' 
    case ACTIVITY_TYPE.DAMAGE_JOB_NUMBER_CHANGED:
        return 'Job number changed' 
    case ACTIVITY_TYPE.DAMAGE_STATE_CHANGED:
        return 'Damage state changed' 
    case ACTIVITY_TYPE.DAMAGE_TYPE_CHANGED:
        return 'Damage type changed'
    case ACTIVITY_TYPE.DAMAGE_DESCRIPTION_CHANGED:
        return 'Damagedescription changed'
    case ACTIVITY_TYPE.DAMAGE_NOTE_CHANGED:
        return 'Damagenote changed'
    case ACTIVITY_TYPE.INVOICE_CREATED:
        return 'Invoice created'
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_INITIATED:
        return 'Lawfirm communication initiated'
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_ABORTED:
        return 'Lawfirm communication aborted'
    case  ACTIVITY_TYPE.TASK_CREATED: 
        return 'Task created'
    case  ACTIVITY_TYPE.TASK_DELETED: 
        return 'Task deleted'
    case  ACTIVITY_TYPE.TASK_STATE_CHANGED: 
        return 'Taskstate changed'
    case  ACTIVITY_TYPE.TASK_COMMENT_CREATED:
        return 'Comment created' 
    case ACTIVITY_TYPE.VEHICLE_UPDATED:
        return 'Vehicle updated'
    case ACTIVITY_TYPE.DAMAGE_INCIDENT_DATE_CHANGED:
        return 'Damageincidentdate changed'
    case ACTIVITY_TYPE.DOCUMENT_CREATED:
        return 'Document created'
    case ACTIVITY_TYPE.DOCUMENT_DELETED:
        return 'Document deleted'
    case ACTIVITY_TYPE.VEHICLE_KEEPER_CHANGED:
        return 'Vehiclekeeper changed'
    case ACTIVITY_TYPE.MESSAGE_CREATED:
        return 'Message created'
    case ACTIVITY_TYPE.MESSAGE_DELETED:
        return 'Message deleted'
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_OTHER_INITIATED:
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_INITIATED:
        return 'initiated expert communication'
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_ESTABLISHED:
        return 'confirmed expert communication'
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_FAILED:
        return 'blocked expert communication'
    default:
        return 'Other'
    }
}






const ActivityCardComponent = (
    {
        activity,
        theme
    }
) => {

    const {t} = useTranslation()

    const iconKey = mapIconKey(activity.type),
        title = mapTitle(activity.type),
        createdAt  = activity.occurredAt, 
        fullName = activity.actor?.name || 'System'

    const ActivityInfo = getActivityInfoByType(activity.type)



    return <Card>
        <CardContainer>
            <FlexBox 
                flexBasis='56px'
                alignItems='center'
                cssRules='padding: 8px;'
            >
                <GravatarWithInitial
                    backgroundColor={theme.color.gray70}
                    large='40'
                    icon={iconKey}
                />
                <FlexBox 
                    flexBasis='auto' 
                    alignItems='center'
                    paddingLeft='8px'
                    flexDirection='column'
                >
                    <FlexBox 
                        justifyContent='space-between' 
                    >
                        <TextName>
                            <strong>{fullName}</strong> &nbsp;
                            {
                                title !== 'Other' &&  t('has')
                            }
                            &nbsp; {t(title)} 
                        </TextName>
                        <HighlightSubline>
                            <DateComponent date={createdAt}/>, <LocalTime date={createdAt}/>
                        </HighlightSubline>
                    </FlexBox>
                    <PushBottom8 />
                    <Paragraph cssMargin='0'>
                        <ActivityInfo activityData={activity.data}/>
                    </Paragraph>
                </FlexBox>
            </FlexBox>
        </CardContainer>
    </Card>
}


export const ActivityCard =  withTheme(ActivityCardComponent)