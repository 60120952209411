import React, { useState } from 'react'
import * as Fragments from './fragments'
import { useTranslation } from 'react-i18next'
import { DragAndDropUploader, SimpleUploader } from 'application/components/controls/form/uploaders'
import ThumbnailComponent, { ThumbnailContainer } from 'application/components/building_blocks/attachments/thumbnail'




const TaskUploader = ({
    onFileAdded = () => { },
    onFilesRejected,
    disabled = false,
    onAttachmentClick = () => { },
    selectedThumbnail = null,
    initialAttachments = [], //From Server
    description = 'drag your files here to upload them',
    title = 'Documents',
    label = 'Upload documents',
    labelMore = 'Upload more documents',
    disableActiveState = false,

    files = [],  //uploadQueue
    setFiles,
    onDataChange = () => {}
}) => {
    const { t } = useTranslation()
    
    const handleSetFiles = (filesToPersist) => {
        setFiles(filesToPersist)
        onDataChange()
    }

    const handleAddFiles = (newFiles) => {
        const allFiles = [
            ...files,
            ...newFiles
        ]        
        handleSetFiles(allFiles)
    }
  
    const handleAdded = file => {
        onFileAdded(file)
    }

    const handleRemoveFile = (fileName) => {
        const remainingFiles = files.filter(
            file =>  file.attachment.fileName !== fileName
        )
        handleSetFiles(remainingFiles)
    }

    const [selected, setSelected] = useState([])
    return <Fragments.Wrapper>
        <Fragments.TitleBar>
            <Fragments.Title>{t(title)}</Fragments.Title>
            {
                (files.length > 0 || initialAttachments.length > 0) && <SimpleUploader
                    disabled={disabled}
                    label={t(labelMore)}
                    iconKey='add'
                    onFileAdded={handleAdded}
                    onFilesRejected={onFilesRejected}
                    onFilesAdded={handleAddFiles}
                />
            }
        </Fragments.TitleBar>
        <DragAndDropUploader
            disabled={disabled}
            onFileAdded={(file) => { handleAdded(file) }}
            onFilesAdded={handleAddFiles}
            onFilesRejected={onFilesRejected}
        >
            {
                files.length === 0 && initialAttachments.length === 0
                    ? <SimpleUploader
                        disabled={disabled}
                        label={t(label)}
                        iconKey='add'
                        onFileAdded={handleAdded}
                        onFilesRejected={onFilesRejected}
                        onFilesAdded={handleAddFiles}
                    />
                    : <React.Fragment>
                        {
                            initialAttachments.map((attachment, fileIndex) => {
                                return <ThumbnailContainer
                                    key={fileIndex}
                                    attachmentId={attachment.id}
                                    attachmentName={attachment.name}
                                    damageId={attachment.damageId}
                                    taskId={attachment.resourceId}
                                    onAttachmentClick={file => {
                                        setSelected(file)
                                        onAttachmentClick(file)
                                    }}
                                    extendedData={
                                        {
                                            category: attachment.category,
                                            isExportedToLawFirm: attachment.isExportedToLawFirm
                                        }
                                    }
                                    removeFile={handleRemoveFile}
                                    removableFiles={false}
                                />
                            })
                        }
                        {
                            files.map((file, fileIndex) => {
                                return <ThumbnailComponent
                                    attachment={file.attachment}
                                    onAttachmentClick={file => {
                                        setSelected(file)
                                        onAttachmentClick(file)
                                    }}
                                    isActive={!disableActiveState 
                                        ? selectedThumbnail === null 
                                            ? selected.id === file.id 
                                            : selectedThumbnail.id === file.id 
                                        : false}
                                    key={fileIndex}
                                    removeFile={handleRemoveFile}
                                    removableFiles={true}
                                />
                            })
                        }
                    </React.Fragment>
            }
        </DragAndDropUploader>
        <Fragments.Description>{t(description)}</Fragments.Description>

    </Fragments.Wrapper>
}

export default TaskUploader