import { DIALOG } from 'application/constants'
import { dialogDefaultLoadingSaga } from './dialog_default_saga' 
import { dialogCreateDamageSaga } from './dialog_create_damage_saga' 
import { dialogDeleteMessageSaga } from './dialog_delete_message_saga'
import { dialogDeleteDamageSaga } from './dialog_delete_damage_saga'
import { dialogCreateUserSaga } from './create_user/create_user_saga'
import { dialogDeleteUserSaga } from './dialog_delete_user_saga'
import { dialogEditUserSaga } from './dialog_edit_user_saga'
import { dialogUploadFilesSaga } from './dialog_upload_files_saga'
import { dialogCreateTaskSaga } from './dialog_create_task_saga'
import { dialogCreateLawyersSaga } from './dialog_create_lawyers'
import { dialogCommissionDekraExpertSaga } from './dialog_commission_dekra_expert'
import { dialogCommissionOtherExpertSaga } from './dialog_commission_other_expert'
import { dialogDeleteTaskDamageSaga } from './dialog_delete_task_saga'
import { dialogCreateCaseNotesSaga } from './dialog_create_case_notes'
import { dialogDeleteAttachmentSaga } from './dialog_delete_attachment_saga'
import { dialogMoDocumentsDeleteSaga } from './mo/documents/dialog_mo_documents_delete_saga'
import { dialogCreateTaskWorkflowPanelSaga } from './dialog_create_task_saga_workflow_panel'
import { dialogCreateInvoiceLawyerCommunicationSaga } from './dialog_create_invoice_lawyer_communication'
import { dialogCancelPaymentLawyerCommunicationSaga } from './dialog_cancel_payment_lawyer_communication'
import { dialogCreatePaymentSaga } from './dialog_create_payment'
import { dialogDeleteInvoiceSaga } from './dialog_delete_invoice'
import { dialogDeleteInvoiceLawyerCommunicationSaga } from './dialog_delete_invoice_lawyer_communication'
import { dialogEditTaskSaga } from './dialog_edit_task_saga'
import { dialogDeleteTaskWorkflowPanelSaga } from './dialog_delete_task_workflow_panel_saga'
import { dialogEditTaskWorkflowPanelSaga } from './dialog_edit_task_workflow_panel_saga'
import { dialogDeleteAttachmentWorkflowPanelSaga } from './dialog_delete_attachment_workflow_panel_saga'
import { dialogUploadFilesWorkflowPanelSaga } from './dialog_upload_files_workflow_panel_saga'
import { dialogCreateMessageWorkflowPanelSaga } from './dialog_create_message_workflow_panel'
import { dialogEditCustomerSaga } from './dialog_edit_customer_saga'
import { dialogDeleteCustomerSaga } from './dialog_delete_customer_saga'
import { dialogGetDamageInvoicesSaga } from './dialog_get_damage_invoices_saga'
import { dialogMoDocumentsChangeCategorySaga } from './mo/documents/dialog_mo_documents_change_category_saga'
import { dialogMoDocumentsIsExportedToLawfirmSaga } from './mo/documents/dialog_mo_documents_is_exported_to_lawfirm_saga'
import { changeCurrentBranchFromDialogSaga } from './branches/change'
import { addCategorySaga } from './dialog_add_category_saga'
import { deleteCategorySaga } from './dialog_delete_category_saga'
import { editCategorySaga } from './dialog_edit_category_saga'
import { addCustomStateSaga } from './dialog_add_custom_state_saga'
import { editCustomStateSaga } from './dialog_edit_custom_state_saga'
import { deleteCustomStateSaga } from './dialog_delete_custom_state_saga'
import { editBranchSaga } from './branches/edit'
import { createBranchSaga } from './branches/create'
import { dialogMoDamagesDeleteSaga } from './mo/damages/damages_delete_saga'
import { dialogMoDamagesChangeStateSaga } from './mo/damages/damages_change_status_saga'
import { dialogUploadFilesForTaskSaga } from './dialog_upload_files_for_task_saga'
import { damageSwitchCustomerSaga } from './dialog_damage_switch_customer_sage'
import { assignPreferredBranchSaga } from './assign_preferred_branch'
import { deleteBranchFromDialogSaga } from './branches/delete'
import { addUsersToGroupSaga } from './groups/dialog_add_users_to_group_saga'
import { createGroupSaga } from './groups/dialog_create_group'
import { editGroupSaga } from './groups/dialog_edit_group'
import { removeUserFromGroupSaga } from './groups/dialog_remove_user_from_group_saga'
import { deleteGroupSaga } from './groups/dialog_delete_group'
import { dialogReopenTaskSaga } from './dialog_reopen_task_saga'
import { dialogUpdateUserGroupsSaga } from './dialog_update_user_groups_saga'
import { dialogAdminUsersSaga } from './dialog_get_admin_users'
import { dialogMoTasksDeleteSaga } from './mo/tasks/delete'
import { dialogDeleteGroupGetMembersSaga } from './groups/dialog_delete_group_get_members_saga'
import { dialogFetchDamagesByCustomerSaga } from './dialog_fetch_damages_by_customer_saga'
import { dialogGetDamagesByBranchSaga } from './dialog_get_damages_by_branch_saga'
import { dialogGetDataForExpertCommissionSaga } from './dialog_get_data_for_expert_commission'
import { dialogEditCustomerFromDialogSaga } from './dialog_edit_customer_from_dialog_saga'
import { dialogWriteBackToLastDialogSaga } from './dialog_write_back_to_last_dialog_saga'
import {dialogExportToLawfirmFromDocumentSidebarSaga} from './export_to_lawfirm/dialog_export_to_lawfirm_from_document_sidebar'
import { dialogGetUserNotificationSettingsSaga } from './dialog_get_user_notification_settings_saga'
import { dialogEditUserNotificationSettingsSaga } from './dialog_edit_user_notification_settings_saga'
import { dialogReExportToLawfirmFromDocumentSidebarSaga } from './export_to_lawfirm/dialog_re_export_to_lawfirm_from_document_sidebar'
import { dialogCreateCustomerQRCodeSaga } from './qr_code/dialog_create_customer_qr_code'
import { dialogGetCustomerSaga } from './dialog_get_customer_saga'
import { dialogConfigureDashboardSaga } from './dialog_configure_dashboard_saga'
import { dialogCreateCustomerSaga } from './dialog_create_customer_saga'

//post processors
import * as postProcessors from 'application/redux/saga/sagas/post_processors'
import { dialogGetLawfirmSaga } from './dialog_get_lawfirm_saga'
import { updateComprehensiveDataSaga, updateLiabilityInsuranceAndClaimSaga } from '../pages/damage_manager/shared/damage'
import { dialogCreateDamageDekraSaga } from './dialog_create_damage_dekra_saga'
import { dialogCreateDamageQuickStartSaga } from './dialog_create_damage_quick_start_saga'
import { dialogCreateDamageQuickStartCommissionSaga } from './dialog_create_damage_quick_start_commission_saga'
import { dialogCreateMessageTrackingSaga } from './dialog_create_message_tracking'


export const getDialogDataSagas = (name) => {
    switch (name) {
    case DIALOG.NAME.DAMAGE_INVOICES:
        return [dialogGetDamageInvoicesSaga]
    case DIALOG.NAME.EDIT_USER_GROUPS_FROM_LIST:
    case DIALOG.NAME.DELETE_USER:
        return [dialogAdminUsersSaga]
    case DIALOG.NAME.CREATE_LAWYERS:
        return [dialogGetLawfirmSaga]
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_GROUP: 
        return [dialogDeleteGroupGetMembersSaga]
    case DIALOG.NAME.DELETE_CUSTOMER:
        return [dialogFetchDamagesByCustomerSaga]
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_BRANCH:
        return [dialogGetDamagesByBranchSaga]
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT: 
    case DIALOG.NAME.COMMISSION_OTHER_EXPERT: 
        return [dialogGetDataForExpertCommissionSaga]
    case DIALOG.NAME.USER_NOTIFICATION_SETTINGS:
        return [dialogGetUserNotificationSettingsSaga]
    case DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE:
        return [dialogGetCustomerSaga]
    default:
        return [dialogDefaultLoadingSaga]
    }
}




export const sendDialogDataSagas = (name) => {
    switch (name) {
    case DIALOG.NAME.CREATE_DAMAGE:
        return [
            dialogCreateDamageSaga, 
            postProcessors.refreshDamageTablesSaga,
            postProcessors.refreshLastDamagesListWidgets,
            postProcessors.refreshCustomersTablesSaga
        ]
    case DIALOG.NAME.CREATE_DAMAGE_QUICK_START:
        return [
            dialogCreateDamageQuickStartSaga, 
            postProcessors.refreshDamageTablesSaga,
            postProcessors.refreshLastDamagesListWidgets,
            postProcessors.refreshCustomersTablesSaga
        ]
    case DIALOG.NAME.CREATE_DAMAGE_QUICK_START_COMMISSION: 
        return [
            dialogCreateDamageQuickStartCommissionSaga,
            postProcessors.refreshDamageTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_DAMAGE_DEKRA:
        return [
            dialogCreateDamageDekraSaga, 
            postProcessors.refreshDamageTablesSaga,
            postProcessors.refreshLastDamagesListWidgets,
            postProcessors.refreshCustomersTablesSaga
        ]
    case DIALOG.NAME.CREATE_DAMAGE_AND_CLOSE_DIALOG:
        return [
            dialogCreateDamageSaga, 
            postProcessors.refreshDamageTablesSaga,
            postProcessors.refreshLastDamagesListWidgets,
            postProcessors.refreshCustomersTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_DAMAGE_UPDATE_INSURANCE_DATA: 
        return [
            updateComprehensiveDataSaga,
            updateLiabilityInsuranceAndClaimSaga,
            postProcessors.refreshCustomersTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_MESSAGE:
        return [
            dialogDeleteMessageSaga, 
            postProcessors.refreshMessageList,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_DAMAGE:
        return [
            dialogDeleteDamageSaga, 
            postProcessors.refreshDamageTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_USER:
        return [
            dialogCreateUserSaga,
            postProcessors.refreshUsersTablesSaga,
            postProcessors.closeDialogByNameSaga
        ]
    case DIALOG.NAME.ASSIGN_PREFERRED_BRANCH:
        return [
            assignPreferredBranchSaga,
            postProcessors.closeAllDialogsSaga   
        ]
    case DIALOG.NAME.DELETE_USER:
        return [
            dialogDeleteUserSaga, 
            postProcessors.refreshUsersTablesSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_USER:
        return [
            dialogEditUserSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_USER_FROM_LIST:
        return [
            dialogEditUserSaga, 
            postProcessors.refreshUsersTablesSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CATEGORY_UPLOAD:
        return [
            dialogUploadFilesSaga, 
            postProcessors.resetDamageManagerDocumentCenterSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CATEGORY_UPLOAD_WORKFLOW_PANEL:
        return [
            dialogUploadFilesWorkflowPanelSaga, 
            postProcessors.refreshWorkflowPanelSelectedTaskSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CATEGORY_SELECT_FROM_TASK_WIDGET:
        return [
            dialogUploadFilesForTaskSaga, 
            postProcessors.refreshDamageManagerSelectedTaskSaga, 
            postProcessors.refreshDamageManagerTaskWidgetsSaga, 
            postProcessors.resetDamageManagerDocumentCenterSaga,
            postProcessors.refreshDamageManagerTasksSaga,
            postProcessors.closeAllDialogsSaga
        ]     
    case DIALOG.NAME.CATEGORY_UPLOAD_FROM_DOCUMENT_CENTER:
        return [
            dialogUploadFilesSaga, 
            postProcessors.refreshDamageManagerDocumentCenterSaga,
            postProcessors.closeAllDialogsSaga
        ]    
    case DIALOG.NAME.ADD_DOCUMENTS_TO_INVOICE:
        return [
            dialogUploadFilesSaga, 
            postProcessors.refreshInvoices,
            postProcessors.refreshWorkflowPanelInvoicesSaga,
            postProcessors.refreshDamageManagerDocumentCenterSaga,
            postProcessors.closeAllDialogsSaga
        ]        
    case DIALOG.NAME.CREATE_TASK_FROM_DAMAGE_MANAGER:
        return [
            dialogCreateTaskSaga, 
            postProcessors.refreshDamageManagerTasksSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_LAWYERS:
        return [
            dialogCreateLawyersSaga, 
            postProcessors.refreshDamageCommisionedLawfirmSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT:
        return [
            dialogCommissionDekraExpertSaga, 
            postProcessors.refreshDamageCommisionedExpertSaga,
            postProcessors.refreshDamageManagerOverviewSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.COMMISSION_OTHER_EXPERT:
        return [
            dialogCommissionOtherExpertSaga, 
            postProcessors.refreshDamageCommisionedExpertSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_TASK:
        return [
            dialogDeleteTaskDamageSaga, 
            postProcessors.refreshTasksTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_TASK_FROM_DAMAGE_MANAGER:
        return [
            dialogDeleteTaskDamageSaga, 
            postProcessors.removeTaskFromTaskList, 
            postProcessors.removeSelectedTask,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_CASE_NOTES:
    case DIALOG.NAME.EDIT_CASE_NOTES:
        return [
            dialogCreateCaseNotesSaga, 
            postProcessors.refreshDamageManagerOverviewSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_ATTACHMENT:
        return [
            dialogDeleteAttachmentSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_ATTACHMENT_WORKFLOW_PANEL:
        return [
            dialogDeleteAttachmentWorkflowPanelSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_TASK_FROM_WORKFLOW_PANEL:
        return [
            dialogCreateTaskWorkflowPanelSaga, 
            postProcessors.refreshWorkflowPanelTasksSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_TASK_WORKFLOW_PANEL:
        return [
            dialogDeleteTaskWorkflowPanelSaga, 
            postProcessors.removeTaskFromTaskListWorkflowPanel, 
            postProcessors.removeSelectedTaskWorkflowPanel,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_TASK_WORKFLOW_PANEL:
        return [
            dialogEditTaskWorkflowPanelSaga, 
            postProcessors.refreshWorkflowPanelSelectedTaskSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_INVOICE_WORKFLOW_PANEL:
        return [
            dialogCreateInvoiceLawyerCommunicationSaga, 
            dialogUploadFilesSaga,
            postProcessors.refreshWorkflowPanelInvoicesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_INVOICE_LAWYER_COMMUNICATION:
        return [
            dialogCreateInvoiceLawyerCommunicationSaga, 
            dialogUploadFilesSaga,
            postProcessors.refreshInvoices,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CANCEL_PAYMENT_WORKFLOW_PANEL:
        return [
            dialogCancelPaymentLawyerCommunicationSaga, 
            postProcessors.refreshWorkflowPanelInvoicesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CANCEL_PAYMENT_LAWYER_COMMUNICATION:
        return [
            dialogCancelPaymentLawyerCommunicationSaga, 
            postProcessors.refreshInvoices,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_PAYMENT_WORKFLOW_PANEL:
        return [
            dialogCreatePaymentSaga, 
            postProcessors.refreshWorkflowPanelInvoicesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_PAYMENT_LAWYER_COMMUNICATION:
        return [
            dialogCreatePaymentSaga, 
            postProcessors.refreshInvoices,
            postProcessors.refreshDamageManagerTasksSaga, 
            postProcessors.closeAllDialogsSaga            
        ]
    case DIALOG.NAME.DELETE_INVOICE_WORKFLOW_PANEL:
        return [
            dialogDeleteInvoiceSaga, 
            postProcessors.refreshWorkflowPanelInvoicesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_INVOICE_LAWYER_COMMUNICATION:
        return [
            dialogDeleteInvoiceLawyerCommunicationSaga,
            postProcessors.refreshInvoices,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_TASK:
        return [
            dialogEditTaskSaga, 
            postProcessors.refreshTaskSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_MESSAGE_WORKFLOW_PANEL:
        return [
            dialogCreateMessageWorkflowPanelSaga,
            postProcessors.refreshWorkflowPanelMessagesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_MESSAGE_FROM_TRACKING:
        return [
            dialogCreateMessageTrackingSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_MESSAGE_FOR_LAWFIRM:
        return [
            dialogCreateMessageWorkflowPanelSaga,
            postProcessors.refreshDamageManagerMessageList,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_CUSTOMER:
        return [
            dialogEditCustomerSaga, 
            postProcessors.refreshCustomersTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_CUSTOMER:
        return [
            dialogCreateCustomerSaga, 
            postProcessors.refreshCustomersTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_CUSTOMER_FROM_DIALOG: 
        return [
            dialogEditCustomerFromDialogSaga, 
            postProcessors.closeDialogByNameSaga
        ]
    case DIALOG.NAME.EDIT_CUSTOMER_FROM_WIDGET:
        return [
            dialogEditCustomerSaga, 
            postProcessors.refreshDamageCustomerWidgets,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_CUSTOMER:
        return [
            dialogDeleteCustomerSaga, 
            postProcessors.refreshCustomersTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.MO_DOCUMENTS_DELETE:
        return [
            dialogMoDocumentsDeleteSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.MO_DOCUMENTS_CHANGE_CATEGORY:
        return [
            dialogMoDocumentsChangeCategorySaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.MO_DOCUMENTS_IS_EXPORTED_TO_LAWFIRM:
        return [
            dialogMoDocumentsIsExportedToLawfirmSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_CHANGE_BRANCH:
        return [
            changeCurrentBranchFromDialogSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.ADD_DOCUMENT_CATEGORY:
        return [
            addCategorySaga,
            postProcessors.refreshDocumentCategoriesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_DOCUMENT_CATEGORY:
        return [
            deleteCategorySaga,
            postProcessors.refreshDocumentCategoriesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_DOCUMENT_CATEGORY:
        return [
            editCategorySaga,
            postProcessors.refreshDocumentCategoriesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.ADD_CUSTOM_STATE:
        return [
            addCustomStateSaga,
            postProcessors.refreshCustomStatesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_CUSTOM_STATE:
        return [
            editCustomStateSaga,
            postProcessors.refreshCustomStatesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.DELETE_CUSTOM_STATE:
        return [
            deleteCustomStateSaga,
            postProcessors.refreshCustomStatesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_BRANCH:
        return [
            editBranchSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_ADD_BRANCH:
        return [
            createBranchSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.MO_DAMAGES_DELETE:
        return [
            dialogMoDamagesDeleteSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshDamageTablesSaga
        ]
    case DIALOG.NAME.MO_DAMAGES_CHANGE_STATE:
        return [
            dialogMoDamagesChangeStateSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshDamageTablesSaga
        ]
    case DIALOG.NAME.MO_TASKS_DELETE:
        return [
            dialogMoTasksDeleteSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshTasksTablesSaga
        ]
    case DIALOG.NAME.SWITCH_DAMAGE_CUSTOMER:
        return [
            damageSwitchCustomerSaga,
            postProcessors.refreshDamageCustomerWidgets,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_BRANCH: 
        return [
            deleteBranchFromDialogSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshBranchManagementSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_ADD_USERS_TO_GROUP: 
        return [
            addUsersToGroupSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshGroupTablesSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_ADD_GROUP:
        return [
            createGroupSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshBranchManagementSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_EDIT_GROUP:
        return [
            editGroupSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshBranchManagementSaga
        ]
                
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_GROUP: 
        return [
            deleteGroupSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshBranchManagementSaga
        ]
    case DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_USER_FROM_GROUP:
        return [
            removeUserFromGroupSaga,
            postProcessors.closeAllDialogsSaga,
            postProcessors.refreshGroupTablesSaga
        ]
    case DIALOG.NAME.REOPEN_TASK:
        return [
            dialogReopenTaskSaga,
            postProcessors.refreshTaskSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.EDIT_USER_GROUPS_FROM_LIST:
        return [
            dialogUpdateUserGroupsSaga,
            postProcessors.refreshUsersTablesSaga,
            postProcessors.refreshGroupTablesSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.WRITE_BACK_TO_LAST_DIALOG: //Change to write back to last dialog
        return [
            dialogWriteBackToLastDialogSaga,
            postProcessors.closeDialogByNameSaga
        ]
    case DIALOG.NAME.EXPORT_TO_LAWFIRM_CONFIRMATION_FROM_DOCUMENT_SIDEBAR:
        return [
            dialogExportToLawfirmFromDocumentSidebarSaga,
            postProcessors.refreshDamageManagerSelectedTaskSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.RE_EXPORT_TO_LAWFIRM_CONFIRMATION_FROM_DOCUMENT_SIDEBAR:
        return [
            dialogReExportToLawfirmFromDocumentSidebarSaga,
            postProcessors.refreshDamageManagerSelectedTaskSaga, 
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.USER_NOTIFICATION_SETTINGS:
        return [
            dialogEditUserNotificationSettingsSaga,
            postProcessors.closeAllDialogsSaga
        ]
    case DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE:
        return [dialogCreateCustomerQRCodeSaga]
    case DIALOG.NAME.CONFIGURE_DASHBOARD:
        return[
            dialogConfigureDashboardSaga,
            postProcessors.closeAllDialogsSaga
        ]
    default:
        return []
    }
}