import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import CreateMessagFromTrackingComponent from './component'

const mapStateToProps = (state, props) => {
    return {
        auth: state.auth,
        dialog: state.dialogs.registeredDialogs.find(d => d.name === props.dialog.name)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateMessage: (dialogName, payload, metaData) => dispatch(sendPayloadRequest(dialogName, payload, metaData)),
    }
}

export const CreateMessagFromTracking = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateMessagFromTrackingComponent)