import styled from 'styled-components'

export const ContainerActivityLog = styled.div`
    padding: 20px 16px;
    max-width: 640px;

    @media only screen and (max-width: ${props => props.theme.breakpoint.small}px){
        width: 100%;
        padding: 20px 8px;
    }
`

export const BoxActivity = styled.div`
    border-bottom: 1px solid ${props => props.theme.color.gray20};
`