import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { FlexBox } from 'application/components/fragments/flex_box'

const Wrapper = styled.div`
    position: relative;
    &:hover {
        .icon-copy {
            display: block;
        }
    }
`

const StyleInputCopy = styled.input`
    background: white;
    border: 1px solid ${props => props.theme.color.gray15};
    font-size: 16px;
    width: 100%;
    padding: 8px;
    user-select: text;

    &:focus {
        border: 1px solid ${props => props.theme.color.gray15};
        outline: none;
    }
`

const IconContainer = styled.div`
    display: none;
    padding-top: 4px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 34px;
    padding-top: 6px;
    background: ${props => props.theme.color.gray15};
    cursor: pointer;
`

export default (
    {copyOf}
) => {
    const {t} = useTranslation()
    const [copyIcon, setCopyIcon] = useState('assignment')

    const inputRef = useRef(null)

    return <Wrapper 
        onMouseLeave={
            ()=>{ setCopyIcon('assignment') }
        }
    >
        <FlexBox justifyContent='space-between'>
            <StyleInputCopy ref={inputRef}
                readOnly='readonly'
                value={copyOf}
                size={copyOf.length + 1} 
            />
            <IconContainer
                className='icon-copy' 
                onClick={(e) => {
                    inputRef.current.disabled = false
                    inputRef.current.select()
                    document.execCommand('copy')
                    e.target.focus()
                    inputRef.current.disabled = true
                    setCopyIcon('assignment_turned_in') 
                }}>
                <IconButtonWithLabel
                    iconSize='20px'
                    iconKey={copyIcon}
                    title={t('Copy')}
                    cssPadding='2px 0 2px 8px'
                />
            </IconContainer>   
        </FlexBox>
    </Wrapper>
}