import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../_common/commission_fragments'
import { WidgetSubHeadline, WidgetErrorMessage } from 'application/components/widgets/_common'
import { FontIcon } from '@ec/ui-controls-react'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { withRouter } from 'react-router-dom'
import { INFOBOX, COMMISSION_STATE } from 'application/constants'
import { Spacer } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'
import { FlexBox } from 'application/components/fragments/flex_box'

const CommissionLawfirmWidgetComponent = (
    { 
        widgetKey, 
        match, 
        payload, 
        loadingFailed, 
        openDialogCreateLawyers,
        customerId
    }
) => {
    const {
        lawfirm, 
        lawfirmFound
    } = payload

    const { t } = useTranslation()
    const {damageId} = match.params


    if(loadingFailed){
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    const handleOpenDialogCreateLawyer = () => {
        openDialogCreateLawyers(
            damageId, 
            lawfirmFound,
            customerId
        )
    }

    return <React.Fragment>
        <FlexBox 
            justifyContent='space-between'
            alignItems='center'
            flexGrow={'1'}
        >
            <WidgetSubHeadline>{t('Commissioned lawfirm')}</WidgetSubHeadline>
            {
                lawfirmFound && <IconButtonWithLabel
                    iconKey='autorenew'
                    label={t('Change')}
                    onButtonClick={handleOpenDialogCreateLawyer}
                /> 
            }
        </FlexBox>
        <FlexBox flexDirection='column'>
            {
                lawfirmFound 
                    ? <Fragments.ParticipantsWrapper>
                        <Fragments.StyleButtonToggle>
                            <Fragments.ParticipantsName>
                                <FontIcon icon='home' /> {lawfirm.name}
                            </Fragments.ParticipantsName>
                        </Fragments.StyleButtonToggle>
                        {
                            lawfirm.state === COMMISSION_STATE.FAILED 
                            && <InfoBox type={INFOBOX.TYPES.ALERT}
                                direction='row' 
                                cssMargin='0'
                            >
                                <FontIcon icon='info_outline' />
                                <Spacer basis='10px' />
                                <span>{t('Commissioning failed')}</span>
                                <Spacer />
                            </InfoBox>
                        }
                       
                    </Fragments.ParticipantsWrapper>
                    : <React.Fragment>
                        <IconButtonWithLabel
                            iconKey='add'
                            label={t('Lawfirm')}
                            onButtonClick={handleOpenDialogCreateLawyer}
                            cssPadding='0'
                        />
                    </React.Fragment>
            }    
        </FlexBox>
    </React.Fragment>
}

CommissionLawfirmWidgetComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export const CommissionLawfirm = withRouter(CommissionLawfirmWidgetComponent)

export default CommissionLawfirm