import { connect } from 'react-redux'

import { DIALOG } from 'application/constants'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'
import { openDialogByName } from 'application/redux/actions/dialogs'
import WorkflowPanelTasksTasksEffects from './effects'
import { getTasksWorkflowPanel, resetSelectedTaskWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'

const mapStateToProps = state => {
    const {tasksList, tasksLoaded, tasksLoading} = state.pages.workflowPanel.tasks
    return {
        tasksList,
        tasksLoaded,
        tasksLoading,
        loggedIn: state.sharedResource.loggedIn,
        damage: state.pages.workflowPanel.shared.damage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddTask: (sharedResourceId, damage) => dispatch(openDialogByName(DIALOG.NAME.CREATE_TASK_FROM_WORKFLOW_PANEL, true, { sharedResourceId, damage, usersList: [], usersListLoading: false}, 'Create Task')),
        onRefreshWidgetContexts: (contexts) => {
            for (const context of contexts){
                dispatch(refreshWidgetsByContext(context))
            }
        },
        onLoadTasks: (sharedResourceId) => {
            dispatch(getTasksWorkflowPanel(sharedResourceId))
        },
        onDeleteTasks: (taskIds) => {console.log('deleting: ', taskIds)},
        onForwardTasks: (taskIds) => {console.log('forwarding: ', taskIds)},
        onDownloadTasks: (taskIds) => {console.log('downloading: ', taskIds)},
        onResetSelectedTask: (t) => dispatch(resetSelectedTaskWorkflowPanel())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkflowPanelTasksTasksEffects)