import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { STORAGE } from 'application/constants'
import * as storage from 'application/storage'

const tableName = STORAGE.TABLE_NAME.ACCOUNTS


export const configuration = {
    name: tableName,
    filters: {
        match: [],
        query: [],
        property: [],
        static: []
    },
    sorting: {
        sortParamKey: ROUTE_PARAMETERS.ACCOUNTS.ORDER_BY.KEY,
        directionParamKey: ROUTE_PARAMETERS.ACCOUNTS.ORDER_BY.DIRECTION,
        defaultSortParamValue: ROUTE_PARAMETERS.ACCOUNTS.ORDER_BY.VALUES.ID,
        defaultDirectionParamValue: ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING
    },
    pagination: {
        offset: ROUTE_PARAMETERS.ACCOUNTS.OFFSET,
        limit: ROUTE_PARAMETERS.ACCOUNTS.LIMIT,
        getItemsPerPage: () => {
            return storage.tableConfig.getItemsPerPage(tableName, 5)
        }
    },
    href: (element) => getAccountLinkHref(
        {
            params: {
                accountId: element.id
            }
        }, 
        ROUTES.ACCOUNT_PRELOADER
    ),
    columns: [],
    ContextMenu: ({element, moIsActive}) =>null,
    contextMenuWidth: '0',
    massOperations: {
        available: false //are mass operations possible for this table?
    }
}