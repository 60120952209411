import { connect } from 'react-redux'
import ForSpecificBranchPermissionsComponent from './component'

const mapStateToProps = (state) => {
    return {
        allBranchPermissions: state.auth.allPermissions
    }
}

export default connect(
    mapStateToProps
)(ForSpecificBranchPermissionsComponent)