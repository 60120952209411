import React from 'react'
import { GravatarComponent } from 'application/components/building_blocks/gravatar'
import {useGravatar} from 'application/components/hooks/use_gravatar'



const UserDetailsSidebarComponent = (
    {
        firstName = '',
        lastName = ''
    }
) => {

    const {
        getInitials,
        getFullName 
    } = useGravatar(
        firstName,
        lastName
    )

    return <GravatarComponent
        getInitials = {getInitials}
        getFullName = {getFullName}
        cssWidth = '45%'
        cssHeight = '400px'
    />

}



export {UserDetailsSidebarComponent}