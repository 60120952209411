import { connect } from 'react-redux'
import CreateLawyersComponent from './component'
import { getWebakteLawfirmForDialogRequestDebounced } from 'application/redux/actions/common'
import { openDialogByName, sendPayloadRequest, updateDialogDataByName } from 'application/redux/actions/dialogs'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onCreateLawyer: (name, payload, successMsg, match, changeMode) => dispatch(
            sendPayloadRequest(
                name, 
                payload, 
                { 
                    match, 
                    successMsg, 
                    changeMode 
                }
            )
        ),
        onSearchLawfirm: (name, search) => dispatch(
            getWebakteLawfirmForDialogRequestDebounced(
                name, 
                0, 
                10,
                'id', 
                'asc', 
                new ListFilter(filters.users.name).setValue(search).getQuery()
            )
        ),
        updateDialog: (name) => dispatch(
            updateDialogDataByName(
                name, 
                { 
                    usersList: [], 
                    usersListLoading: false, 
                }
            )
        ),
        onChangeCustomerData: (
            customer,
            damageId,
            dialogName,
            recommendContactData
        ) =>{
            dispatch(
                openDialogByName(
                    DIALOG.NAME.EDIT_CUSTOMER_FROM_DIALOG,
                    true,
                    {
                        customer,
                        damageId,
                        fromDialogName: dialogName,
                        recommendContactData
                    },
                    'Edit customer'
                )
            )
        } 
    }
}

export const CreateLawyers = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateLawyersComponent)