import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { TextInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { FormElementWrapper } from 'application/components/controls/form/_common'
import { PushBottom8 } from 'application/components/pages/_common'
import { permissionSets } from './permission_sets'
import { PermissionSet } from './sub_components'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'



const dec2hex = (dec) => {
    return dec.toString(16).padStart(2, '0')
}
  
const generateId = (len = 6) => {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
}

const AddRole = (
    { 
        abort, 
        dialog, 
        onAddRole 
    }
) => {
    const {t} = useTranslation()

    const defaultValues = {
        roleName: ''
    }

    const { register, errors, handleSubmit, getValues } = useForm({defaultValues})

    const onSubmitSuccess = () => {
        onAddRole(getValues().roleName, generateId())
    }

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <TextInput
                    label={t('Name of the permission collection')}
                    name='roleName'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.roleName}
                    autoFocus={true}
                    onKeyPressEnter={handleSubmit(onSubmitSuccess)}
                />
                <PushBottom8 />
                <FormElementWrapper>
                    <label>{t('Active permissions')} (bitte mindestens eines auswählen)</label>
                    {
                        permissionSets.map((ps, i) => {
                            return <PermissionSet key={i} {...ps} />
                        })
                    }
                </FormElementWrapper>
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Add permission collection'
        />
    </React.Fragment>

}

export default withDialogDataLoader(AddRole)