import { requestActivityLogWorkflowPanel, requestActivityLogMoreWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/activity_log'
import { connect } from 'react-redux'
import ActivityLogWorkflowPanelEffects from './effects'

const mapStateToProps = state => {
    return {
        loggedIn: state.sharedResource.loggedIn,
        activities: state.pages.workflowPanel.activityLog.activities,
        total: state.pages.workflowPanel.activityLog.total,
        activityLoaded: state.pages.workflowPanel.activityLog.isLoaded,
        activityLoading: state.pages.workflowPanel.activityLog.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadActivityLog: (offset, limit, direction) => dispatch(
            requestActivityLogWorkflowPanel(offset, limit, direction)
        ),
        onLoadMoreActivityLog: (offset, limit, direction) => dispatch(
            requestActivityLogMoreWorkflowPanel(offset, limit, direction)
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityLogWorkflowPanelEffects)