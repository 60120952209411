import { connect } from 'react-redux'
import BranchManagementFlowchart from './component'
import { BRANCH_MANAGEMENT } from 'application/constants'
import { DIALOG } from 'application/constants'
import { 
    addGroupBranchRelationship, 
    addGroupRoleRelationship, 
    removeGroupBranchRelationship, 
    removeGroupRoleRelationship, 
    removeRole
} from 'application/redux/actions/pages/branch_management'
import { openDialogByName } from 'application/redux/actions/dialogs'


const mapStateToProps = state => {
    return {
        roles: state.common.roles,
        groups: state.common.userGroups,
        branches: state.common.allBranches,
        currentBranch: state.auth.currentBranch
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onEdgeRemove: (sourceId, targetId, connectionType) => {
            switch (connectionType) {
            case BRANCH_MANAGEMENT.CONNECTION_TYPE.GROUP_TO_ROLE:
                dispatch(removeGroupRoleRelationship(sourceId, targetId))
                break
            case BRANCH_MANAGEMENT.CONNECTION_TYPE.GROUP_TO_BRANCH:
                dispatch(removeGroupBranchRelationship(sourceId, targetId))
                break
            default:
                console.error('unknown connection type...')
            }
        },
        onNodeConnect: (sourceId, targetId, connectionType) => {
            switch (connectionType) {
            case BRANCH_MANAGEMENT.CONNECTION_TYPE.GROUP_TO_ROLE:
                dispatch(addGroupRoleRelationship(sourceId, targetId))
                break
            case BRANCH_MANAGEMENT.CONNECTION_TYPE.GROUP_TO_BRANCH:
                dispatch(addGroupBranchRelationship(sourceId, targetId))
                break
            default:
                console.error('unknown connection type...')
            }
        },
        onNodeRemove: (id, nodeType) => {
            if(typeof props.onRemoveNode === 'function'){
                props.onRemoveNode()
            }
            switch (nodeType) {
            case BRANCH_MANAGEMENT.NODE_TYPE.ROLE:
                dispatch(removeRole(id))
                break
            case BRANCH_MANAGEMENT.NODE_TYPE.GROUP:
                dispatch(
                    openDialogByName(
                        DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_GROUP, 
                        false,
                        { groupId:id }, 
                        'Remove user group'
                    )
                )
                break
            case BRANCH_MANAGEMENT.NODE_TYPE.BRANCH:
                dispatch(
                    openDialogByName(
                        DIALOG.NAME.BRANCH_MANAGEMENT_REMOVE_BRANCH, 
                        false,
                        {
                            branchId:id
                        }, 
                        'Delete branch'
                    )
                )
                break
            default:
                console.error('unknown node type...')
            }
        }
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchManagementFlowchart)