import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { WIDGET_CONTEXTS } from 'application/constants'
import ControllingInvoices from './component'

const ControllingInvoicesEffects = (props) => {
    const { onRefreshWidgetContexts } = props

    useEffect(() => {
        onRefreshWidgetContexts([WIDGET_CONTEXTS.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS])
    }, [onRefreshWidgetContexts])

    return <ControllingInvoices {...props} />
}

ControllingInvoicesEffects.propTypes = {
    onRefreshWidgetContexts: PropTypes.func.isRequired,
}

export default ControllingInvoicesEffects