import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { put } from 'redux-saga/effects'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetWorkflowPanelInsuranceInformationSaga(action) {
    
    const liabilityDataResponse = yield resourceCall(
        api.gateway.damage.getLiabilityData
    )
    
    yield put(updateWidget(action.key, action.dependentWidgetKeys, {
        formData: {
            liabilityData: liabilityDataResponse
        },
    }
    ))
}