import { isDefined } from 'application/common/data_helpers'
import { getDamageManagerLinkHref } from 'application/common/route_helpers'
import { ACTIVITY_RELATED_ENTITY_TYPES, ROUTES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { StyledActivityLink } from '../link'

export const RelatedDamageLink = withRouter((
    {
        activity,
        match
    }
) => {
    const {t} = useTranslation()
    const relatedDamage = activity.relatedEntities.find(
        entity => entity.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    )

    if(!isDefined(relatedDamage)){
        return null
    }

    const damageUrl = getDamageManagerLinkHref(
        match, 
        ROUTES.DAMAGE_MANAGER, 
        relatedDamage.id
    )

    return <StyledActivityLink
        to={damageUrl}
        match={match}
    >
        &raquo; {t('View damage')}
    </StyledActivityLink>
})