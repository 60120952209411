import { isDefined } from 'application/common/data_helpers'


export const 
    
    insuranceSelectInputName = 'liabilityCompanyId',
    insuranceTextName = 'liabilityCompanyName',
    certificateNumberName = 'liabilityCertificateNumber',
    claimNumberName = 'liabilityClaimNumber',

    emptyValues = {
        liabilityClaimNumber: 'unbekannt',
        liabilityCompanyId: null,
        liabilityCompanyName: null,
        liabilityCertificateNumber: null
    },

    mapDefaultValues = (formData) => ({
        liabilityCertificateNumber: formData.insurance?.certificateNumber || emptyValues.liabilityCertificateNumber,
        liabilityCompanyId: formData?.insurance?.company?.id || emptyValues.liabilityCompanyId,
        liabilityCompanyName: formData?.insurance?.company?.name || emptyValues.liabilityCompanyName,
        liabilityClaimNumber: formData.claim?.number || emptyValues.liabilityClaimNumber
    }),

    mapFormValuesToInsuranceCompany = (formValues) => {
        return  isDefined(formValues?.liabilityCompanyId) 
            ? { id: formValues.liabilityCompanyId }
            : { name: formValues.liabilityCompanyName }
    },

    mapFormValuesToLiabilityPayload = (formValues) => {
        return {
            liability: {
                insurancePayload: {
                    certificateNumber: formValues.liabilityCertificateNumber,
                    company: mapFormValuesToInsuranceCompany(formValues)
                },
                claimPayload: {
                    number: formValues.liabilityClaimNumber
                }
            }        
        }    
    }