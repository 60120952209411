import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { populateInsurances } from 'application/redux/actions/common'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getInsurancesSaga(action) {
    try {
        const insurancesResponse = yield resourceCall(
            api.gateway.insurances.getInsurances,
            {
                offset: 0,
                limit: 150
            }
        )
        yield put(populateInsurances(insurancesResponse.companies))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* getInsurancesWatcher() {
    yield takeLeading(ACTIONS.COMMON_GET_INSURANCES_REQUEST, getInsurancesSaga)
}