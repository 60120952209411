import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ImageWithLabel from 'application/components/controls/image_with_label'


const StyledLanguageSwitchButton = styled.div`
    cursor: pointer;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
`

const StyledLanguageSwitchWrapper = styled.div`
    flex: 0 0 40px;
    width: 100%;
    display: flex;
    padding: ${props => props.cssPadding !== undefined ? props.cssPadding : '0'};
`

const StyledLanguageDisplay = styled.div`
    cursor: pointer;
    padding: 10px;
    position: relative;
    left: -10px;
`

const StyledLanguageSwitchOverlay = styled.div`
    position: relative;
    left: -10px;
    box-sizing: border-box;
    width: auto;
    max-width: 100%;
    flex: 0 0 auto;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    padding: 10px;
`

const LanguageSwitch = ({currentLanguage, languages, onSwitchLanguages, switchCondition=false, cssPadding = '0'}) => {
    const [switchVisible, setSwitchVisible] = useState(switchCondition)
    const sortedLanguages = [currentLanguage, ...languages.filter(l => l.substring(0, 2) !== currentLanguage)]
    const uniqueLanguages = [...new Set(sortedLanguages)]

    return <StyledLanguageSwitchWrapper cssPadding={cssPadding}>
        {
            switchVisible 
                ? <StyledLanguageSwitchOverlay>
                    {
                        uniqueLanguages.map((lang, idx) => {
                            const subStringLang = lang.substring(0, 2)
                            return <StyledLanguageSwitchButton
                                className={`jestButtonLanguage-${idx}-${subStringLang}`}
                                key={idx}
                                onClick={() =>
                                {
                                    onSwitchLanguages(subStringLang)
                                    setSwitchVisible(false)
                                }
                                }
                            >
                                <ImageWithLabel source={subStringLang} />
                            </StyledLanguageSwitchButton>
                        })
                    }
                </StyledLanguageSwitchOverlay> 
                : <StyledLanguageDisplay className='jestButtonToSwitch' onClick={() => setSwitchVisible(true)}>
                    <ImageWithLabel source={currentLanguage} showLabel={false} />
                </StyledLanguageDisplay>
                
        }
    </StyledLanguageSwitchWrapper>
}

LanguageSwitch.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    languages: PropTypes.array.isRequired,
    onSwitchLanguages: PropTypes.func.isRequired,
    switchCondition: PropTypes.bool,
    cssPadding: PropTypes.string
}

export default LanguageSwitch