import React, {useState, useEffect} from 'react'
import {useRadioCheckBoxesByValue} from 'application/components/hooks'
import {TextInput, HiddenField} from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { REASONS_FOR_PAYMENT_REDUCTION } from 'application/constants/payment'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RadioCheckboxWithLabel } from 'application/components/controls/form/checkboxes/radio_checkbox_with_label'

const RadioCheckBoxGroupWithFreetextField = (
    {
        options,
        register,
        setValue = () => {},
        name, 
        validate={},
        error={}, 
        onDataChange = () => {},
        onChange = () => {},
        onCheckboxChange = () => {},
        hasFreetextOption = false,
        defaultSelectedItem,
        validateGroup={},
        errorGroup,
        freeTextOptionValue = REASONS_FOR_PAYMENT_REDUCTION.OTHER,
        errorMessageOverride
    }
) => {
    const {t} = useTranslation()
    const [selectedCheckboxValue, setSelectedCheckboxValue] = useState(options.length ? options[0].value : null)

    useEffect(()=>{
        onCheckboxChange(selectedCheckboxValue)
    }, [onCheckboxChange, selectedCheckboxValue])

    const addFreetextOptionAsLastMember = () => {
        return [
            ...options, 
            {
                label: t(freeTextOptionValue),
                value: freeTextOptionValue
            },
        ]
    }

    
    const optionsToRender = hasFreetextOption 
        ? addFreetextOptionAsLastMember()
        : options


    const {
        isChecked, 
        handleCheckboxChange, 
        getSelectedValue
    } = useRadioCheckBoxesByValue(optionsToRender, defaultSelectedItem)

    
    const selectedCheckBoxIsFreetext = () => {
        return getSelectedValue() === freeTextOptionValue
    }

    const checkBoxChange = (e, value) => {
        onChange(value === freeTextOptionValue ? '' : value)
        setSelectedCheckboxValue(value)
        handleCheckboxChange(e)
    }


    const renderFreeTextField = () => {
        return <TextInput
            label={t('Free designation')}
            name={name}
            register={register}
            validate={validate}
            error={error}
            onChange={e => {onDataChange(); onChange(e.target.value)}} 
        />
    }


    const renderSelectedOptionValueHiddenField = () => {
        return <HiddenField
            name={name} 
            register={register} 
            validate={validateGroup}
            error={errorGroup}
            errorMessageOverride={errorMessageOverride}
        />
    }

    return <React.Fragment>
        {
            optionsToRender.map(
                (option, i) => {
                    return <FlexBox paddingBottom='8px' key={i}>
                        <RadioCheckboxWithLabel
                            key={option.value}
                            name={option.value}
                            label={option.label}
                            isRequired={false}
                            isSelected={isChecked(option.value)}
                            onCheckboxChange={
                                (e) => {
                                    checkBoxChange(e, option.value)
                                    setValue(name, option.value)
                                }
                            }
                            activeLabel={option.activeLabel}
                        />
                    </FlexBox>
                } 
            )
        }
        {
            (
                (hasFreetextOption && !selectedCheckBoxIsFreetext()) 
                || !hasFreetextOption
            )
            && renderSelectedOptionValueHiddenField()
        }
        {
            hasFreetextOption 
            && selectedCheckBoxIsFreetext()
            && renderFreeTextField()
        }
    </React.Fragment>

}

RadioCheckBoxGroupWithFreetextField.propTypes = {
    options: PropTypes.array.isRequired,
    //only with freetextfield
    validate: PropTypes.object,
    name: PropTypes.string, 
    register: PropTypes.func,
    error: PropTypes.object, 
    onDataChange: PropTypes.func,
    hasFreetextField: PropTypes.bool
}

export default RadioCheckBoxGroupWithFreetextField