import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
    StyledHiddenCheckbox, 
    StyledCheckBoxWithLabel,
    StyledLabel,
    ActiveLabel
} from './fragments'
import { useTranslation } from 'react-i18next'
import { ValidationErrorMessage } from 'application/components/controls/form/_common'
import { PureCheckbox } from './pure_component'

const HiddenCheckbox = ({
    name,
    checked,
    onChange,
    validate = null,
    register = () => {},
    value = ''
}) => (

    <StyledHiddenCheckbox
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        ref={register({validate})}
        value={value}
    />

)

HiddenCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    validate: PropTypes.object,
    register: PropTypes.func,
    value: PropTypes.string,
}


const CheckboxWithLabel = (
    {
        register,
        name,
        isSelected, 
        onCheckboxChange,
        value,
        isRequired = false,
        label = '',
        fontSize='',
        activeLabel = false,
        isSmall = false,
        disabled = false,
        activeColor = false,
        hideValidationMessage = true,
        error = null,
        errorMessageOverride = '',
        validate = null,
        deselectable = true
    }
) =>{ 
    const {t} = useTranslation()
    const requiredLabel = isRequired ? label + '*' : label
    const activeColoring = activeLabel || activeColor
    const [isChecked, setIsChecked] = useState(isSelected)

    useEffect(
        ()=>{
            setIsChecked(isSelected)
        },
        [isSelected]
    )

    const handleOnChange = (e) => {       
        onCheckboxChange(e, !isChecked)
        setIsChecked(!isChecked)
    }

    const preHandleOnChange = (e) => {
        if (!deselectable && isChecked) return 
        handleOnChange(e)
    }

    return (
        <StyledCheckBoxWithLabel disabled={disabled} >
            <label>
                <HiddenCheckbox
                    name={name}
                    register={register}
                    value={value}
                    className='jest-hiddencheckbox-324ljklj-324'
                    checked={isChecked}
                    onChange={preHandleOnChange}
                    validate={validate}
                />           
                <PureCheckbox
                    isSmall = { isSmall }
                    checked = { isChecked } 
                    activeColor = { activeColoring }
                />   
            </label>
            <StyledLabel 
                fontSize = {fontSize}
                isActive = {activeColoring}
                activeColor = {activeColor}
            >                   
                {requiredLabel} { activeLabel && <ActiveLabel>
                    {'('+ t('Active') +')'}
                </ActiveLabel> }  
                <ValidationErrorMessage
                    hideValidationMessage={hideValidationMessage} 
                    error={error} 
                    errorMessageOverride={errorMessageOverride} 
                />
            </StyledLabel>
        </StyledCheckBoxWithLabel>
    )
}

CheckboxWithLabel.propTypes = {
    register: PropTypes.func,
    name: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
    isRequired:  PropTypes.bool,
    label: PropTypes.string,
    fontSize: PropTypes.string, 
    isSmall: PropTypes.bool, 
    disabled: PropTypes.bool,
    activeColor: PropTypes.bool,
    hideValidationMessage: PropTypes.bool,
    activeLabel: PropTypes.bool,
    error:  PropTypes.object,
    errorMessageOverride: PropTypes.string,
    value: PropTypes.string,
    validate: PropTypes.object,
    deselectable: PropTypes.bool
}

export default CheckboxWithLabel
