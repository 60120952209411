import { isDefined } from 'application/common/data_helpers'
import { getDamageManagerLinkHref } from 'application/common/route_helpers'
import { ACTIVITY_RELATED_ENTITY_TYPES, ROUTES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { StyledActivityLink } from '../link'
import PropTypes  from 'prop-types'


// {
//     "actor": {
//         "name": "Schuessler Lukas"
//     },
//     "branchId": "137937948057272320",
//     "data": {
//         "damage": {
//             "licensePlate": "TASK-DELETED"
//         },
//         "id": "142423906123251712",
//         "subject": "Neuer test"
//     },
//     "entity": {
//         "id": "142423906123251712",
//         "type": "Message"
//     },
//     "id": "142423906358132736",
//     "occurredAt": "2022-09-09T12:54:23.31858Z",
//     "relatedEntities": [
//         {
//             "id": "141848734303518720",
//             "type": "Damage"
//         }
//     ],
//     "type": "MessageCreatedActivity"
// }


export const MessageInDamageLink = withRouter((
    {
        activity,
        match
    }
) => {
    const {t} = useTranslation()

    const damageId= activity.relatedEntities.find(
        re => re.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    ).id

    if( !isDefined(damageId)){
        return null
    }

    const damageUrl = getDamageManagerLinkHref(
        match, 
        ROUTES.DAMAGE_MANAGER, 
        damageId
    ) 

    const damageInboxUrl = damageUrl + '/messages'
    const messageId = activity.entity.id

    if( isDefined(messageId) ){
        const damageInboxMessageUrl = `${damageInboxUrl}/${messageId}`
        return <StyledActivityLink
            to={damageInboxMessageUrl}
            match={match}
        >
        &raquo; {t('View message')}
        </StyledActivityLink>
    }

    return <StyledActivityLink
        to={damageInboxUrl}
        match={match}
    >
        &raquo; {t('View inbox')}
    </StyledActivityLink>
})


MessageInDamageLink.propTypes = {
    activity: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
}