import { connect } from 'react-redux'

import AdministrationSettingsCategories from './component'

const mapStateToProps = state => {
    const {
        categories,
        categoriesLoaded,
        categoriesLoading
    } = state.common

    return {
        categories,
        categoriesLoaded,
        categoriesLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdministrationSettingsCategories)