import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { accidentReportDataMapper } from 'application/api/data_mappers/export/damage'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* updateAccidentReportSaga(action) {
    try {
        const payload = accidentReportDataMapper(action.damageData)
        yield resourceCall(
            api.gateway.damage.updateAccidentReport, 
            {
                damageId: action.damageId
            }, 
            payload
        )
        yield put(showToast('Accident data saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateAccidentReportWatcher() {
    yield takeLeading(ACTIONS.DAMAGE_MANAGER_UPDATE_ACCIDENT_REPORT, updateAccidentReportSaga)
}