import { connect } from 'react-redux'
import WelcomePage from './component'

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

export default connect(
    mapStateToProps
)(WelcomePage)