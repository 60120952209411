import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import BranchManagement from './component'
import { DIALOG, BRANCH_MANAGEMENT } from 'application/constants'
import {
    toggleBranchSidebar,
    toggleGroupSidebar,
    toggleRoleSidebar,
    closeAllSidebars,
    toggleHelpSidebar
} from 'application/redux/actions/pages/branch_management'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { withGroupsLoader, withRolesLoader } from 'application/components/higher_order_components'

const mapStateToProps = state => {
    const { 
        roleSidebarVisible, 
        groupSidebarVisible, 
        branchSidebarVisible, 
        helpSidebarVisible 
    } = state.pages.branchManagement

    return {
        helpSidebarVisible,
        sidebarVisible: roleSidebarVisible || groupSidebarVisible || branchSidebarVisible || helpSidebarVisible,
        dataLoaded: state.common.accessibleBranchesLoaded 
            && state.auth.currentBranchLoaded
            && state.common.userGroupsLoaded
            && state.common.rolesLoaded
    }
}



const mapDispatchToProps = dispatch => {
    return {
        onAddRole: () => dispatch(
            openDialogByName(
                DIALOG.NAME.BRANCH_MANAGEMENT_ADD_ROLE, 
                true, 
                {}, 
                'Add permission collection'
            )
        ),
        onAddGroup: () => dispatch(
            openDialogByName(
                DIALOG.NAME.BRANCH_MANAGEMENT_ADD_GROUP, 
                true, 
                {}, 
                'Add user group'
            )
        ),
        onAddBranch: () => dispatch(
            openDialogByName(
                DIALOG.NAME.BRANCH_MANAGEMENT_ADD_BRANCH, 
                true, 
                {}, 
                'Add branch'
            )
        ),
        onCloseAllSidebars: () => dispatch(
            closeAllSidebars()
        ),
        onNodeClick: (nodeId, nodeType) => {
            dispatch(closeAllSidebars())
            switch (nodeType) {
            case BRANCH_MANAGEMENT.NODE_TYPE.ROLE:
                dispatch(toggleRoleSidebar(true, nodeId))
                break
            case BRANCH_MANAGEMENT.NODE_TYPE.GROUP:
                dispatch(toggleGroupSidebar(true, nodeId))
                break
            case BRANCH_MANAGEMENT.NODE_TYPE.BRANCH:
                dispatch(toggleBranchSidebar(true, nodeId))
                break
                
            default:
            }
        },
        onShowHelpSidebar: () => {
            dispatch(closeAllSidebars())
            dispatch(toggleHelpSidebar(true))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLoggedInUser(withGroupsLoader(withRolesLoader(BranchManagement))))