import { connect } from 'react-redux'
import DamageManagerDocumentsEffects from './effects'
import { getAllDocumentsRequest, hideAttachmentDetails, showAttachmentDetails } from 'application/redux/actions/pages/damage_manager/documents'
import { MASS_OPERATIONS } from 'application/constants'
import { fetchMoByName } from 'application/components/building_blocks/mass_operations/helper_functions'



const mapStateToProps = (state) => {

    const damageId = state.pages.damageManager.documentCenter.damageId
        
    const moType = MASS_OPERATIONS.TYPE.DOCUMENTS
    const moName = `${moType}_${damageId}` 

    const mo = fetchMoByName(
        state,
        {
            moName
        }
    )

    const atLeastOneMoIsActive = mo !== null && mo.isActive
    return {
        documents: state.pages.damageManager.documentCenter.documents,
        currentDamageId: damageId,
        categories: state.common.categories,
        sidebarDamageId: state.pages.damageManager.documentCenter.attachmentDetails.damageId,
        documentCenterAttachmentDetails: state.pages.damageManager.documentCenter.attachmentDetails,
        atLeastOneMoIsActive,
        documentsLoading: state.pages.damageManager.documentCenter.documentsLoading,
        documentsLoaded: state.pages.damageManager.documentCenter.documentsLoaded,
        moName,
        moType
    }
}

const mapDispatchToProps = dispatch => {
    return {        
        onAttachmentClick: (
            attachment, 
            damageId
        ) =>{           
            dispatch(
                showAttachmentDetails(
                    attachment, 
                    damageId
                )
            )
        },
        onLoadDocuments: (id) => dispatch(
            getAllDocumentsRequest(id)
        ),
        onHideAttachmentDetails: () => dispatch(
            hideAttachmentDetails()
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerDocumentsEffects)