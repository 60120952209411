import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from '@ec/ui-controls-react'
import {QRCodeCanvas} from 'qrcode.react'
import styled, { withTheme } from 'styled-components'
import { useCreateDamageUrl } from './use_create_damage_url'

const QrCodeWrapper = styled.div`
    width: 250px;
    height: 250px;

    & canvas{
        width: inherit !important;
        height: inherit !important;
    }
`

export const  Step1Component = withTheme(
    ({
        abort,
        goToPreviousStep,
        onQRCodeDownload,
        dialog,
        currentAccountName,
        theme
    }) => {
        const {t} = useTranslation()

        const {
            licensePlate,
            customer,
            categoryId,
            proxyShareId
        } = dialog.payload

        const qrCodeElementId = 'customerQRCode'

        const getQrCodeObjectUrl = () => {
            return document.getElementById(qrCodeElementId)
                .toDataURL('image/png')
                .replace('image/png', 'image/octet-stream')
        }


        const handleDownload = () => {
            onQRCodeDownload(
                getQrCodeObjectUrl(),
                `QR-Code-Schadenabwicklung-${licensePlate}.png`    
            )
        }

        const {
            createDamageUrl
        } = useCreateDamageUrl(
            customer, 
            licensePlate, 
            currentAccountName, 
            categoryId, 
            proxyShareId
        )

        const imageSrc = '/images/logos/esy360_logo_qr.png'

        return <React.Fragment>
            <FlexBox 
                flexDirection='column' 
                justifyContent='center'
                alignItems='center'
            >
                <QrCodeWrapper>
                    <QRCodeCanvas
                        id={qrCodeElementId}
                        value={createDamageUrl}
                        size={1000}
                        includeMargin={true}
                        imageSettings={
                            { 
                                src: imageSrc,
                                width: 200,
                                height: 242
                            }
                        }
                        level="M"
                    />
                </QrCodeWrapper>
                <FlexBox
                    justifyContent='center'
                    alignItems='center'
                    paddingTop='16px'
                    cssRules={`font-size:${theme.fontSize.small};word-break:break-all;`}
                >
                    {createDamageUrl}
                </FlexBox>
            </FlexBox>

            <StepsLayoutFooter>
                {/* TODO: DEV360-1058 Back Button to Change Data */}
                <IconButtonWithLabel
                    iconKey='arrow_back' 
                    label={t('Back')} 
                    onButtonClick={goToPreviousStep} 
                />
                <ActionButton
                    buttonText={t('Download')} 
                    onButtonClick={handleDownload} 
                />
            </StepsLayoutFooter>
        </React.Fragment>
    }
)