import React from 'react'
import { ACTIVITY_TYPE } from 'application/constants'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'






const ActivityAction = (
    {
        activityType = ''
    }
) => {
    const {t} = useTranslation()

    const mapTitle = (activityType) => {
        switch (activityType) {
        case ACTIVITY_TYPE.ACCOUNT_CREATED:
            return 'Account has been created'      
        case ACTIVITY_TYPE.ACCOUNT_INVITATION_CREATED:
            return 'Account invitation has been created'  
        case ACTIVITY_TYPE.BRANCH_CREATED:
            return 'Branch has been created'
        case ACTIVITY_TYPE.BRANCH_DELETED:
            return 'Branch has been deleted'    
        case ACTIVITY_TYPE.COMPREHENSIVE_DAMAGE_TYPE_CREATED:
            return 'Comprehensivedamage type was created'
        case ACTIVITY_TYPE.COMPREHENSIVE_DAMAGE_TYPE_CHANGED:
            return 'Comprehensive damage type changed'    
        case ACTIVITY_TYPE.COMPREHENSIVE_DAMAGE_TYPE_DELETED:
            return 'Comprehensivedamage type has been deleted'
        case ACTIVITY_TYPE.COMPREHENSIVE_DAMAGE_TYPE_RENAMED:
            return 'Comprehensivedamage type has been renamed'
        case ACTIVITY_TYPE.DAMAGE_ASSIGNEE_CHANGED:
            return 'Damage assignee has been changed' 
        case ACTIVITY_TYPE.DAMAGE_CREATED:
            return 'Damage has been created' 
        case ACTIVITY_TYPE.DAMAGE_DELETED:
            return 'Damage has been deleted'
        case ACTIVITY_TYPE.DAMAGE_JOB_NUMBER_CHANGED:
            return 'Job number has been changed' 
        case ACTIVITY_TYPE.DAMAGE_STATE_CREATED:
            return 'Damagestate has been created'
        case ACTIVITY_TYPE.DAMAGE_STATE_CHANGED:
            return 'Damage state  has been changed' 
        case ACTIVITY_TYPE.DAMAGE_STATE_DELETED:
            return 'Damage state  has been deleted'
        case ACTIVITY_TYPE.DAMAGE_TYPE_CHANGED:
            return 'Damage type  has been changed'
        case ACTIVITY_TYPE.DAMAGE_DESCRIPTION_CHANGED:
            return 'Damagedescription has been changed'
        case ACTIVITY_TYPE.DAMAGE_INCIDENT_DATE_CHANGED:
            return 'Damageincidentdate has been changed'
        case ACTIVITY_TYPE.DAMAGE_NOTE_CHANGED:
            return 'Damagenote has been changed'
        case ACTIVITY_TYPE.INVOICE_CREATED:
            return 'Invoice  has been created'
        case ACTIVITY_TYPE.INVOICE_DELETED:
            return 'Invoice  has been deleted'
        case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_INITIATED:
            return 'Lawfirm communication  has been initiated' 
        case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_ABORTED:
            return 'Lawfirm communication  has been aborted' 
        case ACTIVITY_TYPE.PAYMENT_CANCELLED:
            return 'Payment has been cancelled' 
        case ACTIVITY_TYPE.PAYMENT_CREATED:
            return 'Payment has been created' 
        case ACTIVITY_TYPE.USER_CREATED:
            return 'User has been created'
        case ACTIVITY_TYPE.USER_DATA_CHANGED:
            return 'Userdata has been changed'
        case ACTIVITY_TYPE.USER_DELETED:
            return 'User has been deleted'
        case ACTIVITY_TYPE.USER_LINKED:
            return 'Userinvitation has been accepted'
        case ACTIVITY_TYPE.USER_INVITATION_CREATED:
            return 'Userinvitation has been created'       
        case ACTIVITY_TYPE.TASK_CREATED:
            return 'Task has been created'         
        case ACTIVITY_TYPE.TASK_DELETED:
            return 'Task has been deleted'
        case ACTIVITY_TYPE.TASK_STATE_CHANGED:
            return 'Task state has been changed'
        case ACTIVITY_TYPE.TASK_COMMENT_CREATED:
            return 'Taskcomment has been created'
        case ACTIVITY_TYPE.VEHICLE_UPDATED:
            return 'Vehicledata has been updated'
        case ACTIVITY_TYPE.VEHICLE_KEEPER_CHANGED:
            return 'Vehiclekeeper has changed'
        case ACTIVITY_TYPE.DOCUMENT_CREATED:
            return 'Document has been created'
        case ACTIVITY_TYPE.DOCUMENT_DELETED:
            return 'Document has been deleted'
        case ACTIVITY_TYPE.MESSAGE_CREATED:
            return 'Message has been created'
        case ACTIVITY_TYPE.MESSAGE_DELETED:
            return 'Message has been deleted'
        case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_INITIATED:
            return 'DEKRA expert communication initiated'
        case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_ESTABLISHED:
            return 'DEKRA expert communication established'
        case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_FAILED:
            return 'DEKRA expert communication failed'
        case ACTIVITY_TYPE.EXPERT_COMMUNICATION_OTHER_INITIATED:
            return 'Other expert communication initiated'
        case ACTIVITY_TYPE.ACCOUNT_LOCKED:
            return 'Company account has been locked'
        case ACTIVITY_TYPE.ACCOUNT_UNLOCKED:
            return 'Company account has been unlocked'
        case ACTIVITY_TYPE.USER_LOCKED:
            return 'User has been locked'
        case ACTIVITY_TYPE.USER_UNLOCKED:
            return 'User has been unlocked'
        default:
            return 'Unknown'
        }
    }

    return  <React.Fragment>
        { t(mapTitle(activityType)) }
    </React.Fragment>
}


ActivityAction.propTypes = {
    activityType: PropTypes.string
}

export {ActivityAction}