import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { DAMAGE_TYPE } from 'application/constants'
import { useStatefulForm } from 'application/components/hooks'
import { BasicDamageInformationPartialForm } from '../_partial_forms'

const DamageBasicDamageInformationForm = (
    {
        formData = {}, 
        damageId, 
        damageCreatedAt,
        onSave
    }
) => {
    const {t} = useTranslation()

    const defaultValues = {
        jobNumber: '',
        type: DAMAGE_TYPE.UNKNOWN,
        incidentDate: null,
        description: '',
        ...formData
    }
    const {
        register, 
        errors,
        handleSubmit, 
        getValues, 
        setValue, 
        control, 
        formStateValues, 
        updateFormState
    } = useStatefulForm({defaultValues})

    const onSubmitSuccess = () => { 
        onSave(damageId, getValues())
    }

    return <FlexBox flexDirection='column'>
        <BasicDamageInformationPartialForm 
            register={register}
            errors={errors}
            setValue={setValue}
            formStateValues={formStateValues}
            updateFormState={updateFormState}
            control={control}
            damageCreatedAt={damageCreatedAt}          
        />
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save damage data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </FlexBox>

}

export { DamageBasicDamageInformationForm }