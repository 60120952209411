import React from 'react'
import styled, { withTheme } from 'styled-components'
import { INFOBOX } from 'application/constants'

const StyledInfoBox = styled.div`
    font-size: ${props => props.theme.fontSize.standard};
    background: ${props => props.infoboxStyle.backgroundColor};
    border: 1px solid ${props => props.infoboxStyle.borderColor};
    font-weight: ${props => props.theme.fontWeight.standardRegular};
    padding: ${props => props.cssPadding};
    color: ${props => props.theme.color.anthracite};
    border-radius: 2px;
    margin: ${props => props.cssMargin};
    width: 100%;
    max-width: ${props => props.cssMaxWidth ? props.cssMaxWidth : '100%'};
    display: flex;
    flex-direction: ${props => props.direction};
    align-items: ${props => props.direction === 'row' ? 'center': 'flex-start'};
    min-height: ${props => props.cssMinHeight};
    
    &>*{
        margin-right: ${props => props.direction === 'row' ? '4px' : '0'};
        margin-bottom: ${props => props.direction === 'column' ? '4px' : '0'};
    }
`

const getInfoboxStyle = (theme, type) => {
    switch (type) {
    case INFOBOX.TYPES.SECONDARY:
        return {
            backgroundColor: theme.color.white,
            borderColor: theme.color.anthracite50
        }
    case INFOBOX.TYPES.WARNING:
        return {
            backgroundColor: theme.color.warningBackground,
            borderColor: theme.color.warning
        }
    case INFOBOX.TYPES.ALERT:
        return {
            backgroundColor: theme.color.errorBackground,
            borderColor: theme.color.error
        }
    case INFOBOX.TYPES.SUCCESS:
        return {
            backgroundColor: theme.color.successBackground,
            borderColor: theme.color.success
        }
    default:
        return {
            backgroundColor: theme.color.primaryBackground,
            borderColor: theme.color.primary
        }
    }
}

const InfoBox = (
    {
        cssMargin = '0 0 16px 0', 
        direction = 'column', 
        type=INFOBOX.TYPES.STANDARD, 
        cssPadding='8px', 
        children, 
        cssMaxWidth='100%', 
        theme, 
        cssMinHeight='38px'
    }
) => {
    const infoboxStyle = getInfoboxStyle(theme, type)
    return <StyledInfoBox 
        cssMargin={cssMargin} 
        direction={direction} 
        cssPadding={cssPadding} 
        cssMaxWidth={cssMaxWidth} 
        infoboxStyle={infoboxStyle} 
        cssMinHeight={cssMinHeight}
    >
        {children}
    </StyledInfoBox>
}

export default withTheme(InfoBox)