import React, {useEffect, useState} from 'react'
import CurrencyInput from '../currency_input'
import HiddenField from '../hidden/component'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PureSelect } from 'application/components/controls/form'
import TwoColumns from 'application/components/forms/_common'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import { validationMethods } from 'application/common'
import { exists } from 'application/common/data_helpers'



const StyledLabel = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    margin-bottom: 4px;
`


export const DeductibleInput =  (
    { 
        label = 'Is decuctible',
        name = 'deductable', 
        setValue = () => {},
        register, 
        validate = null,
        control,
        onChange=()=>{}, 
        error, 
        hideValidationMessage = false, 
        defaultValue='0', 
        errorMessageOverride=''
    } 
) => {
    const {t} = useTranslation()

    const deductibleOptions = [
        {
            label: t('Yes'),
            value: true
        },
        {
            label: t('No'),
            value: false
        }
    ]

    const [textInputVisible, setTextInputVisible] = useState(exists(defaultValue) && defaultValue !== '0')
    const [deductibleValue, setDeductibleValue] = useState(defaultValue)

    const defaultOption = textInputVisible
        ? deductibleOptions[0]
        : deductibleOptions[1]

    const [isDeductibleOption, setIsDeductibleOption] = useState( 
        defaultOption
    )

    const handleSelectChange = (option) => {
        setIsDeductibleOption(option)
        setTextInputVisible(option.value)
    }
    
    useEffect(()=>{
        if(!textInputVisible){
            setValue(name, '0')
            setDeductibleValue('0')
        }else{
            setValue(name, deductibleValue)
        }
    },[deductibleValue, name, setValue, textInputVisible])


    return <React.Fragment>
        <TwoColumns

            columnLeft={
                <FlexBox 
                    height='72px' 
                    flexDirection='column'
                >
                    <StyledLabel>
                        {t(label)}
                    </StyledLabel>
                    <PureSelect
                        label={'Is deductible?'}
                        options={deductibleOptions}
                        value={isDeductibleOption}
                        onChange={handleSelectChange}
                        isClearable={false}
                    />
                </FlexBox>
            }


            columnRight={
                textInputVisible 
                    ? <CurrencyInput 
                        label={t('Amount')}
                        name={name} 
                        register={register} 
                        control={control} 
                        error={error} 
                        hideValidationMessage={hideValidationMessage} 
                        defaultValue={deductibleValue}
                        errorMessageOverride={errorMessageOverride}
                        onChange={(e)=>{
                            onChange(e)
                            setDeductibleValue(e.target.value)
                        }}
                        validate={{ notEmpty: validationMethods.notEmpty, currencyMustBeNotZero: validationMethods.currencyMustBeNotZero }}
                    />
                    : <HiddenField 
                        name={name} 
                        register={register} 
                    />
            }

        />
    </React.Fragment>
}
