import Moment from 'moment'
import { getOffset } from './date_time_helpers'
import {createBrowserHistory} from 'history'
import { ACCOUNT_BASE, BRANCH_BASE } from 'application/constants/routes'
import { notNull } from './data_helpers'

export const history = createBrowserHistory()



export const buildQueryString = (params) => {
    let queryString = '?'
    for (const param of params) {
        queryString += `${param.key}=${param.value}&` 
    }
    return queryString.substring(0, queryString.length - 1)
} 

export const getParamValueByKey = (paramsArray, key, fallback = '') =>{
    const entry = paramsArray.find(p => p.key === key)

    if(entry === undefined){
        return fallback
    }

    return     entry.value === undefined 
            || entry.value === null 
            || entry.value === '' 
        ? fallback 
        : entry.value.toString()
}

export const getUrlParamValueString = (paramName, fallback = '') => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if(!urlParams.has(paramName)){
        return fallback
    }
    return urlParams.get(paramName)
}

export const getUrlParamValueNumber = (paramName, fallback = 0) => {
    const value = getUrlParamValueString(paramName, fallback)
    const parsed = parseInt(value)
    if (isNaN(parsed)) { 
        return fallback 
    }
    return parsed
}

export const getUrlParamValueDate = (param, fallback) => {
    const urlParamValue = getUrlParamValueString(param, fallback)
    const date = new Date(urlParamValue)
    const dateOffset = getOffset(date)
    return new Moment(dateOffset).toISOString()
}


/**
 * generates urlKey by urlKeyName for react-router in the form "/:urlKeyName"
 * @param {string} urlKeyName name of variable to replace with value from URL "/:urlKeyName"
 */
const getURLKey = (urlKeyName) => {
    return notNull(urlKeyName) 
        ? `/:${urlKeyName}`
        : ''
}

/**
 * replaces "/:urlKeyName" with urlValue
 * @param {string} urlValue 
 * @param {object} match  prop from withRouter()
 * @param {string} urlKey 
 * @return {string} "value" 
 */
const getURLValue = (
    urlValue, 
    match, 
    urlKey
) => {
    return notNull(urlValue) 
        ? urlValue 
        : match.params[urlKey]
}


/**
 * replaces router variable "/:variableName" with given value
 * @param {string} urlKeyName name of variable to replace with value from URL "/:urlKeyName"
 * @param {string} urlValue value to replace string "/:variableName"
 * @param {string} href  URL string
 * @param {object} match  prop from withRouter()
 * @return {string} Url with replaced value /:urlKeyName -> /:urlValue
*/
export const setURLValue = (
    urlKeyName = null,
    urlValue = null,
    href,
    match
) => {
    const urlKey = getURLKey(urlKeyName)

    const value = getURLValue(
        urlValue, 
        match, 
        urlKey
    )

    return href.includes(urlKey) 
        ? href.replace(
            urlKey, 
            `/${value}`
        )
        : href
}



export const getAccountLinkHref = (match, href) => {
    let url = `${ACCOUNT_BASE}/${match.params.accountId}/${href}`

    if(href.includes(`${ACCOUNT_BASE}/:accountId`)){
        url = href.replace(`${ACCOUNT_BASE}/:accountId`, `${ACCOUNT_BASE}/${match.params.accountId}`)
    }else if(href.startsWith('/')){
        href = href.substring(1)
        url = `${ACCOUNT_BASE}/${match.params.accountId}/${href}`
    }

    return url
}

export const getBranchLinkHref = (match, href, branchId = null) => {
    let url = getAccountLinkHref(match, href)    

    if(href.includes(`${BRANCH_BASE}/:branchId`)){
        if(branchId !== null){
            url = url.replace(
                `${BRANCH_BASE}/:branchId`, 
                `${BRANCH_BASE}/${branchId}`
            )
        }else{
            url = url.replace(
                `${BRANCH_BASE}/:branchId`, 
                `${BRANCH_BASE}/${match.params.branchId}`
            )
        }
    }

    return url
}

export const getDamageManagerLinkHref = (
    match, 
    href, 
    damageId = null
) => {
    let url = getAccountLinkHref(match, href)    

    return setURLValue(
        'damageId', 
        damageId,
        url,
        match
    )
}


export const getDamageManagerTaskLinkHref = (
    match, 
    href, 
    damageId = null,
    taskId = null
) => {
    let damageManagerURL = getDamageManagerLinkHref(
        match,
        href,
        damageId
    )

    return setURLValue(
        'taskId', 
        taskId,
        damageManagerURL,
        match
    )
}