import React from 'react'
import { withTheme } from 'styled-components'
import { PushBottom32, Spacer } from 'application/components/pages/_common'
import { useTranslation } from 'react-i18next'
import { USER_PERMISSONS } from 'application/constants'
import LayoutAllBranches from 'application/components/pages/_layout/layout_all_branches'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import TableUsers from 'application/components/tables/users'


const AdministrationUsers = ({
    usersList,
    usersListLoaded,
    usersListLoading,
    onCreateUser,
    theme,
    updateConstraint,
    onLoadUsersList
}) => {
    const { t } = useTranslation()

    return <LayoutAllBranches
        titleSegments={['Users management']}
        headlineGeneric={t('Administration')} 
        headlineSpecific={t('User Administration')}
        headlineChildren={
            <ForAnyBranchPermissions permissions={[USER_PERMISSONS.USER.CREATE]} allNeeded={true}>
                <Spacer />    
                <IconButtonWithLabel
                    iconKey='person_add'
                    label={t('Create User')}
                    onButtonClick={onCreateUser}
                    iconSize={theme.fontSize.medium}
                    fontSize={theme.fontSize.medium}
                />
            </ForAnyBranchPermissions>
        }
    >
        <PushBottom32 />
        <TableUsers 
            usersList={usersList.users}
            usersListLoaded={usersListLoaded}
            usersListLoading={usersListLoading}
            usersListTotal={usersList.total}
            onLoadUsersList={onLoadUsersList}
            updateConstraint={updateConstraint}
        />
    </LayoutAllBranches>
}

export default withTheme(AdministrationUsers)
