import { connect } from 'react-redux'
import EditDocumentCategoryComponent from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onSaveDocumentCategory: (dialogName, categoryId, categoryName) => dispatch(sendPayloadRequest(dialogName, {categoryName}, {categoryId}))
    }
}

export const EditDocumentCategory = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditDocumentCategoryComponent)