import React from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { TextInput, DatePicker, HiddenField, TextArea } from 'application/components/controls/form'
import useStatefulForm from 'application/components/hooks/use_stateful_form'
import { DAMAGE_TYPE } from 'application/constants'
import { FormContainer } from './fragments'
import { useQuery } from 'application/components/hooks/use_query'
import { ROUTE_PARAMETERS } from './route_parameters'
import { StepsNavigation } from './steps_navigation'
import { Headline3 } from 'application/components/fragments/typography'
import TwoColumns from 'application/components/forms/_common'
import { PushBottom16 } from 'application/components/pages/_common'
import { Select } from 'application/components/controls/form'
import YesNoSelect from 'application/components/controls/form/yes_no_select/component'
import { InsuranceInputGroup } from 'application/components/building_blocks/insurance_input_group'
import { useState } from 'react'
import { exists } from 'application/common/data_helpers'

export const ExtCreateDamageForm = ({damageData, setDamageData, setCurrentStep}) => {
    const {t} = useTranslation()
    const query = useQuery()
    const [insuranceSelectionFromList, setInsuranceSelectionFromList] = useState(exists(damageData.insuranceSelectionFromList) ? damageData.insuranceSelectionFromList : false)
    const defaultValues = {
        type: DAMAGE_TYPE.LIABILITY, //hidden field
        customer: {
            firstName: query.getStringParam(ROUTE_PARAMETERS.FIRST_NAME_PARAM),
            lastName: query.getStringParam(ROUTE_PARAMETERS.LAST_NAME_PARAM),
            ...damageData.customer
        },
        licensePlate: query.getStringParam(ROUTE_PARAMETERS.LICENSE_PLATE_PARAM),
        whosAtFault: 'unknown',
        incidentDate: new Date(),
        incidentSite: '',
        accidentOpponent: '',
        policeOnSite: false,
        licensePlateOpponent: '',
        vehicleTypeOpponent: '',
        insuranceOponentName: '',
        insuranceOponentId: '',
        accidentReport: '',
        ...damageData
    }

    const selectOptions = [
        {
            value: 'Unbekannt',
            label: 'Unbekannt'
        },
        {
            value: 'Fahrzeughalter',
            label: 'Fahrzeughalter / Fahrer'
        },
        {
            value: 'Unfallgegner',
            label: 'Unfallgegner'
        },
        {
            value: 'Beide',
            label: 'Beide'
        },
    ]

    const setForm = () => {
        updateFormState()
    }

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        control, 
        updateFormState, 
        setValue,
        setFormStateValues,
        formStateValues,
        reset
    } = useStatefulForm({ defaultValues })

    return <FormContainer>
        <HiddenField
            name='type'
            register={register} 
        />

        <Headline3 cssMargin='16px 0'>Schaden / Unfallgeschehen</Headline3>

        <TwoColumns
            columnLeft={
                <Select 
                    label='Wer war Schuld?'
                    name='whosAtFault'
                    register={register}
                    setValue={setValue}
                    options={selectOptions}
                    defaultValue={exists(damageData.whosAtFault) ? selectOptions.find(o => o.value === damageData.whosAtFault) : selectOptions[0]}
                    validate={null} //no validation needed, no empty value possible
                    isClearable={false}
                />
            }
            columnRight={
                <YesNoSelect
                    register={register}
                    setValue={setValue}
                    initialValue={defaultValues.policeOnSite}
                    name='policeOnSite'
                    label='Polizei vor Ort?'
                /> 
            }
        />

        <TwoColumns
            columnLeft={
                <TextInput 
                    label='Schadenort'
                    name='incidentSite'
                    register={register}
                    error={errors.incidentSite}
                    onChange={updateFormState}
                />
            }
            columnRight={
                <DatePicker 
                    label={t('Damage date')}
                    name='incidentDate'
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.incidentDate}
                    onDateChange={setForm}
                    control={control}
                    disabledDays={{after: new Date()}}
                />  
            }
        />
        
        <TextArea
            label={t('Damage description')}
            name='accidentReport'
            register={register}
            validate={null}
            error={errors.accidentReport}
            onChange={setForm}
            placeholder='Bitte geben Sie hier eine kurze Beschreibung des Unfalls und des Schadens an ihrem Fahrzeug ein.'
        />

        <PushBottom16 />

        <Headline3 cssMargin='16px 0'>Fahrzeughalter / Kunde</Headline3>

        <TwoColumns
            columnLeft={
                <TextInput 
                    label={t('First name')}
                    name='customer.firstName'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.customer?.firstName}
                    onChange={updateFormState}
                />
            }
            columnRight={
                <TextInput 
                    label={t('Last name')}
                    name='customer.lastName'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.customer?.lastName}
                    onChange={updateFormState}
                />            
            }
        />
        <TwoColumns
            columnLeft={
                <TextInput 
                    label={t('License plate')}
                    name='licensePlate'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.licensePlate}
                    onChange={updateFormState}
                />
            }
           
        />

        <PushBottom16 />

        <Headline3 cssMargin='16px 0'>Unfallgegner</Headline3>

        <TextArea
            label='Name, Anschrift, Telefonnummer'
            name='accidentOpponent'
            register={register}
            validate={null}
            error={errors.accidentOpponent}
            onChange={setForm}
        />

        <TwoColumns
            columnLeft={
                <TextInput 
                    label='KFZ Kennzeichen'
                    name='licensePlateOpponent'
                    register={register}
                    error={errors.licensePlateOpponent}
                    onChange={updateFormState}
                />
            }
            columnRight={
                <TextInput 
                    label='Fahrzeugtyp'
                    name='vehicleTypeOpponent'
                    register={register}
                    error={errors.vehicleTypeOpponent}
                    onChange={updateFormState}
                />            
            }
        />

        <InsuranceInputGroup 
            register={register}
            setValue={setValue}
            formStateValues={formStateValues}
            updateFormState={updateFormState}
            errors={errors}
            insuranceSelectInputName = 'insuranceOponentId'
            insuranceTextName = 'insuranceOponentName'
            setInsuranceSelectionFromList = {setInsuranceSelectionFromList}
            insuranceSelectionFromList={insuranceSelectionFromList}
            setFormStateValues={setFormStateValues}
            reset={reset}
            formTitle=''
        />
    
        <StepsNavigation 
            previousVisible={false}
            onClickNext={handleSubmit(()=>{
                setDamageData({
                    ...getValues(),
                    insuranceSelectionFromList
                })
                setCurrentStep(1)
            })}
        />
    </FormContainer>
}