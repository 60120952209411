import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog' 
import { Paragraph } from 'application/components/fragments/typography'
import { Footer } from 'application/components/dialogs/_common/footer'
import { PushBottom32 } from 'application/components/pages/_common'
import { DASHBOARD_CONFIGURATION } from 'application/constants'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { StyledDashboardConfigurationOption } from './fragments'
import { FlexBox } from 'application/components/fragments/flex_box'
import { IMAGES } from 'application/constants'
import Image from 'application/components/controls/image'

const ConfigureDashboard =
(
    { 
        abort, 
        dialog, 
        onSaveDashboardConfiguration
    }
) => {
    const [dashboardConfiguration, setDashboardConfiguration] = useState(null)
        
    const {t} = useTranslation()

    return <React.Fragment>
        <DialogMainContent>

            <Paragraph cssPadding='16px 0'>
                {t('Please choose your dashboard configuration')}
            </Paragraph>
            <FlexBox flexDirection='row'>
                <StyledDashboardConfigurationOption 
                    isActive={dashboardConfiguration === DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES} 
                    onClick={()=>{setDashboardConfiguration(DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES)}}
                >
                    <Image data={IMAGES.DASHBOARD_CONFIGURATION_DAMAGES} />
                    <span>{t('Damages & releases')}</span>
                </StyledDashboardConfigurationOption>
                <StyledDashboardConfigurationOption 
                    isActive={dashboardConfiguration === DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CONTROLLING} 
                    onClick={()=>{setDashboardConfiguration(DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CONTROLLING)}}
                >
                    <Image data={IMAGES.DASHBOARD_CONFIGURATION_CONTROLLING} />
                    <span>{t('Controlling & statistics')}</span>
                </StyledDashboardConfigurationOption>
                <StyledDashboardConfigurationOption 
                    isActive={dashboardConfiguration === DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CUSTOMERS} 
                    onClick={()=>{setDashboardConfiguration(DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CUSTOMERS)}}
                >
                    <Image data={IMAGES.DASHBOARD_CONFIGURATION_CUSTOMERS} />
                    <span>{t('Customers & tracking')}</span>
                </StyledDashboardConfigurationOption>
            </FlexBox>
            <PushBottom32 />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            submitText='Apply configuration'
            onSubmit={
                () => onSaveDashboardConfiguration(
                    dialog.name, 
                    dashboardConfiguration
                )
            }
            disabled={dashboardConfiguration === null}
        />
    </React.Fragment>

}

export default withDialogDataLoader(ConfigureDashboard) 