import { connect } from 'react-redux'

import CommissionExpert from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onCommissionExpert: (name, payload, damageId) => dispatch(sendPayloadRequest(name, payload, {damageId})),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommissionExpert)