import { DIALOG } from 'application/constants'
import { moveCategory } from 'application/redux/actions/common'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import CategoryTree from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onAddDocumentCategory: () => dispatch(openDialogByName(DIALOG.NAME.ADD_DOCUMENT_CATEGORY, true, {}, 'Add category')),
        onDeleteDocumentCategory: (categoryId, categoryName) => dispatch(openDialogByName(DIALOG.NAME.DELETE_DOCUMENT_CATEGORY, true, {categoryName, categoryId}, 'Delete category')),
        onMoveDocumentCategory: (payload) => dispatch(moveCategory(payload)),
        onEditDocumentCategory: (categoryId, categoryName) => dispatch(openDialogByName(DIALOG.NAME.EDIT_DOCUMENT_CATEGORY, true, {categoryId, categoryName}, 'Edit category'))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoryTree)