import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import TruncateString from 'react-truncate-string'
import { NavLink } from 'react-router-dom'

import DeadlineBox from 'application/components/controls/deadline'
import Tag from 'application/components/controls/tag'
import IconCounter from 'application/components/controls/indicator'
import TaskStatusIcon from 'application/components/controls/task_status_icon'
import { DiffDateCloseTheDeadline, DiffDateOverTheDeadline } from 'application/common/deadline_helpers'

import * as Fragments from './fragments'
import { exists } from 'application/common/data_helpers'
import TaskPriorityDisplay from 'application/components/controls/task_priority_display'

const TaskLinked = ({ 
    task, 
    isActive, 
    link, 
    isSelectMode = false,
    onClick = () => {}, 
}) => {
    const {t} = useTranslation()
    const handleDisabledState = (e, disabled) => disabled ? e.preventDefault() : onClick()
    const isExternal = exists(task.creator) && task.creator.userId === undefined
    const isAssigned = task.assignee?.userId !== null && exists(task.assignee)

    return <Fragments.Task cssActive={isActive}>
        <NavLink to={link} onClick={(e)=>{handleDisabledState(e, isSelectMode)}}>
            <Fragments.Row marginBottom='0'>

                <Fragments.Left>
                    <TaskStatusIcon state={task.state} isActive={isActive} isAssigned={isAssigned} />
                </Fragments.Left>

                <Fragments.Filler>
                    <Fragments.Row>
                        {
                            i18n.exists(task.title)
                                ? <Fragments.TaskTitle title={t(task.title)}><TruncateString text={t(task.title)} truncateAt={100}/></Fragments.TaskTitle>
                                : <Fragments.TaskTitle title={task.title}><TruncateString text={task.title} truncateAt={100}/></Fragments.TaskTitle>
                        }
                    </Fragments.Row>
                    <Fragments.Row marginBottom='0'>
                        <TaskPriorityDisplay priority={task.priority} showLabel={false} />
                        <Fragments.Spacer cssWidth='6px' />
                        <IconCounter iconKey='textsms' text={task.comments.total} disabled={task.comments.total === 0} />
                        <IconCounter iconKey='attach_file' text={task.documents.total} disabled={task.documents.total === 0} />
                        <Fragments.Filler />
                        {
                            isExternal && <Tag>{t('External')}</Tag>
                        }
                        <Fragments.Spacer />
                        {task.deadline !== undefined && <DeadlineBox
                            deadlineFromTask={task.deadline}
                            isOverTheDeadline={DiffDateOverTheDeadline(task.deadline)}
                            isCloseTheDeadline={DiffDateCloseTheDeadline(task.deadline)}
                        />}
                    </Fragments.Row>
                    <Fragments.Filler />
                    
                </Fragments.Filler>
            
            </Fragments.Row>
        </NavLink>
    </Fragments.Task>
}

TaskLinked.propTypes = {
    task: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
    canBeSelected: PropTypes.bool, 
    isSeclected: PropTypes.bool,
    onSelectTask: PropTypes.func,
    onDeselectTask: PropTypes.func,
    isSelectMode: PropTypes.bool,
    onClick: PropTypes.func
}

export default TaskLinked