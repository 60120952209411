import React from 'react'
import * as Fragments from '../../fragments'
import { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { useWindowSize } from 'application/components/hooks'
import { FlexBox } from 'application/components/fragments/flex_box'

const FooterWelcome = ({ theme }) => {

    const screenWidth = useWindowSize().width

    return <Fragments.FooterWelcomeWrapper>
        <FlexBox 
            justifyContent='space-between' 
            alignItems='center'
        >
            <FlexBox>
                <Fragments.FooterWelcomeCompanyName>
                    © e.Consult AG {(new Date().getFullYear())}
                </Fragments.FooterWelcomeCompanyName>
            </FlexBox>
            <FlexBox justifyContent='flex-end'>
                <FlexBox  alignItems='center' width='auto'>
                    <Fragments.FooterLink 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        href={process.env.REACT_APP_PRIVACY_LINK}
                    >
                            Datenschutz
                    </Fragments.FooterLink>
                    <FontIcon 
                        color={
                            screenWidth <= theme.breakpoint.laptop 
                                ? theme.color.white 
                                : theme.color.black
                        } 
                        icon='call_made' 
                        fontSize={theme.fontSize.xSmall}
                    />
                </FlexBox>
                <FlexBox  alignItems='center' width='auto'>
                    <Fragments.FooterLink 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
                    >
                            AGB
                    </Fragments.FooterLink>
                    <FontIcon 
                        color={
                            screenWidth <= theme.breakpoint.laptop 
                                ? theme.color.white 
                                : theme.color.black
                        } 
                        icon='call_made' 
                        fontSize={theme.fontSize.xSmall}
                    />
                </FlexBox>
                <FlexBox  alignItems='center' width='auto'>
                    <Fragments.FooterLink 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        href={process.env.REACT_APP_IMPRINT_LINK}
                    >
                            Impressum
                    </Fragments.FooterLink>
                    <FontIcon 
                        color={
                            screenWidth <= theme.breakpoint.laptop 
                                ? theme.color.white 
                                : theme.color.black
                        } 
                        icon='call_made' 
                        fontSize={theme.fontSize.xSmall}
                    />
                </FlexBox>
            </FlexBox>
        </FlexBox>
    </Fragments.FooterWelcomeWrapper>
}

export default withTheme(FooterWelcome)