import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { removeEmptyStringProperties } from 'application/api/data_mappers/export/customer'



export function* dialogCreateCustomerSaga(action) {
    yield put(
        showWaitingScreen('Creating customer')
    )

    const mappedPayload = removeEmptyStringProperties(action.payload)

    const {id} = yield resourceCall(
        api.gateway.customer.postCustomer, 
        {
            customerId: action.metaData.customerId
        }, 
        mappedPayload
    )

    //Only temporarily until postCustomer backendendpoint is ready for more props
    yield resourceCall(
        api.gateway.customer.putCustomer, 
        {
            customerId: id
        }, 
        mappedPayload
    )

    yield put(
        showToast(action.metaData.successMsg)
    )
    yield put(
        hideWaitingScreen()
    )
}