import React from 'react'
import { Redirect } from 'react-router-dom'
import { isPermittedInSpecificBranch } from 'application/components/context/permissions'

export const PermissionRedirectStrategyComponent = ({
    strategy = [],
    permissions,
    permissionCheck = isPermittedInSpecificBranch
}) => {
    const redirect = strategy.find(s => permissionCheck(permissions, s.permissions))

    if(!redirect){
        return null
    }
    
    return <Redirect to={redirect.href} />
}