import React from 'react'
import LanguageSwitch from 'application/components/building_blocks/language_switch/hoc'
import * as Fragments from './fragments'
import { FlexBox } from 'application/components/fragments/flex_box'
import MenuSidebarIcon from './_common/menu_icon'
import AccordionSubmenu from './_common/accordion_menu'
import { withTheme } from 'styled-components'
import { withRouter } from 'react-router'
import BranchSpecificMenuContent from './accordion_menu_contents/open/branch_specific'
import BranchSpecificMenuContentClosed from './accordion_menu_contents/closed/branch_specific'
import AdministrationMenuContent from './accordion_menu_contents/open/administration'
import AdministrationMenuContentClosed from './accordion_menu_contents/closed/administration'
import AllBranchesMenuContent from './accordion_menu_contents/open/all_branches'
import AllBranchesMenuContentClosed from './accordion_menu_contents/closed/all_branches'
import PropTypes from 'prop-types'
import { BRANCH_BASE, ADMINISTRATION_BASE, ALL_BRANCHES_BASE } from 'application/constants/routes'


const openCloseSidebar = (sideBarOpen) => {
    return sideBarOpen 
        ? '210px'
        : '48px'
} 


const MainNavigationSidebar = (
    {
        auth,
        sideBarOpen,
        theme,
        match,
        onOpenChangeBranchDialog,
        onToggleSidebar,
        currentBranchLoaded,
        hasMultipleBranches
    }
) => {
    const { url } = match

    const sideBarWidth = openCloseSidebar(sideBarOpen, theme)
    const handleSetOpen = () => {
        onToggleSidebar()
    }

    const primaryColor = theme.color.primary
    const hoverColor = theme.color.primaryHover
    const currentBranchName = auth.currentBranch?.name || ''

    return <Fragments.MenuSidebar
        sideBarOpen={sideBarOpen}
        width={sideBarWidth}
    >
        <FlexBox
            flexDirection='column'
        >
            <FlexBox
                justifyContent='flex-end'
                height='inherit'
            >
                <MenuSidebarIcon
                    onClick={handleSetOpen}
                    iconKey='menu'
                    color={theme.color.white}
                    isClickable={true}
                    hoverColor={theme.color.primary}
                />
            </FlexBox>
            {
                hasMultipleBranches && <AccordionSubmenu
                    primaryColor={primaryColor}
                    menuName={currentBranchName}
                    accordionOpen={url.includes(BRANCH_BASE)}
                    menuIconKey={'store'}
                    sideBarOpen={sideBarOpen}
                    isLoading={!currentBranchLoaded}
                > 
                    {
                        sideBarOpen 
                            ? <BranchSpecificMenuContent
                                onOpenChangeBranchDialog={onOpenChangeBranchDialog}
                                match={match}
                                primaryColor={primaryColor}
                                primaryHoverColor={hoverColor}
                            />
                            : <BranchSpecificMenuContentClosed
                                onOpenChangeBranchDialog={onOpenChangeBranchDialog}
                                match={match}
                                primaryColor={primaryColor}
                                primaryHoverColor={hoverColor}
                            />
                    }
                </AccordionSubmenu>  
            }
             
            <AccordionSubmenu
                primaryColor={hasMultipleBranches ? theme.color.anthracite : primaryColor}
                menuName={hasMultipleBranches ? 'All Branches' : currentBranchName}
                menuIconKey={hasMultipleBranches ? 'language' : 'store'}
                accordionOpen={url.includes(ALL_BRANCHES_BASE)}
                sideBarOpen={sideBarOpen}
                isLoading={false}
            >
                {
                    sideBarOpen 
                        ? <AllBranchesMenuContent
                            match={match}
                            primaryColor={hasMultipleBranches ? theme.color.anthracite : primaryColor}
                            primaryHoverColor={hasMultipleBranches ? theme.color.anthracite50 : hoverColor}
                        />
                        : <AllBranchesMenuContentClosed
                            match={match}
                            primaryColor={hasMultipleBranches ? theme.color.anthracite : primaryColor}
                            primaryHoverColor={hasMultipleBranches ? theme.color.anthracite50 : hoverColor}
                            menuName={'All Branches'}
                        />
                }
            </AccordionSubmenu>          
            <AccordionSubmenu
                primaryColor={theme.color.anthracite}
                menuName={'Administration'}
                menuIconKey={'settings'}
                accordionOpen={url.includes(ADMINISTRATION_BASE)}
                sideBarOpen={sideBarOpen}
                isLoading={false}
            >
                {
                    sideBarOpen 
                        ? <AdministrationMenuContent
                            match={match}
                            primaryColor={theme.color.anthracite}
                            primaryHoverColor={theme.color.anthracite50}
                        /> 
                        : <AdministrationMenuContentClosed
                            match={match}
                            primaryColor={theme.color.anthracite}
                            primaryHoverColor={theme.color.anthracite50}
                            menuName={'Administration'}
                        /> 
                }
            </AccordionSubmenu>
            
        </FlexBox>
        <LanguageSwitch cssPadding='0 0 40px 12px' />
    </Fragments.MenuSidebar>
}

MainNavigationSidebar.propTypes = {
    auth: PropTypes.object.isRequired,
    sideBarOpen: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onOpenChangeBranchDialog: PropTypes.func.isRequired,
    onToggleSidebar: PropTypes.func.isRequired,
    hasMultipleBranches: PropTypes.bool
}


export default withRouter(withTheme(MainNavigationSidebar))