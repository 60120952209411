import React from 'react'
import { nameHelpers } from 'application/common'



const FullName = (
    { person }
) => {
    if(person === undefined) return null

    return <div>{ nameHelpers.getFullName(person) }</div>
}

export default FullName