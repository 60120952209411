import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen, addNewSubMessage } from 'application/redux/actions/waiting_screen'
import { showToast } from 'application/redux/actions/notifications'
import { assignTask } from 'application/redux/actions/pages/damage_manager/tasks'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogCreateTaskSaga(action) {

    const {
        damageId,
        hasAssignee,
        assignee
    } = action.metaData

    const {
        payload
    } = action

    yield put(
        showWaitingScreen('Creating task')
    )
    yield put(
        addNewSubMessage(payload.title)
    )
    const response = yield resourceCall(
        api.gateway.tasks.createTask, 
        {
            damageId
        }, 
        payload
    )

    // priority + deadline muss laut Lukas extra nachgereicht werden, 
    // daher dieser call
    // er fragt jan obs auch gleich beim POST geht. 

    const deadline =  payload?.deadline || undefined
    const hasDeadline = deadline !== undefined
    const priority = payload?.priority || undefined
    const hasPriority = priority !== undefined
    const hasDeadlineOrPriority = hasDeadline || hasPriority

    yield hasDeadlineOrPriority && resourceCall(
        api.gateway.tasks.updateTask, 
        {
            damageId, 
            taskId: response.id, 
        },
        payload
    )

    yield hasAssignee && put(
        assignTask(
            damageId, 
            response.id, 
            assignee.id,
            assignee.firstName,
            assignee.lastName
        )
    )
    yield put(
        hideWaitingScreen()
    )
    yield put(
        showToast('Task created')
    )
}