import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.pages.damageManager.shared, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.damageManager.shared
    }
    case ACTIONS.DAMAGE_MANAGER_REQUEST_DAMAGE: {
        return {
            ...state,
            damageLoaded: false,
            damageLoading: true,
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_POPULATE_DAMAGE: {
        return {
            ...state,
            damage: action.damage,
            damageLoaded: true,
            damageLoading: false,
        }
    }
    case ACTIONS.DAMAGE_MANAGER_SHARED_RESET_DAMAGE: {
        return {
            ...state,
            damage: null,
            damageLoaded: false,
            damageLoading: false,
        }
    }
    case ACTIONS.DAMAGE_MANAGER_POPULATE_SHARED_LAWFIRM: {
        return {
            ...state,
            hasLawfirm: action.hasLawfirm,
            lawfirmLoaded: true
        }
    }
    
    case ACTIONS.DAMAGE_MANAGER_SHARED_POPULATE_VEHICLE:
        state.damage.vehicle = action.vehicleData
        return state
    default:
        return state
    }
}