import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.errors, action) => {
    switch (action.type) {
    case ACTIONS.ERRORS_STORE_DETAILS:
        return {
            ...state,
            storedErrors: [
                ...state.storedErrors,
                {
                    id: action.errorId,
                    payload: action.payload
                }
            ]
        }
    case ACTIONS.ERRORS_SHOW_DETAILS:
        return {
            ...state,
            currentErrorId: action.errorId
        }
    case ACTIONS.ERRORS_HIDE_DETAILS:
        return {
            ...state,
            currentErrorId: null
        }
    default:
        return state
    }
}
