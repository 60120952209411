import { updateAccount } from 'application/redux/actions/common'
import { connect } from 'react-redux'

import AdministrationSettingsAccountData from './component'

const mapStateToProps = state => {
    return {
        currentAccount: state.auth.currentAccount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSaveAccount: (payload) => dispatch(updateAccount(payload))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdministrationSettingsAccountData)