import React from 'react'
import { Table } from 'application/components/tables/_common'
import { configuration } from './configuration'
import { useMassOperationsClose, useTableLoader } from 'application/components/hooks'
import { withMassOperations } from 'application/components/context/mass_operations'
import { withRouter } from 'react-router-dom'

const TableCustomers = (
    {
        customersListLoading,
        customersListLoaded,
        customersListTotal,
        customersList,
        moName,
        emptyMessage='No customers found',
        match,
        closeMoByName,
        onLoadCustomersList,
        updateConstraint
    }
) => {
    useTableLoader(onLoadCustomersList, match, configuration, updateConstraint)
    useMassOperationsClose(moName, closeMoByName, customersList)

    return <Table 
        configuration={configuration}
        elementsLoading={customersListLoading}
        elementsLoaded={customersListLoaded}
        elementsTotal={customersListTotal}
        elements={customersList}
        emptyMessage={emptyMessage}
        moName={moName}
    />
}

export default withMassOperations(withRouter(TableCustomers))