import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { TextArea, HiddenField } from 'application/components/controls/form'
import AktenStatusTypeSelect from 'application/components/controls/form/akten_status_select/component'
import { validationMethods } from 'application/common'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useStatefulForm } from 'application/components/hooks'
import { CASE_STATUS } from 'application/constants'

const LawfirmAktenStatusForm = ({
    formData = {}, 
    widgetKey, 
    onSave
}) => {
    const { t } = useTranslation()    
    const { notEmpty } = validationMethods
    
    const defaultValues = {
        status: formData.generalCommunication?.caseFileInfo?.status || CASE_STATUS.NOT_SET,
        note: formData.generalCommunication?.caseFileInfo?.note || '',
        referenceId: formData.generalCommunication?.caseFileInfo?.referenceId || ''
    }

    const { 
        register, 
        errors, 
        handleSubmit, 
        setValue, 
        getValues,
        formStateValues,
        updateFormState
    } = useStatefulForm(
        { defaultValues }
    )

    const onSubmitSuccess = () => { 
        onSave({
            ...formStateValues, //include all values
            ...getValues()
        })
    }

    return <React.Fragment>
        <HiddenField name='referenceId' register={register} />
        <FlexBox flexDirection='column'>
            <AktenStatusTypeSelect
                error={errors.status}
                register={register}
                setValue={setValue}
                initialValue={formStateValues.status}
                onChange={updateFormState}
                validate={{
                    notEmpty
                }}
            />
            <TextArea
                label={t('Description')}
                placeholder={t('Comment')}
                name='note'
                register={register}
                validate={null}
                error={errors.note}
                hideValidationBox={true}
                onChange={updateFormState}
            />
        </FlexBox>
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save Akten status')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </React.Fragment>
}

export { LawfirmAktenStatusForm }