import Moment from 'moment'
import { exists } from './data_helpers'

export const getOffset = (d) => {
    return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
}

export const dateIsBefore = (d1, d2) => {
    const date1 = new Moment(new Moment(d1).format('YYYY-MM-DD')).toDate()
    const date2 = new Moment(new Moment(d2).format('YYYY-MM-DD')).toDate()
    return new Date(date1) < new Date(date2)
}

export const isBeforeToday = (d1) => {
    const date1 = new Moment(new Moment(d1).format('YYYY-MM-DD')).toDate()
    const date2 = new Moment(new Moment(new Date()).format('YYYY-MM-DD')).toDate()
    return new Date(date1) < new Date(date2)
}

export const datesRange = (d1, d2) => {
    const date1 = new Moment(new Moment(d1).format('YYYY-MM-DD'))
    const date2 = new Moment(new Moment(d2).format('YYYY-MM-DD'))
    return date2.diff(date1, 'days')
}

export const timerangeConverter = {
    getDateTimesFromRange: (timeRangeString, separator = '|') => {
        if(!exists(timeRangeString)){
            return {
                startDate: null,
                endDate: null
            }
        }
        const [startDate, endDate] = timeRangeString.split(separator)
        return {
            startDate,
            endDate
        }
    },
    getRangeFromDateTimes: (startDate, endDate, format = 'YYYY-MM-DD', separator = '|') => {
        const date1string = new Moment(startDate).format(format)
        const date2string = new Moment(endDate).format(format)
    
        return `${date1string}${separator}${date2string}`
    }
}