import React, {useEffect} from 'react'

import TaskSelect from './component'

const TaskSelectEffects = (props) => {
    const { damageId, tasksListDamageId, tasksLoaded, tasksLoading, onLoadTasks } = props

    const shouldLoad = (!tasksLoaded || damageId !== tasksListDamageId) && !tasksLoading 

    useEffect(() => {
        shouldLoad && onLoadTasks(damageId)
    }, [onLoadTasks, damageId, shouldLoad])

    return <TaskSelect {...props} />
}

export default TaskSelectEffects