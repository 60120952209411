import { connect } from 'react-redux'

import { LawfirmInvoices } from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => ({
    isExternal: state.sharedResource.id !== null
})

const mapDispatchToProps = dispatch => {
    return {
        addDocuments: (parentId, invoiceId, payload, isExternal) => dispatch(
            openDialogByName(
                DIALOG.NAME.ADD_DOCUMENTS_TO_INVOICE, 
                true, 
                { 
                    parentId, 
                    damageId: parentId,
                    invoiceId,
                    useDefaultCategory: isExternal,
                    ...payload 
                }, 
                'Add documents to invoice'
            )
        ),
        addPayment: (parentId, payload)  => 
            dispatch(
                openDialogByName(
                    DIALOG.NAME.CREATE_PAYMENT_WORKFLOW_PANEL, 
                    true, 
                    {
                        parentId, 
                        ...payload
                    }, 
                    'Add payment'
                )
            ),
        cancelPayment: (parentId, payload)  => 
            dispatch(
                openDialogByName(
                    DIALOG.NAME.CANCEL_PAYMENT_WORKFLOW_PANEL, 
                    true, 
                    {
                        parentId, 
                        ...payload
                    }, 
                    'Cancel payment')
            ),
        deleteInvoice: (parentId, payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.DELETE_INVOICE_WORKFLOW_PANEL, 
                true, 
                { 
                    parentId, 
                    ...payload 
                }, 
                'Delete payment'
            )
        ),

        onCreateInvoice: (sharedResourceId, incidentDate) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_INVOICE_WORKFLOW_PANEL, 
                true, 
                { sharedResourceId, incidentDate }, 
                'Create invoice'
            )
        ),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LawfirmInvoices)