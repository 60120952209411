//reducer for the generic title bar
import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.titleBar, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.titleBar
    }
    case ACTIONS.TITLE_BAR_GET_AFFILIATES_REQUEST:
        return {
            ...state,
            affiliatesList: [],
            affiliatesLoaded: false
        }
    case ACTIONS.TITLE_BAR_POPULATE_AFFILIATES:
        return {
            ...state,
            affiliatesList: action.affiliatesList,
            affiliatesLoaded: true,
            affiliatesEffectBlocked: true
        }
    default:
        return state
    }
}
