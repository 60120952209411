import React from 'react'
import { HeaderContainer } from './fragments'
import { FormHeadline, Paragraph } from 'application/components/fragments/typography'
import { AnimatedCheckmark } from './animated_checkmark'
import { getFullName } from 'application/common/name_helpers'
import { PushBottom32 } from 'application/components/pages/_common'

export const ExtCreateDamageSuccess = (
    {
        damageData
    }
) => {
    return <HeaderContainer>

        <React.Fragment>
            <FormHeadline>
                        Serviceanfrage gesendet
            </FormHeadline>
            <Paragraph cssAlign='center'>{getFullName(damageData.customer)}</Paragraph>
            <Paragraph cssAlign='center'>{damageData.licensePlate}</Paragraph>
            <PushBottom32 />
            <AnimatedCheckmark />
            <PushBottom32 />
            <Paragraph cssAlign='center'>
                Ein Servicemitarbeiter setzt sich in Kürze mit Ihnen in Verbindung.
            </Paragraph>
            {
                damageData.incidentDate !== null && <Paragraph cssAlign='center'>
                    Wir versuchen Ihnen einen Termin am <strong>{new Date(damageData.desiredDate).toLocaleDateString()}</strong> zu vermitteln.
                </Paragraph>
            }
            
        </React.Fragment> 
    </HeaderContainer>
}