import React from 'react'
import { SubPageLayout, PushBottom32 } from 'application/components/pages/_common'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { useTranslation } from 'react-i18next'
import { LayoutCurrentBranch } from 'application/components/pages/_layout'
import { TableActivities } from 'application/components/tables/activities'
import TimeRangeFilter from 'application/components/building_blocks/filters/time_range_filter'
import { ROUTE_PARAMETERS } from 'application/constants'






const ActivityLog = withLoggedInUser((
    {
        activityLog,
        onLoadActivitiesList,
        updateConstraint
    }
) => {
    const {t} = useTranslation()

    const { 
        activitiesList, 
        activitiesListLoading, 
        activitiesListLoaded, 
        activitiesListTotal
    } = activityLog

    return <LayoutCurrentBranch
        titleSegments={['Activity Log']}
        headline={t('Activity Log')}
    >
        <SubPageLayout>
            <TimeRangeFilter
                routeParameters={ROUTE_PARAMETERS.ACTIVITIES.ALL}
                label='Show activities between'
                maxPeriod={null}
            />
            <PushBottom32 />
            <TableActivities
                activitiesList={activitiesList}
                activitiesListLoading={activitiesListLoading}
                activitiesListLoaded={activitiesListLoaded}
                activitiesListTotal={activitiesListTotal}
                onLoadActivitiesList={onLoadActivitiesList}
                updateConstraint={updateConstraint}
                // moName={moName}
            /> 
        </SubPageLayout>
    </LayoutCurrentBranch>
})

export default ActivityLog