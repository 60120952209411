import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { TextInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'

const EditCustomState = (
    { 
        abort, 
        dialog, 
        onSaveCustomState 
    }
) => {
    const { customStateName, customStateId } = dialog.payload
    const {t} = useTranslation()

    const defaultValues = {
        name: customStateName
    }

    const { 
        register,
        errors, 
        handleSubmit,
        getValues 
    } = useForm({defaultValues})

    const onSubmitSuccess = () => {
        onSaveCustomState(
            dialog.name, 
            customStateId, 
            getValues().name
        )
    }
    
    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <TextInput
                    label={t('Custom state name')}
                    name='name'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.name}
                    autoFocus={true}
                    onKeyPressEnter={handleSubmit(onSubmitSuccess)}
                />              
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Save custom status'
        />
    </React.Fragment>
}

export default withDialogDataLoader(EditCustomState) 