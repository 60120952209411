import React from 'react'
import {
    // Switch,
    // Route,
    withRouter,
    Redirect
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import { TabsLinks } from 'application/components/building_blocks/tabs'
import { ROUTES } from 'application/constants'
import { LayoutAllBranches } from 'application/components/pages/_layout'
// import ControllingStatistics from './statistics'
import ControllingInvoices from './invoices'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { getAccountLinkHref } from 'application/common/route_helpers'
// import { PERMISSION_SCOPE } from 'application/constants/permissions'
import { USER_PERMISSONS } from 'application/constants'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'




const Controlling = ({ match }) => {
    const {t} = useTranslation()

    if (match.path === ROUTES.CONTROLLING) {
        return <Redirect to={getAccountLinkHref(match, ROUTES.CONTROLLING_INVOICES)} />
    }

    return <LayoutAllBranches
        titleSegments={['Controlling', 'All branches']}
        headlineSpecific={t('Controlling')}
    >

        <ForAnyBranchPermissions
            permissions={[
                USER_PERMISSONS.CONTROLLING.READ
            ]}
        >
            <ControllingInvoices />
        </ForAnyBranchPermissions>


        {/* <TabsLinks
            items={[
                {
                    label: t('Invoices'),
                    link: getAccountLinkHref(match, ROUTES.CONTROLLING_INVOICES),
                    isActive: match.path === ROUTES.CONTROLLING_INVOICES,
                    permissions: [ USER_PERMISSONS.CONTROLLING.READ ],
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    allNeeded: true
                },
                {
                    label: t('Created damages'),
                    link: getAccountLinkHref(match, ROUTES.CONTROLLING_STATISTICS),
                    isActive: match.path === ROUTES.CONTROLLING_STATISTICS,
                    permissions: [ USER_PERMISSONS.CONTROLLING.READ ],
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    allNeeded: true
                }
            ]}
        /> */}
        {/* <Switch>
            <Route path={ROUTES.CONTROLLING_INVOICES}>
                <ForAnyBranchPermissions
                    permissions={[
                        USER_PERMISSONS.CONTROLLING.READ
                    ]}
                >
                    <ControllingInvoices />
                </ForAnyBranchPermissions>
            </Route>
            <Route path={ROUTES.CONTROLLING_STATISTICS}>
                <ForAnyBranchPermissions
                    permissions={[
                        USER_PERMISSONS.CONTROLLING.READ
                    ]}
                >
                    <ControllingStatistics />
                </ForAnyBranchPermissions>
            </Route>
        </Switch> */}
    </LayoutAllBranches>
}

export default withLoggedInUser(withRouter(Controlling))