import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import DamageManagerTasks from './component'

const DamageManagerTasksEffects = (props) => {
    const { onLoadTasks, loggedIn, match } = props
    const { damageId } = match.params

    useEffect(() => {
        loggedIn && onLoadTasks(damageId)
    }, [loggedIn, onLoadTasks, damageId])

    return <DamageManagerTasks {...props} />
}

DamageManagerTasksEffects.propTypes = {
    onLoadTasks: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
}

export default withRouter(DamageManagerTasksEffects)