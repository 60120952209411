import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import CommissionLawfirm from './component'


const mapDispatchToProps = dispatch => {
    return {
        openDialogCreateLawyers: ( 
            damageId, 
            changeMode
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_LAWYERS, 
                false, 
                { 
                    lawyersList: [], 
                    lawyersListLoading: false, 
                    changeMode, 
                    damageId, 
                    lawfirm: null
                }, 
                'Commission lawfirm'
            )
        ),
    }
}

export default connect(
    null,
    mapDispatchToProps
)(CommissionLawfirm)