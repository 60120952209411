import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { WIDGET_TYPES } from 'application/constants'
import DamageManagerInbox from './component'

const DamageManagerInboxEffects = (props) => {
    const { onRefreshWidgetType, match, onResetMessageDetails } = props
    const { damageId } = match.params

    useEffect(() => {
        onRefreshWidgetType(WIDGET_TYPES.DAMAGE_MANAGER_INBOX_MESSAGE_LIST)
    }, [onRefreshWidgetType, damageId])

    useEffect(()=>{
        onResetMessageDetails()
    },[onResetMessageDetails])

    return <DamageManagerInbox {...props} />
}

DamageManagerInboxEffects.propTypes = {
    onRefreshWidgetType: PropTypes.func.isRequired,
}

export default withRouter(DamageManagerInboxEffects)