import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { LawfirmForms } from 'application/components/forms'
import { PushBottom24 } from 'application/components/pages/_common'
import { FlexBox } from 'application/components/fragments/flex_box'


const LawfirmInsuranceInformation = (
    {
        widgetKey, 
        payload, 
        loadingFailed, 
        onSave,
        damageType
    }
) => {
    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('Insurance data could not be loaded')}
        </WidgetErrorMessage>
    }
    const { formData } = payload
    const handleSave = (payload) => {
        onSave(
            widgetKey, 
            payload
        )
    } 

    return <FlexBox flexDirection='column'>
        <PushBottom24 />
        <LawfirmForms.LawfirmInsuranceInformationForm
            onSave={handleSave}
            formData={formData}
            damageType={damageType}
        />
    </FlexBox>
}

LawfirmInsuranceInformation.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { LawfirmInsuranceInformation }