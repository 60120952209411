import React, {useEffect} from 'react'
import {DamageNotFoundPage} from './component'
import { withRouter } from 'react-router-dom'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { ROUTES } from 'application/constants'



const DamageNotFoundEffectsComponent = (props) => {

    const { 
        onResetDamage,
        match,
        accounts
    } = props

    const {
        damageId = '',
    } = match.params

    useEffect(
        () => {
            onResetDamage()
        }, [onResetDamage,damageId]
    )

    if(!accounts.loaded){
        return null
    }

    const linkURL = getAccountLinkHref(
        match, 
        ROUTES.DASHBOARD
    )

    return <DamageNotFoundPage linkURL={linkURL}>
        {props.children}
    </DamageNotFoundPage>
}


export const DamageNotFoundEffects =  withRouter(
    DamageNotFoundEffectsComponent
)