import { put, select } from 'redux-saga/effects'
import { api } from 'application/api'
import { damageManagerSelectedTask } from 'application/redux/selectors'
import { deleteAttachment } from 'application/redux/actions/attachments'
import { hideAttachmentDetails as hideTasksAttachmentDetails, getSelectedTask } from 'application/redux/actions/pages/damage_manager/tasks'
import { hideAttachmentDetails as hideDocumentsAttachmentDetails, removeAttachmentFromDocumentCenter } from 'application/redux/actions/pages/damage_manager/documents'
import { showToast } from 'application/redux/actions/notifications'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'




export function* dialogDeleteAttachmentSaga(action) {
    const { payload } = action
    const selectedTask = yield select(damageManagerSelectedTask)
    yield put(showWaitingScreen('Deleting document'))
    yield resourceCall(
        api.gateway.attachments.deleteAttachment, 
        {
            damageId: payload.damageId, 
            attachmentId: payload.attachmentId
        }
    )
    yield put(hideTasksAttachmentDetails())
    yield put(hideDocumentsAttachmentDetails())
    yield put(
        removeAttachmentFromDocumentCenter(payload.attachmentId)
    )
    if (
        payload.taskId !== null 
        && selectedTask !== null 
        && selectedTask.id === payload.taskId
    ) {
        yield put(
            getSelectedTask(
                payload.taskId, 
                payload.damageId
            )
        )
    }
    yield put(deleteAttachment(payload.attachmentId))
    yield put(showToast('Document deleted'))
    yield put(hideWaitingScreen())
}