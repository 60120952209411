import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledCommissionDekraExpertButton = styled.button`
    background: ${ props => props.theme.color.gray10};
    border-radius: 2px;
    font-size: ${ props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.gray70};
    flex: 0 0 auto;    
    display: flex;
    flex-direction: row;
    border: 0;
    padding: 8px 4px;
    margin: ${props => props.cssMargin};
    align-items: center;
    cursor: pointer;

    &>i{
        align-self: center;
        font-size: ${props => props.theme.fontSize.medium};
        color: ${props => props.theme.color.gray70};
    }
    &>img{
        height: 20px;
        margin: 0 4px 0 6px;
    }
    :hover{
        background: ${ props => props.theme.color.gray15};
        color: ${props => props.theme.color.black};
        &>i{
            color: ${props => props.theme.color.black};
        }
    }
`

export const CommissionDekraExpertButton = ({
    cssMargin='2px 8px',
    iconKey='add',
    onButtonClick = () => {}
}) => {
    const {t} = useTranslation()
    return <StyledCommissionDekraExpertButton cssMargin={cssMargin} onClick={onButtonClick}>
        <i className="material-icons">{iconKey}</i>
        <img src='/images/DEKRA.svg' alt='dekra' />
        {t('expert')}
    </StyledCommissionDekraExpertButton>
}