import styled from 'styled-components'
import { NavLink } from 'react-router-dom'


const Wrapper = styled.div`
    flex: 0 0 ${props => props.smallFont ? '16px' : '48px'};
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    z-index: 1;
`

const TabsWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-right: ${props => props.smallFont ? '0' : '24px'};
`

const TabsItem = styled.div`
    display: flex;
    margin-right: ${props => props.smallFont ? '8px' : '0'};
    cursor: pointer;
    font-size: ${props => props.smallFont ? '12px' : '16px'};
    color: ${props => props.isSelected ? props.theme.color.primary : props.theme.color.anthracite};
    align-items: center;

    & a {
        display: flex;
        align-items: center;
        padding: ${props => props.smallFont ? '0' : '0px 16px'};
        height: ${props => props.smallFont ? '18px' : '32px'};
        border-bottom: ${props => props.smallFont ? '1px' : '2px'} solid ${
    props => props.isSelected 
        ? props.theme.color.primary 
        : 'transparent' };
    }


    &:hover {
        color: ${props => props.isSelected ? props.theme.color.primary : props.theme.color.black};
    }

    a {
        color: ${props => props.isSelected ? props.theme.color.primary : props.theme.color.anthracite};
        text-decoration: none;

        &:hover {
            color: ${props => props.isSelected ? props.theme.color.primary : props.theme.color.black};
        }
    }
`

const TabButton = styled.div`
    display: flex;
    padding: ${props => props.smallFont ? '0 8px' : '0px 16px'};
    height: ${props => props.smallFont ? '24px' : '32px'};
    cursor: ${props => props.isSelected ? 'default' : 'pointer'};
    font-size: ${props => props.smallFont ? '12px' : '16px'};
    background: ${props => props.isSelected ? props.theme.color.anthracite : props.theme.color.gray10};
    color: ${props => props.isSelected ? props.theme.color.white : props.theme.color.anthracite};
    align-items: center;
    font-weight: ${props => props.theme.fontWeight.standardRegular};

    &:hover {
        color: ${props => props.isSelected ? props.theme.color.white : props.theme.color.black};
        background: ${props => props.isSelected ?  props.theme.color.anthracite : props.theme.color.gray20};
    }
    &:first-child{
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    &:last-child{
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
`

const TabsItemLink = styled(NavLink)`
    display: flex;
    padding: 0px 16px;
    height: 32px;
    cursor: pointer;
    color: ${props => props.isSelected ? props.theme.color.primary : props.theme.color.anthracite};
    border-bottom: 1px solid ${props => props.isSelected ? props.theme.color.primary : 'transparent'};
    align-items: center;

    &:hover {
        color: ${props => props.isSelected ? props.theme.color.primary : props.theme.color.black};
    }
`

const PlaceForRightSide = styled.div`
    display: flex;
    flex-flow: row nowrap;
`

const WrapperForTheRightSide = styled.div`
    display: flex;
    height: ${props => props.smallFont ? '12px' : '32px'};
    align-items: center;
    flex-flow: row nowrap;
`

const NeedSpace = styled.div.attrs(props => ({
    noNeedSpace: props.noNeedSpace || false
}))`
    margin-right: ${props => props.noNeedSpace ? '0px' : '8px'};
`

export {
    Wrapper,
    TabsWrapper,
    TabsItem,
    TabsItemLink,
    TabButton,
    PlaceForRightSide,
    WrapperForTheRightSide,
    NeedSpace
}

