import { TASK_STATES } from 'application/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'

export const TaskDetailsContextMenu = (
    {
        task, 
        damageId, 
        damage, 
        onEditTask, 
        onDeleteTask
    }
) => {
    const {t} = useTranslation()

    return <FlexBox flexBasis='content'>
        {
            task.state !== TASK_STATES.DONE && <IconButtonWithLabel
                onButtonClick={
                    () => { 
                        onEditTask(
                            task, 
                            damageId, 
                            damage
                        ) 
                    }
                }
                label={t('Edit task')}
                iconKey='edit'
            />
        }
        {
            task.isDeletable && <IconButtonWithLabel
                onButtonClick={
                    () => { 
                        onDeleteTask(
                            {
                                ...task, 
                                damage: { id: damageId }
                            }
                        ) 
                    }
                }
                label={t('Delete task')}
                iconKey='delete_outline'
            />
        }
    </FlexBox>
}