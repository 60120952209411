import React from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { useTranslation } from 'react-i18next'
import { Paragraph } from 'application/components/fragments/typography'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { MoDialogFooter } from 'application/components/dialogs/mass_operations/_common/footer'
import { MoDialogStateTable } from '../../damages_overview/_common/state_table'
import { PushBottom16 } from 'application/components/pages/_common'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import ThemedPreloader from 'application/components/controls/themed_preloader'
import { withStatuslistLoader } from 'application/components/higher_order_components'
import { useDamagesGroupedByState } from '../_common/use_damages_grouped_by_state'
import { USER_PERMISSONS } from 'application/constants'
import InfoBox from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import {MoDialogTable} from 'application/components/dialogs/mass_operations/_common/tables'
import {tableConfiguration} from 'application/components/dialogs/mass_operations/_common/tables/configurations/damages'

const MoDamagesDeleteComponent = (
    {
        abort, 
        onDeletion,
        dialog,
        moName = '',
        selectedDamages = [],
        statusList,
        allPermissions
    }
) => {
    const { t } = useTranslation()
    const states = statusList.data

    const {
        stateGroups,
        filteredSelectedDamages,
        handleRemoveDamage,
        selectedDamagesCount,
        damagesSelected,
        unprocessableItems
    } = useDamagesGroupedByState(states, selectedDamages, allPermissions, [USER_PERMISSONS.DAMAGE.DELETE])


    if (statusList.loading || !statusList.loaded) {
        return <ThemedPreloader />
    }

    const handleDeletion = () => {
        const payload = {
            selectedItems: filteredSelectedDamages
        }
        const metaData = {
            moName
        }

        onDeletion(
            dialog.name,
            payload,
            metaData
        )
    }

    return  <React.Fragment>
        <DialogMainContent>
            <Paragraph>
                {   damagesSelected 
                    ?
                    <Trans  
                        i18nKey='Should_damages_be_deleted'
                    >
                        {{selectedDamagesCount}}
                    </Trans>
                    :
                    t('No damages selected')
                }
            </Paragraph>
            <PushBottom16/>
            <Paragraph />
            {
                stateGroups.map( 
                    (stateGroup, i) => {      
                        return <MoDialogStateTable
                            key={i}
                            removeDamage={handleRemoveDamage}
                            state={stateGroup.state}
                            items={stateGroup.damages}
                        />
                    }
                )
            }
            {
                unprocessableItems.length > 0 && <React.Fragment>
                    <InfoBox
                        type={INFOBOX.TYPES.WARNING} 
                        direction='row' 
                        cssMargin='0' 
                    >
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        {t('The following items exist in branches in which this user is missing the delete permission')}
                        <Spacer />
                    </InfoBox>      
                    <MoDialogTable
                        removeElement={handleRemoveDamage}
                        tableConfig={tableConfiguration}
                        elements={unprocessableItems}
                        disabled={true}
                        alert={true}
                    />
                </React.Fragment> 
            }
            <Paragraph />
        </DialogMainContent>
        <MoDialogFooter 
            onCloseDialog={abort} 
            onSubmit={handleDeletion}
            submitButtonText={ t('Delete') }
            submitDisabled={  !damagesSelected || selectedDamagesCount < 1 }
        />
    </React.Fragment>
}

MoDamagesDeleteComponent.propTypes = {
    abort: PropTypes.func.isRequired,
    onDeletion: PropTypes.func.isRequired,
    dialog: PropTypes.object.isRequired,
    moName: PropTypes.string,
    selectedDamages: PropTypes.array,
    statusList: PropTypes.object
}

export const MoDamagesDeleteC =  withDialogDataLoader(
    withStatuslistLoader(MoDamagesDeleteComponent)
)