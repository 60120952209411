import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'

import DamageManagerTitleBar from './component'

const mapStateToProps = (state) => {
    return {
        vehicle: state.pages.damageManager.shared.damage.vehicle,
        damageLoaded: state.pages.damageManager.shared.damageLoaded,
        damageLoading: state.pages.damageManager.shared.damageLoading,
        damageId: state.pages.damageManager.shared.damage.id,
        hasLawfirm: state.pages.damageManager.shared.hasLawfirm,
        lawfirmLoaded: state.pages.damageManager.shared.lawfirmLoaded,
        currentBranchId: state.auth.currentBranch?.id,
        hasMultipleBranches: state.auth.hasMultipleBranches,
        branchId: state.pages.damageManager.shared.damage.branchId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openDialogCreateLawyers: ( 
            damageId, 
            changeMode
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_LAWYERS, 
                false, 
                { 
                    lawyersList: [], 
                    lawyersListLoading: false, 
                    changeMode, 
                    damageId, 
                    lawfirm: null
                }, 
                'Commission lawfirm'
            )
        ),
        onOpenVehicleAndKeeperQRCodeCreationDialog: (
            payload
        ) => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_VEHICLE_AND_KEEPER_QR_CODE, 
                false, 
                payload, 
                'Create QR Code for vehiclekeeper'
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerTitleBar)