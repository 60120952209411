import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { takeLatest, put } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { setArchiveDownloading } from 'application/redux/actions/pages/damage_manager/documents'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* downloadDamageDocumentsSaga(action) {
    try {
        yield put(setArchiveDownloading(true))
        yield resourceCall(
            api.gateway.attachments.downloadDamageDocumentsArchive,
            {
                damageId: action.damageId
            }
        )
        yield put(setArchiveDownloading(false))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* downloadDamageDocumentsWatcher() {
    yield takeLatest(ACTIONS.DOCUMENTS_DOWNLOAD_DAMAGE_DOCUMENTS, downloadDamageDocumentsSaga)
}