import { getWidgetContextMeta } from './get_widget_context_meta'

export const widgetBoardConfigStore = widgetContextName => {
    const { storage, defaults, boardConfiguration } = getWidgetContextMeta(widgetContextName)

    return {
        store: (newBoardConfiguration) => {
            localStorage.removeItem(storage.boardConfiguration)
            localStorage.setItem(storage.boardConfiguration, JSON.stringify({boardConfiguration: newBoardConfiguration}))
        },
        get: () => {
            if(storage === null){
                return defaults.boardConfiguration
            }

            const storageEntry = localStorage.getItem(storage.boardConfiguration)
            return storageEntry === null ? boardConfiguration : {
                ...(JSON.parse(storageEntry)).boardConfiguration
            }
        },
        delete: () => {
            localStorage.removeItem(storage.layout)
        }
    }
}