import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import WidgetAreaEffects from './effects'
import { registerWidget } from 'application/redux/actions/widgets'

const mapStateToProps = (state, props) => {
    
    const hasContext = state.widgetContexts.contexts.filter(
        c => c.key === props.widgetContext
    ).length > 0

    const context = state.widgetContexts.contexts.find(
        c => c.key === props.widgetContext
    )
    
    return {
        hasContext,
        widgetList: hasContext ? context.widgetList: [],   
        registeredWidgets: state.widgets.registeredWidgets
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRegisterWidgets: (widgetContext, widgetList) => {
            for(const widget of widgetList){
                dispatch(
                    registerWidget(
                        widget.key, 
                        widget.type, 
                        widgetContext, 
                        widget.title, 
                        widget.initialPayload, 
                        widget.hideHeadline, 
                        widget.accountDependent, 
                        widget.dependentWidgetKeys, 
                        widget.dependsOnOtherWidget
                    )
                )
            }
        }
    }
}

const WidgetAreaContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(WidgetAreaEffects)

WidgetAreaContainer.propTypes = {
    widgetContext: PropTypes.string.isRequired
}

export default WidgetAreaContainer