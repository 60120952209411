import { WIDGET_TYPES } from 'application/constants'

const KEYS = {
    DAMAGE_MANAGER_VEHICLE_OWNER: 'DAMAGE_MANAGER_VEHICLE_OWNER',
    DAMAGE_MANAGER_VEHICLE: 'DAMAGE_MANAGER_VEHICLE',
    DAMAGE_MANAGER_INSURANCES: 'DAMAGE_MANAGER_INSURANCES',
    DAMAGE_MANAGER_REPORT: 'DAMAGE_MANAGER_REPORT',
    DAMAGE_MANAGER_ACCIDENT_REPORT: 'DAMAGE_MANAGER_ACCIDENT_REPORT',
    DAMAGE_MANAGER_CASE_NOTES: 'DAMAGE_MANAGER_CASE_NOTES',
}

export const layoutDamageManagerOverview= {
    LARGE: [
        {i: KEYS.DAMAGE_MANAGER_VEHICLE_OWNER, x: 0, y: 0, w: 2, h: 4},
        {i: KEYS.DAMAGE_MANAGER_VEHICLE, x: 2, y: 0, w: 2, h: 4},
        {i: KEYS.DAMAGE_MANAGER_INSURANCES, x: 4, y: 0, w: 2, h: 4},
        {i: KEYS.DAMAGE_MANAGER_REPORT, x: 0, y: 4, w: 3, h: 5},
        {i: KEYS.DAMAGE_MANAGER_ACCIDENT_REPORT, x: 3, y: 4, w: 3, h: 5},
        {i: KEYS.DAMAGE_MANAGER_CASE_NOTES, x: 0, y: 9, w: 6, h: 3},
    ]
}

export const widgetsDamageManagerOverview = [
    {
        key: KEYS.DAMAGE_MANAGER_VEHICLE_OWNER,
        type: WIDGET_TYPES.DAMAGE_VEHICLE_OWNER,
        title: 'Customer / owner',
        hideHeadline: true,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_VEHICLE,
        type: WIDGET_TYPES.DAMAGE_VEHICLE,
        title: 'Vehicle',
        hideHeadline: false,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_INSURANCES,
        type: WIDGET_TYPES.DAMAGE_INSURANCES,
        title: 'Insurances',
        hideHeadline: true,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_REPORT,
        type: WIDGET_TYPES.DAMAGE_REPORT,
        title: 'Damage Report',
        hideHeadline: true,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_ACCIDENT_REPORT,
        type: WIDGET_TYPES.DAMAGE_ACCIDENT_REPORT,
        title: 'Accident report',
        hideHeadline: true,
        accountDependent: true
    },
    {
        key: KEYS.DAMAGE_MANAGER_CASE_NOTES,
        type: WIDGET_TYPES.DAMAGE_CASE_NOTES,
        title: 'Case notes',
        hideHeadline: true,
        isBlank: true,
        accountDependent: true
    },
]