import { exists } from 'application/common/data_helpers'
import styled from 'styled-components'

export const PageHeadline = styled.h1`
    font-size: ${props => props.theme.fontSize.headline1};
    font-weight: ${props => props.theme.fontWeight.headline1};
    color: ${ props => props.theme.color.anthracite};
    margin-top: 0;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'};
    align-items: ${props => props.cssAlign ? props.cssAlign : 'center'};
    justify-content: ${props => exists(props.justifyContent) ? props.justifyContent : 'flex-start'};
    flex: 0 0 auto;
    height: 28px;
    @media (max-width: ${props => props.theme.breakpoint.xLarge}px) {
        font-size: ${props => props.theme.fontSize.headline3};
    }
`

export const HeadlineGeneric = styled.span`
    color: ${props => props.$color !== undefined ? props.$color : props.theme.color.anthracite};
`