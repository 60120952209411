import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { ROUTES } from 'application/constants'
import * as Pages from 'application/components/pages'
import { AuthRedirect } from './auth_redirect'

const Routes = () => {
    return <Switch>
        <Route exact path={ROUTES.EXT_LOGIN} component={Pages.External.WelcomeLogin} />
        <Route exact path={ROUTES.SESSION_EXPIRED} render={Pages.Errors.Internal.SessionExpiredPage} />
        <Route exact path={ROUTES.NO_PERMISSION} component={Pages.Errors.Internal.NoPermissionsPage} />
        <Route exact path={ROUTES.NO_ACCOUNTS} component={Pages.Errors.Internal.NoAccountsPage} />
        <Route exact path={ROUTES.SSO_CALLBACK} component={Pages.External.Callback} />
        <Route exact path={ROUTES.SSO_SILENT_RENEW} component={Pages.External.SilentRenew} />
        <Route exact path={ROUTES.LOAD} component={Pages.External.Load} />
        <Route exact path={ROUTES.LEGACY_DASHBOARD} render={()=><Redirect to={ROUTES.PREFERRED_ACCOUNT_PRELOADER} />} />
        <Route exact path={ROUTES.PREFERRED_ACCOUNT_PRELOADER} render={() => <AuthRedirect><Pages.Internal.PreferredAccountPreloader/></AuthRedirect>} />
        <Route exact path={ROUTES.CHOOSE_ACCOUNT} render={() => <AuthRedirect><Pages.Internal.ChooseAccount /></AuthRedirect>} />
        <Route exact path={ROUTES.ACCOUNT_PRELOADER} render={() => <AuthRedirect><Pages.Internal.AccountPreloader /></AuthRedirect>} />
        <Route exact path={ROUTES.DASHBOARD} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.Dashboard /></AuthRedirect>} />
        <Route exact path={ROUTES.DAMAGES_OVERVIEW} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.DamagesOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.DAMAGES_OVERVIEW_MY_DAMAGES} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.DamagesOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.DamagesOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.DAMAGES_OVERVIEW_ARCHIVED_DAMAGES} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.DamagesOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.TASKS_OVERVIEW} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.TasksOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.TASKS_OVERVIEW_MY_TASKS} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.TasksOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.TASKS_OVERVIEW_ALL_TASKS} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.TasksOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.ALL_BRANCHES_INBOX} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.Inbox /></AuthRedirect>} />
        <Route exact path={ROUTES.INBOX_PRESELECTED} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.Inbox /></AuthRedirect>} />
        <Route exact path={ROUTES.ACTIVITY_LOG} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.ActivityLog /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_ACTIVITY_LOG} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.ActivityLog /></AuthRedirect>} />
        <Route exact path={ROUTES.CONTROLLING} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.Controlling /></AuthRedirect>} />
        <Route exact path={ROUTES.CONTROLLING_INVOICES} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.Controlling /></AuthRedirect>} />
        <Route exact path={ROUTES.CONTROLLING_STATISTICS} render={() => <AuthRedirect><Pages.Internal.Branches.AllBranches.Controlling /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_DASHBOARD} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Dashboard /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.DamagesOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.DamagesOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.DamagesOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ARCHIVED_DAMAGES} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.DamagesOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.TasksOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.TasksOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_ALL_TASKS} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.TasksOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_INBOX} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Inbox /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_INBOX_PRESELECTED} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Inbox /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_CONTROLLING} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Controlling /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Controlling /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS} render={() => <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Controlling /></AuthRedirect>} />
        <Route exact path={ROUTES.DAMAGE_MANAGER} render={() => <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />
        <Route exact path={ROUTES.DAMAGE_MANAGER_WITH_SUBROUTES} render={() => <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />
        <Route exact path={ROUTES.DAMAGE_MANAGER_WITH_SUBROUTES_ID} render={() => <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />
        <Route exact path={ROUTES.ADMINISTRATION_USERS} render={() => <AuthRedirect><Pages.Internal.Users /></AuthRedirect>} />
        <Route exact path={ROUTES.ADMINISTRATION_SETTINGS} render={() => <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />
        <Route exact path={ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES} render={() => <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />
        <Route exact path={ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES} render={() => <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />
        <Route exact path={ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA} render={() => <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />
        <Route exact path={ROUTES.CUSTOMERS} render={() => <AuthRedirect><Pages.Internal.CustomersOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.CUSTOMERS_PRIVATE} render={() => <AuthRedirect><Pages.Internal.CustomersOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.CUSTOMERS_BUSINESS} render={() => <AuthRedirect><Pages.Internal.CustomersOverview /></AuthRedirect>} />
        <Route exact path={ROUTES.CHANGELOG} render={() => <AuthRedirect><Pages.Internal.Changelog /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_MANAGEMENT_GROUP_MEMBERS} render={() => <AuthRedirect><Pages.Internal.BranchManagementGroupMembers /></AuthRedirect>} />
        <Route exact path={ROUTES.BRANCH_MANAGEMENT} render={() => <AuthRedirect><Pages.Internal.BranchManagement /></AuthRedirect>} />
        <Route exact path={ROUTES.DAMAGE_NOT_FOUND}  render={()=> <AuthRedirect><Pages.Errors.Internal.DamageNotFoundPage /></AuthRedirect>} />
        <Route exact path={ROUTES.EXT_REGISTRATION_CALLBACK} component={Pages.External.RegistrationCallback} />
        <Route exact path={ROUTES.EXT_REGISTRATION} component={Pages.External.Registration} />
        <Route exact path={ROUTES.EXT_ACCOUNT_REGISTRATION_CALLBACK} component={Pages.External.AccountRegistrationCallback} />
        <Route exact path={ROUTES.EXT_ACCOUNT_REGISTRATION} component={Pages.External.AccountRegistration} />
        <Route exact path={ROUTES.SHARED_RESOURCE_INVALID} component={Pages.Errors.External.SharedResourceInvalid} />
        <Route exact path={ROUTES.EXT_SHARED_RESOURCE_LOGIN} component={Pages.External.SharedResourceLogin} />
        <Route exact path={ROUTES.EXT_SHARED_RESOURCE_SWITCH} component={Pages.External.SharedResourceTypeSwitch} />
        <Route exact path={ROUTES.EXT_CREATE_DAMAGE} component={Pages.External.CreateDamage} />
        <Route exact path={ROUTES.EXT_CREATE_DAMAGE_KICKSTART} component={Pages.External.CreateDamageKickstart} />
        <Route exact path={ROUTES.EXT_WORKFLOW_PANEL} component={Pages.External.Lawfirm} />
        <Route exact path={ROUTES.EXT_WORKFLOW_PANEL_WITH_SUBROUTES} component={Pages.External.Lawfirm}/>
        <Route exact path={ROUTES.EXT_WORKFLOW_PANEL_WITH_SUBROUTES_ID} component={Pages.External.Lawfirm} />
        <Route exact path={ROUTES.EXT_DAMAGE_TRACKING} component={Pages.External.DamageTracking} />
        <Route exact path={ROUTES.EXT_SERVICE_REQUEST} component={Pages.External.CreateServiceRequest} />
        <Route exact path={ROUTES.EXT_NOT_FOUND} component={Pages.Errors.External.NotFoundPage} />
        <Route exact path={ROUTES.GENERIC_ERROR} component={Pages.Errors.External.GenericError} />
        <Route exact component={Pages.Errors.External.NotFoundPage} />
    </Switch>
}

export default Routes