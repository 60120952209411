import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextInput, DatePicker } from 'application/components/controls/form'
import YesNoSelect from 'application/components/controls/form/yes_no_select'
import useStatefulForm from 'application/components/hooks/use_stateful_form'
import TwoColumns from 'application/components/forms/_common'
import { DAMAGE_TYPE, DIALOG } from 'application/constants'
import InfoBox from 'application/components/controls/info_box'
import { ActionButton, FontIcon } from '@ec/ui-controls-react'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { TextButton } from 'application/components/fragments/typography'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { FlexBox } from 'application/components/fragments/flex_box'


export const CreateDamageStepComponent = (
    { 
        //from component
        abort, 
        dialog,
        onDataChange,
        getStepsState,
        goToNextStep,
        //from container
        onCreateDamage, 
        onSearchUser,
        currentBranch,
        onOpenChangeBranchDialog,
    }
) => {
    const defaultValues = {
        isCompany: false,
        type: DAMAGE_TYPE.UNKNOWN
    }

    const {t} = useTranslation()

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        setValue, 
        control, 
        formStateValues, 
        updateFormState
    } = useStatefulForm({ defaultValues })  

    const setForm = () => {
        onDataChange()
        updateFormState()
    }


    const createDamageAndContinue = (dialogName, payload) => {
        onCreateDamage(
            dialogName,
            payload
        )
        goToNextStep()
    }
    
    const createDamageAndCloseDialog = (payload) => {
        onCreateDamage(
            DIALOG.NAME.CREATE_DAMAGE_AND_CLOSE_DIALOG,
            payload
        )
    }

    const onSubmitSuccess = (continueWithNextStep) => {
        const newDamageData = {
            ...formStateValues, //include all values
            ...getValues()
        }
        continueWithNextStep 
            ? createDamageAndContinue(
                dialog.name,
                newDamageData
            )
            : createDamageAndCloseDialog(
                newDamageData
            )
    }

    const currentBranchName = currentBranch.name

    return <React.Fragment>
        <DialogMainContent >
            <PushBottom16/>
            <InfoBox 
                direction='row' 
                cssMargin='0' 
            >
                <FontIcon icon='info_outline' />
                <Spacer basis='10px' />
                <Trans i18nKey='This damage will be created in currentBranch'>
                    {{currentBranchName}} 
                </Trans>
                <Spacer />
                <TextButton  onClick={() => onOpenChangeBranchDialog(dialog.name)}>
                    &raquo;{t('Change branch')}
                </TextButton>
            </InfoBox>
            <PushBottom16/>
            

            <TwoColumns 
                columnLeft={ 
                    <TextInput 
                        label={t('License plate damaged person')}
                        name='licensePlate'
                        register={register}
                        validate={{notEmpty: validationMethods.notEmpty}}
                        error={errors.licensePlate}
                        onChange={setForm}
                    />
                }
                columnRight={
                    <DatePicker 
                        label={t('Damage date')}
                        name='incidentDate'
                        validate={{notEmpty: validationMethods.notEmpty}}
                        error={errors.incidentDate}
                        onDateChange={setForm}
                        control={control}
                        disabledDays={{after: new Date()}}
                    />
                }
            />

            <TwoColumns 
                columnLeft={ 
                    <YesNoSelect 
                        register={register}
                        setValue={setValue}
                        initialValue={formStateValues.isCompany}
                        name='isCompany'
                        label={t('Is vehicle owned by company?')}
                        onChange={setForm}
                    />
                }
            />
            
            {
                formStateValues.isCompany 
                    ? <TwoColumns 
                        columnLeft={
                            <TextInput 
                                label={t('Company name')}
                                name='companyName'
                                register={register}
                                validate={{notEmpty: validationMethods.notEmpty}}
                                error={errors.companyName}
                                onChange={setForm}
                            />
                        }
                    />

                    : <TwoColumns 
                        columnLeft={
                            <TextInput 
                                label={t('Firstname')}
                                name='firstName'
                                register={register}
                                validate={{notEmpty: validationMethods.notEmpty}}
                                error={errors.firstName}
                                onChange={setForm}
                            />
                        }
                        columnRight={
                            <TextInput 
                                label={t('Lastname')}
                                name='lastName'
                                register={register}
                                validate={{notEmpty: validationMethods.notEmpty}}
                                error={errors.lastName}
                                onChange={setForm}
                            />
                        }
                    /> 
            }

                
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={abort} 
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Complete the damage creation')} 
                onButtonClick={handleSubmit(() => onSubmitSuccess(false))} 
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Create damage and continue')} 
                onButtonClick={handleSubmit(() => onSubmitSuccess(true))} 
            />
        </StepsLayoutFooter>
    </React.Fragment>
}