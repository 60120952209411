import { getAccountLinkHref } from 'application/common/route_helpers'
import { 
    ROUTES,
    USER_PERMISSONS
} from 'application/constants'
import React from 'react'
import SideBarIconLink from 'application/components/building_blocks/main_navigation_sidebar/_common/icon_link'
import PropTypes from 'prop-types'
import { buildToolTip } from '../helper_functions'
import { useTranslation } from 'react-i18next'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'

const AdministrationAccordionClosed = (
    {
        match,
        primaryColor,
        primaryHoverColor,
        menuName=''
    }
) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <ForAnyBranchPermissions 
            permissions={[USER_PERMISSONS.USER.READ]} 
            allNeeded={false}
        >
            <SideBarIconLink 
                toolTip={ buildToolTip(menuName, 'User Administration', t) }
                to={ getAccountLinkHref(match, ROUTES.ADMINISTRATION_USERS) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                iconKey={'people'}
                isActive={
                    match.path === ROUTES.ADMINISTRATION_USERS 
                }
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions 
            permissions={[USER_PERMISSONS.CUSTOMER.READ]} 
            allNeeded={false}
        >
            <SideBarIconLink 
                toolTip={ buildToolTip(menuName, 'Customers', t) }
                to={ getAccountLinkHref(match, ROUTES.CUSTOMERS) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                iconKey={'face'}
                isActive={
                    match.path === ROUTES.CUSTOMERS 
                || match.path ===  ROUTES.CUSTOMERS_BUSINESS 
                || match.path ===  ROUTES.CUSTOMERS_PRIVATE
                }
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions 
            permissions={[
                USER_PERMISSONS.GROUP.READ,
                USER_PERMISSONS.ROLE.READ
            ]} 
            allNeeded={true}
        >
            <SideBarIconLink 
                toolTip={ buildToolTip(menuName, 'Branch management', t) }
                to={ getAccountLinkHref(match, ROUTES.BRANCH_MANAGEMENT) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                iconKey={'store'}
                isActive={
                    match.path === ROUTES.BRANCH_MANAGEMENT 
                }
            />
        </ForAnyBranchPermissions>
        <ForAnyBranchPermissions 
            permissions={[
                USER_PERMISSONS.ACCOUNT.UPDATE,
                {/* USER_PERMISSONS.DOCUMENT_CATEGORY.CREATE,
                USER_PERMISSONS.DOCUMENT_CATEGORY.UPDATE,
                USER_PERMISSONS.DOCUMENT_CATEGORY.DELETE,
                USER_PERMISSONS.DAMAGE_STATE.CREATE,
                USER_PERMISSONS.DAMAGE_STATE.UPDATE,
                USER_PERMISSONS.DAMAGE_STATE.DELETE */}
            ]} 
            allNeeded={false}
        >
            <SideBarIconLink 
                toolTip={ buildToolTip(menuName, 'Settings', t) }
                to={ getAccountLinkHref(match, ROUTES.ADMINISTRATION_SETTINGS) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                iconKey={'tune'}
                isActive={
                    match.path === ROUTES.ADMINISTRATION_SETTINGS
                || match.path === ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES
                || match.path === ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES
                || match.path === ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA
                }
            />
        </ForAnyBranchPermissions>
        <SideBarIconLink 
            toolTip={ buildToolTip(menuName, 'Whats`s new?', t) }
            to={ getAccountLinkHref(match, ROUTES.CHANGELOG) }
            primaryColor={primaryColor}
            hoverBackground={primaryHoverColor}
            iconKey={'cached'}
            isActive={
                match.path === ROUTES.CHANGELOG
            }
        />
    </React.Fragment>

}




AdministrationAccordionClosed.propTypes = {
    match: PropTypes.object.isRequired,
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired,
    menuName: PropTypes.string
}

export default AdministrationAccordionClosed


