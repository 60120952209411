import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FlexBox } from 'application/components/fragments/flex_box'
import {MappedStepIcon} from 'application/components/building_blocks/steps_bar/step/mapped_step_icon'
import {MappedStepLabel} from 'application/components/building_blocks/steps_bar/step/mapped_step_label'

const StyledLine = styled.div`
flex: 1;
height: 21px;
border-bottom: 1px solid #a6a6a6;
margin: 0 8px;
`



const StepComponent = (
    {
        state,
        isLast = false,
        label = '',
        i = 0
    }
) => {

    const numbering  = i + 1
    const flexGrow = isLast ? '0' : '3'

    return  <FlexBox 
        flexGrow={flexGrow} 
        width='auto'
    >
        <MappedStepIcon 
            state={state} 
            numbering={numbering} 
        />
        <FlexBox 
            alignItems='center' 
            width='auto' 
        >
            <MappedStepLabel 
                state={state} 
                labelText={label}
            />
        </FlexBox>
        {
            !isLast && <StyledLine/>
        }
    </FlexBox>
}

StepComponent.propTypes = {
    state: PropTypes.number.isRequired,
    isLast: PropTypes.bool,
    label: PropTypes.string,
    i: PropTypes.number,
}

export const Step = StepComponent
