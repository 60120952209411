  
import React, {useEffect} from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { CallbackComponent } from 'redux-oidc'
import { useTranslation } from 'react-i18next'

import { defaultUserManager } from 'application/common/user_manager'
import { GlobalStyle } from 'application/style'
import Preloader from 'application/components/controls/themed_preloader'
import { ROUTES } from 'application/constants'

const StyledMessage = styled.div`
  text-align: center;
  width: 100%;
  padding-top: 40px;

`

export default ({onConnectUser, userConnected}) => {
    const history = useHistory()
    const { t } = useTranslation()

    useEffect(() => {
        userConnected && history.push(ROUTES.CHOOSE_ACCOUNT)
    }, [history, userConnected])
    
    return (
        <CallbackComponent
            userManager={defaultUserManager}
            successCallback={(user) => {
                onConnectUser(user.state.sharedResourceId, user.access_token, user.id_token)
            }}
            errorCallback={error => {
                console.error(error)
                history.push('/')
            }}
        >
            <React.Fragment>
                <GlobalStyle />
                <StyledMessage>
                    <Preloader preloaderText={t('Your user is being activated') + '...'}  />
                </StyledMessage>
            </React.Fragment>
        </CallbackComponent>
    )
}