import React from 'react'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'
import ThemedPreloader from 'application/components/controls/themed_preloader'

export const ForActiveBranchComponent = ({branchActive, children, simplePreloader = false}) => {
    return <React.Fragment>
        { 
            branchActive
                ? children
                : simplePreloader
                    ? <ThemedPreloader />
                    : <PagePreloader text='loading branch' />
        }
    </React.Fragment>
}

export default ForActiveBranchComponent