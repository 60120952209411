import React from 'react'
import { StyledTableHeader, StyledTableCell } from './fragments'
import { useTranslation } from 'react-i18next'
import TableHeaderLink from 'application/components/controls/table_header_link'
import { TableCell } from './'
import { exists, isDefined } from 'application/common/data_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'
export const TableHeaderRow = (
    {   
        sorting,
        columns,
        configuration
    }
) => {
    const {t} = useTranslation()
    const contextMenuWidth = configuration.contextMenuWidth || 0
    const flexibleCellContainerWidth = `calc(100% - ${contextMenuWidth})`

    return <StyledTableHeader>
        <FlexBox flexBasis={flexibleCellContainerWidth} flexGrow='0' flexShrink='0'>
            {
                columns.map(
                    (thcell, i) => {
                        return <TableCell
                            key={i}
                            column={thcell} 
                            configuration={configuration} 
                        >
                            {
                                thcell.hasSorting 
                                    ? <TableHeaderLink 
                                        {...sorting}
                                        sortParamValue={thcell.sortParamValue}
                                    >
                                        {t(thcell.label)}
                                    </TableHeaderLink>
                                
                                    : t(thcell.label)
                            }
                            {
                                isDefined(thcell.secondLabel) && <React.Fragment>
                                    <br />
                                    { t(thcell.secondLabel) }
                                </React.Fragment>
                            }
                        </TableCell>
                    }
                )
            }
        </FlexBox>
        {
            exists(configuration.ContextMenu) && <StyledTableCell flexGrow='0' cssWidth={`${configuration.contextMenuWidth}`} cssMaxWidth={`${configuration.contextMenuWidth}`} />
        }
    </StyledTableHeader>
}