import React, {useEffect} from 'react'
import { ROUTES } from 'application/constants'
import ChooseAccount from './component'
import { Redirect } from 'react-router'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'
import { VisibilitySwitch } from 'application/components/pages/_common'

const ChooseAccountEffects = (props) => {
    const   {
        accountsFound,
        permissionsFound,
        unLoadCurrentAccount,
        accountsTotal,
        firstAccountId,
        firstAccountLocked,
        onLoadPreferredAccount,
        preferredAccount,
        accountsPerPage,
        accountsList,
        onLoadAccounts,
        accountsLoaded
    } = props

    useEffect(
        () => { 
            unLoadCurrentAccount()
        }, [unLoadCurrentAccount]
    )

    useEffect(
        () => { 
            !preferredAccount.loaded && onLoadPreferredAccount()
        }, [
            onLoadPreferredAccount,
            preferredAccount.loaded
        ]
    )

    //needed !?
    if (!permissionsFound && !accountsFound) {
        return <Redirect to={ROUTES.NO_PERMISSION} />
    }

    if(accountsFound && accountsTotal === 1 && !firstAccountLocked){
        return <Redirect to={
            getAccountLinkHref(
                {
                    params:{
                        accountId: firstAccountId
                    }
                }, 
                ROUTES.ACCOUNT_PRELOADER
            )
        } />
    }

    return <React.Fragment>
        <VisibilitySwitch hidden={accountsLoaded}>
            <PagePreloader text='Loading accounts' />
        </VisibilitySwitch>
        <VisibilitySwitch hidden={!accountsLoaded}>
            <ChooseAccount 
                {...props} 
                accountsPerPage={accountsPerPage}
                preferredAccount={preferredAccount}
                accounts={accountsList}
                onLoadAccounts={onLoadAccounts}
            /> 
        </VisibilitySwitch>
    </React.Fragment>
}

export default ChooseAccountEffects