import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'
import DateComponent from 'application/components/controls/date'

const BoxDeadline = styled.div`
  height: 24px;
  padding: 0px 6px;
  background-color: ${props =>
        props.deadlineClose || props.overTheDeadline ? props.theme.color.red : props.theme.color.gray70};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.color.white};
  font-size: ${props => props.theme.fontSize.small};
  font-weight: ${props => props.theme.fontWeight.medium18};
  border-radius: 2px;
`

export const DeadlineBox = ({ deadlineFromTask }) => {
    const today = moment(new Date())
    const deadline = moment(deadlineFromTask)
    const dateDiff = deadline.diff(today, 'days')
    const amountDayToTheDeadline = process.env.REACT_APP_AMOUNT_DAY_CLOSE_TO_THE_DEADLINE
    const deadlineClose = dateDiff < amountDayToTheDeadline
    const isOverTheDeadline = dateDiff < 0
    
    return deadlineFromTask !== null && deadlineFromTask !== undefined && moment(deadlineFromTask).isValid()
        ? <BoxDeadline overTheDeadline={isOverTheDeadline} deadlineClose={deadlineClose}>
            {deadlineFromTask && <DateComponent date={deadlineFromTask} />}
        </BoxDeadline>
        : <React.Fragment></React.Fragment>
}

DeadlineBox.propTypes = {
    isOverTheDeadline: PropTypes.bool,
    deadlineFromTask: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
}
