import { put } from 'redux-saga/effects'
import { hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { updateDocumentCenterAttachmentDetails } from 'application/redux/actions/pages/damage_manager/documents'
import { showAttachmentDetails } from 'application/redux/actions/pages/damage_manager/tasks'
import { notNull } from 'application/common/data_helpers'
import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogReExportToLawfirmFromDocumentSidebarSaga(action) {

    const {
        attachment
    } = action.payload

    const {
        damageId,
        taskId = null,
        attachmentId
    } = action.metaData

    yield resourceCall(
        api.gateway.attachments.updateAttachment, 
        {
            damageId,
            attachmentId
        }, 
        { 
            isExportedToLawFirm: false 
        }
    )

    yield resourceCall(
        api.gateway.attachments.updateAttachment, 
        {
            damageId,
            attachmentId
        }, 
        { 
            isExportedToLawFirm: true 
        }
    )

 

    //UpdateAttachmentdetails
    yield put(
        updateDocumentCenterAttachmentDetails( attachment)
    )
    
    yield notNull(taskId) && put(
        showAttachmentDetails(
            attachment, 
            damageId, 
            taskId
        )
    )
    yield put(hideWaitingScreen())
}