import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { Paragraph } from 'application/components/fragments/typography'
import { FormatCurrencyDE } from 'application/common/currency_helpers'
import { Spacer } from 'application/components/pages/_common'
import { FlexBox } from 'application/components/fragments/flex_box'

const VacantPostionsInfo = (
    {
        unpaidTotal, 
        paidTotal, 
        theme
    }
) => {
    const valueTotalAmount = paidTotal + unpaidTotal
    const {t} = useTranslation()

    return <FlexBox flexDirection='column'>
        <FlexBox>
            <FlexBox width='auto' flexShrink='0'>
                <Paragraph>{t('Invoices total amount')}</Paragraph>
            </FlexBox>
            <FlexBox>
                <Spacer 
                    dotted 
                    cssMargin='0 4px 10px' 
                />
            </FlexBox>
            <FlexBox width='auto' flexShrink='0'>
                <Paragraph>
                    {FormatCurrencyDE(valueTotalAmount)}
                </Paragraph> 
            </FlexBox>       
        </FlexBox>

        <FlexBox>
            <FlexBox width='auto' flexShrink='0'>
                <Paragraph fontColor={theme.color.success}>
                    {t('Paid off')}
                </Paragraph>
            </FlexBox>
            <FlexBox>
                <Spacer 
                    dotted 
                    cssMargin='0 4px 10px' 
                    borderColor={theme.color.success}
                />
            </FlexBox>
            <FlexBox width='auto' flexShrink='0'>
                <Paragraph fontColor={theme.color.success}>
                    {FormatCurrencyDE(paidTotal)}
                </Paragraph>
            </FlexBox>
        </FlexBox>

        <FlexBox>
            <FlexBox width='auto' flexShrink='0'>
                <Paragraph fontColor={theme.color.error}>
                    {t('Vacant positions')}
                </Paragraph>
            </FlexBox>
            <FlexBox>
                <Spacer 
                    dotted 
                    cssMargin='0 4px 10px'
                    borderColor={theme.color.error}
                />
            </FlexBox>
            <FlexBox width='auto' flexShrink='0'>
                <Paragraph fontColor={theme.color.error}>
                    {FormatCurrencyDE(unpaidTotal)}
                </Paragraph>
            </FlexBox>
        </FlexBox> 

    </FlexBox>
}

export default withTheme(VacantPostionsInfo)