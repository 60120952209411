import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { INFOBOX } from 'application/constants'
import InfoBox from 'application/components/controls/info_box'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom16, PushBottom32, Spacer } from 'application/components/pages/_common'
import { Paragraph } from 'application/components/fragments/typography'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'





const DeleteMessage = (
    { 
        abort, 
        dialog, 
        onDeleteMessage 
    }
) => {
    const { t } = useTranslation()

    const dialogPayload = dialog.payload
    const subject = dialogPayload.subject
    const successMsg = t('Message') + ': ' + dialogPayload.subject + ' ' + t('has been successfully deleted')


    const handleSubmit = () => {       

        const metaData = {
            damageId: dialogPayload.damageId,
            messageId: dialogPayload.id, 
            successMsg
        }

        onDeleteMessage(
            dialog.name, 
            null,
            metaData
        )
    }


    return <React.Fragment>
        <DialogMainContent>
            <FlexBox 
                cssRules='min-height: 100px;'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
            >
                <Paragraph>
                    {t('Do you want to delete the following message?')}
                </Paragraph>
                <Paragraph>
                    <strong>{t('Subject')}: "{subject}"</strong>
                </Paragraph>
                <PushBottom16/>
                <InfoBox 
                    type={INFOBOX.TYPES.WARNING} 
                    direction='row' 
                    cssMargin='0' 
                >
                    <FontIcon icon='info_outline' />
                    <Spacer basis='10px' />
                    <span>
                        {t('Message related documents are not deleted. These can still be accessed via the claims manager')}
                    </span>
                    <Spacer />
                </InfoBox>
                <PushBottom32/>
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit}
            submitText='Delete message'
        />
    </React.Fragment>

}

export const DeleteMessageComponent = withDialogDataLoader(DeleteMessage)