import React from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROUTES } from 'application/constants'
import { LayoutDamageManager } from 'application/components/pages/_layout'
import * as Common from 'application/components/pages/_common'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { getAccountLinkHref } from 'application/common/route_helpers'

const DamageManager = (
    { 
        match, 
        damageLoading, 
        damageLoaded,
        hasLawfirm,
        damage, 
        children,
        sidebar = null,
        tabBarButtons = null,
        paddingRight = '16px',
        subRoute,
        titleSegments=[]
    }
) => {
    const { t } = useTranslation()

    const showLawfirmTab = damageLoaded && hasLawfirm

    const titleSegmentsWithLicensePlate = damageLoading  
        ? titleSegments
        : [damage?.vehicle?.licensePlate, ...titleSegments] 

    return <LayoutDamageManager titleSegments={titleSegmentsWithLicensePlate}>
        <Common.Container>
            <Common.Main paddingLeft='0' paddingRight={paddingRight}>
                <Common.VerticalContainer>
                    <TabsLinks
                        items={[
                            {
                                label: t('Overview'),
                                link: getAccountLinkHref(match, ROUTES.DAMAGE_MANAGER_BASE + match.params.damageId + '/overview'),
                                isActive: subRoute === 'overview'
                            },
                            {
                                label: t('Damage processing'),
                                link: getAccountLinkHref(match, ROUTES.DAMAGE_MANAGER_BASE + match.params.damageId + '/tasks'),
                                isActive: subRoute === 'tasks'
                            },
                            {
                                label: t('Documents & Pictures'),
                                link: getAccountLinkHref(match, ROUTES.DAMAGE_MANAGER_BASE + match.params.damageId + '/documents'),
                                isActive: subRoute === 'documents'
                            },
                            {
                                label: t('Messages'),
                                link: getAccountLinkHref(match, ROUTES.DAMAGE_MANAGER_BASE + match.params.damageId + '/messages'),
                                isActive: subRoute === 'messages'
                            },
                            {
                                label: t('Invoices'),
                                link: getAccountLinkHref(match, ROUTES.DAMAGE_MANAGER_BASE + match.params.damageId + '/invoices'),
                                isActive: subRoute === 'invoices'
                            },
                            {
                                label: t('Lawyer communication'),
                                link: getAccountLinkHref(match, ROUTES.DAMAGE_MANAGER_BASE + match.params.damageId + '/lawyercommunication'),
                                isActive: subRoute === 'lawyercommunication',
                                skip: !showLawfirmTab
                            }
                        ]}
                    >
                        { tabBarButtons }

                    </TabsLinks>

                    { children }

                </Common.VerticalContainer>
            </Common.Main>

            {
                sidebar !== null && <Common.Sidebar>
                    { sidebar }
                </Common.Sidebar>
            }
            
        </Common.Container>
    </LayoutDamageManager>
}

export default withRouter(DamageManager)