import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetSubHeadline, WidgetErrorMessage, WidgetInformation, WidgetText } from 'application/components/widgets/_common'
import Collapsable from 'application/components/controls/collapsable'
import LiabilityInquiryInfoName from 'application/components/name_mappings/liability_inquiry_info'
import LiabilityInquiryStatusName from 'application/components/name_mappings/liability_inquiry_status'

const DamageAKLiabilityRequest = (props) => {

    const {t} = useTranslation()

    const {
        payload, 
        loadingFailed, 
        isLoaded
    } = props

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }
    
    if(isLoaded && !payload.hasData){
        return <WidgetInformation>
            {t('No information regarding liability request yet')}
        </WidgetInformation>
    }

    const {data} = payload

    return <React.Fragment>
        <WidgetSubHeadline>
            {t('Liability request')}
        </WidgetSubHeadline>
        <Collapsable 
            initiallyCollapsed={false} 
            headline={t('Information')}
        >
            {
                data?.liabilityInquiry?.status && 
                <WidgetText>
                    {t('Liability type')}: <strong>
                        <LiabilityInquiryStatusName 
                            liabilityInquiryStatus={data?.liabilityInquiry?.status}
                        />
                    </strong>
                </WidgetText>
            }
            {
                data?.liabilityInquiry?.info && 
                <WidgetText>
                    {t('Liability request type')}: <strong>
                        <LiabilityInquiryInfoName 
                            liabilityInquiryInfo={data?.liabilityInquiry?.info} 
                        />
                    </strong>
                </WidgetText>
            }
            <br/>
            {
                data?.liabilityInquiry?.description && 
                <WidgetText>
                    {t('Comment')}: <strong>
                        {data?.liabilityInquiry?.description}
                    </strong>
                </WidgetText>
            }
        </Collapsable>      
    </React.Fragment>
}

DamageAKLiabilityRequest.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { DamageAKLiabilityRequest }