import { ACTIVITY_TYPE } from 'application/constants'

import { DefaultActivityInfo } from './default_activity'
import { PaymentCreatedActivity }from './payment/payment_created_activity'
import { DamageAssigneeChangedActivity } from './damage/damage_assignee_changed'
import { DamageCreatedActivity } from './damage/damage_created_activity'
import { DamageStateChangedActivity } from './damage/damage_state_changed_activity'
import { InvoiceCreatedActivity } from './invoice_created_activity'
import { DamageTypeChangedActivity } from './damage/damage_type_changed_activity'
import { DamageJobNumberChangedActivity } from './damage/damage_job_number_changed_activity'
import { PaymentCancelledActivity } from './payment/payment_cancelled_activity'
import { LawFirmCommunicationInitiatedActivity, LawFirmCommunicationAbortedActivity } from './lawfirm'
import { InvoiceDeletedActivity } from './invoice_deleted_activity'
import { DamageComprehensiveDamageTypeChangedActivity } from './damage/damage_comprehensive_damage_type_changed_activity'
import {TaskCreatedActivity, TaskDeletedActivity, StateChangedActivity, TaskCommentCreatedActivity} from './task'
import { VehicleUpdatedActivity, VehicleKeeperChangedActivity } from './vehicle'
import {DamageDescriptionChangedActivity, DamageIncidentDateChangedActivity, DamageNoteChangedActivity} from './damage'
import {DocumentCreatedActivity, DocumentDeletedActivity} from './document'
import {MessageCreatedActivity, MessageDeletedActivity} from './message'
import {ExpertCommissionDekraEstablishedActivity, ExpertCommissionDekraFailedActivity, ExpertCommissionDekraInitiatedActivity, ExpertCommissionOtherInitiatedActivity} from './expert_commission'


export const getActivityInfoByType = activityType => {
    switch (activityType) {     
    case ACTIVITY_TYPE.PAYMENT_CREATED: 
        return PaymentCreatedActivity
    case ACTIVITY_TYPE.PAYMENT_CANCELLED:
        return PaymentCancelledActivity
    case ACTIVITY_TYPE.DAMAGE_ASSIGNEE_CHANGED:
        return DamageAssigneeChangedActivity
    case ACTIVITY_TYPE.DAMAGE_CREATED:
        return DamageCreatedActivity
    case ACTIVITY_TYPE.DAMAGE_JOB_NUMBER_CHANGED:
        return DamageJobNumberChangedActivity
    case ACTIVITY_TYPE.DAMAGE_STATE_CHANGED:
        return DamageStateChangedActivity
    case ACTIVITY_TYPE.DAMAGE_TYPE_CHANGED:
        return DamageTypeChangedActivity
    case ACTIVITY_TYPE.COMPREHENSIVE_DAMAGE_TYPE_CHANGED: 
        return DamageComprehensiveDamageTypeChangedActivity
    case ACTIVITY_TYPE.DAMAGE_INCIDENT_DATE_CHANGED:
        return DamageIncidentDateChangedActivity
    case ACTIVITY_TYPE.DAMAGE_NOTE_CHANGED:
        return DamageNoteChangedActivity
    case ACTIVITY_TYPE.INVOICE_CREATED:
        return InvoiceCreatedActivity
    case ACTIVITY_TYPE.INVOICE_DELETED:
        return InvoiceDeletedActivity
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_INITIATED:
        return LawFirmCommunicationInitiatedActivity
    case ACTIVITY_TYPE.LAWFIRM_COMMUNICATION_ABORTED:
        return LawFirmCommunicationAbortedActivity
    case ACTIVITY_TYPE.TASK_CREATED:
        return TaskCreatedActivity
    case ACTIVITY_TYPE.TASK_DELETED: 
        return TaskDeletedActivity
    case ACTIVITY_TYPE.TASK_STATE_CHANGED:
        return StateChangedActivity
    case ACTIVITY_TYPE.TASK_COMMENT_CREATED:
        return TaskCommentCreatedActivity
    case ACTIVITY_TYPE.VEHICLE_UPDATED:
        return VehicleUpdatedActivity
    case ACTIVITY_TYPE.VEHICLE_KEEPER_CHANGED:
        return VehicleKeeperChangedActivity
    case ACTIVITY_TYPE.DAMAGE_DESCRIPTION_CHANGED:
        return DamageDescriptionChangedActivity
    case ACTIVITY_TYPE.DOCUMENT_CREATED:
        return DocumentCreatedActivity
    case ACTIVITY_TYPE.DOCUMENT_DELETED:
        return DocumentDeletedActivity
    case ACTIVITY_TYPE.MESSAGE_CREATED:
        return MessageCreatedActivity
    case ACTIVITY_TYPE.MESSAGE_DELETED:
        return MessageDeletedActivity
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_ESTABLISHED:
        return ExpertCommissionDekraEstablishedActivity
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_FAILED:
        return ExpertCommissionDekraFailedActivity
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_DEKRA_INITIATED:
        return ExpertCommissionDekraInitiatedActivity
    case ACTIVITY_TYPE.EXPERT_COMMUNICATION_OTHER_INITIATED:
        return ExpertCommissionOtherInitiatedActivity
    default:
        return DefaultActivityInfo
    }
}