import { connect } from 'react-redux'
import AddCustomStateComponent from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onAddCustomState: (dialogName, customStateName, systemStateId) => dispatch(sendPayloadRequest(dialogName, {customStateName}, {systemStateId}))
    }
}

export const AddCustomState = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCustomStateComponent)