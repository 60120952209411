import React from 'react'
import { FilterRadioCheckboxGroup }  from '../radio_checkbox_group'
import PropTypes from 'prop-types'
import * as filters from 'application/filters'
import { FILTERS } from 'application/constants/filters'


const ExternalFilterCheckboxGroup = (
    {
        pagination,
        disabled = false,
        spaceLeft = false
    }
) => {

    const TASKFILTERS = FILTERS.VALUES.TASKS
    const filter = filters.tasks.external

    const checkboxDefinitions = [
        {
            label: 'Internal & external tasks',
            value: TASKFILTERS.IS_EXTERNAL.ALL
        },
        {
            label: 'Only external tasks',
            value: TASKFILTERS.IS_EXTERNAL.TRUE
        }
    ]


    return <FilterRadioCheckboxGroup
        pagination={pagination}
        filter={filter}
        disabled={disabled}
        checkboxDefinitions={checkboxDefinitions}
        spaceLeft={spaceLeft}
    />                
}

ExternalFilterCheckboxGroup.propTypes = {
    pagination: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    spaceLeft: PropTypes.bool
}

export {ExternalFilterCheckboxGroup}