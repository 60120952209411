import React from 'react'
import {FlexBox} from 'application/components/fragments/flex_box.js'
import {MassOperationsSwitchWithLabel} from './switch'
import {MassOperationsInfoBox} from './info_box'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'



const StyledMoPanel = styled.div`
    display: flex;
    flex-direction: row;
    background: ${props => props.moIsActive ? props.theme.color.primaryBackground : 'transparent'};
    align-items: center;
    min-height: 100px;
    height: 100px;
    padding-left: 16px;
`


const MassOperationPanel = (
    {
        mo,
        onMoClearAllItemsByName,
        onMoSetInactiveByName,
        children,
        label='Multiselectionmode',
        itemsDesignation = 'elements',
        itemDesignation = 'element',
        moOpenAdditionalAction = () => {}
    }
) => {
    const {t} = useTranslation()

    const moIsActive = mo.isActive

    const handleMoClose = (moName) => {
        onMoClearAllItemsByName(moName)
        onMoSetInactiveByName(moName)
    }

    return <StyledMoPanel
        moIsActive={moIsActive}
    >
        <FlexBox 
            justifyContent='flex-end'
            alignItems='center'
            width='auto'
        >
            <MassOperationsSwitchWithLabel
                isActive={moIsActive}
                label={t(label)}
                moName={mo.moName}
                moOpenAdditionalAction={moOpenAdditionalAction}
            />
        </FlexBox>
        {
            moIsActive &&  <MassOperationsInfoBox 
                onMoClose={handleMoClose}
                mo={mo}
                selectedItems={mo.selectedItems}
                itemsDesignation={itemsDesignation}
                itemDesignation={itemDesignation}
            >
                {children}
            </MassOperationsInfoBox>
        }
    </StyledMoPanel>
}

MassOperationPanel.propTypes = {
    mo: PropTypes.object.isRequired,
    onMoClearAllItemsByName: PropTypes.func.isRequired,
    onMoSetInactiveByName: PropTypes.func.isRequired,
    children: PropTypes.object,
    label: PropTypes.string,
    itemsDesignation: PropTypes.string,
    itemDesignation: PropTypes.string,
    moOpenAdditionalAction: PropTypes.func
}


export { MassOperationPanel }