import { WIDGET_CONTEXTS } from 'application/constants'
import React, { useEffect } from 'react'

import DashboardContent from './component'

const DashboardContentEffects = (props) => {
    const { onLoadDashboard, ...rest } = props

    useEffect(() => {
        onLoadDashboard(WIDGET_CONTEXTS.DASHBOARD)
    }, [onLoadDashboard])

    return <DashboardContent {...rest} />
}

export default DashboardContentEffects