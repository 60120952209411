import React from 'react'
import { useTranslation } from 'react-i18next'
import { exists } from 'application/common/data_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Paragraph } from 'application/components/fragments/typography'
import { BulletList, BulletListItem } from 'application/components/widgets/_common'

const GroupConnectionsInfo = ({
    group
}) => {
    const {t} = useTranslation()

    if(!exists(group)){
        return null
    }

    return <FlexBox flexDirection='column'>

        <Paragraph>{t('Users in this group have the permissions')}:</Paragraph>

        <BulletList>
            {
                group.roles.length === 0
                    ? <BulletListItem>{t('no permissions connected')}</BulletListItem>
                    : group.roles.map((role, i) => <BulletListItem key={i}>{t(`role${role.name}`)}</BulletListItem>)
            }
        </BulletList>

        <Paragraph cssMargin='12px 0 8px'>{t('in these branches')}:</Paragraph>

        <BulletList>
            {
                group.canAccessAllBranches
                    ? <BulletListItem>{t('all branches')}</BulletListItem>
                    : <React.Fragment>
                        {
                            group.branches.length === 0
                                ? <BulletListItem>{t('no branches connected')}</BulletListItem>
                                : group.branches.map((branch, i) => <BulletListItem key={i}>{branch.name}</BulletListItem>)
                        }
                    </React.Fragment>
            }
        </BulletList>
        
    </FlexBox>
}

export default GroupConnectionsInfo