import React, { useEffect } from 'react'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'
import { Redirect, withRouter } from 'react-router-dom'
import { getAccountLinkHref, getBranchLinkHref } from 'application/common/route_helpers'
import { ROUTES } from 'application/constants'
import { exists } from 'application/common/data_helpers'
import * as storage from 'application/storage'

export default withRouter(({
    currentAccount,
    currentUser,
    currentAccountLoaded,
    currentAccountLoading,
    onLoadAccount,
    loggedIn,
    match
}) => {
    const {accountId} = match.params

    useEffect(() => {
        if(!currentAccountLoaded && !currentAccountLoading){
            storage.currentBranch.delete(accountId)
            onLoadAccount(accountId)
        }
    }, [accountId, currentAccountLoaded, currentAccountLoading, onLoadAccount])

    if(currentAccountLoaded && loggedIn){
        const defaultBranchId = exists(currentUser.preferredBranch) 
            ? currentUser.preferredBranch.id
            : currentAccount.branches[0]
        storage.currentBranch.store(accountId, defaultBranchId)

        if(currentAccount.branches.length === 1){
            return <Redirect to={getAccountLinkHref(match, ROUTES.DASHBOARD)} />
        }
        return <Redirect to={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_DASHBOARD, defaultBranchId)} />
    }

    return <PagePreloader text='loading company data' />
})