import { put, takeLeading, debounce } from 'redux-saga/effects'
import { ACTIONS, DIALOG } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { populateUsers, setUsersLoading } from 'application/redux/actions/common'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

const getApiEndPoint = (name) => {
    switch (name) {
    case DIALOG.NAME.CREATE_LAWYERS:
        return api.gateway.participants.getLawyers
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT:
        return api.gateway.users.getUsersList
    default:
        return api.gateway.users.getUsersList
    }
}

const getResponseName = (name, response) => {
    switch (name) {
    case DIALOG.NAME.CREATE_LAWYERS:
        return response.anwaltAccounts
    case DIALOG.NAME.COMMISSION_DEKRA_EXPERT:
        return response.users
    default:
        return response.users
    }
}

function* getUsersSaga(action) {
    try {
        yield put(setUsersLoading())
        const usersResponse = yield resourceCall(
            api.gateway.users.getUsersList, 
            {
                offset: action.offset, 
                limit: action.limit, 
                orderBy: action.orderBy, 
                direction: action.direction, 
                filterQuery: action.filterQuery
            }
        )
        yield put(populateUsers(usersResponse))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* getUsersForDialogsSaga(action) {
    try {
        yield put(updateDialogDataByName(action.dialogName, {usersList: [], usersListLoading: true}))
        const usersResponse = yield resourceCall(
            getApiEndPoint(action.dialogName), 
            {
                offset: action.offset, 
                limit: action.limit,
                orderBy: 'id',
                direction: 'desc',
                filterQuery: action.filterQuery
            }
        )
        yield put(updateDialogDataByName(action.dialogName, { usersList: getResponseName(action.dialogName, usersResponse), usersListLoading: false}))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* getUsersWatcher() {
    yield takeLeading(ACTIONS.COMMON_GET_USERS_REQUEST, getUsersSaga)
    yield debounce(1000, ACTIONS.COMMON_GET_USERS_REQUEST_DEBOUNCED, getUsersSaga)
    yield takeLeading(ACTIONS.COMMON_GET_USERS_FOR_DIALOG_REQUEST, getUsersForDialogsSaga)
    yield debounce(1000, ACTIONS.COMMON_GET_USERS_FOR_DIALOG_REQUEST_DEBOUNCED, getUsersForDialogsSaga)
}