
import { dataMappers } from 'application/api'
import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { sendInvitation } from 'application/redux/actions/pages/administration'
import { connect } from 'react-redux'

import UsersContextMenu from './context_menu'

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        onEditUser: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.EDIT_USER_FROM_LIST, 
                true, 
                dataMappers.importMappers.user.userFormDataMapper(payload), 
                'Edit user'
            )
        ),
        onDeleteUser: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.DELETE_USER, 
                false, 
                payload, 
                'Delete user'
            )
        ),
        onInviteUser: (userId) => dispatch(
            sendInvitation(userId)
        ),
        onEditUserGroups: (payload, dialogTitle) => dispatch(
            openDialogByName(
                DIALOG.NAME.EDIT_USER_GROUPS_FROM_LIST,
                false, 
                payload, 
                dialogTitle
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( UsersContextMenu )