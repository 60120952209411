import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { WidgetSubHeadline, WidgetErrorMessage, BulletList, BulletListItem, BulletListLabel, SubListItem } from 'application/components/widgets/_common'


const DamageVehicle = ({payload, loadingFailed}) => {
    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>{t('vehicle could not be loaded')}</WidgetErrorMessage>
    }
    
    return <React.Fragment>
        <WidgetSubHeadline>{payload.licensePlate}</WidgetSubHeadline>
        <BulletList>
            <BulletListItem><BulletListLabel cssWidth='80px'>{t('Make')}:</BulletListLabel>{payload.make}</BulletListItem>
            <BulletListItem><BulletListLabel cssWidth='80px'>{t('Model')}:</BulletListLabel>{payload.model}</BulletListItem>
            <BulletListItem><BulletListLabel cssWidth='80px'>{t('Chassis number')}:</BulletListLabel></BulletListItem>
            <SubListItem>{payload.chassisNumber}</SubListItem>
        </BulletList>
    </React.Fragment>
}

DamageVehicle.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { DamageVehicle }