import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import RepairClearanceTypeSelect from 'application/components/controls/form/repair_clearance_select'
import { validationMethods } from 'application/common'
import { useStatefulForm } from 'application/components/hooks'
import { FlexBox } from 'application/components/fragments/flex_box'

const LawfirmRepairClearanceForm = (
    { 
        formData = {}, 
        onSave
    }
) => {
    const {t} = useTranslation()
    
    const defaultValues = {
        repairAuthorization: formData
    }

    const { notEmpty } = validationMethods

    const { 
        register,
        handleSubmit, 
        setValue, 
        getValues, 
        errors,
        formStateValues,
        updateFormState
    } = useStatefulForm({ defaultValues })

    const onSubmitSuccess = () => { 
        onSave({
            ...formStateValues, //include all values
            ...getValues()
        })
    }

    return <FlexBox 
        flexDirection='column' 
        flexBasis='100%'
    >
        <FlexBox flexGrow='1'>
            <RepairClearanceTypeSelect
                error={errors.repairAuthorization}
                validate={{
                    notEmpty
                }}
                register={register}
                setValue={setValue}
                initialValue={formStateValues.repairAuthorization}
                onChange={updateFormState}
            />
        </FlexBox>
        <FlexBox 
            justifyContent='flex-end' 
            flexGrow='1'
        >
            <ActionButton 
                buttonText={t('Save repair clearance')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </FlexBox>
}

export { LawfirmRepairClearanceForm }