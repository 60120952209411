import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.pages.workflowPanel.tasks, action) => {
    switch (action.type) {
    case ACTIONS.WORKFLOW_PANEL_TASKS_REQUEST_TASKS: {
        return {
            ...state,
            tasksList: [],
            tasksLoaded: false,
            tasksLoading: true,
            selectedTask: null,
            selectedTaskLoading: false,
            selectedTaskLoaded: false
        }
    }   
    case ACTIONS.WORKFLOW_PANEL_TASKS_UPDATE_TASK_IN_LIST: {
        return {
            ...state,
            tasksList: state.tasksList.map(task => {
                return task.id === action.taskId ? {
                    ...task,
                    ...action.payload
                } : task
            }) 
        }
    }
    case ACTIONS.WORKFLOW_PANEL_TASKS_ADD_COMMENT_TO_TASK_IN_LIST: {
        return {
            ...state,
            tasksList: state.tasksList.map(task => {
                return task.id === action.taskId ? {
                    ...task,
                    comments: task.comments + 1
                } : task
            })
        }
    }
    case ACTIONS.WORKFLOW_PANEL_TASKS_ADD_ATTACHMENTS_TO_TASK_IN_LIST: {
        return {
            ...state,
            tasksList: state.tasksList.map(task => {
                return task.id === action.taskId ? {
                    ...task,
                    attachments: task.attachments + 1
                } : task
            })
        }
    }
    case ACTIONS.WORKFLOW_PANEL_TASKS_MINUS_ATTACHMENTS_TO_TASK_IN_LIST: {
        return {
            ...state,
            tasksList: state.tasksList.map(task => {
                return task.id === action.taskId ? {
                    ...task,
                    attachments: task.attachments - 1
                } : task
            })
        }
    }
    case ACTIONS.WORKFLOW_PANEL_TASKS_POPULATE_TASKS: {
        return {
            ...state,
            tasksList: action.tasksList,
            tasksLoaded: true,
            tasksLoading: false,
            taskLoadedAndNotFound: false,
        }
    }   
    case ACTIONS.WORKFLOW_PANEL_TASK_NOT_FOUND: {
        return {
            ...state,
            taskLoadedAndNotFound: true
        }
    }
    case ACTIONS.WORKFLOW_PANEL_TASKS_REQUEST_SELECTED_TASK: {
        return {
            ...state,
            selectedTask: null,
            selectedTaskDocuments: null,
            selectedTaskLoaded: false,
            selectedTaskLoading: true
        }
    }   
    case ACTIONS.WORKFLOW_PANEL_TASKS_POPULATE_SELECTED_TASK: {
        return {
            ...state,
            selectedTask: { ...action.task, taskWasDeleted: false },
            selectedTaskLoaded: true,
            selectedTaskLoading: false,
        }
    }   
    case ACTIONS.WORKFLOW_PANEL_TASKS_POPULATE_SELECTED_TASK_DOCUMENTS: {
        return {
            ...state,
            selectedTaskDocuments: {
                documents: action.documents,
                total: action.total
            }
        }
    }   
    case ACTIONS.WORKFLOW_PANEL_TASKS_UPDATE_SELECTED_TASK: {
        return {
            ...state,
            selectedTask: {
                ...state.selectedTask,
                ...action.task
            }
        }
    }
    case ACTIONS.WORKFLOW_PANEL_TASKS_LOAD_USERLIST_DEBOUNCED: {
        return {
            ...state,
            selectedTaskUserList: defaultStore.pages.damageManager.tasks.selectedTaskUserList,
            selectedTaskUserListLoading: true
        }
    } 
    case ACTIONS.WORKFLOW_PANEL_TASKS_POPULATE_USERLIST: {
        return {
            ...state,
            selectedTaskUserList: action.userList,
            selectedTaskUserListLoading: false
        }
    }  
    case ACTIONS.WORKFLOW_PANEL_TASKS_RESET_SELECTED_TASK: {
        return {
            ...state,
            selectedTask: null,
            selectedTaskLoaded: false,
            selectedTaskLoading: false,
            taskLoadedAndNotFound: false,
        }
    }
    case ACTIONS.WORKFLOW_PANEL_SHOW_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                visible: true,
                attachment: action.attachment,
                damageId: action.damageId,
                sharedResourceId: action.damageId,
                taskId: action.taskId
            }
        }
    }
    case ACTIONS.WORKFLOW_PANEL_HIDE_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                visible: false,
                attachmentId: null,
                attachmentName: '',
                damageId: null,
                sharedResourceId: null,
                taskId: null
            }
        }
    }  
    case ACTIONS.WORKFLOW_PANEL_TASKS_REMOVE_TASK_FROM_LIST: {
        return {
            ...state,
            tasksList: state.tasksList.filter(task => task.id !== action.taskId)
        }
    }
    default:
        return state
    }
}