import { takeLatest, put, debounce, takeLeading } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateTasks, populateSelectedTask, populateUsersForTasks, populateSelectedTaskDocuments } from 'application/redux/actions/pages/damage_manager/tasks'
import { ListFilter } from 'application/filters/filter_class'
import * as filters from 'application/filters'
import { updateTaskInList, updateSelectedTask } from 'application/redux/actions/pages/damage_manager/tasks'
import { updateSelectedTaskWorkflowPanel, updateTaskInListWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'
import { refreshTaskSaga } from '../../../post_processors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* damageManagerTasksSaga(action) {
    try {
        const { tasks } = yield resourceCall(
            api.gateway.tasks.getTasksByDamageId,
            {
                damageId: action.damageId
            }
        )
        yield put(
            populateTasks(
                tasks,
                action.damageId
            )
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* damageManagerTasksWatcher() {
    yield takeLatest(
        ACTIONS.DAMAGE_MANAGER_TASKS_REQUEST_TASKS,
        damageManagerTasksSaga
    )
}

function* damageManagerSelectedTaskSaga(action) {
    try {
        const taskResponse = yield resourceCall(
            api.gateway.tasks.getTaskById,
            {
                damageId: action.damageId,
                taskId: action.taskId
            }
        )
        yield put(
            populateSelectedTask(
                taskResponse
            )
        )

        if (taskResponse.documents.total > 0) {
            const documentsResponse = yield resourceCall(
                api.gateway.attachments.getAttachmentsByDamageId,
                {
                    damageId: action.damageId,
                    filterQuery: new ListFilter(filters.documents.parentTaskId).setValue(action.taskId).getQuery()
                }
            )

            yield put(
                populateSelectedTaskDocuments(
                    documentsResponse.documents,
                    documentsResponse.total
                )
            )
        } else {
            yield put(populateSelectedTaskDocuments([], 0))
        }
    } catch (e) {
        yield handleError(e)
    }
}

function* getUsersForTasksSaga(action) {
    try {
        const usersResponse = yield resourceCall(
            api.gateway.users.getUsersList,
            {
                offset: action.offset,
                limit: action.limit,
                orderBy: action.orderBy,
                direction: action.direction,
                filterQuery: action.filterQuery
            }
        )
        yield put(populateUsersForTasks(usersResponse))
    } catch (e) {
        yield handleError(e, action)
    }
}




export function* damageManagerSelectedTaskWatcher() {
    yield takeLatest(
        ACTIONS.DAMAGE_MANAGER_TASKS_REQUEST_SELECTED_TASK,
        damageManagerSelectedTaskSaga
    )
    yield debounce(
        1000,
        ACTIONS.DAMAGE_MANAGER_TASKS_LOAD_USERLIST_DEBOUNCED,
        getUsersForTasksSaga
    )
}

function* updateTaskSaga(action) {
    try {
        const response = yield resourceCall(
            api.gateway.tasks.updateTask,
            {
                damageId: action.damageId,
                taskId: action.taskId
            },
            action.taskData
        )
        yield put(
            updateTaskInList(
                action.taskId,
                response
            )
        )
        yield put(
            updateSelectedTask(
                {
                    ...response,
                    bearbeiter: response.bearbeiter
                }
            )
        ) //in case that new bearbeiter is undefined, still write the prop
    } catch (e) {
        yield handleError(e, action)
    }
}

function* updateTaskWorkflowPanelSaga(action) {
    try {
        const response = yield resourceCall(
            api.gateway.tasks.updateTask,
            {
                taskId: action.taskId
            },
            action.taskData
        )
        yield put(
            updateTaskInListWorkflowPanel(action.taskId, response)
        )
        yield put(
            updateSelectedTaskWorkflowPanel(response)
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateTaskWatcher() {
    yield takeLeading(
        ACTIONS.DAMAGE_MANAGER_UPDATE_TASK,
        updateTaskSaga
    )
    yield takeLeading(
        ACTIONS.DAMAGE_MANAGER_UPDATE_TASK_WORKFLOW_PANEL,
        updateTaskWorkflowPanelSaga
    )
}


function* assignTaskSaga(action) {
    try {
        yield resourceCall(
            api.gateway.tasks.assignTask,
            {
                damageId: action.damageId,
                taskId: action.taskId
            },
            {
                userId: action.userId
            }
        )
        yield refreshTaskSaga({
            metaData: {
                ...action
            }
        })
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* assignTaskWatcher() {
    yield takeLeading(
        ACTIONS.DAMAGE_MANAGER_ASSIGN_TASK,
        assignTaskSaga
    )
}


function* changeTaskStateSaga(action) {
    try {
        yield resourceCall(
            api.gateway.tasks.changeTaskState,
            {
                damageId: action.damageId,
                taskId: action.taskId,
            },
            {
                state: action.state
            }
        )
        yield refreshTaskSaga({
            metaData: {
                ...action
            }
        })
    } catch (e) {
        yield handleError(e, action)
    }
}


export function* changeTaskStateWatcher() {
    yield takeLeading(
        ACTIONS.DAMAGE_MANAGER_TASK_STATE,
        changeTaskStateSaga
    )
}