import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'
import { getWidgetPermissions } from 'application/widget_configuration/get_widget_permissions'

export default (state = defaultStore.widgets, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.widgets
    }
    case ACTIONS.WIDGET_REGISTER_WIDGET:
        return {
            ...state,
            registeredWidgets: state.registeredWidgets.some(w => w.key === action.key)
                ? state.registeredWidgets
                : [...state.registeredWidgets, {
                    key: action.key,
                    type: action.widgetType,
                    context: action.context,
                    title: action.title,
                    permissions: getWidgetPermissions(action.widgetType).permissions,
                    allPermissionsNeeded: getWidgetPermissions(action.widgetType).allNeeded,
                    permissionScope: getWidgetPermissions(action.widgetType).permissionScope,
                    payload: action.payload,
                    initialPayload: action.payload,
                    isLoaded: false,
                    isLoading: false,
                    loadingFailed: false,
                    hideHeadline: action.hideHeadline,
                    accountDependent: action.accountDependent,
                    dependentWidgetKeys: action.dependentWidgetKeys,
                    dependsOnOtherWidget: action.dependsOnOtherWidget
                }]
        }

    case ACTIONS.WIDGET_REQUEST_DATA:
        return {
            ...state,
            registeredWidgets: state.registeredWidgets.map(w => {
                return w.key === action.key ? {
                    ...w,
                    isLoaded: false,
                    isLoading: true,
                    loadingFailed: false
                } : w
            }) 
        }        
    case ACTIONS.WIDGET_UNREGISTER_WIDGET:
        return {
            ...state,
            registeredWidgets: state.registeredWidgets.filter(w => w.key !== action.key)
        }
    case ACTIONS.WIDGET_UNREGISTER_ALL_WIDGETS:
        return {
            ...state,
            registeredWidgets: []
        }
    case ACTIONS.WIDGET_UPDATE_CONTENT_BY_KEY:
        return {
            ...state,
            registeredWidgets: state.registeredWidgets.map(w => {
                return w.key === action.key || action.dependentWidgetKeys.some(dwk => dwk === w.key) ? {
                    ...w,
                    payload: {
                        ...w.payload,
                        ...action.payload
                    } 
                } : w
            }) 
        }
    case ACTIONS.WIDGET_CLEAR_CONTENT_BY_KEY:
        return {
            ...state,
            registeredWidgets: state.registeredWidgets.map(w => {
                return w.key === action.key ? {
                    ...w,
                    payload: {
                        ...w.initialPayload
                    } 
                } : w
            }) 
        }
    case ACTIONS.WIDGET_UPDATE_LOADED_STATE:
        return {
            ...state,
            registeredWidgets: state.registeredWidgets.map(w => {
                return w.key === action.key ? {
                    ...w,
                    isLoaded: action.isLoaded,
                    isLoading: action.isLoading,
                    loadingFailed: action.loadingFailed,
                    payload: w.payload
                } : w
            }) 
        }        

    //refreshing the widgets:
    case ACTIONS.WIDGET_REFRESH_WIDGET_BY_KEY:
        return {
            ...state,
            registeredWidgets: state.registeredWidgets.map(w => {
                return w.key === action.key ? {
                    ...w,
                    isLoaded: false,
                    isLoading: false,
                    loadingFailed: false
                } : w
            }) 
        }   
    case ACTIONS.WIDGET_REFRESH_WIDGETS_BY_CONTEXT:
        return {
            ...state,
            registeredWidgets: state.registeredWidgets.map(w => {
                return w.context === action.context ? {
                    ...w,
                    isLoaded: false,
                    isLoading: false,
                    loadingFailed: false
                } : w
            }) 
        }           
    case ACTIONS.WIDGET_REFRESH_WIDGETS_BY_TYPE:
        return {
            ...state,
            registeredWidgets: state.registeredWidgets.map(w => {
                return w.type === action.widgetType ? {
                    ...w,
                    isLoaded: false,
                    isLoading: false,
                    loadingFailed: false
                } : w
            }) 
        }                   
    default:
        return state
    }
}
