import { connect } from 'react-redux'
import EditTaskWorkflowPanelDataMapper from './data_mapper'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'


const mapStateToProps = state => state


const mapDispatchToProps = dispatch => {
    return {
        onSaveTask: (name, sharedResourceId, payload, previousTaskData) => dispatch(sendPayloadRequest(name, payload, { sharedResourceId, previousTaskData }))
    }
}


export const EditTaskWorkflowPanel =  connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTaskWorkflowPanelDataMapper)