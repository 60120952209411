import React from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { ROUTES } from 'application/constants'
import Tasks from './tasks'
import Overview from './overview'
import Documents from './documents'
import Inbox from './inbox'
import Invoices from './invoices'
import AnwaltKommunikation from './lawyer_communication'
import { getAccountLinkHref } from 'application/common/route_helpers'

const DamageManagerRoutes = ({ match }) => {

    if (match.params.subRoute === undefined) {
        return <Redirect to= {
            getAccountLinkHref(
                match, 
                ROUTES.DAMAGE_MANAGER_BASE + match.params.damageId + '/overview'
            )
        } />
    }

    return <Switch>
        <Route path={ROUTES.DAMAGE_MANAGER_OVERVIEW}>
            <Overview subRoute={match.params.subRoute} />
        </Route>
        <Route path={ROUTES.DAMAGE_MANAGER_TASKS_PRESELECTED}>
            <Tasks subRoute={match.params.subRoute} />
        </Route>
        <Route path={ROUTES.DAMAGE_MANAGER_TASKS}>
            <Tasks subRoute={match.params.subRoute} />
        </Route>
        <Route path={ROUTES.DAMAGE_MANAGER_DOCUMENTS}>
            <Documents subRoute={match.params.subRoute} />
        </Route>
        <Route path={ROUTES.DAMAGE_MANAGER_INBOX_PRESELECTED}>
            <Inbox subRoute={match.params.subRoute} />
        </Route>
        <Route path={ROUTES.DAMAGE_MANAGER_INBOX}>
            <Inbox subRoute={match.params.subRoute} />
        </Route>
        <Route path={ROUTES.DAMAGE_MANAGER_ANWALT_KOMMUNIKATION}>
            <AnwaltKommunikation subRoute={match.params.subRoute} />
        </Route>
        <Route path={ROUTES.DAMAGE_MANAGER_INVOICES}>
            <Invoices subRoute={match.params.subRoute} />
        </Route>
    </Switch>


}

export default withRouter(DamageManagerRoutes)