import {widgetLayoutStore} from './layout_storage'
import {widgetListStore} from './list_storage'
import {widgetBoardConfigStore} from './config_storage'

export const widgetContextBuilder = widgetContextName => {
    const boardConfiguration = widgetBoardConfigStore(widgetContextName).get()
    const widgetLayout = widgetLayoutStore(widgetContextName).get()
    const widgetList = widgetListStore(widgetContextName).get()
    
    return {
        key: widgetContextName,
        widgetLayout,
        widgetList,
        boardConfiguration
    }
}