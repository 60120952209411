import React from 'react'
import CustomersTable from 'application/components/tables/customers'
import { PushBottom32 } from 'application/components/pages/_common'
import { withCustomersLoader } from 'application/components/higher_order_components/with_customers_loader'

const CustomersContentComponent = ( 
    {
        customersList,
        customersListLoaded,
        customersListLoading,
        customersListTotal,
        updateConstraint,
        onLoadCustomersList
    }
) => {
    return <React.Fragment>
        <PushBottom32 />
        <CustomersTable
            customersList={customersList}
            customersListLoaded={customersListLoaded}
            customersListLoading={customersListLoading}
            customersListTotal={customersListTotal}
            onLoadCustomersList={onLoadCustomersList}
            updateConstraint={updateConstraint}
        />
        <PushBottom32 />
    </React.Fragment>     
}

export const CustomersContent = withCustomersLoader(
    CustomersContentComponent
)