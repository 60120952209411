import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { exists } from 'application/common/data_helpers'
import { useTranslation } from 'react-i18next'
import CurrencyDisplay from 'application/components/controls/currency_display'

export const ActivityDetailWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
`

export const ActivityDetailBox = styled.div`
    padding: 0px 6px;
    margin: 2px 4px 2px 0;
    height: 24px;
    border-radius: 2px;
    background-color: ${props => props.$primary ? props.theme.color.primaryBackground : props.theme.color.black10};
    color: ${props => props.theme.color.anthracite};
    display: flex;
    align-items: center;
    flex-shrink: 0;
`

export const ActivityDetail = (
    {
        label,
        value,
        isCurrency = false,
        title='',
        primary = false
    }
) => {
    const {t} = useTranslation()

    if(!exists(value)){
        return null
    }

    if(isCurrency){
        return <ActivityDetailBox title={title} $primary={primary}>
            <strong>{t(label)}</strong>: <CurrencyDisplay amount={value} />
        </ActivityDetailBox>
    }

    return <ActivityDetailBox title={title} $primary={primary}>
        <strong>{t(label)}</strong>: {value}
    </ActivityDetailBox>

}

ActivityDetail.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    title: PropTypes.string,
    isCurrency: PropTypes.bool,
    primary: PropTypes.bool
}