import React from 'react'
import { ROUTES } from 'application/constants'
import { IMAGES } from 'application/constants'
import LayoutNoAccount from 'application/components/pages/_layout/layout_no_account_container'
import { useTranslation } from 'react-i18next'
import { Paragraph } from 'application/components/fragments/typography'
import * as Common from 'application/components/pages/_common'
import Image from 'application/components/controls/image'
import { FlexBox } from 'application/components/fragments/flex_box'
import { getAccountLinkHref } from 'application/common/route_helpers'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { AccountsTable } from 'application/components/tables/accounts'

import { 
    StyledMessage, 
    StyledInfoHeadline, 
    StyledInfoContent, 
    StyledSuccessMessage, 
    StyledSuccessHeadline, 
    StyledSuccessContent, 
    StyledPreferredAccountLink, 
    StyledPreferredInfoButton 
} from './fragments'


const ChooseAccountPage = (
    {
        accounts, 
        accountsTotal,
        preferredAccount = null,
        onSetAsPreferredAccount,
        onUnsetAsPreferredAccount,
        onLoadAccounts
    }
) => {
    const {t} = useTranslation()

    const hasPreferredAccount = preferredAccount.loaded  && preferredAccount.found && preferredAccount.data !== null    
    const preferredAccountIsLocked = hasPreferredAccount && (preferredAccount.data.isLocked || preferredAccount.data.user.isLocked)

    const preferredAccountId = hasPreferredAccount
        ? preferredAccount.data.id 
        : ''


    return <LayoutNoAccount titleSegments={['Choose account']}>
        <Common.Container>
            <Common.Main 
                paddingLeft='0' 
                paddingRight='0'
            >
                <Common.VerticalContainer>
                    <FlexBox 
                        justifyContent='center'
                        paddingRight='16px'
                        cssRules='padding-bottom: 48px;'
                    >
                        <FlexBox 
                            cssRules='
                            min-width:700px; 
                            max-width: 1000px;
                        ' 
                            flexDirection='column'
                        >
                            <Common.PageAllBranchesHeadline 
                                justifyContent='flex-start' 
                                alignItems='flex-end'
                            >
                                <Image 
                                    data={IMAGES.LOGO_72} 
                                    cssMargin='0 16px 0 0' 
                                />
                                {t('Welcome to e.sy 360!')}
                            </Common.PageAllBranchesHeadline>
                            <Paragraph 
                                cssMargin='32px 0 8px'
                            >
                                {t('Please select a car dealer for your digital damage processing.')}<br/>
                                {t('You have the option, to mark a preferred account')} <br/>
                                {t('The forwarding will be activated if a preferred account is set')}
                            </Paragraph>
                            <Common.PushBottom24/>
                            {
                                !hasPreferredAccount  && <StyledMessage>

                                    <FlexBox 
                                        alignItems='center'
                                    >
                                        <i className='material-icons'>info</i>
                                        <StyledInfoHeadline>
                                            <strong>{t('No preferred account is set.')}</strong>
                                        </StyledInfoHeadline><br/>
                                    </FlexBox>
                                    <StyledInfoContent>
                                        <Paragraph>
                                            {t('You could set an preferred account by clicking on the star.')}
                                        </Paragraph>
                                    </StyledInfoContent>
                                </StyledMessage>
                            }
                            {
                                hasPreferredAccount && !preferredAccountIsLocked  && <StyledSuccessMessage>
                                    <FlexBox 
                                        alignItems='center'
                                    >
                                        <i className='material-icons'>info</i>
                                        <StyledSuccessHeadline>
                                            <strong>{t('A preferred account is set')}: </strong>({t('It will be displayed with a blue marking in the list.')})
                                        </StyledSuccessHeadline><br/>
                                    </FlexBox>
                                    
                                    <StyledSuccessContent>
                                        <FlexBox justifyContent='center'>
                                            <StyledPreferredAccountLink 
                                                to={
                                                    getAccountLinkHref(
                                                        {
                                                            params: {
                                                                accountId: preferredAccountId
                                                            }
                                                        }, 
                                                        ROUTES.ACCOUNT_PRELOADER
                                                    )
                                                }
                                            >
                                                {preferredAccount.data.name}
                                            </StyledPreferredAccountLink>
                                            <StyledPreferredInfoButton>
                                                <IconButtonWithLabel
                                                    iconKey={'star'} 
                                                    title={t('Unset as preferred')}
                                                    iconSize='22px'
                                                    fontSize='14px'
                                                    onButtonClick={onUnsetAsPreferredAccount}
                                                />
                                            </StyledPreferredInfoButton>
                                        </FlexBox>

                                    </StyledSuccessContent>
                                </StyledSuccessMessage>
                            }
                            <Common.PushBottom16/>
                            <AccountsTable 
                                accounts={accounts}
                                preferredAccountId={preferredAccountId}
                                onSetAsPreferredAccount={onSetAsPreferredAccount}
                                onUnsetAsPreferredAccount={onUnsetAsPreferredAccount}
                                onLoadAccounts={onLoadAccounts}
                                accountsTotal={accountsTotal}
                            />
                        </FlexBox>
                    </FlexBox>
                </Common.VerticalContainer>
            </Common.Main>
        </Common.Container>
    </LayoutNoAccount>
}

export default ChooseAccountPage