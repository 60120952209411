import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Option } from 'application/components/tables/_common/items_per_page_selection/option'
import { useTranslation } from 'react-i18next'

const StyledSelection = styled.div`
    display: flex;
    font-size: ${props => props.theme.fontSize.small};
    padding: 16px 0;
`

const StyledLabel = styled.div`
    display: flex;
    align-items: center;
    padding-right: 16px;
`

const ItemsPerPageSelection = (
    {
        tableName,
        offsetKey,
        limitKey,
        options = [10, 25, 50],
        selectedOptionValue = 10
    }
) => {
    const {t} = useTranslation()

    return <StyledSelection>
        <StyledLabel>
            {t('Results per page')}
        </StyledLabel>
        {
            options.map(
                value => <Option 
                    key={value}
                    tableName={tableName}
                    value={value}
                    isActive={value === selectedOptionValue}
                    offsetKey={offsetKey}
                    limitKey={limitKey}
                />
            )
        }
    </StyledSelection>
}

ItemsPerPageSelection.propTypes = {
    tableName: PropTypes.string.isRequired,
    offsetKey: PropTypes.string.isRequired,
    limitKey: PropTypes.string.isRequired,
    onSelection: PropTypes.func,
    options: PropTypes.array,
    selectedOptionValue: PropTypes.number
}

export {ItemsPerPageSelection}
