import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
// import CustomersQrCodeTable from 'application/components/tables/customers_qr_code'
import { WidgedTableHeadline } from 'application/components/widgets/_common/table-headline'
import { StyledCustomerQrCodeList } from './fragments'
import { CustomersQrCodeElement } from './customer_qr_code_element'
import { withCategoriesLoader } from 'application/components/higher_order_components'

const CustomersQrCodeTableWidget = withCategoriesLoader((
    {
        payload, 
        loadingFailed,
        isLoaded,
        isLoading,
        onQRCodeDownload,
        currentAccountName,
        onEditCustomer,
        categories
    }
) => {
    const {t} = useTranslation()

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage> 
    }

    const categoryId = categories.find(c => c.isDefault).id

    return <React.Fragment>
        <WidgedTableHeadline
            message={t('last customers')}
        />
        <StyledCustomerQrCodeList>
            {
                payload.customers.map((customer, i) => {
                    return <CustomersQrCodeElement 
                        key={i} 
                        customer={customer} 
                        currentAccountName={currentAccountName}
                        onQRCodeDownload={onQRCodeDownload}
                        categoryId={categoryId}
                        onEditCustomer={onEditCustomer}
                    />
                })
            }
        </StyledCustomerQrCodeList>
        {/* <CustomersQrCodeTable 
            customersListLoading={isLoading}
            customersListLoaded={isLoaded}
            customersListTotal={payload.total}
            customersList={ payload.customers}
        /> */}

    </React.Fragment>
})

CustomersQrCodeTableWidget.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export default CustomersQrCodeTableWidget