import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Indicator from '../component'

const OverrideColorIndicator = styled.div`
    & i{
        color: ${(props) => {
        switch (props.overrideColorWith) {
        case 'black':
            return props.theme.color.black
        case 'anthracite':
            return props.theme.color.anthracite
        case 'green':
            return props.theme.color.green
        case 'warning':
            return props.theme.color.warning
        case 'success':
            return props.theme.color.success
        case 'gray70':
            return props.theme.color.gray70
        case 'gray15':
            return props.theme.color.gray15
        case 'gray20':
            return props.theme.color.gray20
        default:
            return props.theme.color.primary
        } 
    }
};
    }
    & span{
        color: ${(props) => {
        switch (props.overrideColorWith) {
        case 'black':
            return props.theme.color.black
        case 'anthracite':
            return props.theme.color.anthracite
        case 'green':
            return props.theme.color.green
        case 'warning':
            return props.theme.color.warning
        case 'success':
            return props.theme.color.success
        case 'gray70':
            return props.theme.color.gray70
        case 'gray15':
            return props.theme.color.gray15
        case 'gray20':
            return props.theme.color.gray20
        default:
            return props.theme.color.primary
        };
    }
}
        };
`

const IndicatorWithColor = (props) => {

    const {overrideColorWith = ''} = props
    return <OverrideColorIndicator overrideColorWith={overrideColorWith}>
        <Indicator {...props} />
    </OverrideColorIndicator>

}

export default IndicatorWithColor

IndicatorWithColor.propTypes = {
    overrideColorWith: PropTypes.string.isRequired
}