import { HttpError, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { eventChannel, buffers, END } from 'redux-saga'
import { apply, call, put, take, select } from 'redux-saga/effects'
import { applicationToken } from 'application/storage'
import { ACTIONS } from 'application/constants'
import { addNotification } from 'application/redux/actions/realtime_notifications'
// import { showToast } from 'application/redux/actions/notifications'
import { 
    // NOTIFICATION, 
    REALTIME_NOTIFICATION_CHANNEL } from 'application/constants'
import { getCurrentAccountId } from 'application/redux/selectors'
// import { storeErrorDetails } from 'application/redux/actions/errors'
// import { guidGenerator } from 'application/common/guid_helpers'
import { jsonHelpers } from 'application/common'

const createConnection = (currentAccountId) => {
    const url = process.env.REACT_APP_REALTIME_NOTIFICATIONS_URL
    const accessTokenFactory = () => applicationToken.get(currentAccountId)

    const connection = new HubConnectionBuilder()
        .withUrl(url, {
            accessTokenFactory,
            transport: 4
        })
        .withAutomaticReconnect()
        .build()

    return connection
}

const createChannel = connection => eventChannel((emit) => {
    const handler = (data) => {
        emit(data)
    }

    connection.on(REALTIME_NOTIFICATION_CHANNEL, handler)
    connection.onreconnecting((e)=>{
        if(e instanceof HttpError && e.statusCode === 401){
            connection.stop()
            emit(END)
        }else{
            emit(new Error(e))
        }
    })
    
    return () => {
        connection.off(REALTIME_NOTIFICATION_CHANNEL, handler)
    }
}, buffers.expanding())
    
export function* realtimeNotificationsWatcher() {
    yield take(ACTIONS.AUTH_LOGIN_SUCCESS) //build connection only after login
    const currentAccountId = yield select(getCurrentAccountId)
    try{
        const connection = yield call(createConnection, currentAccountId)
        const channel = yield call(createChannel, connection)
    
        if(connection.state !== HubConnectionState.Connected){
            yield apply(connection, 'start')
        }

        try {
            while (connection.state === HubConnectionState.Connected) {
                const response = yield take(channel)
                const [isJson, parsedData] = jsonHelpers.parseJson(response)
                const data = isJson ? parsedData : response

                yield put(addNotification(data))
            }
        } catch(e){
            yield apply(connection, 'stop')
            yield apply(channel, 'close')
            throw(e)
        } 
    } catch(e){
        // const errorId = guidGenerator()
        // yield put(storeErrorDetails(e, errorId))
        // yield put(showToast('Error establishing realtime connection', NOTIFICATION.LEVEL.ERROR, {errorId}))
    }
}