
import { getAccountLinkHref } from 'application/common/route_helpers'
import { 
    ROUTES,
    USER_PERMISSONS
} from 'application/constants'
import React from 'react'
import ButtonLink from 'application/components/building_blocks/main_navigation_sidebar/_common/button_link'
import PropTypes from 'prop-types'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'

const AdministrationAccordion = (
    {
        match,
        primaryColor,
        primaryHoverColor
    }
) =>  <React.Fragment>
    <ForAnyBranchPermissions 
        permissions={[USER_PERMISSONS.USER.READ]} 
        allNeeded={false}
    >
        <ButtonLink 
            isActive={
                match.path === ROUTES.ADMINISTRATION_USERS 
            }
            label={'User Administration'}
            pathDestination={getAccountLinkHref(match, ROUTES.ADMINISTRATION_USERS)}
            iconKey={'people'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
    </ForAnyBranchPermissions>
    <ForAnyBranchPermissions 
        permissions={[USER_PERMISSONS.CUSTOMER.READ]} 
        allNeeded={false}
    >
        <ButtonLink 
            isActive={
                match.path === ROUTES.CUSTOMERS 
            || match.path === ROUTES.CUSTOMERS_BUSINESS 
            || match.path === ROUTES.CUSTOMERS_PRIVATE
            }
            label={'Customers'}
            pathDestination={getAccountLinkHref(match, ROUTES.CUSTOMERS)}
            iconKey={'face'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
    </ForAnyBranchPermissions>
    <ForAnyBranchPermissions 
        permissions={[
            USER_PERMISSONS.GROUP.READ,
            USER_PERMISSONS.ROLE.READ
        ]} 
        allNeeded={true}
    >
        <ButtonLink 
            isActive={
                match.path === ROUTES.BRANCH_MANAGEMENT
            || match.path === ROUTES.BRANCH_MANAGEMENT_GROUP_MEMBERS
            }
            label={'Groups & Branches'}
            pathDestination={getAccountLinkHref(match, ROUTES.BRANCH_MANAGEMENT)}
            iconKey={'store'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
    </ForAnyBranchPermissions>
    <ForAnyBranchPermissions 
        permissions={[
            USER_PERMISSONS.ACCOUNT.UPDATE,
            {/* USER_PERMISSONS.DOCUMENT_CATEGORY.CREATE,
            USER_PERMISSONS.DOCUMENT_CATEGORY.UPDATE,
            USER_PERMISSONS.DOCUMENT_CATEGORY.DELETE,
            USER_PERMISSONS.DAMAGE_STATE.CREATE,
            USER_PERMISSONS.DAMAGE_STATE.UPDATE,
            USER_PERMISSONS.DAMAGE_STATE.DELETE */}
        ]} 
        allNeeded={false}
    >
        <ButtonLink 
            isActive={
                match.path === ROUTES.ADMINISTRATION_SETTINGS
            || match.path === ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES
            || match.path === ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES
            || match.path === ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA
            }
            label={'Settings'}
            pathDestination={getAccountLinkHref(match, ROUTES.ADMINISTRATION_SETTINGS)}
            iconKey={'tune'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
    </ForAnyBranchPermissions>
    <ButtonLink 
        isActive={
            match.path === ROUTES.CHANGELOG 
        }
        label={'Whats`s new?'}
        pathDestination={getAccountLinkHref(match, ROUTES.CHANGELOG)}
        iconKey={'cached'}
        primaryColor={primaryColor}
        primaryHoverColor={primaryHoverColor}
    />
</React.Fragment>

AdministrationAccordion.propTypes = {
    match: PropTypes.object.isRequired,
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired
}

export default AdministrationAccordion


