import React from 'react'
import DateComponent from 'application/components/controls/date'
import Deadline from 'application/components/controls/deadline'
import TruncateString from 'react-truncate-string'
import { TaskCreator } from 'application/components/tables/tasks/_common/task_creator'
// import { STORAGE } from 'application/constants'
// import { ROUTE_PARAMETERS } from 'application/constants'
// import FullName from 'application/components/controls/full_name'
// import StateName from 'application/components/name_mappings/state_name'



const tableName = 'moDialogDelete'

export const tableConfiguration = {
    name: tableName,
    columns: [
        {
            width: '22.5%',
            label: 'Title',
            CellContent: ({element}) => <TruncateString text={element.title} truncateAt={50} />
        },
        {
            width: '15%',
            label: 'Deadline',
            CellContent: ({element}) => <Deadline deadlineFromTask={element.deadline} />
        },
        {
            width: '20%',
            label: 'Creation date',
            CellContent: ({element}) => <DateComponent date={element.createdAt} />
        },
        {
            width: '22.5%',
            label: 'Creator',
            CellContent: ({element}) => <TaskCreator task={element} />
        },
        // {
        //     width: 11,
        //     label: 'Assignee',
        //     hasSorting: false,
        //     sortParamValue: null,
        //     CellContent: ({element}) => <FullName person={element.assignee} />
        // },
        // {
        //     width: 10,
        //     label: 'State',
        //     hasSorting: false,
        //     sortParamValue: null,
        //     CellContent: ({element}) => <StateName state={element.state} />
        // },
        // {
        //     width: 11,
        //     label: 'Details',
        //     hasSorting: false,
        //     sortParamValue: null,
        //     CellContent: ({element}) => <TaskDetails task={element} />
        // },
        // {
        //     width: 11,
        //     label: 'Customer / owner',
        //     hasSorting: false,
        //     sortParamValue: null,
        //     CellContent: ({element}) => <FullName person={element.damage.vehicle.keeper} />
        // },
        {
            width: '20%',
            label: 'License plate',
            CellContent: ({element}) => <React.Fragment>
                {element.damage.vehicle.licensePlate}
            </React.Fragment>
            
        }
    ],
}
