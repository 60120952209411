import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import WidgetArea from './component'

const WidgetAreaEffects = (props) => {
    const { registeredWidgets, widgetList, widgetContext, onRegisterWidgets } = props
    const registeredWidgetKeys = registeredWidgets.map(w => w.key)
    const widgetsToRegister = widgetList.filter(w => !registeredWidgetKeys.includes(w.key)).map(w => ({
        ...w,
        initialPayload: w.routeParameters !== undefined ? {
            ...w.initialPayload,
            routeParameters: w.routeParameters
        } : {
            ...w.initialPayload
        }
    }))
    useEffect(() => {
        onRegisterWidgets(widgetContext, widgetsToRegister)
    }, [onRegisterWidgets, widgetContext, widgetsToRegister])

    return <WidgetArea {...props} />
}

export default withRouter(WidgetAreaEffects)