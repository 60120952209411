import {sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import {EditCustomerStepComponent} from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => ({
    onEditCustomer: (
        dialogName, 
        payload,
        metaData
    ) => {
        dispatch(
            sendPayloadRequest(
                dialogName, 
                payload, 
                metaData
            )
        )
    } 
})

export const EditCustomerStep = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCustomerStepComponent)