//reducer for the damage manager title bar
import { combineReducers } from 'redux'

import documentCenter from './document_center'
import tasks from './tasks'
import overview from './overview'
import inbox from './inbox'
import shared from './shared'

export default combineReducers({
    documentCenter,
    tasks,
    overview,
    inbox,
    shared
})