import styled from 'styled-components'

export const Wrapper = styled.div`
    background-color: ${props => props.theme.color.primaryBackground};
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-top: 0;
    flex: 1 1 100%;
`

export const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const Title = styled.h3`
    color: ${props => props.theme.color.anthrazite};
    font-size: ${props => props.theme.fontSize.standard};
    font-weight: ${props => props.theme.fontWeight.standardBold};    
`

export const Description = styled.div`
    color: ${props => props.theme.color.anthracite50};
    font-size: ${props => props.theme.fontSize.standard};
    font-style: ${props => props.theme.fontStyle.italic};
    text-align: center;
    padding-top: 16px; 
    flex: 0 0 40px;
`