import React from 'react'
import { Table } from 'application/components/tables/_common'
import { configuration } from './configuration'
import { useMassOperationsClose, useTableLoader } from 'application/components/hooks'
import { withMassOperations } from 'application/components/context/mass_operations'
import { withRouter } from 'react-router-dom'

const TableGroupMembers = (
    {
        groupMembersList,
        groupMembersListLoading,
        groupMembersListLoaded,
        groupMembersTotal,
        onLoadGroupMembers,
        updateConstraint,
        match,
        closeMoByName,
        moName=null
    }
) => {
    useTableLoader(onLoadGroupMembers, match, configuration, updateConstraint)
    useMassOperationsClose(moName, closeMoByName, groupMembersList)

    return <Table 
        configuration={configuration}
        elementsLoading={groupMembersListLoading}
        elementsLoaded={groupMembersListLoaded}
        elementsTotal={groupMembersTotal}
        elements={groupMembersList}
        emptyMessage='No users found'
    />
}

export default withMassOperations(withRouter(TableGroupMembers))