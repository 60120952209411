import { put, debounce } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* getWebakteLawfirmForDialogsSaga(action) {
    try {
        yield put(
            updateDialogDataByName(
                action.dialogName, 
                {
                    lawyersList: [], 
                    lawyersListLoading: true
                }
            )
        )

        const customersResponse = yield resourceCall(
            api.gateway.participants.getLawyers,
            {
                offset: action.offset, 
                limit: action.limit, 
                orderBy: action.orderBy, 
                direction: action.direction, 
                filterQuery: action.filterQuery
            }
        )

        yield put(
            updateDialogDataByName(
                action.dialogName, 
                { 
                    lawyersList: customersResponse.webakteAccounts, 
                    lawyersListLoading: false
                }
            )
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* getWebakteLawfirmWatcher() {
    yield debounce(
        1000, 
        ACTIONS.COMMON_GET_WEBAKTE_LAWFIRM_FOR_DIALOG_REQUEST_DEBOUNCED, 
        getWebakteLawfirmForDialogsSaga
    )
}