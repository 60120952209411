import { connect } from 'react-redux'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG, UPDATE_CONSTRAINTS } from 'application/constants'
import { downloadTaskDocuments } from 'application/redux/actions/attachments'
import MyTasks from './component'
import { getAllMyTasksRequest } from 'application/redux/actions/pages/my_tasks/all_tasks'
import { getUpdateConstraint } from 'application/redux/selectors'
import { MASS_OPERATIONS } from 'application/constants'



const mapStateToProps = state => {
    return {
        auth: state.auth,
        allTasks: state.pages.myTasks.userTasks,
        tasksListLoaded: state.pages.myTasks.userTasks.tasksListLoaded,
        tasksListLoading: state.pages.myTasks.userTasks.tasksListLoading,
        tasksList: state.pages.myTasks.userTasks.tasksList,
        tasksListTotal: state.pages.myTasks.userTasks.tasksListTotal,
        updateConstraint: getUpdateConstraint(state, UPDATE_CONSTRAINTS.TASKS_TABLES),
        moName: MASS_OPERATIONS.NAME.TASKS_OVERVIEW_CURRENT_BRANCH_MY_TASKS
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadTasksList: (
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        ) => dispatch(
            getAllMyTasksRequest(
                offset,
                limit,
                orderBy,
                direction,
                filterQuery
            )
        ),
        onDeleteTask: (payload) => dispatch(openDialogByName(DIALOG.NAME.DELETE_TASK, true, payload, 'Delete task')),
        onDownloadDocuments: (payload) => dispatch(downloadTaskDocuments(payload.damageId, payload.id)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyTasks)