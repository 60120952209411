import { put, takeLeading, call} from 'redux-saga/effects'

import { ACTIONS } from 'application/constants'
import { closeAllDialogs } from 'application/redux/actions/dialogs'
import { downloadFile } from 'application/api/helpers'

function* downloadQRCodeSaga(action) {
    yield call(
        downloadFile,
        action.qrCodeObjectUrl,
        action.downloadedImageFileName
    )

    yield put(
        closeAllDialogs()
    )
}

export function* qrCodeDownloadWatcher() {
    yield takeLeading(
        ACTIONS.QR_CODE_DOWNLOAD, 
        downloadQRCodeSaga
    )
}