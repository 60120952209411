import { useLocation } from 'react-router-dom'
import { routeHelpers } from 'application/common'

export const useQuery = () => {
    const query = new URLSearchParams(useLocation().search)

    query.getParsedParam = (paramName, defaultValue = 0) => typeof query.get(paramName) === 'string' 
    && query.get(paramName) !== '' 
    && !isNaN(query.get(paramName))
        ? parseInt(query.get(paramName)) 
        : defaultValue

     
    query.getStringParam = (paramName, defaultValue = '') => typeof query.get(paramName) === 'string' 
    && query.get(paramName) !== '' 
        ? query.get(paramName) 
        : defaultValue

    const all = []

    for (const param of query) {
        all.push(param) 
    }

    query.all = Array.from(all, (a) => ({ key: a[0], value: a[1] }))
    return query
}



export const setQuery = (
    history, 
    location, 
    pathname, 
    queryParams = [], 
    keepOldQuery = false,
    removeParams = []
) => {
    const { buildQueryString } = routeHelpers
    const oldQuery = new URLSearchParams(location.search)

    for (const { key } of queryParams) {
        if (oldQuery.has(key)) {
            oldQuery.delete(key)
        }
    }
    for (const key of removeParams) {
        if (oldQuery.has(key)) {
            oldQuery.delete(key)
        }
    }

    const newQuery = keepOldQuery 
        ? buildQueryString(queryParams) + '&' + oldQuery.toString() 
        : buildQueryString(queryParams)
    history.push({
        pathname,
        search: newQuery
    })
}



export const deleteQuery = (
    history, 
    location, 
    pathname, 
    queryKeys = [], 
    keepOldQuery = true
) => {
    const oldQuery = new URLSearchParams(location.search)

    for (const key of queryKeys) {
        if (oldQuery.has(key)) {
            oldQuery.delete(key)
        }
    }

    const newQuery = keepOldQuery ? oldQuery.toString() : ''

    history.push({
        pathname,
        search: newQuery
    })
}