import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { TASK_STATES } from 'application/constants'
import { ButtonDescription } from 'application/components/fragments/typography'
import { FlexBox } from 'application/components/fragments/flex_box'

const TaskOperations = ({
    task,
    isInternal = true,
    onTaskStateChange
}) => {
    const { t } = useTranslation()

    const handleCloseTask = () => {
        onTaskStateChange(task.id, TASK_STATES.DONE)
    }

    const handleReopenTask = () => {
        onTaskStateChange(task.id, TASK_STATES.TO_DO)
    }

    const getOperations = (isInternal, isAssigned) => {

        switch(task.state) {
        case TASK_STATES.TO_DO:
            return <ButtonDescription>
                {
                    isAssigned 
                        ? t('This task has not been done yet by car dealer') 
                        : t('This task has not been assigned yet')
                }
            </ButtonDescription>
        case TASK_STATES.TO_REVIEW:
            return <React.Fragment>
                <FlexBox justifyContent='flex-end' marginLeft='2%'>
                    <ActionButton
                        buttonText={t('Reopen task')}
                        onButtonClick={handleReopenTask}
                    />
                    <FlexBox width='8px'/>
                    <ActionButton
                        buttonText={t('Close task')}
                        onButtonClick={() => handleCloseTask(isInternal)}
                    />
                </FlexBox>
            </React.Fragment>

        case TASK_STATES.DONE:
            return  <ActionButton
                buttonText={t('Reopen task')}
                onButtonClick={handleReopenTask}
            />

        default: 
            return null
        }
    }
    return <React.Fragment>
        {
            getOperations(isInternal, task.assignee !== undefined)
        }
    </React.Fragment>
}

export default TaskOperations