import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { showDialogStepsWaitingScreen, hideDialogStepsWaitingScreen} from 'application/redux/actions/dialog_steps_waiting_screen'
import { removeEmptyStringProperties } from 'application/api/data_mappers/export/customer'




export function* dialogEditCustomerFromDialogSaga(action) {
    yield put(
        showDialogStepsWaitingScreen(
            'Saving customer'
        )
    ) 

    const mappedPayload = removeEmptyStringProperties(action.payload)

    yield resourceCall(
        api.gateway.customer.putCustomer, 
        {
            customerId: action.metaData.customerId
        }, 
        mappedPayload
    )

    yield put(
        updateDialogDataByName(
            action.metaData.fromDialogName,
            { 
                customer: {
                    ...mappedPayload,
                    customerId: action.metaData.customerId
                } 
            }
        )  
    )
    yield put(
        showToast(action.metaData.successMsg)
    )
    yield put(
        hideDialogStepsWaitingScreen()
    )
}