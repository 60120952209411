import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { populateAttachmentMeta } from 'application/redux/actions/attachments'
import { attachmentMetaLoaded } from 'application/redux/selectors'
import { put, select, takeEvery } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* fetchAttachmentMetaByIdSaga(action) {
    try {

        const isLoaded = yield select(attachmentMetaLoaded, action.id)

        if (!isLoaded) {
            const result = yield resourceCall(
                api.gateway.attachments.getAttachmentMetaById, 
                {
                    damageId: action.metaData.damageId,
                    attachmentId: action.id
                }
            )
            yield put(populateAttachmentMeta(action.id, action.attachmentName, {...action.metaData , ...result}))
        }

    } catch (e) {
        yield handleError(e, action)
    }
}

export function* fetchAttachmentMetaByIdWatcher() {
    yield takeEvery(ACTIONS.ATTACHMENTS_GET_META_REQUEST, fetchAttachmentMetaByIdSaga)
}