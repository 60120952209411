import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import MessageDetails from 'application/components/building_blocks/message/details'
import {withMessageDetailsLoader} from 'application/components/higher_order_components/with_messages_details_loader'
import { ROUTES } from 'application/constants'



const BranchSpecificMessageDetailsWidget = (
    { 
        deleteMessage, 
        loadingFailed, 
        downloadAttachment, 
        messageDetails 
    }
) => {
    const { t } = useTranslation()
    if (loadingFailed) {
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    return <React.Fragment>
        <MessageDetails 
            downloadAttachment={downloadAttachment}
            messageDetails={messageDetails}
            deleteMessage={deleteMessage}
        />
    </React.Fragment>
}

BranchSpecificMessageDetailsWidget.propTypes = {
    loadingFailed: PropTypes.bool.isRequired,
    downloadAttachment: PropTypes.func.isRequired,
    messageDetails: PropTypes.object.isRequired,
    deleteMessage: PropTypes.func.isRequired
}

export const BranchSpecificMessageDetails =  withMessageDetailsLoader( 
    ROUTES.BRANCH_SPECIFIC_INBOX
)(BranchSpecificMessageDetailsWidget)