import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'

import DamageInvoicesHeader from './header'

const mapDispatchToProps = dispatch => {
    return {
        onChangeAllCollapsables: (dialogName, initiallyCollapsed) => dispatch(updateDialogDataByName(dialogName, {initiallyCollapsed}))
    }
}

export default connect(
    () =>({}),
    mapDispatchToProps
)(DamageInvoicesHeader)