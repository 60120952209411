import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { DIALOG } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'



const DeleteAttachment = (
    { 
        abort, 
        dialog, 
        onDeleteAttachment 
    }
) => {
    const {t} = useTranslation()
    const payload = dialog.payload
    const isExternal = payload.match.params.sharedResourceId !== undefined

    const dialogName = isExternal 
        ? DIALOG.NAME.DELETE_ATTACHMENT_WORKFLOW_PANEL 
        : DIALOG.NAME.DELETE_ATTACHMENT

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox>
                {t('Do you want to delete this document?')}
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={
                () => onDeleteAttachment(
                    dialogName,
                    payload.damageId,
                    payload.attachmentId,
                    payload.taskId, 
                    payload.match
                )
            }
            submitText='Delete'
        />
    </React.Fragment>
}

export const DeleteAttachmentComponent = withDialogDataLoader(DeleteAttachment)