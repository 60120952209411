import { ROUTE_PARAMETERS } from 'application/components/pages/external/create_damage/sub_components/route_parameters'
import { buildQueryString } from 'application/common/route_helpers'
import { useMemo } from 'react'


export const useCreateDamageUrl = (
    customer, 
    licensePlate, 
    currentAccountName, 
    categoryId, 
    proxyShareId
) => {
    const createDamageUrl = useMemo(
        () => {
            const customerSpecificQueryObjects = customer.isCompany 
                ? [
                    {
                        key: ROUTE_PARAMETERS.COMPANY_NAME_PARAM, 
                        value: customer.companyName
                    },
                ]
                : [
                    {
                        key: ROUTE_PARAMETERS.FIRST_NAME_PARAM, 
                        value: customer.firstName
                    },
                    {
                        key: ROUTE_PARAMETERS.LAST_NAME_PARAM, 
                        value: customer.lastName
                    }
                ]

            const queryString = buildQueryString(
                [
                    {
                        key: ROUTE_PARAMETERS.LICENSE_PLATE_PARAM, 
                        value: licensePlate
                    },
                    {
                        key: ROUTE_PARAMETERS.ACCOUNT_NAME_PARAM, 
                        value: currentAccountName
                    }, 
                    {
                        key: ROUTE_PARAMETERS.CATEGORY_ID_PARAM, 
                        value: categoryId
                    },
                    {
                        key: ROUTE_PARAMETERS.IS_COMPANY_PARAM,
                        value: customer.isCompany
                    },
                    ...customerSpecificQueryObjects
                ]
            )
            return `${window.location.protocol}//${window.location.hostname}/external/createdamage/${proxyShareId}${encodeURI(queryString)}`

        }, [categoryId, currentAccountName, customer, licensePlate, proxyShareId])

    return {
        createDamageUrl
    }
}