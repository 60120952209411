import React, {useState} from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { useTranslation } from 'react-i18next'
import { Paragraph } from 'application/components/fragments/typography'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { MoDialogFooter } from 'application/components/dialogs/mass_operations/_common/footer'
import { isDefined } from 'application/common/data_helpers'
import { MoDialogCategoryBlock } from '../_common/category_block'
import { removeItemFromListById } from 'application/common/list_helpers'
import { PushBottom16 } from 'application/components/pages/_common'
import { SelectWrapper } from './fragments'
import {groupItemsByCategory} from '../_common/helper_functions'
import { FlexBox } from 'application/components/fragments/flex_box'
import CategorySelect from 'application/components/controls/form/category_select'
import { useForm } from 'react-hook-form'

const MoDocsChangeCategoryComponent = ({
    abort, 
    onSubmit,
    dialog
}) => {
    const { t } = useTranslation()

    const { 
        damageId, 
        moName,
        selectedItems,
        categories
    } = dialog.payload

    const {register, setValue, getValues} = useForm()

    const [selectedCategoryName, setSelectedCategoryName] = useState(categories[0].name)
    const [selectedItemsByDialog, setSelectedItemsByDialog] = useState(selectedItems)
    const documentsCount = isDefined(selectedItemsByDialog) ? selectedItemsByDialog.length : '0'

    const handleRemoveItem = (    
        documentId 
    ) =>  {
        const listWithoutItem = removeItemFromListById(selectedItemsByDialog,documentId)
        setSelectedItemsByDialog(listWithoutItem)
    }

    const itemsGroupedByCategory = groupItemsByCategory(selectedItemsByDialog)

    const handleSubmit = () => {
        const payload = {
            selectedDocuments: selectedItemsByDialog
        }

        const metaData = {
            damageId,
            moName,
            categoryId: getValues().category
        }
        
        onSubmit(
            dialog.name,
            payload,
            metaData
        )
    }

    return  <React.Fragment>
        <DialogMainContent cssHeight='400px'>
            <Paragraph>
                <Trans  
                    i18nKey='Should_category_of_documents_be_changed_into'
                >
                    {{documentsCount}} {selectedCategoryName}
                </Trans>
            </Paragraph>
            <PushBottom16/>
            <FlexBox 
                flexDirection='column'
                paddingLeft='16px'
                paddingRight='16px'
            >
                <SelectWrapper>
                    <CategorySelect 
                        register={register} 
                        setValue={setValue} 
                        label='Please select new category'
                        onChange={(option)=>{
                            setSelectedCategoryName(option.label)
                        }}
                    />
                </SelectWrapper> 
                <PushBottom16/>
                <PushBottom16/>
                <Paragraph />
                {
                    itemsGroupedByCategory.map( 
                        itemsGroup => {            
                            return <MoDialogCategoryBlock
                                key={itemsGroup.id}
                                itemsGroup={itemsGroup}
                                removeItem={handleRemoveItem}
                                moName={moName}
                            />
                        }
                    )
                }
                <Paragraph />
            </FlexBox>  
        </DialogMainContent>
        <MoDialogFooter 
            onCloseDialog={abort} 
            onSubmit={handleSubmit}
            submitButtonText={ t('Change category') }
            submitDisabled={false}
        />   
    </React.Fragment>
}

MoDocsChangeCategoryComponent.propTypes = {
    abort: PropTypes.func.isRequired,
    onChangeCategory: PropTypes.func.isRequired,
    dialog: PropTypes.object.isRequired
}

export {MoDocsChangeCategoryComponent}