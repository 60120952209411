import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, withRouter } from 'react-router-dom'

import { ROUTES } from 'application/constants'
import * as Common from 'application/components/pages/_common'
import { SubPageLayout } from 'application/components/pages/_common'
import TaskBarWorkflowPanel from 'application/components/building_blocks/task_bar_workflow_panel'
import TaskDetailsWorkflowPanel from 'application/components/building_blocks/task_details_workflow_panel'

const WorkflowPanelTasks = ({tasksList, tasksLoaded, tasksLoading, damage, onAddTask, onDeleteTasks, onForwardTasks, onDownloadTasks, onResetSelectedTask, match}) => {
    // redirect to the first task in the list if no task is selected
    if (tasksList.length > 0 && tasksLoaded && match.path !== ROUTES.EXT_WORKFLOW_PANEL_TASKS_PRESELECTED){
        const redirect = `${match.url}/${tasksList[0].id}`
        return <Redirect to={redirect} />
    }
    return <SubPageLayout paddingLeft='0'>
        <Common.Container>
            <Common.Sidebar cssWidth='300px' paddingLeft='0' paddingRight='0' paddingTop='0' paddingBottom='50px'>
                <TaskBarWorkflowPanel 
                    tasksList={tasksList} 
                    tasksLoaded={tasksLoaded} 
                    tasksLoading={tasksLoading}
                    onAddTask={onAddTask}
                    onDeleteTasks={onDeleteTasks}
                    onForwardTasks={onForwardTasks}
                    onDownloadTasks={onDownloadTasks}
                    onTaskClick={onResetSelectedTask}
                    damage={damage}
                />
            </Common.Sidebar>
            <Common.Main paddingLeft='0' paddingRight='0' className='taskScrollArea'>
                {
                    match.path === ROUTES.EXT_WORKFLOW_PANEL_TASKS_PRESELECTED && <TaskDetailsWorkflowPanel match={match} /> 
                }
            </Common.Main>
        </Common.Container>
    </SubPageLayout>
}

WorkflowPanelTasks.propTypes = {
    tasksList: PropTypes.array.isRequired,
    tasksLoaded: PropTypes.bool.isRequired,
    onAddTask: PropTypes.func.isRequired,
    onDeleteTasks: PropTypes.func.isRequired,
    onForwardTasks: PropTypes.func.isRequired,
    onDownloadTasks: PropTypes.func.isRequired,
    onResetSelectedTask: PropTypes.func.isRequired,
}

export default withRouter(WorkflowPanelTasks)