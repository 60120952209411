import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, useLocation, withRouter } from 'react-router-dom'
import { ROUTES } from 'application/constants'
import * as Common from 'application/components/pages/_common'
import { SubPageLayout } from 'application/components/pages/_common'
import TaskBar from 'application/components/building_blocks/task_bar'
import TaskDetails from 'application/components/building_blocks/task_details'
import DamageManager from '../container'
import DamageManagerTasksSidebar from './sidebar'



const DamageManagerTasks = ({
    tasksList, 
    tasksLoaded, 
    tasksLoading, 
    tasksListDamageId, 
    onAddTask, 
    onDeleteTasks, 
    onForwardTasks, 
    onDownloadTasks, 
    onResetSelectedTask, 
    subRoute,
    damage,
    damageLoaded,
    match
}) => {
    const path = useLocation().pathname

    // redirect to the first task in the list if no task is selected
    if(tasksList.length > 0 && tasksLoaded && match.path !== ROUTES.DAMAGE_MANAGER_TASKS_PRESELECTED && tasksListDamageId === match.params.damageId){
        const redirect = `${path}/${tasksList[0].id}`
        return <Redirect to={redirect} />
    }

    return <DamageManager
        titleSegments={['Damage processing']}
        subRoute={subRoute}
        sidebar={ <DamageManagerTasksSidebar /> }
        paddingRight='0'
    >
        <SubPageLayout paddingLeft='0'>
            <Common.Container>
                <Common.Sidebar 
                    cssWidth='300px' 
                    paddingLeft='0' 
                    paddingRight='0' 
                    paddingTop='0' 
                    paddingBottom='50px'
                >
                    <TaskBar 
                        tasksList={tasksList} 
                        tasksLoaded={tasksLoaded} 
                        tasksLoading={tasksLoading}
                        onAddTask={onAddTask}
                        onDeleteTasks={onDeleteTasks}
                        onForwardTasks={onForwardTasks}
                        onDownloadTasks={onDownloadTasks}
                        onTaskClick={onResetSelectedTask}
                        damage={damage}
                    />
                </Common.Sidebar>
                <Common.Main paddingLeft='0' paddingRight='0' className='taskScrollArea'>
                    {
                        match.path === ROUTES.DAMAGE_MANAGER_TASKS_PRESELECTED 
                        && damageLoaded 
                        && damage.id === match.params.damageId 
                        && <TaskDetails /> 
                    }
                </Common.Main>
            </Common.Container>
        </SubPageLayout>
    </DamageManager>
}

DamageManagerTasks.propTypes = {
    tasksList: PropTypes.array.isRequired,
    tasksLoaded: PropTypes.bool.isRequired,
    onAddTask: PropTypes.func.isRequired,
    onDeleteTasks: PropTypes.func.isRequired,
    onForwardTasks: PropTypes.func.isRequired,
    onDownloadTasks: PropTypes.func.isRequired,
    onResetSelectedTask: PropTypes.func.isRequired,
}

export default withRouter(DamageManagerTasks)