import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { Wrapper, CellLeft, CellRight } from './fragments'
import { setQuery } from 'application/components/hooks/use_query'
import { PureSelect } from 'application/components/controls/form'
import { getAccountLinkHref } from 'application/common/route_helpers'

const StyledTextInputWithLabel = styled.div`
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${props => props.marginBottom !== undefined ? props.marginBottom : '16px'};
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.color.anthracite};

    & input[type=text]{
        color: ${props => props.theme.color.anthracite};
        border: 0;
        height: 33px;
        padding: 0;
        width: inherit;
    }

    & input[type=text]::placeholder { 
        color: ${props => props.theme.color.anthracite50};
    }

    & input[type=text]:disabled {
        border: 0;
        background-color: ${props => props.theme.color.gray5};
        color: ${props => props.theme.color.anthracite50};
    }

    & input[type=text]:focus{
        outline: none;
    }
`

const SearchComboBox = ({disabled = false, filters, match}) => {
    const {t} = useTranslation()
    const options = filters.map(f => ({
        ...t,
        label: t(f.label),
        value: f.filterKey
    }))
    const [selectedOption, setSelectedOption] = useState(options[0])
    const [term, setTerm] = useState('')
    const history = useHistory()
    const location = useLocation()
    const isDamageOverview = match.path === ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES

    const ref = useRef()

    const changeTerm = e => {
        if(!disabled){
            setTerm(e.target.value)
        }
    }

    const changeFilter = o => !disabled && setSelectedOption(o)
    const search = e => {
        if(e.nativeEvent.keyCode === 13){
            setQuery(
                history, 
                location, 
                getAccountLinkHref(match, ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES), 
                [{
                    key: selectedOption.value,
                    value: encodeURIComponent(term)
                },{
                    key: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.SEARCH_BY.KEY,
                    value: selectedOption.value
                }], 
                isDamageOverview,
                filters.map(f => f.filterKey) //remove all old search query keys
            )
            setTerm('')
        }
    }

    return (
        <Wrapper ref={ref}>
            <CellLeft>
                <PureSelect
                    options={options}
                    value={selectedOption}
                    onChange={changeFilter}
                />
            </CellLeft>
            <CellRight>
                <StyledTextInputWithLabel>
                    <input 
                        value={term}
                        type='text'
                        onKeyPress={search} 
                        placeholder={t('Search')}
                        disabled={disabled}  
                        onChange={changeTerm}
                        className='jestSearchInput'
                    />
                </StyledTextInputWithLabel>
            </CellRight>
        </Wrapper>
    )
}

export default withRouter(SearchComboBox)