import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { exists } from 'application/common/data_helpers'

const StyledImage = styled.img`
    margin: ${props => props.cssMargin};
    height: ${props => exists(props.cssHeight) ? props.cssHeight : 'auto'};
`

export default ({data, className = '', cssMargin = '0'}) => { //pass through className for styled(Image)
    const {t} = useTranslation()

    return <StyledImage 
        src={data.src} 
        alt={t(data.alt)} 
        className={className}
        cssMargin={cssMargin}
        cssHeight={data.height}
    />
}