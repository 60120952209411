import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import DeleteCustomStateComponent from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onDeleteCustomState: (name, customStateId, customStateName) => {
            dispatch(sendPayloadRequest(name, { customStateName }, { customStateId }))
        },
    }
}

export const DeleteCustomState = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteCustomStateComponent)