import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'application/components/controls/form'
import InsuranceSelect from 'application/components/controls/form/insurance_select'
import { validationMethods } from 'application/common'
import TwoColumns from 'application/components/forms/_common'
import { PureSelectWithLabel } from 'application/components/controls/form/select/pure_select_with_label'
import PropTypes from 'prop-types'
import { PushBottom8 } from 'application/components/pages/_common'
import { SubHeadline } from 'application/components/fragments/dialog'
import { FlexBox } from 'application/components/fragments/flex_box'
import { exists } from 'application/common/data_helpers'



const InsuranceInputGroupComponent = ({
    register,
    setValue,
    formStateValues,
    updateFormState,
    errors,
    reset,
    setInsuranceSelectionFromList,
    insuranceSelectionFromList = true,
    insuranceSelectInputName,
    insuranceTextName,
    formTitle = 'Insurance'
}) => {
    const {t} = useTranslation()

    const insuranceTypeOptions = [
        {
            label: t('Select from list'),
            value: true
        },
        {
            label: t('Other insurance'),
            value: false
        }
    ]

    const removeCompanyIdFromValidationForm = () => {
        let formStateValuesWithoutCompanyId = formStateValues
        delete formStateValuesWithoutCompanyId[insuranceSelectInputName]
        updateFormState()
        reset(formStateValuesWithoutCompanyId)
    }
    
    
    const handleSetInsuranceSelectionType = (value) => {
        setInsuranceSelectionFromList(value)
        !value && removeCompanyIdFromValidationForm()
    }


    return <FlexBox flexDirection='column'>
        {
            exists(formTitle) && <React.Fragment>
                <SubHeadline >{t(formTitle)}</SubHeadline>
                <PushBottom8/>
            </React.Fragment>
        }
        <TwoColumns
            columnLeft={
                <PureSelectWithLabel
                    options = {insuranceTypeOptions}
                    defaultValue = {insuranceSelectionFromList}
                    setValue = {handleSetInsuranceSelectionType}
                    untranslatedLabel = 'Insurance name'
                />
            }
            columnRight={
                insuranceSelectionFromList
                    ? 
                    <InsuranceSelect
                        register={register}
                        setValue={setValue}
                        name={insuranceSelectInputName}
                        initialValue={formStateValues[insuranceSelectInputName]}
                        onChange={updateFormState}
                        validate={{notEmpty: validationMethods.notEmpty}}
                        error={errors[insuranceSelectInputName]}
                        menuPlacement='top'
                        maxMenuHeight={204}
                    /> 
                    : 
                    <TextInput
                        label={t('Name of other insurance')}
                        name={insuranceTextName}
                        register={register}
                        validate={{notEmpty: validationMethods.notEmpty} }
                        error={errors[insuranceTextName]}
                        onChange={updateFormState}
                    />  

            }
        />
    </FlexBox> 
}


InsuranceInputGroupComponent.propTypes = {
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    formStateValues: PropTypes.object.isRequired,
    insuranceSelectInputName: PropTypes.string.isRequired,
    insuranceTextName: PropTypes.string.isRequired,
    setFormStateValues: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    setInsuranceSelectionFromList: PropTypes.func.isRequired,
    insuranceSelectionFromList: PropTypes.bool
}

export {InsuranceInputGroupComponent}