import React from 'react'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { sendInvitation } from 'application/redux/actions/pages/administration'
import { connect } from 'react-redux'
import EditUserComponent from './component'
import Preloader from 'application/components/controls/themed_preloader'

const mapStateToProps = state => ({
    authentificatedUserId: state.auth.user.id,
    accessibleBranches: state.common.accessibleBranches,
    accessibleBranchesLoaded: state.common.accessibleBranchesLoaded
})


const mapDispatchToProps = dispatch => {
    return {
        onEditUser: (
            dialogName,
            payload,
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName,
                    payload,
                    metaData
                )
            )
        },
        onSendInvitation: (id) => dispatch(sendInvitation(id))
    }
}

const EditUserPreloader = props => !props.accessibleBranchesLoaded ? <Preloader /> : <EditUserComponent {...props} />

export const EditUser = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUserPreloader)