import { connect } from 'react-redux'

import DashboardContentEffects from './effects'
import { WIDGET_TYPES, WIDGET_CONTEXTS } from 'application/constants'
import {  setStaticMode, addWidget } from 'application/redux/actions/widget_contexts'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'
import { withLoggedInUser } from 'application/components/context/logged_in'

const mapStateToProps = state => {
    return {
        // prop to show / hide the experimental button bar
        isStatic: state.widgetContexts.contexts.filter(c => c.key === WIDGET_CONTEXTS.DASHBOARD).length > 0 
            ? state.widgetContexts.contexts.find(c => c.key === WIDGET_CONTEXTS.DASHBOARD).boardConfiguration.isStatic 
            : false
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //for testing purpose
        onSetStaticMode: (contextKey, isStatic) => dispatch(setStaticMode(contextKey, isStatic)),
        onAddWidget: (contextKey) => dispatch(addWidget(contextKey, Date.now().toString(), WIDGET_TYPES.LAST_REPAIR_AUTHORIZATIONS, [], 'last repair authorization')),
        onLoadDashboard: (context) => dispatch(refreshWidgetsByContext(context))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLoggedInUser(DashboardContentEffects))