import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label'
import { DialogFooter, DialogFooterRight } from 'application/components/fragments/dialog'
import PropTypes from 'prop-types'



const MoDialogFooter =  (
    { 
        onCloseDialog, 
        onSubmit,
        submitButtonText,
        submitDisabled = false
    }
) => {
    const {t} = useTranslation()

    return <DialogFooter>
        <DialogFooterRight>
            <IconButtonWithLabel 
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={onCloseDialog} 
            />
            <ActionButton 
                buttonText={submitButtonText} 
                onButtonClick={onSubmit}
                disabled={submitDisabled}
            />
        </DialogFooterRight>
    </DialogFooter>
}

MoDialogFooter.propTypes = {
    onCloseDialog: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    submitDisabled: PropTypes.bool
}

export  {MoDialogFooter}