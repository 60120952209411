import React from 'react'
import { DamageAssigneeChangedMetaContent } from './damage_assignee_changed'
import { DamageCreatedMetaContent } from './damage_created'
import { DamageDeletedMetaContent } from './damage_deleted'
import { DamageDescriptionChangedMetaContent } from './damage_description_changed'
import { DamageIncidentDateChangedMetaContent } from './damage_incidentdate_changed'
import { DamageJobNumberChangedMetaContent } from './damage_jobnumber_changed'
import { DamageNoteChangedMetaContent } from './damage_note_changed'
import { DamageStateChangedMetaContent } from './damage_state_changed'
import { DamageTypeChangedMetaContent } from './damage_type_changed'
import { DocumentCreatedMetaContent } from './document_created'
import { DocumentDeletedMetaContent } from './document_deleted'
import { InvoiceCreatedMetaContent } from './invoice_created'
import { InvoiceDeletedMetaContent } from './invoice_deleted'
import { TaskCommentCreatedMetaContent } from './task_comment_created'
import { TaskCreatedMetaContent } from './task_created'
import { TaskDeletedMetaContent } from './task_deleted'
import { TaskStateChangedMetaContent } from './task_state_changed'
import { VehicleUpdatedMetaContent } from './vehicle_updated'
import { VehicleKeeperChangedMetaContent } from './vehiclekeeper_changed'
import { REALTIME_NOTIFICATION_TYPE } from 'application/constants'
import { MessageCreatedMetaContent, MessageDeletedMetaContent } from './message'
import { ExpertCommunicationDekraEstablishedMetaContent } from './expert_communication_dekra_established'
import { ExpertCommunicationDekraFailedMetaContent } from './expert_communication_dekra_failed'
import { ExpertCommunicationDekraInitiatedMetaContent } from './expert_communication_dekra_initiated'
import { ExpertCommunicationOtherInitiatedMetaContent } from './expert_communication_other_initiated'


export const getMetaContentByResourceName = notificationType => {
    switch (notificationType) {
    case REALTIME_NOTIFICATION_TYPE.DAMAGE_ASSIGNEE_CHANGED:  
        return DamageAssigneeChangedMetaContent     
    case REALTIME_NOTIFICATION_TYPE.DAMAGE_CREATED:  
        return DamageCreatedMetaContent
    case REALTIME_NOTIFICATION_TYPE.DAMAGE_DELETED:  
        return DamageDeletedMetaContent
    case REALTIME_NOTIFICATION_TYPE.DAMAGE_DESCRIPTION_CHANGED:  
        return DamageDescriptionChangedMetaContent
    case REALTIME_NOTIFICATION_TYPE.DAMAGE_INCIDENT_DATE_CHANGED:  
        return DamageIncidentDateChangedMetaContent
    case REALTIME_NOTIFICATION_TYPE.DAMAGE_JOB_NUMBER_CHANGED:  
        return DamageJobNumberChangedMetaContent
    case REALTIME_NOTIFICATION_TYPE.DAMAGE_NOTE_CHANGED:  
        return DamageNoteChangedMetaContent 
    case REALTIME_NOTIFICATION_TYPE.DAMAGE_STATE_CHANGED:  
        return DamageStateChangedMetaContent 
    case REALTIME_NOTIFICATION_TYPE.DAMAGE_TYPE_CHANGED:  
        return DamageTypeChangedMetaContent
    case REALTIME_NOTIFICATION_TYPE.DOCUMENT_CREATED:  
        return DocumentCreatedMetaContent
    case REALTIME_NOTIFICATION_TYPE.DOCUMENT_DELETED:  
        return DocumentDeletedMetaContent
    case REALTIME_NOTIFICATION_TYPE.INVOICE_CREATED:  
        return InvoiceCreatedMetaContent
    case REALTIME_NOTIFICATION_TYPE.INVOICE_DELETED:  
        return InvoiceDeletedMetaContent
    case REALTIME_NOTIFICATION_TYPE.TASK_COMMENT_CREATED:  
        return TaskCommentCreatedMetaContent
    case REALTIME_NOTIFICATION_TYPE.TASK_CREATED:  
        return TaskCreatedMetaContent
    case REALTIME_NOTIFICATION_TYPE.TASK_DELETED:  
        return TaskDeletedMetaContent
    case REALTIME_NOTIFICATION_TYPE.TASK_STATE_CHANGED:  
        return TaskStateChangedMetaContent
    case REALTIME_NOTIFICATION_TYPE.VEHICLE_KEEPER_CHANGED:  
        return VehicleKeeperChangedMetaContent
    case REALTIME_NOTIFICATION_TYPE.VEHICLE_UPDATED:  
        return VehicleUpdatedMetaContent
    case REALTIME_NOTIFICATION_TYPE.MESSAGE_CREATED:  
        return MessageCreatedMetaContent
    case REALTIME_NOTIFICATION_TYPE.MESSAGE_DELETED:  
        return MessageDeletedMetaContent
    case REALTIME_NOTIFICATION_TYPE.EXPERT_COMMUNICATION_DEKRA_ESTABLISHED:  
        return ExpertCommunicationDekraEstablishedMetaContent
    case REALTIME_NOTIFICATION_TYPE.EXPERT_COMMUNICATION_DEKRA_FAILED:  
        return ExpertCommunicationDekraFailedMetaContent
    case REALTIME_NOTIFICATION_TYPE.EXPERT_COMMUNICATION_DEKRA_INITIATED:  
        return ExpertCommunicationDekraInitiatedMetaContent
    case REALTIME_NOTIFICATION_TYPE.EXPERT_COMMUNICATION_OTHER_INITIATED:  
        return ExpertCommunicationOtherInitiatedMetaContent

    default: 
        return () => null
    }
}

export const MetaContent = ({notification, onLinkClick, inline=false}) => {

    const MetaContentComponent = getMetaContentByResourceName(notification.notificationType)
    return <MetaContentComponent 
        notification={notification}
        onLinkClick={onLinkClick}
        inline={inline}
    />
}