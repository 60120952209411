import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import Collapsable from 'application/components/controls/collapsable'
import {REPAIR_AUTHORIZATION_TYPE} from 'application/constants'
import { withRouter } from 'react-router-dom'
import { FlexBox } from 'application/components/fragments/flex_box'

const TaskDamageRepairAuthorizationForm = withRouter(
    ({
        widgetKey,
        payload, 
        loadingFailed,
        match, 
        onUpdateRepairAuthorization
    }) => {
        const {t} = useTranslation()
        const {response} = payload
        const {damageId} = match.params
        const [repairAuthorizationType, setRepairAuthorizationType] = useState(response.repairAuthorization)

        if(loadingFailed){
            return <WidgetErrorMessage>{t('Repair approval data could not be loaded')}</WidgetErrorMessage>
        }
    
        return <FlexBox 
            flexDirection='column' 
            noWrap={true}
        >
            <Collapsable
                headline={t('Repair approval')} 
                initiallyCollapsed={false}
            >
                <ActionButton onButtonClick={()=>{
                    onUpdateRepairAuthorization(damageId, REPAIR_AUTHORIZATION_TYPE.GRANTED)
                    setRepairAuthorizationType(REPAIR_AUTHORIZATION_TYPE.GRANTED)
                }} 
                buttonText={t('Approve repair')} 
                disabled={repairAuthorizationType === REPAIR_AUTHORIZATION_TYPE.GRANTED}
                />
            &nbsp;
                <ActionButton onButtonClick={()=>{
                    onUpdateRepairAuthorization(damageId, REPAIR_AUTHORIZATION_TYPE.REFUSED)
                    setRepairAuthorizationType(REPAIR_AUTHORIZATION_TYPE.REFUSED)
                }} 
                buttonText={t('Disapprove repair')} 
                disabled={repairAuthorizationType === REPAIR_AUTHORIZATION_TYPE.REFUSED}
                />
                <br />
                <br />
                <ActionButton onButtonClick={()=>{
                    onUpdateRepairAuthorization(damageId, REPAIR_AUTHORIZATION_TYPE.NOT_SET)
                    setRepairAuthorizationType(REPAIR_AUTHORIZATION_TYPE.NOT_SET)
                }} 
                buttonText={t('Reset repair approval')} 
                disabled={repairAuthorizationType === REPAIR_AUTHORIZATION_TYPE.NOT_SET}
                secondary={true}
                />
            </Collapsable>
        </FlexBox>
    })

TaskDamageRepairAuthorizationForm.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { TaskDamageRepairAuthorizationForm }