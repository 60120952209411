import React from 'react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { withCreateDamageSteps } from './with_create_damage_steps'
import { compose } from 'redux'
import {CreateDamageStep} from './step_0'
import {EditCustomerStep} from './step_1'
import { notNull } from 'application/common/data_helpers'
import { DialogStepsLayout } from 'application/components/dialogs/_common/steps_layout'
import { EditInsuranceStep } from './step_2'




const CreateDamageComponent = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged,
        dialog,
        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep,
    }
) => {
    
    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const { 
        createdDamage = null,
        customer = null
    } = dialog.payload

    return <React.Fragment>
        <DialogStepsLayout  getStepsState={getStepsState}>
            {
                getCurrentStep().id === 0 && <CreateDamageStep
                    abort={abort}
                    onDataChange={onDataChange}
                    getStepsState={getStepsState} 
                    goToNextStep={goToNextStep}
                    dialog={dialog}
                />
            }
            {
                notNull(createdDamage) && <React.Fragment>
                    {
                        getCurrentStep().id === 1 
                    && <EditCustomerStep
                        abort={abort}
                        goToNextStep={goToNextStep}
                        onDataChange={onDataChange}
                        customer={customer}
                        damageId={createdDamage.id}
                        dialogName={dialog.name}
                        damageType={createdDamage.type}
                    />
                    }
                    {
                        getCurrentStep().id === 2 && <EditInsuranceStep
                            abort={abort}
                            onDataChange={onDataChange}
                            damageId={createdDamage.id}
                            damageType={createdDamage.type}
                        />
                    }
                </React.Fragment>
            }

        </DialogStepsLayout>




    </React.Fragment>

}


export const CreateDamage = compose(
    withCreateDamageSteps(),
    withDialogDataLoader
) (CreateDamageComponent)