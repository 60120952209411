import React from 'react'
import { withRouter } from 'react-router-dom'

import * as Fragments from './fragments'
import LayoutBase from './layout_base_container'
import { TitleBarDamageManager } from 'application/components/pages/internal/_title_bars'
import RealtimeNotificationsSidebar from 'application/components/building_blocks/realtime_notifications_sidebar'
import PageTitle from 'application/components/controls/page_title'

const LayoutDamageManager = ({ children, titleSegments=[] }) => {
    return <React.Fragment>
        <PageTitle segments={titleSegments} />
        <RealtimeNotificationsSidebar />
        <LayoutBase>
            <Fragments.Header>
                <TitleBarDamageManager />
            </Fragments.Header>
            <Fragments.Main>
                <Fragments.Content cssPaddingTop='0' cssPaddingLeft='0' cssPaddingRight='0'>
                    {children}
                </Fragments.Content>
            </Fragments.Main>
        </LayoutBase>
    </React.Fragment>
}

export default withRouter(LayoutDamageManager)