import { connect } from 'react-redux'
import GroupMembers from './component'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { getUpdateConstraint } from 'application/redux/selectors'
import { DIALOG, UPDATE_CONSTRAINTS } from 'application/constants'
import { getGroupMembersRequest } from 'application/redux/actions/pages/branch_management'
import { openDialogByName } from 'application/redux/actions/dialogs'

const mapStateToProps = state => {
    const {
        loadedGroupId,
        groupMembersList,
        groupMembersListLoading,
        groupMembersListLoaded,
        groupMembersTotal
    } = state.pages.branchManagement.groupMembers
    return {
        loadedGroupId,
        groupMembersList,
        groupMembersListLoading,
        groupMembersListLoaded,
        groupMembersTotal,
        updateConstraint: getUpdateConstraint(state, UPDATE_CONSTRAINTS.GROUP_MEMBERS)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadGroupMembers: (
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        ) => dispatch(
            getGroupMembersRequest(
                offset,
                limit,
                orderBy,
                direction,
                filterQuery
            )
        ),
        onEditUsersInGroup: (groupId) => dispatch(
            openDialogByName(
                DIALOG.NAME.BRANCH_MANAGEMENT_ADD_USERS_TO_GROUP,
                true, 
                {
                    groupId, 
                    existingUsersList: [], 
                    usersList: [], 
                    usersListLoading: false
                }, 
                'Add user to group'
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLoggedInUser(GroupMembers))