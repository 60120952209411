import { showErrorDetails } from 'application/redux/actions/errors'
import { connect } from 'react-redux'
import ErrorToast from './component'

const mapDispatchToProps = (dispatch) => {
    return {
        onDetailsClick: (errorId) => dispatch(showErrorDetails(errorId))
    }
}

export default connect(
    () => ({}),
    mapDispatchToProps
)(ErrorToast)