import React from 'react'

import { Provider } from 'react-redux'
import store from 'application/redux/create_store'

import { OidcProvider } from 'redux-oidc'
import { defaultUserManager } from 'application/common/user_manager'

import { Router } from 'react-router-dom'
import { history } from 'application/common/route_helpers'
import Routes from 'application/components/pages/_routes'

import { ThemeProvider } from 'styled-components'
import theme from 'application/style/theme'

import 'application/translation'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'material-icons-font/material-icons-font.css'
import 'application/style/fonts.css'



export default () => {
    return (
        <Provider store={store}>
            <OidcProvider store={store} userManager={defaultUserManager}>
                <ThemeProvider theme={theme}>
                    <Router history={history}>
                        <Routes />
                    </Router>
                </ThemeProvider>
            </OidcProvider>
        </Provider>
    )
}