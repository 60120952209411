import React from 'react'
import { useTranslation } from 'react-i18next'
import { CASE_STATUS } from 'application/constants'

export default ({ aktenStatusType }) => {
    const { t } = useTranslation()
    const mapAktenStatusTypeName = (type) => {
        const names = new Map([
            [CASE_STATUS.NOT_SET, 'Not set'],
            [CASE_STATUS.OPEN, 'Opened'],
            [CASE_STATUS.CLOSED, 'Closed'],
        ])
        return names.get(type)
    }

    return <React.Fragment>
        {t(mapAktenStatusTypeName(aktenStatusType))}
    </React.Fragment>
}