import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { isPermitted } from 'application/components/context/permissions'
import * as Fragments from './fragments'
import { exists } from 'application/common/data_helpers'

export const TabsLinks = (
    {
        items = [], 
        branchPermissions, 
        allPermissions, 
        branchId = null, 
        children
    }
) => {

    return (
        <Fragments.Wrapper>
            <Fragments.TabsWrapper>
                {
                    items.map(
                        (item, index) => {

                            const neededPermissions = item.permissions || []
                            const permissionScope = item.permissionScope || null
                            const allPermissionsNeeded = exists(item.allNeeded) ? item.allNeeded : true

                            const hasPermission = isPermitted(
                                allPermissions, 
                                branchPermissions, 
                                neededPermissions, 
                                permissionScope, 
                                allPermissionsNeeded, 
                                branchId
                            )

                            if(item.skip || !hasPermission){
                                return <React.Fragment key={index} />
                            }
                            return (
                                <Fragments.TabsItem
                                    key={index}
                                    isSelected={item.isActive}
                                    className={
                                        `jestButtonTab-${item.label}-${index} ${
                                            item.isActive 
                                                ? 'tabActive' 
                                                : ''
                                        }`
                                    }
                                >
                                    <NavLink to={item.link}>
                                        {item.label}
                                    </NavLink>
                                </Fragments.TabsItem>
                            )
                        })}
            </Fragments.TabsWrapper>
            <Fragments.PlaceForRightSide>
                <Fragments.WrapperForTheRightSide>
                    {children}
                </Fragments.WrapperForTheRightSide>
            </Fragments.PlaceForRightSide>
        </Fragments.Wrapper>
    )
}

TabsLinks.propTypes = {
    items: PropTypes.array
}