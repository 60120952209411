import { connect } from 'react-redux'
import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import DashboardComponent from './component'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        onConfigureDashboard: () => dispatch(
            openDialogByName(
                DIALOG.NAME.CONFIGURE_DASHBOARD, 
                true, 
                {}, 
                'Configure dashboard'
            )
        ),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardComponent)