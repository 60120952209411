import { connect } from 'react-redux'
import { getMessageByIdRequest } from 'application/redux/actions/pages/inbox'


export const WithMessagesContainer = (
    redirectUrl
) =>  connect(

    state => {
        return {
            detailsLoaded: state.pages.inbox.detailsLoaded,
            messageLoadedAndNotFound: state.pages.inbox.messageLoadedAndNotFound,
            redirectUrl
        }
    },

    dispatch => ({
        loadMessage: (parentRessourceId, messageId) => {
            dispatch(
                getMessageByIdRequest(parentRessourceId, messageId)
            )
        },
    })
)