import React from 'react'
import { SubPageLayout, PushBottom32 } from 'application/components/pages/_common'
import MyDamagesOverviewTable from 'application/components/tables/damages/table_my_damages'
import { FlexBox } from 'application/components/fragments/flex_box'
import { DamageStateFilterCheckboxGroup } from 'application/components/building_blocks/filters/checkboxes'
import { configuration } from 'application/components/tables/damages/table_my_damages/configuration'



const DamagesOverviewMyDamages = ({
    allDamages,
    updateConstraint,
    onLoadDamagesList
}) => {
    const { 
        damagesList, 
        damagesListLoading, 
        damagesListLoaded, 
        damagesListTotal 
    } = allDamages  

    return <SubPageLayout>
        <FlexBox>
            <FlexBox width='400px'>
                <DamageStateFilterCheckboxGroup
                    pagination = {configuration.pagination}
                    disabled = {damagesListLoading}
                />
            </FlexBox>
        </FlexBox>
        <PushBottom32 />
        <MyDamagesOverviewTable
            damagesList={damagesList}
            damagesListLoaded={damagesListLoaded}
            damagesListLoading={damagesListLoading}
            damagesListTotal={damagesListTotal}
            updateConstraint={updateConstraint}
            onLoadDamages={onLoadDamagesList}
        />
    </SubPageLayout>
}

export default DamagesOverviewMyDamages