import React, { useState } from 'react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { withCategoriesLoader } from 'application/components/higher_order_components'
import { withCommissionExpertSteps } from './with_commission_expert_steps'
import { CommissionExpertStep0 } from './component_step_0'
import { CommissionExpertStep1 } from './component_step_1'
import { CommissionExpertStep2 } from './component_step_2'
import { CommissionExpertStep3 } from './component_step_3'
import { compose } from 'redux'
import { EXPERT_TYPE } from 'application/constants'
import { DialogStepsLayout } from '../../_common/steps_layout'



const CommissionExpertDialog = (({
    getStepsState, 
    goToNextStep,
    goToPreviousStep,
    getCurrentStep,
    onCommissionExpert,
    dialog,
    setAbortBlocked, 
    setDataChanged,
    categories
})=>{
    const { damage, branch, customer } = dialog.payload

    const getBranchInfo = () => {
        return {
            name: branch.name,       
            address: {
                line1: '',
                postalCode: '',
                locality: '',
                ...branch.address
            },     
            contact: {
                email: '',
                phoneNumber: '',
                ...branch.contact
            }
        }
    }

    const step2InitialData = {
        inspectionInfo: {
            name: branch.name || '',
            site: {
                line1: '',
                postalCode: '',
                locality: '',
                ...branch.address
            },
            contact: {
                email: '',
                phoneNumber: '',
                ...branch.contact
            },
            date: null
        },
        branchInfo: getBranchInfo(),
        type: EXPERT_TYPE.DEKRA,
        note: ''
    }

    const [documents, setDocuments] = useState([]) //the array of uploaded files
    const [damageData, setDamageData] = useState(damage) //the damage form data
    const [customerData, setCustomerData] = useState(customer)
    const [commissionExpertData, setCommissionExpertData] = useState(step2InitialData) //the branch address form data
    const [inspectionAdressIsBranchAdress, setInspectionAdressIsBranchAdress] = useState(true)

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const handleOnCommissionExpert = (commissionExpertFormData) => {
        const defaultCategory = categories.find(c => c.isDefault)

        const commissionExpertPayload = inspectionAdressIsBranchAdress 
            ? {
                ...commissionExpertFormData,
                inspectionInfo: {
                    ...commissionExpertFormData.inspectionInfo,
                    name: commissionExpertFormData.branchInfo.name,
                    site: commissionExpertFormData.branchInfo.address,
                    contact: commissionExpertFormData.branchInfo.contact
                }
            }
            : commissionExpertFormData

        const payload = {
            commissionExpertPayload,
            damageData,
            customerData: {
                ...customer,
                address: {
                    line1: '',
                    postalCode: '',
                    locality: '',
                    ...customerData.address
                },
                ...customerData
            },
            documents
        }

        const metaData = {
            categoryId:defaultCategory.id,
            damageId: damage.id
        }

        onCommissionExpert(
            dialog.name, 
            payload,
            metaData
        )
    }


    return <DialogStepsLayout getStepsState={getStepsState}>
        {
            getCurrentStep().id === 0 && <CommissionExpertStep0
                goToNextStep={goToNextStep}
                files={documents}
                setFlaggedFiles={setDocuments}
                onDataChange={onDataChange}
            />
        }
        {
            getCurrentStep().id === 1 && <CommissionExpertStep1
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                setStep1data={data => {
                    setDamageData(data)
                    onDataChange()
                }}
                step1data={damageData}
                damage={damage}
            />
        }
        {
            getCurrentStep().id === 2 && <CommissionExpertStep2
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                setStep2data={data => {
                    setCustomerData(data)
                    onDataChange()
                }}
                step2data={customerData}
                damage={damage}
            />
        }

        {
            getCurrentStep().id === 3 && <CommissionExpertStep3
                goToPreviousStep={goToPreviousStep}
                onCommissionExpert={handleOnCommissionExpert}
                setStep2data={data => {
                    setCommissionExpertData(data)
                    onDataChange()
                }}
                step2data={commissionExpertData}
                inspectionAdressIsBranchAdress={inspectionAdressIsBranchAdress}
                setInspectionAdressIsBranchAdress={setInspectionAdressIsBranchAdress}
                getBranchInfo={getBranchInfo}
            />
        }
    </DialogStepsLayout>
})

export default compose(
    withCommissionExpertSteps(),
    withDialogDataLoader,
    withCategoriesLoader
)(CommissionExpertDialog)