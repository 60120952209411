import React, {useEffect} from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { exists, isDefined } from 'application/common/data_helpers'
import { getBranchLinkHref } from 'application/common/route_helpers'
import ThemedPreloader from 'application/components/controls/themed_preloader'




export const withMessagesComponent = Component => props =>  {

    const { t } = useTranslation()

    const isExternal = isDefined(props.match.params?.sharedResourceId)

    const { 
        loadMessage, 
        detailsLoaded, 
        messageLoadedAndNotFound,
        redirectUrl, 
        match
    } = props

    const branchId =  isExternal 
            ? null 
            : props.match.params?.branchId ,
        messageId = isExternal 
            ? match.params?.messageId || null
            : match.params?.messageId || null,
        parentRessourceId = isExternal 
            ? match.params.sharedResourceId
            : match.params.damageId

    useEffect(
        () => {
            parentRessourceId !== null
            && messageId !== null
            && loadMessage(parentRessourceId, messageId)
        }, [loadMessage, messageId, parentRessourceId]
    )

    if (
        messageLoadedAndNotFound
    ) {
        return <Redirect 
            to={
                getBranchLinkHref(
                    match, 
                    redirectUrl, 
                    branchId
                )
            } />
    } 
    
    if(exists(messageId) && !detailsLoaded){
        return <ThemedPreloader preloaderText={t('Loading')} />
    }

    return <Component {...props} />
}