import { connect } from 'react-redux'
import { WIDGET_CONTEXTS } from 'application/constants'
import TaskDetailsEffects from './effects'
import { getSelectedTask, getUsersForTaskDebounced, hideAttachmentDetails } from 'application/redux/actions/pages/damage_manager/tasks'
import { registerContext } from 'application/redux/actions/widget_contexts'
import { refreshWidgetsByContext } from 'application/redux/actions/widgets'
import { assignTask } from 'application/redux/actions/pages/damage_manager/tasks'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'

const mapStateToProps = (state, props) => {
    const selectedTaskLoaded = state.pages.damageManager.tasks.selectedTaskLoaded
    const contextLoaded = selectedTaskLoaded 
        ? state.widgetContexts.contexts.some(
            c => c.key === state.pages.damageManager.tasks.selectedTask.id
        )
        : false

    return {
        auth: state.auth,
        task: state.pages.damageManager.tasks.selectedTask,
        selectedTaskLoading: state.pages.damageManager.tasks.selectedTaskLoading,
        selectedTaskLoaded,
        attachments:[],// state.attachments.fullAttachments,
        contextLoaded,
        userList: state.pages.damageManager.tasks.selectedTaskUserList.users,
        userListLoading: state.pages.damageManager.tasks.selectedTaskUserListLoading,
        hasTaskList: state.pages.damageManager.tasks.tasksList.length > 0,
        damage: state.pages.damageManager.shared.damage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadTask: (taskId, damageId) => {
            dispatch(hideAttachmentDetails())
            dispatch(getSelectedTask(taskId, damageId))
            dispatch(refreshWidgetsByContext(taskId))
            dispatch(refreshWidgetsByContext(WIDGET_CONTEXTS.DAMAGE_MANAGER_TASKS_SIDEBAR))
        },

        onAssignTask: (
            damageId, 
            taskId, 
            userId, 
            userFirstName, 
            userLastname
        ) => dispatch(
            assignTask(
                damageId, 
                taskId, 
                userId, 
                userFirstName, 
                userLastname
            )
        ),
        onSearchUser: (search) => dispatch(
            getUsersForTaskDebounced(
                0, 
                10, 
                new ListFilter(filters.users.name)
                    .setValue(search)
                    .getQuery()
            )
        ),
        onRegisterWidgetContext: (widgetContext, widgetList) => dispatch(
            registerContext(widgetContext, widgetList)
        ),
        onDeleteTask: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.DELETE_TASK_FROM_DAMAGE_MANAGER, 
                true, 
                payload, 
                'Delete task'
            )
        ),
        onEditTask: (task, damageId, damage) => dispatch(
            openDialogByName(
                DIALOG.NAME.EDIT_TASK, 
                true, 
                {
                    task,
                    damageId,
                    damage
                }, 
                task.title)
        ),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDetailsEffects)