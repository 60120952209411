import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import EN from 'assets/images/en.png'
import DE from 'assets/images/de.png'

const StyledImageWithLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`

const StyledImageWrapper = styled.div`
    height: 12px;  
    & img{
        height: inherit;  
    }
`

const StyledLabelWrapper = styled.div`
    flex: 1 1 auto;
    padding-left: 6px;
    padding-right: 6px;
    position: relative;
    top: -2px;
    color: ${props => props.theme.color.gray70};
    font-size: ${props => props.theme.fontSize.small}
`

export const getImageSrc = source => {
    switch (source) {
    case 'en':
        return EN
    case 'de':
        return DE
    default:
        return DE
    }
}

export const getLabel = source => {
    switch (source) {
    case 'en':
        return 'English'
    case 'de':
        return 'Deutsch'
    default:
        return source
    }
}

const ImageWithLabel = ({source, showLabel = true}) => {
    const {t} = useTranslation()
    return <StyledImageWithLabel>
        <StyledImageWrapper>
            <img src={getImageSrc(source)} alt={source} />
        </StyledImageWrapper>
        {
            showLabel && <StyledLabelWrapper>
                <span>
                    {t(getLabel(source))}
                </span>
            </StyledLabelWrapper>
        }
    </StyledImageWithLabel>
}


ImageWithLabel.propTypes = {
    source: PropTypes.string.isRequired,
    showLabel: PropTypes.bool
}

export default ImageWithLabel