import React from 'react'
import {
    Switch,
    Route,
    withRouter,
    Redirect
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LayoutAllBranches } from 'application/components/pages/_layout'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { ROUTES } from 'application/constants'
import AllDamages from './all_damages'
import MyDamages from './my_damages'
import { getAccountLinkHref } from 'application/common/route_helpers'


const DamagesOverview = (
    { 
        match 
    }
) => {
    const {t} = useTranslation()

    //DEV360-468 working example, dont commit these changes

    if (match.path === ROUTES.DAMAGES_OVERVIEW) {
        return <Redirect to={getAccountLinkHref(match, ROUTES.DAMAGES_OVERVIEW_MY_DAMAGES)} />
    } 


    return <LayoutAllBranches
        titleSegments={['Damages', 'All branches']}
        headlineSpecific={t('Damages')}
    >

        <TabsLinks
            items={[
                {
                    label: t('My damages'),
                    link: getAccountLinkHref(match, ROUTES.DAMAGES_OVERVIEW_MY_DAMAGES),
                    isActive: match.path === ROUTES.DAMAGES_OVERVIEW_MY_DAMAGES
                },
                {
                    label: t('All damages'),
                    link: getAccountLinkHref(match, ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES),
                    isActive: match.path === ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES
                }
                //,{
                //    label: t('Archived damages'),
                //    link: getAccountLinkHref(match, ROUTES.DAMAGES_OVERVIEW_ARCHIVED_DAMAGES),
                //    isActive: match.path === ROUTES.DAMAGES_OVERVIEW_ARCHIVED_DAMAGES
                //}
            ]}
        />

        <Switch>
            <Route path={ROUTES.DAMAGES_OVERVIEW_MY_DAMAGES}>
                <MyDamages />
            </Route>
            <Route path={ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES}>
                <AllDamages />
            </Route>
        </Switch>          
               
    </LayoutAllBranches>
}

export default withRouter(DamagesOverview)