import React from 'react'
import { HeaderContainer, PreloaderContainer } from './fragments'
import { FormHeadline, Paragraph } from 'application/components/fragments/typography'
import { FlexBox } from 'application/components/fragments/flex_box'
import Preloader from 'application/components/controls/themed_preloader'
import { AnimatedCheckmark } from './animated_checkmark'
import { getFullName } from 'application/common/name_helpers'
import { PushBottom32 } from 'application/components/pages/_common'

export const ExtCreateDamageSuccess = (
    {
        damageDataSent,
        lawfirmData,
        expertData,
        damageData
    }
) => {


    return <HeaderContainer>

        {
            damageDataSent 
                ? <React.Fragment>
                    <FormHeadline>
                        Schadenmeldung gesendet
                    </FormHeadline>
                    <Paragraph cssAlign='center'>{getFullName(damageData.customer)}</Paragraph>
                    <Paragraph cssAlign='center'>{damageData.licensePlate}</Paragraph>
                    <AnimatedCheckmark />

                    <PushBottom32 />
                    {
                        (lawfirmData.commissionLawyer || expertData.commissionExpert) && <FlexBox flexDirection='column'>
                            <Paragraph cssAlign='center'>Schaden wurde übergeben an:</Paragraph>
                        </FlexBox>
                    }
                    {
                        lawfirmData.commissionLawyer && <FlexBox flexDirection='column'>
                            <Paragraph cssAlign='center'>
                                <strong>Rechtsanwaltskanzlei</strong>
                            </Paragraph>
                            <Paragraph cssAlign='center'>
                                {
                                    lawfirmData.lawfirm.name
                                }
                            </Paragraph>
                            <PushBottom32 />
                        </FlexBox>
                    }
                    {
                        expertData.commissionExpert && <FlexBox flexDirection='column'>
                            <Paragraph cssAlign='center'>
                                <strong>Sachverständiger</strong>
                            </Paragraph>
                            <Paragraph cssAlign='center'>
                                {
                                    expertData.selectedExpert.name
                                }
                            </Paragraph>
                            <PushBottom32 />
                        </FlexBox>
                    }

                </React.Fragment> 
                : <FlexBox>
                    <PreloaderContainer>
                        <Preloader preloaderText='Sende Schadenmeldung' />
                    </PreloaderContainer>
                </FlexBox>
        }
       
    </HeaderContainer>
}