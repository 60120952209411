import { ACTIONS } from 'application/constants'


export const updateRepairAuthorization = (
    damageId, 
    repairAuthorizationType
) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_REPAIR_AUTHORIZATION,
    damageId,
    repairAuthorizationType
})

export const updateAccidentReport = (damageId, damageData) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_ACCIDENT_REPORT,
    damageId,
    damageData
})

export const updateOpponentKeeper = (damageId, damageData) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_OPPONENT_KEEPER,
    damageId,
    damageData
})

export const updateOpponentKeeperAndDriver = (damageId, damageData) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_OPPONENT_KEEPER_AND_DRIVER,
    damageId,
    damageData
})

export const updateOpponentDriver = (damageId, damageData) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_OPPONENT_DRIVER,
    damageId,
    damageData
})

export const updateOpponentVehicle= (damageId, damageData) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_OPPONENT_VEHICLE,
    damageId,
    damageData
})

export const updatePolice = (damageId, damageData) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_POLICE,
    damageId,
    damageData
})

export const updateWitness = (damageId, damageData) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_WITNESS,
    damageId,
    damageData
})

export const updateComprehensiveData = (
    payload = null,
    metaData = null
) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_COMPREHENSIVE_DATA,
    payload,
    metaData
})


export const updateLiabilityInsuranceAndClaim = (
    payload = null,
    metaData = null
) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_LIABILITY_INSURANCE_AND_CLAIM,
    metaData, 
    payload
})

export const updateVehicleProprietor = (damageId, formData) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_VEHICLE_PROPRIETOR,
    damageId, formData
})
