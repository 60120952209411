import { exists } from 'application/common/data_helpers'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'

export const editAccountDataMapper = (payload) => {
    return {
        name: payload.name
    }
}

export const editAccountAddressDataMapper = (payload) => {
    if(exists(payload.latitude) && exists(payload.longitude)){
        return {
            line1: payload.line1,
            postalCode: payload.postalCode,
            locality: payload.locality,
            coordinates: {
                latitude: payload.latitude,
                longitude: payload.longitude
            }
        }
    }
    return {
        line1: payload.line1,
        postalCode: payload.postalCode,
        locality: payload.locality,
        coordinates: null
    }
}


export const editAccountContactDataMapper = (payload) => {
    //remove empty values:
    return removePropertiesWithEmptyStrings(payload.contact)
}
