import { connect } from 'react-redux'

import { LawfirmRepairClearance } from './component'
import { updateWorkflowPanelForm } from 'application/redux/actions/common/workflow_panel'

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (widgetKey, payload) => dispatch(updateWorkflowPanelForm(widgetKey, payload))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LawfirmRepairClearance)