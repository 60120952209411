import { connect } from 'react-redux'
import { CreateDamageExternal } from './component'
import { createDamageRequest, sendDocumentsRequest } from 'application/redux/actions/ext_create_damage'

const mapStateToProps = state => ({
    damageDataSent: state.pages.extCreateDamage.damageDataSent,
    damageDataSending: state.pages.extCreateDamage.damageDataSending,
    damageId: state.pages.extCreateDamage.damageId,
    documentsSent: state.pages.extCreateDamage.documentsSent,
    documentsSending: state.pages.extCreateDamage.documentsSending,
})

const mapDispatchToProps = dispatch => {
    return {
        onCreateDamage: (formData) => dispatch(createDamageRequest(formData)),
        onSendDocuments: (damageId, files, categoryId) => dispatch(sendDocumentsRequest(damageId, files, categoryId))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateDamageExternal)