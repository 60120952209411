import {
    StringContainsFilter, StaticFilter,
    OrOperator,
} from '@ec/filter-sort-page-query-js'

export const users = {
    groupId: {
        filterName: 'users_groupId',
        filterKey: 'groupId',
        label: 'group Id', 
        defaultValue: null,
        getFilterQuery: value => ([ 
            new StaticFilter(`groups[id == "${value}"]`)
        ])
    },
    name: {
        filterName: 'users_name',
        filterKey: 'auun',
        label: 'Name',
        defaultValue: null,
        getFilterQuery: value => ([
            new StringContainsFilter('firstName', value, false),
            new OrOperator(),
            new StringContainsFilter('lastName', value, false)
        ])
    },
    getUsersInAdminGroups: {
        filterName: 'users_adminUsers',
        filterKey: 'uiag',
        label: 'Admin users',
        defaultValue: null,
        isStatic: true,
        getFilterQuery: () => ([
            new StaticFilter('groups[[roles[[id == 101]]]]')
        ])
    },
}