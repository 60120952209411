import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'

export const AddressDataPartialForm = ({
    register,
    disabled = false,
    onChange = () => {},
    nameStreet = 'address.line1',
    namePostalCode = 'address.postalCode',
    nameCity = 'address.locality',
    errorNameStreet = null,
    errorNamePostalCode = null,
    errorNameCity = null,
    validate = null,
    validatePostalCode = null
}) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <TextInput 
            label={t('Street and house number')}
            name={nameStreet}
            register={register}
            validate={validate}
            error={errorNameStreet}
            disabled={disabled}
            onChange={onChange}
        />
        <FlexBox>
            <FlexBox flexBasis='120px'>
                <TextInput 
                    label={t('Postal code')}
                    name={namePostalCode}
                    register={register}
                    validate={validatePostalCode}
                    error={errorNamePostalCode}
                    disabled={disabled}
                    onChange={onChange}
                />
            </FlexBox>
            <FlexBox flexBasis='12px' flexGrow='0' flexShrink='0' />
            <FlexBox >
                <TextInput 
                    label={t('City')}
                    name={nameCity}
                    register={register}
                    validate={validate}
                    error={errorNameCity}
                    disabled={disabled}
                    onChange={onChange}
                />
            </FlexBox>
        </FlexBox>
    </React.Fragment>
}