import { ROUTE_PARAMETERS } from 'application/constants'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import ActivityLogWorkflowPanel from './component'

const ActivityLogWorkflowPanelEffects = (props) => {
    const { loggedIn, onLoadActivityLog, activityLoaded, sortDirection = ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING } = props

    useEffect(() => {
        loggedIn && !activityLoaded && onLoadActivityLog(0, 10, sortDirection)
    }, [loggedIn, onLoadActivityLog, activityLoaded, sortDirection])

    if (activityLoaded) {
        return <ActivityLogWorkflowPanel {...props} />
    }

    return <React.Fragment />
}

export default withRouter(ActivityLogWorkflowPanelEffects)