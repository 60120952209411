import { connect } from 'react-redux'

import { TaskDamageCreateInvoice } from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => {
    return {
        onCreateInvoice: (damageId, incidentDate) => dispatch(openDialogByName(DIALOG.NAME.CREATE_INVOICE_LAWYER_COMMUNICATION, true, { damageId, incidentDate }, 'Create invoice'))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageCreateInvoice)