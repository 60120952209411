import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import NotificationSettingsComponent from './component'

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => {
    return {
        onSaveNotificationSettings: (
            dialogName,
            payload,
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName,
                    payload,
                    metaData
                )
            )
        }
    }
}

export const NotificationSettings = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationSettingsComponent)