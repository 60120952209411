import { connect } from 'react-redux'
import CallbackPage from './component'
import { createAccount } from 'application/redux/actions/registration'

const mapDispatchToProps = dispatch => {
    return {
        onCreateAccount: (formData, sharedResourceId, idToken, ssoAccessToken) => dispatch(createAccount(formData, sharedResourceId, idToken, ssoAccessToken))
    }
}

export default connect(
    state => ({
        accountCreated: state.registration.accountCreated
    }),
    mapDispatchToProps
)(CallbackPage)