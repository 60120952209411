import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { ActionButton } from '@ec/ui-controls-react'
import { TextInput } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'
import { AddressDataPartialForm } from '../_partial_forms'
import TwoColumns from '../_common'

const DamageWitnessForm = ({formData = {}, damageId, onSave, disabled}) => {
    const {t} = useTranslation()
    
    const defaultValues = {
        firstName: formData?.witness?.firstName || null,
        lastName: formData?.witness?.lastName || null,
        line1: formData?.witness?.address?.line1 || null,
        postalCode: formData?.witness?.address?.postalCode || null,
        locality: formData?.witness?.address?.locality || null,
    }

    const {register, errors, handleSubmit, getValues} = useForm({ defaultValues })

    const onSubmitSuccess = () => { 
        onSave(damageId, getValues())
    }
    
    return <React.Fragment>
        <TwoColumns 
            columnLeft={
                <TextInput 
                    label={t('First name')}
                    name='firstName'
                    register={register}
                    validate={null}
                    error={errors.firstName}
                    disabled={disabled}
                />
            }
            columnRight={
                <TextInput 
                    label={t('Last name')}
                    name='lastName'
                    register={register}
                    validate={null}
                    error={errors.lastName}
                    disabled={disabled}
                />
            }
        />

        <AddressDataPartialForm
            register={register}
            errors={errors}
            disabled={disabled}
        />

        <FlexBox justifyContent='flex-end'>
            <ActionButton buttonText={t('Save witness data')} onButtonClick={handleSubmit(onSubmitSuccess)} />
        </FlexBox>
    </React.Fragment>
}

export { DamageWitnessForm }