import React from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import styled from 'styled-components'

const StyledList = styled.div`
    display: flex;
    min-height: 200px;
    flex-direction: column;
    justify-content: flex-start;
`

const DotWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
`

const StyledDot = styled.div`
    width: 10px;
    height: 10px;
    background: ${props => props.theme.color.primary};
    border-radius: 50%;
`

const StyledListElement = styled.div`
    display: flex;
    margin-bottom: 4px;
    height: 35px;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.color.gray5};
`

const StyledListLabel = styled.div`
    display: flex;
    align-items: center;
`

const StyledIconButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    cursor: pointer;

    & > i {
        font-size: 14px;
    }
`

export default ({list, onClear, getElementToolTip = () => null}) => {
    return <StyledList>
        {
            list.map(
                (element, i) => {
                    const toolTip = getElementToolTip(element)
                    return <StyledListElement key={i} title={toolTip}>
                        <FlexBox>
                            <DotWrapper>
                                <StyledDot />
                            </DotWrapper>
                            <StyledListLabel>
                                {element.label}
                            </StyledListLabel>
                        </FlexBox>
                        <StyledIconButton
                            onClick={
                                () => { 
                                    onClear(element) 
                                }
                            }
                        >
                            <i className='material-icons'>clear</i>
                        </StyledIconButton>
                    </StyledListElement> 
                }
            )
        }
    </StyledList>
}