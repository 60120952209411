import styled from 'styled-components'

export const StyledHiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`


export const StyledCheckbox = styled.div`
  display: flex;
  width: ${props => props.isSmall ? '20px' : '35px'};
  height: ${props => props.isSmall ? '20px' : '35px'};
  background: white;
  border: 1px solid ${props => props.theme.color.gray15};
  transition: all 150ms;
  padding: 5px;
  cursor: ${props => props.cursor ? props.cursor : 'auto'};

  justify-content: center;
  align-items: center;
`

export const StyledCheckBoxWithLabel = styled.div`   
    display: flex;
    width: auto;

    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    opacity: ${props => props.disabled ? '0.5' : '1'};

    & label {
        display: flex;
        flex-direction: row;
        cursor: ${props => props.disabled ? 'auto' : 'pointer'};
    }
`

export const StyledLabel = styled.div`
    display: flex;
    flex-grow: 1;
    padding-left: 8px;
    align-items: center;
    justify-content: space-between;
    font-size: ${props=>props.fontSize!=='' ? props.fontSize : props.theme.fontSize.standard};
    color: ${props => props.isActive ? props.theme.color.primary : props.theme.color.anthracite};
`

export const ActiveLabel = styled.span`
    font-weight: bold;
    padding-left: 4px;
`


export const StyledIcon = styled.i`
    visibility: ${props => props.checked ? 'visible' : 'hidden'};
    align-self: center;
    color: ${props => props.activeColor ? props.theme.color.primary : props.theme.color.anthracite};

    ${
    props => props.isSmall 
        ? `
                font-size: 14px;
                font-weight: bold;
            ` 
        : ''
}
`