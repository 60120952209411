import React, {useEffect} from 'react'

import { GlobalStyle } from 'application/style'
import * as Fragments from './fragments'
import Toaster from 'application/components/building_blocks/toaster'
import WaitingScreen from 'application/components/building_blocks/waiting_screen'
import { withRouter } from 'react-router-dom'
import ErrorDisplay from 'application/components/building_blocks/error_display'
import { exists } from 'application/common/data_helpers'

const LayoutBaseWorkflowPanel = ({ 
    sharedResource,  
    waitingScreen,
    onValidatePin,
    children,
    match,
    dialogsOpen,
    storePin
}) => {
    const hasPin = exists(sharedResource.pin)
    const pin = sharedResource.pin || ''
    const sharedResourceId = match.params.sharedResourceId
    const shouldValidatePin = !sharedResource.pinValidationPending 

    useEffect(() => {
        hasPin && shouldValidatePin && onValidatePin(pin, sharedResourceId, storePin)
    }, [hasPin, pin, onValidatePin, sharedResourceId, storePin, shouldValidatePin])

    return <React.Fragment>
        <GlobalStyle />
        <Fragments.Wrapper blurred={waitingScreen.active && !dialogsOpen && !waitingScreen.inDialog}>
            {children}
        </Fragments.Wrapper>
        <ErrorDisplay />
        <Toaster />
        <WaitingScreen />
    </React.Fragment>
}

export default withRouter(LayoutBaseWorkflowPanel)