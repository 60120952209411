import { connect } from 'react-redux'
import Thumbnail from './load_thumbnail_hoc'
import {getThumbnailById} from 'application/redux/actions/attachments'

const mapStateToProps = (state) => {
    return {
        attachments: state.attachments
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onAttachmentClick: (attachment) => {
            props.onAttachmentClick(attachment)
        },
        onLoadAttachmentThumbnail: (
            id, 
            attachmentName, 
            damageId, 
            taskId, 
            match
        ) => dispatch(
            getThumbnailById(
                id, 
                attachmentName, 
                {
                    damageId,
                    taskId, 
                    match
                }
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Thumbnail)