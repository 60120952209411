import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'
import { navbarOpenState } from 'application/storage'

export default (
    state = {
        ...defaultStore.mainNavigationSidebar,
        sideBarOpen: navbarOpenState.get()
    }, 
    action
) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return {
            ...defaultStore.mainNavigationSidebar,
            sideBarOpen: navbarOpenState.get()
        }
    }
    case ACTIONS.MAIN_NAVIGATION_SIDEBAR_TOGGLE:
        navbarOpenState.store(!state.sideBarOpen)
        return {
            ...state,
            sideBarOpen: !state.sideBarOpen
        }

    case ACTIONS.MAIN_NAVIGATION_REGISTER_ACCORDION_MENU_STATE:
        const newAccordionMenuState = {                 
            name: action.name,
            accordionOpen: action.accordionOpen
        }

        return {
            ...state,
            registeredAccordionMenus: [
                ...state.registeredAccordionMenus,
                newAccordionMenuState
            ]
        }

    case  ACTIONS.MAIN_NAVIGATION_OPEN_ONLY_CURRENT_ACCORDION:
        return {
            ...state,
            registeredAccordionMenus: state.registeredAccordionMenus.map(
                accordionDefinition => {
                    return accordionDefinition.name === action.name 
                        ? {
                            ...accordionDefinition,
                            accordionOpen: true
                        }
                        : {
                            ...accordionDefinition,
                            accordionOpen: false
                        }
                }
            )
        }
    case ACTIONS.MAIN_NAVIGATION_CLOSE_ACCORDIONS:
        return {
            ...state,
            registeredAccordionMenus: state.registeredAccordionMenus.map(
                accordionMenu => {
                    return {
                        ...accordionMenu,
                        accordionOpen:false
                    } 
                }
            )
        }

    default:
        return state
    }
}
