import ACTIONS from 'application/constants'

export const showGallery = (damageId, attachmentId = null, attachmentName = '', taskId = null, match) => ({
    type: ACTIONS.GALLERY_SHOW_GALLERY,
    damageId,
    attachmentId,
    attachmentName,
    taskId,
    match
})

export const setActiveAttachment = (damageId, attachmentId, attachmentName) => ({
    type: ACTIONS.GALLERY_SET_ACTIVE_ATTACHMENT,
    damageId,
    attachmentId,
    attachmentName
})

export const hideGallery = () => ({
    type: ACTIONS.GALLERY_HIDE_GALLERY
})