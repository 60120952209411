import { 
    put, 
    select,
    all,
    call
} from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { getCurrentBranchId } from 'application/redux/selectors'
import { ROUTE_PARAMETERS } from 'application/constants'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* widgetLastCustomersSaga(action){

    const currentBranchId = yield select(getCurrentBranchId)
    const offset = 0
    const limit = 6
    const orderBy = ROUTE_PARAMETERS.CUSTOMERS.ORDER_BY.VALUES.CREATED_AT
    const direction = ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING
    const filterQuery = []

    const response = yield resourceCall(
        api.gateway.customer.getAllCustomers,
        {
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        }
    )

    const getCustomerQrCodeResourceServerCalls = response.customers.map(customer => function* () {
        const sharedResourceResponse = yield resourceCall(
            api.gateway.customer.putCustomerQRCodeResource,
            { 
                customerId: customer.id,
                branchId: currentBranchId
            }
        )
        customer.sharedResource = sharedResourceResponse
    })

    yield all(getCustomerQrCodeResourceServerCalls.map(sc => call(sc)))

    yield put(
        updateWidget(
            action.key, 
            action.dependentWidgetKeys,
            {
                ...response, 
                isLoaded: true
            }
        )
    )
}