import React from 'react'
import { Table } from 'application/components/tables/_common'
import { configuration } from './configuration'
import { withRouter } from 'react-router-dom'
import { useMassOperationsClose, useTableLoader } from 'application/components/hooks'
import { withMassOperations } from 'application/components/context/mass_operations'

export const TableMyTasksOverview = withMassOperations(withRouter((
    {
        tasksListLoading,
        tasksListLoaded,
        tasksListTotal,
        tasksList,
        moName,
        closeMoByName,
        onLoadTasks,
        updateConstraint = null,
        match,
        currentUserId
    }
) => {
    useTableLoader(
        onLoadTasks, 
        match, 
        configuration, 
        updateConstraint, 
        {userId: currentUserId}
    )

    useMassOperationsClose(
        moName, 
        closeMoByName, 
        tasksList
    )
    
    return <Table 
        configuration={configuration}
        elementsLoading={tasksListLoading}
        elementsTotal={tasksListTotal}
        elementsLoaded={tasksListLoaded}
        elements={tasksList}
        emptyMessage='No tasks found'
        moName={moName}
    />
}))
