import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {MessageListWFP} from 'application/components/building_blocks/message/list'
import { withRouter } from 'react-router-dom'
import { FlexDividerVertical } from 'application/components/fragments/flex_divider'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'

const WFPMessageListWidget = ({
    widgetKey, 
    match, 
    payload, 
    loadingFailed, 
    onPaginationChange 
}) => {
    const { t } = useTranslation()

    if (loadingFailed) {
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }
      
    return <React.Fragment>
        <FlexBox>
            <MessageListWFP 
                getMessageUrl={
                    (sharedResourceId, messageId) => {
                        return  ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/message/' + messageId
                    }
                }
                baseUrlParams={ROUTE_PARAMETERS.WORKFLOW_PANEL.INBOX}
                messages={payload.messages}
                messagesTotal={payload.total}
                selectedMessageId={match.params.messageId}
                onPaginationChange={
                    (offset, limit) => { 
                        onPaginationChange(
                            widgetKey, 
                            offset, 
                            limit
                        )
                    }
                }
            />
            <FlexDividerVertical />
        </FlexBox>
    </React.Fragment>
}

WFPMessageListWidget.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export default withRouter(WFPMessageListWidget)