import React from 'react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { withCreateDamageQuickStartSteps } from './with_create_damage_steps'
import { compose } from 'redux'
import {CreateDamageStep} from './step_0'
import {CommissionStep} from './step_1'
import { notNull } from 'application/common/data_helpers'
import { DialogStepsLayout } from '../../_common/steps_layout'



const CreateDamageQuickStartComponent = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged,
        dialog,
        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep,
    }
) => {
    
    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const { 
        createdDamage = null,
        customer = null
    } = dialog.payload

    return <React.Fragment>
        <DialogStepsLayout cssMaxWidth='400px'  getStepsState={getStepsState}>
            {
                getCurrentStep().id === 0 && <CreateDamageStep
                    abort={abort}
                    onDataChange={onDataChange}
                    getStepsState={getStepsState} 
                    goToNextStep={goToNextStep}
                    dialog={dialog}
                />
            }
            {
                notNull(createdDamage) && <React.Fragment>
                    {
                        getCurrentStep().id === 1 
                    && <CommissionStep
                        abort={abort}
                        goToNextStep={goToNextStep}
                        onDataChange={onDataChange}
                        customer={customer}
                        damageId={createdDamage.id}
                        dialogName={dialog.name}
                        damageType={createdDamage.type}
                        dialog={dialog}
                    />
                    }
                   
                </React.Fragment>
            }

        </DialogStepsLayout>




    </React.Fragment>

}


export const CreateDamageQuickStart = compose(
    withCreateDamageQuickStartSteps(),
    withDialogDataLoader
) (CreateDamageQuickStartComponent)