import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const putCustomer = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(
                `customers/${params.customerId}`, 
                HTTP_METHOD.PUT, 
                payload
            )
            return apiCall(request)
        },
        sharedResource: () => null
    }
)