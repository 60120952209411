import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { TextInput } from 'application/components/controls/form'
import { FlexBox }  from 'application/components/fragments/flex_box'
import TwoColumns from 'application/components/forms/_common'
import YesNoSelect from 'application/components/controls/form/yes_no_select/component'
import { validationMethods } from 'application/common'
import { useStatefulForm } from 'application/components/hooks'
import { AddressDataPartialForm } from '../_partial_forms'

const DamageOpponentKeeperForm = ({formData = {}, damageId, onSave}) => {
    const {t} = useTranslation()

    const defaultValues = {
        isCompany: formData?.vehicle?.keeper?.isCompany,
        firstName: formData?.vehicle?.keeper?.firstName || null,
        lastName: formData?.vehicle?.keeper?.lastName || null,
        companyName: formData?.vehicle?.keeper?.companyName || null,
        line1: formData?.vehicle?.keeper?.address?.line1 || null,
        postalCode: formData?.vehicle?.keeper?.address?.postalCode || null,
        locality: formData?.vehicle?.keeper?.address?.locality || null
    }
    
    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        setValue,
        formStateValues,
        updateFormState
    } = useStatefulForm({ defaultValues })

    const onSubmitSuccess = () => { 
        onSave(damageId, {
            ...formStateValues, //include all values
            ...getValues()
        })
    }

    return <FlexBox flexDirection='column'>
        <TwoColumns 
            columnLeft={
                <YesNoSelect 
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.isCompany}
                    name='isCompany'
                    label={t('Keeper is company')}
                    onChange={updateFormState}
                />
            }
        />
        {
            formStateValues.isCompany ?
                <TextInput 
                    label={t('Company name')}
                    name='companyName'
                    register={register}
                    validate={formStateValues.isCompany ? {notEmpty: validationMethods.notEmpty} : null}
                    error={errors.companyName}
                    onChange={updateFormState}
                />
                : <React.Fragment>
                    <TwoColumns
                        columnLeft={
                            <TextInput 
                                label={t('First name')}
                                name='firstName'
                                register={register}
                                validate={formStateValues.isCompany ? null : {notEmpty: validationMethods.notEmpty}}
                                error={errors.firstName}
                                onChange={updateFormState}
                            />
                        }

                        columnRight={
                            <TextInput 
                                label={t('Last name')}
                                name='lastName'
                                register={register}
                                validate={formStateValues.isCompany ? null : {notEmpty: validationMethods.notEmpty}}
                                error={errors.lastName}
                                onChange={updateFormState}
                            />
                        }
                    />
                </React.Fragment>
        }

        <AddressDataPartialForm
            register={register}
            errors={errors}
            onChange={updateFormState}
        />
                    
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save opponent keeper data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </FlexBox>
}

export { DamageOpponentKeeperForm }