import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ButtonLink from 'application/components/building_blocks/main_navigation_sidebar/_common/button_link'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import PropTypes from 'prop-types'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label'
import { ROUTES, USER_PERMISSONS } from 'application/constants'
import { getBranchLinkHref } from 'application/common/route_helpers'



export const MenuButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    & .open-branch-specific-dialog-button {
        border: 1px solid ${props => props.theme.color.white};
        background: ${props => props.theme.color.primary};
        color: ${props => props.theme.color.white};
        padding: 8px;
    }

    & .open-branch-specific-dialog-button i {
        color: ${props => props.theme.color.white};
    }

    & .open-branch-specific-dialog-button:hover {
        background: ${props => props.theme.color.primaryHover};
    }
`


const BranchSpecificAccordionMenu = (
    {
        onOpenChangeBranchDialog,
        match,
        primaryColor,
        primaryHoverColor,
        currentBranch,
        hasMultipleBranches
    }
) => {
    const {t} = useTranslation()

    if(currentBranch === null){
        return null
    }
    const branchId = currentBranch.id

    return  <React.Fragment>
        <ButtonLink 
            label={'Overview'}
            pathDestination={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_DASHBOARD, branchId)}
            isActive={
                match.path === ROUTES.BRANCH_SPECIFIC_DASHBOARD 
            }
            iconKey={'widgets'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.DAMAGE.READ]} 
            allNeeded={false}
        >
            <ButtonLink 
                label={'Damages'}
                pathDestination={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW, branchId)}
                isActive={
                    match.path === ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW 
                || match.path === ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES 
                || match.path === ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ARCHIVED_DAMAGES 
                || match.path === ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES
                }
                iconKey={'directions_car'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.TASK.READ]} 
            allNeeded={false}
        >
            <ButtonLink 
                label={'Tasks'}
                pathDestination={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW, branchId)}
                isActive={
                    match.path === ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW 
                || match.path === ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_ALL_TASKS 
                || match.path === ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS
                }
                iconKey={'description'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.MESSAGE.READ]} 
            allNeeded={false}
        >
            <ButtonLink 
                label={'Inbox'}
                pathDestination={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_INBOX, branchId)}
                isActive={
                    match.path === ROUTES.BRANCH_SPECIFIC_INBOX 
                || match.path === ROUTES.BRANCH_SPECIFIC_INBOX_PRESELECTED
                }
                iconKey={'mail_outline'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.CONTROLLING.READ]}
        >
            <ButtonLink 
                label={'Controlling'}
                pathDestination={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_CONTROLLING, branchId)}
                isActive={
                    match.path === ROUTES.BRANCH_SPECIFIC_CONTROLLING 
                || match.path === ROUTES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS 
                || match.path === ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES
                }
                iconKey={'bar_chart'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForCurrentBranchPermissions>
        <ButtonLink 
            label={'Activities'}
            pathDestination={ getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_ACTIVITY_LOG, branchId) }
            isActive={ match.path === ROUTES.BRANCH_SPECIFIC_ACTIVITY_LOG }
            iconKey={'list'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
        {
            hasMultipleBranches && <MenuButtonWrapper>
                <IconButtonWithLabel
                    className={'open-branch-specific-dialog-button'}
                    iconKey='swap_horiz'
                    iconSize='18px'
                    label={t('Change branch')}
                    onButtonClick={onOpenChangeBranchDialog}
                    hoverBackground={primaryHoverColor}
                />
            </MenuButtonWrapper>
        }
    </React.Fragment>
}

BranchSpecificAccordionMenu.propTypes = {
    match: PropTypes.object.isRequired,
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired,
    onOpenChangeBranchDialog: PropTypes.func,
    currentBranch: PropTypes.object,
    hasMultipleBranches: PropTypes.bool
}


export default BranchSpecificAccordionMenu