import { updateStatus } from 'application/redux/actions/pages/damage_manager/overview'
import { connect } from 'react-redux'

import DamageStatusSelect from './component'

const mapStateToProps = (state) => {
    const { shared } = state.pages.damageManager
    const { statusList } = state.common

    return {
        statusList: statusList.data,
        statusListLoaded: statusList.loaded,
        damage: shared.damage,
        damageLoaded: shared.damageLoaded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateState: (
            damageId, 
            stateId
        ) => dispatch(
            updateStatus(
                damageId, 
                stateId
            )
        ),
    
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageStatusSelect)