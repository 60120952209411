import { connect } from 'react-redux'
import DamageManagerEffects from './effects'
import {  requestDamage } from 'application/redux/actions/pages/damage_manager'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { withStatuslistLoader } from 'application/components/higher_order_components'

const mapStateToProps = (state) => {

    const {
        damage, 
        damageLoading,
        damageLoaded,
        hasLawfirm
    } = state.pages.damageManager.shared

    
    return {
        damageLoading,
        damageLoaded,
        damage,
        hasLawfirm
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadDamage: (damageId) => dispatch(
            requestDamage(damageId)
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withLoggedInUser(
        withStatuslistLoader(
            DamageManagerEffects
        )
    )
)