import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextInput, TextArea, DatePicker } from 'application/components/controls/form'
import PrioritySelect from 'application/components/controls/form/priority_select'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import TwoColumns from 'application/components/forms/_common'
import { useStatefulForm } from 'application/components/hooks'
import { isBeforeToday } from 'application/common/date_time_helpers'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'

const EditTask = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged, 
        onSaveTask, 
        dialog, 
        formData 
    }
) => {
    const damageIncidentDate = dialog.payload.damage?.incidentDate
    const { t } = useTranslation()
    const {
        register, 
        errors,
        handleSubmit, 
        getValues, 
        setValue, 
        control, 
        formStateValues, 
        updateFormState
    } = useStatefulForm({defaultValues: formData})

    const {damageId, task} = dialog.payload
    const [deadlineChanged, setDeadlineChanged] = useState(false)

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
        updateFormState()
    }

    const onSubmitSuccess = () => { 
        onSaveTask(dialog.name, damageId, task, {
            ...getValues()
        })
    }

    const onError = (errors, e) => console.log(errors, e)

    return <React.Fragment>
        <DialogMainContent cssHeight='330px'>
            <FlexBox flexDirection='column'>
                <TwoColumns 
                    columnLeft={
                        <TextInput 
                            label={t('Title')}
                            name='title'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.title}
                            onChange={onDataChange}
                        />
                    }
                    columnRight={
                        <DatePicker 
                            label={t('Deadline')}
                            name='deadline'
                            validate={null}
                            error={errors.deadline}
                            disabledDays={{before: new Date(damageIncidentDate)}}
                            onDateChange={()=>{
                                onDataChange()
                                setDeadlineChanged(true)
                            }}
                            control={control}
                        />
                    }
                />
                <TwoColumns 
                    columnLeft={
                        <TextArea 
                            label={t('Description')}
                            name='description'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.description}
                            onChange={onDataChange}
                        />
                    }
                    columnRight={
                        <PrioritySelect 
                            register={register}
                            setValue={setValue}
                            error={errors.damageTyp}
                            initialValue={formStateValues.priority}
                            onChange={onDataChange}
                        />
                    }
                />
            </FlexBox>
 
        </DialogMainContent>
        <Footer
            showDeadlineInfo={deadlineChanged && isBeforeToday(formStateValues.deadline)}
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess, onError)}
            submitText='Save task'
        />
    </React.Fragment>
}

export default withDialogDataLoader(EditTask)