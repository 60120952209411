import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import {MessageListInternal} from 'application/components/building_blocks/message/list'
import { useLocation, withRouter } from 'react-router-dom'
import { FlexDividerVertical } from 'application/components/fragments/flex_divider'
import { getDamageManagerLinkHref } from 'application/common/route_helpers'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'

const DamageManagerInboxMessageList = (
    {
        widgetKey,
        match,
        payload,
        loadingFailed, 
        onPaginationChange 
    }
) => {
    const { t } = useTranslation()
    const location = useLocation()

    if (loadingFailed) {
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    return <FlexBox>
        <MessageListInternal
            getMessageUrl={(damageId, messageId) => {
                return getDamageManagerLinkHref(
                    match, 
                    ROUTES.DAMAGE_MANAGER_INBOX + '/' + messageId, 
                    damageId
                ) + location.search
            }}
            baseUrlParams={ROUTE_PARAMETERS.DAMAGE_MANAGER_INBOX}
            messages={payload.messages}
            messagesTotal={payload.total}
            selectedMessageId={match.params.messageId}
            onPaginationChange={
                (offset, limit) => { 
                    onPaginationChange(widgetKey, offset, limit)
                }
            }
        />
        <FlexDividerVertical />
    </FlexBox>
}

DamageManagerInboxMessageList.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export default withRouter(DamageManagerInboxMessageList)