import { connect } from 'react-redux'

import EditTaskDataMapper from './data_mapper'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        onSaveTask: (name, damageId, taskId, payload, previousTaskData) => dispatch(sendPayloadRequest(name, payload, {damageId, previousTaskData, taskId}))
    }
}

export  const EditTask = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTaskDataMapper)