import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (
    state = defaultStore.pages.workflowPanel.shared, 
    action
) => {
    switch (action.type) {
    case ACTIONS.WORKFLOW_PANEL_SHARED_POPULATE_ACCOUNT_DATA: {
        return {
            ...state,
            account: action.account,
            branch: action.branch
        }
    }
    case ACTIONS.WORKFLOW_PANEL_SHARED_POPULATE_DAMAGE_DATA: {
        return {
            ...state,
            damage: action.damage,
            damageLoaded: true
        }
    }
    case ACTIONS.WORKFLOW_PANEL_SHARED_POPULATE_LAWFIRM: {
        return {
            ...state,
            lawfirm: action.lawfirm,
            lawfirmLoaded: true
        }
    }
    case ACTIONS.WORKFLOW_PANEL_SHARED_UPDATE_INVOICES: {
        return {
            ...state,
            invoices: state.invoices.map(d => {
                return d.id === action.invoiceId ? {
                    ...d,
                    ...action.invoices
                } : d
            })
        }
    }
    case ACTIONS.WORKFLOW_PANEL_SHARED_REFRESH_INVOICES: {
        return {
            ...state,
            invoices: []
        }
    }
    case ACTIONS.WORKFLOW_PANEL_SHARED_CATEGORIES_REQUEST:
        return {
            ...state,
            categories: [],
            categoriesLoaded: false,
            categoriesLoading: true
        }
    case ACTIONS.WORKFLOW_PANEL_SHARED_POPULATE_CATEGORIES:
        return {
            ...state,
            categories: action.categories,
            categoriesLoaded: true,
            categoriesLoading: false
        }
    default:
        return state
    }
}