import { composeFilterQuery } from 'application/common/filter_helpers'
import { getCustomersForDialogRequestDebounced } from 'application/redux/actions/common'
import { connect } from 'react-redux'

import CustomerSearch from './component'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onSearchCustomer: (dialogName, search, istFirma) => dispatch(
            getCustomersForDialogRequestDebounced(
                dialogName, 
                0, 
                10, 
                'lastname',
                'desc',
                composeFilterQuery([
                    new ListFilter(filters.customers.isCompany).setValue(istFirma),
                    new ListFilter(filters.customers.name).setValue(search)
                ])
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerSearch)