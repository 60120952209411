  
import React, {useEffect, useState} from 'react'
import { ActionButton } from '@ec/ui-controls-react'
import { useHistory } from 'react-router-dom'
import { CallbackComponent } from 'redux-oidc'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { validationMethods } from 'application/common'
import { TextInput } from 'application/components/controls/form'
import { defaultUserManager } from 'application/common/user_manager'
import RegistrationLayout from 'application/components/pages/_registration_layout'
import { ROUTES } from 'application/constants'

export default ({accountCreated, onCreateAccount}) => {
    const history = useHistory()
    const { t } = useTranslation()
    const [accessToken, setAccessToken] = useState()
    const [idToken, setIdToken] = useState()
    const [sharedResourceId, setSharedResourceId] = useState()
    const {register, errors, getValues, handleSubmit} = useForm()

    useEffect(() => {
        accountCreated && history.push(ROUTES.CHOOSE_ACCOUNT)
    }, [accessToken, accountCreated, history])

    const onSubmitSuccess = () => {
        onCreateAccount(getValues(), sharedResourceId, idToken, accessToken)
    }

    return (
        <CallbackComponent
            userManager={defaultUserManager}
            successCallback={(user) => {
                setAccessToken(user.access_token)
                setIdToken(user.id_token)
                setSharedResourceId(user.state.sharedResourceId)
            }}
            errorCallback={error => {
                console.error(error)
                history.push('/')
            }}
        >
            <RegistrationLayout>
                <TextInput 
                    label={t('Name of car dealership')}
                    name='accountName'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.accountName}
                />
                <ActionButton buttonText={t('Done')} onButtonClick={handleSubmit(onSubmitSuccess)} />
            </RegistrationLayout>
        </CallbackComponent>
    )
}