import React from 'react'
import CategorySelect from 'application/components/controls/form/category_select'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Paragraph } from 'application/components/fragments/typography'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Footer } from 'application/components/dialogs/_common/footer'
import { useStatefulForm } from 'application/components/hooks'
import { exists } from 'application/common/data_helpers'
import { VDivider } from 'application/components/fragments/v_divider'
import { FilesListWithLawyerFlag } from 'application/components/building_blocks/files_list_with_lawyer_flag'
import {withExportToLawfirmConfirmationDialog} from 'application/components/higher_order_components'
import { INFOBOX } from 'application/constants'
import InfoBox from 'application/components/controls/info_box'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'



 
const CategorySelectDialogComponent = (
    {
        abort, 
        dialog,
        //from withExport... hoc
        flaggedFiles,
        setFlaggedFiles,
        onSubmitWithExportToLawfirmConfirmation
    }
) => {
    const { t } = useTranslation()

    const defaultValues = {
        category: dialog.payload.categoryId
    }

    const useDefaultCategory = exists(dialog.payload.useDefaultCategory) 
        ? dialog.payload.useDefaultCategory 
        : false
    
    const {
        register, 
        setValue, 
        getValues, 
        formStateValues, 
        updateFormState
    } = useStatefulForm({defaultValues})   

    const uploadFiles = () => {
        const payload = { files:flaggedFiles }
        const metaData = { 
            taskId: dialog.payload.taskId, 
            damageId: dialog.payload.damageId, 
            sharedResourceId: dialog.payload.sharedResourceId, 
            categoryId: getValues().category
        }

        onSubmitWithExportToLawfirmConfirmation(
            payload,
            metaData
        )
        //Hook potential
    }

    const hasFiles = flaggedFiles.length > 0

    return <React.Fragment>
        <DialogMainContent cssHeight='400px'>
            {
                hasFiles
                    ? 
                    <FlexBox flexDirection='column'>
                        <FlexBox flexBasis='70px'>
                            <Paragraph>
                                {t('Please select a category for these attachments.')}
                            </Paragraph>
                        </FlexBox>
                        <FlexBox 
                            basis='100%' 
                            height='312px'
                        >   
                            <FlexBox 
                                flexDirection='column' 
                                flexBasis='50%'
                                flexGrow='0'
                                flexShrink='0'
                                cssRules='overflow-x:hidden;'
                            >
                                <FilesListWithLawyerFlag 
                                    files={flaggedFiles}
                                    setFiles={setFlaggedFiles}
                                />
                            </FlexBox>
                            <VDivider/>
                            <FlexBox 
                                flexDirection='column' 
                                flexBasis='50%'
                            >
                                <CategorySelect
                                    register={register}
                                    setValue={setValue}
                                    initialValue={formStateValues.category}
                                    fixedDefaultCategory={useDefaultCategory}
                                    disabled={useDefaultCategory}
                                    onChange={updateFormState}
                                /> 
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                    :
                    <InfoBox
                        type={INFOBOX.TYPES.WARNING} 
                        direction='row' 
                        cssMargin='14px 0 0 0'
                    >
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        <span>{t('There are no files')}</span>
                        <Spacer />
                    </InfoBox>
            }

        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={uploadFiles}
            submitText={ 'Save' }
            disabled={ !hasFiles }
        />
    </React.Fragment>

}

export const CategorySelectDialog =  withExportToLawfirmConfirmationDialog(CategorySelectDialogComponent)