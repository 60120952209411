import { put } from 'redux-saga/effects'
import { ROUTES } from 'application/constants/routes'
import { history } from 'application/common/route_helpers'
import { requestCurrentBranchChange } from 'application/redux/actions/auth'
import { getBranchLinkHref } from 'application/common/route_helpers'
import * as storage from 'application/storage'

export function* changeCurrentBranchFromDialogSaga(action) {
    const {branch} = action.payload
    const {match} = action.metaData

    yield storage.currentBranch.store(match.params.accountId, branch.id)

    //if active page is branch specific, navigate to the dashboard of the new branch
    if(match.params.branchId !== undefined){
        const newRoute = getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_DASHBOARD, branch.id)
        yield history.push(newRoute)
    }else{
        //if active page is NOT branch specific, stay where you are but load new branch info
        yield put(requestCurrentBranchChange(branch.id))
    }
}