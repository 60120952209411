import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {ActionButton} from '@ec/ui-controls-react'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { withRouter } from 'react-router-dom'


const TaskDamageCreateInvoiceComponent = ({ onCreateInvoice, match, payload }) => {
    const {t} = useTranslation()
    const {damageId} = match.params
    const {incidentDate} = payload.damage

    if(damageId === undefined){
        return <WidgetErrorMessage>{t('widget must be used within damage manager')}</WidgetErrorMessage>
    }

    return <p>
        <ActionButton 
            buttonText={t('Add invoice')}
            onButtonClick={() => onCreateInvoice(damageId, incidentDate)}
            strechted={false}
        />
    </p>
}

TaskDamageCreateInvoiceComponent.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

const TaskDamageCreateInvoice = withRouter(TaskDamageCreateInvoiceComponent)

export { TaskDamageCreateInvoice }