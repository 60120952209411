import React, { useState } from 'react'
import { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'

import { StyleButtonToggle, StyleDisplayToggle, StyledToggleText, StyledWrapperSubCategory, StyledAttachmentsContainer, StyledToggleTextSecondary } from './fragments'
import FileButton from 'application/components/controls/file_button'

const GalleryTree = ({ category, theme, documents, onAttachmentClick, damageId, selectedAttachmentId }) => {
    const hasChildren = category.categories !== undefined && category.categories.length > 0
    const appliedDocuments = documents.filter(d => d.category.id === category.id)
    const hasContent = appliedDocuments.length > 0
    const [isOpen, setIsOpen] = useState(hasContent || hasChildren)

    return <StyledWrapperSubCategory>
        <StyleButtonToggle onClick={() => setIsOpen(!isOpen)}>
            <FontIcon icon={isOpen ? 'expand_more' : 'chevron_right'} fontSize={theme.fontSize.medium} color={theme.color.gray70} />
            <StyledToggleText>
                {category.name} {hasContent && <StyledToggleTextSecondary>({appliedDocuments.length})</StyledToggleTextSecondary>} 
            </StyledToggleText>
        </StyleButtonToggle>
        <StyleDisplayToggle active={isOpen}>
            {
                hasContent && <StyledAttachmentsContainer>
                    {
                        appliedDocuments.map((ad) => {
                            return <FileButton 
                                key={ad.id}
                                isClickable={true}
                                isActive={selectedAttachmentId === ad.id}  
                                onClick={() => {onAttachmentClick({id: ad.id, fileName: ad.name})}}
                                fileName={ad.name}
                            />
                        })
                    }
                </StyledAttachmentsContainer>
            }
            {
                hasChildren && category.categories.map(sc => {
                    return <GalleryTree
                        key={sc.id}
                        category={sc}
                        theme={theme}
                        documents={documents}
                        onAttachmentClick={onAttachmentClick}
                        damageId={damageId}
                        selectedAttachmentId={selectedAttachmentId}
                    />
                })
            }
        </StyleDisplayToggle>
    </StyledWrapperSubCategory>
}

export default withTheme(GalleryTree)