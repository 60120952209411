import { connect } from 'react-redux'

import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { getAffiliatesRequest } from 'application/redux/actions/title_bar'
import TitleBarEffects from './effects'

const mapStateToProps = (state) => {
    const currentAccount = state.auth.currentAccount

    return {
        affiliatesLoaded: state.titleBar.affiliatesLoaded,
        affiliatesEffectBlocked: state.titleBar.affiliatesEffectBlocked,
        hasAccount: currentAccount !== null,
        currentAccount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateDamage: () => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_DAMAGE, 
                false, 
                {
                    usersList: [], 
                    usersListLoading: false, 
                    customerList: [], 
                    customerListLoading: false
                }, 
                'Create damage case'
            )
        ),
        onCreateDamageQuickStart: () => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_DAMAGE_QUICK_START, 
                true, 
                {}, 
                'Create damage case'
            )
        ),
        onCreateDamageDekra: () => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_DAMAGE_DEKRA, 
                false, 
                {
                    usersList: [], 
                    usersListLoading: false, 
                    customerList: [], 
                    customerListLoading: false
                }, 
                'Create damage case'
            )
        ),
        onLoadAffiliates: (
            offset = 0, 
            limit = 10, 
            search = ''
        ) => dispatch(
            getAffiliatesRequest(
                offset, 
                limit, 
                search
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TitleBarEffects)