import React from 'react'
import { DAMAGE_TYPE, ROUTES } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'
import DamageLicenseplate from 'application/components/controls/damage_licenseplate'
import DamageTypeName from 'application/components/name_mappings/damage_type_name'
import {JobNumber} from 'application/components/controls/job_number'
import FullName from 'application/components/controls/full_name'
import DamageStatus from 'application/components/controls/damage_status'
import DateComponent from 'application/components/controls/date'
import { getAccountLinkHref } from 'application/common/route_helpers'
import {CustomerNameDamage} from 'application/components/controls/customer_name_damage'
import {ClaimNumber} from 'application/components/controls/claim_number'
import RepairAuthorizationIndicator from 'application/components/controls/repair_authorization_indicator'
import { LawFirmName } from 'application/components/name_mappings/lawfirm_name'



export const configuration = {
    //no pagination (hide=true) -> no tableName needed
    sorting: {
        sortParamKey: '',
        directionParamKey: '',
        defaultSortParamValue: '',
        defaultDirectionParamValue: ''
    },
    pagination: {

        hidePagination: true
    },
    href: (element, match) => getAccountLinkHref(match, ROUTES.DAMAGE_MANAGER_BASE + element.id),
    columns: [

        {
            width: 11,
            label: 'License plate',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <DamageLicenseplate damage={element}/>
        },
        {
            width: 14,
            label:  'Customer / owner',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <CustomerNameDamage damage={element} />
        },
        {
            width: 15,
            label: 'Claim number', 
            secondLabel: 'Damagetype',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element, isSelected}) => {
                const claimNumber = element.type === DAMAGE_TYPE.COMPREHENSIVE 
                    ? element?.comprehensiveData?.claim?.number
                    : element?.liabilityData?.claim?.number

                return <FlexBox flexDirection='column'>
                    <div>
                        <ClaimNumber 
                            number={claimNumber} 
                            isSelected={isSelected}
                        />
                    </div>
                    <div>
                        <DamageTypeName 
                            damageType={element.type} 
                            isSelected={isSelected}
                        />
                    </div>
                </FlexBox>
            }
        },
        {
            width: 13,
            label: 'Job number',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <JobNumber number={element.jobNumber} />
        },
        {
            width: 15,
            label: 'Assignee',
            secondLabel: 'Lawfirm',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <FlexBox flexDirection='column'>
                <FullName person={element.assignee} />
                <LawFirmName lawFirm={element.lawFirm} />
            </FlexBox>
        },

        {
            width: 15,
            label: 'Status',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element, isSelected}) =><DamageStatus status={element.state} isSelected={isSelected} />
        },
        {
            width: 11,
            label: 'last change',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <DateComponent date={element.modifiedAt} />
        },
        {
            width: 6,
            label: 'Authorization',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <RepairAuthorizationIndicator repairAuthorization={element.repairAuthorization} />
        }
    ],
    ContextMenu: () => null,
    contextMenuWidth: '0px',
    massOperations: {
        available: false //are mass operations possible for this table?
    },
    rowHeight: '36px'
}
