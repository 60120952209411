import { connect } from 'react-redux'
import DialogDataEffects from './effects'
import { getDialogDataRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = (state, props) => {
    const { dialog } = props
    return {
        loggedIn: state.auth.loggedIn,
        isLoading: dialog.isLoading,
        isLoaded: dialog.isLoaded,
        dialogName: dialog.name,
        metaData: dialog.payload
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadDialogData: (name, metaData) => dispatch(
            getDialogDataRequest(name, metaData)
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DialogDataEffects)