import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as Fragments from './fragments'

export const TabsNavigation = ({ initialActiveTabIndex, items = [], smallFont = false, children }) => {

    const [active, setActive] = useState(initialActiveTabIndex)

    return (
        <Fragments.Wrapper smallFont={smallFont}>
            <Fragments.TabsWrapper smallFont={smallFont}>
                {items.map((item, index) => {
                    const currentTab = index === active
                    return (
                        <Fragments.TabsItem
                            key={index}
                            smallFont={smallFont}
                            className={`jestButtonTab-${item.label}-${index} ${currentTab ? 'tabActive' : ''}`}
                            isSelected={currentTab}
                            onClick={e => {
                                setActive(index)
                                item.onItemClick()
                            }}
                        >
                            {item.label}

                        </Fragments.TabsItem>
                    )

                })}
            </Fragments.TabsWrapper>
            <Fragments.PlaceForRightSide>
                <Fragments.WrapperForTheRightSide smallFont={smallFont}>
                    {children}
                </Fragments.WrapperForTheRightSide>
            </Fragments.PlaceForRightSide>
        </Fragments.Wrapper>
    )
}

TabsNavigation.propTypes = {
    initialActiveTabIndex: PropTypes.number,
    items: PropTypes.array,
    smallFont: PropTypes.bool
}