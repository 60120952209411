import { connect } from 'react-redux'

import TitleBarWorkflowPanel from './component'
import { logout } from 'application/redux/actions/shared_resource'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { getDamageDataWorkflowPanelRequest, getLawfirmWorkflowPanelRequest } from 'application/redux/actions/pages/workflow_panel/shared'
import { DIALOG } from 'application/constants'

const mapStateToProps = (state) => {
    return {
        vehicle: state.pages.workflowPanel.shared?.damage?.vehicle || null,
        damageLoaded: state.pages.workflowPanel.shared.damageLoaded,
        lawfirm: state.pages.workflowPanel.shared.lawfirm,
        lawfirmLoaded: state.pages.workflowPanel.shared.lawfirmLoaded,
        damage: state.pages.workflowPanel.shared?.damage || null,
        account: state.pages.workflowPanel.shared?.account || null,
        branch: state.pages.workflowPanel.shared?.branch || null,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogoutRequest: (sharedResourceId) => dispatch(logout(sharedResourceId)),
        onCreateMessage: () => dispatch(openDialogByName(DIALOG.NAME.CREATE_MESSAGE_WORKFLOW_PANEL, false, {}, 'Create Message')),
        onLoad: () => {
            dispatch(getDamageDataWorkflowPanelRequest())
            dispatch(getLawfirmWorkflowPanelRequest())
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TitleBarWorkflowPanel)