import React from 'react'
import { Headline2, Paragraph } from 'application/components/fragments/typography'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ServerError } from './server_error'
import { ClientError } from './client_error'
import { StyledErrorDisplayContainer } from './fragments'
import { exists } from 'application/common/data_helpers'
import { ADVANCED_ERROR_DISPLAY } from 'application/constants'

export default ({isVisible, error, closeErrorDetails}) => {
    if(!isVisible || !ADVANCED_ERROR_DISPLAY){
        return null
    }
  
    return <StyledErrorDisplayContainer>
        <FlexBox justifyContent='space-between'>
            <Headline2>Error Details</Headline2>
            <IconButtonWithLabel
                iconKey='close'
                iconSize='22px'
                fontSize='14px'
                onButtonClick={closeErrorDetails} 
            />
        </FlexBox>
        
        <Paragraph cssMargin='4px 0'>Error ID: {error.id}</Paragraph>
        {
            exists(error.payload) 
                ? <React.Fragment>
                    <ServerError payload={error.payload} />
                    <ClientError payload={error.payload} />
                </React.Fragment>
                : <Paragraph>Error details cannot be displayed</Paragraph>
        }
        
    </StyledErrorDisplayContainer>
}