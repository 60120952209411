import React from 'react'
import { PermissionRedirectStrategy } from 'application/components/controls/permission_redirect_stragegy'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { withRouter } from 'react-router'
import { ROUTES, USER_PERMISSONS } from 'application/constants'

export const RedirectStrategy = withRouter(({match}) => {
    return <PermissionRedirectStrategy
        strategy={
            [
                {
                    href: getAccountLinkHref(match, ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA),
                    permissions: [
                        USER_PERMISSONS.ACCOUNT.UPDATE
                    ]
                },
                {
                    href: getAccountLinkHref(match, ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES),
                    permissions: [
                        USER_PERMISSONS.DOCUMENT_CATEGORY.CREATE,
                        USER_PERMISSONS.DOCUMENT_CATEGORY.UPDATE,
                        USER_PERMISSONS.DOCUMENT_CATEGORY.DELETE
                    ]
                },
                {
                    href: getAccountLinkHref(match, ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES),
                    permissions: [
                        USER_PERMISSONS.DAMAGE_STATE.CREATE,
                        USER_PERMISSONS.DAMAGE_STATE.UPDATE,
                        USER_PERMISSONS.DAMAGE_STATE.DELETE
                    ]
                }
            ]
        }
    />
})