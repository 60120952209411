import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import Link from 'application/components/controls/hyperlink'
import { getDamageManagerTaskLinkHref } from 'application/common/route_helpers'
import { withRouter } from 'react-router-dom'
import { ROUTES, ACTIVITY_RELATED_ENTITY_TYPES } from 'application/constants'
import PropTypes from 'prop-types'


const TaskCreatedActivityComponent = (
    {
        activity, 
        match
    }
) => {
    const {t} = useTranslation()
    const damageId= activity.relatedEntities.find(
        re => re.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    ).id

    const taskId = activity.data.id

    const link = getDamageManagerTaskLinkHref(
        match, 
        ROUTES.DAMAGE_MANAGER_TASKS_PRESELECTED, 
        damageId,
        taskId
    )
            
    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t(`${activity.type}Title`)}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Title')}: {activity.data.title}
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            <Link to={link}>
                {t('Switch to task')}
            </Link>
        </Fragments.ActivityParagraph>
    </React.Fragment>
}

TaskCreatedActivityComponent.propTypes = {
    activity: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
}


export const TaskCreatedActivity = withRouter(
    TaskCreatedActivityComponent
)