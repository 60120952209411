export const thumbnailLoaded = (state, thumbnailId) => state.attachments.thumbnails.filter(t => t.id === thumbnailId && t.isLoaded).length > 0
export const fullAttachmentLoaded = (state, attachmentId) => state.attachments.fullAttachments.filter(t => t.id === attachmentId && t.isLoaded).length > 0
export const attachmentMetaLoaded = (state, metaId) => state.attachments.meta.filter(m => m.id === metaId && m.isLoaded).length > 0
export const damageManagerSelectedTask = state => state.pages.damageManager.tasks.selectedTask
export const getPin = (state) => state.sharedResource.pin || ''
export const getSharedResourceId = (state) => state.sharedResource.id
export const getSharedResourceType = (state) => state.sharedResource.resourceType
export const workflowPanelSelectedTask = state => state.pages.workflowPanel.tasks.selectedTask
export const loggedIn = state => state.auth.loggedIn
export const getAccessToken = state => state.oidc.user.access_token
export const getAccounts = state => state.auth.accounts
export const getAccountsTotal = state => state.auth.accountsTotal
export const accountsLoaded = state => state.auth.accountsLoaded
export const getCurrentAccount = state => state.auth.currentAccount
export const getCurrentAccountId = state => state.auth.currentAccountId
export const getAccessibleBranches = state => state.auth.accessibleBranches
export const getCurrentBranchId = state => state.auth.currentBranch !== null ? state.auth.currentBranch.id : null
export const getCurrentBranchInfo = state => ({currentBranch: state.auth.currentBranch, currentBranchLoaded: state.auth.currentBranchLoaded, currentBranchLoading: state.auth.currentBranchLoading})
export const getUserGroupById = (state, groupId) => state.common.userGroups.find(g => g.id === groupId)
export const getCurrentDamage = state => state.pages.damageManager.shared.damage
export const getCurrentDamageWorkflowPanel = state => state.pages.workflowPanel.shared.damage
export const getAllPermissions = state => state.auth.allPermissions

export const getUpdateConstraint = (state, constraint) => {
    return state.updateScheduler.constraints.find(c => c.updateConstraint === constraint) !== undefined
        ? state.updateScheduler.constraints.find(c => c.updateConstraint === constraint).timeStamp
        : null
}
