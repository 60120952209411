import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LayoutAllBranches from 'application/components/pages/_layout/layout_all_branches'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { ROUTES, USER_PERMISSONS } from 'application/constants'
import { getAccountLinkHref } from 'application/common/route_helpers'
import {
    Switch,
    Route,
    withRouter,
    Redirect
} from 'react-router-dom'
import { CustomersContent } from './customers'
import { CompaniesContent } from './companies'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'






const CustomersOverviewComponent = (
    { 
        match,
        onOpenCreateCustomerDialog
    }
) => {
    const { t } = useTranslation()

    const customersURL = ROUTES.CUSTOMERS_PRIVATE
    const companiesURL = ROUTES.CUSTOMERS_BUSINESS

    if (match.path === ROUTES.CUSTOMERS) {
        return <Redirect 
            to={
                getAccountLinkHref(
                    match, 
                    customersURL
                )
            }
        />
    } 


    return <LayoutAllBranches
        headlineGeneric={t('Administration')} 
        headlineSpecific={t('Customers')}
        titleSegments={['Customers']}
    >
        <TabsLinks
            items={
                [
                    {
                        label: t('PrivateCustomers'),
                        link: getAccountLinkHref(
                            match, 
                            customersURL
                        ),
                        isActive: match.path === customersURL
                    },
                    {
                        label: t('BusinessCustomers'),
                        link: getAccountLinkHref(
                            match, 
                            companiesURL
                        ),
                        isActive: match.path === companiesURL
                    }
                ]
            }
        >
            <ForAnyBranchPermissions
                permissions={[
                    USER_PERMISSONS.CUSTOMER.CREATE
                ]} 
                allNeeded={true}
            >
                <IconButtonWithLabel
                    iconKey='add' 
                    onButtonClick={onOpenCreateCustomerDialog}
                    label={t('Create customer')}
                />
            </ForAnyBranchPermissions>
        </TabsLinks>
        <Switch>
            <Route path={customersURL}>
                <CustomersContent />
            </Route>
            <Route path={companiesURL}>
                <CompaniesContent />
            </Route>
        </Switch>                
    </LayoutAllBranches>
}

CustomersOverviewComponent.propTypes = {
    match: PropTypes.object.isRequired
}

export const CustomersOverview = withLoggedInUser(
    withRouter(CustomersOverviewComponent)
) 