import React from 'react'
import styled from 'styled-components'
import * as Fragments from './fragments'
import { NavLink, withRouter } from 'react-router-dom'
import { ROUTES } from 'application/constants'
import { IconButton, FontIcon } from '@ec/ui-controls-react'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { useTranslation } from 'react-i18next'
import LocalTime from 'application/components/controls/local_time'
import DateComponent from 'application/components/controls/date'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { MessageSubject } from 'application/components/controls/message_subject'
import { PushBottom24 } from 'application/components/pages/_common'
import { DIALOG } from 'application/constants'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import { USER_PERMISSONS } from 'application/constants'


const MessageDetailsWrapper = styled.div`
    background-color: ${props => props.theme.color.white};
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 0 16px 60px;
`

const StyledLink = styled(NavLink)`
    color: ${props => props.theme.color.primary};
    display: flex;
    align-items: center;
    text-decoration: none;
`

const MessageDetails = (
    { 
        messageDetails, 
        deleteMessage, 
        downloadAttachment, 
        match, 
        showDamageLink = true 
    }
) => {
    const {t} = useTranslation()

    const {damageId} = match.params
    const documents = messageDetails?.documents?.documents || []

    if (messageDetails === null){
        return <MessageDetailsWrapper>
            Bitte Nachricht auswählen.
        </MessageDetailsWrapper>
    } 

    const handleDeleteMessage = () => {

        const payload =                     { 
            messageId: messageDetails.id, 
            ...messageDetails, 
            damageId: match.params.damageId,
            path: match.path
        }

        deleteMessage(
            DIALOG.NAME.DELETE_MESSAGE, 
            true, 
            payload,
            'Delete message'
        )
    }

    return (
        <MessageDetailsWrapper>
            {
                showDamageLink && <Fragments.CaseWrapper>
                    <StyledLink 
                        to={
                            getAccountLinkHref(
                                match, 
                                ROUTES.DAMAGE_MANAGER_BASE + damageId
                            )
                        }
                    >
                        <FontIcon 
                            icon='east' 
                            fontSize='16px'
                        /> 
                        {t('to damage')}
                    </StyledLink>
                </Fragments.CaseWrapper>
            }
            <Fragments.HeaderWrapper>
                <Fragments.HeaderLeft>
                    <Fragments.MessageSender>
                        {t('from')} {': '}
                        { messageDetails.sender.name }
                        { messageDetails.sender.email !== undefined && <React.Fragment>
                            &lt;{messageDetails.sender.email}&gt;
                        </React.Fragment>}
                    </Fragments.MessageSender>
                    <Fragments.MessageTime>
                        <DateComponent date={messageDetails.createdAt} />, 
                        <LocalTime date={messageDetails.createdAt} />
                    </Fragments.MessageTime>
                </Fragments.HeaderLeft>
                <Fragments.HeaderRight>
                    <ForCurrentBranchPermissions
                        permissions={[USER_PERMISSONS.MESSAGE.DELETE]}
                    >
                        <IconButton
                            iconKey='delete'
                            onButtonClick={handleDeleteMessage}
                        />
                    </ForCurrentBranchPermissions>
                </Fragments.HeaderRight>
            </Fragments.HeaderWrapper>
            {
                documents.length > 0 && (
                    <Fragments.MessageAttachmentWrapper>
                        {
                            documents.map(
                                (document, i) => (
                                    <Fragments.MessageAttachment key={i}>
                                        <IconButtonWithLabel
                                            iconKey="attach_file"
                                            label={document.name}
                                            fontSize="15px"
                                            iconSize="15px"
                                            onButtonClick={
                                                () => downloadAttachment(
                                                    document.id, 
                                                    document.name, 
                                                    damageId
                                                )
                                            }
                                        />
                                    </Fragments.MessageAttachment>
                                )
                            )
                        }
                    </Fragments.MessageAttachmentWrapper>
                )
            }
            <PushBottom24 />
            <MessageSubject subject={messageDetails.subject} />
            <PushBottom24 />
            <Fragments.MessageBody>
                { messageDetails.body }
            </Fragments.MessageBody>
        </MessageDetailsWrapper>
    )
}

export default withRouter( MessageDetails )
