import React from 'react'
import styled from 'styled-components'
import { HeaderContainer } from './fragments'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PushBottom16, PushBottom8 } from 'application/components/pages/_common'
import { StepsNavigation } from './steps_navigation'
import AccordionMenu from 'application/components/building_blocks/main_navigation_sidebar/_common/accordion_menu/component'
import { PureCheckbox } from 'application/components/controls/form/checkboxes/checkbox_with_label'

const StyledItem = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 36px;
    margin-bottom: 10px;
    align-items: center;
    cursor: pointer;

    & span {
        margin-left: 8px;
        font-size: 16px;
    }
`

export const ExtServices = (
    {
        selectedService,
        setSelectedService,
        setCurrentStep,
        accordions,
        setAccordions
    }
) => {

    

    return <HeaderContainer>
        <PushBottom16 />
        <FlexBox flexDirection='column'>
            {
                accordions.map((a, i) => {
                    return <AccordionMenu 
                        onCloseAccordions={()=>{}}
                        key={i}
                        accordionMenuState={a.menuState}
                        menuName={a.menuState.name}
                        menuIconKey={a.menuIconKey}
                        primaryColor={a.primaryColor}
                        onOpenOnlyCurrentAccordeon={
                            () => {
                                setAccordions(accordions.map(acc =>
                                    acc.id === a.id
                                        ? {
                                            ...acc,
                                            menuState: {
                                                ...acc.menuState,
                                                accordionOpen: true
                                            }
                                        }
                                        : {
                                            ...acc,
                                            menuState: {
                                                ...acc.menuState,
                                                accordionOpen: false
                                            }
                                        }
                                ))

                            }
                        }
                        sideBarOpen={true}
                    >
                        <FlexBox flexDirection='column'>
                            <PushBottom8 />
                            {
                                a.items.map(item => <StyledItem 
                                    key={item.id}
                                    onClick={
                                        () => {
                                            setSelectedService(item)
                                            setCurrentStep(1)
                                        }
                                    }
                                >
                                    <PureCheckbox 
                                        checked={selectedService?.id === item.id}
                                    />
                                    <span>{item.label}</span>
                                </StyledItem>)
                            }
                            <PushBottom8 />
                        </FlexBox>
                    </AccordionMenu>
                }
                )
            }

        </FlexBox>
        <PushBottom16 />
        <StepsNavigation 
            previousVisible={false}
            onClickNext={
                ()=>{
                    if(selectedService !== null){
                        setCurrentStep(1)
                    }
                }
            }
        />    
        
    </HeaderContainer>
}