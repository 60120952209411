import React from 'react'
import { ROUTE_PARAMETERS } from 'application/constants'
import CustomersContextMenu from './context_menu_container'
import AddressCity from 'application/components/controls/address_city'
import {AdressLine1} from 'application/components/controls/address_line_1'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import { STORAGE } from 'application/constants'
import * as storage from 'application/storage'

const tableName = STORAGE.TABLE_NAME.COMPANIES

export const configuration = {
    name: tableName,
    filters: {
        match: [],
        query: [],
        property: [],
        static: [
            new ListFilter(filters.customers.businessCustomers)
        ]
    },
    sorting:  {
        sortParamKey: ROUTE_PARAMETERS.COMPANIES.ORDER_BY.KEY,
        directionParamKey: ROUTE_PARAMETERS.COMPANIES.ORDER_BY.DIRECTION,
        defaultSortParamValue: ROUTE_PARAMETERS.COMPANIES.ORDER_BY.VALUES.COMPANY_NAME,
        defaultDirectionParamValue: ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING
    },
    pagination: {
        offset: ROUTE_PARAMETERS.CUSTOMERS.OFFSET,
        limit: ROUTE_PARAMETERS.CUSTOMERS.LIMIT,
        getItemsPerPage: () => {
            return storage.tableConfig.getItemsPerPage(tableName)
        }
    },
    href: () => null,
    columns: [
        {
            width: 25,
            label: 'Company name',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.COMPANIES.ORDER_BY.VALUES.COMPANY_NAME,
            CellContent: ({element}) => <React.Fragment>{element.companyName}</React.Fragment>
        },
        {
            width: 25,
            label: 'Email',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <React.Fragment>{element.email}</React.Fragment>
        },
        {
            width: 25,
            label: 'Street and housenumber',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <AdressLine1 entity={element} />
        },
        {
            width: 25,
            label: 'City',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <AddressCity entity={element} />
        }
    ],
    ContextMenu: (
        {element, moIsActive}
    ) => moIsActive 
        ? <React.Fragment /> 
        : <CustomersContextMenu customer={element} />,
    contextMenuWidth: '34px',
    massOperations: {
        available: false //are mass operations possible for this table?
    }
}