import React, {useCallback, useState} from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { useTranslation } from 'react-i18next'
import { Paragraph } from 'application/components/fragments/typography'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { MoDialogFooter } from 'application/components/dialogs/mass_operations/_common/footer'
import { removeItemFromListById } from 'application/common/list_helpers'
import { PushBottom16 } from 'application/components/pages/_common'
import {MoDialogTable} from 'application/components/dialogs/mass_operations/_common/tables'
import {tableConfiguration} from 'application/components/dialogs/mass_operations/_common/tables/configurations/tasks'
import InfoBox from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { isPermitted } from 'application/components/context/permissions'
import { PERMISSION_SCOPE, USER_PERMISSONS } from 'application/constants'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'

const MoTasksDelete = (
    {
        abort, 
        onDeletion,
        dialog,
        moName = '',
        selectedItems = [],
        allPermissions
    }
) => {
    const { t } = useTranslation()
    const [selectedItemsByDialog, setSelectedItemsByDialog] = useState(selectedItems)

    const userHasTaskDeletePermission = useCallback(
        (task) => {
            return isPermitted(
                allPermissions, 
                [], 
                [USER_PERMISSONS.TASK.DELETE],
                PERMISSION_SCOPE.SPECIFIC_BRANCH, 
                true, 
                task.branch.id
            )
        }, [allPermissions]
    )

    const deletableItems = selectedItemsByDialog.filter(
        item => item.isDeletable && userHasTaskDeletePermission(item)
    )

    const tasksCount = deletableItems.length 


    const hasDeletableElements = deletableItems.length > 0

    const itemsWithoutDeletePermissions = selectedItemsByDialog.filter(
        item => !userHasTaskDeletePermission(item) 
    )

    const notDeletableItems = selectedItemsByDialog.filter(
        item => !item.isDeletable && !itemsWithoutDeletePermissions.find(
            i => i.id === item.id
        )
    )

    const handleRemoveElement = (    
        damageId 
    ) =>  {
        const listWithoutItem = removeItemFromListById(selectedItemsByDialog,damageId)
        setSelectedItemsByDialog(listWithoutItem)
    }
    

    const handleDeletion = () => {
        const payload = {
            ids: deletableItems.map(
                item => item.id
            )
        }
        const metaData = {
            moName
        }
        onDeletion(
            dialog.name,
            payload,
            metaData
        )
    }


    return  <React.Fragment>
        <DialogMainContent>
            <PushBottom16/>
            <Paragraph>
                {   hasDeletableElements 
                    ?
                    <Trans  
                        i18nKey='Should_tasks_be_deleted'
                    >
                        {{tasksCount}}
                    </Trans>
                    :
                    <InfoBox
                        type={INFOBOX.TYPES.WARNING} 
                        direction='row' 
                        cssMargin='0' 
                    >
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        {t('No tasks for deletion selected')}
                        <Spacer />
                    </InfoBox>                    
                }
            </Paragraph>
            {
                deletableItems.length > 0 && <React.Fragment>
                    <MoDialogTable
                        removeElement={handleRemoveElement}
                        tableConfig={tableConfiguration}
                        elements={deletableItems}
                    />
                </React.Fragment>
            }
            {
                itemsWithoutDeletePermissions.length > 0 && <React.Fragment>
                    <InfoBox
                        type={INFOBOX.TYPES.WARNING} 
                        direction='row' 
                        cssMargin='0' 
                    >
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        {t('The following items exist in branches in which this user is missing the delete permission')}
                        <Spacer />
                    </InfoBox>      
                    <MoDialogTable
                        removeElement={handleRemoveElement}
                        tableConfig={tableConfiguration}
                        elements={itemsWithoutDeletePermissions}
                        disabled={true}
                        alert={true}
                    />
                </React.Fragment> 
            }
            {
                notDeletableItems.length > 0 && <React.Fragment>
                    <InfoBox
                        type={INFOBOX.TYPES.WARNING} 
                        direction='row' 
                        cssMargin='0' 
                    >
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        {t('The following tasks cannot be deleted and are excluded from the deletion process')}
                        <Spacer />
                    </InfoBox>      
                    <MoDialogTable
                        removeElement={handleRemoveElement}
                        tableConfig={tableConfiguration}
                        elements={notDeletableItems}
                        disabled={true}
                        alert={true}
                    />
                </React.Fragment> 
            }
            
            <Paragraph />
        </DialogMainContent>
        <MoDialogFooter 
            onCloseDialog={abort} 
            onSubmit={handleDeletion}
            submitButtonText={ t('Delete') }
            submitDisabled={ !hasDeletableElements }
        />
    </React.Fragment>
}

MoTasksDelete.propTypes = {
    abort: PropTypes.func.isRequired,
    onDeletion: PropTypes.func.isRequired,
    dialog: PropTypes.object.isRequired,
    moName: PropTypes.string,
    selectedItems: PropTypes.array.isRequired
}

export const MoTasksDeleteComponent = withDialogDataLoader(MoTasksDelete)