import React, { forwardRef } from 'react'
import uuidv4 from 'uuid/v4'
import { Controller } from 'react-hook-form'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/components/controls/form/_common'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment'
import 'moment/locale/de'
import { useTranslation } from 'react-i18next'
import { DisabledDatePicker, Icon, Row, StyledDatePicker } from './fragments'
import moment from 'moment'

const DatePickerComponent = (
    { 
        onBlur = () => {}, 
        value, 
        name, 
        placeholder,
        control,
        onDateChange,
        isReadOnly,
        htmlId,
        disabledDays
    }
) => {
    const handleOnBlur = (inputProps) => {
        inputProps.onBlur()
        onBlur()
    }
    
    const getDateValue = v => {
        return typeof v === 'string' ? new Date(v) : v
    }

    return <DayPickerInput
        onDayChange={
            val => {
                const dayValue = moment(val.setHours(0,0,0,0)).utcOffset(0, true).format()
                control.setValue( name, dayValue)
                onDateChange(dayValue)
            }
        }
        name={name}
        value={getDateValue(value)}
        component={
            forwardRef((props, ref) => {
                return <input 
                    readOnly={isReadOnly} 
                    {...props} 
                    ref={ref}
                    id={htmlId} 
                    onBlur={() => {handleOnBlur(props)}} 
                    placeholder={placeholder} 
                
                />
            })
        } 
        format='DD.MM.YYYY'
        dayPickerProps={
            {
                locale: 'de',
                localeUtils: MomentLocaleUtils,
                disabledDays
            }
        }
        formatDate={formatDate}
        parseDate={parseDate}
    />
}

export default (
    {
        label = '', 
        name, 
        control, 
        validate = {}, 
        error, 
        onDateChange = () => {}, 
        hideValidationMessage = false, 
        defaultValue = null, 
        errorMessageOverride='',
        isReadOnly = true,
        disabled = false,
        isClearable = true,
        disabledDays = {}
    }
) => {
    const {t} = useTranslation()
    const htmlId = uuidv4()
    const placeholder = t('no date selected')



    const clearDate = () => {
        control.setValue(name, null)
        onDateChange(null)
    }

    return <StyledDatePicker>
        {
            label !== '' && <label 
                className='label' 
                htmlFor={htmlId}
            >
                {label}
                <RequiredFieldMarker 
                    validate={validate}
                />
            </label>
        }



        <Row>
            {
                disabled 
                
                    ? <DisabledDatePicker>
                        <span> {placeholder}</span>
                    </DisabledDatePicker>


                    : <Controller
                        control={control} 
                        name={name}
                        rules={{ validate }}
                        defaultValue={defaultValue}
                        render={ (props) => {
                            return <DatePickerComponent 
                                value={props.value}
                                name={props.name}
                                placeholder={placeholder}
                                control={control}
                                onDateChange={(val)=>{
                                    props.onChange(val)
                                    onDateChange(val)
                                }}
                                isReadOnly={isReadOnly}
                                htmlId={htmlId}
                                disabledDays={disabledDays}
                            />
                        } }
                    />
            }

            <Icon
                className="icon-date-clear-button"
                onClick={ disabled || !isClearable ? ()=>{} : clearDate }
                disabled={ disabled }
                isClearable={isClearable}
            >
                {
                    isClearable && <i className="material-icons">close</i> 
                }
                
            </Icon>
        </Row>


        {/* <Controller
            control={control} 
            name={name}
            rules={{ validate }}
            defaultValue={defaultValue}
            render={renderDatePickerComponent}
        /> */}
        
        <ValidationErrorMessage 
            hideValidationMessage={hideValidationMessage} 
            error={error} 
            errorMessageOverride={errorMessageOverride} 
        />
    </StyledDatePicker>


}