import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { WIDGET_CONTEXTS } from 'application/constants'
import DamageManagerLawyerKommunikation from './component'


const DamageManagerLawyerKommunikationEffects = (props) => {

    const { 
        onRefreshWidgetContexts, 
        match 
    } = props

    const { damageId } = match.params

    useEffect(
        () => {
            onRefreshWidgetContexts(
                [
                    WIDGET_CONTEXTS.DAMAGE_MANAGER_ANWALT_KOMMUNIKATION
                ]
            )
        }, [onRefreshWidgetContexts, damageId]
    )

    return <DamageManagerLawyerKommunikation {...props} />
}

DamageManagerLawyerKommunikationEffects.propTypes = {
    onRefreshWidgetContexts: PropTypes.func.isRequired
}

export default withRouter(DamageManagerLawyerKommunikationEffects)