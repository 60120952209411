import React from 'react'
import CurrencyDisplay from 'application/components/controls/currency_display'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'


const CanceledInfo = (
    {activityData}
) => {
    const {t}  = useTranslation()

    return <React.Fragment>
        {t('Amount')}: <CurrencyDisplay amount={activityData.amount} /><br />
        {t('Bill number')}: {activityData.invoice.number}<br/>
        {t('Reason')}: {activityData.cancellationReason}
    </React.Fragment>
}

CanceledInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {CanceledInfo} 