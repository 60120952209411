import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const getAllBranches = (params) => (
    {
        authenticated: () => {
            const request = fetchRequestBuilder('branches', HTTP_METHOD.GET)
            return apiCall(request)
        },
        sharedResource: () => null,
        qrpoc: () => {
            const request = fetchRequestBuilderPin(`external/${params.sharedResourceType}/${params.sharedResourceId}/branches`, params.pin, HTTP_METHOD.GET)
            return apiCall(request)
        }
    }
)