import React from 'react'
import PropTypes from 'prop-types'
import ReactGridLayout, { WidthProvider } from 'react-grid-layout'
import { FontIcon } from '@ec/ui-controls-react'

import { WidgetContainer, RemoveWidgetButton, WidgetHandle, WidgetEditLink } from 'application/components/building_blocks/widget'
import Widget from 'application/components/building_blocks/widget'
import { withTheme } from 'styled-components'
import { gridItemBuilder } from './grid_item_builder'



const ResponsiveGridLayout = WidthProvider(ReactGridLayout)

const WidgetBoard = ({
    hasContext,
    widgetLayout,
    widgetList,
    widgetContext,
    boardConfiguration,
    onWidgetLayoutChange,
    onUpdateStaticMode,
    onRemoveWidget,
    theme,
    widgetMargin = [16, 16]
}) => {
    //fail safe
    if(!hasContext){ return <React.Fragment /> }

    const configuration = {
        isStatic: false,
        isEditable: false,
        rowHeight: 100,
        cols: 8,
        className: 'layout',
        defaultWidth: 1,
        defaultHeight: 2,
        ...boardConfiguration
    }

    const onEditModeEnable = () => {
        onUpdateStaticMode(widgetContext, false)
    }
    
    const staticModeAdjustedLayout = widgetLayout.map(
        widget => ({
            ...widget, 
            static: configuration.isStatic
        })
    )

    return <ResponsiveGridLayout
        layout={staticModeAdjustedLayout}
        cols={configuration.cols} 
        rowHeight={configuration.rowHeight}  
        className={configuration.className}
        onLayoutChange={onWidgetLayoutChange}   
        measureBeforeMount={true}   
        draggableHandle='.widgetboard-move-handle'
        containerPadding={[0, 0]}
        margin={widgetMargin}
    >
        {
            widgetList.map((w, i)=>{
                const gridItem = gridItemBuilder(
                    staticModeAdjustedLayout, 
                    i, 
                    boardConfiguration.isStatic, 
                    boardConfiguration.defaultWidth, 
                    boardConfiguration.defaultHeight
                )

                return <WidgetContainer 
                    key={w.key}
                    data-grid={gridItem}
                >
                    {
                        !configuration.isStatic && <WidgetHandle className='widgetboard-move-handle'>
                            <FontIcon 
                                icon='open_with' 
                                fontSize={theme.fontSize.medium}
                            />
                        </WidgetHandle>
                    }
                    {
                        !configuration.isStatic && <RemoveWidgetButton 
                            onClick={ 
                                ()=>{
                                    onRemoveWidget(widgetContext, w.key)
                                }
                            }
                        >
                            <FontIcon 
                                icon='delete' 
                                isClickable={true} 
                                fontSize={theme.fontSize.medium}
                            />
                        </RemoveWidgetButton>
                    }
                    {
                        configuration.isStatic && configuration.isEditable && <WidgetEditLink 
                            className='on-hover' 
                            onClick={onEditModeEnable}
                        >
                            <FontIcon 
                                icon='edit' 
                                isClickable={true} 
                                fontSize={theme.fontSize.medium} 
                            />
                        </WidgetEditLink>
                    }
                    <Widget 
                        isBlank={w.isBlank}
                        widgetKey={w.key} 
                        type={w.type} 
                        isSecondary={w.isSecondary || false}  
                        hideBorder={w.hideBorder || false}
                    />

                </WidgetContainer>
            })
        }
    </ResponsiveGridLayout>
}

WidgetBoard.propTypes = {
    hasContext: PropTypes.bool.isRequired,
    widgetLayout: PropTypes.array.isRequired,
    widgetList: PropTypes.array.isRequired,
    widgetContext: PropTypes.string.isRequired,
    boardConfiguration: PropTypes.shape({
        rowHeight: PropTypes.number,
        cols: PropTypes.number,
        className: PropTypes.string
    }).isRequired,
    onWidgetLayoutChange: PropTypes.func.isRequired,
    onUpdateStaticMode: PropTypes.func.isRequired,
    onRemoveWidget: PropTypes.func.isRequired
}

export default withTheme(WidgetBoard)