import ACTIONS from 'application/constants'

export const resetMessageDetails = () => {
    return {
        type: ACTIONS.INBOX_RESET_MESSAGE_DETAILS
    }
}

export const getMessageByIdRequest = (damageId, messageId) => {
    return {
        type: ACTIONS.INBOX_GET_MESSAGE_BY_ID,
        damageId, messageId
    }
}

export const messageNotFound = () => ({
    type: ACTIONS.INBOX_MESSAGE_NOT_FOUND
})

export const populateMessageDetails = (messageDetails) => ({
    type: ACTIONS.INBOX_POPULATE_MESSAGE_DETAILS,
    messageDetails,
})