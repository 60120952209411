import { downLoadQRCode } from 'application/redux/actions/qr_code_download'
import { connect } from 'react-redux'
import {Step1Component} from './component'

const mapStateToProps = state => ({
    currentAccountName: state.auth.currentAccount.name
})

const mapDispatchToProps = dispatch => {
    return {
        onQRCodeDownload: (
            qrCodeObjectUrl,
            downloadedImageFileName
        ) => dispatch(
            downLoadQRCode(
                qrCodeObjectUrl,
                downloadedImageFileName
            )
        ),
    }
}

export const Step1 = connect(
    mapStateToProps,
    mapDispatchToProps
)(Step1Component)