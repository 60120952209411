import React from 'react'
import { withRouter } from 'react-router-dom'
import {ROUTE_PARAMETERS, USER_PERMISSONS } from 'application/constants'
import { SubPageLayout,  PushBottom16 } from 'application/components/pages/_common'
import AllTasksOverviewTable from 'application/components/tables/tasks/current_branch/all_tasks'
import UserFilter from 'application/components/building_blocks/filters/user_filter'
import { configuration } from 'application/components/tables/tasks/current_branch/all_tasks/configuration'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import { TaskStateFilterCheckboxGroup, ExternalFilterCheckboxGroup } from 'application/components/building_blocks/filters/checkboxes'
import { FlexBox } from 'application/components/fragments/flex_box'



const AllTasks = (
    {
        tasksList, 
        tasksListLoading, 
        tasksListLoaded, 
        tasksListTotal,
        moName,
        onLoadTasksList,
        updateConstraint
    }
) => {   

    return  <SubPageLayout>
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.USER.READ]}
        >
            <UserFilter 
                routeParameters={ROUTE_PARAMETERS.ALL_TASKS.USER}
            />
        </ForCurrentBranchPermissions>
        <FlexBox>
            <FlexBox width='400px'>
                <TaskStateFilterCheckboxGroup
                    pagination={configuration.pagination}
                    disabled={tasksListLoading}
                />
            </FlexBox>   
            <ExternalFilterCheckboxGroup
                pagination={configuration.pagination}
                disabled={tasksListLoading}
                spaceLeft={true}
            />
        </FlexBox>
        <PushBottom16/>
        <ForCurrentBranchPermissions 
            permissions={
                [
                    USER_PERMISSONS.TASK.DELETE
                ]
            } 
            allNeeded={false}
        >
            {/* <MassOperationPanel
                moName={moName}
                label={'Edit multiple tasks'}
                itemDesignation={'Task'}
                itemsDesignation={'Tasks'}
            >
                <MassOperationsTasksSelect
                    permissionScope={PERMISSION_SCOPE.CURRENT_BRANCH}
                    moName={moName}
                />
            </MassOperationPanel>   */}
        </ForCurrentBranchPermissions>
        <PushBottom16/>
        <AllTasksOverviewTable
            tasksListLoading={tasksListLoading}
            tasksListLoaded={tasksListLoaded}
            tasksListTotal={tasksListTotal}
            tasksList={tasksList}
            moName={moName}
            onLoadTasks={onLoadTasksList}
            updateConstraint={updateConstraint}
        />
    </SubPageLayout>
}

AllTasks.propTypes = {

}

export default withRouter(AllTasks)