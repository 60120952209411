import { exists } from './data_helpers'

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = (item) => {
    return (item && typeof item === 'object' && !Array.isArray(item))
}
  
/**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   */
export const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target
    const source = sources.shift()
  
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} })
                mergeDeep(target[key], source[key])
            } else {
                Object.assign(target, { [key]: source[key] })
            }
        }
    }
  
    return mergeDeep(target, ...sources)
}

/**
   * Return list of objects by list of ids
   * 
   * @param listOfIds array of ids
   * @param listOfObjects Array of objects with an id key
   * @returns {Array} list of objects with ids of listOfIds
*/

export const selectObjectsByIds = (listOfIds, listOfObjects) => {
    return listOfObjects.filter(o => listOfIds.includes(o.id))
}

/**
   * Return list of objects with unique ids
   * 
   * @param objects the array of objects, must have id property
   * @returns {Array} list of objects with unique ids
*/
export const removeDuplicateIdsFromArrayOfObjects = objects => {
    return objects
        .filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
}

/**
 * Remove Properties with empty/null/undefined string values and empty objects from object recursively
 * @param {object} object 
 * @returns object without empty string values
 */
export const removePropertiesWithEmptyStrings = (object) => {
    return Object.fromEntries(
        Object.entries(object)
            .filter(([_, v]) => exists(v))
            .map(([k, v]) => {
                if(v instanceof Date){
                    return [k, v.toISOString()]
                }
                return [k, v]
            })
            .map(
                ([k, v]) => [k, v === Object(v) 
                    ? removePropertiesWithEmptyStrings(v) 
                    : v
                ]
            )
            .filter(([_, v]) => ( typeof v === 'object' && Object.keys(v).length > 0 ) 
            || typeof v !== 'object')
    )
}