
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { getDamageManagerLinkHref } from 'application/common/route_helpers'
import { ACTIVITY_RELATED_ENTITY_TYPES, ROUTES } from 'application/constants'
import Link from 'application/components/controls/hyperlink'
import { withRouter } from 'react-router-dom'
import { ShortenedString } from 'application/components/controls/shortened_string'




const MessageCreatedActivityComponent = (
    {
        activity,
        match
    }
) => {
    const {t} = useTranslation()

    const messageId = activity.data.id

    const damageId= activity.relatedEntities.find(
        re => re.type === ACTIVITY_RELATED_ENTITY_TYPES.DAMAGE
    ).id

    const messageLink = getDamageManagerLinkHref(
        match, 
        ROUTES.DAMAGE_MANAGER, 
        damageId
    ) + '/messages/' + messageId

    return <React.Fragment>
        <Fragments.ActivityTitle>
            {t('Message created')}
        </Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            {t('Subject')}: <ShortenedString text={activity.data.subject} shortenAt={35}/> 
        </Fragments.ActivityParagraph>
        <Fragments.ActivityParagraph>
            <Link to={messageLink}>
                {t('Show message')}
            </Link>
        </Fragments.ActivityParagraph>
    </React.Fragment>
}

MessageCreatedActivityComponent.propTypes = {
    activity: PropTypes.object.isRequired
}

export const MessageCreatedActivity = withRouter(
    MessageCreatedActivityComponent
)