import React from 'react'
import PropTypes from 'prop-types'
import { ValidationErrorMessage} from 'application/components/controls/form/_common'



const ValidatedHiddenField = (
    {
        name,
        value,
        register,
        error,
        validate,
        errorMessageOverride = ''
    }
) => {
    return <React.Fragment> 
        <input 
            type="hidden" 
            ref={ register({validate}) } 
            name={name} 
            value={value}
        />
        <ValidationErrorMessage
            hideValidationMessage={false} 
            error={error}
            errorMessageOverride={errorMessageOverride}
        />
    </React.Fragment>
}

ValidatedHiddenField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType(
        [
            PropTypes.string, 
            PropTypes.bool, 
            PropTypes.number
        ]
    ).isRequired,
    register: PropTypes.func.isRequired
}

export { ValidatedHiddenField }