import { FontIcon } from '@ec/ui-controls-react'
import React from 'react'
import styled from 'styled-components'

const Gravatar = styled.div`
    flex: 0 0 ${props => props.large}px;
    display: flex;
    justify-content: center;
`

const GravatarCircle = styled.div`
    background-color: ${props => props.backgroundColor};
    border-radius: 50%;
    height: ${props => props.large}px;
    text-align: center;
    width: ${props => props.large}px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const GravatarInitials = styled.span`
    font-size:  calc(${props => props.large}px / 3);
    line-height: 1;
    color: ${props => props.theme.color.white};
`

const GravatarWithInitial = ({
    initial = '',
    backgroundColor = '',
    large = '70',
    icon = '',
}) => {

    const iconSize = (large/2).toString()
   
    return <Gravatar large={large}>
        <GravatarCircle large={large} backgroundColor={backgroundColor}>
            <GravatarInitials large={large}>
                {
                    icon !== '' 
                        ? <FontIcon 
                            icon={icon}
                            color='#fff'
                            fontSize={iconSize}
                        /> 
                        :
                        initial
                } 
            </GravatarInitials>
        </GravatarCircle>
    </Gravatar>
}

export default GravatarWithInitial