import { connect } from 'react-redux'
import { logoutRequest } from 'application/redux/actions/auth'

import SimpleTitleBar from './component'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(logoutRequest())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SimpleTitleBar)