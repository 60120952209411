import { connect } from 'react-redux'
import FormWorkflowPanelEffects from './effects'

const mapStateToProps = state => {
    return {
        damageLoaded: state.pages.workflowPanel.shared.damageLoaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormWorkflowPanelEffects)