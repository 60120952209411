import React, {useState} from 'react'
import { withRouter } from 'react-router'
import { IMAGES, SHARED_RESOURCE_TYPE } from 'application/constants'
import { LayoutSharedResource } from 'application/components/pages/_layout'
import { PageContainer } from './sub_components/fragments'
import { ExtCreateDamageForm } from './sub_components/form'
import { ExtCreateDamageLawfirm } from './sub_components/lawfirm'
import { ExtCreateDamageDocuments } from './sub_components/documents'
import { ExtCreateDamageHeader } from './sub_components/header'
import { withSharedResourceValidation } from 'application/components/higher_order_components/shared_resource_validation'
import { ExtCreateDamageSuccess } from './sub_components/success'
import { useQuery } from 'application/components/hooks'
import { withBranches, withFirstBranchLoader } from 'application/components/higher_order_components'
import { ROUTE_PARAMETERS } from './sub_components/route_parameters'

export const CreateDamageKickstartExternal = withSharedResourceValidation()(withFirstBranchLoader(withBranches(withRouter((
    {        
        onCreateDamage,
        damageDataSent, 
        firstBranch
    }
) => {
    const [damageData, setDamageData] = useState({})
    const [files, setFiles] = useState([])
    const [lawfirmData, setLawfirmData] = useState({
        lawfirm: {
            name: 'Rechtsanwaltskanzlei Dietrich Frey Presper',
            id: '12345',
            logo: IMAGES.LAWYER_6
        },
        commissionLawyer: false
    })
    const [expertData, setExpertData] = useState({})
    const [currentStep, setCurrentStep] = useState(0)

    const query = useQuery()
    const accountName = query.getStringParam(
        ROUTE_PARAMETERS.ACCOUNT_NAME_PARAM
    )    

    return <LayoutSharedResource
        sharedResourceType={SHARED_RESOURCE_TYPE.KICKSTART}
    >        
        <PageContainer>
            <ExtCreateDamageHeader />

            {
                currentStep === 0 && <ExtCreateDamageForm 
                    damageData={damageData}
                    setDamageData={setDamageData}
                    setCurrentStep={setCurrentStep}
                />
            }

            {
                currentStep === 1 && <ExtCreateDamageLawfirm 
                    lawfirmData={lawfirmData}
                    setLawfirmData={setLawfirmData}
                    setCurrentStep={setCurrentStep}
                    expertData={expertData}
                    setExpertData={setExpertData}
                    firstBranch={firstBranch}
                />
            }

            {
                currentStep === 2 && <ExtCreateDamageDocuments 
                    files={files}
                    setFiles={setFiles}
                    setCurrentStep={setCurrentStep}
                    onSubmit={() => {
                        onCreateDamage({
                            damageData,
                            lawfirmData,
                            expertData,
                            files,
                            accountName,
                            branch: firstBranch
                        })
                    }}
                />
            }

            {
                currentStep === 3 && <ExtCreateDamageSuccess 
                    damageDataSent={damageDataSent}
                    lawfirmData={lawfirmData}
                    expertData={expertData}
                    damageData={damageData}
                />
            }

        </PageContainer>
    </LayoutSharedResource>
}))))