import { resetMessageDetails } from 'application/redux/actions/pages/inbox'
import { refreshWidgetsByType } from 'application/redux/actions/widgets'
import { connect } from 'react-redux'
import DamageManagerInboxEffects from './effects'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onRefreshWidgetType: (type) => dispatch(refreshWidgetsByType(type)),
        onResetMessageDetails: () => dispatch(resetMessageDetails())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerInboxEffects)