import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { LayoutSharedResource } from 'application/components/pages/_layout'
import { SharedResourceRedirect } from 'application/components/pages/external/shared_resource/redirect'

const withSharedResourcePinValidationComponent = sharedResourceType => Component => ({ ...props }) => {
    const {match} = props

    return <LayoutSharedResource
        sharedResourceType={sharedResourceType}
    >
        <SharedResourceRedirect
            sharedResourceType={sharedResourceType}
            sharedResourceId={match.params.sharedResourceId}
        >
            <Component {...props} />
        </SharedResourceRedirect>
    </LayoutSharedResource>
}

export const withSharedResourcePinValidation = (sharedResourceType) => compose(
    withRouter,
    withSharedResourcePinValidationComponent(sharedResourceType)
)