import { WIDGET_CONTEXTS } from 'application/constants'
import { widgetContextBuilder } from 'application/widget_configuration'

export const initialContextsBuilder = () => {

    const activeWidgetContexts = [
        WIDGET_CONTEXTS.DASHBOARD,
        WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD,
        WIDGET_CONTEXTS.CONTROLLING_VACANT_POSTIONS,
        WIDGET_CONTEXTS.CONTROLLING_STATISTICS,
        WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW,
        WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW_SIDEBAR,
        WIDGET_CONTEXTS.DAMAGE_MANAGER_TASKS_SIDEBAR,
        WIDGET_CONTEXTS.INBOX,
        WIDGET_CONTEXTS.DAMAGE_MANAGER_INBOX,
        WIDGET_CONTEXTS.WORKFLOW_PANEL_INBOX,
        WIDGET_CONTEXTS.WORKFLOW_PANEL_TASKS_SIDEBAR,
        WIDGET_CONTEXTS.WORKFLOW_PANEL_FORM_SIDEBAR,
        WIDGET_CONTEXTS.DAMAGE_MANAGER_ANWALT_KOMMUNIKATION,
        WIDGET_CONTEXTS.DAMAGE_MANAGER_ANWALT_KOMMUNIKATION_INVOICES,
        WIDGET_CONTEXTS.DAMAGE_MANAGER_INVOICES,
        WIDGET_CONTEXTS.WORKFLOW_PANEL_FORM,
        WIDGET_CONTEXTS.BRANCH_SPECIFIC_CONTROLLING_STATISTICS,
        WIDGET_CONTEXTS.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS,
        WIDGET_CONTEXTS.BRANCH_SPECIFIC_INBOX
    ]

    return activeWidgetContexts.map(context => {
        return widgetContextBuilder(context)
    })
}