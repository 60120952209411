import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import Preloader from 'application/components/controls/themed_preloader'

const InsuranceSelect = ({
    name='insuranceCompany ',
    label='Insurance',
    register,
    setValue,
    initialValue = null,
    menuPlacement='bottom',
    onChange = () => {},
    insurances,
    insurancesLoading,
    validate=null,
    error=null,
    maxMenuHeight=300
}) => {
    const {t} = useTranslation()

    if(insurancesLoading){
        return <Preloader />
    }

    const insuranceOptions = insurances.map((insurance) => ({
        value: insurance.id,
        label: insurance.name
    }))
    const initialOption = insuranceOptions.find(o => o.value === initialValue)

    return <Select 
        label={t(label)}
        name={name}
        register={register}
        setValue={setValue}
        options={insuranceOptions}
        defaultValue={initialOption}
        validate={validate}
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
        error={error}
        maxMenuHeight={maxMenuHeight}
    />
}

InsuranceSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}

export default InsuranceSelect