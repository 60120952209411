import { connect } from 'react-redux'

import { getInsurancesRequest } from 'application/redux/actions/common'
import InsuranceSelect from './effects'

const mapStateToProps = state => ({
    insurances: state.common.insurances,
    insurancesLoaded: state.common.insurancesLoaded,
    insurancesLoading: state.common.insurancesLoading,
})

const mapDispatchToProps = dispatch => {
    return {
        onGetInsurances: () => dispatch(getInsurancesRequest())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InsuranceSelect)