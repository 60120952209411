import React, {useEffect, useMemo, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'
import { StyledMapContainer } from './fragments'
import { ExternalMapLink } from './external_map_link'

const ChangeCenter = ({ center }) => {
    const map = useMap()

    useEffect(()=>{
        map.setView(center)
    }, [center, map])
    
    return null
}

export default ({
    mapWidth = '324px', 
    mapHeight = '324px', 
    zoom = 13, 
    latLong = null,
    mapLinkText = 'Show on bigger map',
    markerDraggable = true,
    onMarkerDragEnd = () => {},
    hideMapLink = false
}) => {
    const {t} = useTranslation()
    const markerRef = useRef(null)
    const [position, setPosition] = useState(latLong)

    const lat = latLong[0]
    const lon = latLong[1]

    useEffect(() => { 
        setPosition([lat, lon]) 
    }, [lat, lon])

    const markerEventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                    onMarkerDragEnd(marker.getLatLng())
                }
            },
        }),
        [onMarkerDragEnd]
    )

    if(position === null){
        return <React.Fragment />
    }

    return <StyledMapContainer mapWidth={mapWidth} mapHeight={`calc(${mapWidth} + 20px)`} >
        {
            position !== null && <React.Fragment>
                <MapContainer
                    center={position}
                    zoom={zoom}
                    style={{ width: mapWidth, height: mapHeight }}
                >
                    <ChangeCenter center={position} /> 
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker 
                        position={position} 
                        draggable={markerDraggable} 
                        ref={markerRef} 
                        eventHandlers={markerEventHandlers}
                        autoPan={true}
                    />
                </MapContainer>
                {
                    !hideMapLink && <ExternalMapLink position={position} zoom={zoom} linkText={t(mapLinkText)} />
                }
            </React.Fragment>
        }
    </StyledMapContainer>
}