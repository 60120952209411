import { connect } from 'react-redux'
import Thumbnail from './load_thumbnail_hoc'
import {getThumbnailById} from 'application/redux/actions/attachments'
import { moAddSelectedItemByName, moRemoveSelectedItemByName } from 'application/redux/actions/mass_operations'
import { fetchMoByName } from 'application/components/building_blocks/mass_operations/helper_functions'


const mapStateToProps = (state, ownProps) => {

    const mo = fetchMoByName(
        state,
        ownProps
    )

    return {
        attachments: state.attachments,
        mo
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoadAttachmentThumbnail: (
            id, 
            attachmentName, 
            damageId, 
            taskId, 
            match
        ) => dispatch(
            getThumbnailById(
                id, 
                attachmentName, 
                {
                    damageId, 
                    taskId, 
                    match
                }
            )
        ),
        onMoAddSelectedItemByName: (name, item) => dispatch(
            moAddSelectedItemByName(
                name,
                item
            )
        ),
        onMoRemoveSelectedItemByName: (name, id) => dispatch(
            moRemoveSelectedItemByName(
                name,
                id
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Thumbnail)