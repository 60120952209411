import React from 'react'
const { useSteps } = require('application/components/hooks')
const { useTranslation } = require('react-i18next')


export const withUploadSteps = (
    initialSteps = [
        {
            id: 0,
            label: 'Upload files'
        },
        {
            id: 1,
            label: 'Select Category & Lawyer'
        }
    ],
    initialStepIndex = 0
) => (Component) => ({ ...props }) => {

    const { t } = useTranslation()

    const translatedInitialSteps = initialSteps.map(
        step => ({
            ...step,
            label: t(step.label)
        })
    )

    const {
        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep
    } = useSteps(translatedInitialSteps, initialStepIndex)

    return <Component 
        {...props} 
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        getCurrentStep={getCurrentStep}
        getStepsState={getStepsState}
    />
}