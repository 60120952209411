import ACTIONS from 'application/constants'

export const getAllDocumentsRequestWorkflowPanel = (sharedResourceId) => ({
    type: ACTIONS.WORKFLOW_PANEL_DOCUMENTS_GET_ALL_DOCUMENTS_REQUEST,
    sharedResourceId
})

export const populateAllDocumentsWorkflowPanel = (documents, sharedResourceId, taskId) => ({
    type: ACTIONS.WORKFLOW_PANEL_DOCUMENTS_POPULATE_DOCUMENTS,
    documents,
    sharedResourceId,
    taskId
})

export const showAttachmentDetailsDCWorkflowPanel = (attachmentName, attachmentId, damageId, sharedResourceId, taskId, isExportedToLawFirm) => ({
    type: ACTIONS.WORKFLOW_PANEL_DOCUMENTS_SHOW_ATTACHMENT_DETAILS,
    attachmentName,
    attachmentId,
    damageId,
    sharedResourceId,
    taskId,
    isExportedToLawFirm
})

export const hideAttachmentDetailsDCWorkflowPanel = () => ({
    type: ACTIONS.WORKFLOW_PANEL_DOCUMENTS_HIDE_ATTACHMENT_DETAILS
})

export const removeAttachmentFromDocumentCenterWorkflowPanel = (attachmentId) => ({
    type: ACTIONS.WORKFLOW_PANEL_DOCUMENTS_REMOVE_ATTACHMENT,
    attachmentId
})

export const downloadAttachmentsWFPZipRequest = (sharedResourceId, contactPerson) => ({
    type: ACTIONS.WORKFLOW_PANEL_DOWNLOAD_ATTACHMENTS_ZIP,
    sharedResourceId,
    contactPerson
})

export const setArchiveDownloadingWorkflowPanel = (archiveDownloading = true) => ({
    type: ACTIONS.WORKFLOW_PANEL_DOCUMENTS_SET_ARCHIVE_DOWNLOADING,
    archiveDownloading
})