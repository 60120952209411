import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import DeleteTaskComponent from './component'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onDeleteTask: (dialogName, damageId, taskId, payload, successMsg) => {
            dispatch(sendPayloadRequest(dialogName, { damageId, taskId, successMsg }, { ...payload }))
        },
    }
}

export const DeleteTask =  connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteTaskComponent)