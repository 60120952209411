import React, { useState} from 'react'
import styled from 'styled-components'
import { HeaderContainer } from './fragments'
import { FormHeadline, Paragraph } from 'application/components/fragments/typography'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PushBottom16 } from 'application/components/pages/_common'
import { StepsNavigation } from './steps_navigation'
import { TextInput, DatePicker } from 'application/components/controls/form'
import { useStatefulForm } from 'application/components/hooks'
import { AddressDataPartialForm } from 'application/components/forms/_partial_forms'
import Collapsable from 'application/components/controls/collapsable'
import Image from 'application/components/controls/image'
import TwoColumns from 'application/components/forms/_common'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'

const StyledLawyer = styled.div`
    width: 200px;
    height: 80px;
    margin-bottom: 6px;
    background-color: white;
    border: 1px solid ${props => props.isActive ? props.theme.color.primary : props.theme.color.gray15};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & img{
        height: 90% !important;
    }
    & span{
        margin-top: 20px;
        color: ${props => props.theme.color.anthracite};
    }
`

export const ExtCreateDamageLawfirm= (
    {
        lawfirmData,
        setLawfirmData,
        expertData,
        setExpertData,
        setCurrentStep,
        firstBranch
    }
) => {
    const {t} = useTranslation()
    const [commissionExpert, setCommissionExpert] = useState(false)
    const [commissionLawyer, setCommissionLawyer] = useState(false)

    const defaultValues = {
        expert: {
            name: '',
            email: '',
            inspectionSite: {
                ...firstBranch.address
            },
            inspectionDate: null,
            inspectionName: firstBranch.name
        }
    }

    const { 
        register,
        errors, 
        handleSubmit, 
        getValues, 
        control, 
        updateFormState
    } = useStatefulForm({defaultValues})

    const {
        mustBeEmail,
        notEmpty,
        mustBeGermanPostalCode
    } = validationMethods

    return <HeaderContainer>

        <FlexBox flexDirection='column'>

            <Paragraph cssAlign='center'>
                Entscheiden Sie, ob Sie die Schadenabwicklung an einen Sachverständigen und/oder Anwalt übergeben wollen.
            </Paragraph>
            <PushBottom16 />
            <Collapsable
                headline='An Sachverständigen übergeben' 
                initiallyCollapsed={true}
                onCollapseChange={collapsed => setCommissionExpert(!collapsed)}
                cssOnly={false}
                checkBoxStyle={true}
            >
                <FlexBox flexDirection='column'>
                    <TwoColumns 
                        columnLeft={
                            <DatePicker
                                label={t('Inspection date')}
                                name='expert.inspectionDate'
                                validate={
                                    {
                                        notEmpty
                                    }
                                }
                                error={errors.expert?.inspectionDate}
                                onDateChange={updateFormState}
                                control={control}
                            />
                        }
                    />

                    <TwoColumns 
                        columnLeft={
                            <TextInput
                                label={t('Name')} 
                                name='expert.name'
                                register={register}
                                validate={{notEmpty}}
                                error={errors.expert?.name}
                                autoFocus={true}
                                onChange={updateFormState}
                            />
                        }
                        columnRight={
                            <TextInput
                                label={t('E-Mail')} 
                                name='expert.email'
                                register={register}
                                validate={{notEmpty, mustBeEmail}}
                                error={errors.expert?.email}
                                onChange={updateFormState}
                            />
                        }
                    />

                    <FormHeadline>{t('Inspection site')}</FormHeadline>
                    <TextInput
                        label={t('Name')} 
                        name='expert.inspectionName'
                        register={register}
                        validate={{notEmpty}}
                        error={errors.expert?.inspectionName}
                        onChange={updateFormState}
                    />
                    <AddressDataPartialForm
                        register={register}
                        errors={errors}
                        disabled={false}
                        nameStreet = 'expert.inspectionSite.line1'
                        namePostalCode = 'expert.inspectionSite.postalCode'
                        nameCity = 'expert.inspectionSite.locality'
                        validate={{notEmpty}}
                        validatePostalCode={{notEmpty, mustBeGermanPostalCode}}
                        errorNameStreet={errors.expert?.inspectionSite?.line1}
                        errorNamePostalCode={errors.expert?.inspectionSite?.postalCode}
                        errorNameCity={errors.expert?.inspectionSite?.locality}
                    />
                </FlexBox>
            </Collapsable>
                
            <Collapsable
                headline='An Anwaltskanzlei übergeben'
                initiallyCollapsed={true}
                onCollapseChange={collapsed => setCommissionLawyer(!collapsed)}
                cssOnly={false}
                checkBoxStyle={true}
            >
                <FlexBox alignItems='center' flexDirection='column'>
                    <Paragraph cssAlign='center'>Ihre Schadenmeldung und alle Dokumente werden übergeben an </Paragraph>
                    <PushBottom16 />
                    <StyledLawyer>
                        <Image data={lawfirmData.lawfirm.logo} />
                    </StyledLawyer>
                    <Paragraph  cssAlign='center'>{lawfirmData.lawfirm.name}</Paragraph>
                </FlexBox>
            </Collapsable>
        </FlexBox>
        <PushBottom16 />
        <StepsNavigation 
            onClickPrevious={()=>{
                setCurrentStep(0)
            }}
            onClickNext={
                handleSubmit(() => {
                    setLawfirmData({
                        lawfirm: lawfirmData.lawfirm,
                        commissionLawyer
                    })
                    setExpertData({
                        selectedExpert: getValues().expert,
                        commissionExpert
                    })
                    setCurrentStep(2)
                })
            }
        />    
        
    </HeaderContainer>
}