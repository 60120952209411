import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.gallery, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.gallery
    }
    case ACTIONS.GALLERY_SHOW_GALLERY:
        return {
            ...state,
            isVisible: true,
            damageId: action.damageId,
            selectedAttachmentId: action.attachmentId,
            selectedAttachmentName: action.attachmentName,
            taskId: action.taskId,
            match: action.match,
        }
    case ACTIONS.GALLERY_SET_ACTIVE_ATTACHMENT:
        return {
            ...state,
            damageId: action.damageId,
            selectedAttachmentId: action.attachmentId,
            selectedAttachmentName: action.attachmentName
        }
    case ACTIONS.GALLERY_HIDE_GALLERY:
        return {
            ...state,
            isVisible: false,
            damageId: null,
            selectedAttachmentId: null,
            selectedAttachmentName: '',
            taskId: null,
            match: null,
        }
    default:
        return state
    }
}
