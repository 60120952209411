import { connect } from 'react-redux'
import ActivityLog from './component'
import { getActivitiesRequest } from 'application/redux/actions/pages/activity_log'
import { UPDATE_CONSTRAINTS } from 'application/constants'
import { getUpdateConstraint } from 'application/redux/selectors'
import { MASS_OPERATIONS } from 'application/constants'

const mapStateToProps = state => {
    return {
        loggedIn: state.auth.loggedIn,
        activityLog: state.pages.activityLog,
        updateConstraint: getUpdateConstraint(state, UPDATE_CONSTRAINTS.ACTIVITY_LOG),
        moName: MASS_OPERATIONS.NAME.ACTIVITIES_ALL_BRANCHES,
        moType: MASS_OPERATIONS.TYPE.ACTIVITIES,
    }
}




const mapDispatchToProps = dispatch => {
    return {
        onLoadActivitiesList: (
            offset,
            limit,
            orderBy,
            direction,
            filterQuery 
        ) => dispatch(
            getActivitiesRequest(
                offset,
                limit,
                orderBy,
                direction,
                filterQuery
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityLog)
