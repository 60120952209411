import { connect } from 'react-redux'

import { createCommentWorkflowPanel } from 'application/redux/actions/pages/damage_manager/comments'
import { LawfirmTaskComments } from './component'

const mapStateToProps = state => ({
    lawfirm: state.pages.workflowPanel.shared.lawfirm,
    lawfirmLoaded: state.pages.workflowPanel.shared.lawfirmLoaded,
    taskLoaded: state.pages.workflowPanel.tasks.selectedTaskLoaded
})

const mapDispatchToProps = dispatch => {
    return {
        onSaveComment: (
            sharedResourceId, 
            taskId, 
            comment
        ) => dispatch(
            createCommentWorkflowPanel(
                sharedResourceId, 
                taskId, 
                comment
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LawfirmTaskComments)