import React from 'react'
import { isPermittedInSpecificBranch } from '../is_permitted_in_specific_branch'
        
export const ForCurrentBranchPermissionsComponent = (
    {
        branchPermissions, 
        permissions,
        children , 
        allNeeded = false
    }
) => {
    return <React.Fragment>
        { 
            isPermittedInSpecificBranch(
                branchPermissions, 
                permissions, 
                allNeeded
            ) 
            && children 
        }
    </React.Fragment>
}

export default ForCurrentBranchPermissionsComponent