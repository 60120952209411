import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import { FILTERS } from 'application/constants'

const ProprietaryReasonSelect = ({
    name = 'proprietaryReason',
    register,
    setValue,
    error,
    initialValue = FILTERS.VALUES.DAMAGES.PROPRIETARY_REASON.NOT_SET,
    validate = null,
    menuPlacement = 'bottom',
    onChange
}) => {
    const { t } = useTranslation()
    const proprietaryReasonOptions = [
        {
            value: FILTERS.VALUES.DAMAGES.PROPRIETARY_REASON.NOT_SET,
            label: t('Not set')
        },
        {
            value: FILTERS.VALUES.DAMAGES.PROPRIETARY_REASON.LEASING_CONTRACT,
            label: t('Leasing contract')
        },
        {
            value: FILTERS.VALUES.DAMAGES.PROPRIETARY_REASON.FINANCING_CONTRACT,
            label: t('Financing contract')
        },
    
    ]

    const initialOption = proprietaryReasonOptions.find(o => o.value === initialValue)

    return <Select
        error={error}
        label={t('Proprietary reason')}
        name={name}
        register={register}
        setValue={setValue}
        options={proprietaryReasonOptions}
        defaultValue={initialOption}
        validate={validate} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
    />
}

ProprietaryReasonSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}

export default ProprietaryReasonSelect