import React from 'react'
import { Redirect, withRouter } from 'react-router'
import { ROUTES } from 'application/constants'
import { connect } from 'react-redux'
import { setReturnUrl } from 'application/redux/actions/auth'

const AuthRedirectComponent = ({
    auth, 
    isValidUser, 
    isLoadingUser, 
    onRedirectToLogin,
    children,
    match
}) => {
    if (!isValidUser && !isLoadingUser){
        onRedirectToLogin(match.url)
        const redirectTo = auth.manualLoggedOut
            ? ROUTES.EXT_LOGIN
            : `${ROUTES.EXT_LOGIN}?returnUrl=${match.url}`
        return <Redirect to={redirectTo}/>
    }

    if (!auth.accounts.found) {
        return <Redirect to={ROUTES.NO_ACCOUNTS} />
    }

    if (!auth.permissionsFound) {
        return <Redirect to={ROUTES.NO_PERMISSION} />
    }

    return <React.Fragment>{children}</React.Fragment>
}


const mapDispatchToProps = dispatch => {
    return {
        onRedirectToLogin: (returnUrl) => dispatch(setReturnUrl(returnUrl))
    }
}

export const AuthRedirect = connect(
    state => ({
        auth: state.auth,
        isValidUser: state.oidc.user !== null,
        isLoadingUser: state.oidc.isLoadingUser
    }),
    mapDispatchToProps
)(withRouter(AuthRedirectComponent))