import { connect } from 'react-redux'
import CreateCaseNotesComponent from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onCreateTask: (
            name, 
            payload, 
            damageId
        ) => dispatch(
            sendPayloadRequest(
                name, 
                payload, 
                {damageId}
            )
        ),
    }
}

export const CreateCaseNotes = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateCaseNotesComponent)