import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* addCustomStateSaga(action) {
    try {
        const {customStateName} = action.payload
        const {systemStateId} = action.metaData

        yield resourceCall(
            api.gateway.damageState.addCustomState, 
            null,
            {
                customStateName, 
                systemStateId
            }
        )
        yield put(showToast('State created'))
    } catch (e) {
        yield handleError(e, action)
    }
}