import React from 'react'
import { AccountBox } from 'application/components/building_blocks/account_box'
import { useTableLoader } from 'application/components/hooks'
import { withRouter } from 'react-router-dom'
import { configuration } from './configuration'
import { FlexBox } from 'application/components/fragments/flex_box'
import { RoutedPagination } from 'application/components/building_blocks/routed_pagination'


export default withRouter(({
    accounts,
    preferredAccountId,
    onSetAsPreferredAccount,
    onUnsetAsPreferredAccount,
    onLoadAccounts,
    updateConstraint = null,
    accountsTotal,
    match
}) => {
    useTableLoader(onLoadAccounts, match, configuration, updateConstraint)

    return <React.Fragment>
        {
            accounts.map(
                (a, i) => {
                    return <AccountBox
                        to={ configuration.href(a)}
                        key={i} 
                        account={a}
                        address={a.address}
                        isPreferred={a.id === preferredAccountId}
                        onSetAsPreferredAccount={onSetAsPreferredAccount}
                        onUnsetAsPreferredAccount={onUnsetAsPreferredAccount}
                    />
                }
            )
        }
        <br />
        {
            accounts.length > 0 
            && <FlexBox justifyContent='center'>
                <RoutedPagination
                    totalSize={parseInt(accountsTotal)}
                    itemsPerPage={configuration.pagination.getItemsPerPage()}
                    parameterNames={configuration.pagination}
                />
            </FlexBox>
        }
    </React.Fragment>
})