import { nanoid } from 'nanoid'
import { isDefined } from './data_helpers'

export const getFileExtension = filename => /(?:\.([^.]+))?$/.exec(filename)[1]

const arrayBufferToBase64 = (buffer) => {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

export const mapFileToAttachmentDataStructure = (file, binaryData) => {
    var extensionRegEx = /(?:\.([^.]+))?$/
    const displayableExtensions = process.env.REACT_APP_SUPPORTED_THUMBNAIL_EXTENSIONS_FROM_UPLOAD
    const currentExtension = extensionRegEx.exec(file.name)[1]

    if (currentExtension === undefined || displayableExtensions.indexOf(currentExtension.toLowerCase()) === -1) {
        return {
            hasThumbnail: false,
            thumbnailIsLoading: false,
            fileExtension: currentExtension,
            fileName: file.name,
            thumbnail: ''
        }
    }

    return {
        hasThumbnail: true,
        thumbnailIsLoading: false,
        fileExtension: currentExtension,
        fileName: file.name,
        thumbnail: 'data:image/png;base64,' + arrayBufferToBase64(binaryData)
    }
}

export const getAccept = () => {
    const allowedExtensions = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_EXTENSIONS
    const extensionsArray = allowedExtensions.split(',')
    let accept = ''
    for (let i = 0; i < extensionsArray.length; i++) {
        accept += '.' + extensionsArray[i]
        if (i < extensionsArray.length - 1) {
            accept += ','
        }
    }
    return accept
}

export const isAcceptedFileExtension = ext => {
    const allowedExtensions = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_EXTENSIONS
    const extensionsArray = allowedExtensions.split(',')
    return extensionsArray.indexOf(ext) > -1
}

export const isEmpty = file => {
    return file.size === 0
}

export const readFiles = (acceptedFiles, callback, allFilesCallback = undefined) => {
    let allFiles = []
    let processedFiles = 0
    const filesCount = acceptedFiles.length

    acceptedFiles.forEach(file => {
        const reader = new FileReader()
        const tempId = nanoid()
        reader.onloadend = () => {
            callback({
                tempId,
                name: file.name,
                metaData: file,
                binaryData: reader.result,
                attachment: mapFileToAttachmentDataStructure(file, reader.result),
                isExportedToLawfirm: false
            })
            allFiles.push({
                tempId,
                name: file.name,
                metaData: file,
                binaryData: reader.result,
                attachment: mapFileToAttachmentDataStructure(file, reader.result),
                isExportedToLawfirm: false
            })
            processedFiles++
            if (allFilesCallback !== undefined && processedFiles === filesCount) {
                allFilesCallback(allFiles)
            }
        }
        reader.readAsArrayBuffer(file)
    })
}

export const isExportedToLawfirm = (element) => {
    if(!isDefined(element?.isExportedToLawFirm))  return false
    return element.isExportedToLawFirm === true
}