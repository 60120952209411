import React from 'react'
import { WIDGET_CONTEXTS } from 'application/constants'
import WidgetArea from 'application/components/building_blocks/widget_area'
import DamageManager from '../container'
import { SubPageLayout } from 'application/components/pages/_common'
import DamageManagerInboxTabbarButtons from './tab_bar_buttons'


const DamageManagerInbox = ({subRoute}) => {
    return <DamageManager
        titleSegments={['Inbox']}
        subRoute={subRoute}
        tabBarButtons={ <DamageManagerInboxTabbarButtons /> }
    >
        <SubPageLayout>
            <WidgetArea 
                widgetContext={WIDGET_CONTEXTS.DAMAGE_MANAGER_INBOX} 
                direction='row' 
                successively={true}
            />
        </SubPageLayout>
    </DamageManager>
}

DamageManagerInbox.propTypes = {

}

export default DamageManagerInbox
