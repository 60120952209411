import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'application/components/hooks'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { ROUTE_PARAMETERS, ROUTES } from 'application/constants'
import { PushBottom32, PushBottom16 } from 'application/components/pages/_common'
import { TableWrapper, TableHeader, TableRowLink, TableCell } from 'application/components/fragments/table'
import { RoutedPagination } from 'application/components/building_blocks/routed_pagination'
import TableHeaderLink from 'application/components/controls/table_header_link'
import FullName from 'application/components/controls/full_name'
import DamageTypeName from 'application/components/name_mappings/damage_type_name'
import DamageStatus from 'application/components/controls/damage_status'
import { WidgetInformation } from 'application/components/widgets/_common'
import { Headline2 } from 'application/components/fragments/typography'
import { VacantPositionsBar } from 'application/components/charts'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { withTheme } from 'styled-components'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { withRouter } from 'react-router-dom'
import {JobNumber} from 'application/components/controls/job_number'
import DamageLicenseplate from 'application/components/controls/damage_licenseplate'

const ControllingVacantPositionsDamagesTableInner = ({payload, loadingFailed, onRefresh, widgetKey, onOpenDamageInvoicesDialog, theme, match}) => {
    const query = useQuery()
    const {t} = useTranslation()

    if(loadingFailed){
        return <React.Fragment>
            <Headline2>{t('Damages with vacant positions')}</Headline2>
            <PushBottom16 />
            <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
        </React.Fragment>
    }
    
    const {damages, total} =  payload.response
    const handleRefresh = () => onRefresh(widgetKey)
    const limit = query.getParsedParam(ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.LIMIT) === 0 ? 10 : query.getParsedParam(ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.LIMIT) 

    const sorting = {
        sortParamKey: ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.KEY,
        directionParamKey: ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.DIRECTION,
        defaultSortParamValue: ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.VALUES.VACANT_POSITIONS,
        defaultDirectionParamValue: ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING
    }

    return <React.Fragment>
        <Headline2>{t('Damages with vacant positions')}</Headline2>
        <PushBottom16 />
        <TableWrapper>
            <TableHeader>

                <TableCell cssWidth='12%'>{t('Job number')}</TableCell>
                <TableCell cssWidth='13%'>
                    <TableHeaderLink {...sorting}
                        sortParamValue={ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.VALUES.LICENSE_PLATE}
                        onLinkClick={handleRefresh}
                    >{t('License plate')}</TableHeaderLink>
                </TableCell>
                <TableCell cssWidth='11%'>{t('Damage type')}</TableCell>
                <TableCell cssWidth='15%'>
                    <TableHeaderLink {...sorting}
                        sortParamValue={ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.VALUES.STATUS}
                        onLinkClick={handleRefresh}
                    >{t('Status')}</TableHeaderLink>
                </TableCell>
                <TableCell cssWidth='13%'>{t('Assignee')}</TableCell>
                <TableCell cssWidth='20%'>
                    <TableHeaderLink {...sorting}
                        sortParamValue={ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.VALUES.VACANT_POSITIONS}
                        onLinkClick={handleRefresh}
                    >{t('Vacant positions')}</TableHeaderLink>
                </TableCell>
                <TableCell cssWidth='15%'></TableCell>
            </TableHeader>

            {
                damages.map((r, i) => {
                    return  <TableRowLink
                        key={i} to={getAccountLinkHref(match, `${ROUTES.DAMAGE_MANAGER_BASE}${r.id}`)}>
                            
                        <TableCell cssWidth='12%'>
                            <JobNumber number={r.jobNumber} />
                        </TableCell>
                        <TableCell cssWidth='13%'><DamageLicenseplate damage={r} /></TableCell>
                        <TableCell cssWidth='11%'><DamageTypeName damageType={r.damageType} /></TableCell>
                        <TableCell cssWidth='15%'><DamageStatus status={r.state} /></TableCell>
                        <TableCell cssWidth='13%'><FullName person={r.assignee} /></TableCell>
                        <TableCell cssWidth='20%'>
                            <VacantPositionsBar unpaidTotal={r.unpaidTotal} paidTotal={r.paidTotal} />
                        </TableCell>
                        <TableCell cssWidth='15%'>
                            <IconButtonWithLabel 
                                fontSize={theme.fontSize.small}
                                iconKey='keyboard_arrow_right'
                                underlined={true} 
                                cssPadding='0'
                                onButtonClick={(e)=>{e.preventDefault();onOpenDamageInvoicesDialog(r.id, r.vehicle.licensePlate)}} 
                                label={t('Show invoices')} 
                            />
                        </TableCell>
                    </TableRowLink>
                })
                   
            }

            {
                total === 0 && <WidgetInformation>{t('No damages found')}</WidgetInformation>
            }


        </TableWrapper>
        <PushBottom32 />
        <RoutedPagination
            totalSize={total}
            itemsPerPage={limit}
            parameterNames={{
                offset: ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.OFFSET,
                limit: ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.LIMIT
            }}
            onPaginationChange={handleRefresh}
        />
    </React.Fragment>
}

ControllingVacantPositionsDamagesTableInner.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

const ControllingVacantPositionsDamagesTable = withRouter(withTheme(ControllingVacantPositionsDamagesTableInner))

export { ControllingVacantPositionsDamagesTable }