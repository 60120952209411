import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom48, PushBottom8 } from 'application/components/pages/_common'
import { ChangelogList, ChangelogListItem, ChangelogItemType, ChangelogItemTextWrapper, ChangelogTag } from './fragments'
import { Headline2, Paragraph, TextButton, TextLink, TextIcon } from 'application/components/fragments/typography'
import LayoutAllBranches from 'application/components/pages/_layout/layout_all_branches'
import { FlexBox } from 'application/components/fragments/flex_box'

export default ({onShowNotificationsSidebar}) => {

    const {t} = useTranslation()

    return <LayoutAllBranches
        titleSegments={['Whats`s new?']}
        headlineGeneric={t('Whats`s new?')}
    >
        <PushBottom8 />
        <Paragraph>
                    Hier finden Sie Informationen zu den neusten Funktionen und aktuellen Behebungen von Bugs des e.sy 360. 
        </Paragraph>
        <Paragraph>
                    Um die Online-Plattform stetig zu verbessern, freuen wir uns auf Ihr Feedback und Anregungen. Einfach E-Mail an: <TextLink href='mailto:e.sy360@e-consult.de'>e.sy360@e-consult.de</TextLink> senden.
        </Paragraph>
        <PushBottom48 />
        <FlexBox 
            width='60%'
            justifyContent='center'
        >
            <FlexBox alignItems='flex-start'>
                <FlexBox flexDirection='column'>


                    <Headline2>
                        <ChangelogTag>UPDATE</ChangelogTag>
                        <ChangelogTag version='true'>v1.0.1</ChangelogTag>
                        20.01.2022
                    </Headline2>
                    <ChangelogList>
                        {/* <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Bei der DEKRA Beauftragung wird der Benutzer auf den ausgewählten Besichtigungstermin
                                    hingewiesen, wenn das Datum in der Vergangenheit liegt. 
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem> */}

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Wird der Schadenmanager geschlossen, wird der Benutzer zum Dashboard der letzten Filiale weitergeleitet.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Account-Stammdaten wurden um E-Mail und Telefonnummer erweitert
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Anwalt kann nun auch aus jeder Situation des Schadenmanagers beauftragt werden. Hierfür 
                                    wurde ein neuer Button in der Titlebar eingefügt.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Der nachträgliche Upload von Dateien an Rechnungen ist ab sofort möglich.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Erweiterung des Schadenanlage-Dialoges:   Es können nun im Zuge der Schadenanlage die Schadendaten, Kundendaten sowie die Versicherungsinfo
                                    eingepflegt werden.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Filialdaten beinhalten jetzt auch die Telefonummer und Emailadresse.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Versicherungsliste wurde mit dem Feld "sonstige Versicherung" erweitert.
                                    Es können nun auch Versicherungen genannt werden, welche nicht in der 
                                    vorgegebenen Namensliste erscheinen.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Rechnungsdokumente können über die Rechnung mit hochgeladen werden.
                                    Diese können im Dokumentencenter eingesehen werden. 
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Die Errorseiten werden nun konsistent dargestellt
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Einführung der Gutachterbeauftragung. 
                                    {/* Es kann sowohl eine strukturierte, 
                                    als auch eine einfache Beauftragung initiert werden.*/}  In der einfachen Beauftragung 
                                    können der Gutachter, das Besichtigungsdatum und der Besichtigungsort angegeben werden. 
                                    {/*                                     
                                    Die strukturierte Beauftragung ist hingegen genau auf die hierfür angesetzte Expertenorganisation
                                    zugeschnitten. Im ersten Schritt werden Dokumente hochgeladen, die dem Gutachter zur Verfügung 
                                    gestellt werden sollen. Im zweiten und dritten Schritt können die Schaden- sowie Kundendaten vervollständigt werden. 
                                    Im letzten Schritt wird der Besichtigungsort festgelegt.  */}
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Benutzerverwaltung: Nutzer können ab sofort gesperrt und entsperrt werden.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>


                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Schadenliste: Neuer Filter für laufende und abgeschlossene Fälle verfügbar.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Das Workflowpanel beinhaltet nun einen Ordner für ausgehende Nachrichten. 
                                    Nachrichten die vom Anwalt versandt wurden, werden hier aufgelistet und 
                                    können vom Anwalt eingesehen werden.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Hochgeladene Dokumente können ab sofort für Kanzleisoftware bereit gestellt werden.
                                    Für die Kanzleisoftware bereitgestellte Dokumente können nach der Bereitstellung erneut synchronisiert werden.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Anzeige von zusätzlichen Autohaus Informationen in der Titelleiste des Workflowpanels. 
                                    Es werden der Schadenbearbeiter, der Name des Accounts sowie die Filiale angezeigt.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                    </ChangelogList>





                    <Headline2>
                        <ChangelogTag>UPDATE</ChangelogTag>
                        <ChangelogTag version='true'>v1.0.0</ChangelogTag>
                        1.10.2022
                    </Headline2>
                    <ChangelogList>
                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                Einführung eines Gruppen- und Filialmanagement-Systems: 
                                Die Online-Plattform ist filialübergreifend oder filialspezifisch nutzbar. 
                                Filialübergreifend werden alle Schäden einer Autohausgruppe angezeigt. 
                                Schäden einer ausgewählten Filiale werden in der filialspezifischen Ansicht angezeigt. 
                                Der Benutzer kann jederzeit zwischen den verschiedenen Ansichten wechseln.  
                                </Paragraph>
                                <Paragraph>
                                Schadenfälle können einzelnen Filialen zugeordnet werden.
                                Bei der Anlage eines Schadens erfolgt eine (Sicherheits-) Abfrage, ob der Schaden in der richtigen Filiale angelegt wird.
                                </Paragraph>
                                <Paragraph>
                                Festlegen von Filialen und Benutzergruppen erfolgt in der Rubrik „Administration“ unter dem Reiter Gruppen & Filialen. 
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                Einführung eines Rechtemanagements: 
                                Zuweisen von unterschiedlichen Rechten für Mitarbeitergruppen entsprechende Ihrer Rollen und Befugnisse ist möglich. 
                                Es existieren Lese-, Bearbeitungs- und Löschrechte. 
                                </Paragraph>
                                <Paragraph>
                                Zuweisen von Rechten erfolgt in der Rubrik „Administration“ unter dem Reiter Gruppen & Filialen. <br/>
                                Dort befindet sich eine detaillierte Beschreibung der einzelnen Rechte.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                Redesign der Benutzeroberfläche: 
                                Die Benutzeroberfläche wurde überarbeitet und eine neue Navigation entwickelt.
                                Die farbig hinterlegte Benutzerführung bildet die Fillialstruktur ab und 
                                sorgt für Übersicht. <br/>
                                Dieses kann zudem platzsparend nach Bedarf ein- oder ausgeklappt werden. 
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                In der Rubrik „Aktivitäten“ sind alle Ereignisse, die in einem Schadenfall erfolgten, in Form einer Liste dargestellt. 
                                Eine Filterung von Aktivitäten in einer bestimmten Zeitspanne ist möglich.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                Allgemeine Aktivitätenlisten sind sowohl für das gesamte Unternehmen als auch für einzelne Fillialen abrufbar.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>
                    </ChangelogList>



                    <Headline2>
                        <ChangelogTag>UPDATE</ChangelogTag>
                        <ChangelogTag version='true'>v0.8.7</ChangelogTag>
                       1.6.2022
                    </Headline2>
                    <ChangelogList> 

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                Bei der Erfassung der Kundendaten ist es möglich, die interne Kundennummer des Autohauses zu erfassen. 
                                Dem am Fall beteiligten Anwalt wird diese Info mitgeteilt.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    In der Rubrik „Aufgaben“ ist eine Filterung nach internen und externen gestellten Aufgaben möglich. 
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Der Wechsel des Schadenbearbeiters führt zur Benachrichtigung der am Schaden berechtigten Personen. 
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Die Benachrichtigung innerhalb der Webakte wurde um folgende Daten erweitert: 
                                    IBAN, Art der Finanzierung, Leasinginfos, Eigentümer, Vertragsnummer, Vorsteuerabzugsberechtigt und Art der Selbstbeteiligung.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Die Darstellung der Prioritäten von abzuarbeitenden Aufgaben wurde überarbeitet. 
                                    Die Priorität wird farblich deutlich hervorgehoben. Zudem werden diese
                                    nun auch in der Aufgabenliste angezeigt.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Bei einer Auszahlung auf eine gekürzte Rechnung ist die Auswahl mehrerer Kürzungsgründe möglich.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Übersichtliche Strukturierung der Schadenabwicklungsformulare.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Erweiterung der Aktivitäten und somit auch der Benachrichtigungen der Nutzer. 
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                    </ChangelogList> 



                    <Headline2>
                        <ChangelogTag>UPDATE</ChangelogTag><ChangelogTag version='true'>v0.8.6</ChangelogTag>10.12.2021
                    </Headline2>
                    <ChangelogList>                                
                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Bei der Anlage eines neuen Benutzers ist es möglich, diesem eine Rolle zuzuweisen. Es wird unterschieden zwischen der Rolle eines Administrators und der eines Benutzer. Ein Administrator ist in der Lage, Benutzer für die Anwendung anzulegen. 
                                </Paragraph>
                                <Paragraph>
                                        Zusätzliche Neuerung: Es können mehr als zwei Administratoren angelegt werden.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Bugfix :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                            Schadenliste: Das Paging der Schadentabelle funktioniert ordnungsgemäß.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>                                
                            <ChangelogItemType>Bugfix :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                            Registrierung: Ein Weiterleitungsfehler beim initialen Login nach der Neuregistrierung eines Benutzers wurde behoben.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>  
                            <ChangelogItemType>Bugfix :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                    Controlling-App: Der Zeitraumfilter für die Statistik der erstellten Schäden ist jetzt auswählbar.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>


                    </ChangelogList>

                    <Headline2><ChangelogTag>UPDATE</ChangelogTag><ChangelogTag version='true'>v0.8.5</ChangelogTag>12.11.2021</Headline2>
                    <ChangelogList>

                                
                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>User, die mehrere Autohäuser betreuen, werden nach dem Login zur Auswahl des Autohauses (oder des Unternehmens) aufgefordert, in welchem sie aktiv werden. Darüberhinaus kann jederzeit zwischen den Unternehmen gewechselt werden.</Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>Übersichtsliste der Schäden enthält eine neue Kategorie "Schadennummer".</Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>Suche nach Schäden kann über den Filter "Schadennummer" erfolgen.</Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>Unter dem Navigationspunkt Aufgaben ist eine Filterung nach "Offen", "Fertig" oder "Alle" Aufgaben möglich.</Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>Kommentare zu Auszahlungen und Stornierungen können in Rechnungen eingeblendet werden.</Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>Beim Aufrufen des Navigationspunktes "Aufgaben" wird initial die Liste der offenen Aufgaben des Users angezeigt.</Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>Das Dokumenten- und Fotocenter eines Falles ist erweitert um die neue Kategorie "Dokumente zum Schaden". </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>
                    </ChangelogList>


                    <Headline2><ChangelogTag>UPDATE</ChangelogTag><ChangelogTag version='true'>v0.8.4</ChangelogTag>29.10.2021</Headline2>
                    <ChangelogList>
                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>Die Funktion Umfang einer Rechnungsauszahlung ist neben den Rubriken "Komplett erfolgt" und "Teilweise erfolgt" um die Rubrik "Mit Kürzung erfolgt" erweitert. Über ein Auswahlfeld sind vorgegebene Gründe für die Auszahlungskürzung auswählbar.</Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                        <ChangelogListItem>
                            <ChangelogItemType>Improvement :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>Auch wenn in einen Schadenfall kein Anwalt involviert ist, können Sie nun als Autohausmitarbeiter Rechnungen anlegen oder Auszahlungen vornehmen. Darüber hinaus erfolgt eine Erweiterung des Rechnungsformulars um: Erstellungsdatum, Kundennummer, Rechnungsnummer und Rechnungstyp.</Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>
                    </ChangelogList>


                    <Headline2><ChangelogTag>UPDATE</ChangelogTag><ChangelogTag version='true'>v0.8.3</ChangelogTag>09.07.2021</Headline2>
                    <ChangelogList>
                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>Change log hinzugefügt (diese Seite).</Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>
                        <ChangelogListItem>
                            <ChangelogItemType>Feature :</ChangelogItemType>
                            <ChangelogItemTextWrapper>
                                <Paragraph>
                                                Echtzeit-Benachrichtigungen
                                </Paragraph>
                                <Paragraph>
                                            Durch Klick auf das <TextButton onClick={onShowNotificationsSidebar}><TextIcon><FontIcon fontSize='inherit' icon='notifications' /></TextIcon>-Symbol</TextButton> in der Kopfzeile finden Sie die letzten 10 Nachrichten und Aktivitäten zu Ihren Schadenfällen.
                                </Paragraph>
                            </ChangelogItemTextWrapper>
                        </ChangelogListItem>

                    </ChangelogList>
                                
                </FlexBox>
            </FlexBox>
        </FlexBox>
    </LayoutAllBranches>
}