import React from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { MoDocumentListElement } from '../mass_operations/documents/_common/list_element'
import { Footer } from 'application/components/dialogs/_common/footer'
import { useTranslation } from 'react-i18next'
import { PushBottom16, PushBottom32, Spacer } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'
import { FontIcon } from '@ec/ui-controls-react'
import { INFOBOX } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'
 

export const ExportToLawFirmConfirmationComponent = (
    {
        abort, 
        onSubmit, 
        dialog,
    }
) => {
    const {t} = useTranslation()

    const {
        files = [],
        sendPayloadRequestData = null
    } = dialog.payload

    const handleSubmit = () => {
        onSubmit(
            sendPayloadRequestData.dialogName,
            sendPayloadRequestData.payload,
            sendPayloadRequestData.metaData        
        )
    }

    return  <React.Fragment>
        <DialogMainContent cssHeight='400px'>
            <PushBottom32/>
            <InfoBox 
                type={INFOBOX.TYPES.SECONDARY} 
                direction='row' 
                cssMargin='0'
            >
                <FontIcon icon='info_outline' />
                <Spacer basis='10px' />
                <FlexBox flexDirection='column'>
                    {t('The following files are sent directly to the law firm.')}<br/>
                    {t('This operation cannot be undone.')}
                </FlexBox>
                <Spacer />
            </InfoBox>
            <PushBottom16/>
            {
                files.map(
                    (file, index) =>  <MoDocumentListElement
                        key={index}
                        document={file.attachment}
                        removeItem={() => {}}
                        hideRemoveButton={true}
                    />
                )
            }
        </DialogMainContent> 
        <Footer
            onAbort={abort} 
            onSubmit={handleSubmit}
            submitButtonText={ t('Confirm') }
            submitDisabled={false}
        />  
    </React.Fragment>
}