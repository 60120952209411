import { connect } from 'react-redux'
import ForLoggedInUser from './component'

const mapStateToProps = (state) => {
    return {
        loggedIn: state.auth.loggedIn,
        permissionsLoaded: state.auth.permissions.length > 0
    }
}

export default connect(
    mapStateToProps
)(ForLoggedInUser)