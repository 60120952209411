import React from 'react'
import ThemedPreloader from 'application/components/controls/themed_preloader'
import PageHeadline from 'application/components/building_blocks/page_headline'
import { withTheme } from 'styled-components'

const PageBranchHeadlineComponent = (
    {
        currentBranch,
        currentBranchLoaded,
        headline='',
        theme,
        children
    }
) => {
    return <PageHeadline
        headlineGeneric={
            currentBranchLoaded 
                ? <React.Fragment>{currentBranch.name}</React.Fragment>
                : <ThemedPreloader small={true} paddingTop={6} />
        }
        headlineSpecific={headline}
        headlineGenericColor={theme.color.primary}
    >
        {currentBranchLoaded && children}
    </PageHeadline>
}

export default withTheme(PageBranchHeadlineComponent)
