import React from 'react'
import styled from 'styled-components'
import {FlexBox} from 'application/components/fragments/flex_box.js'
import DamageState from 'application/components/controls/damage_status'
import { PushBottom8 } from 'application/components/pages/_common'
import PropTypes from 'prop-types'
import {MoDialogTable} from 'application/components/dialogs/mass_operations/_common/tables'
import {tableConfiguration} from 'application/components/dialogs/mass_operations/_common/tables/configurations/damages'


const StyledDamagesByStateTable = styled.div`
    margin-bottom: 16px;
`

const MoDialogStateTable = (
    {
        removeDamage,
        state,
        items
    }
) => {
    return <StyledDamagesByStateTable>
        <FlexBox>
            <DamageState status={state} />
        </FlexBox>
        <PushBottom8/>
        <MoDialogTable
            removeElement={removeDamage}
            tableConfig={tableConfiguration}
            elements={items}
        />
    </StyledDamagesByStateTable>  
}

MoDialogStateTable.propTypes = {
    removeDamage: PropTypes.func.isRequired,
    states: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired
}

export {MoDialogStateTable}