import React from 'react'
import { useTranslation } from 'react-i18next'
import { TASK_PRIORITIES } from 'application/constants'

export default ({priority}) => {
    const {t} = useTranslation()
    const mapPriorityName = (priority) => {
        const names = new Map([
            [TASK_PRIORITIES.LOW, 'Low priority'],
            [TASK_PRIORITIES.MEDIUM, 'Medium priority'],
            [TASK_PRIORITIES.HIGH, 'High priority']
        ])
        return names.get(priority)
    }

    return <React.Fragment>
        {t(mapPriorityName(priority))}
    </React.Fragment>
}