import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import TitleBar from './component'

const TitleBarEffects = (props) => {
    
    const { 
        onLoadAffiliates, 
        affiliatesEffectBlocked 
    } = props

    useEffect(() => {
        !affiliatesEffectBlocked && onLoadAffiliates()
    }, [affiliatesEffectBlocked, onLoadAffiliates])

    return <TitleBar {...props} />
}

export default withRouter(TitleBarEffects)