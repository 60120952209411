import { connect } from 'react-redux'
import AccountPreloader from './component'
import { requestCurrentAccountById } from 'application/redux/actions/auth'

const mapStateToProps = (state) => {
    return {
        currentAccount: state.auth.currentAccount,
        currentUser: state.auth.user,
        currentAccountLoaded: state.auth.currentAccountLoaded,
        currentAccountLoading: state.auth.currentAccountLoading,
        loggedIn: state.auth.loggedIn
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadAccount: accountId => dispatch(requestCurrentAccountById(accountId))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPreloader)