import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { TASK_STATES } from 'application/constants'
import { ButtonDescription } from 'application/components/fragments/typography'
import { LinkButton } from 'application/components/fragments/typography'

const TaskOperations = ({
    task,
    taskExternal = false,
    currentUser,
    onAssignTask,
    damageId,
    onTaskStateChange,
    onReopenTask
}) => {
    const { t } = useTranslation()

    const handleCloseTask = () => {
        onTaskStateChange(damageId, task.id, TASK_STATES.DONE)
    }

    const handleDoneTask = () => {
        onTaskStateChange(damageId, task.id, TASK_STATES.TO_REVIEW)
    }

    const handleReopenTask = () => {
        onReopenTask(damageId, task)
    }

    const handleAssignMe = () => {
        onAssignTask(damageId, task.id, currentUser.id, currentUser.firstName, currentUser.lastName)
    }

    const getOperations = (taskExternal, isAssigned) => {
        if(taskExternal && isAssigned && task.state !== TASK_STATES.DONE && task.state !== TASK_STATES.TO_REVIEW){
            return <React.Fragment>
                <ActionButton
                    buttonText={t('Done task')}
                    onButtonClick={handleDoneTask}
                />
            </React.Fragment>
        }

        switch(task.state) {
        case TASK_STATES.TO_DO:
            return !isAssigned 
                ? <React.Fragment>
                    <ButtonDescription>
                        {t('This task has not been assigned yet')} <LinkButton isActive={true} onClick={handleAssignMe}>{t('assign to me')}</LinkButton>
                    </ButtonDescription>
                    <ActionButton
                        buttonText={t(taskExternal ? 'Done task' : 'Close task')}
                        onButtonClick={()=>{}}
                        disabled={true}
                    />
                </React.Fragment>
                :  <ActionButton
                    buttonText={t('Close task')}
                    onButtonClick={handleCloseTask}
                />
            
        case TASK_STATES.TO_REVIEW:
            return <ButtonDescription>
                {t('Waiting action from lawyer')}
            </ButtonDescription>
        case TASK_STATES.DONE:
            return taskExternal ? <ButtonDescription>
                {t('Task is done by lawyer')}
            </ButtonDescription> : <ActionButton
                buttonText={t('Reopen task')}
                onButtonClick={handleReopenTask}
            />
        default: return <React.Fragment>

            <ActionButton
                buttonText={t('Close task')}
                onButtonClick={handleCloseTask}
            />

            
        </React.Fragment>
        }
    }
    return <React.Fragment>
        {
            getOperations(taskExternal, task.assignee !== undefined && task.assignee.userId !== null)
        }
    </React.Fragment>
}

export default TaskOperations