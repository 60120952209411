import React  from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import Preloader from 'application/components/controls/themed_preloader'
import { withCategoriesLoader } from 'application/components/higher_order_components'

const flattenCategories = (categories) => categories.reduce((cats, cat) => {
    cat.categories.forEach(subcat => {
        subcat.structure = cat.structure !== undefined ? `${cat.structure} → ${cat.name}` : cat.name
    })
    return cat.categories.length > 0 
        ? cats.concat(flattenCategories(cat.categories)) 
        : cats.concat(cat)
}, [])

const CategorySelect = ({
    name='category',
    register,
    setValue,
    menuPlacement='bottom',
    categories = [],
    categoriesLoaded,
    onChange = () => {},
    initialValue = null,
    label = 'Category',
    fixedDefaultCategory = false,
    disabled = false
}) => {
    const {t} = useTranslation()

    if(!categoriesLoaded){
        return <Preloader />
    }
    const flattenedCategoriesOptions = flattenCategories(categories).map(cat => ({
        value: cat.id,
        label: cat.structure !== undefined ? `${cat.structure} → ${cat.name}` : cat.name,
        category: cat
    }))

    const options = flattenedCategoriesOptions
    const initialOption = fixedDefaultCategory 
        ? options.find(o => o.category.isDefault)
        : initialValue === null 
            ? options[0] 
            : options.find(o => o.value === initialValue)
    
    return <Select 
        label={t(label)}
        name={name}
        register={register}
        setValue={setValue}
        options={options}
        defaultValue={initialOption}
        validate={null} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
        disabled={disabled}
    />
}

CategorySelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string,
    onChange: PropTypes.func,
    categories: PropTypes.array,
    categoriesLoaded: PropTypes.bool.isRequired
}

export default withCategoriesLoader(CategorySelect)