import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.pages.damageManager.documentCenter, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.damageManager.documentCenter
    }
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_GET_ALL_DOCUMENTS_REQUEST: {
        return {
            ...state,
            documents: [],
            documentsLoading: true,
            documentsLoaded: false,
            damageId: state.damageId
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_RESET: {
        return {
            ...state,
            documents: [],
            documentsLoading: false,
            documentsLoaded: false,
            damageId: state.damageId
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_POPULATE_DOCUMENTS: {
        return {
            ...state,
            documents: action.documents,
            documentsLoading: false,
            documentsLoaded: true,
            damageId: action.damageId
        }
    }   
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_SHOW_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                visible: true,
                attachment: action.attachment,
                damageId: action.damageId, 
            }
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_SHOW_ATTACHMENT_DETAILS: //hide details in document center if details in task view is shown
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_HIDE_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                visible: false,
                attachment: null,
                damageId: null
            }
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_UPDATE_ATTACHMENT_DETAILS: {
        return {
            ...state,
            attachmentDetails: {
                ...state.attachmentDetails,
                attachment: {
                    ...state.attachmentDetails.attachment,
                    ...action.payload
                }
            }
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_REMOVE_ATTACHMENT: {
        return {
            ...state,
            documents: state.documents.filter(d => d.id !== action.attachmentId)
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_DOCUMENTS_SET_ARCHIVE_DOWNLOADING: {
        return {
            ...state,
            archiveDownloading: action.archiveDownloading
        }
    }  
    case ACTIONS.DAMAGE_MANAGER_REQUEST_UPDATE_DOCUMENT: {
        return {
            ...state,
            documents: state.documents.map(document => 
                document.id === action.attachmentId
                    ? {
                        ...document,
                        ...action.payload
                    } 
                    : document
            )
        }
    }   
    
    default:
        return state
    }
}