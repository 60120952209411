import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import Preloader from 'application/components/controls/themed_preloader'
import DocumentsContent from './component'

const DocumentsEffects = (props) => {
    const { onLoadDocumentsWorkflowPanel, categoriesLoaded, documentsLoaded, loggedIn, match, onHideAttachmentDetails, categoriesLoading, onGetCategories } = props
    const { sharedResourceId } = match.params
    // const shouldLoad = !documentsLoading && sharedResourceId !== undefined


    useEffect(() => {
        loggedIn && onLoadDocumentsWorkflowPanel(sharedResourceId)
        loggedIn && onHideAttachmentDetails() //hide sidebar when new document center data is loaded
    }, [loggedIn, onHideAttachmentDetails, onLoadDocumentsWorkflowPanel, sharedResourceId])

    useEffect(() => {
        loggedIn && !categoriesLoaded && !categoriesLoading && onGetCategories(sharedResourceId)
    }, [loggedIn, categoriesLoaded, categoriesLoading, onGetCategories, sharedResourceId])

    if (categoriesLoaded && documentsLoaded) {
        return <DocumentsContent 
            {...props}
        />
    }

    return <Preloader />
}

export default withRouter(DocumentsEffects)