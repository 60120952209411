import React from 'react'
import {
    Switch,
    Route,
    withRouter
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { 
    PERMISSION_SCOPE, 
    ROUTES, USER_PERMISSONS } from 'application/constants'
import LayoutAllBranches from 'application/components/pages/_layout/layout_all_branches'
import AdministrationSettingsCategories from './categories'
// import AdministrationSettingsDamageStates from './damage_status'
import AdministrationSettingsAccountData from './account_data'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { RedirectStrategy } from './redirect_strategy'

const Administration = (
    { 
        match
    }
) => {
    const {t} = useTranslation()

    if (match.path === ROUTES.ADMINISTRATION_SETTINGS) {
        return <RedirectStrategy />
    }

    return <LayoutAllBranches 
        titleSegments={['Settings']}
        headlineGeneric={t('Administration')} 
        headlineSpecific={t('Settings')}
    >
        <TabsLinks
            items={[
                {
                    label: t('Account data'),
                    link: getAccountLinkHref(match, ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA),
                    isActive: match.path === ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA,
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    permissions: [
                        USER_PERMISSONS.ACCOUNT.UPDATE
                    ]
                },
                {
                    label: t('Document categories'),
                    link: getAccountLinkHref(match, ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES),
                    isActive: match.path === ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES,
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    permissions: [
                        USER_PERMISSONS.DOCUMENT_CATEGORY.CREATE,
                        USER_PERMISSONS.DOCUMENT_CATEGORY.UPDATE,
                        USER_PERMISSONS.DOCUMENT_CATEGORY.DELETE
                    ]
                },
                // {
                //     label: t('Damage state'),
                //     link: getAccountLinkHref(match, ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES),
                //     isActive: match.path === ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES,
                //     permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                //     permissions: [
                //         USER_PERMISSONS.DAMAGE_STATE.CREATE,
                //         USER_PERMISSONS.DAMAGE_STATE.UPDATE,
                //         USER_PERMISSONS.DAMAGE_STATE.DELETE
                //     ]
                // }
            ]}
        />

        <Switch>
            <Route path={ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA}>
                <ForAnyBranchPermissions
                    permissions={[
                        USER_PERMISSONS.ACCOUNT.UPDATE
                    ]}
                >
                    <AdministrationSettingsAccountData />
                </ForAnyBranchPermissions>
            </Route>
            <Route path={ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES}>
                
                <ForAnyBranchPermissions
                    permissions={[
                        USER_PERMISSONS.DOCUMENT_CATEGORY.CREATE,
                        USER_PERMISSONS.DOCUMENT_CATEGORY.UPDATE,
                        USER_PERMISSONS.DOCUMENT_CATEGORY.DELETE
                    ]}
                >
                    <AdministrationSettingsCategories />
                </ForAnyBranchPermissions>
            </Route>
            {/* <Route path={ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES}>
                <ForAnyBranchPermissions
                    permissions={[
                        USER_PERMISSONS.DAMAGE_STATE.CREATE,
                        USER_PERMISSONS.DAMAGE_STATE.UPDATE,
                        USER_PERMISSONS.DAMAGE_STATE.DELETE
                    ]}
                >
                    <AdministrationSettingsDamageStates />
                </ForAnyBranchPermissions>
            </Route> */}

        </Switch>
    </LayoutAllBranches>
}

export default withRouter(withTheme(Administration))