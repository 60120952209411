import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'
import { validationMethods } from 'application/common'

export const VehicleDataPartialForm = ({
    register,
    errors,
    disabled = false,
    onChange = () => {}
}) => {
    const {t} = useTranslation()
    return <React.Fragment>
        <FlexBox>
            <FlexBox flexBasis='160px' flexGrow='0' flexShrink='0'>
                <TextInput 
                    label={t('License plate')}
                    name='licensePlate'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.licensePlate}
                    onChange={onChange}
                    disabled={disabled}
                />
            </FlexBox>
            <FlexBox flexBasis='12px' flexGrow='0' flexShrink='0' />
            <FlexBox>
                <TextInput 
                    label={t('Vehicle brand')}
                    name='make'
                    register={register}
                    validate={null}
                    error={errors.make}
                    onChange={onChange}
                    disabled={disabled}
                />
                
            </FlexBox>
            <FlexBox flexBasis='12px' flexGrow='0' flexShrink='0' />
            <FlexBox>
                <TextInput 
                    label={t('Vehicle model')}
                    name='model'
                    register={register}
                    validate={null}
                    error={errors.model}
                    onChange={onChange}
                    disabled={disabled}
                />
            </FlexBox>
        </FlexBox>

    </React.Fragment>
}