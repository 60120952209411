import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import DamageTypeName from 'application/components/name_mappings/damage_type_name'
import { RightArrow } from 'application/components/fragments/typography'

export const DamageTypeChangedActivity = ({activity}) => {
    const {t} = useTranslation()

    return <React.Fragment>
        <Fragments.ActivityTitle>{t('Damage type changed')}</Fragments.ActivityTitle>
        <Fragments.ActivityParagraph>
            <DamageTypeName damageType={activity.data.oldType} /><RightArrow /><DamageTypeName damageType={activity.data.newType} />
        </Fragments.ActivityParagraph>
    </React.Fragment>
}