import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from '../fragments'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ThumbnailContainer } from 'application/components/building_blocks/attachments/thumbnail'
import { withRouter } from 'react-router-dom'

const InvoiceDocuments = withRouter(({ invoiceData, onDownloadAttachment, match }) => {
    const { t } = useTranslation()

    const handleDownloadAttachment = (attachment) => {
        onDownloadAttachment(attachment.id, attachment.fileName, match.params.damageId)
    }

    return <FlexBox flexDirection='column'>
        <Fragments.InvoiceInformation>
            <Fragments.InvoiceInformationLabel>
                {t('Documents')}
            </Fragments.InvoiceInformationLabel>
        </Fragments.InvoiceInformation>
        <FlexBox flexWrap='wrap'>
            {
                invoiceData.documents.map((attachment, fileIndex) => {
                    return <ThumbnailContainer
                        key={fileIndex}
                        attachmentId={attachment.id}
                        attachmentName={attachment.name}
                        damageId={attachment.damageId}
                        taskId={attachment.resourceId}
                        onAttachmentClick={handleDownloadAttachment}
                        extendedData={
                            {
                                category: attachment.category,
                                isExportedToLawFirm: attachment.isExportedToLawFirm
                            }
                        }
                    />
                })
            }

        </FlexBox>
    </FlexBox>
})

export default InvoiceDocuments