import { exists } from 'application/common/data_helpers'
import { useState } from 'react'

/**
 * hook wich takes care of Checkbox selection. Only one Checkbox could be selected!
 * it handles its selection by the ids of the objects. Ids are required as object propertys!
 *  
 * @param {[]} list array of objects. Objects need to have a property id!
 * @param {Object} defaultSelectedItem  Object with property id. This represents the default selection 
 * 
 * @function isChecked(id) - returns true or false if the given id is equal to selected id. 
 * @function handleCheckboxChange(event) - the given event.target by the Checkbox name property value is set as selected id
 * @function   getSelectedId() - returns the selected id
 * @function getSelectedObject() - returns the whole selected object
 * 
 * 
 * 
 * ---------------------------------------------
 * Implementation Example
 * ---------------------------------------------
 * 
 * required imports: 
 *  
 *  import CheckboxWithLabel from 'application/components/controls/form/checkbox_with_label'
 *  import { useRadioCheckBoxes } from 'application/components/hooks'
 * 
 *     const reasonsForReductionKeywords = [
 *      {
 *          id: '0',
 *          label: 'Stichwort 1'
 *      },
 *      {
            id: '1',
            label: 'Stichwort 2'
        },
        {
            id: '2',
            label: 'Stichwort 3'
        },
        {
            id: '3',
            label: 'sonstiges'
        },
    ]


    const {
        isChecked, 
        handleCheckboxChange, 
        getSelectedId
    } = useRadioCheckBoxes(reasonsForReductionKeywords)



    //JSX part:
                    reasonsForReductionKeywords.map(
                    (keyword, i) => {
                        return <CheckboxWithLabel
                            key={keyword.id}
                            name={keyword.id}
                            label={keyword.label}
                            isRequired={false}
                            isSelected={isChecked(keyword.id)}
                            onCheckboxChange={handleCheckboxChange} 
                        />
                    } 
                )
 * 
 * 
 * 
 */


const useRadioCheckBoxes = (list, defaultSelectedItem) => {

    const getInitialSelectedItemId = () => {
        return exists(defaultSelectedItem) 
            ? defaultSelectedItem.id
            : list[0].id    
    }

    const [selectedItemId, setSelectedItemId] = useState( getInitialSelectedItemId() )

    const handleCheckboxChange =  (event) =>  {
        const { name } = event.target 
        const id = name
        setSelectedItemId(id)      
    }

    const getSelectedId = () => {
        return selectedItemId
    }

    const isChecked = (id) => {
        return selectedItemId === id
    }

    const getSelectedObject = () => {
        return list.find(
            item => item.id === selectedItemId
        )
    }

    return {
        isChecked, 
        handleCheckboxChange, 
        getSelectedId,
        getSelectedObject
    }

}

export  { useRadioCheckBoxes }