import React from 'react'
import RealtimeNotifications from './component'

const RealtimeNotificationsEffects = (props) => {
    // const {         
    //     previousNotificationsLoaded,
    //     previousNotificationsLoading,
    //     onGetPreviousNotifications
    // } = props

    // useEffect(() => {
    //     !previousNotificationsLoaded && !previousNotificationsLoading && onGetPreviousNotifications()
    // }, [onGetPreviousNotifications, previousNotificationsLoaded, previousNotificationsLoading])

    return <RealtimeNotifications {...props} />
}

export default RealtimeNotificationsEffects