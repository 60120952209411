import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useState } from 'react'
import { BasicDamageComprehensiveInsuranceDataForm } from '../_partial_forms/basic_damage_comprehensive_insurance_data_form'
import {comprehensiveDataStructure} from 'application/components/forms/_configurations'



const DamageComprehensiveInsuranceDataForm = ( 
    {
        damageId, 
        onSave,
        stateFulFormHook,
        insuranceSelectionFromListInitiallySelected = true
    }
) => {
    const {t} = useTranslation()

    const {
        handleSubmit, 
        getValues, 
        reset,
        setFormStateValues
    } = stateFulFormHook

    const {
        emptyValues,
        mapFormValuesToComprehensivePayload
    } = comprehensiveDataStructure

    const [insuranceSelectionFromList, setInsuranceSelectionFromList] = useState(insuranceSelectionFromListInitiallySelected)

    const resetForm = () => {
        reset(emptyValues)
        setFormStateValues(emptyValues)
    }

    const onSubmitSuccess = () => { 
        const payload = mapFormValuesToComprehensivePayload(getValues())
        const metaData = {damageId}
        onSave(
            payload,
            metaData
        )
    }

    return <FlexBox 
        cssRules='min-height: 160px;' 
        flexDirection='column'
    >
        <BasicDamageComprehensiveInsuranceDataForm
            resetForm={resetForm}
            stateFulFormHook={stateFulFormHook}
            setInsuranceSelectionFromList={setInsuranceSelectionFromList}
            insuranceSelectionFromList={insuranceSelectionFromList}
        />
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save comprehensive data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>       
    </FlexBox>  
}

export { DamageComprehensiveInsuranceDataForm }