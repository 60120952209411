import React from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import {STEPS_BAR_STATE} from 'application/constants/steps_bar'



const StyledStepLabel = styled.div`
    color: ${props => props.color};
    padding-left: 8px;
    font-size: ${props => props.theme.fontSize.standard};
`



const MappedStepLabelComponent = (
    {
        state,
        labelText,
        theme
    }
) => {

    const colorMapping = (state) => {
        switch (state) {
        case STEPS_BAR_STATE.CURRENT: 
            return theme.color.primary
        case STEPS_BAR_STATE.UNDONE: 
        case STEPS_BAR_STATE.DONE: 
        default: 
            return theme.color.anthracite
        }
    }

    const color = colorMapping(state)

    return <StyledStepLabel color={color}>
        {labelText}
    </StyledStepLabel>

}

MappedStepLabelComponent.propTypes = {
    state: PropTypes.number,
    labelText: PropTypes.string,
    theme: PropTypes.object
}

export const MappedStepLabel = withTheme(MappedStepLabelComponent)
