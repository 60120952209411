import { connect } from 'react-redux'
import EditCaseNotesComponent from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'


const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onEditTask: (name, payload, damageId) => dispatch(sendPayloadRequest(name, payload, {damageId})),
    }
}

export const EditCaseNotes = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCaseNotesComponent)