import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { WIDGET_TYPES } from 'application/constants'
import WorkflowPanelInbox from './component'

const WorkflowPanelInboxEffects = (props) => {
    const { 
        onRefreshWidgetType, 
        match 
    } = props
    
    const { sharedResourceId } = match.params

    useEffect(() => {
        onRefreshWidgetType(WIDGET_TYPES.WORKFLOW_PANEL_INBOX_MESSAGE_LIST)
    }, [onRefreshWidgetType, sharedResourceId])

    return <WorkflowPanelInbox {...props} />
}

WorkflowPanelInboxEffects.propTypes = {
    onRefreshWidgetType: PropTypes.func.isRequired,
}

export default withRouter(WorkflowPanelInboxEffects)