import { connect } from 'react-redux'
import CreateBranch from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onAddBranch: (dialogName, payload) => {
            dispatch(sendPayloadRequest(dialogName, payload))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateBranch)