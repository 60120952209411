import React from 'react'
import styled, {keyframes, withTheme} from 'styled-components'
import PropTypes from 'prop-types'
import { FlexBox } from 'application/components/fragments/flex_box'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`


const StyledThemedPreloaderWrapper = styled.div`
    padding-top: ${props => props.paddingTop};
    display: flex;
    flex-direction: ${props => props.cssDirection};
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
`

const StyledThemedPreloader = styled.div`
    flex: 0 0 ${props => props.small ? '13px' : '26px'};
    display: flex;
    justify-content: center;
    margin: 4px;
`

const StyledThemedPreloaderInner = styled.div`
	display:block;
    height: ${props => props.small ? '13px' : '26px'};
    width: ${props => props.small ? '13px' : '26px'};
	background-color: ${props => props.backgroundColor};
	border-radius: 26px;
	box-shadow:inset 0 0 0 0.8em ${props => props.backgroundColor};
	background: linear-gradient(-45deg, ${props => props.primaryColor}, ${props => props.primaryColor} 50%, ${props => props.secondaryColor} 50%, ${props => props.secondaryColor});
	background-blend-mode: multiply;
	border-top:${props => props.small ? '3px' : '4px'} solid ${props => props.primaryColor};
	border-left:${props => props.small ? '3px' : '4px'} solid ${props => props.primaryColor};
	border-bottom:${props => props.small ? '3px' : '4px'} solid ${props => props.secondaryColor};
    border-right:${props => props.small ? '3px' : '4px'} solid ${props => props.secondaryColor};
    animation:${rotate} ${props => props.speed} infinite linear;
`

const StyledThemedPreloaderText = styled.div`
    text-align: center;
    color: ${props => props.preloaderTextColor};
    font-size: ${props => props.small ? props.theme.fontSize.small : props.theme.fontSize.standard};
`

const ThemedPreloader = (
    { 
        primaryColor = null, 
        paddingTop = 12, 
        height = 26,
        secondaryColor = null, 
        backgroundColor = null, 
        speed = '1.15s', 
        preloaderText = '',
        preloaderTextColor = null,
        small=false, 
        cssDirection='column',
        theme
    }
) => {

    primaryColor = primaryColor === null ? theme.color.primary : primaryColor
    secondaryColor = secondaryColor === null ? theme.color.gray15 : secondaryColor
    backgroundColor = backgroundColor === null ? theme.color.white : backgroundColor
    preloaderTextColor = preloaderTextColor === null ? theme.color.anthracite : preloaderTextColor

    const hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null
    }

    const hexToRgbString = (hex) => {
        const result = hexToRgb(hex)
        return `rgb(${result.r}, ${result.g}, ${result.b})`
    }

    return <FlexBox 
        flexBasis='100%' 
        justifyContent='center' 
        height={height + paddingTop + 'px'} 
        paddingTop={paddingTop + 'px'}
    >
        <StyledThemedPreloaderWrapper className='jest-themed-preloader' cssDirection={cssDirection}>
            <StyledThemedPreloader small={small}>
                <StyledThemedPreloaderInner small={small} primaryColor={hexToRgbString(primaryColor)} secondaryColor={hexToRgbString(secondaryColor)} backgroundColor={hexToRgbString(backgroundColor)} speed={speed} />
            </StyledThemedPreloader>
            {
                preloaderText !== null && <StyledThemedPreloaderText small={small}>{preloaderText}</StyledThemedPreloaderText>
            }
        </StyledThemedPreloaderWrapper>
    </FlexBox>
}

ThemedPreloader.propTypes = {
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    preloaderText: PropTypes.string,
    speed: PropTypes.string
}

export default withTheme(ThemedPreloader)