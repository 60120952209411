import { connect } from 'react-redux'

import { TabsLinks } from './tabs_links'

const mapStateToProps = state =>( {
    branchPermissions: state.auth.permissions,
    allPermissions: state.auth.allPermissions
})

export default connect(
    mapStateToProps
)(TabsLinks)