import React, {useRef} from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import RealtimeNotifications from 'application/components/building_blocks/realtime_notifications'
import { Headline3 } from 'application/components/fragments/typography'
import IconButton from 'application/components/controls/icon_button/component'
import { useClickOutside } from 'application/components/hooks'
import Link from 'application/components/controls/hyperlink'
import { ROUTES } from 'application/constants'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { withRouter } from 'react-router-dom'
import { FlexBox } from 'application/components/fragments/flex_box'

const StyledSidebar = styled.div`
    position: fixed;
    width: 540px;
    height: 100vh;
    background: ${props => props.theme.color.gray20};
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 8px;
    flex: 0 0 100vh;
    box-shadow: -4px 1px 16px 0px rgba(0,0,0,0.36);
`

export default withRouter(
    (
        {
            sidebarVisible, 
            onHideSidebar,
            match
        }
    ) => {
        const sidebarRef = useRef()
        useClickOutside(sidebarRef, onHideSidebar)

        const {t} = useTranslation()
        if(!sidebarVisible){
            return null
        }

        return <StyledSidebar ref={sidebarRef}>
            <FlexBox 
                flexShrink='0' 
                flexBasis='48px'
            >
                <Headline3 
                    cssMargin='0'>
                    {t('Last notifications')}
                    <IconButton 
                        hoverBackground='transparent' 
                        iconKey='close' 
                        onButtonClick={onHideSidebar} 
                    />
                </Headline3>
            </FlexBox>
            <RealtimeNotifications onLinkClick={onHideSidebar} />
            <Link 
                onClick={onHideSidebar} 
                to={getAccountLinkHref(match, ROUTES.ACTIVITY_LOG)}
            >
                {t('show all activities')}
            </Link>
        </StyledSidebar>
    }
)