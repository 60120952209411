import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { SETTINGS } from 'application/constants'
import { ToggleSwitchSettingCard } from 'application/components/controls/toggle_switch_setting_card/component'
import { useNotificationSettings } from './use_notification_settings'
import { Spacer } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'

const NotificationSettingsDialog = ( 
    {
        abort,
        setAbortBlocked,
        setDataChanged,
        dialog,
        onSaveNotificationSettings,
    }
) => {
    const {t} = useTranslation()
    const { payload } = dialog
    const { defaultSettings, userNotificationSettings, userId } = payload

    const {
        notificationSettings, 
        setNotificationSettingValue,
        notificationSettingsRequestValues
    } = useNotificationSettings(defaultSettings.notifications, userNotificationSettings, [SETTINGS.KEYS.NOTIFICATIONS_CHANNEL_TYPES.EMAIL, SETTINGS.KEYS.NOTIFICATIONS_CHANNEL_TYPES.REALTIME_NOTIFICATION])

    const handleSaveSettings = () => {
        onSaveNotificationSettings(
            dialog.name, 
            {
                notificationSettingsRequestValues
            }, 
            {
                userId
            }
        )
    }

    const handleChange = () => {
        setDataChanged(true)
        setAbortBlocked(true)
    }

    return <React.Fragment>
        <DialogMainContent flexDirection='row'>
            <FlexBox flexDirection='column'>

                <InfoBox direction='row' cssMinHeight='48px'>
                    <FontIcon icon='info_outline' />
                    <Spacer basis='10px' />
                    <FlexBox flexDirection='column'>
                        <span>
                            {t('Which notifications do you want to receive for damages you are assigned to')}?
                            <br />
                            {t('You have to configure these settings for every company you have access to separately')}.
                        </span>
                    </FlexBox>
                    <Spacer />
                </InfoBox>

                <Spacer basis='16px' />

                <FlexBox flexDirection='column'>
                    {
                        notificationSettings.map(setting => {
                            return <ToggleSwitchSettingCard
                                key={setting.type}
                                name={`${setting.name}Title`}
                                description={t(setting.description)}
                                onChange={(settingKey, settingValue) => {
                                    setNotificationSettingValue(setting.type, settingKey, settingValue)
                                    handleChange()
                                }}
                                switches={setting.valueDict}
                            />
                        })
                    }
                </FlexBox>

            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSaveSettings}
            submitText='Save'
        />
    </React.Fragment>
}

export default withDialogDataLoader(NotificationSettingsDialog)