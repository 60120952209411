import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import Collapsable from 'application/components/controls/collapsable'
import { Paragraph } from 'application/components/fragments/typography'
import CustomerDisplay from 'application/components/building_blocks/customer_display'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withRouter } from 'react-router-dom'

const TaskDamageUpdateCustomer = withRouter(
    (
        {
            widgetKey, 
            payload, 
            loadingFailed, 
            match, 
            onChangeCustomerData, 
            onSwitchCustomer
        }
    ) => {
        const {t} = useTranslation()
        const {customer} = payload
        const {damageId} = match.params

        if(loadingFailed){
            return <WidgetErrorMessage>{t('Customer data could not be loaded')}</WidgetErrorMessage>
        }
    
        return <FlexBox 
            flexDirection='column'
        >
            <Collapsable 
                headline={t('Customer')} 
                initiallyCollapsed={true}
            >
                <FlexBox 
                    flexBasis='auto' 
                    flexGrow='1' 
                    flexDirection='column'
                >
                    <Paragraph>
                        <CustomerDisplay customer={customer} />
                    </Paragraph>
                    <FlexBox  
                        flexBasis='auto' 
                        justifyContent='flex-end' 
                        alignItems='flex-end'
                    >
                        <ActionButton 
                            onButtonClick={()=>{onSwitchCustomer(damageId)}} 
                            buttonText={t('Switch customer')} 
                        />
                    &nbsp;
                        <ActionButton 
                            onButtonClick={()=>{onChangeCustomerData(customer)}} 
                            buttonText={t('Change customer data')} 
                        />
                    </FlexBox>
                </FlexBox>
            </Collapsable>
        </FlexBox>
    })

TaskDamageUpdateCustomer.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { TaskDamageUpdateCustomer }