import React from 'react'
import DamageManager from '../container'
import { WIDGET_CONTEXTS, ROUTES } from 'application/constants'
import { SubPageLayout } from 'application/components/pages/_common'
import WidgetArea from 'application/components/building_blocks/widget_area'
import { DamageManagerLawyerCommunicationSidebar } from './sidebar'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Redirect, withRouter } from 'react-router-dom'
import { getDamageManagerLinkHref } from 'application/common/route_helpers'



const DamageManagerLawyerKommunikation = (
    {
        subRoute,
        hasLawfirm,
        damageLoaded,
        match,
        
    }
) => {
    const shouldRedirect = damageLoaded && !hasLawfirm

    //redirect to overview to avoid unnessesary widget loading attempts    
    if(shouldRedirect) {
        return <Redirect to={getDamageManagerLinkHref(match, ROUTES.DAMAGE_MANAGER, match.params.damageId)} />
    }

    return <DamageManager
        titleSegments={['Invoices']}
        subRoute={subRoute}
        sidebar={<DamageManagerLawyerCommunicationSidebar/>}
    >
        <SubPageLayout 
            paddingLeft='16px' 
            paddingRight='16px'
        >
        
            {
                damageLoaded && <FlexBox>
                    <WidgetArea 
                        successively={false} 
                        widgetContext={WIDGET_CONTEXTS.DAMAGE_MANAGER_ANWALT_KOMMUNIKATION} 
                    />
                </FlexBox>
            }

        </SubPageLayout>
    </DamageManager>
}

export default withRouter(DamageManagerLawyerKommunikation)