import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.updateScheduler, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.updateScheduler
    }
    case ACTIONS.SCHEDULE_UPDATE:
        return {
            ...state,
            constraints: state.constraints.find(
                c => c.updateConstraint === action.updateConstraint
            )
                ? state.constraints.map(
                    c => c.updateConstraint === action.updateConstraint
                        ? {
                            ...c,
                            timeStamp: new Date().toUTCString()
                        }
                        : c
                )
                : [
                    ...state.constraints, 
                    {
                        updateConstraint: action.updateConstraint,
                        timeStamp: new Date().toUTCString()
                    }
                ]
        }
    default:
        return state
    }
}
