import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledImage = styled.img`
    width: auto;
    max-width: 90%;
`

const ImageViewerComponent = ({fileType, filePath, errorComponent = null}) => {
    return <StyledImage src={filePath} className='jestImageViewer' />
}

ImageViewerComponent.propTypes = {
    fileType: PropTypes.string.isRequired,
    filePath: PropTypes.string.isRequired,
    errorComponent: PropTypes.element,
}

export default ImageViewerComponent