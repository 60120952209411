import styled from 'styled-components'


export const StyledTaskHighlightBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.color.gray5};
  padding: 8px;
  min-height: 72px;
`

export const SelectStyling = styled.div`
    max-height: 35px;

    & div.reactSelect > div {
      background-color: ${props => props.theme.color.gray5};
      border: 1px solid transparent;
    
      &:hover{
        border: 1px solid ${props => props.theme.color.gray15}; 
        background: ${props => props.theme.color.white};
      }
    }
`

export const StyledAssignMeButton = styled.div`
  font-size: ${props => props.theme.fontSize.standard};
  margin-top: 4px;
  padding-left: 8px;
`