import React, {useEffect, useMemo, useState} from 'react'
import { ActionButton } from '@ec/ui-controls-react'
import { HeaderContainer, PreloaderContainer } from './fragments'
import { FormHeadline, Paragraph } from 'application/components/fragments/typography'
import { useQuery } from 'application/components/hooks/use_query'
import { ROUTE_PARAMETERS } from './route_parameters'
import TaskUploaderComponent from 'application/components/building_blocks/task_uploader'
import { FlexBox } from 'application/components/fragments/flex_box'
import Preloader from 'application/components/controls/themed_preloader'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { AnimatedCheckmark } from './animated_checkmark'
import ActionLink from 'application/components/controls/action_link'



export const ExtCreateDamageSuccess= (
    {
        onSendDocuments, 
        damageId, 
        documentsSent, 
        documentsSending
    }
) => {
    const [files, setFiles] = useState([])
    const query = useQuery()
    const companyName = query.getStringParam(ROUTE_PARAMETERS.ACCOUNT_NAME_PARAM)
    const categoryId = query.getStringParam(ROUTE_PARAMETERS.CATEGORY_ID_PARAM)
    const [documentsSentState, setDocumentsSentState] = useState(documentsSent)

    useEffect(()=>{
        setDocumentsSentState(documentsSent)
    }, [documentsSent])


    const handleSendDocuments = () => {
    	onSendDocuments(damageId, files, categoryId)
    }

    const trackingUrl = useMemo(()=>{
        return `https://next360.esy.one/external/tracking/ZFUADEJVCYG5O5GIO4OQUPY5OZH6UCX2CEQKLJ62PHYB2PBNXMTA?fn=Mario&ln=Schwarz&c=${encodeURI(companyName)}`
    }, [companyName])

    return <HeaderContainer>
        <AnimatedCheckmark />
        <FormHeadline>
                        Schadenmeldung gesendet
        </FormHeadline>

        {
            !documentsSentState && <Paragraph cssAlign='center'>
                Um die Schadenabwicklung zu beschleunigen, können Sie zusätzlich noch Fotos des Schadens an das Autohaus senden.
            </Paragraph>
        }

        {
            documentsSentState 
                ? <React.Fragment>
                    <PushBottom16 />
                    {
                        files.length > 0 && <React.Fragment>
                            <AnimatedCheckmark />
                            <FormHeadline>
                                Fotos gesendet
                            </FormHeadline>
                            <PushBottom16 />
                        </React.Fragment>
                    }
                    
                    <Paragraph cssAlign='center'>
                        Ein Sachbearbeiter von {companyName} setzt sich in Kürze mit Ihnen in Verbindung.
                    </Paragraph>
                    <PushBottom16 />
                    <Paragraph>
                        <ActionLink
                            strechted={true}
                            linkText='Zur Schadennachverfolgung'
                            to={trackingUrl}
                        />
                    </Paragraph>
                </React.Fragment>
                : <React.Fragment>
                    {
                        documentsSending
                            ? <FlexBox><PreloaderContainer><Preloader preloaderText='Sende Fotos' /></PreloaderContainer></FlexBox>
                            : <React.Fragment>
                                <FlexBox>
                                    <TaskUploaderComponent
                                        title='Fotos'
                                        description=''
                                        label='Foto machen'
                                        labelMore='Weitere Fotos hinzufügen'
                                        onFileAdded={() => { }}
                                        files={files}
                                        setFiles={setFiles}
                                    />
                                </FlexBox>
                                <FlexBox justifyContent='flex-end' cssRules='margin-top: 20px; margin-bottom: 60px;'>
                                    <ActionButton
                                        buttonText='Uberspringen'
                                        onButtonClick={()=>{setDocumentsSentState(true)}}
                                        secondary={true}
                                    />
                                    <Spacer basis='8px' />
                                    <ActionButton
                                        buttonText='Fotos senden'
                                        onButtonClick={handleSendDocuments}
                                        disabled={files.length === 0}
                                    />
                                </FlexBox>
                            </React.Fragment>
                    }
                    
                </React.Fragment>
        }
        
    </HeaderContainer>
}