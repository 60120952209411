import React, {useEffect} from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { requestCurrentAccountById } from 'application/redux/actions/auth'
import ForLoggedInUser from './container'
import { ROUTES } from 'application/constants'

const mapStateToProps = state => ({
    currentAccount: state.auth.currentAccount,
    loggedIn: state.auth.loggedIn,
    sessionExpired: state.auth.sessionExpired
})
const mapDispatchToProps = dispatch => ({ 
    onLoadAccount: (id) => dispatch(requestCurrentAccountById(id)),
})


const withLoggedInUserComponent = Component => ({ ...props }) => {
    const {onLoadAccount, match, sessionExpired} = props
    const shouldLoadAccount = match.params.accountId !== undefined && (props.currentAccount === null || props.currentAccount.id !== match.params.accountId)

    useEffect(()=>{
        !sessionExpired && shouldLoadAccount && onLoadAccount(match.params.accountId)
    },[match.params.accountId, onLoadAccount, sessionExpired, shouldLoadAccount])

    if(sessionExpired){
        return <Redirect to={ROUTES.SESSION_EXPIRED} />
    }
    
    return <ForLoggedInUser>
        <Component {...props} />
    </ForLoggedInUser>

}

export const withLoggedInUser = compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    compose(
        withRouter,
        withLoggedInUserComponent
    )
)