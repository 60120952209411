import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { validationMethods } from 'application/common'
import { PhoneNumberInput, TextInput } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PushBottom24 } from 'application/components/pages/_common'
import ThemedPreloader from 'application/components/controls/themed_preloader'
import { GeoCodingControl } from 'application/components/controls/geocoding_control'
import { useGeoDataSubmit} from 'application/components/hooks'
import { useStatefulForm } from 'application/components/hooks'
import { AddressDataPartialForm } from '../_partial_forms'
import TwoColumns from '../_common'

const EditAccountForm = (
    {
        name ='',
        address= null, 
        onSave,
        markerMoved = false,
        contact = null
    }
) => {
    const {t} = useTranslation()
    
    const defaultValues = {
        name,
        line1: address?.line1 || '',
        postalCode: address?.postalCode || '',
        locality: address?.locality || '',
        latitude: address?.coordinates?.latitude || '',
        longitude: address?.coordinates?.longitude || '',
        contact: {
            email: contact?.email || '',
            phoneNumber: contact?.phoneNumber || ''
        }
    }

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues,
        setValue,
        formStateValues, 
        updateFormState,
        control
    } = useStatefulForm({defaultValues})

    const handleSubmitWithCoordinates = (
        lat, 
        lon
    ) => {
        onSave(
            {
                latitude: lat,
                longitude: lon,
                ...getValues()
            }
        )
    }   

    const {
        onSubmitSuccess,
        submitDisabled,
        useGeoCoding,
        setUseGeoCoding
    } =  useGeoDataSubmit(
        formStateValues,
        setValue,
        handleSubmitWithCoordinates,
        !markerMoved 
    )

    const onChange = () =>{
        updateFormState({
            line1: getValues().line1,
            postalCode: getValues().postalCode,
            locality: getValues().locality
        })
    }

    return <FlexBox 
        flexDirection='column'
        alignItems='flext-start'
    >
        <FlexBox   
            flexDirection='column'
        >
            <TextInput 
                label={t('Company name')}
                name='name'
                register={register}
                validate={{notEmpty: validationMethods.notEmpty}}
                error={errors.name}
            />

            <TwoColumns
                columnLeft={ 
                    <TextInput
                        label={t('Email')}
                        name='contact.email'
                        register={register}
                        validate={{
                            mustBeEmail: validationMethods.mustBeEmail
                        }}
                        error={errors.contact?.email}
                        onChange={onChange}
                    />
                }
                columnRight={
                    <PhoneNumberInput
                        defaultValue={contact.phoneNumber}
                        validate={null}
                        control={control}
                        label={t('Phone number (mobile or landline)')}
                        name='contact.phoneNumber'
                        register={register}
                        error={errors.contact?.phoneNumber}
                        onChange={onChange}
                        setValue={setValue}
                        allowLandLine={true}
                    />
                }
            />

            <PushBottom24 />

            <AddressDataPartialForm 
                register={register}
                errors={errors}
                onChange={onChange}
                nameCity='locality'
                namePostalCode='postalCode'
                nameStreet='line1'
            />

            <PushBottom24 />
            <GeoCodingControl
                register={register}
                setValue={setValue}
                useGeoCoding={useGeoCoding}
                setUseGeoCoding={setUseGeoCoding}
                errors={errors}
                defaultLatitude={defaultValues.latitude}
                defaultLongitude={defaultValues.longitude}
                latitude={formStateValues?.latitude}
                longtitude={formStateValues?.longitude}
                markerMoved={markerMoved}
            /> 
            <FlexBox justifyContent='space-between'>
                <FlexBox flexBasis='100px' flexGrow='0' alignItems='center'>
                    {
                        submitDisabled && <ThemedPreloader 
                            small={true} 
                            preloaderText={t('geocoding...' )}
                            cssDirection='row' 
                            paddingTop={0} 
                        />
                    }
                </FlexBox>
                <ActionButton 
                    buttonText={t('Save account data')} 
                    onButtonClick={handleSubmit(onSubmitSuccess)}
                    disabled={submitDisabled}
                />
            </FlexBox>
        </FlexBox>
    </FlexBox>
}

export { EditAccountForm }