import { exists } from 'application/common/data_helpers'


//POST
export const branchPOSTMapper = branchData => {
    return {
        name: branchData.name
    }
}

//Adress
export const branchAddressDataMapper = branchData => {
    const baseAddress = {
        line1: branchData.line1,
        postalCode: branchData.postalCode,
        locality: branchData.locality
    }
    const hasCoordinates = exists(branchData.latitude) 
                        && exists(branchData.longitude)

    return hasCoordinates 
        ?   {
            ...baseAddress,
            coordinates: {
                latitude: parseFloat(branchData.latitude),
                longitude: parseFloat(branchData.longitude)
            }
        }
        : {
            ...baseAddress,
            coordinates: null
        }

}

//Contact data
export const branchContactDataMapper = contactData => {
    if(!exists(contactData.email) && !exists(branchAddressDataMapper.phoneNumber)){
        return {}
    }

    if(!exists(contactData.email)){
        return {
            phoneNumber: contactData.phoneNumber
        }
    }

    if(!exists(contactData.phoneNumber)){
        return {
            email: contactData.email
        }
    }

    return {
        ...contactData
    }
}




export const branchToFormDataMapper = (branchObject = null) => {

    const contact = {
        email: branchObject?.contact?.email || '',
        phoneNumber: branchObject?.contact?.phoneNumber || ''
    }

    return  {
        name: branchObject?.name || '',
        line1: branchObject?.address?.line1  || '',
        postalCode: branchObject?.address?.postalCode || '',
        locality: branchObject?.address?.locality || '',
        latitude: branchObject?.address?.coordinates?.latitude || '',
        longitude: branchObject?.address?.coordinates?.longitude || '',
        contact
    }
}


//edit
export const branchDataMapper = branchData => {
    return {
        name: branchData.name,
        id: branchData.id,
        contact: branchData.contact,
        address: branchAddressDataMapper(branchData)
    }
}