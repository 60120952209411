import { connect } from 'react-redux'
import DamagesOverview from './component'
import { withLoggedInUser } from 'application/components/context/logged_in'

const mapStateToProps = (state) => {
    return {
        currentBranch: state.auth.currentBranch
    }
}

export default connect(
    mapStateToProps
)(withLoggedInUser(DamagesOverview))