import { useEffect } from 'react'
import { defaultUserManager } from 'application/common/user_manager'
import { ROUTES } from 'application/constants'

export const Load = () => {

    useEffect(()=>{
        defaultUserManager.signinRedirect({state: {returnUrl: ROUTES.PREFERRED_ACCOUNT_PRELOADER}})
    })

    return null
}