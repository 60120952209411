import React from 'react'
import styled from 'styled-components'
import ToggleSwitch from 'application/components/controls/toggle-switch'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Spacer } from 'application/components/pages/_common'
import { useTranslation } from 'react-i18next'

export const StyledSettingsCard = styled.div`
    padding: 4px 8px;
    background-color: ${props => props.theme.color.gray15};
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const SettingName = styled.div`
    font-size: ${props => props.theme.fontSize.standard};
    /*margin-bottom: 8px; not needed without description */
`

const SettingDescription = styled.div`
    font-size: ${props => props.theme.fontSize.small};
`

export const ToggleSwitchSettingCard = (
    {
        name,
        description,
        onChange,
        switches = []
    }
) => {
    const {t} = useTranslation()
    
    return <StyledSettingsCard>
        <FlexBox flexDirection='column'>
            <SettingName>
                {t(name)}
            </SettingName>
            <SettingDescription>
                {t(description)}
            </SettingDescription>
        </FlexBox>
        <FlexBox justifyContent='flex-end'>
            {
                switches.map((s, i) => {
                    return <React.Fragment key={i}>
                        <Spacer basis='8px' />
                        <ToggleSwitch
                            label={t(`${s.key}SettingTitle`)}
                            initiallyActive={s.value}
                            onChange={(value) => {
                                onChange(s.key, value)
                            }}
                        />
                    </React.Fragment>
                })
            }

        </FlexBox>
    </StyledSettingsCard>
}