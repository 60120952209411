import styled from 'styled-components'

export const StyledDashboardConfigurationOption = styled.div`
    flex: 0 0 198px;
    height: 198px;
    margin-right: 20px;
    background-color: ${props => props.isActive ? props.theme.color.primaryBackground : props.theme.color.gray5};
    border: 1px solid ${props => props.isActive ? props.theme.color.primary : props.theme.color.gray15};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & span{
        margin-top: 20px;
        color: ${props => props.theme.color.anthracite};
    }
`