import { exists } from 'application/common/data_helpers'
import React from 'react'
import { PageHeadline, HeadlineGeneric } from './fragments'

const PageHeadlineComponent = (
    {
        headlineGeneric,
        headlineGenericColor,
        headlineSpecific,
        divider='|',
        children
    }
) => {
    return <PageHeadline>
        <HeadlineGeneric $color={headlineGenericColor}>{headlineGeneric}</HeadlineGeneric>{exists(headlineSpecific) ? <React.Fragment>&nbsp;{divider}&nbsp;{headlineSpecific}</React.Fragment> : null}
        {children}
    </PageHeadline> 
}

export default PageHeadlineComponent    