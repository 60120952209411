import { ACTIONS } from 'application/constants'

export const registerContext = (contextKey, widgetList = []) => ({
    type: ACTIONS.WIDGETCONTEXT_REGISTER_CONTEXT,
    contextKey,
    widgetList
})

export const unregisterContext = (contextKey) => ({
    type: ACTIONS.WIDGETCONTEXT_UNREGISTER_CONTEXT,
    contextKey
})

export const saveWidgetLayout = (contextKey, widgetLayout) => ({
    type: ACTIONS.WIDGETCONTEXT_SAVE_WIDGET_LAYOUT, 
    contextKey,
    widgetLayout
})

export const saveWidgetList = (contextKey, widgetList) => ({
    type: ACTIONS.WIDGETCONTEXT_SAVE_WIDGET_LIST, 
    contextKey,
    widgetList
})

export const saveWidgetBoardConfig = (contextKey, boardConfiguration) => ({
    type: ACTIONS.WIDGETCONTEXT_SAVE_WIDGET_BOARD_CONFIG, 
    contextKey,
    boardConfiguration
})

export const updateWidgetContext = (contextKey, widgetLayout, widgetList, boardConfiguration) => ({
    type: ACTIONS.WIDGETCONTEXT_UPDATE_CONTEXT,
    contextKey, 
    widgetLayout, 
    widgetList, 
    boardConfiguration
})

export const setStaticMode = (contextKey, isStatic) => ({
    type: ACTIONS.WIDGETCONTEXT_SET_STATIC_MODE,
    contextKey,
    isStatic
})

export const addWidget = (contextKey, widgetKey, widgetType, permissions, title, initialPayload) => ({
    type: ACTIONS.WIDGETCONTEXT_ADD_WIDGET,
    contextKey,
    widgetKey,
    widgetType,
    permissions,
    title,
    initialPayload
})

export const removeWidget = (contextKey, key) => ({
    type: ACTIONS.WIDGETCONTEXT_REMOVE_WIDGET,
    contextKey,
    key
})

export const resetWidgetLayout = (contextKey) => ({
    type: ACTIONS.WIDGETCONTEXT_RESET_LAYOUT,
    contextKey  
})