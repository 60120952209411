import { refreshWidgetsByType } from 'application/redux/actions/widgets'
import { connect } from 'react-redux'
import WorkflowPanelInboxEffects from './effects'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onRefreshWidgetType: (type) => {
            dispatch(refreshWidgetsByType(type))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkflowPanelInboxEffects)