import React from 'react'
import { StyledActivityLink } from 'application/components/controls/activity_links/common'
import { useTranslation } from 'react-i18next'
import { ROUTES, USER_PERMISSONS } from 'application/constants'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { withRouter } from 'react-router-dom'

export const BranchActivityLinks = withRouter((
    {
        match
    }
) => {
    const {t} = useTranslation()
    const link = getAccountLinkHref(match, ROUTES.BRANCH_MANAGEMENT)

    return <ForAnyBranchPermissions permissions={[USER_PERMISSONS.BRANCH.CREATE]}>
        <StyledActivityLink to={link}>
            &raquo; {t('View branches')}
        </StyledActivityLink>
    </ForAnyBranchPermissions>
})