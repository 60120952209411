import React from 'react'
import {
    // Switch,
    // Route,
    withRouter,
    Redirect
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import { TabsLinks } from 'application/components/building_blocks/tabs'
import { ROUTES } from 'application/constants'
import { LayoutCurrentBranch } from 'application/components/pages/_layout'
// import ControllingStatistics from './statistics'
import ControllingInvoices from './invoices'
import { getBranchLinkHref } from 'application/common/route_helpers'
// import { PERMISSION_SCOPE } from 'application/constants/permissions'
import { USER_PERMISSONS } from 'application/constants'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'


const Controlling = (
    {
        match, 
        currentBranch
    }
) => {
    const {t} = useTranslation()

    const currentBranchId = currentBranch !== null ? currentBranch.id : null

    if(currentBranchId === null){
        return null
    }

    if (match.path === ROUTES.BRANCH_SPECIFIC_CONTROLLING) {
        return <Redirect to={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES, currentBranchId)} />
    }

    return <LayoutCurrentBranch
        titleSegments={['Controlling']}
        headline={t('Controlling')}
    >

        <ForCurrentBranchPermissions
            permissions={[USER_PERMISSONS.CONTROLLING.READ]} 
            allNeeded={false}
        >
            <ControllingInvoices />
        </ForCurrentBranchPermissions>

        {/* <TabsLinks
            items={[
                {
                    label: t('Invoices'),
                    link: getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES, currentBranchId),
                    isActive: match.path === ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES,
                    permissions: [USER_PERMISSONS.CONTROLLING.READ],
                    permissionScope: PERMISSION_SCOPE.CURRENT_BRANCH,
                    allNeeded: true
                },
                {
                    label: t('Created damages'),
                    link: getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS, currentBranchId),
                    isActive: match.path === ROUTES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS,
                    permissions: [ USER_PERMISSONS.CONTROLLING.READ],
                    permissionScope: PERMISSION_SCOPE.CURRENT_BRANCH,
                    allNeeded: true
                }
            ]}
        />
        <Switch>
            <Route path={ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES}>
                <ForCurrentBranchPermissions
                    permissions={[USER_PERMISSONS.CONTROLLING.READ]} 
                    allNeeded={false}
                >
                    <ControllingInvoices />
                </ForCurrentBranchPermissions>
            </Route>
            <Route path={ROUTES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS}>
                <ForCurrentBranchPermissions
                    permissions={[USER_PERMISSONS.CONTROLLING.READ]} 
                    allNeeded={false}
                >
                    <ControllingStatistics />
                </ForCurrentBranchPermissions>
            </Route>
        </Switch> */}
    </LayoutCurrentBranch>
}

export default withRouter(Controlling)