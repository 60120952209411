import { connect } from 'react-redux'

import TableMyDamagesOverview from './component'

const mapStateToProps = state => {
    return {
        currentUserId: state.auth.user.id
    }
}

export default connect(
    mapStateToProps
)(TableMyDamagesOverview)