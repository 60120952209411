import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { ROUTE_PARAMETERS, SHARED_RESOURCE_TYPE } from 'application/constants'

import { withSharedResourcePinValidation } from 'application/components/higher_order_components'
import ActivityLog from 'application/components/pages/external/_common/activity_log'
import { ExtCreateDamageHeader } from '../../create_damage/sub_components/header'
import { FormHeadline } from 'application/components/fragments/typography'
import { PushBottom32 } from 'application/components/pages/_common'
import { ActionButton } from '@ec/ui-controls-react'
import Dialogs from 'application/components/building_blocks/dialog/xdialogs'

const DamageTracking = (
    { 
        match, 
        onLoad,
        sendMessage
    }
) => {

    useEffect(() => {
        onLoad()
    }, [match.params.sharedResourceId, onLoad])

    return <React.Fragment>
        <ExtCreateDamageHeader>
            <PushBottom32 />
            <ActionButton
                buttonText='Nachricht an Autohaus'
                onButtonClick={sendMessage}
            />
            <PushBottom32 />
            <FormHeadline>Aktivitäten</FormHeadline>
            <ActivityLog sortDirection={ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING} />
        </ExtCreateDamageHeader>
        <Dialogs />
    </React.Fragment>
}

export default withSharedResourcePinValidation(SHARED_RESOURCE_TYPE.DAMAGE_TRACKING)(withRouter(DamageTracking))