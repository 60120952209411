import { WIDGET_TYPES } from 'application/constants'

const KEYS = {
    WORKFLOW_PANEL_FORM_INVOICES: 'WORKFLOW_PANEL_FORM_INVOICES'
}

export const widgetsWorkflowPanelFormSidebar = [
    {
        key: KEYS.WORKFLOW_PANEL_FORM_INVOICES,
        type: WIDGET_TYPES.WORKFLOW_PANEL_FORM_INVOICES,
        title: 'Invoices',
        hideHeadline: true
    }
]