//redux action constants
export const ACTIONS = {
    //sidebars.all
    SIDEBAR_CLOSE_ALL_SIDEBARS: 'SIDEBAR_CLOSE_ALL_SIDEBARS',

    //auth reducer
    AUTH_LOGOUT_REQUEST: 'AUTH_LOGOUT_REQUEST',
    AUTH_WRITE_APPLICATION_TOKEN: 'AUTH_WRITE_APPLICATION_TOKEN',
    AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    AUTH_RESET_LOGIN: 'AUTH_RESET_LOGIN',
    AUTH_SET_RETURN_URL: 'AUTH_SET_RETURN_URL',
    AUTH_USERDATA_REQUEST: 'AUTH_USERDATA_REQUEST',
    AUTH_POPULATE_PERMISSIONS: 'AUTH_POPULATE_PERMISSIONS',
    AUTH_POPULATE_CURRENT_BRANCH_PERMISSIONS: 'AUTH_POPULATE_CURRENT_BRANCH_PERMISSIONS',
    AUTH_GET_USER_DATA_REQUEST: 'AUTH_GET_USER_DATA_REQUEST',
    AUTH_RESET_CURRENT_USER: 'AUTH_RESET_CURRENT_USER',
    AUTH_POPULATE_USER_DATA: 'AUTH_POPULATE_USER_DATA',
    AUTH_POPULATE_ACCOUNTS: 'AUTH_POPULATE_ACCOUNTS',
    AUTH_POPULATE_ACCOUNTS_TOTAL: 'AUTH_POPULATE_ACCOUNTS_TOTAL',
    AUTH_SET_CURRENT_ACCOUNT_LOADED_STATE: 'AUTH_SET_CURRENT_ACCOUNT_LOADED_STATE',
    AUTH_SET_CURRENT_ACCOUNT_ID: 'AUTH_SET_CURRENT_ACCOUNT_ID',
    AUTH_POPULATE_CURRENT_ACCOUNT: 'AUTH_POPULATE_CURRENT_ACCOUNT',
    AUTH_UPDATE_CURRENT_ACCOUNT_NAME: 'AUTH_UPDATE_CURRENT_ACCOUNT_NAME',
    AUTH_UPDATE_CURRENT_ACCOUNT_ADDRESS: 'AUTH_UPDATE_CURRENT_ACCOUNT_ADDRESS',
    AUTH_REQUEST_CURRENT_ACCOUNT_BY_ID: 'AUTH_REQUEST_CURRENT_ACCOUNT_BY_ID',
    AUTH_REQUEST_ACCOUNTS: 'AUTH_REQUEST_ACCOUNTS',
    AUTH_POPULATE_DEFAULT_BRANCH_INFO: 'AUTH_POPULATE_DEFAULT_BRANCH_INFO',
    AUTH_RESET_CURRENT_BRANCH: 'AUTH_RESET_CURRENT_BRANCH',
    AUTH_REQUEST_CURRENT_BRANCH_CHANGE: 'AUTH_REQUEST_CURRENT_BRANCH_CHANGE',
    AUTH_POPULATE_CURRENT_BRANCH: 'AUTH_POPULATE_CURRENT_BRANCH',
    AUTH_NO_PERMISSIONS_FOUND: 'AUTH_NO_PERMISSIONS_FOUND',
    AUTH_PERMISSIONS_FOUND: 'AUTH_PERMISSIONS_FOUND',
    AUTH_NO_ACCOUNTS_FOUND: 'AUTH_NO_ACCOUNTS_FOUND',
    AUTH_ACCOUNTS_FOUND: 'AUTH_ACCOUNTS_FOUND',
    AUTH_SESSION_EXPIRED: 'AUTH_SESSION_EXPIRED',
    AUTH_REQUEST_PREFERRED_ACOUNT: 'AUTH_REQUEST_PREFERRED_ACOUNT',
    AUTH_POPULATE_PREFERRED_ACOUNT: 'AUTH_POPULATE_PREFERRED_ACOUNT',
    //preferred account
    AUTH_PREFERRED_ACOUNT_NOT_FOUND: 'AUTH_PREFERRED_ACOUNT_NOT_FOUND',
    AUTH_PREFERRED_ACOUNT_FOUND: 'AUTH_PREFERRED_ACOUNT_FOUND',
    AUTH_PREFERRED_ACOUNT_SET_ACCOUNT_AS_PREFERRED: 'AUTH_PREFERRED_ACOUNT_SET_ACCOUNT_AS_PREFERRED',
    AUTH_PREFERRED_ACOUNT_UNSET_ACCOUNT_AS_PREFERRED: 'AUTH_PREFERRED_ACOUNT_UNSET_ACCOUNT_AS_PREFERRED',
    AUTH_PREFERRED_ACOUNT_REFRESH_PREFERRED_ACCOUNT: 'AUTH_PREFERRED_ACOUNT_REFRESH_PREFERRED_ACCOUNT',
    
    //oidc
    OIDC_USER_EXPIRED: 'redux-oidc/USER_EXPIRED',

    //notification
    NOTIFICATIONS_SHOW_TOAST: 'NOTIFICATIONS_SHOW_TOAST',

    //dialogs
    DIALOG_OPEN_DIALOG_BY_NAME: 'DIALOG_OPEN_DIALOG_BY_NAME',
    DIALOG_CLOSE_DIALOG_BY_NAME: 'DIALOG_CLOSE_DIALOG_BY_NAME',
    DIALOG_CLOSE_ALL: 'DIALOG_CLOSE_ALL',
    DIALOG_POPULATE_DIALOG_CONTENT_BY_NAME: 'DIALOG_POPULATE_DIALOG_CONTENT_BY_NAME',
    DIALOG_SUCCEED_LOADED: 'DIALOG_SUCCEED_LOADED',
    DIALOG_UPDATE_DIALOG_DATA_BY_NAME: 'DIALOG_UPDATE_DIALOG_DATA_BY_NAME',
    DIALOG_SEND_DIALOG_DATA_BY_NAME: 'DIALOG_SEND_DIALOG_DATA_BY_NAME',
    DIALOG_REQUEST_DIALOG_DATA: 'DIALOG_REQUEST_DIALOG_DATA',
    DIALOG_SWITCH_DIALOG: 'DIALOG_SWITCH_DIALOG',
    DIALOG_STEPS_LAYOUT_WAITING_SCREEN_SHOW: 'DIALOG_STEPS_LAYOUT_WAITING_SCREEN_SHOW',
    DIALOG_STEPS_LAYOUT_WAITING_SCREEN_HIDE: 'DIALOG_STEPS_LAYOUT_WAITING_SCREEN_HIDE',

    //title bar
    TITLE_BAR_GET_AFFILIATES_REQUEST: 'TITLE_BAR_GET_AFFILIATES_REQUEST',
    TITLE_BAR_POPULATE_AFFILIATES: 'TITLE_BAR_POPULATE_AFFILIATES',

    //widgets
    WIDGET_REGISTER_WIDGET: 'WIDGET_REGISTER_WIDGET',
    WIDGET_UNREGISTER_WIDGET: 'WIDGET_UNREGISTER_WIDGET',
    WIDGET_UNREGISTER_ALL_WIDGETS: 'WIDGET_UNREGISTER_ALL_WIDGETS',
    WIDGET_REQUEST_DATA: 'WIDGET_REQUEST_DATA',
    WIDGET_GET_CONTENT_REQUEST: 'WIDGET_GET_CONTENT_REQUEST',
    WIDGET_UPDATE_CONTENT_BY_KEY: 'WIDGET_UPDATE_CONTENT_BY_KEY',
    WIDGET_CLEAR_CONTENT_BY_KEY: 'WIDGET_CLEAR_CONTENT_BY_KEY',
    WIDGET_UPDATE_LOADED_STATE: 'WIDGET_UPDATE_LOADED_STATE',
    WIDGET_REFRESH_WIDGET_BY_KEY: 'WIDGET_REFRESH_WIDGET_BY_KEY',
    WIDGET_REFRESH_WIDGETS_BY_TYPE: 'WIDGET_REFRESH_WIDGETS_BY_TYPE',
    WIDGET_REFRESH_WIDGETS_BY_CONTEXT: 'WIDGET_REFRESH_WIDGETS_BY_CONTEXT',

    //widget contexts
    WIDGETCONTEXT_ADD_WIDGET: 'WIDGETCONTEXT_ADD_WIDGET',
    WIDGETCONTEXT_SAVE_WIDGET_LAYOUT: 'WIDGETCONTEXT_SAVE_WIDGET_LAYOUT',
    WIDGETCONTEXT_SAVE_WIDGET_LIST: 'WIDGETCONTEXT_SAVE_WIDGET_LIST',
    WIDGETCONTEXT_SAVE_WIDGET_BOARD_CONFIG: 'WIDGETCONTEXT_SAVE_WIDGET_BOARD_CONFIG',
    WIDGETCONTEXT_SET_STATIC_MODE: 'WIDGETCONTEXT_SET_STATIC_MODE',
    WIDGETCONTEXT_REMOVE_WIDGET: 'WIDGETCONTEXT_REMOVE_WIDGET',
    WIDGETCONTEXT_REGISTER_CONTEXT: 'WIDGETCONTEXT_REGISTER_CONTEXT',
    WIDGETCONTEXT_UPDATE_CONTEXT: 'WIDGETCONTEXT_UPDATE_CONTEXT',
    WIDGETCONTEXT_UNREGISTER_CONTEXT: 'WIDGETCONTEXT_UNREGISTER_CONTEXT',
    WIDGETCONTEXT_RESET_LAYOUT: 'WIDGETCONTEXT_RESET_LAYOUT',

    //damagemanager shared
    DAMAGE_MANAGER_REQUEST_DAMAGE: 'DAMAGE_MANAGER_REQUEST_DAMAGE',
    DAMAGE_MANAGER_POPULATE_DAMAGE: 'DAMAGE_MANAGER_POPULATE_DAMAGE',
    DAMAGE_MANAGER_POPULATE_SHARED_LAWFIRM: 'DAMAGE_MANAGER_POPULATE_SHARED_LAWFIRM',
    DAMAGE_MANAGER_SHARED_UPDATE_VEHICLE: 'DAMAGE_MANAGER_SHARED_UPDATE_VEHICLE',
    DAMAGE_MANAGER_SHARED_POPULATE_VEHICLE: 'DAMAGE_MANAGER_SHARED_POPULATE_VEHICLE',
    DAMAGE_MANAGER_SHARED_RESET_DAMAGE: 'DAMAGE_MANAGER_SHARED_RESET_DAMAGE',

    //damagemanager tasks
    DAMAGE_MANAGER_TASKS_REQUEST_TASKS: 'DAMAGE_MANAGER_TASKS_REQUEST_TASKS',
    DAMAGE_MANAGER_TASKS_UPDATE_TASK_IN_LIST: 'DAMAGE_MANAGER_TASKS_UPDATE_TASK_IN_LIST',
    DAMAGE_MANAGER_TASKS_POPULATE_TASKS: 'DAMAGE_MANAGER_TASKS_POPULATE_TASKS',
    DAMAGE_MANAGER_TASKS_REQUEST_SELECTED_TASK: 'DAMAGE_MANAGER_TASKS_REQUEST_SELECTED_TASK',
    DAMAGE_MANAGER_TASKS_POPULATE_SELECTED_TASK: 'DAMAGE_MANAGER_TASKS_POPULATE_SELECTED_TASK',
    DAMAGE_MANAGER_TASKS_UPDATE_SELECTED_TASK: 'DAMAGE_MANAGER_TASKS_UPDATE_SELECTED_TASK',
    DAMAGE_MANAGER_TASKS_UPDATE_SELECTED_TASK_DOCUMENT_CATEGORY: 'DAMAGE_MANAGER_TASKS_UPDATE_SELECTED_TASK_DOCUMENT_CATEGORY',
    DAMAGE_MANAGER_TASKS_POPULATE_SELECTED_TASK_DOCUMENTS: 'DAMAGE_MANAGER_TASKS_POPULATE_SELECTED_TASK_DOCUMENTS',
    DAMAGE_MANAGER_TASKS_RESET_SELECTED_TASK: 'DAMAGE_MANAGER_TASKS_RESET_SELECTED_TASK',
    DAMAGE_MANAGER_TASKS_LOAD_USERLIST_DEBOUNCED: 'DAMAGE_MANAGER_TASKS_LOAD_USERLIST_DEBOUNCED',
    DAMAGE_MANAGER_TASKS_POPULATE_USERLIST: 'DAMAGE_MANAGER_TASKS_POPULATE_USERLIST',
    DAMAGE_MANAGER_UPDATE_STATUS: 'DAMAGE_MANAGER_UPDATE_STATUS',
    DAMAGE_MANAGER_DOCUMENTS_GET_ALL_DOCUMENTS_REQUEST: 'DAMAGE_MANAGER_DOCUMENTS_GET_ALL_DOCUMENTS_REQUEST',
    DAMAGE_MANAGER_DOCUMENTS_RESET: 'DAMAGE_MANAGER_DOCUMENTS_RESET',
    DAMAGE_MANAGER_DOCUMENTS_POPULATE_DOCUMENTS: 'DAMAGE_MANAGER_DOCUMENTS_POPULATE_DOCUMENTS',
    DAMAGE_MANAGER_SHOW_ATTACHMENT_DETAILS: 'DAMAGE_MANAGER_SHOW_ATTACHMENT_DETAILS',
    DAMAGE_MANAGER_ATTACHMENT_DETAILS_SET_CATEGORY: 'DAMAGE_MANAGER_ATTACHMENT_DETAILS_SET_CATEGORY',
    DAMAGE_MANAGER_HIDE_ATTACHMENT_DETAILS: 'DAMAGE_MANAGER_HIDE_ATTACHMENT_DETAILS',
    DAMAGE_MANAGER_DOCUMENTS_HIDE_ATTACHMENT_DETAILS: 'DAMAGE_MANAGER_DOCUMENTS_HIDE_ATTACHMENT_DETAILS',
    DAMAGE_MANAGER_DOCUMENTS_SHOW_ATTACHMENT_DETAILS: 'DAMAGE_MANAGER_DOCUMENTS_SHOW_ATTACHMENT_DETAILS',
    DAMAGE_MANAGER_DOCUMENTS_REMOVE_ATTACHMENT: 'DAMAGE_MANAGER_DOCUMENTS_REMOVE_ATTACHMENT',
    DAMAGE_MANAGER_DOCUMENTS_SET_ARCHIVE_DOWNLOADING: 'DAMAGE_MANAGER_DOCUMENTS_SET_ARCHIVE_DOWNLOADING',
    DAMAGE_MANAGER_DOCUMENTS_UPDATE_ATTACHMENT_DETAILS: 'DAMAGE_MANAGER_DOCUMENTS_UPDATE_ATTACHMENT_DETAILS',
    DAMAGE_MANAGER_TASKS_REMOVE_TASK_FROM_LIST: 'DAMAGE_MANAGER_TASKS_REMOVE_TASK_FROM_LIST',
    DAMAGE_MANAGER_UPDATE_DAMAGE: 'ACTIONS.DAMAGE_MANAGER_UPDATE_DAMAGE',
    DAMAGE_MANAGER_UPDATE_DAMAGE_ASSIGNEE: 'DAMAGE_MANAGER_UPDATE_DAMAGE_ASSIGNEE',
    DAMAGE_MANAGER_UPDATE_LIABILITY_INSURANCE_AND_CLAIM: 'DAMAGE_MANAGER_UPDATE_LIABILITY_INSURANCE_AND_CLAIM',
    DAMAGE_MANAGER_UPDATE_VEHICLE_PROPRIETOR: 'DAMAGE_MANAGER_UPDATE_VEHICLE_PROPRIETOR',
    DAMAGE_MANAGER_UPDATE_REPAIR_AUTHORIZATION: 'DAMAGE_MANAGER_UPDATE_REPAIR_AUTHORIZATION',
    DAMAGE_MANAGER_UPDATE_ACCIDENT_REPORT: 'DAMAGE_MANAGER_UPDATE_ACCIDENT_REPORT',
    DAMAGE_MANAGER_UPDATE_POLICE: 'DAMAGE_MANAGER_UPDATE_POLICE',
    DAMAGE_MANAGER_UPDATE_OPPONENT_DRIVER: 'DAMAGE_MANAGER_UPDATE_OPPONENT_DRIVER',
    DAMAGE_MANAGER_UPDATE_OPPONENT_KEEPER: 'DAMAGE_MANAGER_UPDATE_OPPONENT_KEEPER',
    DAMAGE_MANAGER_UPDATE_OPPONENT_KEEPER_AND_DRIVER: 'DAMAGE_MANAGER_UPDATE_OPPONENT_KEEPER_AND_DRIVER',
    DAMAGE_MANAGER_UPDATE_OPPONENT_VEHICLE: 'DAMAGE_MANAGER_UPDATE_OPPONENT_VEHICLE',
    DAMAGE_MANAGER_UPDATE_WITNESS: 'DAMAGE_MANAGER_UPDATE_WITNESS',
    DAMAGE_MANAGER_UPDATE_TASK: 'DAMAGE_MANAGER_UPDATE_TASK',
    DAMAGE_MANAGER_ASSIGN_TASK: 'DAMAGE_MANAGER_ASSIGN_TASK',
    DAMAGE_MANAGER_TASK_STATE: 'DAMAGE_MANAGER_TASK_STATE',
    DAMAGE_MANAGER_UPDATE_TASK_WORKFLOW_PANEL: 'DAMAGE_MANAGER_UPDATE_TASK_WORKFLOW_PANEL',
    DAMAGE_MANAGER_COMMENT_CREATE: 'DAMAGE_MANAGER_COMMENT_CREATE',
    DAMAGE_MANAGER_COMMENT_CREATE_WORKFLOW_PANEL: 'DAMAGE_MANAGER_COMMENT_CREATE_WORKFLOW_PANEL',
    DAMAGE_MANAGER_UPDATE_COMPREHENSIVE_DATA: 'DAMAGE_MANAGER_UPDATE_COMPREHENSIVE_DATA',
    DAMAGE_MANAGER_COMMENTS_LOAD_MORE: 'DAMAGE_MANAGER_COMMENTS_LOAD_MORE',
    DAMAGE_MANAGER_REQUEST_UPDATE_DOCUMENT: 'DAMAGE_MANAGER_REQUEST_UPDATE_DOCUMENT',
    DAMAGE_MANAGER_TASKS_COMMENTS_SET_TOTAL: 'DAMAGE_MANAGER_TASKS_COMMENTS_SET_TOTAL',
    DAMAGE_MANAGER_TASKS_INCREMENT_COMMENTS_TOTAL: 'DAMAGE_MANAGER_TASKS_INCREMENT_COMMENTS_TOTAL',

    //damages overview
    DAMAGES_OVERVIEW_GET_ALL_DAMAGES_REQUEST: 'DAMAGES_OVERVIEW_GET_ALL_DAMAGES_REQUEST',
    DAMAGES_OVERVIEW_POPULATE_ALL_DAMAGES: 'DAMAGES_OVERVIEW_POPULATE_ALL_DAMAGES',
    DAMAGES_OVERVIEW_DELETE_DAMAGE_BY_ID: 'DAMAGES_OVERVIEW_DELETE_DAMAGE_BY_ID',

    //tasks_overview
    MY_TASKS_GET_ALL_TASKS_REQUEST: 'MY_TASKS_GET_ALL_TASKS_REQUEST',
    MY_TASKS_POPULATE_ALL_MY_TASKS: 'MY_TASKS_POPULATE_ALL_MY_TASKS',
    MY_TASKS_GET_ALL_MY_TASKS_REQUEST: 'MY_TASKS_GET_ALL_MY_TASKS_REQUEST',
    MY_TASKS_POPULATE_ALL_TASKS: 'MY_TASKS_POPULATE_ALL_TASKS',
    MY_TASKS_DELETE_TASK_BY_ID: 'MY_TASKS_DELETE_TASK_BY_ID',
    
    //customers
    CUSTOMERS_GET_CUSTOMERS_REQUEST: 'CUSTOMERS_GET_CUSTOMERS_REQUEST',
    CUSTOMERS_POPULATE_CUSTOMERS_LIST: 'CUSTOMERS_POPULATE_CUSTOMERS_LIST',

    //Notification history
    ACTIVITY_LOG_GET_ACTIVITIES_REQUEST: 'ACTIVITY_LOG_GET_ACTIVITIES_REQUEST',
    ACTIVITY_LOG_POPULATE_ACTIVITIES_LIST: 'ACTIVITY_LOG_POPULATE_ACTIVITIES_LIST',

    //administration
    ADMINISTRATION_SEND_INVITATION: 'ADMINISTRATION_SEND_INVITATION',

    //cross cutting
    COMMON_GET_USERS_REQUEST: 'COMMON_GET_USERS_REQUEST',
    COMMON_GET_USERS_REQUEST_DEBOUNCED: 'COMMON_GET_USERS_REQUEST_DEBOUNCED',
    COMMON_GET_USERS_FOR_DIALOG_REQUEST: 'COMMON_GET_USERS_FOR_DIALOG_REQUEST',
    COMMON_GET_USERS_FOR_DIALOG_REQUEST_DEBOUNCED: 'COMMON_GET_USERS_FOR_DIALOG_REQUEST_DEBOUNCED',
    COMMON_GET_WEBAKTE_LAWFIRM_FOR_DIALOG_REQUEST_DEBOUNCED: 'COMMON_GET_WEBAKTE_LAWFIRM_FOR_DIALOG_REQUEST_DEBOUNCED',
    COMMON_GET_CUSTOMERS_FOR_DIALOG_REQUEST_DEBOUNCED: 'COMMON_GET_CUSTOMERS_FOR_DIALOG_REQUEST_DEBOUNCED',
    COMMON_SET_USERS_LOADING: 'COMMON_SET_USERS_LOADING',
    COMMON_CLEAR_USERS_LIST: 'COMMON_CLEAR_USERS_LIST',
    COMMON_POPULATE_USERS: 'COMMON_POPULATE_USERS',
    COMMON_RESET_USERS: 'COMMON_RESET_USERS',
    COMMON_UPDATE_ACCOUNT: 'COMMON_UPDATE_ACCOUNT',
    COMMON_GET_INSURANCES_REQUEST: 'COMMON_GET_INSURANCES_REQUEST',
    COMMON_POPULATE_INSURANCES: 'COMMON_POPULATE_INSURANCES',
    COMMON_WP_UPDATE: 'COMMON_WP_UPDATE',
    COMMON_GET_ACCESSIBLE_BRANCHES_REQUEST: 'COMMON_GET_ACCESSIBLE_BRANCHES_REQUEST',
    COMMON_GET_FIRST_BRANCH_REQUEST: 'COMMON_GET_FIRST_BRANCH_REQUEST',
    COMMON_RESET_ACCESSIBLE_BRANCHES: 'COMMON_RESET_ACCESSIBLE_BRANCHES',
    COMMON_POPULATE_ACCESSIBLE_BRANCHES: 'COMMON_POPULATE_ACCESSIBLE_BRANCHES',
    COMMON_POPULATE_FIRST_BRANCH: 'COMMON_POPULATE_FIRST_BRANCH',
    COMMON_RESET_BRANCHES: 'COMMON_RESET_BRANCHES',
    COMMON_UPDATE_BRANCH_IN_LIST: 'COMMON_UPDATE_BRANCH_IN_LIST',
    COMMON_ADD_BRANCH_TO_LIST: 'COMMON_ADD_BRANCH_TO_LIST',
    COMMON_GET_COMPREHENSIVE_DAMAGE_TYPES_REQUEST: 'COMMON_GET_COMPREHENSIVE_DAMAGE_TYPES_REQUEST',
    COMMON_POPULATE_COMPREHENSIVE_DAMAGE_TYPES: 'COMMON_POPULATE_COMPREHENSIVE_DAMAGE_TYPES',
    COMMON_GET_USER_GROUPS_REQUEST: 'COMMON_GET_USER_GROUPS_REQUEST',
    COMMON_POPULATE_USER_GROUPS: 'COMMON_POPULATE_USER_GROUPS',
    COMMON_RESET_USER_GROUPS: 'COMMON_RESET_USER_GROUPS',
    COMMON_REQUEST_STATUS_LIST: 'COMMON_REQUEST_STATUS_LIST',
    COMMON_POPULATE_STATUS_LIST: 'COMMON_POPULATE_STATUS_LIST',
    
    //inbox
    INBOX_GET_MESSAGE_BY_ID: 'INBOX_GET_MESSAGE_BY_ID',
    INBOX_POPULATE_MESSAGE_DETAILS: 'INBOX_POPULATE_MESSAGE_DETAILS',
    INBOX_MESSAGE_NOT_FOUND: 'INBOX_MESSAGE_NOT_FOUND',
    INBOX_RESET_MESSAGE_DETAILS: 'INBOX_RESET_MESSAGE_DETAILS',

    //attchments
    ATTACHMENTS_GET_THUMBNAIL_REQUEST: 'ATTACHMENTS_GET_THUMBNAIL_REQUEST',
    ATTACHMENTS_GET_FULL_ATTACHMENT_REQUEST: 'ATTACHMENTS_GET_FULL_ATTACHMENT_REQUEST',
    ATTACHMENTS_POPULATE_THUMBNAIL: 'ATTACHMENTS_POPULATE_THUMBNAIL',
    ATTACHMENTS_POPULATE_FULL_ATTACHMENT: 'ATTACHMENTS_POPULATE_FULL_ATTACHMENT',
    ATTACHMENTS_GET_META_REQUEST: 'ATTACHMENTS_GET_META_REQUEST',
    ATTACHMENTS_POPULATE_META: 'ATTACHMENTS_POPULATE_META',
    ATTACHMENTS_DELETE_ATTACHMENT: 'ATTACHMENTS_DELETE_ATTACHMENT',
    DOWNLOAD_ATTACHMENT_BY_ID_REQUEST: 'DOWNLOAD_ATTACHMENT_BY_ID_REQUEST',
    DOCUMENTS_DOWNLOAD_DAMAGE_DOCUMENTS: 'DOCUMENTS_DOWNLOAD_DAMAGE_DOCUMENTS',
    DOCUMENTS_DOWNLOAD_TASK_DOCUMENTS: 'DOCUMENTS_DOWNLOAD_TASK_DOCUMENTS',

    //workflow panel
    WORKFLOW_PANEL_TASKS_REQUEST_TASKS: 'WORKFLOW_PANEL_TASKS_REQUEST_TASKS',
    WORKFLOW_PANEL_TASKS_UPDATE_TASK_IN_LIST: 'WORKFLOW_PANEL_TASKS_UPDATE_TASK_IN_LIST',
    WORKFLOW_PANEL_TASKS_POPULATE_TASKS: 'WORKFLOW_PANEL_TASKS_POPULATE_TASKS',
    WORKFLOW_PANEL_TASK_NOT_FOUND: 'WORKFLOW_PANEL_TASK_NOT_FOUND',
    WORKFLOW_PANEL_TASKS_REQUEST_SELECTED_TASK: 'WORKFLOW_PANEL_TASKS_REQUEST_SELECTED_TASK',
    WORKFLOW_PANEL_TASKS_POPULATE_SELECTED_TASK: 'WORKFLOW_PANEL_TASKS_POPULATE_SELECTED_TASK',
    WORKFLOW_PANEL_TASKS_POPULATE_SELECTED_TASK_DOCUMENTS: 'WORKFLOW_PANEL_TASKS_POPULATE_SELECTED_TASK_DOCUMENTS',
    WORKFLOW_PANEL_TASKS_UPDATE_SELECTED_TASK: 'WORKFLOW_PANEL_TASKS_UPDATE_SELECTED_TASK',
    WORKFLOW_PANEL_TASKS_RESET_SELECTED_TASK: 'WORKFLOW_PANEL_TASKS_RESET_SELECTED_TASK',
    WORKFLOW_PANEL_TASKS_LOAD_USERLIST_DEBOUNCED: 'WORKFLOW_PANEL_TASKS_LOAD_USERLIST_DEBOUNCED',
    WORKFLOW_PANEL_TASKS_POPULATE_USERLIST: 'WORKFLOW_PANEL_TASKS_POPULATE_USERLIST',
    WORKFLOW_PANEL_SHOW_ATTACHMENT_DETAILS: 'WORKFLOW_PANEL_SHOW_ATTACHMENT_DETAILS',
    WORKFLOW_PANEL_HIDE_ATTACHMENT_DETAILS: 'WORKFLOW_PANEL_HIDE_ATTACHMENT_DETAILS',
    WORKFLOW_PANEL_UPDATE_STATUS: 'WORKFLOW_PANEL_UPDATE_STATUS',
    WORKFLOW_PANEL_SHARED_POPULATE_DAMAGE_DATA: 'WORKFLOW_PANEL_SHARED_POPULATE_DAMAGE_DATA',
    WORKFLOW_PANEL_SHARED_POPULATE_ACCOUNT_DATA: 'WORKFLOW_PANEL_SHARED_POPULATE_ACCOUNT_DATA',
    WORKFLOW_PANEL_SHARED_POPULATE_LAWFIRM: 'WORKFLOW_PANEL_SHARED_POPULATE_LAWFIRM',
    WORKFLOW_PANEL_SHARED_DAMAGE_DATA_REQUEST: 'WORKFLOW_PANEL_SHARED_DAMAGE_DATA_REQUEST',
    WORKFLOW_PANEL_SHARED_LAWFIRM_REQUEST: 'WORKFLOW_PANEL_SHARED_LAWFIRM_REQUEST',
    WORKFLOW_PANEL_SHARED_UPDATE_INVOICES: 'WORKFLOW_PANEL_SHARED_UPDATE_INVOICES',
    WORKFLOW_PANEL_SHARED_REFRESH_INVOICES: 'WORKFLOW_PANEL_SHARED_REFRESH_INVOICES',
    WORKFLOW_PANEL_SHARED_CATEGORIES_REQUEST: 'WORKFLOW_PANEL_SHARED_CATEGORIES_REQUEST',
    WORKFLOW_PANEL_SHARED_POPULATE_CATEGORIES: 'WORKFLOW_PANEL_SHARED_POPULATE_CATEGORIES',
    WORKFLOW_PANEL_WRITE_USER_DATA: 'WORKFLOW_PANEL_WRITE_USER_DATA',
    WORKFLOW_PANEL_TASKS_ADD_COMMENT_TO_TASK_IN_LIST: 'WORKFLOW_PANEL_TASKS_ADD_COMMENT_TO_TASK_IN_LIST',
    WORKFLOW_PANEL_TASKS_ADD_ATTACHMENTS_TO_TASK_IN_LIST: 'WORKFLOW_PANEL_TASKS_ADD_ATTACHMENTS_TO_TASK_IN_LIST',
    WORKFLOW_PANEL_TASKS_MINUS_ATTACHMENTS_TO_TASK_IN_LIST: 'WORKFLOW_PANEL_TASKS_MINUS_ATTACHMENTS_TO_TASK_IN_LIST',
    WORKFLOW_PANEL_ACTIVITY_LOG_REQUEST: 'WORKFLOW_PANEL_ACTIVITY_LOG_REQUEST',
    WORKFLOW_PANEL_ACTIVITY_LOG_MORE_REQUEST: 'WORKFLOW_PANEL_ACTIVITY_LOG_MORE_REQUEST',
    WORKFLOW_PANEL_ACTIVITY_LOG_POPULATE_DATA: 'WORKFLOW_PANEL_ACTIVITY_LOG_POPULATE_DATA',
    WORKFLOW_PANEL_DOCUMENTS_POPULATE_DOCUMENTS: 'WORKFLOW_PANEL_DOCUMENTS_POPULATE_DOCUMENTS',
    WORKFLOW_PANEL_DOCUMENTS_GET_ALL_DOCUMENTS_REQUEST: 'WORKFLOW_PANEL_DOCUMENTS_GET_ALL_DOCUMENTS_REQUEST',
    WORKFLOW_PANEL_DOCUMENTS_SHOW_ATTACHMENT_DETAILS: 'WORKFLOW_PANEL_DOCUMENTS_SHOW_ATTACHMENT_DETAILS',
    WORKFLOW_PANEL_DOCUMENTS_HIDE_ATTACHMENT_DETAILS: 'WORKFLOW_PANEL_DOCUMENTS_HIDE_ATTACHMENT_DETAILS',
    WORKFLOW_PANEL_DOCUMENTS_REMOVE_ATTACHMENT: 'WORKFLOW_PANEL_DOCUMENTS_REMOVE_ATTACHMENT',
    WORKFLOW_PANEL_TASKS_REMOVE_TASK_FROM_LIST: 'WORKFLOW_PANEL_TASKS_REMOVE_TASK_FROM_LIST',
    WORKFLOW_PANEL_DOWNLOAD_ATTACHMENTS_ZIP: 'WORKFLOW_PANEL_DOWNLOAD_ATTACHMENTS_ZIP',
    WORKFLOW_PANEL_DOCUMENTS_SET_ARCHIVE_DOWNLOADING: 'WORKFLOW_PANEL_DOCUMENTS_SET_ARCHIVE_DOWNLOADING',

    //waiting screen
    WAITING_SCREEN_SHOW: 'WAITING_SCREEN_SHOW',
    WAITING_SCREEN_HIDE: 'WAITING_SCREEN_HIDE',
    WAITING_SCREEN_ADD_NEW_SUB_MESSAGE: 'WAITING_SCREEN_ADD_NEW_SUB_MESSAGE',
    WAITING_SCREEN_ADD_TO_LAST_SUB_MESSAGE: 'WAITING_SCREEN_ADD_TO_LAST_SUB_MESSAGE',

    //gallery
    GALLERY_SHOW_GALLERY: 'GALLERY_SHOW_GALLERY',
    GALLERY_HIDE_GALLERY: 'GALLERY_HIDE_GALLERY',
    GALLERY_SET_ACTIVE_ATTACHMENT: 'GALLERY_SET_ACTIVE_ATTACHMENT',

    //registration
    REGISTRATION_CONNECT_USER_REQUEST: 'REGISTRATION_CONNECT_USER_REQUEST',
    REGISTRATION_CONNECT_USER_CALLBACK: 'REGISTRATION_CONNECT_USER_CALLBACK',
    REGISTRATION_CREATE_ACCOUNT_REQUEST: 'REGISTRATION_CREATE_ACCOUNT_REQUEST',
    REGISTRATION_CREATE_ACCOUNT_CALLBACK: 'REGISTRATION_CREATE_ACCOUNT_CALLBACK',

    //realtime notifications
    REALTIME_NOTIFICATIONS_SHOW_SIDEBAR: 'REALTIME_NOTIFICATIONS_SHOW_SIDEBAR',
    REALTIME_NOTIFICATIONS_HIDE_SIDEBAR: 'REALTIME_NOTIFICATIONS_HIDE_SIDEBAR',
    REALTIME_NOTIFICATIONS_ADD_NOTIFICATION: 'REALTIME_NOTIFICATIONS_ADD_NOTIFICATION',
    REALTIME_NOTIFICATIONS_SET_NEW_NOTIFICATIONS_TO_FALSE: 'REALTIME_NOTIFICATIONS_SET_NEW_NOTIFICATIONS_TO_FALSE',

    //M ass O peration S (MOS)
    MOS_REGISTER_MO: 'MOS_REGISTER_MO',
    MOS_UNREGISTER_MO_BY_NAME: 'MOS_UNREGISTER_MO_BY_NAME',
    MOS_SET_MO_ACTIVE_BY_NAME: 'MOS_SET_MO_ACTIVE_BY_NAME',
    MOS_SET_MO_INACTIVE_BY_NAME: 'MOS_SET_MO_INACTIVE_BY_NAME',

    MOS_CLEAR_ALL_ITEMS_BY_MO_NAME: 'MOS_CLEAR_ALL_ITEMS_BY_MO_NAME', 
    MOS_SET_ITEMS_BY_MO_NAME: 'MOS_SET_ITEMS_BY_MO_NAME',
    MOS_ADD_ITEM_BY_MO_NAME: 'MOS_ADD_ITEM_BY_MO_NAME',
    MOS_REMOVE_ITEM_BY_MO_NAME: 'MOS_REMOVE_ITEM_BY_MO_NAME',

    //branch_management
    BRANCH_MANAGEMENT_ADD_ROLE: 'BRANCH_MANAGEMENT_ADD_ROLE',
    BRANCH_MANAGEMENT_REMOVE_ROLE: 'BRANCH_MANAGEMENT_REMOVE_ROLE',
    BRANCH_MANAGEMENT_REMOVE_GROUP: 'BRANCH_MANAGEMENT_REMOVE_GROUP',
    BRANCH_MANAGEMENT_REMOVE_BRANCH: 'BRANCH_MANAGEMENT_REMOVE_BRANCH',
    BRANCH_MANAGEMENT_ADD_GROUP_ROLE_RELATIONSHIP: 'BRANCH_MANAGEMENT_ADD_GROUP_ROLE_RELATIONSHIP',
    BRANCH_MANAGEMENT_ADD_GROUP_BRANCH_RELATIONSHIP: 'BRANCH_MANAGEMENT_ADD_GROUP_BRANCH_RELATIONSHIP',
    BRANCH_MANAGEMENT_REMOVE_GROUP_ROLE_RELATIONSHIP: 'BRANCH_MANAGEMENT_REMOVE_GROUP_ROLE_RELATIONSHIP',
    BRANCH_MANAGEMENT_REMOVE_GROUP_BRANCH_RELATIONSHIP: 'BRANCH_MANAGEMENT_REMOVE_GROUP_BRANCH_RELATIONSHIP',
    BRANCH_MANAGEMENT_TOGGLE_ROLE_SIDEBAR: 'BRANCH_MANAGEMENT_TOGGLE_ROLE_SIDEBAR',
    BRANCH_MANAGEMENT_TOGGLE_GROUP_SIDEBAR: 'BRANCH_MANAGEMENT_TOGGLE_GROUP_SIDEBAR',
    BRANCH_MANAGEMENT_TOGGLE_BRANCH_SIDEBAR: 'BRANCH_MANAGEMENT_TOGGLE_BRANCH_SIDEBAR',
    BRANCH_MANAGEMENT_TOGGLE_HELP_SIDEBAR: 'BRANCH_MANAGEMENT_TOGGLE_HELP_SIDEBAR',
    BRANCH_MANAGEMENT_CLOSE_ALL_SIDEBARS: 'BRANCH_MANAGEMENT_CLOSE_ALL_SIDEBARS',
    BRANCH_MANAGEMENT_GET_GROUP_MEMBERS_REQUEST: 'BRANCH_MANAGEMENT_GET_GROUP_MEMBERS_REQUEST',
    BRANCH_MANAGEMENT_POPULATE_GROUP_MEMBERS: 'BRANCH_MANAGEMENT_POPULATE_GROUP_MEMBERS',
    BRANCH_MANAGEMENT_UPDATE_GROUP: 'BRANCH_MANAGEMENT_UPDATE_GROUP',
    
    //main navigation sidebar
    MAIN_NAVIGATION_SIDEBAR_TOGGLE: 'MAIN_NAVIGATION_SIDEBAR_TOGGLE',
    MAIN_NAVIGATION_REGISTER_ACCORDION_MENU_STATE: 'MAIN_NAVIGATION_REGISTER_ACCORDION_MENU_STATE',
    MAIN_NAVIGATION_OPEN_ONLY_CURRENT_ACCORDION: 'MAIN_NAVIGATION_OPEN_ONLY_CURRENT_ACCORDION',
    MAIN_NAVIGATION_CLOSE_ACCORDIONS: 'MAIN_NAVIGATION_CLOSE_ACCORDIONS',

    //document categories
    COMMON_GET_CATEGORIES_REQUEST: 'COMMON_GET_CATEGORIES_REQUEST',
    COMMON_POPULATE_CATEGORIES: 'COMMON_POPULATE_CATEGORIES',
    COMMON_CHANGE_ATTACHMENT_CATEGORY: 'COMMON_CHANGE_ATTACHMENT_CATEGORY',
    COMMON_CHANGE_ATTACHMENT_CATEGORY_FROM_TASK: 'COMMON_CHANGE_ATTACHMENT_CATEGORY_FROM_TASK',
    COMMON_MOVE_CATEGORY: 'COMMON_MOVE_CATEGORY',
    COMMON_RESET_CATEGORIES: 'COMMON_RESET_CATEGORIES',

    //component update scheduler
    SCHEDULE_UPDATE: 'SCHEDULE_UPDATE',

    //handled by multiple reducers, resets data on account change
    RESET_ACCOUNT_RELATED_DATA: 'RESET_ACCOUNT_RELATED_DATA',
    
    //error details
    ERRORS_SHOW_DETAILS: 'ERRORS_SHOW_DETAILS',
    ERRORS_STORE_DETAILS: 'ERRORS_STORE_DETAILS',
    ERRORS_HIDE_DETAILS: 'ERRORS_HIDE_DETAILS',

    //roles
    COMMON_GET_ROLES_REQUEST: 'COMMON_GET_ROLES_REQUEST',
    COMMON_POPULATE_ROLES: 'COMMON_POPULATE_ROLES',
    COMMON_RESET_ROLES: 'COMMON_RESET_ROLES',

    //generic shared resource operations
    SHARED_RESOURCE_SET_SHARED_RESOURCE_DATA: 'SHARED_RESOURCE_SET_SHARED_RESOURCE_DATA',
    SHARED_RESOURCE_VALIDATE_PIN_REQUEST: 'SHARED_RESOURCE_VALIDATE_PIN_REQUEST',
    SHARED_RESOURCE_VALIDATE_PIN_FAILED: 'SHARED_RESOURCE_VALIDATE_PIN_FAILED',
    SHARED_RESOURCE_VALIDATE_PIN_SUCCESS: 'SHARED_RESOURCE_VALIDATE_PIN_SUCCESS',
    SHARED_RESOURCE_GET_SHARED_RESOURCE_BY_ID: 'SHARED_RESOURCE_GET_SHARED_RESOURCE_BY_ID',
    SHARED_RESOURCE_POPULATE_SHARED_RESOURCE: 'SHARED_RESOURCE_POPULATE_SHARED_RESOURCE',
    SHARED_RESOURCE_SET_RETURN_URL: 'SHARED_RESOURCE_SET_RETURN_URL',
    SHARED_RESOURCE_LOGOUT: 'SHARED_RESOURCE_LOGOUT',
    SHARED_RESOURCE_UPDATE_PIN: 'SHARED_RESOURCE_UPDATE_PIN',
    SHARED_RESOURCE_SKIP_VALIDATION: 'SHARED_RESOURCE_SKIP_VALIDATION',
    //external damage creation
    EXT_CREATE_DAMAGE_REQUEST: 'EXT_CREATE_DAMAGE_REQUEST',
    EXT_CREATE_DAMAGE_KICKSTART_REQUEST: 'EXT_CREATE_DAMAGE_KICKSTART_REQUEST',
    EXT_CREATE_DAMAGE_SUCCESS: 'EXT_CREATE_DAMAGE_SUCCESS',
    EXT_SEND_DOCUMENTS_REQUEST: 'EXT_SEND_DOCUMENTS_REQUEST',
    EXT_SEND_DOCUMENTS_SUCCESS: 'EXT_SEND_DOCUMENTS_SUCCESS',

    QR_CODE_DOWNLOAD: 'QR_CODE_DOWNLOAD'
}
