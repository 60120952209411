import {ACTIONS} from 'application/constants'


export const getAccessibleBranchesRequest = () => ({
    type: ACTIONS.COMMON_GET_ACCESSIBLE_BRANCHES_REQUEST
})

export const getFirstBranchRequest = () => ({
    type: ACTIONS.COMMON_GET_FIRST_BRANCH_REQUEST
})

export const resetAccessibleBranches = () => ({
    type: ACTIONS.COMMON_RESET_ACCESSIBLE_BRANCHES
})

export const populateAccessibleBranches = (accessibleBranches, allBranches) => ({
    type: ACTIONS.COMMON_POPULATE_ACCESSIBLE_BRANCHES,
    accessibleBranches,
    allBranches
})

export const populateFirstBranch = (branch) => ({
    type: ACTIONS.COMMON_POPULATE_FIRST_BRANCH,
    branch
})

export const updateBranchInList = (branchId, branchData) => ({
    type: ACTIONS.COMMON_UPDATE_BRANCH_IN_LIST,
    branchId, 
    branchData
})

export const resetBranches = () => ({
    type: ACTIONS.COMMON_RESET_BRANCHES
})

export const addBranchToList = (branch) => ({
    type: ACTIONS.COMMON_ADD_BRANCH_TO_LIST,
    branch
})