import React, { useState } from 'react'
import { CheckboxWithLabel, ValidatedHiddenField } from 'application/components/controls/form'
import PropTypes from 'prop-types'
import { FlexBox } from 'application/components/fragments/flex_box'
import { validationMethods } from 'application/common'
import styled from 'styled-components'

const Validation = styled.div`
    margin-top: -10px;
    margin-bottom: 10px;
`



const CheckBoxGroup = (
    {
        options,
        register,
        errors,
        name ='checkboxGroup',
        validationCondition = { selectAtLeastOne: validationMethods.selectAtLeastOne },
        errorMessageOverride ='Please select at least one',
        onChange = () => {}
    }
) => {

    const [selectedElementsCount, setSelectedElementsCount] = useState(0)

    const handleCheckboxChange = (e, isChecked) => {
        setSelectedElementsCount(isChecked ? selectedElementsCount + 1 : selectedElementsCount - 1)
        onChange(e, isChecked)
    }

    return <React.Fragment>
        {
            options.map(
                (option) => {
                    return <FlexBox
                        key={option.value} 
                        paddingBottom='8px'
                    >
                        <CheckboxWithLabel
                            register={register}
                            name={name}
                            label={option.label}
                            isRequired={false}
                            isSelected={ false }
                            value={option.value}
                            onCheckboxChange={handleCheckboxChange}
                            activeLabel={option.activeLabel}
                        />
                    </FlexBox>
                }
            )
        }
        <Validation>
            <ValidatedHiddenField 
                register={register}
                name={`${name}SelectedCount`}
                validate={validationCondition}
                error={errors[`${name}SelectedCount`]}
                errorMessageOverride={errorMessageOverride}
                value={selectedElementsCount}
            />
        </Validation>
    </React.Fragment>
}

CheckBoxGroup.propTypes = {
    options: PropTypes.array.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired, 
    name: PropTypes.string,
    validationCondition: PropTypes.object, 
    errorMessageOverride: PropTypes.string,
    shouldPassSelectedNames: PropTypes.bool,
    setSelectedCheckboxNames: PropTypes.func
}

export { CheckBoxGroup } 