import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, setQuery, deleteQuery } from 'application/components/hooks/use_query'
import { nameHelpers } from 'application/common'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'
import IconButton from 'application/components/controls/icon_button'
import { PureSelect } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'
import { exists } from 'application/common/data_helpers'

const UserFilter = (
    { 
        onSearchUser, 
        usersList, 
        usersListLoading, 
        routeParameters, 
        filterTitle = 'Showing tasks for', 
        onRemoveFilter = () => { } 
    }
) => {
        
    const query = useQuery()
    const location = useLocation()
    const history = useHistory()
    const { t } = useTranslation()

    const { ID, FIRST_NAME, LAST_NAME } = routeParameters
    const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls
    const [selectedUserId, setSelectedUserId] = useState(query.getStringParam(ID))
    const [selectedUserFirstName, setSelectedUserFirstName] = useState(query.getStringParam(FIRST_NAME))
    const [selectedUserLastName, setSelectedUserLastName] = useState(query.getStringParam(LAST_NAME))

    //predefine the selected option if the information is present in the URL
    const [selectedUserOption, setSelectedUserOption] = useState( 
        query.getStringParam(ID) === '' 
            ? null 
            : {
                value: query.getStringParam(ID),
                label: nameHelpers.getFullName(
                    { 
                        firstName: query.getStringParam(FIRST_NAME), 
                        lastName: query.getStringParam(LAST_NAME) 
                    }
                ),
                firstName: query.getStringParam(FIRST_NAME),
                lastName: query.getStringParam(LAST_NAME)
            }
    )

    const handleSearchUser = search => {
        if (userSearchActive && search.length > 2) {
            onSearchUser(search)
        }
    }

    const handleSelectUser = option => {
        setSelectedUserId(option.value)
        setSelectedUserLastName(option.lastName)
        setSelectedUserFirstName(option.firstName)
        setSelectedUserOption(option)

        setQuery(
            history, 
            location, 
            location.pathname, 
            [
                {
                    key: ID,
                    value: option.value
                }, {
                    key: FIRST_NAME,
                    value: option.firstName
                }, {
                    key: LAST_NAME,
                    value: option.lastName
                }
            ], 
            true
        )
    }

    const usersOptions = usersList.map((u, i) => ({
        value: u.id,
        label: nameHelpers.getFullName(u),
        firstName: u.firstName,
        lastName: u.lastName
    }))

    const handleResetUserSearch = () => {
        setSelectedUserId('')
        setSelectedUserLastName('')
        setSelectedUserFirstName('')
        setSelectedUserOption(null)

        deleteQuery(
            history, 
            location, 
            location.pathname, 
            [ID, FIRST_NAME, LAST_NAME], 
            true
        )
    }

    return  <React.Fragment>
        <FlexBox 
            flexDirection='row'
        >
            <PureSelect
                options={usersOptions}
                value={selectedUserOption}
                onChange={handleSelectUser}
                onInputChange={handleSearchUser}
                cssFlexBasis='400px'
                isSearchable={true}
                onBlur={ () => { setUserSearchActive(false) }}
                onFocus={ () => { setUserSearchActive(true) }}
                placeholder={ t('Filter for assignee')}
                loadingMessage={ () => (t('Searching for user'))}
                noOptionsMessage={ () => (t('No user found'))}
                isLoading={usersListLoading}
            />            

            <FlexBox 
                flexBasis='100%'
                paddingLeft='16px'
            >
                {
                    exists(selectedUserId) && <InfoBox direction='row' cssMargin='0'>
                        <span>{t(filterTitle)}:</span>
                        <strong>
                                "{
                                nameHelpers.getFullName(
                                    { 
                                        firstName: selectedUserFirstName, 
                                        lastName: selectedUserLastName 
                                    }
                                )
                            }"
                        </strong>
                        <Spacer />
                        <IconButton 
                            iconKey='close' 
                            width='12px' 
                            height='16px' 
                            onButtonClick={handleResetUserSearch} 
                        />
                    </InfoBox>
                }
            </FlexBox>
        </FlexBox>
        <PushBottom16/>
    </React.Fragment>

}

export default UserFilter