import React from 'react'
import { useForm } from 'react-hook-form'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PartialForms } from 'application/components/forms'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'

const AddGroup = (
    { 
        abort, 
        dialog, 
        setAbortBlocked, 
        setDataChanged,
        onSaveGroup 
    }
) => {
    const defaultValues = {
        name: '',
        description: ''
    }

    const { register, errors, handleSubmit, getValues } = useForm({defaultValues})

    const onSubmitSuccess = () => {
        onSaveGroup(dialog.name, getValues())
    }

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='column'>
                <PartialForms.GroupDataPartialForm
                    register={register}
                    errors={errors}
                    onChange={()=>{
                        setAbortBlocked(true)
                        setDataChanged(true)
                    }}
                />           
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Add user group'
        />
    </React.Fragment>

}

export default withDialogDataLoader(AddGroup)