import React from 'react'
const { useSteps } = require('application/components/hooks')
const { useTranslation } = require('react-i18next')



export const withCreateDamageSteps = (initialStepIndex = 0) => (Component) => (
    { ...props }
) => {
    const { t } = useTranslation()

    const initialSteps = [
        {
            id: 0,
            label: t('Create damage')
        },
        {
            id: 1,
            label: t('Edit customer (optional)')
        },
        {
            id:2,
            label: t('Insurance data (optional)')  
        }
    ]


    const {
        getStepsState, 
        goToNextStep,
        goToPreviousStep,
        getCurrentStep
    } = useSteps(initialSteps, initialStepIndex)


    return <Component 
        {...props} 
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        getCurrentStep={getCurrentStep}
        getStepsState={getStepsState}
    />
}