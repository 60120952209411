import styled from 'styled-components'

export const FullnameWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export const Gravatar = styled.div`
    flex: 0 0 70px;
    display: flex;
    justify-content: center;
`

export const GravatarCircle = styled.div`
    background-color: ${props => props.theme.color.primary};
    border-radius: 50%;
    height: 120px;
    text-align: center;
    width: 120px;
`

export const GravatarInitials = styled.span`
    font-size: calc(120px / 3);
    line-height: 1;
    position: relative;
    top: calc(120px / 3);
    color: ${props => props.theme.color.white};
`

export const Fullname = styled.h2`
    flex: 1 1 auto;
    color: ${props => props.theme.color.anthracite};
    font-size: ${props => props.theme.fontSize.headline3};
    font-weight: ${props => props.theme.fontWeight.headline3};
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    word-break: break-all;
`