import React from 'react'
import { nameHelpers } from 'application/common'

export const CustomerNameDamage = (
    { damage }
) => {
    const customer = damage.vehicle.keeper === undefined || damage.vehicle.keeper === null
        ? null
        : damage.vehicle.keeper

    if(customer === undefined || customer === undefined){
        return null
    }

    const customerName = customer.isCompany
        ? customer.companyName
        : nameHelpers.getFullName(customer)

    return <React.Fragment>
        {customerName}
    </React.Fragment>
}
