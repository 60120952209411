import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import Collapsable from 'application/components/controls/collapsable'
import { DamageForms } from 'application/components/forms'
import { withRouter } from 'react-router-dom'
import { FlexBox } from 'application/components/fragments/flex_box'



const TaskDamageVehicleProprietorForm = withRouter(
    ({
        widgetKey,
        payload, 
        loadingFailed, 
        onSave,
        match, 
        currentDamage, 
        theme
    }) => {
        const {t} = useTranslation()
        const {damageId} = match.params

        if(loadingFailed){
            return <WidgetErrorMessage>
                {t('Police data could not be loaded')}
            </WidgetErrorMessage>
        }

        return <FlexBox flexDirection='column'>
            <Collapsable 
                headline={t('Vehicle proprietor')} 
                initiallyCollapsed={true}
            >
                <DamageForms.DamageOpponentProprietorForm 
                    onSave={onSave}  
                    damageId={damageId}
                    formData={payload.formData}
                />
            </Collapsable>
        </FlexBox>
    })

TaskDamageVehicleProprietorForm.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { TaskDamageVehicleProprietorForm }