import { connect } from 'react-redux'
import DamageManagerDocumentsSidebar from './component'
import { MASS_OPERATIONS } from 'application/constants'
import { fetchMoByName } from 'application/components/building_blocks/mass_operations/helper_functions'
import { hideAttachmentDetails } from 'application/redux/actions/pages/damage_manager/documents'
import { changeAttachmentCategory } from 'application/redux/actions/common'



const mapStateToProps = (state) => {
    const damageId = state.pages.damageManager.documentCenter.damageId

    const mo = fetchMoByName(
        state,
        {
            moName: `${MASS_OPERATIONS.TYPE.DOCUMENTS}_${damageId}` 
        }
    )

    const atLeastOneMoIsActive = mo !== null && mo.isActive
    return {
        documentCenterAttachmentDetails: state.pages.damageManager.documentCenter.attachmentDetails,
        atLeastOneMoIsActive
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onHideAttachmentDetails: () => dispatch(
            hideAttachmentDetails()
        ),
        onChangeCategory: (
            damageId, 
            attachmentId, 
            category
        ) => dispatch(
            changeAttachmentCategory(
                damageId, 
                attachmentId, 
                category
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DamageManagerDocumentsSidebar)