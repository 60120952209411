import React, {useState} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
import { TabsButtons } from 'application/components/building_blocks/tabs'
import { TextInput, Select, DatePicker } from 'application/components/controls/form'
import { CustomerSearch } from 'application/components/controls/form/customer_search'
import YesNoSelect from 'application/components/controls/form/yes_no_select'
import DamageTypeSelect from 'application/components/controls/form/damage_type_select'
import { Footer } from 'application/components/dialogs/_common/footer'
import { nameHelpers } from 'application/common'
import useStatefulForm from 'application/components/hooks/use_stateful_form'
import TwoColumns from 'application/components/forms/_common'
import { USER_PERMISSONS, DAMAGE_TYPE } from 'application/constants'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import InfoBox from 'application/components/controls/info_box'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { TextButton } from 'application/components/fragments/typography'


const CreateDamageDekraComponent = (
    { 
        abort, 
        setAbortBlocked, 
        setDataChanged,
        onCreateDamage, 
        dialog,
        onSearchUser,
        currentBranch,
        onOpenChangeBranchDialog
    }
) => {
    const defaultValues = {
        isCompany: false,
        customerId: '',
        type: DAMAGE_TYPE.UNKNOWN
    }

    const [existingCustomer, setExistingCustomer] = useState(false)
    const [userSearchActive, setUserSearchActive] = useState(false) //to prevent unwanted server calls
    
    const {t} = useTranslation()
    
    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        setValue, 
        control, 
        reset, 
        formStateValues, 
        updateFormState
    } = useStatefulForm({ defaultValues })


    const {usersList, customerList} = dialog.payload
    
    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }
    
    const setForm = () => {
        onDataChange()
        updateFormState()
    }
    
    const onSubmitSuccess = () => { 
        onCreateDamage(
            dialog.name,
            {
                ...formStateValues, //include all values
                ...getValues()
            }
        )
    }

    const handleSearchUser = search => {
        if(userSearchActive && search.length > 2){
            onSearchUser(dialog.name, search)
        }  
    }

    const usersOptions = usersList.map((u, i) => ({
        value: u.id,
        label: nameHelpers.getFullName(u),
        firstName: u.firstName,
        lastName: u.lastName
    }))

    const handleSetExistingCustomer = isSet => {
        reset({defaultValues})
        setExistingCustomer(isSet)
    }

    const currentBranchName = currentBranch.name

    return <React.Fragment>
        <DialogMainContent >
            <PushBottom16/>
            <InfoBox 
                direction='row' 
                cssMargin='0' 
            >
                <FontIcon icon='info_outline' />
                <Spacer basis='10px' />
                <Trans i18nKey='This damage will be created in currentBranch'>
                    {{currentBranchName}} 
                </Trans>
                <Spacer />
                <TextButton onClick={() => onOpenChangeBranchDialog(dialog.name)}>&raquo;{t('Change branch')}</TextButton>
            </InfoBox>
            <PushBottom16/>
            <SubHeadline>{t('Vehicle & owner')}
                <TabsButtons
                    smallFont={true}
                    items={[
                        {
                            label: t('New customer'),
                            onItemClick: () => { handleSetExistingCustomer(false) }
                        },
                        {
                            label: t('Existing customer'),
                            onItemClick: () => { handleSetExistingCustomer(true) }
                        }
                    ]}
                />
            </SubHeadline>

            <TwoColumns 
                columnLeft={ 
                    <TextInput 
                        label={t('License plate')}
                        name='licensePlate'
                        register={register}
                        validate={{notEmpty: validationMethods.notEmpty}}
                        error={errors.licensePlate}
                        onChange={setForm}
                    />
                }
                columnRight={
                    !existingCustomer && <YesNoSelect 
                        register={register}
                        setValue={setValue}
                        initialValue={formStateValues.isCompany}
                        name='isCompany'
                        label={t('Is vehicle owned by company?')}
                        onChange={setForm}
                    />
                }
            />
            <ForCurrentBranchPermissions
                permissions={[
                    USER_PERMISSONS.USER.READ
                ]}
            >
                {
                    existingCustomer && <CustomerSearch 
                        customerList={customerList}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        stateForm={formStateValues}
                        onDataChange={setForm}
                        dialog={dialog}
                        isActive={existingCustomer}
                    />
                }
            </ForCurrentBranchPermissions>
            

            {
                !existingCustomer && <React.Fragment>
                    {
                        formStateValues.isCompany 
                            ? <TwoColumns 
                                columnLeft={
                                    <TextInput 
                                        label={t('Company name')}
                                        name='companyName'
                                        register={register}
                                        validate={{notEmpty: validationMethods.notEmpty}}
                                        error={errors.companyName}
                                        onChange={setForm}
                                    />
                                }
                            />

                            : <TwoColumns 
                                columnLeft={
                                    <TextInput 
                                        label={t('Firstname')}
                                        name='firstName'
                                        register={register}
                                        validate={{notEmpty: validationMethods.notEmpty}}
                                        error={errors.firstName}
                                        onChange={setForm}
                                    />
                                }
                                columnRight={
                                    <TextInput 
                                        label={t('Lastname')}
                                        name='lastName'
                                        register={register}
                                        validate={{notEmpty: validationMethods.notEmpty}}
                                        error={errors.lastName}
                                        onChange={setForm}
                                    />
                                }
                            /> 
                    }
                </React.Fragment>
            }

               




            <SubHeadline>{t('Damage information')}</SubHeadline>
            <TwoColumns 
                columnLeft={
                    <TextInput 
                        label={t('Job number')}
                        name='jobNumber'
                        register={register}
                        validate={null}
                        error={errors.jobNumber}
                        onChange={setForm}
                    />
                }
            />

            <TwoColumns 
                columnLeft={  
                    <DamageTypeSelect 
                        register={register}
                        setValue={setValue}
                        error={errors.type}
                        onChange={setForm}
                        initialValue={formStateValues.type}
                    />
                }
                columnRight={
                    <DatePicker 
                        label={t('Damage date')}
                        name='incidentDate'
                        validate={{notEmpty: validationMethods.notEmpty}}
                        error={errors.incidentDate}
                        onDateChange={setForm}
                        control={control}
                        disabledDays={{after: new Date()}}
                    />
                }
            />
            <TextInput 
                label={t('Damage description')}
                name='description'
                register={register}
                validate={null}
                error={errors.description}
                onChange={setForm}
            />


            <SubHeadline>{t('Assign damage')}</SubHeadline>
            <TwoColumns 
                columnLeft={
                    <Select 
                        label={t('Assign employee')}
                        name='assignedTo'
                        register={register}
                        setValue={setValue}
                        options={usersOptions}
                        error={errors.assignedTo}
                        validate={null}
                        menuPlacement='top'
                        onInputChange={handleSearchUser}
                        onBlur={()=>{setUserSearchActive(false)}}
                        onFocus={()=>{setUserSearchActive(true)}}
                        noOptionsMessage={t('No users found')}
                        placeholder={t('Enter at least 3 characters')}
                        isLoading={dialog.payload.usersListLoading}
                        onChange={setForm}
                    />
                }
            />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Create damage'
        />
    </React.Fragment>

}

export default withDialogDataLoader(CreateDamageDekraComponent)