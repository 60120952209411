import React, {useEffect} from 'react'

import InsuranceSelect from './component'

const InsuranceSelectEffects = (props) => {
    const { insurancesLoaded, insurancesLoading, onGetInsurances } = props
    const shouldLoad = !insurancesLoaded && !insurancesLoading

    useEffect(() => {
        shouldLoad && onGetInsurances()
    }, [onGetInsurances, shouldLoad])

    return <InsuranceSelect {...props} />
}

export default InsuranceSelectEffects