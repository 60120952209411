import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExternalTaskMarker } from 'application/components/controls/external_task_marker'

export default ({task}) => {
    const {t} = useTranslation()
    const creatorName = task.creator?.name || t('System')
    return <React.Fragment>
        <ExternalTaskMarker task={task} />
        {creatorName}
    </React.Fragment>
}