import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { FormatCurrencyDE } from 'application/common/currency_helpers'
import DateComponent from 'application/components/controls/date'
import LocalTime from 'application/components/controls/local_time'
import { exists } from 'application/common/data_helpers'

const PaymentOption = ({payment}) => {
    const { t } = useTranslation()
    const {payedAt, amount, createdAt} = payment
    return <React.Fragment>
        {t('Amount')}: <strong>{FormatCurrencyDE(amount)}</strong>,&nbsp;
        {t('Payment date')}: <strong><DateComponent date={payedAt} /></strong>,&nbsp;
        {t('Payment created at')}: <strong><DateComponent date={createdAt} />, <LocalTime date={createdAt} /></strong>
    </React.Fragment>
}

const PaymentSelect = ({
    payments,
    name,
    register,
    setValue,
    error,
    menuPlacement = 'bottom',
    label = 'Cancel payment',
    filter = y => !exists(y.cancelledAt),
    onChange
}) => {
    const { t } = useTranslation()

    const paymentOptions = payments.filter(filter).map((p, i) => {
        return {
            value: p.id,
            label: <PaymentOption payment={p} />,
            ...p
        }
    })

    return <Select
        defaultValue={null}
        label={t(label)}
        name={name}
        register={register}
        setValue={setValue}
        options={paymentOptions}
        error={error}
        validate={{notEmpty: validationMethods.notEmpty}}
        menuPlacement={menuPlacement}
        onChange={onChange}
    />
}

PaymentSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string,
    onChange: PropTypes.func,
}

export default PaymentSelect