import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getInsurancesRequest } from 'application/redux/actions/common'

const mapStateToProps = state => ({
    insurancesLoaded: state.common.insurancesLoaded,
    insurancesLoading: state.common.insurancesLoading,
    auth: state.auth,
})

const mapDispatchToProps = dispatch => ({ 
    onGetInsurances: () => dispatch(getInsurancesRequest()),
})

const withInsurancesLoaderComponent = Component => ({ ...props }) => {
    const {auth, insurancesLoaded, insurancesLoading, onGetInsurances} = props

    useEffect(() => { 
        auth.loggedIn 
        && !insurancesLoaded 
        && !insurancesLoading 
        && onGetInsurances() 
    }, [auth.loggedIn, insurancesLoaded, insurancesLoading, onGetInsurances])

    return <Component {...props} />
}

export const withInsurancesLoader = compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    withInsurancesLoaderComponent
)