import styled from 'styled-components'

export const StyledCustomerQrCodeList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const StyledCustomerQrCodeElement = styled.div`
    border: 1px solid ${props => props.theme.color.gray15};
    margin-bottom: 12px;
    min-height: 100px;
    flex: 0 1 32.4%;
    display: flex;
    justify-content: space-between;
`

export const QrCodeWrapper = styled.div`
    width: 200px;
    height: 200px;

    & canvas{
        width: inherit !important;
        height: inherit !important;
    }
`