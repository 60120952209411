import React from 'react'
import PropTypes from 'prop-types'
import styled, {withTheme} from 'styled-components'
import { LicensePlate } from 'application/components/controls/license_plate'
import { MakeModel } from 'application/components/controls/make_model'

const StyledVehicleInfo = styled.div`
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.color.anthracite};
    display: flex;
    align-items: center;
    &>*{
        margin-right: 4px;
    }
`

const VehicleInfoComponent = (
    { 
        damageLoaded,
        vehicle = null
    }
) => {

    if(!damageLoaded){
        return <React.Fragment />
    }
    
    const make = vehicle?.make || ''
    const model =  vehicle?.model || ''
    const licensePlate = vehicle?.licensePlate || ''

    return <StyledVehicleInfo>
        <LicensePlate 
            licencePlate={licensePlate} 
        />
        <MakeModel 
            make={make} 
            model={model} 
        />
    </StyledVehicleInfo>
}

VehicleInfoComponent.propTypes = {
    damageLoaded: PropTypes.bool.isRequired,
    vehicle: PropTypes.object
}

export const VehicleInfo = withTheme(VehicleInfoComponent)