//key should always be a usable englisch strings

export const de = {
    translation: {
        //cross cutting
        'Login': 'Anmelden',
        'today': 'heute',
        'User': 'Benutzer',
        'Log out': 'Abmelden',
        'Damage': 'Schaden',
        'Edit': 'Bearbeiten',
        'Finish': 'Beenden',
        'Abort': 'Abbrechen',
        'Cancel': 'Abbrechen',
        'Discard changes?': 'Änderungen verwerfen?',
        'Yes': 'Ja',
        'No': 'Nein',
        'Assignee': 'Bearbeiter',
        'last change': 'letzte Änderung',
        'Search': 'Suche',
        'Active search': 'Aktive Suche',
        'Documents': 'Dokumente',
        'Category': 'Kategorie',
        'Save damage data': 'Schadendaten speichern',
        'Uploading files': 'Dateien werden hochgeladen',
        'Loading': 'Lädt',
        'Without category': 'Ohne Kategorie',
        'Hide': 'Ausblenden',
        'Delete': 'Löschen',
        'Delete damage': 'Schaden löschen',
        'Download file': 'Datei herunterladen',
        'No preview possible for': 'Keine Vorschau möglich für',
        'Task': 'Aufgabe',
        'Damage has been created': 'Schaden wurde angelegt',
        'Damage created': 'Schaden angelegt',
        'Your user is being activated': 'Benutzer wird aktiviert',
        'Creation date': 'Erstelldatum',
        'Email': 'E-Mail',
        'Permission collection': 'Rechtesammlung',
        'Save': 'Speichern',
        'Deadline': 'Frist',
        'no options': 'keine Treffer',
        'has been successfully deleted': 'wurde gelöscht',
        'has been successfully created': 'wurde angelegt',
        'Document deleted': 'Dokument gelöscht',
        'Download all': 'Alle herunterladen',
        'jump to task': 'zu Aufgabe wechseln',
        'jump to invoices': 'zu Rechnungen wechseln',
        'Copy address': 'Adresse kopieren',
        'from': 'von',
        'to': 'an',
        'to damage': 'zum Schaden',
        'New customer': 'Neuer Kunde',
        'Existing customer': 'Bestandskunde',
        'Search for existing customer': 'Bestandskunde suchen',
        'do you want to delete this user?': 'Möchten Sie den Benutzer "<1>{{fullName}}</1>" löschen?',
        //date + time
        'at': 'um',
        'h': '',
        //login page
        'username': 'Benutzername',
        'password': 'Passwort',
        'Enter the PIN code': 'PIN eingeben',
        'login': 'Anmelden',
        'Start': 'Fortfahren',
        'Wrong PIN': 'Falsche PIN',
        'legal notice': 'Impressum',
        'privacy': 'Datenschutz',
        'conditions': 'AGB',
        //navigation
        'Overview': 'Übersicht',
        'Damages': 'Schäden',
        'My tasks': 'Meine Aufgaben',
        'Inbox': 'Posteingang',
        'Administration': 'Administration',
        //tab titles
        'User management': 'Benutzerverwaltung',
        'Settings': 'Einstellungen',
        'My damages': 'Meine Schäden',
        'All damages': 'Alle Schäden',
        'Archived damages': 'Archiviert',
        'Damage processing': 'Schadenabwicklung',
        'Documents & Pictures': 'Dokumente & Fotos',
        'Messages': 'Nachrichten',
        //widgets
        'Edit widgets': 'Widgets bearbeiten',
        'Quit editing widgets': 'Widgets nicht mehr bearbeiten',
        'add widget': 'Widget hinzufügen',
        'add repair authorization widget': 'Letzte Freigaben-Widget hinzufügen',
        'reset widgetboard': 'Standard Widgets',
        'Error': 'Fehler',
        //widgets dashboard
        'last tasks': 'Letzte Aufgagen',
        'last damages': 'Letzte Schäden',
        'last repair authorization': 'Freigaben',
        'data could not be loaded': 'Daten konnten nicht geladen werden',
        //widgets damages
        'Vehicle owner': 'Eigentümer des Fahrzeugs',
        'vehicle owner could not be loaded': 'Fahrzeughalter konnte nicht geladen werden',
        'Vehicle': 'Fahrzeug',
        'vehicle could not be loaded': 'Fahrzeugdaten konnten nicht geladen werden',
        'Insurances': 'Versicherungen',
        'Damage report': 'Schadenmeldung',
        'Accident report': 'Unfallmeldung',
        'Car insurance': 'KFZ Versicherung',
        'Opponent car insurance': 'Gegnerische KFZ Versicherung',
        'Legal protection insurance': 'RS Versicherung',
        'Victim': 'Geschädigter',
        'Opponent': 'Gegner',
        'Own fault': 'Eigenverschuldung',
        'Tasks': 'Aufgaben',
        'Participants': 'Beteiligte',
        'Activity log': 'Aktivitäten',
        'Activities': 'Aktivitäten',
        'Project participants': 'Projektbeteiligte',
        'No project participants at the moment': 'Zurzeit keine Projektbeteiligte',
        'No task at the moment': 'Zurzeit keine Aufgaben',
        'Reset layout': 'Layout zurücksetzen',
        'Repair approval': 'Reparaturfreigabe',
        'Repair approval state changed': 'Reparaturfreigabe geändert',
        'Editor for this damage is changed': 'Schadenbearbeiter geändert',
        'Approve repair': 'Reparaturfreigabe erteilen',
        'Disapprove repair': 'Reparaturfreigabe nicht erteilen',
        'Reset repair approval': 'Reparaturfreigabe zurücksetzen',
        'Repair': 'Reparatur',
        'Location': 'Ort',
        'Date': 'Datum',
        'Policyholder': 'Versicherungsnehmer',
        //damage manager
        'To dashboard': 'Zum Dashboard',
        'To damage list': 'Zur Schadenliste',
        'Basic data acquisition': 'Grunddaten erfassen',
        'Create damage report': 'Schadenmeldung erstellen',
        'Create accident report': 'Unfallmeldung erstellen',
        'Case notes': 'Fallnotiz',
        'Store case notes': 'Fallnotiz hinterlegen',
        'Edit case notes': 'Fallnotiz bearbeiten',
        'No documents in this category': 'Keine Dokumente in dieser Kategorie',
        'Task saved': 'Aufgabe bearbeitet',
        'Vehicle data saved': 'Fahrzeug gespeichert',
        'Vehicle owner data saved': 'Fahrzeughalter gespeichert',
        'Accident data saved': 'Unfallgeschehen gespeichert',
        'Witness data saved': 'Zeuge gespeichert',
        'Police data saved': 'Polizeidaten gespeichert',
        'Lawyer communication': 'Anwaltskommunikation',
        'Share': 'Teilen',
        'SmartConnect': 'SmartConnect',
        'Copy': 'Kopieren',
        'Copied': 'Kopiert',
        //task states
        'State': 'Status',
        'Unassigned': 'Nicht zugewiesen',
        'Assigned': 'Zugewiesen',
        'Released': 'Freigegeben',
        'Forwarded': 'Weitergeleitet',
        'Accepted': 'Weiterleitung angenommen',
        'Rejected': 'Weiterleitung abgelehnt',
        'In review': 'Zu prüfen',
        'Done': 'Fertig',
        'within': 'in',
        //dialog titles + subheadlines + buttons
        'Create damage case': 'Schaden anlegen',
        'Create Task': 'Aufgabe erstellen',
        'Vehicle & owner': 'Fahrzeug & Halter/Kunde',
        'Create damage': 'Schaden anlegen',
        'Damage information': 'Schadeninformationen',
        'Assign damage': 'Schaden zuweisen',
        'Assign category': 'Kategorie wählen',
        'Please select a category for these attachments.': 'Bitte ordnen Sie den folgenden Dokumenten eine passende Kategorie und Aufgabe zu.',
        'Damage saved': 'Schadendaten gespeichert',
        'Do you want to delete this document?': 'Wollen Sie dieses Dokument löschen?',
        'Delete document': 'Dokument löschen',
        'Save task': 'Aufgabe speichern',
        'Create User': 'Benutzer anlegen',
        'Edit user': 'Benutzer bearbeiten',
        'Delete user': 'Benutzer löschen',
        'Delete message': 'Nachricht löschen',
        'has been saved': 'wurde gespeichert',
        'Task created': 'Aufgabe erstellt',
        //validation messages
        'This field is required': 'Pflichtfeld',
        'Valid phone number required': 'Gültige Telefonnummer erforderlich',
        'Valid mobile number required': 'Gültige Mobilfunknummer erforderlich',
        'Valid E-Mail-Adress required': 'Gültige E-Mail-Adresse erforderlich',
        //form labels
        'License plate': 'KFZ Kennzeichen',
        'Mobile phone number': 'Mobilfunknummer',
        'Order number': 'Auftragsnummer',
        'Insurance number': 'VU Nr',
        'Type of insurance': 'Versicherungstyp',
        'Firstname': 'Vorname',
        'Lastname': 'Nachname',
        'Company': 'Firma',
        'Job number': 'Auftragsnummer',
        'Damage type': 'Schadentyp',
        'Damage description': 'Schadenbeschreibung',
        'Assign employee': 'Mitarbeiter zuweisen',
        'Damage date': 'Schadendatum',
        'Case name': 'Fallname',
        'Enter at least 3 characters': 'Bitte mindestens 3 Buchstaben eingeben',
        'No users found': 'Keine Benutzer gefunden',
        'Priority': 'Priorität',
        'Low priority': 'Niedrige Priorität',
        'Medium priority': 'Mittlere Priorität',
        'High priority': 'Hohe Priorität',
        'Description': 'Beschreibung',
        'Title': 'Titel',
        'Search employee': 'Mitarbeiter suchen',
        'More customer data': 'Ergänzende Kundendaten',
        'More': 'Mehr',
        'E-Mail': 'E-Mail',
        'Phone number': 'Telefonnummer',
        'Phone number (mobile or landline)': 'Telefonnummer (Mobil oder Festnetz)',
        'Phone': 'Tel',
        'Eligible for input tax deduction': 'VSt-abzugsberechtigt',
        'Deductible': 'Selbstbeteiligung',
        'Data customer vehicle': 'Daten Kundenfahrzeug',
        'Vehicle identification number': 'Fahrzeugidentnummer (FIN)',
        'Vehicle brand': 'Fahrzeugmarke',
        'Vehicle model': 'Fahrzeugmodell',
        'Leased': 'Fahrzeug geleast',
        'Financed': 'Fahrzeug finanziert',
        'Contract number': 'Vertragsnummer',
        'Personal injury': 'Personendamage',
        'Accident situation': 'Unfallgeschehen',
        'Accident location': 'Unfallort',
        'Accident date': 'Unfalldatum',
        'Accident description': 'Unfallhergang',
        'Chassis number': 'Fahrgestellnummer',
        'Witness': 'Zeuge',
        'First name': 'Vorname',
        'Last name': 'Nachname',
        'Street': 'Straße',
        'House number': 'Hausnummer',
        'Postal code': 'PLZ',
        'City': 'Ort',
        'Accident opponent': 'Unfallgegner',
        'Police': 'Polizei',
        'Police group': 'Dienststelle',
        'File number': 'Aktenzeichen/Tagebuchnummer',
        'File number lawyer': 'Aktenzeichen',
        'Police involved': 'Polizeilich aufgenommen',
        'Insurance certificate number': 'Versicherungsscheinnummer',
        'Insurance': 'Versicherung',
        'Save accident data': 'Unfallgeschehen speichern',
        'Save vehicle data': 'Fahrzeugdaten speichern',
        'Save police data': 'Polizeidaten speichern',
        'Save witness data': 'Zeugendaten speichern',
        'Save opponent data': 'Unfallgegner speichern',
        'Save vehicle owner data': 'Fahrzeughalter speichern',
        'Is vehicle owned by company?': 'Firmenfahrzeug?',
        'Company name': 'Firmenname',
        'Without task': 'Ohne Aufgabe',
        'start typing or select': 'Suche eingeben oder auswählen',
        //damage types
        'Unknown': 'Unbekannt',
        'Liability': 'Haftpflicht',
        'Casco': 'Kasko', //???
        'Partial Casco': 'Teilkasko',
        'Glas damage': 'Glasdamage',
        'Damaged own vehicle': 'Eigenes Fahrzeug beschädigt',
        'Self payer': 'Eigenzahler',
        //inbox
        'Inbox is empty': 'Der Posteingang ist leer',
        'Do you want to delete this task?': 'Möchten Sie diese Aufgabe löschen?',
        'Message has been successfully sent': 'Nachricht wurde erfolgreich gesendet',
        'Message': 'Nachricht',
        //task + uploader
        'Edit task': 'Aufgabe bearbeiten',
        'Delete task': 'Aufgabe löschen',
        'Upload documents': 'Dokumente hochladen',
        'Upload complete': 'Dokument hochgeladen',
        'Upload more documents': 'Weitere Dokumente hochladen',
        'drag your files here to upload them': 'Ziehen Sie Ihre Dokumente hierher, um sie hochzuladen',
        'Close task': 'Aufgabe abschließen',
        'Done task': 'Aufgabe erledigen',
        'Task is done by lawyer': 'Aufgabe erfolgreich abgeschlossen',
        'Waiting action from lawyer': 'In Prüfung',
        'Reopen task': 'Aufgabe erneut öffnen',
        'do you want to delete this damage?': 'Möchten Sie den Schaden mit dem Kennzeichen <1>{{licenseplate}}</1> löschen?',
        'Do you have a comment?': 'Sie haben einen Kommentar?',
        'Write an information for this task': 'Schreiben Sie eine Info zur Aufgabe',
        'Save comment': 'Kommentar speichern',
        'Comment saved': 'Kommentar gespeichert',
        'Comments': 'Kommentare',
        'This task has not been assigned yet': 'Aufgabe noch nicht zugewiesen',
        'This task has not been done yet by car dealer': 'Aufgabe wurde vom Autohaus noch nicht erledigt',
        'External': 'Extern',
        'Category saved': 'Kategorie gespeichert',
        'assign to me': 'mir zuweisen',
        //project beteiligte
        'Lawyers': 'Anwälte',
        'Lawyer': 'Anwalt',
        'Experts': 'Gutachter',
        'Expert': 'Gutachter',
        'Commission lawfirm': 'Anwaltskanzlei beauftragen',
        'Choose lawfirm': 'Anwaltskanzlei auswählen',
        'Add experts': 'Gutachter hinzufügen',
        'Change lawfirm': 'Anwaltskanzlei wechseln',
        'Change Experts': 'Gutachter wechseln',
        'Lawyer commissioned': 'Anwalt beauftragt',
        'Expert added': 'Gutachter hinzugefügt',
        'Send commission': 'Beauftragung senden',
        'Confirm change': 'Änderung bestätigen',
        //my tasks
        'To damage manager': 'Zum Schadenmanager',
        'Editor': 'Bearbeiter',
        //workflow panel
        'Your message': 'Ihre Nachricht',
        'Unauthorized': 'Zugriff verweigert',
        'Create Message': 'Nachricht zum Vorgang an das Autohaus schicken',
        'Akten status': 'Aktenstatus',
        'Akten status type': 'Aktenstatus auswählen',
        'General communication': 'Allgemeinekommunikation',
        'Liability request': 'Haftungsanfrage',
        'Receipt confirmation': 'Eingangsbestätigung',
        'Repair clearance': 'Reparaturfreigabe',
        'Other': 'Sonstiges',
        'Power of attorney': 'Vollmacht',
        'Rental car repair invoice': 'Mietwagen Reparaturrechnung',
        'Please send repair invoice': 'Bitte Reparaturrechnung übersenden',
        'Investigation file is available': 'Ermittlungsakte liegt vor',
        'Reminder of opposing insurance': 'Erinnerung an gegnerische Versicherung',
        'Appraise figuration': 'Bezifferung gutachten',
        'First letter': 'Erste anschreiben',
        'Request for expert opinion': 'Anfrage wegen gutachten',
        'Reminder to dealership': 'Erinnerung an Autohaus',
        'Repair bill and repair time': 'Reparaturrechnung und Reparaturdauer',
        'Repair duration certificate': 'Reparaturdauer Bescheinigung',
        'Repair schedule': 'Reparaturablaufplan',
        'Opened': 'Offen',
        'Closed': 'Geschlossen',
        'Open': 'öffnen',
        'Close': 'schließen',
        'Not clear': 'Unklar',
        'Approved': 'Bestätigt',
        'Not approved': 'Nicht bestätigt',
        'Missing written notification of the opponent': 'Fehlende schriftliche Anzeige des Gegners',
        'Check by Opposing Insurance Not concluded': 'Prüfung durch gegnerische Versicherung nicht abgeschlossen',
        'Investigation file has been requested': 'Ermittlungsakte wurde angefordert',
        'Liability Rejected': 'Haftung abgelehnt',
        'Liability quota': 'Haftungsquote',
        'Green card': 'Grüne Karte',
        'Missing accident report': 'Fehlende Unfallmeldung',
        'Confirmation of liability': 'Haftungsbestätigung',
        'Information refused': 'Auskunft verweigert',
        'Foreign participation': 'Auslandsbeteiligung',
        'VU in DE': 'VU in DE',
        'VU abroad': 'VU im Ausland',
        'Grant': 'Erteilt',
        'Do not grant': 'Nicht erteilt',
        'Repair invoice': 'Reparaturrechnung',
        'Rental car invoice': 'Mietwagenrechnung',
        'Towing costs': 'Abschleppkosten',
        'Expert costs': 'Sachverständigenkosten',
        'Other invoice': 'Sonstige Rechnung',
        'Repair costs notional': 'Reparaturkosten Fiktiv',
        'Flat rate fee': 'Kostenpauschale',
        'Depreciation': 'Wertminderung',
        'Loss of use': 'Nutzungsausfall',
        'Invoice': 'Rechnung',
        'Invoices': 'Rechnungen',
        'Too much': 'Zu viel bezahlt',
        'Open amount': 'Offen',
        'Since': 'Seit',
        'Last paid': 'Letzte Zahlung',
        'Add payment': 'Auszahlung anlegen',
        'Cancel payment': 'Auszahlung stornieren',
        'Delete invoice': 'Rechnung löschen',
        'Payment': 'Ausgezahlt',
        'No invoices at the moment': 'Es sind noch keine Rechnungen angelegt',
        'Create invoice': 'Rechnung anlegen',
        'Add invoice': 'Rechnung anlegen',
        'Customer number': 'Kundennummer',
        'Bill number': 'Rechnungsnummer',
        'Total (Gross) in €': 'Betrag (Brutto) in €',
        'Total (Nett) in €': 'Betrag (Netto) in €',
        'Invoice is created': 'Rechnung erstellt',
        'Payment is cancelled': 'Auszahlung wird storniert',
        'Amount': 'Betrag',
        'Amount in €': 'Betrag in €',
        'Payment date': 'Auszahlungsdatum',
        'Payment type': 'Umfang der Auszahlung',
        'Successfull': 'Komplett erfolgt',
        'Partially done': 'Teilweise erfolgt',
        'do you want to delete this invoice?': 'Möchten Sie diese Rechnung löschen?',
        'Cancellation': 'Storno',
        'Save receipt confirmation': 'Eingangsbestätigung speichern',
        'Save liability inquiry': 'Haftungsanfrage speichern',
        'Save general communication': 'Generalkommunikation speichern',
        'Save Akten status': 'Aktenstatus speichern',
        'Save repair clearance': 'Reparaturfreigabe speichern',
        'Today': 'Heute',
        'Last week': 'Letzte Woche',
        'Last month': 'Letzter Monat',
        'Older': 'Älter',
        'No activities': 'Keine Aktivitäten',
        'More activities': 'weitere Aktivitäten',
        'Send': 'Senden',
        'Form': 'Vorgangsdaten',
        'Activity Log': 'Aktivitäten',
        'Subject': 'Betreff',
        'Details of the firm': 'Angaben Kanzlei',
        'Contact person': 'Ansprechpartner',
        'Information insurance': 'Angaben Versicherung',
        'Claim number': 'Schadennummer',
        'Comment': 'Kommentar',
        'Liability type': 'Haftungsstatus',
        'Liability request type': 'Anfragedetail',
        'Payment is created': 'Auszahlung erstellt',
        'Delete payment': 'Rechnung löschen',
        'Receipt confirmation data saved': 'Eingangsbestätigung gespeichert',
        'Repair clearance data saved': 'Reparaturfreigabe gespeichert',
        'Case status data saved': 'Aktenstatus gespeichert',
        'Liability request data saved': 'Haftungsanfrage gespeichert',
        'Account name': 'Name des Kontos',
        'Repair clearance type': 'Freigabestatus auswählen',
        'General communication type': 'Rechnungsart',
        'Task has been successful edited': 'Aufgabe wurde erfolgreich bearbeitet',
        //Anwaltskommunikation
        'No information regarding receipt confirmation yet': 'Es liegt noch keine Eingangsbestätigung der Kanzlei vor',
        'No information regarding repair clearance yet': 'Es liegen noch keine Informationen zur Reparaturfreigabe vor',
        'No information regarding liability request yet': 'Es liegen noch keine Informationen zur Haftung vor',
        'No information regarding file status yet': 'Es liegen noch keine Informationen zum Aktenstatus der Kanzlei vor',
        //registration
        'Create user account': 'Benutzerkonto erstellen',
        'Activate account with existing user': 'Konto mit existierendem Benutzer aktivieren',
        'Login with existing user': 'Mit existierendem Benutzer anmelden',
        'Login with new user': 'Mit dem erstellten Benutzer anmelden',
        'Activate account': 'Konto aktivieren',
        'Name of car dealership': 'Name des Autohauses',
        //waiting screen messages
        'Creating task': 'Aufgabe wird erstellt',
        'Creating user': 'Benutzer wird angelegt',
        'Creating payment': 'Auszahlung wird erstellt',
        'Creating damage': 'Schaden wird angelegt',
        'Creating message': 'Nachricht wird erstellt',
        'Assigning lawyer': 'Anwalt wird hinzugefügt',
        'Creating invoice': 'Rechnung wird erstellt',
        'Assigning expert': 'Gutachter wird hinzugefügt',
        'Creating case note': 'Fallnotiz wird erstellt',
        'Payment is being cancelled': 'Auszahlung wird storniert',
        'Deleting document': 'Dokument wird gelöscht',
        'Deleting invoice': 'Rechnung wird gelöscht',
        'Deleting message': 'Nachricht wird gelöscht',
        'Deleting damage': 'Schaden wird gelöscht',
        'Deleting task': 'Aufgabe wird gelöscht',
        'Deleting user': 'Benutzer wird gelöscht',
        'Saving task': 'Aufgabe wird gespeichert',
        'Saving user': 'Benutzer wird gespeichert',
        //empty lists
        'No tasks found': 'Noch keine Aufgaben vorhanden',
        'No damages found': 'Noch keine Schäden vorhanden',
        'No repair approvals found': 'Noch keine Reparaturfreigaben vorhanden',
        'Invalid files have been removed': 'Ungültige Dateien wurden entfernt',
        //customer page
        'Customers': 'Kunden',
        'Saving customer': 'Kunde wird gespeichert',
        'Creating customer': 'Kunde wird erstellt',
        'Edit customer': 'Kunde bearbeiten',
        'Delete customer': 'Kunde löschen',
        'Private customer': 'Privatkunde',
        'No customers found': 'Keine Kunden gefunden',
        'Select an existing customer': 'Wählen Sie einen Bestandskunden',
        'Deleting customer': 'Kunde wird gelöscht',
        'do you want to delete this customer?': 'Möchten Sie den Kunden "<1>{{fullName}}</1>" löschen?',
        'Customer type': 'Kundenart',
        'Warning': 'Warnung',
        'If you delete a customer, all associated Cases will be deleted, too.': 'Wenn Sie einen Kunden löschen, werden alle verbundenen Schäden ebenfalls gelöscht.',
        'No information regarding invoices yet': 'Keine Rechnungsdaten verfügbar',
        'If you delete this case, also tasks, documents and invoices will be deleted.': 'Zusammen mit dem Schaden werden auch die zugehörigen <1>Aufgaben, Dokumente und Rechnungen</1> gelöscht.',
        'Download documents': 'Dokumente herunterladen',
        'All tasks': 'Alle Aufgaben',
        'Creator': 'Ersteller',
        'external': 'extern',
        'Showing tasks for': 'Zeige Aufgaben für',
        'Filter for assignee': 'Nach zugewiesenem Benutzer filtern',
        'Searching for user': 'Suche Benutzer',
        'No user found': 'Kein Benutzer gefunden',
        'Store PIN?': 'PIN speichern?',
        'Controlling': 'Controlling',
        'Vacant positions': 'Offene Posten',
        'Statistics': 'Statistik',
        'Paid off': 'Ausgezahlt',
        'Invoices total amount': 'Rechnungsbeträge gesamt',
        'Total': 'Gesamt',
        'No data available': 'Keine Daten verfügbar',
        'Damages list': 'Schadenliste',
        'Show invoices': 'Rechnungen ansehen',
        'Showing damages assigned to': 'Zeige Schäden für Bearbeiter',
        'Damages with vacant positions': 'Schäden mit offenen Posten',
        'no date selected': 'Kein Datum ausgewählt',
        'Unexpected error': 'Unerwarteter Fehler',
        'no invoices': 'Bisher keine Rechnungen',
        'Last build': 'Letzer build',
        'damages overall': 'Schäden insgesamt',
        'liability damages': 'Haftpflichtschäden',
        'casco damages': 'Kaskoschäden',
        'Created damages': 'Erstellte Schäden',
        'Show created damages from': 'Zeige erstellte Schäden von',
        'until': 'bis',
        'Start date must be before end date': 'Startdatum muss vor Enddatum liegen',
        'Payed': 'Bezahlt',
        'Period must be smaller': 'Der Zeitraum muss kleiner als <1>{{maxPeriod}}</1> Tage sein',
        'Registered': 'Registriert',
        'Invited': 'Eingeladen',
        'Note': 'Hinweis',
        'This user has not accepted the invitation yet.': 'Dieser Benutzer hat die Einladung noch nicht angenommen.',
        'Send invitation again': 'Einladung erneut senden',
        'Invitation sent': 'Einladung gesendet',
        'Last update': 'Letzes Update',
        'e.sy 360 is a product of e.Consult AG': 'e.sy 360 ist ein Produkt der e.Consult AG',
        'to finish the registration, please create a new e.Consult account.': 'um die Registrierung abzuschließen, erstellen Sie bitte ein neues Benutzerkonto bei der e.Consult AG.',
        'Welcome': 'Herzlich willkommen',
        'Last notifications': 'Letzte Benachrichtigungen',
        'jump to damage': 'zu Schaden wechseln',
        'new': 'neu',
        'GeschaedigterHalter': 'Fahrzeughalter',
        'Eingangsbestaetigung': 'Eingangsbestätigung',
        'Document': 'Dokument',
        'Document name': 'Name des Dokuments',
        'jump to message': 'zu Nachricht wechseln',
        'AktenstatusAendern': 'Aktenstatus',
        'show all invoices': 'alle Rechnungen anzeigen',
        'hide all invoices': 'alle Rechnungen verbergen',
        'Error establishing realtime connection': 'Echtzeitverbindung fehlgeschlagen',
        'UnfallgegnerHalter': 'Unfallgegner',
        'Notifications': 'Benachrichtigungen',
        'Time': 'Uhrzeit',
        'Action': 'Aktion',
        'Connections': 'Verbindungen',
        'show all notifications': 'alle Benachrichtigungen anzeigen',
        'There are new notifications available': 'Sie haben neue Benachrichtigungen',
        'Show': 'Anzeigen',
        'Refresh': 'Aktualisieren',
        'Invoice amount': 'Rechnungsbetrag',
        'Invoice created by': 'Rechnung erstellt von',
        'Payments': 'Auszahlungen',
        'canceled': 'storniert',
        'canceled at': 'storniert am',
        'bill number': 'Rechnungsnummer',
        'invoice type': 'Rechnungstyp',
        'Created': 'Erstellt',
        'by': 'von',
        'Whats`s new?': 'Was ist neu?',
        'With reduction': 'Mit Kürzung erfolgt',
        'Reason for reduction': 'Kürzungsgrund',
        'Free designation': 'Freie Angabe',
        'This field is limited to 50 characters': 'Dieses Feld ist auf 50 Zeichen beschränkt',
        'not known': 'nicht bekannt',
        'Open tasks': 'Offene Aufgaben',
        'Closed tasks': 'Erledigte Aufgaben',
        'All': 'Alle',
        'Switch account': 'Unternehmen wechseln',
        'Login with existing e.Consult account': 'Mit bestehendem e.Consult Konto anmelden',
        'Welcome to e.sy 360!': 'Herzlich willkommen bei e.sy360!',
        'Please select a car dealer for your digital damage processing.': 'Bitte wählen Sie hier das Autohaus/Autohausgruppe bzw. Werkstatt aus, in welchem Sie die digitale Schadenbearbeitung durchführen möchten.',
        'Payment created at': 'Auszahlung erstellt am',
        'If you delete this user, all existing connections to damage cases will be removed.': 'Sollten diesem Benutzer noch Fälle oder Aufgaben zugewiesen sein, wird diese Zuordnung ebenfalls entfernt.',
        'without subject': 'ohne Betreff',
        'Customer': 'Kunde',
        'Customer / owner': 'Kunde / Halter',
        'within criteria': 'in Kriterium',
        'show all damage cases': 'Alle Schäden anzeigen',
        'You are in multi selection mode': 'Sie befinden sich in der Mehrfachauswahl:',
        'Multiselectionmode': 'Mehrfachauswahl',
        'Selectionmode is loading...': 'Auswahlmodus wird geladen',
        'No elements have been selected yet': 'Es wurden keine <1>{{designation}}</1> ausgewählt. Bitte wählen sie diese durch Anklicken aus.',
        'One element is selected. Wich action would you apply to your selection?': 'Ein  <1>{{designation}}</1> ist ausgewählt. Welche Aktion möchten Sie auf dieses anwenden?',
        'elements are selected. Wich action would you apply to your selection?': ' <1>{{designation}}</1> sind ausgewählt. Welche Aktion möchten Sie auf diese anwenden?',
        'Choose action': 'Aktion auswählen',
        'Apply': 'Anwenden',
        'Change category': 'Kategorie ändern',
        'Should_documents_be_deleted': 'Sollen die <1>{{documentsCount}}</1> Dokumente gelöscht werden?',
        'Deleting documents': 'Dokumente werden gelöscht',
        'Documents deleted': 'Dokumente wurden gelöscht',
        'Should_category_of_documents_be_changed': 'Soll die Kategorie der folgenden <1>{{documentsCount}}</1> Dokumente geändert werden?',
        'Please select new category': 'Bitte die neue Kategorie auswählen',
        'Should_category_of_documents_be_changed_into': 'Soll die Kategorie der folgenden <1>{{documentsCount}}</1> Dokumente in "<2>{{selectedCategoryName}}</2>" geändert werden?',
        'Changing category...': 'Ändere Kategorie...',
        'Category changed': 'Kategorie geändert',
        'Without costumer': 'Ohne Kunde',
        'This resource is not valid anymore': 'Dieser Link ist nicht mehr gültig',
        'Checking data...': 'Überprüfe Daten ...',
        'Group': 'Benutzergruppe',
        'User group': 'Benutzergruppe',
        'Branch': 'Filiale',
        'Content restricted to branch': 'Angezeigt werden nur Inhalte für die Filiale',
        'permission collection': 'Rechtesammlung',
        'user group': 'Benutzergruppe',
        'branch': 'Filiale',
        'Branch management': 'Verwaltung Filialen',
        'Add user group': 'Benutzergruppe hinzufügen',
        'Add permission collection': 'Rechtesammlung hinzufügen',
        'Add branch': 'Filiale hinzufügen',
        'Name of the permission collection': 'Name der Rechtesammlung',
        'User group name': 'Name der Benutzergruppe',
        'Branch name': 'Name der Filiale',
        'Edit branch': 'Filiale bearbeiten',
        'Edit user group': 'Benutzergruppe bearbeiten',
        'Edit permission collection': 'Rechtesammlung bearbeiten',
        'add more': 'weitere hinzufügen',
        'Street and house number': 'Straße und Hausnummer',
        'Show on bigger map': 'Auf größerer Karte anzeigen',
        'All branches are connected automatically': 'Automatisch mit allen Filialen verbunden',
        'Users in this group': 'Benutzer in dieser Benutzergruppe',
        'role': 'Rechtesammlung',
        'group': 'Benutzergruppe',
        'Active permissions': 'Aktive Rechte',
        'no description yet': 'Bisher keine Beschreibung',
        'no users yet': 'Bisher keine Benutzer',
        'Show only global permissions': 'Globale Rechte hervorheben',
        'User Administration': 'Benutzerverwaltung',
        'Active': 'Aktiv',
        'Confirm': 'Bestätigen',
        //branches
        'Change branch': 'Filiale wechseln',
        'All Branches': 'Alle Filialen',
        'Please select one branch': 'Bitte eine Filiale auswählen',
        'User logging in': 'Benutzer wird eingeloggt',
        'loading branch': 'Lade Filiale',
        'starting e.sy 360': 'e.sy 360 wird gestartet',
        'All branches': 'Alle Filialen',
        'loading company data': 'Lade Unternehmensdaten',
        'Menu': 'Menü',
        'Something went wrong': 'Da ist was schiefgegangen',
        'Document categories': 'Kategorien Dokumente',
        'Are you sure you want to delete this category?': 'Möchten Sie die Kategorie "<1>{{categoryName}}</1>" löschen?',
        'Add category': 'Kategorie hinzufügen',
        'Edit category': 'Kategorie bearbeiten',
        'Category name': 'Name der Kategorie',
        'Save category': 'Kategorie speichern',
        'Delete category': 'Kategorie löschen',
        'If you delete this category, all associated documents will be moved to the default category.': 'Wenn Sie diese Kategorie löschen, werden alle enthaltenen Dokumente <1>in die Standardkategorie verschoben</1>.',
        'This process is not reversible.': 'Dieser Vorgang kann nicht rückgängig gemacht werden.',
        'Also, documents that will be uploaded to that category in the future, like through a QR code already in circulation, will also be moved to the default category.' : 'Darüberhinaus werden alle Dokumente, die in Zukunft in diese Kategorie hochgeladen werden, etwa über einen in Umlauf befindlichen QR Code, ebenfalls in die Standardkategorie verschoben.',
        'Select preferred branch': 'Bevorzugte Filiale auswählen',
        'No branch selected': 'Kein Branch ausgewählt',
        'Damage state': 'Schadenstatus',
        'Add state': 'Status hinzufügen',
        'Custom state name': 'Name des benutzerdefinierten Status',
        'Add custom state': 'Status hinzufügen',
        'Are you sure you want to delete this state?': 'Möchten Sie den Status "<1>{{customStateName}}</1>" löschen?',
        'If you delete this custom state, all damage cases with this state will fall back to the associated system state.': 'Wenn Sie diesen Status löschen, werden alle betroffenen Schadenfälle dem Systemstatus "<1>{{localizedSystemStateName}}</1>" zugeordnet.',
        'Delete custom state': 'Status löschen',
        'Edit custom state': 'Status bearbeiten',
        'System state': 'Systemstatus',
        'associated custom states': 'zugeordnete benutzerdefinierte Status',
        'Comprehensive': 'Kasko',
        'Make': 'Marke',
        'Model': 'Modell',
        'Change customer data': 'Kundendaten ändern',
        'Account data': 'Unternehmen',
        'Account data saved': 'Unternehmensdaten gespeichert',
        'Save account data': 'Unternehmensdaten speichern',
        'Unset as preferred': '"Als bevorzugt" entfernen',
        'Set as preferred': '"Als bevorzugt" setzen',
        'Preferred account': 'Bevorzugter Account',
        'A preferred account is set': 'Ein bevorzugtes Unternehmen ist gesetzt',
        'It will be displayed with a blue marking in the list.': 'Dieses wird mit blauem Stern in der Liste angezeigt',
        'No preferred account is set.': 'Es wurde noch kein bevorzugtes Unternehmen gesetzt.',
        'You could set an preferred account by clicking on the star.': 'Sie können ein bevorzugtes Unternehmen über den Klick auf den jeweiligen Stern setzen/abwählen.',
        'The forwarding will be activated if a preferred account is set': 'Hierdurch wird die automatische Weiterleitung zu diesem nach dem Login aktiviert',
        'You have the option, to mark a preferred account': 'Sie haben die Möglichkeit ein Unternehmen als bevorzugt zu markieren',
        'Account unset as preferred': 'Unternehmen ist nicht mehr als "bevorzugt" gesetzt',
        'Account set as preferred': 'Unternehmen wurde als "bevorzugt" gesetzt',
        'No account address is set': 'Keine Unternehmensaddresse angegeben',
        'Comprehensive data': 'Daten Kaskoschaden',
        'Save comprehensive data': 'Daten Kaskoschaden speichern',
        'Should_damages_be_deleted': 'Sollen der/die <1>{{selectedDamagesCount}}</1> Schäd(en) gelöscht werden?',
        'Damages would be deleted...': 'Schäeden werden entfernt...',
        'Damages are deleted': 'Schäden wurden entfernt',
        'Change state': 'Status ändern',
        'Should_damages_status_change': 'Soll der Status der folgenden <1>{{selectedDamagesCount}}</1> Schäden geändert werden?',
        'No damages for statuschange available': 'Keine Schäden für Statusänderung verfügbar',
        'No damages selected': 'Keine Schäden ausgewählt',
        'Status is changing...': 'Status wird geändert...',
        'Status changed': 'Status wurde geändert',
        'Edit multiple damages': 'Mehrere Schäden bearbeiten',
        'Edit multiple documents': 'Mehrere Dokumente bearbeiten',
        'element': 'Element',
        'elements': 'Elemente',
        'damage': 'Schaden',
        'damages': 'Schäden',
        'document': 'Dokument',
        'documents': 'Dokumente',
        'Coverage': 'Deckung',
        'Shipment': 'Verbringungskosten',
        'RetailPriceSurcharge': 'UPE-Aufschlaege',
        'Surveying': 'Vermessungskosten',
        'Cleaning': 'Reinigungskosten',
        'Disinfection': 'Desinfektion',
        'Finishing': 'Beilackierung von Fahrzeugteilen',
        'TestDrive': 'Probefahrt',
        'Calibration': 'Kalibrierung und Justage von Assistenzsystemen',
        'InvoiceUnknown': 'Unbekannt',
        'InvoiceRepair': 'Reparaturrechnung',
        'InvoiceRentalCar': 'Mietwagenrechnung',
        'InvoiceTowing': 'Abschleppkosten',
        'InvoiceExpert': 'Sachverstaendigenkosten',
        'InvoiceMisc': 'Sonstige Rechnung',
        'InvoiceFictitiousRepair': 'Reparaturkosten Fiktiv',
        'InvoiceFlatRateFee': 'Kostenpauschale',
        'InvoiceImpairment': 'Wertminderung',
        'InvoiceLossOfUse': 'Nutzungsausfall',
        'loading...': 'lädt...',
        'New customer assigned': 'Neuer Kunde wurde zugeordnet',
        'Enter userdata': 'Benutzerdaten eingeben',
        'Assign group': 'Gruppe zuweisen',
        'Select default branch': 'Startfiliale auswählen',
        'Next': 'Weiter',
        'Back': 'Zurück',
        'Create user': 'Benutzer erstellen',
        'No groups has been found': 'Es wurden keine Gruppen gefunden',
        'Select groups': 'Gruppen auswählen',
        'Assign groups': 'Gruppen zuweisen',
        'No groups available': 'Leider stehen keine Gruppen zur Auswahl',
        'Usercreation successfull': 'Nutzer wurde erfolgreich erstellt',
        'Assigment of preferred branch': 'Zuweisung der Startfiliale...',
        'Assigment successfull': 'Zuweisung erfolgreich',
        'Assign branch': 'Filiale zuweisen',
        'Cancel branch assigment': 'Keine Filiale zuweisen',
        'Delete branch': 'Filiale löschen',
        'do you want to delete this branch?': 'Möchten Sie die Filiale "<1>{{branchName}}</1>" löschen?',
        'do you want to delete this group?': 'Möchten Sie die Gruppe "<1>{{groupName}}</1>" löschen?',
        'Current active branch could not be deleted': 'Die aktive Filiale kann nicht gelöscht werden',
        'Branch removed': 'Filiale entfernt',
        'Edit list': 'Liste bearbeiten',
        'Are you sure you want to remove this user from this group?': 'Wollen sie den Benutzer <1>{{userName}}</1> aus der Gruppe <3>{{groupName}}</3> entfernen?',
        'Companies': 'Firmen',
        'PrivateCustomers': 'Privatkunden',
        'BusinessCustomers': 'Geschäftskunden',
        'No companies found': 'Keine Firmen gefunden',
        'Street and housenumber': 'Strasse',
        'Show map': 'Karte anzeigen',
        'Map data not found': 'Kartendaten nicht gefunden',
        'Determine coordinates automatically': 'Standort automatisch ermitteln',
        'Move the marker on the map to correct your companies position.': 'Ziehen Sie den Marker an eine andere Position, um den Standort des Unternehmens zu korrigieren.',
        'Latitude': 'Breitengrad',
        'Longitude': 'Längengrad',
        'Remove user group': 'Benutzergruppe löschen',
        'Deleting usergroup...': 'Benutzergruppe wird gelöscht...',
        'Edit group': 'Gruppe bearbeiten',
        'Delete group': 'Gruppe löschen',
        'This group is defined by the system and can therefore not be altered': 'Diese Gruppe wird vom System vorgegeben und kann daher nicht bearbeitet werden',
        'Groups & Branches': 'Gruppen & Filialen',
        'Do you want to change the damage state?': 'Möchten Sie den Schadenstatus ändern?',
        'Do you want to change or remove the assignee of this task?': 'Möchten Sie den Bearbeiter dieser Aufgabe ändern oder entfernen?',
        'Deadline is in past': 'Frist liegt in der Vergangenheit',
        'Liability forms are only displayed for liability cases.': 'Haftpflichtformulare werden nur für Schäden vom Typ "Haftpflicht" angezeigt.',
        'Results per page': 'Ergebnisse pro Seite',
        'roleAdmin': 'Administrative Rechte',
        'roleUser': 'Normale Benutzerrechte',
        'roleDamageManagementPermissions': 'Rechte für Schadenabwicklung',
        'roleControllingPermissions': 'Rechte für Controlling',
        'roleCommissioningPermissions': 'Rechte für Beauftragungen',
        'roleDeletionPermissions': 'Löschrechte',
        'Users in this group have the permissions': 'Benutzer in dieser Gruppe haben die Rechte',
        'in these branches': 'in folgenden Filialen',
        'all branches': 'alle Filialen',
        'Save group memberships': 'Gruppenzugehörigkeiten speichern',
        'Edit group memberships': 'Gruppenzugehörigkeiten bearbeiten',
        'no permissions connected': 'keine Berechtigungen verbunden',
        'no branches connected': 'keine Filialen verbunden',
        'User has been successfully created.': 'Benutzer wurde erstellt',
        'You can now assign a preferred branch for this user or skip this step.': 'Sie können die bevorzugte Filiale dieses Benutzers auswählen oder diesen Schritt überspringen.',
        'Permissions': 'Berechtigungen',
        'Branches': 'Filialen',
        'User is the only admin in this account and cannot be deleted.': 'Dieser Benutzer ist der einzige Administrator und kann daher nicht gelöscht werden.',
        'User groups cannot be edited for the only admin user in this account.': 'Dieser Benutzer ist der einzige Administrator, daher kann die Gruppenzugehörigkeit nicht bearbeitet werden.',
        'System group': 'Systemgruppe',
        'Message related documents are not deleted. These can still be accessed via the claims manager': 'Nachrichtenbezogene Dokumente werden nicht gelöscht. Diese sind weiterhin über den Schadenmanager abrufbar',
        'Do you want to delete the following message?': 'Möchten Sie die folgende Nachricht löschen?',
        'Task related documents are not deleted. These can still be accessed via the claims manager': 'Aufgabenbezogene Dokumente werden nicht gelöscht. Diese sind weiterhin über den Schadenmanager abrufbar.',
        'Edit user data instead': 'Stattdessen Benutzerdaten bearbeiten',
        'Edit customer data instead': 'Stattdessen Kundendaten bearbeiten',
        'Edit multiple tasks': 'Mehrere Aufgaben bearbeiten',
        'Should_tasks_be_deleted': 'Sollen diese <1>{{tasksCount}}</1> Aufgabe(n) gelöscht werden?',
        'The following tasks will be deleted': 'Die folgenden Aufgaben werden gelöscht',
        'The following tasks cannot be deleted and are excluded from the deletion process': 'Die folgenden Aufgaben können nicht entfernt werden, und werden von diesem Vorgang ausgenommen',
        'No tasks for deletion selected': 'Es stehen keine Aufgaben für das Löschen zur Auswahl',
        'Tasks would be deleted...': 'Aufgaben werden gelöscht...',
        'Tasks are deleted': 'Aufgaben wurden gelöscht',
        'Loading accounts': 'Lade Unternehmen',
        'Load more comments': 'Weitere Kommentare laden',
        'Choose account': 'Unternehmen wählen',
        'Damage manager': 'Schadenmanager',
        'Users management': 'Benutzerverwaltung',
        'Permission missing for this content': 'Fehlende Berechtigung für diesen Inhalt',
        'show all damage cases in this branch': 'Alle Schäden in dieser Filiale anzeigen',
        'Changes concerning branches or the permission system may take up to 10 minutes to take effect.': 'Die Aktualisierung von Änderungen im Berechtigungssytem nimmt bis zu 10 Minuten in Anspruch.',
        'External task': 'Externe Aufgabe',
        'TestDrives': 'Probefahrten',
        'Only external tasks': 'Nur externe Aufgaben',
        'Internal & external tasks': 'Interne & externe Aufgaben',
        'Claim number / type': 'Schadennummer / Typ',
        'Release date': 'Schadendatum',
        'Damage Type': 'Schadentyp',
        'Damage number liability': 'Schadenhaftungsnr',
        'Switch customer': 'Kunde wechseln',
        'Confirm new customer': 'Neuen Kunden bestätigen',
        'Proprietor is company': 'Besitzer ist Firma',
        'Keeper is company': 'Halter ist Firma',
        'Keeper is driver': 'Fahrer ist Fahrzeughalter',
        'Vehicle keeper': 'Fahrzeughalter',
        'Driver': 'Fahrer',
        'Proprietary reason': 'Fahrzeug ist finanziert',
        'Save vehicle Proprietor data': 'Fahrzeughalterdaten speichern',
        'none': 'Keiner',
        'noneSingular': 'Keine',
        'Partial': 'Teilweise',
        'Full': 'Voll',
        'Accident opponent vehicle': 'Fahrzeug des Unfallgegners',
        'Vehicle proprietor': 'Fahrzeugbesitzer',
        'Save opponent vehicle data': 'Fahrzeugdaten des Gegners speichern',
        'Accident Opponent keeper & driver': 'Unfallgegner Halter & Fahrer',
        'Save opponent keeper & driver data': 'Unfallgegner Halter & Fahrerdaten speichern',
        'Liability insurance': 'Haftpflichtversicherung',
        'Save liability insurance data': 'Daten der Haftpflichtversicherung speichern',
        'Damage to person': 'Personenschaden',
        'Invoice data': 'Rechnungsdaten',
        'Invoice data could not be loaded': 'Rechnungsdaten konnten nicht geladen werden',
        'Save branch': 'Filiale speichern',
        'Accident opponent keeper & driver': 'Unfallgegner Halter & Fahrer',
        'The following items exist in branches in which this user is missing the delete permission': 'Für die folgenden Elemente fehlt dem aktuellen Benutzer die Berechtigung "Löschen" in der jeweiligen Filiale',
        'The connection to the previous lawfirm has not been established yet. Please wait a couple of minutes and try again.': 'Die Beauftragung der vorherigen Anwaltskanzlei ist noch nicht abgeschlossen. Bitte versuchen Sie es in Kürze erneut.',
        'No mail available': 'Keine Emailadresse vorhanden',
        'Value must not be zero': 'Wert darf nicht null sein',
        'There are still members assigned to this group': 'Es befinden sich noch Nutzer in dieser Gruppe',
        'Edit users in this group': 'Benutzer in dieser Gruppe bearbeiten',
        'Reason': 'Grund',
        'This customer can´t be deleted.': 'Dieser Kunde kann nicht gelöscht werden.',
        'Show damages related to this customer': 'Kundenbezogene Schäden anzeigen',
        'Damages for customer': 'Schäden für Kunde',
        'damages are assigned to this branch': 'Schäden sind dieser Filiale zugeordnet.',
        'damage is assigned to this branch': 'Schaden ist dieser Filiale zugeordnet.',
        'groups are connected to this branch': 'Gruppen sind mit dieser Filiale verbunden',
        'group is connected to this branch': 'Gruppe ist mit dieser Filiale verbunden',
        'no damages are assigned to this branch': 'Es sind dieser Filiale keine Schäden zugeordnet',
        'no groups are connected to this branch': 'Die Filiale ist mit keiner Gruppe verbunden',
        'Reasons': 'Gründe',
        'This branch cannot be deleted.': 'Diese Filiale kann zurzeit nicht gelöscht werden.',
        'This feature will be available in one of the next releases.': 'Dieses Feature wird in einem der nächsten Releases verfügbar sein.',
        'The following group could not be deleted': 'Die folgende Gruppe kann nicht gelöscht werden',
        'The group': 'Die Gruppe',
        'Could not be deleted.': 'kann nicht gelöscht werden.',
        'No users are assigned to this group': 'In dieser Gruppe befinden sich keine Benutzer',
        'This branch': 'Diese Filiale',
        'Cannot be deleted.': 'kann nicht gelöscht werden.',
        'Could not be deleted': 'kann nicht gelöscht werden',
        'There is still a damage reference.': 'Der Kunde ist einem oder mehreren Schäden zugeordnet.',
        'There are no damages belonging to this customer': 'Der Kunde ist keinem Schadenfall zugeordnet.',
        'Add user to group': 'Benutzer zur Gruppe hinzufügen',
        'Download attachments': 'Anhänge herunterladen',
        'Admin rights': 'Administrative Rechte',
        'User rights': 'Normale Benutzerrechte',
        'Damage Management permissions': 'Rechte für Schadenabwicklung',
        'Controlling permissions': 'Rechte für Controlling',
        'Comission permissions': 'Rechte für Beauftragungen',
        'Deletion permissions': 'Löschrechte',
        'Save custom status': 'Benutzerdefinierten Status speichern',
        'Show help': 'Hilfe anzeigen',
        'Save vehicle proprietor data': 'Fahrzeugbesitzerdaten speichern',
        'Comprehensive data changed': 'Kaskoschadendaten geändert',
        'Not set': 'Nicht gesetzt',
        'Leasing contract': 'Leasingvertrag',
        'Financing contract': 'Finanzierungsvertrag',
        'Vehicle proprietor data changed': 'Fahrzeugbesitzerdaten geändert',
        'Start typing to search': 'Eingabe tätigen',
        'Invoice date': 'Rechnungsdatum',
        'Please select at least one': 'Bitte mindestens ein Element auswählen',
        'Payment created': 'Auszahlung angelegt',
        'show invoices': 'Rechnungen anzeigen',
        'Damage state changed': 'Schadenstatus geändert',
        'Old state': 'Alter Status',
        'New state': 'Neuer Status',
        'Assignee changed': 'Bearbeiter geändert',
        'Invoice created': 'Rechnung erstellt',
        'Gross total': 'Brutto',
        'Net total': 'Netto',
        'Damage type changed': 'Schadentyp geändert',
        'Job number changed': 'Auftragsnummer geändert',
        'Payment cancelled': 'Auszahlung storniert',
        'Lawyer instructed': 'Anwalt beauftragt',
        'has': 'hat',
        'Changed from to': 'Geändert von "{{past}}" zu "{{current}}"',
        'This damage will be created in currentBranch': 'Dieser Schaden wird in der Filiale "<strong>{{currentBranchName}}</strong>" erstellt',
        'Authorization': 'Freigabe',
        'Damagetype': 'SchadenTyp',
        'Lawfirm': 'Anwaltskanzlei',
        'no commissioning': 'keine Beauftragung',
        'Is entitled to tax deduction': 'Berechtigt zum Vorsteuerabzug',
        'DamageAssigneeChangedNotificationTitle': 'Schadenbearbeiter wurde geändert',
        'DamageCreatedNotificationTitle': 'Neuer Schaden wurde erstellt',
        'DamageDeletedNotificationTitle': 'Schaden wurde gelöscht',
        'DamageDescriptionChangedNotificationTitle': 'Schadenmeldung wurde geändert',
        'DamageIncidentDateChangedNotificationTitle': 'Schadendatum wurde geändert',
        'DamageJobNumberChangedNotificationTitle': 'Auftragsnummer wurde geändert',
        'DamageNoteChangedNotificationTitle': 'Fallnotiz wurde geändert',
        'DamageStateChangedNotificationTitle': 'Schadenstatus wurde geändert',
        'DamageTypeChangedNotificationTitle': 'Schadentyp wurde geändert',
        'DocumentCreatedNotificationTitle': 'Neues Dokument wurde erstellt',
        'DocumentDeletedNotificationTitle': 'Dokument wurde gelöscht',
        'InvoiceCreatedNotificationTitle': 'Neue Rechnung wurde erstellt',
        'InvoiceDeletedNotificationTitle': 'Rechnung wurde gelöscht',
        'TaskCommentCreatedNotificationTitle': 'Neuer Kommentar wurde für eine Aufgabe angelegt',
        'TaskCreatedNotificationTitle': 'Neue Aufgabe wurde erstellt',
        'TaskDeletedNotificationTitle': 'Aufgabe wurde gelöscht',
        'TaskStateChangedNotificationTitle': 'Aufgabenstatus wurde geändert',
        'VehicleKeeperChangedNotificationTitle': 'Fahrzeughalter wurde geändert',
        'VehicleUpdatedNotificationTitle': 'Fahrzeugangaben wurden geändert',
        'MessageCreatedNotificationTitle': 'Neue Nachricht',
        'MessageDeletedNotificationTitle': 'Nachricht wurde gelöscht',
        'System generated': 'Vom System erstellt',
        'Task name': 'Aufgabe',
        'ToDo': 'Zu erledigen',
        'ToReview': 'Zu überprüfen',
        'Lawfirm communication has been initiated': 'Kanzlei wurde beauftragt',
        'Jobnumber': 'Schadennr.',
        'Invoice deleted': 'Rechnung gelöscht',
        'Actor': 'Bearbeiter',
        'Occured at': 'Erschienen',
        'Payment has been created': 'Auszahlung wurde erstellt',
        'Payment has been cancelled': 'Auszahlung wurde storniert',
        'Assignee has been changed': 'Bearbeiter wurde gewechselt',
        'Job number has been changed': 'Auftragsnummer wurde geändert',
        'Damage state  has been changed': 'Schadenstatus wurde geändert',
        'Damage type  has been changed': 'Schadentyp wurde geändert',
        'Invoice  has been created': 'Rechnung wurde erstellt',
        'Lawfirm communication  has been initiated': 'Kanzlei wurde beauftragt',
        'Account has been created': 'Account wurde erstellt',
        'Account invitation has been accepted': 'Account Einladung wurde aktzeptiert',
        'Account invitation has been created': 'Account Einladung wurde erstellt',
        'Branch has been created': 'Filiale wurde erstellt',
        'Branch has been deleted': 'Filiale wurde gelöscht',
        'Comprehensivedamage has been created': 'Kaskoschaden wurde erstellt',
        'Comprehensivedamage type has been deleted': 'Kaskoschaden wurde gelöscht',
        'Comprehensivedamage type has been renamed': 'Kaskoschaden wurde unbenannt',
        'Damage assignee has been changed': 'Schadenbearbeiter hat sich geändert',
        'Damage has been deleted': 'Schaden wurde gelöscht',
        'Damagestate has been created': 'Schadenstatus wurde erstellt',
        'Damage state  has been deleted': 'Schadenstatus wurde gelöscht',
        'Invoice  has been deleted': 'Rechnung wurde gelöscht',
        'User has been created': 'Benutzer wurde erstellt',
        'Userdata has been changed': 'Benutzerdaten wurden geändert',
        'User has been deleted': 'Benutzer wurde gelöscht',
        'Userinvitation has been accepted': 'Benutzereinladung wurde angenommen',
        'Userinvitation has been created': 'Benutzereinladung wurde gesendet',
        'Switch to accounts': 'Zu den Accounts wechseln',
        'has invitation accepted': 'hat Einladung angenommen',
        'has invitation created': 'hat Einladung erstellt',
        'Switch to branches': 'Zu den Filialen wechseln',
        'Switch to damage': 'Zum Schaden wechseln',
        'Licenseplate': 'KFZ Kennzeichen',
        'View accounts': 'Konten ansehen',
        'View branches': 'Filialen ansehen',
        'View damage': 'Schaden ansehen',
        'Type': 'Typ',
        'New status': 'Neuer Status',
        'Invoice number': 'Rechnungsnummer',
        'RentalCar': 'Mietwagenrechnung',
        'View invoice': 'Rechnung ansehen',
        'View invoices': 'Rechnungen ansehen',
        'Towing': 'Abschleppkosten',
        'CancellationReason': 'Stornierungsgrund',
        'Cancellation reason': 'Stornierungsgrund',
        'Cancelled by': 'Storniert von',
        'Amount of payment': 'Auszahlungsbetrag',
        'Eur': '€',
        'Show activities between': 'Zeige Aktivitäten von',
        'Activity type': 'Aktivitätentyp',
        'View users': 'Benutzerliste ansehen',
        'Status name': 'Statusname',
        'Details unknown': 'Details unbekannt',
        'Comprehensivedamage type was created': 'Kaskoschaden Typ erstellt',
        'Comprehensivedamage type was deleted': 'Kaskoschaden Typ gelöscht',
        'Commissioning failed': 'Beauftragung fehlgeschlagen',
        'Timerange filter active': 'Filter für Zeitraum aktiv',
        'No participants at the moment': 'Derzeit keine Beteiligten',
        'Branch saved': 'Filiale gespeichert',
        'Category created': 'Kategorie erstellt',
        'State created': 'Status erstellt',
        'Category deleted': 'Kategorie wurde gelöscht',
        'State deleted': 'Status gelöscht',
        'Groups saved': 'Gruppen wurden gespeichert',
        'Branch created': 'Filiale wurde erstellt',
        'Group saved': 'Gruppe wurde gespeichert',
        'Groupmembers loaded': 'Gruppenangehörige wurden geladen',
        'Connection added': 'Verbindung wurde hinzugefügt',
        'Connection deleted': 'Verbindung wurde entfernt',
        'Opponent keeper data saved': 'Fahrzeughalter Gegner Daten gespeichert',
        'Liability insurance data changed': 'Haftpflichtversicherungsdaten geändert',
        'Showing activites of type': 'Zeige Aktivitäten vom Typ',
        'No activities found': 'Keine Aktiviäten gefunden',
        'Comprehensive damage type changed': 'Kaskoschaden Typ geändert',
        'Administrators have these special permissions': 'Administratoren verfügen über folgende gesonderte Rechte',
        'Administrators have these permissions for damage management': 'Administratoren verfügen über folgende Rechte zur Schadenbearbeitung',
        'Create, update and delete users': 'Benutzer in der Benutzerverwaltung anlegen, bearbeiten und löschen',
        'Invite users': 'Benutzer zur Nutzung des e.sy360 einladen',
        'Create, update and delete user groups': 'Benutzergruppen anlegen, bearbeiten und löschen',
        'Assign users to groups': 'Benutzer in der Benutzerverwaltung einer Gruppe zuweisen',
        'Create, update and delete branches': 'Filialen anlegen, bearbeiten und löschen',
        'Assign user groups to branches': 'Benutzergruppen einer oder mehreren Filialen zuweisen',
        'Assign user groups to permission sets': 'Benutzergruppen Rechte zuweisen',
        'Edit company address data': 'Unternehmensstammdaten bearbeiten',
        'Normal users have these permissions:': 'Normale Benutzer haben folgende Rechte',
        'Create, update and delete damage cases': 'Schäden anlegen, bearbeiten und löschen',
        'Create, update, assign and delete tasks': 'Aufgaben erstellen, zuweisen, bearbeiten und löschen',
        'Commission service providers': 'Dienstleister beauftragen',
        'Access to controlling data': 'Zugriff auf Controlling-Daten',
        'Create and delete invoices': 'Rechnungen anlegen und löschen',
        'Create and cancel payments': 'Auszahlungen anlegen und stornieren',
        'Create, update and delete customer data': 'Kundendaten anlegen, bearbeiten und löschen',
        'Users will have these permissions': 'Benutzer haben folgende Rechte',
        'Create and update damage cases': 'Schäden anlegen und bearbeiten',
        'Create, update and assign tasks': 'Aufgaben erstellen, zuweisen und bearbeiten',
        'Create invoices': 'Rechnungen anlegen',
        'Create and update customer data': 'Kundendaten anlegen und bearbeiten',
        'Delete damage cases': 'Schäden löschen',
        'Delete tasks': 'Aufgaben löschen',
        'Delete invoices': 'Rechnungen löschen',
        'Delete customers': 'Kunden löschen',
        'groupAccount AdminsDescription': 'Benutzer in dieser Gruppe haben administrative Rechte in ALLEN Filialen.',
        'groupBenutzerDescription': 'Benutzer in dieser Gruppe haben normale Benutzerrechte in ALLEN Filialen.',
        'Vehicle keeper & driver': 'Fahrzeughalter & Fahrer',
        'show all activities': 'Alle Aktivitäten anzeigen',
        'Saving branch': 'Speichere Filiale',
        'Damage management': 'Schaden Management',
        'Show Details': 'Details anzeigen',
        'Hide Details': 'Details verbergen',
        'Damagedata is loading...': 'Schadendaten werden geladen...',
        'Damage could not be found': 'Schaden wurde nicht gefunden',
        'Back to Dashboard': 'zur Übersicht',
        'From': 'von',
        'Show message': 'Nachricht anzeigen',
        'Switch to task': 'Zur Aufgabe wechseln',

        'TaskCreatedActivityTitle': 'Aufgabe erstellt',
        'TaskDeletedActivityTitle': 'Aufgabe gelöscht',
        'Task deleted': 'Aufgabe gelöscht',
        'Lawfirm communication initiated': 'Kanzlei beauftragt',
        'View task': 'Aufgabe ansehen',
        'Task has been created': 'Aufgabe wurde erstellt',
        'Task has been deleted': 'Aufgabe wurde gelöscht',
        'Task state has been changed': 'Aufgabenstatus wurde geändert',
        'Taskcomment has been created': 'Aufgabenkommentar wurde hinzugefügt',
        'Taskstate changed': 'Aufgabenstatus geändert',
        'TaskCommentCreatedActivityTitle': 'Aufgabenkommentar erstellt',
        'Comment created': 'Kommentar erstellt',
        'Vehiclekeeper': 'Fahrzeughalter',
        'Vehiclekeeper changed': 'Fahrzeughalter geändert',
        'Vehiclekeeper has changed': 'Fahrzeughalter wurde geändert',
        'Vehicle updated': 'Fahrzeug geändert',
        'Vehicledata has been updated': 'Fahrzeugdaten wurden aktualisiert',
        'Damagedescription changed': 'Schadenbeschreibung geändert',
        'Damagedescription has been changed': 'Schadenbeschreibung wurde geändert',
        'Damageincidentdate has been changed': 'Schadendatum wurde geändert',
        'Damageincidentdate changed': 'Schadendatum geändert',
        'Damagenote has been changed': 'Schadennotiz wurde geändert',
        'Damagenote changed': 'Schadennotiz geändert',
        'Document has been created': 'Dokument wurde erstellt',
        'Document created': 'Dokument erstellt',
        'View documents in damage': 'Dokumente ansehen',
        'Document has been deleted': 'Dokument wurde gelöscht',
        'Lawfirm communication  has been aborted': 'Kanzleibeauftragung wurde beendet',
        'Lawfirm communication aborted': 'Kanzleibeauftragung beendet',
        'Message created': 'Nachricht erstellt',
        'Message has been created': 'Nachricht wurde erstellt',
        'Message deleted': 'Nachricht gelöscht',
        'Message has been deleted': 'Nachricht wurde gelöscht',
        'View message': 'Nachricht ansehen',
        'View inbox': 'Posteingang ansehen',
        'Creating user group': 'Benutzergruppe wird erstellt',
        'Opponent vehicle data saved': 'Fahrzeugdaten des Unfallgegners gespeichert',
        'Load more': 'Weitere laden',
        'All loaded': 'Alle geladen',
        'Please select at least one file': 'Bitte wählen Sie mindestens eine Datei aus',
        'Upload files': 'Dateien hochladen',
        'Complete damage data': 'Schadendaten vervollständigen',
        'Inspection site': 'Besichtigungsort',
        'Damage type must not be "Unknown"': 'Schadentyp darf nicht "Unbekannt" sein',
        'Send DEKRA commission': 'DEKRA Beauftragung senden',
        'DEKRA expert commissioned': 'DEKRA Gutachter beauftragt',
        'Commission expert': 'Gutachter beauftragen',
        'Commissioned lawfirm': 'Beauftragte Kanzlei',
        'Commissioned expert': 'Beauftragter Gutachter',
        'DEKRA expert': 'DEKRA Gutachter',
        'Other expert': 'Anderer Gutachter',
        'expert': 'Gutachter',
        'Saving damage data': 'Schadendaten werden gespeichert',
        'Change': 'Ändern',
        'In Progress': 'In Bearbeitung',
        'Outbox': 'Postausgang',
        'DEKRA expert communication initiated': 'DEKRA Gutachterbeauftragung gesendet',
        'DEKRA expert communication established': 'DEKRA Gutachterbeauftragung erfolgreich',
        'DEKRA expert communication failed': 'DEKRA Gutachterbeauftragung fehlgeschlagen',
        'Other expert communication initiated': 'Gutachter beauftragt',
        'Inspection date': 'Besichtigungstag',
        'initiated expert communication': 'Gutachterbeauftragung initiiert',
        'confirmed expert communication': 'Gutachterbeauftragung bestätigt',
        'blocked expert communication': 'Gutachterbeauftragung abgelehnt',
        'ExpertCommunicationOtherInitiatedNotificationTitle': 'Gutachterbeauftragung gesendet',
        'ExpertCommunicationDekraInitiatedNotificationTitle': 'DEKRA Gutachterbeauftragung gesendet',
        'ExpertCommunicationDekraEstablishedNotificationTitle': 'DEKRA Gutachterbeauftragung erfolgreich',
        'ExpertCommunicationDekraFailedNotificationTitle': 'DEKRA Gutachterbeauftragung fehlgeschlagen',
        'You have no permissions for this app': 'Sie besitzen für diese Anwendung keine Berechtigung',
        'Session for this company has expired': 'Sitzung für dieses Unternehmen ist abgelaufen',
        'Page not found': 'Seite wurde nicht gefunden',
        'Select Category & Lawyer': 'Kategorie & Synchronisierung',
        'Makte all documents available for legal software': 'Alle Dokumente für Kanzleisoftware bereitstellen',
        'These documents will be made available for legal software': 'Folgende Dokumente für die Kanzleisoftware bereitgestellt.',
        'If the lawyer communication has already been established, they will be sent immediately.': 'Falls die Anwaltskommunikation bereits initiiert wurde, werden sie sofort gesendet.',
        'These documents are already made available and will be ignored': 'Diese Dokumente sind bereits bereitgestellt und werden daher ignoriert',
        'Marking for legal software...': 'Markiere für Kanzleisoftware...',
        'Documents were made available': 'Dokumente wurden bereitgestellt',
        'Make available': 'Bereitstellen',
        'Make available for legal software': 'Für Kanzleisoftware bereitstellen',
        'Available for legal software': 'Für Kanzleisoftware bereitgestellt',
        'Document updated': 'Dokument wurde aktualisiert',
        'Simple assignment': 'Einfache Beauftragung',
        'Structured assignment': 'Strukturierte Beauftragung',
        'Comment acknowledgement': 'Kommentar Eingangsbestätigung',
        'Comment case file': 'Kommentar Aktenstatus',
        'Different place': 'Abweichender Ort',
        'Place of inspection': 'Besichtigungsort',
        'Branch is location of inspection': 'Filiale ist Besichtigungsort',
        'You currently don\'t have access to this account': 'Sie haben keinen Zugriff auf dieses Unternehmen',
        'User is locked': 'Benutzer ist gesperrt',
        'Company account has been locked': 'Unternehmen wurde gesperrt',
        'Company account has been unlocked': 'Unternehmen wurde entsperrt',
        'User has been locked': 'Benutzer wurde gesperrt',
        'User has been unlocked': 'Benutzer wurde entsperrt',
        'Branch data': 'Filialdaten',
        'Please provide at least address and phone number (or email).': 'Bitte geben Sie mindestens die Adresse und Telefonnummer (alternativ E-Mail) an.',
        'Complete customer data': 'Kundendaten vervollständigen',
        'It is recommended to check the customer data before hiring a lawyer.': 'Es wird empfohlen die Kundendaten vor der Anwaltsbeauftragung zu überprüfen.',
        'Check customer data': 'Kundendaten überprüfen',
        'The following files are sent directly to the law firm.': 'Die folgenden Dateien werden direkt an die Anwaltskanzlei gesendet.',
        'This operation cannot be undone.': 'Dieser Vorgang kann nicht rückgängig gemacht werden.',
        'Confirmation needed': 'Bestätigung benötigt',
        'Saving data...': 'Daten werden gespeichert...',
        'Insurance is from foreign': 'Versicherung ist aus dem Ausland',
        'Foreign insurance name': 'Name der ausländischen Versicherung',
        'Germany': 'Deutschland',
        'Other country': 'Ausland',
        'Insurance country': 'Versicherungsland',
        'Insurance name': 'Name der Versicherung',
        'Select from list': 'Aus Vorschlagsliste auswählen',
        'Other insurance':'Sonstige Vesicherung',
        'Name of other insurance': 'Name der sonstigen Versicherung',
        'Own statement': 'Eigene Angabe',
        'Please select a lawfirm': 'Bitte wählen Sie eine Anwaltskanzlei',
        'No lawfirms found': 'Keine Anwaltskanzleien gefunden',
        'Select lawfirm from list instead': 'Stattdessen Kanzlei aus Liste wählen',
        'Search for lawfirm instead': 'Stattdessen nach Kanzlei suchen',
        'Edit customer (optional)': 'Kunde bearbeiten (optional)',
        'Insurance data (optional)': 'Daten Versicherung (optional)',
        'Create damage and continue': 'Schaden anlegen und weiter',
        'Save customerdata and continue': 'Kundendaten speichern und weiter',
        'Save insurance data': 'Versicherungsdaten speichern',
        'Information comprehensive (vehicle keeper)': 'Informationen Kasko (Fahrzeughalter)',
        'Information liability (opponent)': 'Informationen Haftpflicht (Unfallgegner)',
        'Damage case has been successfully created."': 'Neuer Schaden wurde angelegt.',
        'You can provide additional data or click "cancel to skip.': 'Sie können weitere Informationen zu diesem Fall hinzufügen oder auf "Abbrechen" klicken, um diese Schritte zu überspringen.',
        'Invalid postal code': 'Ungültige Postleitzahl',
        'EmailSettingTitle': 'E-Mail',
        'RealtimeNotificationSettingTitle': 'Echtzeit',
        'Edit user notification settings': 'Benachrichtigungseinstellungen',
        'Notification settings': 'Benachrichtigungseinstellungen',
        'Which notifications do you want to receive for damages you are assigned to': 'Welche Benachrichtigungen möchten Sie erhalten für Schäden, bei denen Sie Sachbearbeiter sind',
        'You have to configure these settings for every company you have access to separately': 'Sie müssen diese Einstellungen für jedes Unternehmen, auf das Sie Zugriff haben, separat vornehmen',
        'Saving notification settings': 'Benachrichtigungseinstellungen werden gespeichert',
        'Notification settings saved': 'Benachrichtigungseinstellungen gespeichert',
        'Add documents': 'Dokumente hinzufügen',
        'Add documents to invoice': 'Dokumente zu Rechnung hinzufügen',
        'Make available for legal software again': 'Erneut für Kanzleisoftware bereitstellen',
        'Saving customer data': 'Speichere Kundendaten',
        'Site name': 'Name des Besichtigungsorts',
        'No connected lawfirms found.': 'Keine Anwaltskanzleien verbunden.',
        'New message for lawfirm': 'Nachricht an Anwalt schicken',
        'Complete the damage creation': 'Schadenanlage abschließen',
        'Save customerdata and finish': 'Kundendaten speichern schließen',
        'The viewing date is in the past.': 'Der Besichtigungstermin liegt in der Vergangenheit',
        'IBAN is invalid': 'Ungültige IBAN',
        'Please provide at least email or phone number.': 'Bitte geben Sie mindestens E-Mail oder Telefonnummer an.',
        'There are no files': 'Es sind keine Dateien vorhanden',
        'Create QR Code for vehiclekeeper': 'QR-Code für Fahrzeughalter erstellen',
        'Check data': 'Daten überprüfen',
        'QR-Code creation': 'QR-Code erstellen',
        'QR-Code generated': 'QR-Code generiert',
        'Generate QR code ...': 'QR-Code wird generiert ...',
        'Generate QR-Code': 'QR-Code generieren',
        'Display QR-Code': 'QR-Code anzeigen',
        'Create QR-Code': 'QR-Code erstellen',
        'Configure dashboard': 'Übersichtsseite konfigurieren',
        'Please choose your dashboard configuration': 'Bitte wählen sie eine dieser Konfigurationen',
        'Damages & releases': 'Schäden & Freigaben',
        'Controlling & statistics': 'Controlling & Statistik',
        'Apply configuration': 'Konfiguration übernehmen',
        'Configuration saved': 'Konfiguration gespeichert',
        'last customers': 'Zuletzt erstellte Kunden',
        'Customer name': 'Name des Kunden',
        'Customers & tracking': 'Kunden & Nachverfolgung',
        'Handing over': 'Übergabe',
        'Commission lawyer': 'Anwalt beauftragen',
        'You can send a commission data or click "cancel" to skip': 'Sie können jetzt noch einen Gutachter und/oder Anwalt beauftragen',
        'Commissions sent': 'Beauftragungen gesendet',
        'License plate damaged person': 'KFZ Kennzeichen Geschädigter',
        'Hand over and finish': 'Übergeben',
        'Liability insurance opponent': 'Haftpflichtversicherung Unfallgegner',
        'Insurance information saved': 'Versicherungsdaten gespeichert',
        'Save insurance information': 'Versicherungsdaten speichern',
        'Create customer': 'Kunde erstellen',
        'Category order saved': 'Kategorienstruktur gespeichert',
        'Remove file': 'Datei entfernen'
    }
}