import { connect } from 'react-redux'
import LastRepairAuthorizations from './component'

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LastRepairAuthorizations)