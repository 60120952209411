import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import { ROUTES } from 'application/constants'
import { withRouter, useLocation } from 'react-router-dom'

export const SharedResourceRedirectComponent = withRouter(({
    children,
    sharedResource,
    sharedResourceId,
    sharedResourceType,
    onSetReturnUrl,
    match
}) => {
    const query = useLocation().search //add url search query params to return url

    useEffect(()=>{
        onSetReturnUrl(match.url + query)
    }, [match.url, onSetReturnUrl, query])

    if(!sharedResource.pinIsValidated && !sharedResource.pinValidationPending && !sharedResource.pinIsValid){
        return <Redirect to={`${ROUTES.EXT_SHARED_RESOURCE_BASE}${sharedResourceType}/${sharedResourceId}`} />
    }

    return <React.Fragment>{children}</React.Fragment>
})