import ACTIONS from 'application/constants'

export const connectUser = (sharedResourceId, ssoAccessToken, identityToken) => ({
    type: ACTIONS.REGISTRATION_CONNECT_USER_REQUEST,
    sharedResourceId,
    ssoAccessToken,
    identityToken
})

export const connectUserCallback = (ssoAccessToken) => ({
    type: ACTIONS.REGISTRATION_CONNECT_USER_CALLBACK,
    ssoAccessToken
})

//create account
export const createAccount = (formData, sharedResourceId, idToken, ssoAccessToken) => ({
    type: ACTIONS.REGISTRATION_CREATE_ACCOUNT_REQUEST,
    formData,
    sharedResourceId,
    idToken,
    ssoAccessToken
})

export const createAccountCallback = (ssoAccessToken) => ({
    type: ACTIONS.REGISTRATION_CREATE_ACCOUNT_CALLBACK,
    ssoAccessToken
})