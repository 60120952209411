import ACTIONS from 'application/constants'

export const showSidebar = () => ({
    type: ACTIONS.REALTIME_NOTIFICATIONS_SHOW_SIDEBAR
})

export const hideNewNotifications = () => ({
    type: ACTIONS.REALTIME_NOTIFICATIONS_SET_NEW_NOTIFICATIONS_TO_FALSE
})

export const hideSidebar = () => ({
    type: ACTIONS.REALTIME_NOTIFICATIONS_HIDE_SIDEBAR
})

export const addNotification = (notification) => ({
    type: ACTIONS.REALTIME_NOTIFICATIONS_ADD_NOTIFICATION,
    notification
})