import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { TextArea, TextInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import useStatefulForm from 'application/components/hooks/use_stateful_form'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import TaskUploader from 'application/components/building_blocks/task_uploader'
import { PushBottom32 } from 'application/components/pages/_common'



const CreateMessagFromTrackingComponent = ({
    onCreateMessage,
    dialog,
    abort,
    setAbortBlocked,
    setDataChanged
}) => {
    const { t } = useTranslation()
    const [files, setFiles] = useState([])
    const defaultValues = {
        subject: '',
        body: ''
    }

    const { 
        register,
        errors,
        handleSubmit,
        getValues, 
        updateFormState
    } = useStatefulForm({ defaultValues })

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }


    const onSubmitSuccess = () => {
        onCreateMessage(
            dialog.name, 
            {
                message: getValues(),
                files
            },
            { damageId: dialog.payload?.damage?.id }
        )
    }

    const setForm = () => {
        onDataChange()
        updateFormState()
    }
    return <React.Fragment>
        <DialogMainContent>
            <TextInput
                label={t('Subject')}
                name='subject'
                register={register}
                validate={{notEmpty: validationMethods.notEmpty}}
                error={errors.subject}
                onChange={setForm}
            />
            <TextArea
                label={t('Your message')}
                name='body'
                register={register}
                validate={{notEmpty: validationMethods.notEmpty}}
                error={errors.body}
            />
            <TaskUploader
                onFileAdded={() => { }}
                onFilesAdded={(newFiles) => { 
                    setFiles([...files, ...newFiles])
                }}
                initialUploadQueue={files}
                disableActiveState={true}
            />
            <PushBottom32 />
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)}
            submitText='Send'
        />
    </React.Fragment>
    
}

export default withDialogDataLoader(CreateMessagFromTrackingComponent)