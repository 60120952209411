import { connect } from 'react-redux'
import { updateOpponentDriver } from 'application/redux/actions/pages/damage_manager/tasks/forms'
import { TaskDamageOpponentDriverForm } from './component'



const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
    return {
        onSave: (
            damageId, 
            damageData, 
            originalDamageData
        ) => dispatch(
            updateOpponentDriver(
                damageId, 
                damageData, 
                originalDamageData
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageOpponentDriverForm)