import React from 'react'
import { useTranslation } from 'react-i18next'
import { PhoneNumberInput, TextInput } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import TwoColumns from '../_common'



export const ContactDataPartialForm = ({
    namePhoneNumber = 'contact.phoneNumber',
    errorNamePhoneNumber = null,
    nameEmail='contact.email',
    errorNameEmail = null,
    defaultValuePhoneNumber = undefined,
    register,
    onChange = () => {},
    setValue,
    control,
}) => {
    const {t} = useTranslation()
    const { notEmpty, mustBeEmail } = validationMethods

    return  <TwoColumns 
        columnLeft={ 
            <TextInput
                label={t('Email')}
                name={nameEmail}
                register={register}
                validate={{
                    mustBeEmail
                }}
                error={errorNameEmail}
                onChange={onChange}
            />
        }
        columnRight={
            <PhoneNumberInput
                defaultValue={defaultValuePhoneNumber}
                validate={{notEmpty}}
                control={control}
                label={t('Phone number (mobile or landline)')}
                name={namePhoneNumber}
                register={register}
                error={errorNamePhoneNumber}
                onChange={onChange}
                setValue={setValue}
                allowLandLine={true}
            />
        }
    />
}