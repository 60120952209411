import React from 'react'
import { useTranslation } from 'react-i18next'
import ContextMenu from 'application/components/controls/context_menu'
import ContextMenuItem from 'application/components/controls/context_menu_item'
import { ContextMenuWrapper } from 'application/components/tables/_common/fragments'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { ROUTES } from 'application/constants'
import { withRouter } from 'react-router-dom'
import { ForSpecificBranchPermissions } from 'application/components/context/permissions'
import { USER_PERMISSONS } from 'application/constants'

const TasksTableContextMenu = withRouter(({
    task, 
    onDeleteTask, 
    onDownloadDocuments,
    match
}) => {
    const {t} = useTranslation()
    const handleClick = (e, handle) => {
        e.preventDefault()
        e.stopPropagation()
        handle()
    }
    return <ForSpecificBranchPermissions
        branchId={task.branch.id}
        permissions={[
            USER_PERMISSONS.DAMAGE.READ,
            USER_PERMISSONS.TASK.DELETE,
            USER_PERMISSONS.DOCUMENT.READ
        ]}
        allNeeded={false}
    >
        <ContextMenuWrapper>
            <ContextMenu iconKey='create'>
                <ForSpecificBranchPermissions
                    branchId={task.branch.id}
                    permissions={[USER_PERMISSONS.DAMAGE.READ]}
                >
                    <ContextMenuItem
                        to={getAccountLinkHref(match, ROUTES.DAMAGE_MANAGER_BASE + task.damage.id)}
                        iconKey='north_east'
                        title={t('To damage manager')}
                        isLinked={true}
                    >
                        {t('To damage manager')}
                    </ContextMenuItem>
                </ForSpecificBranchPermissions>
                <ForSpecificBranchPermissions
                    branchId={task.branch.id}
                    permissions={[USER_PERMISSONS.TASK.DELETE]}
                >
                    <ContextMenuItem
                        disabled={!task.isDeletable }
                        onListElementClick={(event) => {
                            handleClick(event, () => {
                                onDeleteTask(task)
                            })
                        }}
                        iconKey='delete_outline'
                        title={t('Delete task')}
                    >
                        {t('Delete task')}
                    </ContextMenuItem>
                </ForSpecificBranchPermissions>
                <ForSpecificBranchPermissions
                    branchId={task.branch.id}
                    permissions={[USER_PERMISSONS.DOCUMENT.READ]}
                >
                    {
                        task.attachments > 0 && <ContextMenuItem
                            onListElementClick={(event) => {
                                handleClick(event, () => {
                                    onDownloadDocuments(task)
                                })
                            }}
                            iconKey='get_app'
                            title={t('Download documents')}
                        >
                            {t('Download documents')}
                        </ContextMenuItem>
                    }
                </ForSpecificBranchPermissions>
            </ContextMenu>
        </ContextMenuWrapper> 
    </ForSpecificBranchPermissions>
})

export default TasksTableContextMenu