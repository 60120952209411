import { connect } from 'react-redux'
import CallbackPage from './component'
import { connectUser } from 'application/redux/actions/registration'

const mapDispatchToProps = dispatch => {
    return {
        onConnectUser: (sharedResourceId, ssoAccessToken, identityToken) => dispatch(connectUser(sharedResourceId, ssoAccessToken, identityToken))
    }
}

export default connect(
    state => ({
        userConnected: state.registration.userConnected
    }),
    mapDispatchToProps
)(CallbackPage)