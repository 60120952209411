import React from 'react'
import PropTypes from 'prop-types'


const CustomerName = (
    { customer = null }
) => {

    if(customer === null){
        return null
    }

    const {
        firstName = '',
        lastName = ''
    } = customer

    const customerName = customer.isCompany 
        ? customer.companyName
        : `${firstName} ${lastName}`

    return <React.Fragment>
        {customerName}
    </React.Fragment>
}

CustomerName.propTypes = {
    customer: PropTypes.object
}

export {CustomerName}