  
import React from 'react'
import { useHistory } from 'react-router-dom'
import { CallbackComponent } from 'redux-oidc'
import { defaultUserManager } from 'application/common/user_manager'
import { ROUTES } from 'application/constants'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'

export default () => {
    const history = useHistory()
    return (
        <CallbackComponent
            userManager={defaultUserManager}
            successCallback={(user) => {

                const redirect = user.state !== undefined 
                && user.state.returnUrl !== undefined 
                    ? user.state.returnUrl 
                    : ROUTES.PREFERRED_ACCOUNT_PRELOADER
                    
                history.push(redirect)
            }}
            errorCallback={error => {
                console.error(error)
                history.push(ROUTES.GENERIC_ERROR)
            }}
        >
            <PagePreloader text='starting e.sy 360' />
        </CallbackComponent>
    )
}