import styled from 'styled-components'

export const StyleButtonToggle = styled.button`
    background: transparent;
    cursor: default;
    padding: 0;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    font-size:${props => props.theme.fontSize.standard};
    justify-content: space-between;
    margin-bottom: 4px;
    width: 100%;
`

export const StyleDisplayToggle = styled.div`
    display: ${props => props.active ? 'block' : 'none'};
`

export const ParticipantsWrapper = styled.div`
    padding: 0;
    color: ${props => props.theme.color.anthracite};
`

export const ParticipantsInstitution = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    padding-bottom: 8px;
`

export const ParticipantsName = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    display: flex;
    flex-direction: row;
    align-items: center;

    &>i{
        color: ${props => props.theme.color.gray70};
        margin-right: 4px;
        font-size: ${props => props.theme.fontSize.headline3};
    }
    &>img{
        height: 20px;
    }
`
export const ParticipantInfo = styled.span`
    font-size: ${props => props.theme.fontSize.standard};
    margin-top: -1px;
`

export const ParticipantDetailWrapper = styled.div`
    padding: 12px;
    background-color: ${props => props.theme.color.gray5};
    display: flex;
    flex-direction: column;
    max-width: 300px;

    div {
        padding-bottom: 12px;
    }

    div > i {
        margin-right: 16px;
    }
`