import { connect } from 'react-redux'
import ForActiveBranch from './component'

const mapStateToProps = (state) => {
    return {
        branchActive: state.auth.currentBranch !== null
    }
}

export default connect(
    mapStateToProps
)(ForActiveBranch)