import { connect } from 'react-redux'
import ChooseAccountEffects from './effects'
import { 
    setCurrentAccountLoadedState, 
    getPreferredAccount,
    setAccountAsPreferred,
    unsetAccountAsPreferred,
    getAccounts
} from 'application/redux/actions/auth'
import { resetAccessibleBranches } from 'application/redux/actions/common/branches'
import { resetCurrentBranch } from 'application/redux/actions/auth/current_branch'
import { resetCurrentUser } from 'application/redux/actions/auth/current_user'
import { resetLogin } from 'application/redux/actions/auth/log_in'
import { resetAccountRelatedData } from 'application/redux/actions'

const mapStateToProps = (state) => {
    return {
        accountsTotal: state.auth.accounts.total,
        accountsFound : state.auth.accounts.found,
        permissionsFound : state.auth.permissionsFound,
        accountsLoading: state.auth.accounts.loading,
        accountsLoaded: state.auth.accounts.loaded,
        currentAccount: state.auth.currentAccount,
        firstAccountId: state.auth.accounts.firstAccountId,
        firstAccountLocked: state.auth.accounts.firstAccountLocked,
        preferredAccount: state.auth.preferredAccount,
        accountsList: state.auth.accounts.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        unLoadCurrentAccount: () => {
            dispatch(
                setCurrentAccountLoadedState(
                    false, 
                    false
                )
            )
            dispatch(resetLogin())
            dispatch(resetCurrentUser())
            dispatch(resetCurrentBranch())
            dispatch(resetAccessibleBranches())
            dispatch(resetAccountRelatedData())
        },
        onLoadPreferredAccount: () => dispatch(
            getPreferredAccount()
        ),
        onSetAsPreferredAccount: (account) => {
            dispatch(
                setAccountAsPreferred(account)
            )
        } ,
        onUnsetAsPreferredAccount: () => {
            dispatch(
                unsetAccountAsPreferred()
            )
        },
        onLoadAccounts: (
            offset, 
            limit, 
            orderBy, 
            direction, 
            filterQuery
        ) => {
            dispatch(
                getAccounts(
                    offset, 
                    limit, 
                    orderBy, 
                    direction, 
                    filterQuery
                )
            )
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChooseAccountEffects)