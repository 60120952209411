import ACTIONS from 'application/constants'

export const createDamageRequest = (payload) => ({
    type: ACTIONS.EXT_CREATE_DAMAGE_REQUEST,
    payload
})

export const createDamageKickstartRequest = (payload) => ({
    type: ACTIONS.EXT_CREATE_DAMAGE_KICKSTART_REQUEST,
    payload
})

export const createDamageSuccess = (damageId) => ({
    type: ACTIONS.EXT_CREATE_DAMAGE_SUCCESS,
    damageId
})

export const sendDocumentsRequest = (damageId, files, categoryId) => ({
    type: ACTIONS.EXT_SEND_DOCUMENTS_REQUEST,
    damageId, files, categoryId
})

export const sendDocumentsSuccess = () => ({
    type: ACTIONS.EXT_SEND_DOCUMENTS_SUCCESS
})