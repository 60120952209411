import { connect } from 'react-redux'
import { MassOperationsDocumentSelect as MoDocumentComponent } from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { fetchMoByName } from 'application/components/building_blocks/mass_operations/helper_functions'

const mapStateToProps = (state, ownProps) => {

    const mo = fetchMoByName(
        state,
        ownProps
    )

    return {
        categories: state.common.categories,
        mo,
        permissions: state.auth.permissions
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onOpenMoDialog: (
            dialogName,
            initiallyLoaded,
            payload,
            dialogTitle
        ) => {

            dispatch(
                openDialogByName(
                    dialogName,
                    initiallyLoaded, 
                    payload, 
                    dialogTitle
                )
            )
        }
    }
}

export const MassOperationsDocumentSelect =  connect(
    mapStateToProps,
    mapDispatchToProps
)(MoDocumentComponent) 