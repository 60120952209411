import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { useForm } from 'react-hook-form'
import { FontIcon } from '@ec/ui-controls-react'
import { DialogMainContent, SubHeadline } from 'application/components/fragments/dialog'
// import { RadioCheckBoxGroupWithFreetextField, Select } from 'application/components/controls/form'
import { Footer } from 'application/components/dialogs/_common/footer'
// import { BreakLine32 } from 'application/components/pages/external/welcome/fragments'
import { withRouter } from 'react-router-dom'
// import { validationMethods } from 'application/common'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { PushBottom32, Spacer } from 'application/components/pages/_common'
import { INFOBOX, COMMISSION_STATE, IMAGES } from 'application/constants'
import InfoBox from 'application/components/controls/info_box'
// import { GravatarComponent } from 'application/components/building_blocks/gravatar'
// import { useGravatar } from 'application/components/hooks/use_gravatar'
import { BreakLine16 } from 'application/components/building_blocks/document_sidebar/fragments'
// import { useEffect } from 'react'
// import { useCustomerDataPlausibility } from 'application/components/hooks'
import styled from 'styled-components'
import Image from 'application/components/controls/image'

const StyledLawyer = styled.div`
    width: 200px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid ${props => props.isActive ? props.theme.color.primary : props.theme.color.gray15};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & img{
        height: 90% !important;
    }
    & span{
        margin-top: 20px;
        color: ${props => props.theme.color.anthracite};
    }
`

const CreateLawyers = (
    { 
        abort, 
        updateDialog, 
        setAbortBlocked, 
        setDataChanged, 
        onSearchLawfirm, 
        dialog, 
        onCreateLawyer, 
        match,
        onChangeCustomerData
    }
) => {
    const { t } = useTranslation()
    // const { 
    //     register, 
    //     errors, 
    //     handleSubmit, 
    //     setValue
    // } = useForm()
   
    // const [lawfirmSearchActive, setLawfirmSearchActive] = useState(false) //to prevent unwanted server calls
    const [selectedLawfirm, setSelectedLawfirm] = useState(null)
    // const [
    //     selectLawyerBySearch, 
    //     //setSelectLawyerBySearch  //use to switch select / search mode
    // ] = useState(false)

    // const onDataChange = () => {
    //     setAbortBlocked(true)
    //     setDataChanged(true)
    // }

    const {
        // lawyersList, 
        // lawyersListLoading, 
        // preloadedLawyersList,
        changeMode, 
        lawfirm,
        // customer,
        // damageId
    } = dialog.payload

    const preloadedLawyersList = {
        webakteAccounts: [
            {
                name: 'Dr. Mielco und Kollegen',
                id: '12345',
                logo: IMAGES.LAWYER_1
            },
            {
                name: 'Petralli und Partner',
                id: '12346',
                logo: IMAGES.LAWYER_2
            },
            {
                name: 'Veugt Partner',
                id: '12347',
                logo: IMAGES.LAWYER_3
            },
            {
                name: 'Ossendorf und Coll.',
                id: '12348',
                logo: IMAGES.LAWYER_4
            },
            {
                name: 'Prof. Online und Kollegen',
                id: '12349',
                logo: IMAGES.LAWYER_5
            },
        ]
    }

    // const preloadedLawyersListOptions = preloadedLawyersList.webakteAccounts.map(wa => ({
    //     value: wa.id,
    //     label: wa.name
    // }))

    // const firstName = customer.firstName,
    //     lastName = customer.lastName,
    //     isCompany = customer.isCompany,
    //     companyName = customer.companyName

    // const {
    //     getInitials,
    //     getFullName,
    //     setFirstName,
    //     setLastName,
    //     setCompanyName
    // } = useGravatar(
    //     firstName,
    //     lastName,
    //     isCompany,
    //     companyName
    // )

    // useEffect(
    //     () => {
    //         setFirstName(firstName)
    //     },
    //     [firstName, setFirstName]
    // )

    // useEffect(
    //     () => {
    //         setLastName(lastName)
    //     },
    //     [lastName, setLastName]
    // )

    // useEffect(
    //     () => {
    //         setCompanyName(companyName)
    //     },
    //     [companyName, setCompanyName]
    // )

    const lawfirmState = lawfirm?.state || null
    const changeForbidden = dialog.isLoaded && changeMode && lawfirmState === COMMISSION_STATE.PENDING
    const hasLawyersList = dialog.isLoaded && preloadedLawyersList.webakteAccounts.length > 0
    const showWarning = changeForbidden || !hasLawyersList

    // const availableLawfirms = lawyersList
    // const selectLawfirmOptions = availableLawfirms.map(
    //     a => (
    //         { 
    //             value: a.id, 
    //             label: a.name, 
    //             ...a
    //         }
    //     )
    // )

    // const successMsg = t('Lawyer commissioned')

    // const onSubmitSuccess = () => {
    //     onCreateLawyer(
    //         dialog.name, 
    //         selectedLawfirm, 
    //         successMsg, 
    //         match, 
    //         changeMode
    //     )
    // }

    // const addSelectedLawfirm = (lawfirm) => {
    //     lawfirm !== null && setSelectedLawfirm(lawfirm)
    // }
    
    // const handleSearchLawfirm = search => {
    //     if (lawfirmSearchActive && search.length > 2) {
    //         onSearchLawfirm(dialog.name, search)
    //     }
    // }

    // const handleChange = (e) => {
    //     onDataChange()
    //     addSelectedLawfirm(e)
    //     updateDialog(dialog.name)
    // } 

    // const {
    //     customerDataIsPlausible
    // } = useCustomerDataPlausibility(customer)

    // const customerInfoText = customerDataIsPlausible 
    //     ? 'It is recommended to check the customer data before hiring a lawyer.'
    //     : 'Please provide at least email or phone number.'

    // const customerButtonText = customerDataIsPlausible
    //     ? 'Check customer data'
    //     : 'Complete customer data'

    // const customerInfoBoxType = customerDataIsPlausible
    //     ? INFOBOX.TYPES.STANDARD
    //     : INFOBOX.TYPES.WARNING
   
    const handleOnSubmit = () => {
        if(selectedLawfirm !== null){
            onCreateLawyer(
                dialog.name, 
                selectedLawfirm, 
                'lawfirm created', 
                match, 
                changeMode
            )
        }
    }


    if(showWarning){
        return <React.Fragment>
            <DialogMainContent>

                <InfoBox 
                    type={INFOBOX.TYPES.WARNING} 
                    direction='row' 
                    cssMargin='0' 
                >
                    <FontIcon icon='info_outline' />
                    <Spacer basis='10px' />
                    <span>
                        {
                            changeForbidden
                                ? t('The connection to the previous lawfirm has not been established yet. Please wait a couple of minutes and try again.')
                                : t('No connected lawfirms found.')
                        }
                    </span>
                </InfoBox>

            </DialogMainContent>
            <Footer
                submitText={t('Confirm change')}
                onAbort={abort}
                disabled={true}
            />
        </React.Fragment>
    }
    
    return <React.Fragment>
        <DialogMainContent>
            <FlexBox flexDirection='row'>
                <FlexBox flexDirection='column'>
                    <SubHeadline>{t('Choose lawfirm')}</SubHeadline>
                    <BreakLine16 />
                    <FlexBox 
                        flexDirection='row'
                        flexWrap='wrap'
                    >
                        {
                            preloadedLawyersList.webakteAccounts.map((lawfirm) => {
                                return <StyledLawyer onClick={() => {setSelectedLawfirm(lawfirm)}} key={lawfirm.id} isActive={selectedLawfirm?.id === lawfirm.id}>
                                    <Image data={lawfirm.logo} />
                                </StyledLawyer>
                            })
                        }
                    </FlexBox>           
                </FlexBox>

            </FlexBox>
            
            
            <PushBottom32/>
        </DialogMainContent>
        <Footer
            submitText={changeMode ? t('Confirm change') : t('Send commission')}
            onAbort={abort}
            disabled={selectedLawfirm === null}
            onSubmit={handleOnSubmit}
        />
    </React.Fragment>

}

export default withRouter(withDialogDataLoader(CreateLawyers))