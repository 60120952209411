import { connect } from 'react-redux'

import { getTasks } from 'application/redux/actions/pages/damage_manager/tasks'
import TaskSelectEffects from './effects'

const mapStateToProps = state => ({
    tasksList: state.pages.damageManager.tasks.tasksList,
    tasksLoaded: state.pages.damageManager.tasks.tasksLoaded,
    tasksLoading: state.pages.damageManager.tasks.tasksLoading,
    tasksListDamageId: state.pages.damageManager.tasks.tasksListDamageId
})

const mapDispatchToProps = (dispatch) => ({
    onLoadTasks: (damageId) => dispatch(getTasks(damageId))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskSelectEffects)