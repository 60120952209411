import React, { 
    // useEffect, 
    useMemo, 
    useState 
} from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'

import IconButton from 'application/components/controls/icon_button/component'
import { StyleButtonToggle, StyleDisplayToggle, StyledToggleText, StyledWrapperSubCategory, StyledAttachmentsContainer, StyledToggleTextSecondary } from './fragments'
import { Spacer } from 'application/components/pages/_common'
import { withRouter } from 'react-router-dom'
import { MoDocCenterThumbnail } from 'application/components/building_blocks/mo_doc_center_thumbnail'
import { exists } from 'application/common/data_helpers'


const DocCenterToggle = ({
    category, 
    theme, 
    documents, 
    fullAttachments, 
    damageId, 
    onAddDocument, 
    match,
    moName = null,
    onAttachmentClick
}) => {
    const {t} = useTranslation()
    const appliedDocuments = useMemo(() => {
        const isApplied = (document) => exists(document.category) 
            ? document.category.id === category.id 
            : category.isDefault

        return documents.filter(isApplied)
    }, [category.id, category.isDefault, documents])

    const hasChildren = useMemo(()=>{
        return category.categories !== undefined && category.categories.length > 0
    }, [category.categories])

    const hasContent = useMemo(()=>{
        return appliedDocuments.length > 0
    }, [appliedDocuments.length])

    // open documents list by default if it contains attachments:
    const [isOpen, setIsOpen] = useState(hasChildren)
    
    const handleAddDocument=() => {
        onAddDocument(category.id, damageId)
    }

    const isExtern = match.params.sharedResourceId !== undefined

    // use this effect to update the opened state whenever the category of a document changes
    // useEffect(()=>{
    //     setIsOpen(hasContent || hasChildren)
    // }, [hasChildren, hasContent])

    return <StyledWrapperSubCategory>
        <StyleButtonToggle 
            onClick={() => setIsOpen(!isOpen)}
        >
            <FontIcon 
                icon={isOpen ? 'expand_more' : 'chevron_right'} 
                fontSize={theme.fontSize.medium} 
                color={theme.color.gray70} 
            />
            <StyledToggleText>
                {category.name} {hasContent && <StyledToggleTextSecondary>
                    ({appliedDocuments.length})
                </StyledToggleTextSecondary>} 
            </StyledToggleText>
            <Spacer />
            {
                !hasChildren && !isExtern && <IconButton 
                    iconKey='add' 
                    width='24px' 
                    height='24px' 
                    iconSize='24px' 
                    onButtonClick={handleAddDocument}
                />
            }
        </StyleButtonToggle>
        <StyleDisplayToggle active={isOpen}>
            {
                hasContent
                    ? <StyledAttachmentsContainer>
                        {
                            appliedDocuments.map(
                                (ad) => {
                                    return <MoDocCenterThumbnail 
                                        key={ad.id}
                                        attachmentId={ad.id}
                                        attachmentName={ad.name}
                                        onAttachmentClick={onAttachmentClick}
                                        damageId={match.params.damageId}
                                        taskId={ad.resourceId}
                                        match={match}
                                        moName={moName}
                                        extendedData={
                                            {
                                                category,
                                                isExportedToLawFirm: ad.isExportedToLawFirm
                                            }
                                        }
                                    />
                                }
                            )
                        }
                    </StyledAttachmentsContainer>
                    : !hasChildren && <StyledAttachmentsContainer>
                        {t('No documents in this category')}
                    </StyledAttachmentsContainer>
            }
            {
                hasChildren && category.categories.map(sc => {

                    return <DocCenterToggle
                        key={sc.id}
                        category={sc}
                        theme={theme}
                        documents={documents}
                        fullAttachments={fullAttachments}
                        damageId={damageId}
                        onAddDocument={onAddDocument}
                        match={match}
                        moName={moName}
                        onAttachmentClick={onAttachmentClick}
                    />
                })
            }
        </StyleDisplayToggle>
    </StyledWrapperSubCategory>
}

export default withRouter(withTheme(DocCenterToggle))