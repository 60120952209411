import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { Bar, Cell, Group } from 'application/components/pages/internal/_title_bars/_common'
import { ROUTES, USER_PERMISSONS, IMAGES } from 'application/constants'
import { Paragraph } from 'application/components/fragments/typography'
import RealtimeNotificationsIndicator from 'application/components/building_blocks/realtime_notifications_indicator'
import  UserMenu  from 'application/components/building_blocks/user_menu'
import { getAccountLinkHref, getBranchLinkHref } from 'application/common/route_helpers'
import { withRouter } from 'react-router-dom'
import IconLink from 'application/components/controls/icon_link/component'
import { withTheme } from 'styled-components'
import DamageStatusSelect from 'application/components/controls/damage_status_select'
import DamageAssigneeSelect from 'application/components/controls/damage_assignee_select'
import {VehicleInfo} from 'application/components/controls/vehicle_info'
import { exists } from 'application/common/data_helpers'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'


const TitleBar = ({
    vehicle,
    damageLoaded,
    damageId,
    openDialogCreateLawyers,
    theme,
    hasLawfirm,
    lawfirmLoaded,
    currentBranchId,
    hasMultipleBranches,
    match,
    onOpenVehicleAndKeeperQRCodeCreationDialog,
    branchId
}) => {
    const {t} = useTranslation()

    const handleCommissionLawfirm = () => {
        openDialogCreateLawyers(damageId)
    }

    const handleOpenQRCodeCreationDialog = () => {
        const {
            licensePlate = null
        } = vehicle
        
        const payload =  {
            licensePlate,
            branchId,
            customerId: vehicle.keeper.customerId
        }
        onOpenVehicleAndKeeperQRCodeCreationDialog(payload)
    }

    //current branch id should always be given, but just in case the application storage is deleted
    const exitUrl = exists(currentBranchId) && hasMultipleBranches
        ? getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_DASHBOARD, currentBranchId) 
        : getAccountLinkHref(match, ROUTES.DASHBOARD)
    
    return (
        <Bar>
            <Group>
                <Cell
                    paddingRight='0'
                    paddingLeft='0'
                >
                    <RealtimeNotificationsIndicator />
                </Cell>
                <Cell >
                    <VehicleInfo 
                        vehicle={vehicle} 
                        damageLoaded={damageLoaded} 
                    />
                </Cell>
                <Cell>
                    <Paragraph 
                        cssAlign='right' 
                        cssMargin='0'
                    >
                        {t('Assignee')}
                    </Paragraph>
                </Cell>
                <Cell width='200px'>
                    <DamageAssigneeSelect />
                </Cell>
                <Cell>
                    <Paragraph 
                        cssAlign='right' 
                        cssMargin='0'
                    >
                        {t('Status')}
                    </Paragraph>
                </Cell>
                <Cell width='200px'>
                    <DamageStatusSelect />
                </Cell>
            </Group>
            <Group>
                <ForAnyBranchPermissions
                    permissions={[
                        USER_PERMISSONS.CUSTOMER.UPDATE,
                        USER_PERMISSONS.CUSTOMER.READ,
                    ]} 
                    allNeeded={true}
                >
                    <Cell>
                        <ActionButton
                            buttonText={t('Create QR code')}
                            onButtonClick={handleOpenQRCodeCreationDialog}
                        // disabled={!lawfirmLoaded || hasLawfirm}
                        />
                    </Cell> 
                </ForAnyBranchPermissions>
                <Cell>
                    <ActionButton
                        buttonText={t('Commission lawfirm')}
                        onButtonClick={handleCommissionLawfirm}
                        disabled={!lawfirmLoaded || hasLawfirm}
                    />
                </Cell>
                <Cell paddingRight='0' >
                    <UserMenu
                        image={IMAGES.USER_KE}
                    />
                </Cell>
                <IconLink
                    to={exitUrl}
                    iconKey="close"
                    cssHeight='48px'
                    cssWidth='48px'
                    iconSize='24px'
                    textAlign='center'
                    cssHoverBackground={theme.color.black10}
                    title={t('To dashboard')}
                />
            </Group>
        </Bar>
    )
}


export default withTheme(withRouter(TitleBar))
