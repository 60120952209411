
import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'

import ContextMenuGroupMembers from './context_menu'

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        onEditUserGroups: (payload, dialogTitle) => dispatch(
            openDialogByName(
                DIALOG.NAME.EDIT_USER_GROUPS_FROM_LIST,
                false, 
                payload, 
                dialogTitle
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( ContextMenuGroupMembers )