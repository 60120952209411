//reducer for the generic title bar
import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.registration, action) => {
    switch (action.type) {
    case ACTIONS.REGISTRATION_CONNECT_USER_CALLBACK:
        return {
            ...state,
            userConnected: true,
            ssoAccessToken: action.ssoAccessToken
        }
    case ACTIONS.REGISTRATION_CREATE_ACCOUNT_CALLBACK:
        return {
            ...state,
            accountCreated: true
        }
    default:
        return state
    }
}
