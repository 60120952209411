
import React from 'react'
import { RoutedPagination } from 'application/components/building_blocks/routed_pagination'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ItemsPerPageSelection } from 'application/components/tables/_common'
import PropTypes from 'prop-types'


const TableFooter = (
    {
        pagination,
        tableName = '',
        elementsTotal = 0
    }
) => {
    if (pagination.hidePagination) {
        return <React.Fragment/>
    }

    const itemsPerPage = pagination.getItemsPerPage()

    return <FlexBox paddingTop='32px' paddingBottom='16px'>
        <FlexBox>
            <RoutedPagination
                totalSize={elementsTotal}
                parameterNames={pagination}
                itemsPerPage={itemsPerPage}
            />
        </FlexBox>
        <FlexBox justifyContent='flex-end'>
            <ItemsPerPageSelection 
                tableName={tableName}
                offsetKey={pagination.offset}
                limitKey={pagination.limit}
                options={pagination.itemsPerPageOptions}
                selectedOptionValue={itemsPerPage}
            />
        </FlexBox>
    </FlexBox>                 
}

TableFooter.propTypes = {
    pagination: PropTypes.object.isRequired,
    tableName: PropTypes.string,
    elementsTotal: PropTypes.number    
}

export {TableFooter}