import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { connect } from 'react-redux'
import CreateUserComponent from './component'
import { withGroupsLoader } from 'application/components/higher_order_components'

const mapStateToProps = (state, props) => {
    return  {
        allBranches: state.common.allBranches
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateUser: 
        (
            dialogName, 
            payload,
            metaData
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName, 
                    payload,
                    metaData
                )
            )
        },
    }
}

export const CreateUser = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withGroupsLoader(CreateUserComponent)
)