import React from 'react'
import Moment from 'moment'

const DateComponent = ({
    date,
    format = 'DD.MM.YYYY'
}) => {
    return <React.Fragment>
        {new Moment(date).format(format)}
    </React.Fragment>
}

export default DateComponent