import React, { useMemo } from 'react'
import { HeaderContainer } from './fragments'
import { useQuery } from 'application/components/hooks/use_query'
import { Headline2, FormHeadline } from 'application/components/fragments/typography'
import { ROUTE_PARAMETERS } from './route_parameters'
import { IMAGES } from 'application/constants'
import Image from 'application/components/controls/image'

export const ExtCreateDamageHeader = ({children}) => {
    const query = useQuery()
    const accountName = query.getStringParam(
        ROUTE_PARAMETERS.ACCOUNT_NAME_PARAM
    )    

    const accountImage = useMemo(() => {
        if(accountName.toLowerCase().includes('egger')){
            return IMAGES.ACCOUNT_EGGER
        }
        if(accountName.toLowerCase().includes('könig')){
            return IMAGES.ACCOUNT_KOENIG

        }
        if(accountName.toLowerCase().includes('nahetal')){
            return IMAGES.ACCOUNT_NAHETAL

        }
        return IMAGES.ACCOUNT_ZYRULL
    }, [accountName])
    
    return <HeaderContainer>
        <Headline2>
            <Image data={{...accountImage, height:'68px'}} />
        </Headline2>
        <Headline2>
            {accountName}
        </Headline2>
        
        <FormHeadline>
            Neue Schadenmeldung
        </FormHeadline>
        {children}
    </HeaderContainer>
}