import { AndOperator, StartClosure, EndClosure } from '@ec/filter-sort-page-query-js'

export const composeFilterQuery = (filters = [], operator = new AndOperator()) => {
    let composedQuery = []

    const queries = filters
        .map(filter => filter.getQuery())
        .filter(query => query.length > 0)

    queries.forEach((query, i) => {
        composedQuery.push(new StartClosure())

        query.forEach(queryItem => {
            composedQuery.push(queryItem)
        })
        composedQuery.push(new EndClosure())
       
        if( i < queries.length - 1 ){
            composedQuery.push(operator)
        }
    })

    return composedQuery
}