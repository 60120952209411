import { connect } from 'react-redux'
import WidgetEffects from './effects'
import { requestWidgetData } from 'application/redux/actions/widgets'
import { isPermitted } from 'application/components/context/permissions'

const mapStateToProps = (state, props) => {

    const widget = state.widgets.registeredWidgets.find(w => w.key === props.widgetKey)

    return {
        accountDependent: widget ? widget.accountDependent : false,
        title: widget ? widget.title : '',
        payload: widget ? widget.payload : {},
        isLoaded: widget ? widget.isLoaded : false,
        isLoading: widget ? widget.isLoading : false,
        loadingFailed: widget ? widget.loadingFailed : false,
        widgetKey:  widget ? widget.key : '',
        type: widget ? widget.type : '',
        hideHeadline: widget ? widget.hideHeadline : false,
        isRegistered: widget !== undefined,
        isPermitted: widget ? isPermitted(state.auth.allPermissions, state.auth.permissions, widget.permissions, widget.permissionScope, widget.allPermissionsNeeded) : false,
        loggedIn: state.auth.loggedIn,
        isInternal: state.sharedResource.id === null,
        currentAccountLoaded: state.auth.currentAccountLoaded,
        currentAccountLoading: state.auth.currentAccountLoading,
        currentAccountId: state.auth.currentAccount === null ? null : state.auth.currentAccount.id,
        dependentWidgetKeys: widget ? widget.dependentWidgetKeys : [],
        dependsOnOtherWidget: widget ? widget.dependsOnOtherWidget : false
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadWidgetData: (
            key, 
            type, 
            payload, 
            match, 
            urlParams, 
            dependentWidgetKeys = [], 
            dependsOnOtherWidget = false
        ) => dispatch(
            requestWidgetData(
                key,
                type, 
                payload, 
                match, 
                urlParams,
                dependentWidgetKeys,
                dependsOnOtherWidget
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WidgetEffects)