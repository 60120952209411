import { fetchRequestBuilder, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'


export const getCustomerById = (params) => {
    return {
        authenticated: () => {
            const request = fetchRequestBuilder( 
                `customers/${params.customerId}`, 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        },
        sharedResource: () => null
    }
}