import { ROUTES } from 'application/constants'
import React, {useEffect} from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import DamageManager from './component'
import PagePreloader from 'application/components/building_blocks/page_preloader'
import { useTranslation } from 'react-i18next'
import { getAccountLinkHref } from 'application/common/route_helpers'




const DamageManagerEffects = (props) => {
    const {t} = useTranslation()

    const { 
        onLoadDamage,
        match, 
        damage = null,
        damageLoaded = false,
        damageLoading = false
    } = props
    
    const { damageId } = match.params
    const loadedDamageId = damage?.id || ''

    const clickedOnNewDamage = loadedDamageId !== damageId 

    useEffect(
        () => {
            clickedOnNewDamage
            && onLoadDamage(damageId)
        }, [clickedOnNewDamage, damageId, onLoadDamage]
    )

    if (damageLoaded && !damageLoading) {
        if (damage === null) {
            return <Redirect 
                to={
                    getAccountLinkHref(
                        match, 
                        ROUTES.DAMAGE_NOT_FOUND
                    )
                }
            />  
        }
        return <DamageManager {...props}>
            {props.children}
        </DamageManager>
    }

    return <PagePreloader 
        text={t('Damagedata is loading...')} 
    />
}


export default withRouter(DamageManagerEffects)