import { moAddSelectedItemByName, moRemoveSelectedItemByName, moSetSelectedItemsByName, moSetActiveByName, moSetInactiveByName } from 'application/redux/actions/mass_operations'
import { fetchMoByName } from 'application/components/building_blocks/mass_operations/helper_functions'


export const mapStateToProps = (state, ownProps) => {
    const mo = fetchMoByName(
        state,
        ownProps
    )

    return {
        mo
    }
}


export const mapDispatchToProps = (dispatch) => {
    return {
        selectItemByMOName: (moName, item) => dispatch(
            moAddSelectedItemByName(
                moName,
                item
            )
        ),
        deselectItemByMOName: (moName, id) => dispatch(
            moRemoveSelectedItemByName(
                moName,
                id
            )
        ),
        setSelectedItemsByMOName: (moName, items) => dispatch(
            moSetSelectedItemsByName(
                moName,
                items
            )
        ),
        closeMoByName: (moName) => dispatch(
            moSetInactiveByName(moName)
        ),
        openMoByName: (moName) => dispatch(
            moSetActiveByName(moName)
        )
    }
}