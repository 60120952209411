import React from 'react'
import { useTranslation } from 'react-i18next'
import { Spacer } from 'application/components/pages/_common'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label'

export default ({dialog, onChangeAllCollapsables}) => {
    const { t } = useTranslation()
    const { licensePlate, initiallyCollapsed } = dialog.payload
    return <React.Fragment>
        {t('Invoices')}: {licensePlate}
        <Spacer />
        <IconButtonWithLabel 
            underlined={true} 
            onButtonClick={() => { onChangeAllCollapsables(dialog.name, !initiallyCollapsed) }} 
            iconKey={initiallyCollapsed ? 'expand_more' : 'expand_less'} 
            label={initiallyCollapsed ? t('show all invoices') : t('hide all invoices')} 
        />
        <Spacer basis='20px' />
    </React.Fragment>
}