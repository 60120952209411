import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from './fragments'
import LocalTime from 'application/components/controls/local_time'
import NotificationIcon from 'application/components/controls/notification_icon'
import { MetaContent } from './meta_content'
import { PushBottom8 } from 'application/components/pages/_common'
import DateComponent from 'application/components/controls/date'
import { FlexBox } from 'application/components/fragments/flex_box'


const NotificationCard = (
    {
        notification, 
        isNew = false, 
        onLinkClick = () => {}
    }
) => {

    const {payload} = notification
    const {t} = useTranslation()
    const actorName = payload.actor !== null ? payload.actor.fullName : t('System generated')

    return <Fragments.Card>
        <Fragments.CardContainer>

            <FlexBox alignItems='flex-start' >
                <FlexBox
                    flexBasis='56px' 
                    flexShrink={'0'} 
                    flexGrow={'0'} 
                    alignItems='center'
                >
                    <NotificationIcon 
                        notificationType={notification.notificationType}
                    />
                </FlexBox>
                <FlexBox            
                    flexBasis='calc(100% - 56px)' 
                    flexGrow={'1'} 
                    flexShrink={'1'} 
                    alignItems='center' 
                    cssRules='overflow:hidden;'
                    width='auto'
                >
                    <FlexBox 
                        flexDirection='column' 
                        justifyContent='space-between' 
                        cssRules='overflow:hidden;'
                    >
                        <Fragments.TextName>
                            {
                                isNew && <Fragments.IsNewMarker>{t('new')}</Fragments.IsNewMarker>}  {t(`${notification.notificationType}Title`)
                            }
                        </Fragments.TextName>
                        <PushBottom8 />  
                        <FlexBox
                            alignItems='flex-end'
                            justifyContent='space-between'
                        >
                            <Fragments.HighlightSubline>
                                {actorName} <Fragments.TimeStamp>
                                    <DateComponent
                                        date={notification.createdAt}
                                    />, <LocalTime
                                        date={notification.createdAt}
                                    />
                                </Fragments.TimeStamp>
                            </Fragments.HighlightSubline>
                        </FlexBox>
                        <PushBottom8 />  
                        <MetaContent 
                            notification={notification} 
                            onLinkClick={onLinkClick} 
                        />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </Fragments.CardContainer>
    </Fragments.Card>
}

export default NotificationCard