import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'

import {routeHelpers} from 'application/common'
import { useQuery } from 'application/components/hooks'
import { ROUTE_PARAMETERS } from 'application/constants'

const StyledTableHeaderLink = styled.div`
    & * {
        font-weight: ${props => props.isActive ? props.theme.fontWeight.standardBold : props.theme.fontWeight.standardRegular};
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        color: ${props => props.theme.color.anthracite};
    }
    & i {
        font-size: ${props => props.theme.fontSize.medium};
    }
`

const generateParams = (sortParamKey, sortParamValue, directionParamKey, directionParamValue, foreignParams = []) => {
    return [
        {
            key: sortParamKey,
            value: sortParamValue
        },
        {
            key: directionParamKey,
            value: directionParamValue
        },
        ...foreignParams,
    ]
}

const TableHeaderLink = ({
    sortParamKey, 
    sortParamValue, 
    directionParamKey, 
    defaultSortParamValue,
    defaultDirectionParamValue,
    children, 
    match,
    onLinkClick=()=>{}
}) => {
    const { buildQueryString } = routeHelpers
    const query = useQuery()
    const isDefault = query.getStringParam(sortParamKey) === '' && sortParamValue === defaultSortParamValue
    const foreignParams = query.all.filter(l => l.key !== sortParamKey).filter(l => l.key !== directionParamKey)
    let directionParamValue = query.getStringParam(directionParamKey, ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING) === ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING
        ? ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING
        : ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING

    directionParamValue = !isDefault ? directionParamValue
        : defaultDirectionParamValue === ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING
            ? ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING
            : ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING

    const params = generateParams(sortParamKey, sortParamValue, directionParamKey, directionParamValue, foreignParams)
    const to = match.url + buildQueryString(params)
    const isActive = query.getStringParam(sortParamKey) === sortParamValue || isDefault
    const iconKey = !isActive
        ? 'unfold_more'
        : query.getStringParam(directionParamKey) === ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING || (isDefault && defaultDirectionParamValue === ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING)
            ? 'expand_less' 
            : 'expand_more'
          
    return <StyledTableHeaderLink isActive={isActive} onClick={onLinkClick}>
        <NavLink to={to}>{children}<i className="material-icons">{iconKey}</i></NavLink> 
    </StyledTableHeaderLink>
}


TableHeaderLink.propTypes = {
    sortParamKey: PropTypes.string.isRequired,
    sortParamValue: PropTypes.string.isRequired,
    directionParamKey: PropTypes.string.isRequired
}

export default withRouter(TableHeaderLink)

