import React from 'react'
import PropTypes from 'prop-types'
// import CurrencyDisplay from 'application/components/controls/currency_display'
import { useTranslation } from 'react-i18next'


const CreatedInfo = (
    { activityData }
) => {
    const {t} = useTranslation()
    return <React.Fragment>
        {t('Bill number')}: {activityData.number}
        {/* <br />
        {t('Gross total')}: <CurrencyDisplay
            amount={activityData.grossTotal} 
        />
        <br />
        {t('Net total')}: <CurrencyDisplay 
            amount={activityData.netTotal} 
        /> */}
        
    </React.Fragment>
}




CreatedInfo.propTypes = {
    activityData: PropTypes.object.isRequired
}

export {CreatedInfo} 