import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FontIcon, ActionButton } from '@ec/ui-controls-react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { useQuery } from 'application/components/hooks'
import { ROUTES, ROUTE_PARAMETERS } from 'application/constants'
import { nameHelpers } from 'application/common'
import { Spacer, PushBottom32, PushBottom16 } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'
import { INFOBOX, DIALOG } from 'application/constants'
import { Paragraph, TextNavLink } from 'application/components/fragments/typography'
import { FlexBox } from 'application/components/fragments/flex_box'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { withRouter } from 'react-router-dom'
import { SimpleList } from 'application/components/building_blocks/simple_list'



const DeleteCustomer = (
    { 
        abort, 
        dialog, 
        onDeleteCustomer, 
        onEditCustomer,
        onCloseDialog,
        onOpenMenuAccordion,
        match
    }
) => {

    const {
        payload
    } = dialog 


    const {t} = useTranslation()
    const query = useQuery()


    const offset = query.getParsedParam(ROUTE_PARAMETERS.CUSTOMERS.OFFSET)
    
    const limit = query.getParsedParam(ROUTE_PARAMETERS.CUSTOMERS.LIMIT) === 0 
        ? 10 
        : query.getStringParam(ROUTE_PARAMETERS.CUSTOMERS.LIMIT)

    const direction = query.getStringParam(
        ROUTE_PARAMETERS.CUSTOMERS.ORDER_BY.DIRECTION, 
        ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING
    )

    const property = query.getStringParam(
        ROUTE_PARAMETERS.CUSTOMERS.ORDER_BY.KEY, 
        ROUTE_PARAMETERS.CUSTOMERS.ORDER_BY.VALUES.LAST_NAME
    )

    const fullName = nameHelpers.getFullName(payload)
    const successMsg = fullName + ' ' + t('has been successfully deleted')
    const hasDamages =  payload.damagesResponse.total > 0
    const messages = {
        error: [t('There is still a damage reference.')],
        success: [t('There are no damages belonging to this customer')]
    }

    const handleEditCustomer = () => {
        onEditCustomer(
            dialog.name,
            DIALOG.NAME.EDIT_CUSTOMER, 
            true,
            payload,
            'Edit customer'              
        )
    }

    return <React.Fragment>
        <DialogMainContent flexDirection='column' >
            <Paragraph cssPadding='16px 0'>
                <Trans i18nKey='do you want to delete this customer?'>
                    Do you want to delete the customer "{{fullName}}" ?
                </Trans>
            </Paragraph>
            {
                hasDamages 
                    ? <InfoBox 
                        type={INFOBOX.TYPES.ALERT} 
                        direction='row' 
                        cssMargin='0' 
                        cssPadding='16px'
                    >
                        <FontIcon icon='info_outline' />
                        <Spacer basis='10px' />
                        <FlexBox flexDirection='column'>
                            <span>
                                {t('This customer can´t be deleted.')}
                            </span>
                            <Spacer />
                            <PushBottom16/>
                            <FlexBox>
                                <SimpleList 
                                    list={messages.error}
                                    iconKey='close'
                                    type = 'error'
                                    iconSize = '14px'
                                />   
                            </FlexBox>
                            <PushBottom16/>
                            <Paragraph>
                                <TextNavLink
                                    to={
                                        getAccountLinkHref(
                                            match, 
                                            `${ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES}?${ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.CUSTOMER.ID}=${payload.id}&${ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.CUSTOMER.FIRST_NAME}=${payload.firstName}&${ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.CUSTOMER.LAST_NAME}=${payload.lastName}`
                                        )
                                    }
                                    onClick={ 
                                        () => { 
                                            onCloseDialog(dialog.name)
                                            onOpenMenuAccordion('All Branches')
                                        } 
                                    }
                                >
                                        &raquo; 
                                    { t('Show damages related to this customer') }
                                </TextNavLink>
                            </Paragraph>  
                        </FlexBox> 
                    </InfoBox>
                    : <SimpleList 
                        list={messages.success}
                        iconKey='check'
                        type = 'success'
                        iconSize = '14px'
                    />   
            }
            <PushBottom32/>
            <FlexBox width='auto'>
                <ActionButton 
                    buttonText={t('Edit customer data instead')} 
                    onButtonClick={handleEditCustomer}
                />
            </FlexBox>
            <PushBottom32/>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={
                () => onDeleteCustomer(
                    payload.id, 
                    payload, 
                    successMsg, 
                    offset, 
                    limit, 
                    direction, 
                    property
                )
            }
            disabled={hasDamages}
        />    
    </React.Fragment>
}

export default withRouter(
    withDialogDataLoader(DeleteCustomer)
)