import { connect } from 'react-redux'
import UserFilter from './component'
import { getUsersRequestDebounced } from 'application/redux/actions/common'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'

const mapStateToProps = state => {
    const {
        usersList,
        usersListLoading,
        usersListLoaded,
    } = state.common
    return {
        usersList: usersList.users,
        usersListLoading,
        usersListLoaded,
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSearchUser: (search) => dispatch(
            getUsersRequestDebounced(0, 10, new ListFilter(filters.users.name).setValue(search).getQuery())
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserFilter)