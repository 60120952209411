import { connect } from 'react-redux'

import CancelPaymentComponent from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onCancelPayment: (
            name, 
            payload, 
            parentId, 
            invoiceId, 
            paymentId, 
            successMsg
        ) => dispatch(
            sendPayloadRequest(
                name, 
                payload, 
                { 
                    parentId, 
                    invoiceId, 
                    paymentId, 
                    successMsg 
                }
            )
        ),
    }
}

export const CancelPayment = connect(
    mapStateToProps,
    mapDispatchToProps
)(CancelPaymentComponent)