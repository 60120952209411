import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import TaskDetails from './component'

const TaskDetailsEffects = (props) => {
    const { onLoadTask, selectedTaskLoading, selectedTaskLoaded, task, hasTaskList, contextLoaded, onRegisterWidgetContext, match } = props
    const { damageId, taskId } = match.params
    const currentTaskId = task !== null ? task.id : null

    const widgetList = task?.formConfiguration?.components !== undefined ? task.formConfiguration.components.sort((a, b) => a.index - b.index).map(w=>({
        ...w,
        key: `${w.type}_${task.id}`,
        title: `${w.type}_${task.id}`, //empty later
        hideHeadline: true,
        accountDependent: true,
        initialPayload: {
            taskId: task.id,
            damageId: task.damageId
        }
    })) : []


    useEffect(() => {
        taskId && (!selectedTaskLoaded || taskId !== currentTaskId) && !selectedTaskLoading && taskId !== '' && hasTaskList && onLoadTask(taskId, damageId)
    }, [currentTaskId, hasTaskList, match.path, onLoadTask, damageId, selectedTaskLoaded, selectedTaskLoading, taskId])

    useEffect(() => {
        selectedTaskLoaded && !contextLoaded && taskId !== '' && onRegisterWidgetContext(taskId, widgetList) //just use the taskId as context
    }, [contextLoaded, onRegisterWidgetContext, selectedTaskLoaded, taskId, widgetList])

    return <TaskDetails {...props} taskId={taskId} damageId={damageId}/>
}

export default withRouter(TaskDetailsEffects)