import { ACTIONS } from 'application/constants'
import defaultStore from 'application/redux/reducers/default_store'

export default (state = defaultStore.pages.branchManagement, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.branchManagement
    }
    case ACTIONS.BRANCH_MANAGEMENT_ADD_ROLE:
        return {
            ...state,
            roles: [
                ...state.roles,
                {
                    name: action.roleName,
                    id: action.roleId
                }
            ]
        }
    case ACTIONS.BRANCH_MANAGEMENT_REMOVE_ROLE:
        return {
            ...state,
            roles: state.roles.filter(r => r.id !== action.roleId),
            groups: state.groups.map(d => ({
                ...d,
                roles: d.roles.filter(r => r !== action.roleId)
            }))

        }
    case ACTIONS.BRANCH_MANAGEMENT_REMOVE_GROUP:
        return {
            ...state,
            groups: state.groups.filter(d => d.id !== action.groupId)
        }
    case ACTIONS.BRANCH_MANAGEMENT_REMOVE_BRANCH:
        return {
            ...state,
            branches: state.branches.filter(b => b.id !== action.branchId),
            groups: state.groups.map(d => ({
                ...d,
                branches: d.branches.filter(b => b !== action.branchId)
            }))

        }
    case ACTIONS.BRANCH_MANAGEMENT_TOGGLE_ROLE_SIDEBAR:
        return {
            ...state,
            roleSidebarVisible: action.isVisible,
            roleSidebarDataId: action.roleId
        }
    case ACTIONS.BRANCH_MANAGEMENT_TOGGLE_GROUP_SIDEBAR:
        return {
            ...state,
            groupSidebarVisible: action.isVisible,
            groupSidebarDataId: action.groupId
        }
    case ACTIONS.BRANCH_MANAGEMENT_TOGGLE_BRANCH_SIDEBAR:
        return {
            ...state,
            branchSidebarVisible: action.isVisible,
            branchSidebarDataId: action.branchId      
        }
    case ACTIONS.BRANCH_MANAGEMENT_TOGGLE_HELP_SIDEBAR:
        return {
            ...state,
            helpSidebarVisible: action.isVisible
        }
    case ACTIONS.BRANCH_MANAGEMENT_CLOSE_ALL_SIDEBARS:
        return {
            ...state,
            roleSidebarVisible: false,
            roleSidebarDataId: null,
            groupSidebarVisible: false,
            groupSidebarDataId: null,
            branchSidebarVisible: false,
            branchSidebarDataId: null,
            helpSidebarVisible: false
        }
    case ACTIONS.BRANCH_MANAGEMENT_GET_GROUP_MEMBERS_REQUEST:
        return {
            ...state,
            groupMembers:{
                ...state.groupMembers,
                groupMembersList: [],
                groupMembersListLoading: true,
                groupMembersListLoaded: false,
                groupMembersTotal: null  
            } 
        }
    case ACTIONS.BRANCH_MANAGEMENT_POPULATE_GROUP_MEMBERS:
        return {
            ...state,
            groupMembers:{
                loadedGroupId: action.groupId,
                groupMembersList: action.groupMembersList,
                groupMembersListLoading: false,
                groupMembersListLoaded: true,
                groupMembersTotal: action.groupMembersTotal  
            } 
        }
    default:
        return state
    }
}
