import React from 'react'
import { FontIcon } from '@ec/ui-controls-react'
import { withTheme } from 'styled-components'

import { REPAIR_AUTHORIZATION_TYPE } from 'application/constants'

const RepairAuthorizationIndicator = ({repairAuthorization, theme}) => {
    let icon = 'panorama_fish_eye'
    let color = theme.color.powderGray

    if(repairAuthorization === undefined){
        return <FontIcon icon={icon} fontSize={theme.fontSize.medium} color={color} />
    }

    switch (repairAuthorization) {
    case REPAIR_AUTHORIZATION_TYPE.GRANTED:
        icon = 'thumb_up'
        color = theme.color.green
        break
    case REPAIR_AUTHORIZATION_TYPE.REFUSED:
        icon = 'thumb_down'
        color = theme.color.red
        break
    default:
        icon = 'panorama_fish_eye'
        color = theme.color.powderGray
        break
    }
    return <FontIcon 
        icon={icon} 
        fontSize={theme.fontSize.medium} 
        color={color} 
    />
}

export default withTheme(RepairAuthorizationIndicator)