import { connect } from 'react-redux'
import AllTasks from './component'
import { getAllTasksRequest } from 'application/redux/actions/pages/my_tasks/all_tasks'
import { getUpdateConstraint } from 'application/redux/selectors'
import { UPDATE_CONSTRAINTS } from 'application/constants'
import { MASS_OPERATIONS } from 'application/constants'


const mapStateToProps = state => {
    const {
        tasksListLoaded,
        tasksListLoading,
        tasksList,
        tasksListTotal,
    } = state.pages.myTasks.allTasks
    return {
        auth: state.auth,
        allTasks: state.pages.myTasks.allTasks,
        tasksListLoaded,
        tasksListLoading,
        tasksList,
        tasksListTotal,
        updateConstraint: getUpdateConstraint(state, UPDATE_CONSTRAINTS.TASKS_TABLES),
        moName: MASS_OPERATIONS.NAME.TASKS_OVERVIEW_ALL_BRANCHES_ALL_TASKS
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadTasksList: (
            offset,
            limit,
            orderBy,
            direction,
            filterQuery
        ) => dispatch(
            getAllTasksRequest(
                offset,
                limit,
                orderBy,
                direction,
                filterQuery
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTasks)