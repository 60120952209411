import React from 'react'
import { FontIcon } from '@ec/ui-controls-react'
import styled from 'styled-components'
import Image from 'application/components/controls/image'
import { IMAGES } from 'application/constants'

const StyledDekraButton = styled.button`
    border: 1px solid ${props => props.theme.color.anthracite};
    outline: 0;
    font-size: ${props => props.theme.fontSize.medium};
    border-radius: 2px;
    background: ${props => props.theme.color.white};
    height: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: ${props => props.cssMargin ? props.cssMargin : '0'};

    &:hover{
        background: ${props => props.theme.color.gray10};
    }
    & img{
        height: 23px;
        margin: 0 4px;
    }
`

export const DekraButton = ({onButtonClick, buttonText, cssMargin = '0'}) => {
    return <StyledDekraButton onClick={onButtonClick} cssMargin={cssMargin}>
        <FontIcon icon='add' />
        <Image data={IMAGES.DEKRA_LOGO} />
        {buttonText}</StyledDekraButton>
}