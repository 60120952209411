import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { FontIcon } from '@ec/ui-controls-react'
import { WidgetSubHeadline, BulletList, BulletListItem, BulletListLabel } from 'application/components/widgets/_common'
import { stringIsFilled, notNull } from 'application/common/data_helpers'
import { PushBottom8, Spacer } from 'application/components/pages/_common'
import { CustomerName } from 'application/components/controls/customer_name'
import { IconButton } from '@ec/ui-controls-react'



const CustomerDisplay = (
    {
        customer, 
        theme,
        onEditCustomer = null,
        hideLabels = false
    }
) => {
    const {t} = useTranslation()




    const {
        address = null,
        email =  '',
        externalReferenceId =  '',
        mobilePhoneNumber =  '',
        // firstName =  '',
        iban =  '',
        // id =  '',
        // isCompany =  false,
        isEntitledToTaxDeduction =  false,
        // lastName =  '',
    } = customer

    const hasAddress = notNull(address)

    const {
        line1 = '',
        locality = '',
        postalCode = '',
    } = hasAddress ? address : {}


    return <React.Fragment>
        <WidgetSubHeadline>
            <CustomerName customer={customer} />
            {
                onEditCustomer !== null && <React.Fragment>
                    <Spacer basis='8px' />
                    <IconButton 
                        iconKey='edit' 
                        onButtonClick={onEditCustomer} 
                        width='16px' 
                        height='16px' 
                        iconSize='16px'
                    />
                </React.Fragment>
            }
        </WidgetSubHeadline>
        {
            hasAddress
                && stringIsFilled(line1) 
                && <div>
                    {line1}
                </div>
        }
        {                
            hasAddress
                && <div>
                    { 
                        stringIsFilled(postalCode) 
                        && postalCode 
                    }
                    &nbsp;
                    {
                        stringIsFilled(locality) 
                        && locality 
                    }
                </div>
        }
        <PushBottom8/>
        <BulletList hideBullet={true}>
            {
                stringIsFilled(externalReferenceId) && <BulletListItem hideBullet={true}>
                    <BulletListLabel cssWidth='120px'>
                        {t('Customer number')}:
                    </BulletListLabel> 
                    {externalReferenceId}
                </BulletListItem>
            }
            {
                stringIsFilled(mobilePhoneNumber) && <BulletListItem hideBullet={true}>
                    <BulletListLabel cssWidth='auto'>
                        <FontIcon 
                            icon='phone' 
                            fontSize={theme.fontSize.standard} 
                        />
                        {
                            !hideLabels && <React.Fragment>
                                {t('Phone')}:
                            </React.Fragment>
                        }
                    </BulletListLabel>
                    {mobilePhoneNumber}
                </BulletListItem>
            }
            {
                stringIsFilled(email) && <BulletListItem hideBullet={true}>
                    <BulletListLabel 
                        cssWidth='auto'>
                        <FontIcon 
                            icon='send' 
                            fontSize={theme.fontSize.standard} 
                        />
                        {
                            !hideLabels && <React.Fragment>
                                {t('E-Mail')}:
                            </React.Fragment>
                        }
                    </BulletListLabel>
                    {email}
                </BulletListItem>
            }
            <PushBottom8/>
            {
                stringIsFilled(iban) 
                && <BulletListItem hideBullet={true}>
                    <strong>{t('IBAN')}:</strong>  {iban}
                </BulletListItem>
            }
            {
                isEntitledToTaxDeduction 
                && <BulletListItem hideBullet={true}>
                    { t('Is entitled to tax deduction') }
                </BulletListItem>
            }
        </BulletList>
    </React.Fragment>
}

export default withTheme(CustomerDisplay)