import { DIALOG } from 'application/constants'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { downLoadQRCode } from 'application/redux/actions/qr_code_download'
import { connect } from 'react-redux'
import CustomersQrCodeTableWidgetComponent from './component'

const mapStateToProps = state => ({
    currentAccountName: state.auth.currentAccount.name
})

const mapDispatchToProps = dispatch => {
    return {
        onQRCodeDownload: (
            qrCodeObjectUrl,
            downloadedImageFileName
        ) => dispatch(
            downLoadQRCode(
                qrCodeObjectUrl,
                downloadedImageFileName
            )
        ),
        onEditCustomer: (payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.EDIT_CUSTOMER, 
                true, 
                {
                    customer: {
                        ...payload, 
                        customerId: payload.id
                    }
                }, 
                'Edit customer'
            )
        ),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersQrCodeTableWidgetComponent)