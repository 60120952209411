import React from 'react'
import { useTranslation } from 'react-i18next'
import { WidgetInformation } from 'application/components/widgets/_common'
import { withMassOperations } from 'application/components/context/mass_operations'
import { TableRow, StyledTableCell } from './fragments'
import { SelectableTableRow, LinkedTableRow, TableCell } from './'
import { withRouter } from 'react-router-dom'
import { exists } from 'application/common/data_helpers'
import { FlexBox } from 'application/components/fragments/flex_box'

const TableContentComponent = (
    {   
        mo,
        hasMassOperations = false,
        selectItemByMOName,
        deselectItemByMOName,
        elementsLoading,
        elementsTotal,
        elementsLoaded,
        elements,
        configuration,
        emptyMessage,
        match
    }
) => {

    const {t} = useTranslation()
    const contextMenuWidth = configuration.contextMenuWidth || 0
    const flexibleCellContainerWidth = `calc(100% - ${contextMenuWidth})`

    const moIsActive = mo !== null ? mo.isActive : false

    const checkIfItemIsSelectedByMO = (itemId) => {
        const itemFound =  mo.selectedItems.find( sitem => sitem.id === itemId)
        return itemFound !== undefined
    }

    const indexIsEven = (i) => {
        return i % 2 === 0
    } 

    const cssHeight = configuration.rowHeight || '44px'

    return <React.Fragment>
        {
            !elementsLoading 
            && elements.length > 0 
            && elements.map(
                (element, i) => {
                    const isSelected = mo !== null 
                        ? checkIfItemIsSelectedByMO(element.id) 
                        : false

                    const rowIsColoured = indexIsEven(i)

                    return hasMassOperations && moIsActive
                        ? <SelectableTableRow
                            key={element.id}
                            element={element}
                            selectItemByMOName = {selectItemByMOName}
                            deselectItemByMOName = {deselectItemByMOName}
                            isSelected={isSelected}
                            moName={mo.moName}
                            isColoured={rowIsColoured}
                            cssHeight={cssHeight}
                        >      
                            <FlexBox 
                                width='auto' 
                                flexBasis={flexibleCellContainerWidth} 
                                flexGrow='0' 
                                flexShrink='0'
                                cssRules='overflow:hidden;'
                            >                     
                                {
                                    configuration.columns.map(
                                        (column, columnIndex) => {
                                            return <TableCell 
                                                configuration={configuration} 
                                                column={column} 
                                                key={`${element.id}_${columnIndex}`}
                                            >
                                                <column.CellContent 
                                                    element={element} 
                                                    isSelected={isSelected} 
                                                    moIsActive={moIsActive} 
                                                />
                                            </TableCell>
                                        }
                                    )
                                }
                            </FlexBox>
                            {
                                exists(configuration.ContextMenu) && <StyledTableCell 
                                    flexGrow='0' 
                                    cssWidth={`${configuration.contextMenuWidth}`} 
                                    cssMaxWidth={`${configuration.contextMenuWidth}`}
                                >
                                    <configuration.ContextMenu 
                                        element={element} 
                                        moIsActive={moIsActive} 
                                    />
                                </StyledTableCell>
                            }
                        </SelectableTableRow>
                        : configuration.href(element, match) === null 
                            ? <TableRow 
                                key={element.id}
                                isColoured={rowIsColoured}
                                cssHeight={cssHeight}
                            >
                                <FlexBox 
                                    width='auto' 
                                    flexBasis={flexibleCellContainerWidth} 
                                    flexGrow='0' 
                                    flexShrink='0' 
                                    cssRules='overflow:hidden;'
                                > 
                                    {
                                        configuration.columns.map(
                                            (column, columnIndex) => {
                                                return <TableCell 
                                                    key={`${element.id}_${columnIndex}`}
                                                    configuration={configuration} 
                                                    column={column}
                                                >
                                                    <column.CellContent
                                                        element={element} 
                                                        isSelected={isSelected} 
                                                    />
                                                </TableCell>
                                            }
                                        )
                                    }
                                </FlexBox>
                                {
                                    exists(configuration.ContextMenu) && <StyledTableCell 
                                        flexGrow='0' 
                                        cssWidth={`${configuration.contextMenuWidth}`} 
                                        cssMaxWidth={`${configuration.contextMenuWidth}`}
                                    >
                                        <configuration.ContextMenu 
                                            element={element} 
                                            moIsActive={moIsActive} 
                                        />
                                    </StyledTableCell>
                                }
                            </TableRow>
                            : <LinkedTableRow 
                                key={element.id}
                                href={configuration.href(element, match)}
                                isColoured={rowIsColoured}
                                cssHeight={cssHeight}
                            >
                                <FlexBox 
                                    width='auto' 
                                    flexBasis={flexibleCellContainerWidth} 
                                    flexGrow='0' 
                                    flexShrink='0' 
                                    cssRules='overflow:hidden;'
                                > 
                                    {
                                        configuration.columns.map(
                                            (column, columnIndex) => {
                                                return <TableCell 
                                                    key={`${element.id}_${columnIndex}`}
                                                    configuration={configuration} 
                                                    column={column} 
                                                >
                                                    <column.CellContent 
                                                        element={element}
                                                        isSelected={isSelected}
                                                    />
                                                </TableCell>
                                            }
                                        )
                                    }
                                </FlexBox>  
                                {
                                    exists(configuration.ContextMenu) && <StyledTableCell 
                                        flexGrow='0'
                                        cssWidth={`${configuration.contextMenuWidth}`} 
                                        cssMaxWidth={`${configuration.contextMenuWidth}`}
                                    >
                                        <configuration.ContextMenu 
                                            element={element} 
                                            moIsActive={moIsActive} 
                                        />
                                    </StyledTableCell>
                                }
                            </LinkedTableRow>
                }
            )
        }
        {
            elementsLoaded 
            && elementsTotal === 0 
            && <WidgetInformation>
                {t(emptyMessage)}
            </WidgetInformation>
        }
    </React.Fragment>
}

export const TableContent = withRouter(
    withMassOperations(
        TableContentComponent
    )
) 