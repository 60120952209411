import { connect } from 'react-redux'
import DocumentsEffects from './effects'
import { getCategoriesWorkflowPanelRequest } from 'application/redux/actions/pages/workflow_panel/shared'
import { getAllDocumentsRequestWorkflowPanel, hideAttachmentDetailsDCWorkflowPanel, showAttachmentDetailsDCWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/documents'

const mapStateToProps = state => {
    return {
        documents: state.pages.workflowPanel.documentCenter.documents,
        documentsLoaded: state.pages.workflowPanel.documentCenter.documentsLoaded,
        documentsLoading: state.pages.workflowPanel.documentCenter.documentsLoading,
        fullAttachments: state.attachments.fullAttachments,
        // selectedThumbnail: exampleProps.attachments.attachmentDetails.sidebar.selectedThumbnail,
        categories: state.pages.workflowPanel.shared.categories,
        categoriesLoaded: state.pages.workflowPanel.shared.categoriesLoaded,
        loggedIn: state.sharedResource.loggedIn,
        currentDamageId: state.pages.workflowPanel.documentCenter.damageId,
        categoriesLoading: state.pages.workflowPanel.shared.categoriesLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadDocumentsWorkflowPanel: (sharedResourceId) => {
            dispatch(getAllDocumentsRequestWorkflowPanel(sharedResourceId))
        },
        onHideAttachmentDetails: () => dispatch(hideAttachmentDetailsDCWorkflowPanel()),
        onGetCategories: (sharedResourceId) => dispatch(getCategoriesWorkflowPanelRequest(sharedResourceId)),
        onAttachmentClick: (attachmentName, attachmentId, damageId, sharedResourceId, taskId, isExportedToLawFirm) => dispatch(showAttachmentDetailsDCWorkflowPanel(attachmentName, attachmentId, damageId, sharedResourceId, taskId, isExportedToLawFirm))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentsEffects)