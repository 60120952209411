import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { WIDGET_TYPES } from 'application/constants'
import DamageManagerInbox from './component'

const InboxEffects = (props) => {
    const { onRefreshWidgetType, match, onResetMessageDetails } = props
    const { branchId, damageId, messageId } = match.params

    useEffect(() => {
        onRefreshWidgetType(WIDGET_TYPES.BRANCH_SPECIFIC_INBOX_MESSAGE_LIST)
    }, [onRefreshWidgetType, branchId, damageId, messageId])

    useEffect(()=>{
        onResetMessageDetails()
    }, [onResetMessageDetails])

    return <DamageManagerInbox {...props} />
}

InboxEffects.propTypes = {
    onRefreshWidgetType: PropTypes.func.isRequired,
}

export default withRouter(InboxEffects)