
import React, {useState}   from 'react'
import { ActionButton } from '@ec/ui-controls-react'
import { useTranslation } from 'react-i18next'
import { PushBottom24 } from 'application/components/pages/_common'
import { Paragraph, Headline2 } from 'application/components/fragments/typography'
import { registrationUserManager } from 'application/common/user_manager'
import ActionLink from 'application/components/controls/action_link'




export const RegistrationForm = (
    {
        salutation = '', 
        registrationUrl = '', 
        sharedResourceId = ''
    }
) => {

    const {t} = useTranslation()
    const [userManager] = useState(registrationUserManager())


    
    const signIn = () => {
        userManager.signinRedirect( 
            {
                state: { sharedResourceId }
            } 
        )
    }

    return <React.Fragment>
        <Headline2 cssAlign='center'>
            {t('Welcome')} {salutation}
        </Headline2>
        <PushBottom24 />
        <Paragraph cssAlign='center'>
            {t('to finish the registration, please create a new e.Consult account.')}
        </Paragraph>
        <PushBottom24 /> 
        <ActionLink
            strechted={true}
            linkText={t('Create user account')}
            to={registrationUrl}
        />
        <br />
        <br />
        <ActionButton
            strechted={true}
            buttonText={t('Login with existing e.Consult account')}
            onButtonClick={signIn}
        />
    </React.Fragment>
}