import React from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutAllBranches } from 'application/components/pages/_layout'
import DashboardContent from './content'
import { withLoggedInUser } from 'application/components/context/logged_in'
import { Spacer } from 'application/components/pages/_common'
import { ConfigureDashboard } from '../../_common/configure_dashboard'



const Dashboard = ({onConfigureDashboard}) => {
    const {t} = useTranslation()

    return <LayoutAllBranches
        titleSegments={['Overview', 'All branches']}
        headlineSpecific={t('Overview')}
        headlineChildren={
            <React.Fragment>
                <Spacer basis='8px' />
                <ConfigureDashboard />
            </React.Fragment>
        }
    >
        <DashboardContent />
    </LayoutAllBranches>
}

export default withLoggedInUser(Dashboard)