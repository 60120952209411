import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { WIDGET_CONTEXTS } from 'application/constants'
import DamageManagerInvoices from './component'

const DamageManagerInvoicesEffects = (props) => {
    const { onRefreshWidgetContexts, match } = props
    const { damageId } = match.params

    useEffect(() => {
        onRefreshWidgetContexts([WIDGET_CONTEXTS.DAMAGE_MANAGER_INVOICES])
    }, [onRefreshWidgetContexts, damageId])

    return <DamageManagerInvoices {...props} />
}

DamageManagerInvoicesEffects.propTypes = {
    onRefreshWidgetContexts: PropTypes.func.isRequired
}

export default withRouter(DamageManagerInvoicesEffects)