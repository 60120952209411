import { connect } from 'react-redux'
import EditUsersInGroup from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'
import { getUsersForDialogRequestDebounced } from 'application/redux/actions/common'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onSearchUser: (dialogName, search) => dispatch(getUsersForDialogRequestDebounced(dialogName, 0, 10, new ListFilter(filters.users.name).setValue(search).getQuery())),
        onSaveGroup: (dialogName, groupId, users) => dispatch(sendPayloadRequest(dialogName, {users}, {groupId}))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUsersInGroup)