import { connect } from 'react-redux'

import AttachmentGalleryEffects from './effects'

import { hideGallery, setActiveAttachment } from 'application/redux/actions/gallery'
import { downloadAttachment } from 'application/redux/actions/attachments'
import { getAllDocumentsRequest } from 'application/redux/actions/pages/damage_manager/documents'
import { getAllDocumentsRequestWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/documents'

const mapStateToProps = (state, ownProps) => ({
    gallery: state.gallery,
    documents: state.pages.damageManager.documentCenter.documents,
    documentsWP: state.pages.workflowPanel.documentCenter.documents,
    documentsLoaded: state.pages.damageManager.documentCenter.documentsLoaded,
    documentsLoading: state.pages.damageManager.documentCenter.documentsLoading,
    documentsLoadingWP: state.pages.workflowPanel.documentCenter.documentsLoading,
    fullAttachments: state.attachments.fullAttachments,
    // selectedThumbnail: exampleProps.attachments.attachmentDetails.sidebar.selectedThumbnail,
    categories: state.common.categories,
    categoriesLoaded: state.common.categoriesLoaded,
    loggedIn: state.auth.loggedIn,
    currentDamageId: state.pages.damageManager.documentCenter.damageId
})

const mapDispatchToProps = dispatch => ({
    onLoadDocuments: (id, taskId, match) => dispatch(getAllDocumentsRequest(id, taskId, match)),
    onLoadDocumentsWorkflowPanel: (id, taskId, match) => dispatch(getAllDocumentsRequestWorkflowPanel(id, taskId, match)),
    downloadAttachment: (id, attachmentName, damageId) => dispatch(downloadAttachment(id, attachmentName, damageId)),
    // onThumbnailClick: attachment => {
    //     var isQueue = attachment.isLoaded===undefined
    //     dispatch(updateSelectedAttachment(attachment.id, attachment.fileName, attachment, isQueue))},
    onHideGallery: () => dispatch(hideGallery()),
    onSelectAttachment: (damageId, attachmentId, attachmentName) => dispatch(setActiveAttachment(damageId, attachmentId, attachmentName))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttachmentGalleryEffects)