import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {StyledMenu, MenuName} from './fragments'
import { FlexBox } from 'application/components/fragments/flex_box'
import MenuSideBarIcon  from '../menu_icon'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'
import ThemedPreloader from 'application/components/controls/themed_preloader'

const AccordionMenu = (
    {        
        theme,
        menuName,
        menuIconKey,
        primaryColor,
        onOpenOnlyCurrentAccordeon,
        accordionMenuState,
        sideBarOpen,
        children,
        isLoading = false
    }
) => {
    const {t} = useTranslation()
    const {accordionOpen, name} = accordionMenuState  
    const [animationRunning, setAnimationRunning] = useState(false) 
    
    const extensionString = accordionOpen ? t('Close') : t('Open')
    const accordionToolTipText = `${t('Menu')} ${extensionString}:  ${t(menuName)}` 
    const accordionToolTip = !sideBarOpen ? accordionToolTipText : ''

    const initialHeight = accordionOpen ? 'auto' : 0
    const menuId = menuName.toLowerCase().replace(/\s/g, '-')
    const iconKey = accordionOpen  ? 'expand_more' : 'chevron_right' 

    const handleToggleAccordion = () => {
        if(!accordionOpen){
            onOpenOnlyCurrentAccordeon(name) 
        }
    }

    return <StyledMenu 
        backgroundColor={primaryColor} 
        animationRunning={animationRunning} 
        isLoading={isLoading}
    >
        {
            isLoading
                ? <ThemedPreloader 
                    primaryColor={theme.color.white}
                    backgroundColor={primaryColor}
                    preloaderText={sideBarOpen ? t('loading branch') : ''}
                    preloaderTextColor={theme.color.white}
                    small={true}
                    paddingTop={4}
                />
                : <React.Fragment>
                    <FlexBox
                        isDisabled={false}
                        onClick={handleToggleAccordion}
                        aria-expanded={ initialHeight !== 0 }
                        aria-controls={menuId}
                    >
                        <FlexBox
                            cssRules='width: 48px;'
                            paddingLeft='8px'
                            paddingRight='8px'
                        >
                            <MenuSideBarIcon
                                iconKey={menuIconKey}
                                color={theme.color.white}
                                isClickable={true}
                                secondIcon={
                                    !sideBarOpen 
                                        ? <i className="material-icons">
                                            { iconKey }
                                        </i> 
                                        : <React.Fragment/>
                                }
                                toolTip={accordionToolTip}
                            />
                        </FlexBox>
                        {
                            sideBarOpen && <React.Fragment>
                                <MenuName>{t(menuName)} </MenuName>
                                <MenuSideBarIcon
                                    iconKey={iconKey}
                                    color={theme.color.white}
                                    isClickable={true}
                                />
                            </React.Fragment>
                        }
                    </FlexBox>

                    <AnimateHeight
                        id={menuId}
                        duration={ 500 }
                        height={ initialHeight } 
                        onAnimationStart={() => {setAnimationRunning(true)}}
                        onAnimationEnd={() => {setAnimationRunning(false)}}
                    >
                        {children}
                    </AnimateHeight>
                </React.Fragment>
        }
        
    </StyledMenu>
}


AccordionMenu.propTypes = {
    theme: PropTypes.object.isRequired,
    menuName: PropTypes.string.isRequired,
    primaryColor: PropTypes.string.isRequired,
    onOpenOnlyCurrentAccordeon: PropTypes.func.isRequired, 
    onCloseAccordions: PropTypes.func.isRequired, 
    accordionMenuState: PropTypes.object.isRequired,
    sideBarOpen: PropTypes.bool.isRequired,
    children: PropTypes.object
}

export default withTheme(AccordionMenu)