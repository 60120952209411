import React from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { TextInput } from 'application/components/controls/form'
import YesNoSelect from 'application/components/controls/form/yes_no_select'
import TwoColumns from 'application/components/forms/_common'
import { ActionButton } from '@ec/ui-controls-react'
import { useForm } from 'react-hook-form'
import PhoneNumberInput from 'application/components/controls/form/phone_number_input'
import { PartialForms } from 'application/components/forms'
import { FlexBox } from 'application/components/fragments/flex_box'
import { GravatarComponent } from 'application/components/building_blocks/gravatar'
import {useGravatar} from 'application/components/hooks/use_gravatar'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { DIALOG, INFOBOX } from 'application/constants'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom24, Spacer } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'





export const EditCustomerStepComponent = (
    { 
        //from component
        abort, 
        onDataChange,
        customer,
        damageId = null,
        dialogName,
        damageType,
        goToNextStep,
        //from container
        onEditCustomer
    }
) => {
    const { t } = useTranslation()

    const {
        setFirstName,
        setLastName,
        setCompanyName,
        getInitials,
        getFullName 
    } = useGravatar(
        customer.firstName,
        customer.lastName,
        customer.isCompany,
        customer.companyName
    )

    const emptyValuesBase = {
        isEntitledToTaxDeduction: false,
        address: {
            line1: '',
            postalCode: '',
            locality: ''
        },
        email: '',
        iban: '',
        externalReferenceId: '',
        mobilePhoneNumber: '',
        isCompany: false
    }

    const emptyValues = customer.isCompany 
        ? {
            ...emptyValuesBase,
            companyName: ''
        }
        : {
            ...emptyValuesBase,
            firstName: '',
            lastName: ''
        }

    const defaultValues = {
        ...emptyValues,
        ...customer
    }

    const { 
        register,
        errors, 
        handleSubmit, 
        getValues, 
        control, 
        setValue 
    } = useForm({defaultValues})
    
    const successMsg = getFullName() + ' ' + t('has been saved')

    // EDIT_CUSTOMER_AND_CLOSE_DIALOG


    const onEditCustomerAndContinue = (
        payload, 
        metaData
    ) => {
        onEditCustomer(
            DIALOG.NAME.EDIT_CUSTOMER_FROM_DIALOG, 
            payload, 
            metaData
        )
        goToNextStep()
    }

    const onEditCustomerAndCloseDialog = (
        payload, 
        metaData
    ) => {
        onEditCustomer(
            DIALOG.NAME.EDIT_CUSTOMER,
            payload, 
            metaData
        )
    }


    const onSubmitSuccess = (continueWithNextStep) => {
        const payloadToSend = {
            ...getValues(),
            isCompany: customer.isCompany
        }
        const metaData = {
            customerId: customer.customerId,
            isCompany: customer.isCompany,
            damageId,
            fromDialogName: dialogName,
            successMsg
        }
        continueWithNextStep
            ? onEditCustomerAndContinue(
                payloadToSend, 
                metaData
            )
            : onEditCustomerAndCloseDialog(
                payloadToSend, 
                metaData
            )
    }

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox>
                <InfoBox type={INFOBOX.TYPES.SUCCESS} direction='row' cssMargin='0'>
                    <FontIcon icon='directions_car' />
                    <FontIcon icon='done' />
                    <Spacer basis='10px' />
                    <span>
                        <strong>{t('Damage case has been successfully created."')}</strong>&nbsp;
                        <span>{t('You can provide additional data or click "cancel to skip.')}</span>
                    </span>
                    <Spacer />
                </InfoBox>
            </FlexBox>
            <PushBottom24 />
            <FlexBox flexDirection='row'>
                <GravatarComponent
                    getInitials = {getInitials}
                    getFullName = {getFullName}
                    cssWidth='40%' 
                />
                <FlexBox 
                    flexBasis='60%' 
                    flexDirection='column'
                >
                    {
                        !customer.isCompany 
                            ? <React.Fragment>
                                <TextInput
                                    label={t('Firstname')}
                                    name='firstName'
                                    register={register}
                                    validate={{notEmpty: validationMethods.notEmpty}}
                                    error={errors.firstName}
                                    onChange={(e) => {
                                        onDataChange()
                                        setFirstName(e.target.value)
                                    }}
                                />
                                <TextInput
                                    label={t('Lastname')}
                                    name='lastName'
                                    register={register}
                                    validate={{notEmpty: validationMethods.notEmpty}}
                                    error={errors.lastName}
                                    onChange={(e) => {
                                        onDataChange()
                                        setLastName(e.target.value)
                                    }}
                                />
                            </React.Fragment>
                            : 
                            <TextInput
                                label={ t('Company name')}
                                name='companyName'
                                register={register}
                                validate={{notEmpty: validationMethods.notEmpty}}
                                error={errors.companyName}
                                onChange={(e) => {
                                    onDataChange()
                                    setCompanyName(e.target.value)
                                }}
                            />
                    }
                        
                    <PartialForms.AddressDataPartialForm
                        register={register}
                        errors={errors}
                        onChange={onDataChange}
                    />

                    <TextInput
                        label={t('Email')}
                        name='email'
                        register={register}
                        validate={{mustBeEmail: validationMethods.mustBeEmail}}
                        error={errors.email}
                        onChange={(e) => {
                            onDataChange()
                        }}
                    />
                    <PhoneNumberInput
                        defaultValue={customer.mobilePhoneNumber}
                        validate={null}
                        control={control}
                        label={t('Mobile phone number')}
                        name='mobilePhoneNumber'
                        register={register}
                        error={errors.mobilePhoneNumber}
                        onChange={onDataChange}
                        setValue={setValue}
                    />
                    <TextInput
                        label={t('IBAN')}
                        name='iban'
                        register={register}
                        validate={{mustBeIBAN: validationMethods.mustBeIBAN}}
                        error={errors.iban}
                        onChange={onDataChange}
                    />
                    <TwoColumns
                        columnLeft={
                            <TextInput
                                label={t('Customer number')}
                                name='externalReferenceId'
                                register={register}
                                validate={null}
                                error={errors.externalReferenceId}
                                onChange={onDataChange}
                            />
                        }
                        columnRight={
                            <YesNoSelect
                                register={register}
                                setValue={setValue}
                                initialValue={defaultValues.isEntitledToTaxDeduction}
                                name='isEntitledToTaxDeduction'
                                label={t('Is entitled to tax deduction')}
                                onChange={onDataChange}
                                menuPlacement='top'
                            />
                        }
                    />

                </FlexBox>
            </FlexBox>
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={abort} 
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Save customerdata and finish')} 
                onButtonClick={handleSubmit(() => onSubmitSuccess(false))} 
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Save customerdata and continue')} 
                onButtonClick={handleSubmit(() => onSubmitSuccess(true))} 
            />
        </StepsLayoutFooter>
    </React.Fragment>
}