import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'application/components/hooks'
import { composeFilterQuery } from 'application/common/filter_helpers'
import { useLocation } from 'react-router-dom'

export const useTableLoader = (
    onLoadTable = () => {}, 
    match, 
    configuration, 
    updateConstraint, 
    filterValues = {}
) => {
    const query = useQuery()
    const location = useLocation()
    const [properyFilterValues] = useState(filterValues)
    

    //paging
    const offset = query.getParsedParam(configuration.pagination.offset)
    const limit = query.getParsedParam(configuration.pagination.limit) === 0 
        ? configuration.pagination.getItemsPerPage()
        : query.getParsedParam(configuration.pagination.limit)

    //sorting values
    const orderBy = query.getStringParam(
        configuration.sorting.sortParamKey, 
        configuration.sorting.defaultSortParamValue
    )
    const direction = query.getStringParam(
        configuration.sorting.directionParamKey, 
        configuration.sorting.defaultDirectionParamValue
    )



    //filtering
    const filterQuery = useMemo(
        () => {
            const {
                filters = {}
            } = configuration

            //get filter values from props
            filters.property.forEach(
                filter => {
                    filter.setValue(
                        properyFilterValues[filter.filterKey]
                    )
                }
            )

            //get filter values from react router
            filters.match.forEach(
                filter => {
                    filter.setValue(
                        match.params[filter.filterKey]
                    )
                }
            )

            //get filter values from query params
            filters.query.forEach(
                filter => {
                    filter.setValue(
                        new URLSearchParams(location.search).get(filter.filterKey)
                    )
                }
            )

            //build composed filter query from active filters
            return composeFilterQuery(
                [
                    ...filters.property, 
                    ...filters.match, 
                    ...filters.query, 
                    ...filters.static
                ]
            )
        }, [
            configuration,
            location.search,
            match.params, 
            properyFilterValues
        ]
    )
    



    useEffect(
        () => { 
            onLoadTable(
                offset, 
                limit,
                orderBy, 
                direction, 
                filterQuery
            )
        }, [
            direction, 
            filterQuery, 
            limit, 
            offset, 
            onLoadTable, 
            orderBy, 
            updateConstraint
        ]
    )
}
