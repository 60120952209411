import { connect } from 'react-redux'

import { TaskDamageUpdateCustomer } from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => {
    return {
        onSwitchCustomer: (damageId) => dispatch(
            openDialogByName(
                DIALOG.NAME.SWITCH_DAMAGE_CUSTOMER, 
                true, 
                {
                    damageId, 
                    customerList: [],
                    customerListLoading: false
                }, 
                'Switch customer'
            )
        ),
        onChangeCustomerData: (customer) => dispatch(
            openDialogByName(
                DIALOG.NAME.EDIT_CUSTOMER_FROM_WIDGET,
                true, 
                {customer: customer}, 
                'Edit customer'
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskDamageUpdateCustomer)