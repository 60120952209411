import { connect } from 'react-redux'

import  {TaskHighlightBarComponent} from './component'

const mapStateToProps = (state, props) => {
    return {
        currentUser: state.auth.user
    }
}

export const TaskHighlightBar =  connect(
    mapStateToProps
)(TaskHighlightBarComponent)