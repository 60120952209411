import React from 'react'
import { WIDGET_CONTEXTS } from 'application/constants'
import { SubPageLayout } from 'application/components/pages/_common'
import WidgetArea from 'application/components/building_blocks/widget_area'
import DamageManager from '../container'
import { withRouter } from 'react-router-dom'

const DamageManagerInvoices = ({subRoute, damageLoaded, damage, match}) => {

    return <DamageManager
        titleSegments={['Invoices']}
        subRoute={subRoute}
    >
    
        <SubPageLayout paddingLeft='16px' >
            {
                damageLoaded && damage.id === match.params.damageId && <WidgetArea widgetContext={WIDGET_CONTEXTS.DAMAGE_MANAGER_INVOICES} successively={true} />
            }
        </SubPageLayout>
    </DamageManager>
}

export default withRouter(DamageManagerInvoices)