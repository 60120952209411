import { ACTIONS } from 'application/constants'

export const getAffiliatesRequest = (offset = 0, limit = 10, search = '') => ({
    type: ACTIONS.TITLE_BAR_GET_AFFILIATES_REQUEST,
    offset,
    limit,
    search
})

export const populateAffiliates = (affiliatesList) => ({
    type: ACTIONS.TITLE_BAR_POPULATE_AFFILIATES,
    affiliatesList
})