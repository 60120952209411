import { DASHBOARD_CONFIGURATION, WIDGET_CONTEXTS } from 'application/constants'
import { layoutBranchSpecificDashboard, widgetsBranchSpecificDashboard } from './contexts/widgets_branch_specific_dashboard'
import { layoutDashboard, widgetsDashboard } from './contexts/widgets_dashboard'
import { layoutBranchSpecificControllingVacantPositions, widgetsBranchSpecificControllingVacantPositions } from './contexts/widgets_branch_specific_controlling_vacant_positions'
import { layoutControllingVacantPositions, widgetsControllingVacantPositions } from './contexts/widgets_controlling_vacant_positions'
import { layoutCustomersDashboard, widgetsCustomersDashboard } from './contexts/widgets_customers_dashboard'
import { layoutBranchSpecificCustomersDashboard, widgetsBranchSpecificCustomersDashboard } from './contexts/widgets_branch_specific_customers_dashboard'
import { getWidgetContextMeta } from './get_widget_context_meta'

export const getDynamicDashboardConfiguration = configurationKey => {
    switch (configurationKey) {
    case DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_DAMAGES:
        return{
            branch: {
                widgetLayout: layoutBranchSpecificDashboard,
                widgetList: widgetsBranchSpecificDashboard,
                widgetBoardConfig: getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD).boardConfiguration
            },
            generic: {
                widgetLayout: layoutDashboard,
                widgetList: widgetsDashboard,
                widgetBoardConfig: getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD).boardConfiguration
            }
        }
    case DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CONTROLLING:
        return{
            branch: {
                widgetLayout: layoutBranchSpecificControllingVacantPositions,
                widgetList: widgetsBranchSpecificControllingVacantPositions,
                widgetBoardConfig: {
                    ...getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS).boardConfiguration,
                    rowHeight: 70 //ToDo: fix layout on page layer to be able to process the correct rowHeight
                }
            },
            generic: {
                widgetLayout: layoutControllingVacantPositions,
                widgetList: widgetsControllingVacantPositions,
                widgetBoardConfig: {
                    ...getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS).boardConfiguration,
                    rowHeight: 70 //ToDo: fix layout on page layer to be able to process the correct rowHeight
                }
            }
        }


    case DASHBOARD_CONFIGURATION.DASHBOARD_CONFIGURATION_CUSTOMERS:
        return{
            branch: {
                widgetLayout: layoutBranchSpecificCustomersDashboard,
                widgetList: widgetsBranchSpecificCustomersDashboard,
                widgetBoardConfig: {
                    ...getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_CUSTOMERS_DASHBOARD).boardConfiguration
                }
            },
            generic: {
                widgetLayout: layoutCustomersDashboard,
                widgetList: widgetsCustomersDashboard,
                widgetBoardConfig: {
                    ...getWidgetContextMeta(WIDGET_CONTEXTS.CUSTOMERS_DASHBOARD).boardConfiguration
                }
            }
        }

    default:
        return{
            branch: {
                widgetLayout: layoutBranchSpecificDashboard,
                widgetList: widgetsBranchSpecificDashboard,
                widgetBoardConfig: getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD).boardConfiguration
            },
            generic: {
                widgetLayout: layoutDashboard,
                widgetList: widgetsDashboard,
                widgetBoardConfig: getWidgetContextMeta(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD).boardConfiguration
            }
        }
    }
}