import { connect } from 'react-redux'

import TasksTableContextMenu from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'
import { downloadTaskDocuments } from 'application/redux/actions/attachments'

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        onDeleteTask: (payload) => dispatch(openDialogByName(DIALOG.NAME.DELETE_TASK, true, payload, 'Delete task')),
        onDownloadDocuments: (payload) => dispatch(downloadTaskDocuments(payload.damageId, payload.id)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TasksTableContextMenu)