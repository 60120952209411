import { combineReducers } from 'redux'
import dashboard from './dashboard'
import damageManager from './damage_manager'
import damagesOverview from './damages_overview'
import inbox from './inbox'
import {myTasks} from './my_tasks'
import workflowPanel from './workflow_panel'
import customers from './customers'
import branchManagement from './branch_management'
import extCreateDamage from './ext_create_damage'
import activityLog from './activity_log'

export default combineReducers({
    dashboard,
    damageManager,
    damagesOverview,
    inbox,
    myTasks,
    workflowPanel,
    customers,
    branchManagement,
    extCreateDamage,
    activityLog
})