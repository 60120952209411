import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, setQuery, deleteQuery } from 'application/components/hooks/use_query'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'
import IconButton from 'application/components/controls/icon_button'
import { PureSelect } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'
import { exists } from 'application/common/data_helpers'
import { ACTIVITY_TYPE } from 'application/constants'
import { Label } from 'application/components/fragments/typography'
import { ActivityAction } from 'application/components/name_mappings/activity_action'



const ActivityTypeFilter = (
    { 
        routeParameters, 
        filterTitle = 'Showing activites of type'
    }
) => {
        
    const query = useQuery()
    const location = useLocation()
    const history = useHistory()
    const { t } = useTranslation()

    const { FILTERS, OFFSET, LIMIT } = routeParameters
    const routeFilterType = FILTERS.ACTIVITY_TYPE

    const activityTypes = {
        ALL: 'All',
        ...ACTIVITY_TYPE
    }

    const activityTypeOptions =  Object.keys(activityTypes).map( 
        (key) => ({
            value: activityTypes[key],
            label: <ActivityAction activityType={activityTypes[key]} />
        })
    )

    const selectedActivityType = query.getStringParam(routeFilterType)
    
    //predefine the selected option if the information is present in the URL
    const selectedActivityTypeOption =  query.getStringParam(routeFilterType) === '' 
        ? {
            value: activityTypes.ALL,
            label: t(activityTypes.ALL)
        } 
        : {
            value: selectedActivityType,
            label: <ActivityAction activityType={selectedActivityType} />
        }
    

    const handleSelectActivityType = option => {
        setQuery(
            history, 
            location, 
            location.pathname, 
            [
                {
                    key: routeFilterType,
                    value: option.value
                }
            ], 
            true,
            [
                OFFSET,
                LIMIT
            ]
        )
    }

    const handleResetActivityType = () => {
        deleteQuery(
            history, 
            location, 
            location.pathname,
            [routeFilterType, OFFSET, LIMIT],
            true
        )
    }

    return <React.Fragment>
        <FlexBox 
            flexDirection='row'
            alignItems='center'
        >
            <Label cssMargin='0 16px 0 0'>
                {t('Activity type')}
            </Label>
            <PureSelect
                options={activityTypeOptions}
                value={selectedActivityTypeOption}
                onChange={handleSelectActivityType}
                cssFlexBasis='400px'
                isSearchable={false}
                isClearable={false}
            />            

            <FlexBox 
                flexBasis='100%'
                paddingLeft='16px'
            >
                {
                    exists(selectedActivityType) 
                    && selectedActivityType !== activityTypes.ALL 
                    && <InfoBox 
                        direction='row' 
                        cssMargin='0'
                    >
                        <span>{t(filterTitle)}:</span>
                        <strong>
                            "<ActivityAction activityType={selectedActivityType} />"
                        </strong>
                        <Spacer />
                        <IconButton 
                            iconKey='close' 
                            width='12px' 
                            height='16px' 
                            onButtonClick={handleResetActivityType} 
                        />
                    </InfoBox>
                }
            </FlexBox>
        </FlexBox>
        <PushBottom16/>
    </React.Fragment>

}

export default ActivityTypeFilter
