import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label'
import { DialogFooter, DialogFooterRight, DialogFooterSpacer } from 'application/components/fragments/dialog'
import PropTypes from 'prop-types'
import { FlexBox } from 'application/components/fragments/flex_box'

const FooterWithSpacer = (
    { 
        onAbort, 
        onSubmit,
        submitText = 'Confirm',
        disabled = false
    }
) => {
    const {t} = useTranslation()

    return <FlexBox
        flexDirection='column'
        flexBasis='100%'
    >
        <DialogFooterSpacer />
        <DialogFooter>
            <DialogFooterRight>
                <IconButtonWithLabel 
                    iconKey='not_interested' 
                    label={t('Cancel')} 
                    onButtonClick={onAbort} 
                />
                <ActionButton 
                    buttonText={t(submitText)} 
                    onButtonClick={onSubmit}
                    disabled={disabled}
                />
            </DialogFooterRight>
        </DialogFooter>
    </FlexBox>
}

FooterWithSpacer.propTypes = {
    onAbort: PropTypes.func.isRequired, 
    onSubmit: PropTypes.func.isRequired,
    submitText: PropTypes.string, 
    disabled: PropTypes.bool
}

export {FooterWithSpacer}