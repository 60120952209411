export const STORAGE_VERSION = process.env.REACT_APP_APPLICATION_STORAGE_VERSION

export const STORAGE = {
    APPLICATION_TOKEN: `apptoken360_v${STORAGE_VERSION}`, //application token 360
    CURRENT_ACCOUNT: `ca360_v${STORAGE_VERSION}`, //last accessed 360 account
    NAVBAR_OPEN_STATE: `nbos360_v${STORAGE_VERSION}`,
    DASHBOARD_WIDGET_LAYOUT: `dashlayout360_v${STORAGE_VERSION}`,
    BRANCH_SPECIFIC_DASHBOARD_WIDGET_LAYOUT: `branchdashlayout360_v${STORAGE_VERSION}`,
    CUSTOMERS_DASHBOARD_WIDGET_LAYOUT: `customersdashlayout360_v${STORAGE_VERSION}`,
    BRANCH_SPECIFIC_CUSTOMERS_DASHBOARD_WIDGET_LAYOUT: `branchcustomersdashlayout360_v${STORAGE_VERSION}`,
    DASHBOARD_WIDGET_LIST: `dashlist360_v${STORAGE_VERSION}`,
    CUSTOMERS_DASHBOARD_WIDGET_LIST: `customersdashlist360_v${STORAGE_VERSION}`,
    DASHBOARD_WIDGET_CONFIGURATION: `dashconfig360_v${STORAGE_VERSION}`,
    CUSTOMERS_DASHBOARD_WIDGET_CONFIGURATION: `customersdashconfig360_v${STORAGE_VERSION}`,
    BRANCH_SPECIFIC_DASHBOARD_WIDGET_LIST: `branchdashlist360_v${STORAGE_VERSION}`,
    BRANCH_SPECIFIC_CUSTOMERS_DASHBOARD_WIDGET_LIST: `branchcustomersdashlist360_v${STORAGE_VERSION}`,
    BRANCH_SPECIFIC_DASHBOARD_WIDGET_CONFIGURATION: `branchdashconfig360_v${STORAGE_VERSION}`,
    BRANCH_SPECIFIC_CUSTOMERS_DASHBOARD_WIDGET_CONFIGURATION: `branchcustomersdashconfig360_v${STORAGE_VERSION}`,
    DAMAGE_MANAGER_WIDGET_LAYOUT: `managerlayout360_v${STORAGE_VERSION}`,
    DAMAGE_MANAGER_WIDGET_LIST: `managerlist360_v${STORAGE_VERSION}`,
    WORKFLOW_PANEL_SHARED_RESOURCE_ID: `wprsrID_v${STORAGE_VERSION}`,
    SHARED_RESOURCE_ID: `wprsrID_v${STORAGE_VERSION}`,
    SHARED_RESOURCE_PINS: `wpPins360_v${STORAGE_VERSION}`,
    WORKFLOW_PANEL_PINS: `wpPins360_v${STORAGE_VERSION}`,
    CONTROLLING_VACANT_POSITIONS_WIDGET_LAYOUT: `controllingVPlayout360_v${STORAGE_VERSION}`,
    CONTROLLING_VACANT_POSITIONS_WIDGET_LIST: `controllingVPlist360_v${STORAGE_VERSION}`,
    CONTROLLING_STATISTICS_WIDGET_LAYOUT: `controllingSlayout360_v${STORAGE_VERSION}`,
    CONTROLLING_STATISTICS_WIDGET_LIST: `controllingSlist360_v${STORAGE_VERSION}`,
    BRANCH_SPECIFIC_CONTROLLING_VACANT_POSITIONS_WIDGET_LAYOUT: `bscontrollingVPlayout360_v${STORAGE_VERSION}`,
    BRANCH_SPECIFIC_CONTROLLING_VACANT_POSITIONS_WIDGET_LIST: `bscontrollingVPlist360_v${STORAGE_VERSION}`,
    BRANCH_SPECIFIC_CONTROLLING_STATISTICS_WIDGET_LAYOUT: `bscontrollingSlayout360_v${STORAGE_VERSION}`,
    BRANCH_SPECIFIC_CONTROLLING_STATISTICS_WIDGET_LIST: `bscontrollingSlist360_v${STORAGE_VERSION}`,
    CURRENT_BRANCH: `cbid360_v${STORAGE_VERSION}`,
    WORKFLOW_PANEL_FORM_LAYOUT: `wfpformlayout_v${STORAGE_VERSION}`,
    WORKFLOW_PANEL_FORM_LIST: `wfpformlist_v${STORAGE_VERSION}`,

    TABLE_NAME: {
        DAMAGES: {
            ALL: 'damagesAll',
            MY: 'damagesMy',
        },
        TASKS:{
            ALL_BRANCHES_ALL: 'allBranchesTasksAll',
            ALL_BRANCHES_MY: 'allBranchesTasksMy',
            CURRENT_BRANCH_ALL: 'currentBranchTasksAll',
            CURRENT_BRANCH_MY: 'currentBranchTasksMy'
        },
        ACCOUNTS: 'accounts',
        COMPANIES: 'companies',
        CUSTOMERS: 'customers',
        GROUP_MEMBERS: 'groupMembers',
        USERS: 'users',
        ACTIVITIES: {
            ALL: 'activitiesAll'
        }
    }
}