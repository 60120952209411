import { connect } from 'react-redux'
import EditBranch from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onEditBranch: (
            dialogName, 
            payload,
            metaData,
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName, 
                    payload, 
                    metaData
                )
            )
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditBranch)