import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getAccounts } from 'application/redux/actions/auth'
import { useQuery } from '../hooks'
import { ROUTE_PARAMETERS } from 'application/constants'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => ({
    accounts: state.auth.accounts
})

const mapDispatchToProps = dispatch => ({ 
    onGetAccounts: (offset, limit, direction, property) => {
        dispatch(getAccounts(offset, limit, direction, property))
    }
})

const withAccountsLoaderComponent = accountsPerPage => Component => ({ ...props }) => {
    const query = useQuery()
    const {accounts, onGetAccounts, match} = props
    const routeAccountId = match.params.accountId

    const offset = query.getParsedParam(ROUTE_PARAMETERS.ACCOUNTS.OFFSET)

    const limit = query.getParsedParam(ROUTE_PARAMETERS.ACCOUNTS.LIMIT) === 0 
        ? accountsPerPage
        : query.getParsedParam(ROUTE_PARAMETERS.ACCOUNTS.LIMIT)

    const direction = query.getStringParam(
        ROUTE_PARAMETERS.ACCOUNTS.ORDER_BY.DIRECTION, 
        ROUTE_PARAMETERS.SORT_DIRECTIONS.ASCENDING
    )
        
    const orderBy = query.getStringParam(
        ROUTE_PARAMETERS.ACCOUNTS.ORDER_BY.KEY) 
        || ROUTE_PARAMETERS.ACCOUNTS.ORDER_BY.VALUES.ID

    const shouldLoad = routeAccountId === undefined //for the choose accounts page, fire request everytime the pagination data changes
        || (!accounts.loaded && !accounts.loading) //within an account, load the list only once

    useEffect(() => { 
        shouldLoad
        && onGetAccounts(
            offset, 
            limit, 
            orderBy,
            direction, 
            []
        ) 
    }, [direction, limit, offset, onGetAccounts, orderBy, shouldLoad])
    
    return <Component {...props} accountsPerPage={accountsPerPage} accountsList={accounts.data} />
}

export const withAccountsLoader = (accountsPerPage = 1) => compose(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    ),
    withRouter,
    withAccountsLoaderComponent(accountsPerPage)
)