
import React from 'react'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { ROUTES, USER_PERMISSONS } from 'application/constants'
import ButtonLink from 'application/components/building_blocks/main_navigation_sidebar/_common/button_link'
import PropTypes from 'prop-types'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'

const AllBranchesAccordion = (
    {
        match,
        primaryColor,
        primaryHoverColor
    }
) =>  <React.Fragment>
    <ButtonLink 
        label={'Overview'}
        pathDestination={getAccountLinkHref(match, ROUTES.DASHBOARD)}
        isActive={
            match.path === ROUTES.DASHBOARD 
        }
        iconKey={'widgets'}
        primaryColor={primaryColor}
        primaryHoverColor={primaryHoverColor}
    />
    <ForAnyBranchPermissions
        permissions={[USER_PERMISSONS.DAMAGE.READ]}
    >
        <ButtonLink 
            label={'Damages'}
            pathDestination={getAccountLinkHref(match, ROUTES.DAMAGES_OVERVIEW)}
            isActive={
                match.path === ROUTES.DAMAGES_OVERVIEW 
                || match.path === ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES 
                || match.path === ROUTES.DAMAGES_OVERVIEW_ARCHIVED_DAMAGES 
                || match.path === ROUTES.DAMAGES_OVERVIEW_MY_DAMAGES
            }
            iconKey={'directions_car'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
    </ForAnyBranchPermissions>
    <ForAnyBranchPermissions
        permissions={[USER_PERMISSONS.TASK.READ]} 
    >
        <ButtonLink 
            label={'Tasks'}
            pathDestination={getAccountLinkHref(match, ROUTES.TASKS_OVERVIEW)}
            isActive={
                match.path === ROUTES.TASKS_OVERVIEW 
                || match.path === ROUTES.TASKS_OVERVIEW_ALL_TASKS 
                || match.path === ROUTES.TASKS_OVERVIEW_MY_TASKS
            }
            iconKey={'description'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
    </ForAnyBranchPermissions>
    <ForAnyBranchPermissions
        permissions={[USER_PERMISSONS.MESSAGE.READ]} 
    >
        <ButtonLink 
            label={'Inbox'}
            pathDestination={getAccountLinkHref(match, ROUTES.ALL_BRANCHES_INBOX)}
            isActive={
                match.path === ROUTES.ALL_BRANCHES_INBOX 
                || match.path === ROUTES.INBOX_PRESELECTED
            }
            iconKey={'mail_outline'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
    </ForAnyBranchPermissions>
    <ForAnyBranchPermissions
        permissions={[USER_PERMISSONS.CONTROLLING.READ]}
    >
        <ButtonLink 
            label={'Controlling'}
            pathDestination={getAccountLinkHref(match, ROUTES.CONTROLLING)}
            isActive={
                match.path === ROUTES.CONTROLLING 
                || match.path === ROUTES.CONTROLLING_STATISTICS 
                || match.path === ROUTES.CONTROLLING_INVOICES
            }
            iconKey={'bar_chart'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
    </ForAnyBranchPermissions>
    <ButtonLink 
        label={'Activities'}
        pathDestination={getAccountLinkHref(match, ROUTES.ACTIVITY_LOG)}
        isActive={ match.path === ROUTES.ACTIVITY_LOG }
        iconKey={'list'}
        primaryColor={primaryColor}
        primaryHoverColor={primaryHoverColor}
    />
</React.Fragment>

AllBranchesAccordion.propTypes = {
    match: PropTypes.object.isRequired,
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired
}

export default AllBranchesAccordion