import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import { Spacer } from 'application/components/pages/_common'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import Collapsable from 'application/components/controls/collapsable'
import { DamageForms } from 'application/components/forms'
import { DAMAGE_TYPE } from 'application/constants'
import InfoBox from 'application/components/controls/info_box'
import { INFOBOX } from 'application/constants'
import { FlexBox } from 'application/components/fragments/flex_box'

const TaskDamageCombinedLiabilityForms = withRouter(({
    widgetKey, 
    payload, 
    currentDamage, 
    loadingFailed, 
    match, 
    onSaveInsuranceAndClaim,
    onSaveWitness,
    onSaveOpponentVehicle,
    onSavePolice,
    onSaveOpponentKeeperAndDriver,
    onSaveAccidentReport
}) => {
    const {t} = useTranslation()
    const {damageId} = match.params

    if(loadingFailed){
        return <WidgetErrorMessage>
            {t('Liability data could not be loaded')}
        </WidgetErrorMessage>
    }

    if(currentDamage.type !== DAMAGE_TYPE.LIABILITY){
        return <InfoBox type={INFOBOX.TYPES.SECONDARY} direction='row' cssMargin='0'>
            <FontIcon icon='info_outline' />
            <Spacer basis='10px' />
            {t('Liability forms are only displayed for liability cases.')}
        </InfoBox>
    }

    return <FlexBox flexDirection='column'>
        <Collapsable headline={t('Accident opponent vehicle')} initiallyCollapsed={true}>
            <DamageForms.DamageOpponentVehicleForm 
                onSave={onSaveOpponentVehicle}  
                damageId={damageId}
                formData={payload.formData}
            />
        </Collapsable>

        <Collapsable headline={t('Accident opponent keeper & driver')} initiallyCollapsed={true}>
            <DamageForms.DamageOpponentKeeperAndDriverForm 
                onSave={onSaveOpponentKeeperAndDriver}  
                damageId={damageId}
                formData={payload.formData}
            />
        </Collapsable>

        <Collapsable headline={t('Liability insurance')} initiallyCollapsed={true}>
            <DamageForms.DamageLiabilityInsuranceDataForm 
                onSave={onSaveInsuranceAndClaim}  
                damageId={damageId}
                formData={payload.formData}
            />
        </Collapsable>

        <Collapsable headline={t('Accident situation')} initiallyCollapsed={true}>
            <DamageForms.DamageAccidentReportForm 
                onSave={onSaveAccidentReport}  
                damageId={damageId}
                formData={payload.formData}
            />
        </Collapsable>

        <Collapsable headline={t('Police')} initiallyCollapsed={true}>
            <DamageForms.DamagePoliceForm 
                onSave={onSavePolice}  
                damageId={damageId}
                formData={payload.formData}
            />
        </Collapsable>

        <Collapsable headline={t('Witness')} initiallyCollapsed={true}>
            <DamageForms.DamageWitnessForm 
                onSave={onSaveWitness}  
                damageId={damageId}
                formData={payload.formData}
            />
        </Collapsable>

    </FlexBox>
})

TaskDamageCombinedLiabilityForms.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export { TaskDamageCombinedLiabilityForms }