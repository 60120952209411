import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import '@drawbotics/file-icons/dist/style.css'
import { FileIcon } from '@drawbotics/file-icons'

const StyledFallbackThumbnailComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${props => props.isActive ? props.theme.color.primary : 'transparent'};
    width: ${props => props.theme.thumbnail.width - 2}px;
    height: ${props => props.theme.thumbnail.height - 2}px;
    & .file-icon{
        width: 30px;
        height: 42px;
        &:after{
            height: 15px;
            width: 36px;
            bottom: 7px;
            left: -11px;
        }
    }
`
const FallbackThumbnailComponent = ({ fileExtension, isActive = false }) => {
    return (
        <StyledFallbackThumbnailComponent isActive={isActive}><FileIcon file={fileExtension} /></StyledFallbackThumbnailComponent>
    )
}

FallbackThumbnailComponent.propTypes = {
    fileExtension: PropTypes.string.isRequired,
    isActive: PropTypes.bool
}

export default FallbackThumbnailComponent