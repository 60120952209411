import { put, select } from 'redux-saga/effects'
import { api, dataMappers } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showDialogStepsWaitingScreen, hideDialogStepsWaitingScreen} from 'application/redux/actions/dialog_steps_waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { getCurrentAccount } from 'application/redux/selectors'
import { getFullName } from 'application/common/name_helpers'

export function* dialogCreateDamageQuickStartCommissionSaga(action) {
    const {
        payload,
        metaData
    } = action
    const {
        expert,
        lawfirm,
        customer
    } = payload
    const currentAccount = yield select(getCurrentAccount)

    yield put(
        showDialogStepsWaitingScreen(
            'Sending commission'
        )
    )

    if(lawfirm.commissionLawyer){
        const {selectedLawfirm} = lawfirm
        yield resourceCall(
            api.gateway.damage.updateDamageLawfirm, 
            {
                damageId: metaData.createdDamage.id 
            },
            {
                name: selectedLawfirm.name,
                webakteId: selectedLawfirm.id
            }
        )

        const lawfirmResponse = yield resourceCall(
            api.gateway.lawyerCommunication.getLawfirm, 
            {
                damageId: metaData.createdDamage.id
            }
        )
        var host = window.location.protocol + '//' + window.location.hostname
        
        const sharedResourceId = lawfirmResponse.proxyShare.id
        const mailTrackingLink = `${host}/external/tracking/${sharedResourceId}?fn=${customer.firstName}&ln=${customer.lastName}&c=${encodeURI(currentAccount.name)}`
        const mailSubject = 'Schadennachverfolgung ' + metaData.createdDamage.vehicle.licensePlate
        const mailAddress = 'karsten.egger@e-consult.de'
        const mailName = 'Karsten Egger'
        const mailBody = dataMappers.getMailBody(
            getFullName(customer),
            'Ihr Reparaturauftrag wurde entgegengenommen. Klicken Sie auf diesen Link, um die Schadenabwicklung nachzuverfolgen:',
            `<a href="${mailTrackingLink}">${mailTrackingLink}</a>`
        )

        yield resourceCall(
            api.gateway.mails.sendMail,
            null, 
            {
                to: [{
                    name: mailName,
                    address: mailAddress
                }],
                subject: mailSubject,
                body: mailBody
            }
        )
    }

    if(expert.commissionExpert){
        const {selectedExpert} = expert
        const commissionExpertPayload = dataMappers.exportMappers.expert.commissionOtherExpertDataMapper(selectedExpert.email, selectedExpert.name, selectedExpert.inspectionSite, selectedExpert.inspectionDate, selectedExpert.inspectionName)
        yield resourceCall(
            api.gateway.expert.updateCommissionExpert,
            {
                damageId: metaData.createdDamage.id 
            },
            commissionExpertPayload
        )
    }

    yield put(
        hideDialogStepsWaitingScreen()
    )
    yield put(showToast('Commissions sent'))
}