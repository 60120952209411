import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'
import { LIABILITY_INQUIRY_STATUS } from 'application/constants'
import LiabilityInquiryStatusName from 'application/components/name_mappings/liability_inquiry_status'

const LiabilityInquiryStatusSelect = ({
    name = 'status',
    register,
    setValue,
    error,
    initialValue,
    validate = null,
    menuPlacement = 'bottom',
    onChange
}) => {
    const { t } = useTranslation()
    const liabilityInquryStatusOptions = [
        {
            value: LIABILITY_INQUIRY_STATUS.UNKNOWN,
            label: <LiabilityInquiryStatusName liabilityInquiryStatus={LIABILITY_INQUIRY_STATUS.UNKNOWN} />
        },
        {
            value: LIABILITY_INQUIRY_STATUS.CONFIRMED,
            label: <LiabilityInquiryStatusName liabilityInquiryStatus={LIABILITY_INQUIRY_STATUS.CONFIRMED} />
        },
        {
            value: LIABILITY_INQUIRY_STATUS.UNCONFIRMED,
            label: <LiabilityInquiryStatusName liabilityInquiryStatus={LIABILITY_INQUIRY_STATUS.UNCONFIRMED} />
        },
    ]

    const initialOption = liabilityInquryStatusOptions.find(o => o.value === initialValue)

    return <Select
        error={error}
        label={t('Liability type')}
        name={name}
        register={register}
        setValue={setValue}
        options={liabilityInquryStatusOptions}
        defaultValue={initialOption}
        validate={validate} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
    />
}

LiabilityInquiryStatusSelect.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    menuPlacement: PropTypes.string
}

export default LiabilityInquiryStatusSelect