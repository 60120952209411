import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import * as Fragments from './fragments'
import IconButton from 'application/components/controls/icon_button/component'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label'
import Preloader from 'application/components/controls/themed_preloader'
import TaskLinked from 'application/components/building_blocks/task_linked'
import { getAccountLinkHref } from 'application/common/route_helpers'

const TaskBar = (
    {
        tasksList, 
        tasksLoaded, 
        tasksLoading, 
        damage, 
        onAddTask, 
        onDeleteTasks, 
        onForwardTasks, 
        onDownloadTasks, 
        onTaskClick=()=>{}, 
        match
    }
) => {
    const {t} = useTranslation()
    const [selectedTasks, setSelectedTasks]= useState([])
    const [editModeActive, setEditModeActive] = useState(false)
    const {damageId, taskId} = match.params

    const selectTask = (taskId) => {
        setSelectedTasks([...selectedTasks, taskId])
    }

    const deselectTask = (taskId) => {
        setSelectedTasks(selectedTasks.filter(id => id === taskId))
    }

    const handleAddTask = () => {
        onAddTask(damageId, damage)
    }

    return <Fragments.Wrapper>
        <Fragments.Bar>
            {
                editModeActive 
                    ? <Fragments.BarHeader>
                        <IconButton
                            iconKey='get_app'
                            onButtonClick={()=>{onDownloadTasks(selectedTasks)}}
                            width='32px'
                            height='32px'
                            iconSize='20px'
                            color='anthracite'
                        />
                        <IconButton
                            iconKey='send'
                            onButtonClick={()=>{onForwardTasks(selectedTasks)}}
                            width='32px'
                            height='32px'
                            iconSize='20px'
                            color='anthracite'
                        />
                        <IconButton
                            iconKey='delete'
                            onButtonClick={()=>{onDeleteTasks(selectedTasks)}}
                            width='32px'
                            height='32px'
                            iconSize='20px'
                            color='anthracite'
                        />
                        <Fragments.Spacer />
                        <IconButtonWithLabel
                            onButtonClick={() => { setEditModeActive(false); setSelectedTasks([]) }}
                            iconKey='close'
                            label={t('Finish')}
                            iconSize='20px'
                        />
                    </Fragments.BarHeader>
                    : <Fragments.BarHeader>
                        <Fragments.BarTitle>{t('Tasks')}</Fragments.BarTitle>
                        <IconButton
                            iconKey='add'
                            onButtonClick={handleAddTask}
                            width='32px'
                            height='32px'
                            iconSize='20px'
                            color='anthracite'
                        />
                        {/* <IconButtonWithLabel
                            onButtonClick={() => { setEditModeActive(true) }}
                            iconKey='edit'
                            label={t('Edit')}
                            iconSize='20px'
                        /> */}
                    </Fragments.BarHeader>
            }
        </Fragments.Bar>
        <Fragments.TaskList>
            {
                tasksLoading && <Preloader />
            }
            {
                tasksLoaded && tasksList.map(t => {
                    return <TaskLinked 
                        key={t.id}
                        isActive={t.id === taskId}
                        task={t}
                        link={getAccountLinkHref(match, `/damages/${damageId}/tasks/${t.id}`)}
                        canBeSelected={true}
                        isSeclected={selectedTasks.includes(t.id)}
                        onSelectTask={selectTask}
                        onDeselectTask={deselectTask}
                        isSelectMode={editModeActive}
                        onClick={onTaskClick}
                    />
                })
            }
        </Fragments.TaskList>
    </Fragments.Wrapper>
}

TaskBar.propTypes = {
    tasksList: PropTypes.array.isRequired,
    tasksLoaded: PropTypes.bool.isRequired,
    tasksLoading: PropTypes.bool.isRequired,
    onAddTask: PropTypes.func.isRequired,
    onDeleteTasks: PropTypes.func.isRequired,
    onForwardTasks: PropTypes.func.isRequired,
    onDownloadTasks: PropTypes.func.isRequired,
    onTaskClick: PropTypes.func
}

export default withRouter(TaskBar)