import { put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api, dataMappers } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* dialogCommissionOtherExpertSaga(action) {

    const { payload, metaData } = action
    const { damageId } = metaData

    //send expert commission
    const commissionExpertPayload = dataMappers.exportMappers.expert.commissionOtherExpertDataMapper(
        payload.email, 
        payload.name, 
        payload.inspectionSite, 
        payload.inspectionDate,
        payload.inspectionInfoName
    )

    yield put(showWaitingScreen('Assigning expert'))

    yield resourceCall(
        api.gateway.expert.updateCommissionExpert,
        {
            damageId 
        },
        commissionExpertPayload
    )
    
    yield put(hideWaitingScreen())

    yield put(showToast('Expert commissioned'))
}