import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import uuidv4 from 'uuid/v4'
import { Controller } from 'react-hook-form'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/components/controls/form/_common'
import { toCurrencyString } from 'application/common/currency_helpers'
import { FormElementWrapper, TextInputWrapper } from '../_common'

const defaultMaskOptions = {
    prefix: '',
    suffix: ' €',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 12, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

const CurrencyInput = (
    { 
        name, 
        onChange, 
        control, 
        register, 
        validate, 
        maskOptions, 
        error, 
        hideValidationMessage = false, 
        errorMessageOverride = '', 
        label = '', 
        defaultValue, 
        inputType, 
        ...inputProps
    }
) => {
    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
    })
    const currencyValue = toCurrencyString(defaultValue)

    const htmlId = uuidv4()

    const handleOnChange = (...args) => onChange(...args)

    return <FormElementWrapper>
        <label 
            className='label' 
            htmlFor={htmlId}
        >
            {label}
            <RequiredFieldMarker validate={validate}/>
        </label>
        <TextInputWrapper>
            <Controller
                control={control}
                valueName={name}
                name={name}
                rules={{ validate }}
                defaultValue={currencyValue}
                render={
                    ({ onChange, name }) => (
                        <MaskedInput
                            name={name}
                            defaultValue={currencyValue}
                            onChange={(...args) => {
                                onChange(...args)
                                handleOnChange(...args)
                            }}
                            ref={() => register({ name })}
                            type={inputType}
                            mask={currencyMask}
                            {...inputProps} 
                        />
                    )
                }
            />
        </TextInputWrapper>
        <ValidationErrorMessage 
            hideValidationMessage={hideValidationMessage} 
            error={error} 
            errorMessageOverride={errorMessageOverride} 
        />
    </FormElementWrapper>
}

CurrencyInput.defaultProps = {
    inputMode: 'numeric',
    maskOptions: {},
}

CurrencyInput.propTypes = {
    inputmode: PropTypes.string,
    maskOptions: PropTypes.shape({
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        includeThousandsSeparator: PropTypes.bool,
        thousandsSeparatorSymbol: PropTypes.string,
        allowDecimal: PropTypes.bool,
        decimalSymbol: PropTypes.string,
        decimalLimit: PropTypes.string,
        requireDecimal: PropTypes.bool,
        allowNegative: PropTypes.bool,
        allowLeadingZeroes: PropTypes.bool,
        integerLimit: PropTypes.number,
    }),
}

export default CurrencyInput
