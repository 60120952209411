import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from 'application/components/controls/hyperlink'
import * as Fragments from './fragments'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { withRouter } from 'react-router-dom'

export const ExpertCommunicationOtherInitiatedMetaContent = withRouter(
    (
        {
            notification, 
            onLinkClick, 
            inline, 
            match
        }
    ) => {
        const {t} = useTranslation()

        const {payload} = notification
        const damageLink =  getAccountLinkHref(
            match, 
            `/damages/${payload.damage.id}`
        )
      
        return <Fragments.MetaContentContainer inline={inline}>
            <Fragments.ActivityDescription 
                label='Damage'
                value={payload.damage.vehicle.licensePlate}
            />
            <Fragments.ActivityLink inline={inline}>
                <Link 
                    onClick={onLinkClick} 
                    to={damageLink}
                >
                    {t('jump to damage')}
                </Link>
            </Fragments.ActivityLink>
        </Fragments.MetaContentContainer>
    })