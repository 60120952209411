import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { populateFullAttachment } from 'application/redux/actions/attachments'
import { fullAttachmentLoaded } from 'application/redux/selectors'
import { put, select, takeEvery } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* fetchFullAttachmentByIdSaga(action) {
    try {
        const isLoaded = yield select(fullAttachmentLoaded, action.id)

        if (!isLoaded) {
            const result = yield resourceCall(
                api.gateway.attachments.getAttachmentDataById, 
                {
                    damageId: action.metaData.damageId, //in wfp, this is undefined but handled by the resourceCall method
                    attachmentId: action.id
                }
            )
            const blobUrl = window.URL.createObjectURL(result)
            yield put(populateFullAttachment(action.id, action.attachmentName, blobUrl))
        }


    } catch (e) {
        yield handleError(e, action)
    }
}

export function* fetchFullAttachmentByIdWatcher() {
    yield takeEvery(ACTIONS.ATTACHMENTS_GET_FULL_ATTACHMENT_REQUEST, fetchFullAttachmentByIdSaga)
}