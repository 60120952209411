import { connect } from 'react-redux'
import { CreateDamageKickstartExternal } from './component'
import { createDamageKickstartRequest } from 'application/redux/actions/ext_create_damage'

const mapStateToProps = state => ({
    damageDataSent: state.pages.extCreateDamage.damageDataSent,
    damageDataSending: state.pages.extCreateDamage.damageDataSending,
    damageId: state.pages.extCreateDamage.damageId,
    documentsSent: state.pages.extCreateDamage.documentsSent,
    documentsSending: state.pages.extCreateDamage.documentsSending,
})

const mapDispatchToProps = dispatch => {
    return {
        onCreateDamage: (payload) => dispatch(createDamageKickstartRequest(payload)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateDamageKickstartExternal)