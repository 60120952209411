import React from 'react'
import {
    Switch,
    Route,
    withRouter,
    Redirect
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LayoutCurrentBranch } from 'application/components/pages/_layout'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { ROUTES } from 'application/constants'
import MyTasks from './my_tasks'
import AllTasks from './all_tasks'
import { getBranchLinkHref } from 'application/common/route_helpers'

const TasksOverview = (
    {
        match,
        currentBranch
    }
) => {
    const {t} = useTranslation()

    const currentBranchId = currentBranch !== null ? currentBranch.id : null

    if(currentBranchId === null){
        return null
    }

    if (match.path === ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW) {
        return <Redirect to={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS, currentBranchId)} />
    }

    return <LayoutCurrentBranch
        titleSegments={['Tasks']}
        headline={t('Tasks')}
    >
        <TabsLinks
            items={[
                {
                    label: t('My tasks'),
                    link: getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS, currentBranchId),
                    isActive: match.path === ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS
                },
                {
                    label: t('All tasks'),
                    link: getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_ALL_TASKS, currentBranchId),
                    isActive: match.path === ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_ALL_TASKS
                } 
            ]}
        />

        <Switch>
            <Route path={ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_ALL_TASKS}>
                <AllTasks />
            </Route>
            <Route path={ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS}>
                <MyTasks />
            </Route>
        </Switch>

    </LayoutCurrentBranch>
}

export default withRouter(TasksOverview)