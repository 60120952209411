import React from 'react'
import PropTypes from 'prop-types'
import { ValidationErrorMessage } from 'application/components/controls/form/_common'

const HiddenField = ({
    name,
    register=()=>{},
    validate=null,
    error,
    errorMessageOverride=''
}) => {
    return <React.Fragment>
        <input type="hidden" ref={register({validate})} name={name} />
        {
            validate !== null && <ValidationErrorMessage 
                error={error} 
                errorMessageOverride={errorMessageOverride} 
            />
        }
    </React.Fragment>
}

HiddenField.propTypes = {
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired
}

export default HiddenField