import React from 'react'
import { PureSelect } from 'application/components/controls/form'
import { useTranslation } from 'react-i18next'

export default ({
    statusList,
    statusListLoaded,
    damage,
    damageLoaded,
    onUpdateState
}) => {
    const {t} = useTranslation()
    
    if(!statusListLoaded || !damageLoaded){
        return <PureSelect 
            options={[]}
            value={null}
            isDisabled={true}
            placeholder={t('loading...')}
        />
    }

    const stateOptions = statusList.map(s => ({
        value: s.id,
        label: s.name
    }))

    const stateInitialValue = damage.state !== undefined ? {
        value: damage.state.id,
        label: damage.state.name
    } : stateOptions[0]

    const statusChange = (s) => {
        onUpdateState(
            damage.id,
            s.value
        )
    }

    return <PureSelect
        options={stateOptions}
        value={stateInitialValue}
        onChange={statusChange}
    />
}