import { combineReducers } from 'redux'

// import commonData from './common_data'
import tasks from './tasks'
import shared from './shared'
import userData from './user_data'
import activityLog from './activity_log'
import documentCenter from './document_center'
import inbox from './inbox'

export default combineReducers({
    // commonData,
    tasks,
    shared,
    userData,
    activityLog,
    documentCenter,
    inbox,
})