import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { Select,  HiddenField, PureSelect } from 'application/components/controls/form'
import { FormElementWrapper, FormElementGroup } from 'application/components/controls/form/_common'
import { nameHelpers } from 'application/common'
import CustomerCard from 'application/components/building_blocks/customer_card'
import { FlexBox } from 'application/components/fragments/flex_box'

export default ({
    register,
    getValues,
    setValue,
    errors,
    stateForm,
    customerList,
    name = 'customerSearch',
    hiddenFieldName = 'customerId',
    onDataChange = () => {},
    onChange = () => {},
    onSearchCustomer,
    dialog,
    isActive = true
}) => {
    const {t} = useTranslation()
    const [customerSearchFilter, setCustomerSearchFilter] = useState('private')
    const [customerSearchActive, setCustomerSearchActive] = useState(false)
    const [customerListState, setCustomerListState] = useState(customerList)
    const [existingCustomerData, setExistingCustomerData] = useState(null)

    useEffect(()=>{
        setCustomerListState(customerList)
    }, [customerList])
    
    useEffect(()=>{
        setCustomerSearchFilter('private')
        setExistingCustomerData(null)
        setCustomerListState([])
    }, [isActive])

    const searchOptions = [{
        value: 'private',
        label: t('Private customer')
    },{
        value: 'company',
        label: t('Company')
    }]

    const selectedSearchOption = searchOptions.find(o => o.value === customerSearchFilter)

    const customerOptions = customerListState.map(b => ({
        label: nameHelpers.getFullName(b),
        value: b.id
    }))

    const handleSearchCustomer = search => {
        if(customerSearchActive && search.length > 2){
            onSearchCustomer(dialog.name, search, customerSearchFilter === 'company')
        }  
    }
 
    return <React.Fragment>
        <HiddenField name={hiddenFieldName} register={register} />
        <FlexBox>
            <FormElementWrapper>
                <label>{t('Search for existing customer')}</label>
                <FormElementGroup>
                    <PureSelect 
                        options={searchOptions}
                        value={selectedSearchOption}
                        onChange={option => {
                            setCustomerSearchFilter(option.value)
                            setCustomerListState([])
                            setExistingCustomerData(null)
                            setValue(name, null)
                        }}
                        cssFlexBasis='200px'
                        isSearchable={false}
                    />
                    <Select 
                        register={register}
                        name={name}
                        validate={{notEmpty: validationMethods.notEmpty}}
                        setValue={setValue}
                        options={customerOptions} 
                        onChange={option => {
                            const customerId = option === null ? '' : option.value
                            onDataChange()
                            onChange(customerId)
                            setValue(hiddenFieldName, customerId)
                            setExistingCustomerData(option === null ? null : customerList.find(b => b.id === option.value))
                        }}
                        onBlur={()=>{setCustomerSearchActive(false)}}
                        onFocus={()=>{setCustomerSearchActive(true)}}
                        onInputChange={handleSearchCustomer}
                        noOptionsMessage={t('No customers found')}
                        placeholder={t('Enter at least 3 characters')}
                        isLoading={dialog.payload.customerListLoading && customerSearchActive}
                        isClearable={true}
                        error={errors[name]}
                        errorMessageOverride='Select an existing customer'
                        defaultValue={customerOptions.find(b => b.value === stateForm[name])}
                    />
                </FormElementGroup>
            </FormElementWrapper>
        </FlexBox>
        {
            existingCustomerData !== null && <CustomerCard 
                customer={existingCustomerData} 
                cssMargin='8px 0 0 0'
            />
        }
    </React.Fragment>
}