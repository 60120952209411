import React from 'react'
import WidgetArea from 'application/components/building_blocks/widget_area'
import { WIDGET_CONTEXTS } from 'application/constants'
import DocumentsSidebar from 'application/components/building_blocks/document_sidebar'
import { withRouter } from 'react-router-dom'
import { exists } from 'application/common/data_helpers'

export default withRouter((
    {
        tasksAttachmentDetails, 
        onTaskAttachmentDetailsClose, 
        onChangeCategory, 
        match
    }
) => {
    //don't render the sidebar if no active task is given
    if(!exists(match.params.taskId)){
        return null
    }

    return tasksAttachmentDetails.visible
        ? <DocumentsSidebar
            attachmentDetails={tasksAttachmentDetails}
            onClose={onTaskAttachmentDetailsClose}
            showCategorySelect={true}
            onChangeCategory={onChangeCategory}
        />
        : <WidgetArea 
            widgetContext={WIDGET_CONTEXTS.DAMAGE_MANAGER_TASKS_SIDEBAR} 
        />
})