import { ROUTES, SHARED_RESOURCE_TYPE } from 'application/constants'
import React from 'react'
import { withRouter, Redirect } from 'react-router'
import {NotFoundPage} from 'application/components/pages/errors/external/not_found'


export const SharedResourceTypeSwitch = withRouter((
    {
        match
    }
) => {
    const {
        sharedResourceId, 
        sharedResourceType
    } = match.params

    switch (sharedResourceType) {
    case SHARED_RESOURCE_TYPE.LAWFIRM:
        return <Redirect 
            to={`${ROUTES.EXT_WORKFLOW_PANEL_BASE}${sharedResourceId}`} 
        />
    case SHARED_RESOURCE_TYPE.DAMAGE_TRACKING:
        return <Redirect 
            to={`${ROUTES.EXT_DAMAGE_TRACKING_BASE}${sharedResourceId}`} 
        />
    default:
        return <NotFoundPage 
            errorMessage={`resource type "${sharedResourceType}" not found`}
        />
    }
})