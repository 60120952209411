import { connect } from 'react-redux'
import DamagesOverview from './component'
import { withLoggedInUser } from 'application/components/context/logged_in'


const mapStateToProps = (state) => {

    return {}
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withLoggedInUser(DamagesOverview) )