import { put, select } from 'redux-saga/effects'
import { api, dataMappers } from 'application/api'
import { showToast } from 'application/redux/actions/notifications'
import { showDialogStepsWaitingScreen, hideDialogStepsWaitingScreen} from 'application/redux/actions/dialog_steps_waiting_screen'
import { getCurrentBranchId } from 'application/redux/selectors'
import { exists } from 'application/common/data_helpers'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'

export function* dialogCreateDamageSaga(action) {
    const currentBranchId = yield select(getCurrentBranchId)
    const createCustomerPayload = dataMappers.exportMappers.damage.createCustomerDataMapper(action.payload)

    yield put(
        showDialogStepsWaitingScreen(
            'Creating damage'
        )
    )

    let customerId = action.payload.customerId

    if(!exists( action.payload.customerId ) ){
        const createCustomerResponse = yield resourceCall(
            api.gateway.customer.postCustomer, 
            null,
            createCustomerPayload
        )

        customerId = createCustomerResponse.id
    }

    const payload = dataMappers.exportMappers.damage.createDamageDataMapper(
        action.payload, 
        customerId,
        action.payload.assignedTo
    )

    const damageCreationResponse = yield resourceCall(
        api.gateway.damage.createDamage, 
        {
            branchId: currentBranchId
        },
        payload
    )

    const damageIdObject = {
        damageId: damageCreationResponse.id
    }

    const createdDamageDataResponse = yield resourceCall(
        api.gateway.damage.getDamage, 
        damageIdObject,
        payload
    )

    const customer = yield resourceCall(
        api.gateway.damage.getVehicleKeeperByDamageId, 
        damageIdObject
    )

    yield put(
        updateDialogDataByName(
            action.dialogName,
            { 
                createdDamage: createdDamageDataResponse,
                customer: customer
            }
        )
    )

    yield put(
        hideDialogStepsWaitingScreen()
    )
    yield put(showToast('Damage created'))
}