import { put } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateDialogDataByName } from 'application/redux/actions/dialogs'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'


export function* dialogGetDamagesByBranchSaga(action) {

    const { 
        dialogName, 
        metaData 
    } = action
    
    const { branchId } = metaData

    const filterQuery = new ListFilter(filters.common.branchId).setValue(branchId).getQuery()

    const damagesResponse = yield resourceCall(
        api.gateway.damages.getDamages, 
        {
            offset: 0,
            limit: 1,
            orderBy: 'createdAt',
            direction: 'desc',
            filterQuery
        }
    )

    yield put(
        updateDialogDataByName(
            dialogName, 
            {
                damagesTotal: damagesResponse.total
            }
        )
    )
}