import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledIconLink = styled.div`
    list-style: none;
    justify-content: center;
    display: flex;
    width: ${props => props.cssWidth};
    height: ${props => props.cssHeight};

    & i {
        align-self: center;
        font-size: ${props => props.fontSize ? props.fontSize : '12px' };
        font-weight: ${props => props.active ? 'bold' : '' }
    }

    & i.material-icons {
        width: inherit;
    }

    & > a {
        width: inherit;
        height: inherit;
        display: flex;
        color:  ${props => props.active ? props.theme.color.primary : props.color ? props.color :  props.theme.color.anthracite};
        text-decoration: none;
        text-align: ${props => props.textAlign};
        justify-content: ${props => props.textAlign === 'right' ? 'flex-end' : props.textAlign === 'center' ? 'center' : 'flex-start'};
        &:hover {
            color: ${props => props.theme.color.black};
        }
        &:active {
            color: ${props => props.theme.color.black};
        }
    }
    &:hover{
        background: ${props => props.cssHoverBackground};
    }
`
const IconLink = ({ 
    to,
    className='',
    iconKey, 
    iconSize, 
    color,
    cssWidth = '32px',
    cssHeight = '32px',
    textAlign = 'left',
    cssHoverBackground = 'transparent',
    title='',
    onLinkClick = () => {}
}) => {
    return <StyledIconLink color={color}
        className={className}
        fontSize={iconSize}
        cssWidth={cssWidth}
        cssHeight={cssHeight}
        textAlign={textAlign}
        cssHoverBackground={cssHoverBackground}
        title={title}
    >
        <NavLink to={to} onClick={onLinkClick}>
            <i className="material-icons"  >{ iconKey ?  iconKey : 'perm_identity'}</i>
        </NavLink>
    </StyledIconLink>
}

IconLink.propTypes = {
    to: PropTypes.string,
    className: PropTypes.string,
    iconKey: PropTypes.string,
    iconSize: PropTypes.string,
    color: PropTypes.string,
    cssWidth: PropTypes.string,
    cssHeight: PropTypes.string,
    cssHoverBackground: PropTypes.string,
    title: PropTypes.string,
}


export default withRouter(IconLink) 