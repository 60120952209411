import { connect } from 'react-redux'
import MainNavigationSidebar from './component'
import {toggleMainNavigationSidebar} from 'application/redux/actions/main_navigation_sidebar'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        sideBarOpen: state.mainNavigationSidebar.sideBarOpen,
        currentBranchLoaded: state.auth.currentBranchLoaded,
        hasMultipleBranches: state.auth.hasMultipleBranches
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onToggleSidebar: () => { 
            dispatch(
                toggleMainNavigationSidebar()
            )
        },
        onOpenChangeBranchDialog : () => dispatch(
            openDialogByName(
                DIALOG.NAME.BRANCH_MANAGEMENT_CHANGE_BRANCH,
                true, 
                {}, 
                'Change branch'
            )
        ),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainNavigationSidebar)