import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { PushBottom24 } from 'application/components/pages/_common'
import { Footer } from 'application/components/dialogs/_common/footer'
import Collapsable from 'application/components/controls/collapsable/component'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { VacantPositionsBar } from 'application/components/charts'
import { getVacantPositions, groupInvoicesByType } from 'application/common/invoice_helpers'
import { Invoice } from 'application/components/building_blocks/invoices'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'




const DamageInvoices = (
    { 
        abort, 
        dialog 
    }
) => {
    const {t} = useTranslation()
    const { initiallyCollapsed } = dialog.payload
    const { invoices } = dialog.payload.data
    const groupedInvoices = groupInvoicesByType(invoices)

    return <React.Fragment>
        <DialogMainContent flexDirection='column' >
            {
                groupedInvoices.map( 
                    giv => {
                        const {
                            unpaidTotal, 
                            paidTotal
                        } = getVacantPositions(giv.invoices)

                        const bar = <VacantPositionsBar 
                            cssWidth='160px' 
                            unpaidTotal={unpaidTotal} 
                            paidTotal={paidTotal} 
                        />

                        return <Collapsable 
                            key={giv.groupName} 
                            headline={`${t(giv.groupName)} (${giv.invoices.length})`}
                            initiallyCollapsed={initiallyCollapsed} 
                            additionalHeaderItem={bar}>
                            {
                                giv.invoices.map(
                                    (iv, i)=> {
                                        return <React.Fragment key={i}>
                                            <Invoice invoiceData={iv}/>
                                            <PushBottom24 />
                                        </React.Fragment>
                                    }
                                )
                            }
                        </Collapsable>
                    }
                )
            }

            {
                invoices.length === 0 && <WidgetErrorMessage>{t('No invoices at the moment')}</WidgetErrorMessage>
            }
        </DialogMainContent>
        <Footer
            onAbort={abort}
            hasSubmitButton={false}
        />
    </React.Fragment>

}

export default withDialogDataLoader(DamageInvoices) 