import { put } from 'redux-saga/effects'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { getDynamicDashboardConfiguration } from 'application/widget_configuration'
import { saveWidgetLayout, saveWidgetList, saveWidgetBoardConfig } from 'application/redux/actions/widget_contexts'
import { WIDGET_CONTEXTS } from 'application/constants'
import { updateWidgetContext } from 'application/redux/actions/widget_contexts'

export function* dialogConfigureDashboardSaga(action) {
    try {
        const { dashboardConfiguration } = action.payload

        const newConfiguration = getDynamicDashboardConfiguration(dashboardConfiguration) 


        yield put(saveWidgetBoardConfig(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD, newConfiguration.branch.widgetBoardConfig))
        yield put(saveWidgetLayout(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD, newConfiguration.branch.widgetLayout.LARGE))
        yield put(saveWidgetList(WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD, newConfiguration.branch.widgetList))
        yield put(updateWidgetContext(
            WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD,
            newConfiguration.branch.widgetLayout.LARGE,
            newConfiguration.branch.widgetList,
            newConfiguration.branch.widgetBoardConfig
        ))

        yield put(saveWidgetBoardConfig(WIDGET_CONTEXTS.DASHBOARD, newConfiguration.generic.widgetBoardConfig))
        yield put(saveWidgetLayout(WIDGET_CONTEXTS.DASHBOARD, newConfiguration.generic.widgetLayout.LARGE))
        yield put(saveWidgetList(WIDGET_CONTEXTS.DASHBOARD, newConfiguration.generic.widgetList))
        yield put(updateWidgetContext(
            WIDGET_CONTEXTS.DASHBOARD,
            newConfiguration.generic.widgetLayout.LARGE,
            newConfiguration.generic.widgetList,
            newConfiguration.generic.widgetBoardConfig
        ))

        yield put(showToast('Configuration saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}