import styled from 'styled-components'
import Widget from './container'

export const WidgetContainer = styled.div`
    background: ${props => props.theme.color.white};
    & .react-resizable-handle{
        bottom: -3px;
        right: -3px;
    }
    overflow: hidden;
    & .on-hover{
        display: none;
    }
    &:hover .on-hover{
        display: block;
    }
`

export const RemoveWidgetButton = styled.button`
    font-size: ${props => props.theme.fontSize.small};
    background-color: ${props => props.theme.color.gray10};
    border-radius: 2px;
    padding-top: 3px;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
`

export const WidgetHandle = styled.span`
    display: block;
    font-size: ${props => props.theme.fontSize.small};
    background-color: ${props => props.theme.color.gray10};
    border-radius: 2px;
    padding: 3px 4px 1px;
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    cursor: grab;
    & i{
        cursor: grab;
    }
    &:active{
        cursor: grabbing;
        & i{
            cursor: grabbing;
        }
    }
`

export const WidgetEditLink = styled.span`
    display: block;
    font-size: ${props => props.theme.fontSize.small};
    background-color: ${props => props.theme.color.gray10};
    border-radius: 2px;
    padding: 3px 4px 1px;
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
`

export default Widget