import { DAMAGE_TYPE } from 'application/constants'
import { toDecimal } from './currency_helpers'
import { exists } from './data_helpers'
import IBAN from 'iban'

export const isLatitude = val => val !== '' && !isNaN(val) && isFinite(val) && Math.abs(val) <= 90
export const isLongitude = val => val !== '' && !isNaN(val) && isFinite(val) && Math.abs(val) <= 180

export const validationMethods = {
    //for new validation with useForm
    notEmpty: value => {
        return (value !== '' && value !== undefined && value !== null) || 'This field is required'
    },
    mustBeEmail: value => {
        return (value === '' || value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) || 'Valid E-Mail-Adress required'
    },
    currencyMustBeNotZero: value => {
        return toDecimal(value) !== 0 || 'Value must not be zero'
    },
    mustBeValidLongitude: value => {
        const val = exists(value) ? value : ''
        return (!exists(val) || isLongitude(val)) || 'Must be valid longitude'
    },
    mustBeValidLatitude: value => {
        const val = exists(value) ? value : ''
        return (!exists(val) || isLatitude(val)) || 'Must be valid latitude'
    },
    damageTypeMustNotBeUnknown: value => {
        return value !== DAMAGE_TYPE.UNKNOWN || 'Damage type must not be "Unknown"'
    },
    mustBeGermanPostalCode: value => {
        return (value === '' || value.match(/^[1-9]{5}$/)) || 'Invalid postal code'
    },
    max50Characters: value => {
        return value.length <= 50 || 'This field is limited to 50 characters'
    },
    selectAtLeastOne: value => {
        const intValue = parseInt(value) || 0
        return intValue > 0 || 'Please select at least one'
    },
    notFalse: value => {
        return value !== false || 'This field could not be false'
    },
    mustBeIBAN: value => {
        const IBANcouldBeEmptyOrValid = !exists(value) || (IBAN.isValid(value) && value.match(/^[A-Z0-9]*$/))
        return IBANcouldBeEmptyOrValid || 'IBAN is invalid'
    },
    //deprecated, for reference
    passwordMinLength: value => {
        return value.length >= 8
    },
    includesLowerCaseCharacter: value => {
        return value.match(/[a-z]/)
    },
    includesUpperCaseCharacter: value => {
        return value.match(/[A-Z]/)
    },
    includesSpecialCharacter: value => {
        return value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)
    },
    includesNumericCharacter: value => {
        return value.match(/\d+/)
    },
    emailRegex: value => {
        return value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    },
    phoneRegex: value => {
        return value.match(/^.{4,15}[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/)
    },
    rangeLengthRegex : value => {
        return value.match(/^.{3,5}$/)
    },
    rangeLengthPayload : valueKey => {
        return (valueKey.length > 2 && valueKey.length < 5)
    },
    maxLengthRegex : value => {
        return value.match(/^.{0,10}$/)
    },
    maxLengthPayload : valueKey => {
        return (valueKey.length < 11)
    },
    minLengthRegex : value => {
        return value.match(/^.{2,}$/)
    },
    minLengthPayload : valueKey => {
        return (valueKey.length > 0)
    },
    zipCodeRegex : value => {
        return value.match(/^\d{5}$/)
    }
}

export const getErrorMessage = () => {}