import { exists } from 'application/common/data_helpers'
import React, { useEffect } from 'react'
import {MassOperationPanel} from './component'
import Preloader from 'application/components/controls/themed_preloader'
import { useTranslation } from 'react-i18next'

const MassOperationEffects = (props) => {

    const {  
        onRegisterMassOperation,
        moName,
        moType,
        ...rest 
    } = props



    const {t} = useTranslation()

    const massOperation = props.mo

    useEffect(() => {
        !exists(massOperation) && onRegisterMassOperation(
            moName,
            moType
        )
    }, [massOperation, moName, moType, onRegisterMassOperation])

    return exists(massOperation) 
        ?  <MassOperationPanel 
            {...rest} 
        />
        : <Preloader 
            preloaderText={t('Selectionmode is loading...')} 
        /> 
   
}

export { MassOperationEffects }
