import React  from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'application/components/controls/form'


const BranchSelectComponent = ({
    register,
    setValue,
    name='branchId',
    menuPlacement='bottom',
    branches = [],
    onChange = () => {},
    initialBranchId = null,
    label = 'Branch',
    disabled = false
}) => {
    const {t} = useTranslation()
    
    const options = branches.map(
        b => ({
            value: b.id,
            label: b.name
        })
    )

    const initialOption = initialBranchId === null 
        ? options[0] 
        : options.find(o => o.value === initialBranchId)
    
    return <Select 
        label={t(label)}
        name={name}
        register={register}
        setValue={setValue}
        options={options}
        defaultValue={initialOption}
        validate={null} //no validation needed, no empty value possible
        isClearable={false}
        menuPlacement={menuPlacement}
        onChange={onChange}
        disabled={disabled}
    />
}

BranchSelectComponent.propTypes = {
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    name: PropTypes.string,
    menuPlacement: PropTypes.string,
    onChange: PropTypes.func,
    branches: PropTypes.array,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    initialBranchId: PropTypes.string
}

export {BranchSelectComponent}