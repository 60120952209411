import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import LanguageSwitch from '..'
import { I18N } from 'application/constants'

const LanguageSwitchHoc = ({cssPadding='0'}) => {
    const {i18n} = useTranslation()
    const currentLanguage = i18next.language
    return <LanguageSwitch 
        cssPadding={cssPadding}
        languages={I18N.LANGUAGES}
        currentLanguage={currentLanguage}
        onSwitchLanguages={(lang)=>{i18n.changeLanguage(lang)}}
    /> 
}

export default LanguageSwitchHoc