import React from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
    ROUTES, SHARED_RESOURCE_TYPE, WIDGET_CONTEXTS,
    // WIDGET_CONTEXTS
} from 'application/constants'
import * as Common from 'application/components/pages/_common'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import DocumentsSidebar from 'application/components/building_blocks/document_sidebar'
import Preloader from 'application/components/controls/themed_preloader'
import Tasks from './tasks'
import Form from './form'
import ActivityLog from 'application/components/pages/external/_common/activity_log'
import Documents from './documents'
import WidgetArea from 'application/components/building_blocks/widget_area'
// import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import LayoutWorkflowPanel from './layout'
import { withSharedResourcePinValidation } from 'application/components/higher_order_components'
import WorkflowPanelInbox from './inbox'


const Lawfirm = (
    { 
        match, 
        damageLoaded, 
        selectedTaskLoaded, 
        documentCenterArchiveDownloading, 
        documentCenterAttachmentDetails, 
        tasksAttachmentDetails, 
        onDocumentCenterAttachmentDetailsClose, 
        onTaskAttachmentDetailsClose, 
        onCreateInvoice, 
        contactPerson, 
        onDownloadAttachmentsWFPZipRequest, 
        hasAttachments 
    }
) => {
    const { t } = useTranslation()
    const {sharedResourceId} = match.params

    if (match.params.subRoute === undefined) {
        return <Redirect to={ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/form'} />
    }

    return <LayoutWorkflowPanel>
        <Common.Container>
            <Common.Main paddingLeft='0' paddingRight='0'>
                <Common.VerticalContainer>
                    <TabsLinks
                        items={[
                            {
                                label: t('Form'),
                                link: ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/form',
                                isActive: match.params.subRoute === 'form'
                            },
                            {
                                label: t('Tasks'),
                                link: ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/tasks',
                                isActive: match.params.subRoute === 'tasks'
                            },
                            {
                                label: t('Documents'),
                                link: ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/documents',
                                isActive: match.params.subRoute === 'documents'
                            },
                            {
                                label: t('Messages'),
                                link: ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/message',
                                isActive: match.params.subRoute === 'message'
                            },                            
                            {
                                label: t('Activity Log'),
                                link: ROUTES.EXT_WORKFLOW_PANEL_BASE + sharedResourceId + '/activity-log',
                                isActive: match.params.subRoute === 'activity-log'
                            }
                        ]}
                    >
                        {
                            match.params.subRoute === 'documents' && <React.Fragment>
                                {
                                    documentCenterArchiveDownloading && <Preloader small={true} paddingTop='0' />
                                }
                                {/* <IconButtonWithLabel
                                    label={t('Download all')}
                                    iconKey='get_app'
                                    onButtonClick={() => onDownloadAttachmentsWFPZipRequest(sharedResourceId, contactPerson)}
                                    disabled={documentCenterArchiveDownloading || !hasAttachments}
                                /> */}
                            </React.Fragment>
                        }
                    </TabsLinks>

                    <Switch>
                        <Route path={ROUTES.EXT_WORKFLOW_PANEL_FORM}>
                            <Form />
                        </Route>
                        <Route path={ROUTES.EXT_WORKFLOW_PANEL_TASKS_PRESELECTED}>
                            <Tasks />
                        </Route>
                        <Route path={ROUTES.EXT_WORKFLOW_PANEL_TASKS}>
                            <Tasks />
                        </Route>
                        <Route path={ROUTES.EXT_WORKFLOW_PANEL_DOCUMENTS}>
                            <Documents />
                        </Route>
                        <Route path={ROUTES.EXT_WORKFLOW_PANEL_MESSAGE_PRESELECTED}>
                            <WorkflowPanelInbox />
                        </Route>
                        <Route path={ROUTES.EXT_WORKFLOW_PANEL_MESSAGE}>
                            <WorkflowPanelInbox />
                        </Route>
                        <Route path={ROUTES.EXT_WORKFLOW_PANEL_ACTIVITY_LOG}>
                            <ActivityLog />
                        </Route>
                    </Switch>

                </Common.VerticalContainer>
            </Common.Main>
            {match.params.subRoute !== 'activity-log' && <Common.Sidebar>
                <Switch>
                    <Route path={ROUTES.EXT_WORKFLOW_PANEL_TASKS_PRESELECTED}>
                        {
                            selectedTaskLoaded && <React.Fragment>
                                {
                                    tasksAttachmentDetails.visible ? <DocumentsSidebar
                                        attachmentDetails={tasksAttachmentDetails}
                                        onClose={onTaskAttachmentDetailsClose}
                                        match={match}
                                    /> : <WidgetArea widgetContext={WIDGET_CONTEXTS.WORKFLOW_PANEL_TASKS_SIDEBAR} />
                                }
                            </React.Fragment> 
                        }
                    </Route>
                    <Route path={ROUTES.EXT_WORKFLOW_PANEL_FORM}>
                        {
                            damageLoaded && <WidgetArea widgetContext={WIDGET_CONTEXTS.WORKFLOW_PANEL_FORM_SIDEBAR} />
                        }
                    </Route>
                    <Route path={ROUTES.EXT_WORKFLOW_PANEL_DOCUMENTS}>
                        {
                            documentCenterAttachmentDetails.visible && <DocumentsSidebar
                                attachmentDetails={documentCenterAttachmentDetails}
                                onClose={onDocumentCenterAttachmentDetailsClose}
                                match={match}
                                isLawfirm={true}
                            />
                        }
                    </Route>

                </Switch>
            </Common.Sidebar>}
        </Common.Container>
    </LayoutWorkflowPanel>


}

export default withSharedResourcePinValidation(SHARED_RESOURCE_TYPE.LAWFIRM)(withRouter(Lawfirm))