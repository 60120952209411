import React from 'react'
import { withTheme } from 'styled-components'
import DocumentsToggle from 'application/components/building_blocks/documents_toggle'
import { SubPageLayout } from 'application/components/pages/_common'
import { withRouter } from 'react-router-dom'
import { FlexBox } from 'application/components/fragments/flex_box'

const DocumentsContent = ({
    theme,
    documents,
    documentsLoaded,
    onAttachmentClick,
    selectedThumbnail,
    fullAttachments,
    categories,
    categoriesLoaded,
    damageId,
    match
}) => {
    const { sharedResourceId } = match.params
    const handleAttachmentClick = (attachment) => {
        onAttachmentClick(
            attachment.fileName, 
            attachment.id, 
            damageId, 
            sharedResourceId, 
            attachment.taskId,
            attachment.isExportedToLawFirm
        )
    }
    return <SubPageLayout paddingRight='16px'>
        <FlexBox flexDirection='column'>
            {categoriesLoaded && documentsLoaded && <React.Fragment>
                {
                    categories.map(c => {
                        return <DocumentsToggle
                            key={c.id}
                            category={c}
                            documents={documents}
                            fullAttachments={fullAttachments}
                            onAttachmentClick={handleAttachmentClick}
                            damageId={damageId}
                            match={match}
                        />
                    })
                }
            </React.Fragment>
            
            
            }
        </FlexBox>
    </SubPageLayout>
}

export default withRouter(withTheme(DocumentsContent))