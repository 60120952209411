import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'
import {ROUTES} from 'application/constants'
import DateComponent from 'application/components/controls/date'
import { getAccountLinkHref } from 'application/common/route_helpers'

const StyledEarlyAccessNote = styled(NavLink)`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 210px;
    height: 40px;
    padding-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
    background: #f2d37e;
    font-size: 12px;
    color: #444;
    line-height: 16px;
    text-decoration: none;
    box-sizing: border-box;
`

export default withRouter(
    ( {match} ) => {

        const { t } = useTranslation()
        const commitSha = process.env.REACT_APP_COMMIT_SHA
        const buildDate = new Date(process.env.REACT_APP_BUILD_DATE)

        return <StyledEarlyAccessNote 
            title={`commit sha: ${commitSha}`} 
            to={
                getAccountLinkHref(
                    match, 
                    ROUTES.CHANGELOG
                )
            }
        >
            {t('Last update')}: <strong><DateComponent date={buildDate} /></strong>
        </StyledEarlyAccessNote>
    }
)
