import React from 'react'
import {
    StyledUserMenu
} from './fragments'
import ContextMenu from 'application/components/controls/context_menu'
import ContextMenuItem from 'application/components/controls/context_menu_item'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ROUTES } from 'application/constants'
import { dataMappers } from 'application/api'
import { nameHelpers } from 'application/common'
import { getAccountLinkHref } from 'application/common/route_helpers'
import { withRouter } from 'react-router-dom'

const UserMenu = (
    {
        onEditUser,
        currentUser,
        accountsTotal,
        accountsFound,
        onLogout,
        onEditNotificationSettings,
        match,
        image = null
    }
) => {
    const { t } = useTranslation()
   
    const handleOnEditUser = (currentUser) => {
        const mappedPayload = dataMappers.importMappers.user.userFormDataMapper(currentUser)
        onEditUser(mappedPayload)
    }

    const handleOnEditNotificationSettings = (currentUser) => {
        onEditNotificationSettings(currentUser)
    }


    const fullName = nameHelpers.getFullName(currentUser)

    return  <StyledUserMenu>
        <ContextMenu 
            iconKey="person"
            height= {'48px'}
            width= {'48px'}
            iconSize = {'24px'}
            isUserMenu={true}
            image={image}
            userName = {fullName}
            top={'21px'}
        >
            <ContextMenuItem
                iconKey='edit'
                onListElementClick={ () => handleOnEditUser(currentUser) }
            >
                {t('Edit')}
            </ContextMenuItem>
            <ContextMenuItem
                iconKey='notifications'
                onListElementClick={ () => handleOnEditNotificationSettings(currentUser) }
            >
                {t('Notification settings')}
            </ContextMenuItem>

            {
                accountsFound && accountsTotal > 1 && <ContextMenuItem
                    iconKey='cached'
                    to={ROUTES.CHOOSE_ACCOUNT}
                    isLinked={true}
                >
                    {t('Switch account')} 
                </ContextMenuItem>  
            }
                   
            <ContextMenuItem
                iconKey='notes'
                to={getAccountLinkHref(match, ROUTES.CHANGELOG)}
                isLinked={true}
            >
                {t('Whats`s new?')} 
            </ContextMenuItem>
            <ContextMenuItem
                iconKey='exit_to_app'
                onListElementClick={() => onLogout()}
            >
                {t('Logout')}
            </ContextMenuItem>
        </ContextMenu>
    </StyledUserMenu>                
}

UserMenu.propTypes = {
    currentUser: PropTypes.object.isRequired, 
    onEditUser: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired
}

export default withRouter(UserMenu)