import { takeLatest, put } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateDamage, populateSharedLawfirm } from 'application/redux/actions/pages/damage_manager'
import { refreshDamageManagerDamageSaga } from '../../post_processors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'


function* damageManagerGetDamageSaga(action) {
    try {
        try {
            yield resourceCall(
                api.gateway.lawyerCommunication.getLawfirm,
                {
                    damageId: action.damageId
                }
            )
            yield put(
                populateSharedLawfirm(true)
            )
        } catch (e) {
            if (e.response.status === 404){
                yield put(populateSharedLawfirm(false))
            }else{
                throw(e)
            }          
        }
        const damageResponse = yield resourceCall(
            api.gateway.damage.getDamage, 
            {
                damageId: action.damageId
            }
        )
        yield put(
            populateDamage(damageResponse)
        )
    } catch (e) {
        if ( e.data.status === 404) {
            yield put(
                populateDamage(null)
            )
        } else {
            yield handleError(e)
        }
    }
}

function* damageManagerUpdateState(action) {
    try {
        yield resourceCall(
            api.gateway.damage.updateDamageState, 
            {
                damageId: action.damageId
            }, 
            {
                id: action.stateId
            }
        )
        yield refreshDamageManagerDamageSaga(action)
    } catch (e) {
        yield handleError(e)
    }
}

export function* damageManagerWatcher() {
    yield takeLatest(
        ACTIONS.DAMAGE_MANAGER_REQUEST_DAMAGE, 
        damageManagerGetDamageSaga
    )
    yield takeLatest(
        ACTIONS.DAMAGE_MANAGER_UPDATE_STATUS, 
        damageManagerUpdateState
    )
}