import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage, WidgetSubHeadline } from 'application/components/widgets/_common'
import ClipboardCopy from 'application/components/controls/clipboard_copy'



const DamageSharetWidget = (
    {
        // widgetKey, 
        payload, 
        loadingFailed
    }
) => {
    const {t} = useTranslation()

    const damageEmailAddress  = payload?.systemEmail
    // const damageEmailAddress = `${accountId}-${match.params.damageId}@${process.env.REACT_APP_SMART_CONNECT_EMAIL_DOMAIN}`

    if(loadingFailed || damageEmailAddress === undefined){
        return <WidgetErrorMessage>
            {t('data could not be loaded')}
        </WidgetErrorMessage>
    }

    return <div>
        <WidgetSubHeadline>
            {t('SmartConnect')}
        </WidgetSubHeadline>
        <ClipboardCopy 
            copyOf={damageEmailAddress} 
        />
    </div>
}

DamageSharetWidget.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export default DamageSharetWidget