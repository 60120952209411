import { api } from 'application/api'
import ACTIONS from 'application/constants'
import { populateThumbnail } from 'application/redux/actions/attachments'
import { thumbnailLoaded } from 'application/redux/selectors'
import { put, select, takeEvery } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

function* fetchThumbnailByIdSaga(action) {
    try {

        const isLoaded = yield select(thumbnailLoaded, action.id)
        
        if(!isLoaded){
            const result = yield resourceCall(
                api.gateway.attachments.getThumbnailDataById, 
                {
                    damageId:  action.metaData.match.params.damageId,
                    attachmentId: action.id
                }
            )
            const blobUrl = window.URL.createObjectURL(result)
            yield put(populateThumbnail(action.id, action.attachmentName, blobUrl))
        }



    } catch (e) {
        if(e.message === '204'){
            yield put(populateThumbnail(action.id, action.attachmentName, null, true))
        }else{
            yield handleError(e, action)
        }
    }
}

export function* fetchThumbnailByIdWatcher() {
    yield takeEvery(ACTIONS.ATTACHMENTS_GET_THUMBNAIL_REQUEST, fetchThumbnailByIdSaga)
}