import styled from 'styled-components'

export const StyledAttachment = styled.div`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    margin-right: 20px;
    padding: 8px 16px 11px 16px;
    background: ${props => props.isActive ? props.theme.color.gray10 : 'transparent'};
    border-radius: 2px;
    position: relative;
`

export const StyledCaption = styled.div`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.smallRegular};
    margin: 0;
    max-width: 100px;
`

export const StyledThumbnail= styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    height: ${props => props.theme.thumbnail.height}px;
    width: ${props => props.theme.thumbnail.width}px;
    &:hover>.overlay{
        display:flex;
    }
`

export const StyledThumbnailImage = styled.img`
    max-height: ${props => props.theme.thumbnail.height-2}px;
    max-width: ${props => props.theme.thumbnail.width-2}px;
    width: auto;
    height: auto;
    border: 1px solid ${props => props.isActive ? props.theme.color.primary : props.theme.color.gray15};
    outline: 2px solid ${props => props.isActive ? props.theme.color.primary : 'transparent'}
`

export const StyledExportMarker = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${props => props.theme.color.primaryHover};
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;

    & i{
        color: white;
        font-size: 16px;
    }
`

export const StyledRemoveMarker = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${props => props.theme.color.red};
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;

    & i{
        color: white;
        font-size: 11px;
    }
`