import React from 'react'
import { HeaderContainer } from './fragments'
import TaskUploaderComponent from 'application/components/building_blocks/task_uploader'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PushBottom16 } from 'application/components/pages/_common'
import { StepsNavigation } from './steps_navigation'
import { Paragraph } from 'application/components/fragments/typography'

export const ExtCreateDamageDocuments = (
    {
        files, 
        setFiles,
        setCurrentStep,
        onSubmit
    }
) => {

    return <HeaderContainer>

        <Paragraph cssAlign='center'>
                Um die Schadenabwicklung zu beschleunigen, sollten Sie ein Foto der Vollmacht sowie des Fahrzeugscheins hinzufügen.
        </Paragraph>

        <FlexBox>
            <TaskUploaderComponent
                title='Fotos'
                description=''
                label='Foto machen'
                labelMore='Weitere Fotos hinzufügen'
                onFileAdded={() => { }}
                files={files}
                setFiles={setFiles}
            />
        </FlexBox>
        <PushBottom16 />
        <StepsNavigation
            onClickPrevious={()=>{
                setCurrentStep(1)
            }}
            onClickNext={
                () => {
                    setCurrentStep(3)
                    onSubmit()
                }
            }
            nextText='Schadenanlage abschließen'
        />   
    </HeaderContainer>
}