import { ACTIONS } from 'application/constants'

export const getCustomersRequest = (
    offset, 
    limit, 
    orderBy,
    direction,
    filterQuery = []
) => ({
    type: ACTIONS.CUSTOMERS_GET_CUSTOMERS_REQUEST,
    offset, 
    limit, 
    orderBy,
    direction,
    filterQuery
})


export const populateCustomersList = (customersList, customersListTotal) => ({
    type: ACTIONS.CUSTOMERS_POPULATE_CUSTOMERS_LIST,
    customersList,
    customersListTotal,
})
