import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { WIDGET_CONTEXTS } from 'application/constants'
import DamageManagerOverview from './component'

const DamageManagerOverviewEffects = (props) => {
    const { onRefreshWidgetContext, match } = props
    const { damageId } = match.params

    useEffect(() => {
        onRefreshWidgetContext(WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW)
    }, [onRefreshWidgetContext, damageId])

    return <DamageManagerOverview {...props} />
}

DamageManagerOverviewEffects.propTypes = {
    onRefreshWidgetContext: PropTypes.func.isRequired,
}

export default withRouter(DamageManagerOverviewEffects)