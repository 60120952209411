import { connect } from 'react-redux'
import {MassOperationEffects} from './effects'
import {moRegister, moClearAllItemsByName, moSetInactiveByName } from 'application/redux/actions/mass_operations'
import { fetchMoByName } from './helper_functions'

const mapStateToProps = (state, ownProps) => {
    const mo = fetchMoByName(
        state,
        ownProps
    )
    return {
        mo
    }
}



const mapDispatchToProps = dispatch => {

    return {
        onRegisterMassOperation: (
            moName, 
            moType
        ) => {
            dispatch(
                moRegister(
                    moName, 
                    moType
                )
            )
        },
        onMoClearAllItemsByName: (moName) => {
            dispatch(
                moClearAllItemsByName(moName)
            )
        },
        onMoSetInactiveByName: (moName) => {
            dispatch(
                moSetInactiveByName(moName)
            )
        }
    }
}

export const MassOperationPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(MassOperationEffects) 

