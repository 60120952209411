import { ROUTES, USER_PERMISSONS } from 'application/constants'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import PropTypes from 'prop-types'
import SideBarIconLink from 'application/components/building_blocks/main_navigation_sidebar/_common/icon_link'
import MenuIcon from 'application/components/building_blocks/main_navigation_sidebar/_common/menu_icon'
import { buildToolTip } from '../../helper_functions'
import { getBranchLinkHref } from 'application/common/route_helpers'

export const MenuButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    &>button {
        border: 1px solid ${props => props.theme.color.white};
        background: ${props => props.theme.color.primary};
    }

    &>button:hover {
        background: ${props => props.theme.color.primaryHover};
        color: ${props => props.theme.color.white};
    }

`


const BranchSpecificAccordionMenu = (
    {
        onOpenChangeBranchDialog, 
        match,
        primaryColor,
        primaryHoverColor,
        currentBranch,
        hasMultipleBranches
    }
) => {
    const {t} = useTranslation()

    if(currentBranch === null){
        return null
    }

    const branchId = currentBranch.id
    const branchName = currentBranch.name

    return  <React.Fragment>
        <SideBarIconLink 
            toolTip={ buildToolTip(branchName, 'Overview', t) }
            primaryColor={primaryColor}
            hoverBackground={primaryHoverColor}
            to={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_DASHBOARD, branchId)}
            iconKey={'widgets'}
            isActive={
                match.path === ROUTES.BRANCH_SPECIFIC_DASHBOARD 
            }
        />
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.DAMAGE.READ]} 
            allNeeded={false}
        >
            <SideBarIconLink 
                toolTip={ buildToolTip(branchName, 'Damages', t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW, branchId)}
                iconKey={'directions_car'}
                isActive={
                    match.path === ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW 
                || match.path === ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES 
                || match.path === ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ARCHIVED_DAMAGES 
                || match.path === ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES
                }
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.TASK.READ]} 
            allNeeded={false}
        >
            <SideBarIconLink 
                toolTip={ buildToolTip(branchName, 'Tasks', t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW, branchId)}
                iconKey={'description'}
                isActive={
                    match.path === ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW 
                || match.path === ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_ALL_TASKS 
                || match.path === ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS
                }
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.MESSAGE.READ]} 
            allNeeded={false}
        >
            <SideBarIconLink 
                toolTip={ buildToolTip(branchName, 'Inbox', t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_INBOX, branchId)}
                iconKey={'mail_outline'}
                isActive={
                    match.path === ROUTES.BRANCH_SPECIFIC_INBOX 
                || match.path === ROUTES.BRANCH_SPECIFIC_INBOX_PRESELECTED
                }
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.CONTROLLING.READ]} 
        >
            <SideBarIconLink 
                toolTip={ buildToolTip(branchName, 'Controlling', t) }
                primaryColor={primaryColor}
                hoverBackground={primaryHoverColor}
                to={getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_CONTROLLING, branchId)}
                iconKey={'bar_chart'}
                isActive={
                    match.path === ROUTES.BRANCH_SPECIFIC_CONTROLLING 
                || match.path === ROUTES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS 
                || match.path === ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES
                }
            />
        </ForCurrentBranchPermissions>
        <SideBarIconLink 
            toolTip={ buildToolTip(branchName, 'Activities', t) }
            primaryColor={primaryColor}
            hoverBackground={primaryHoverColor}
            to={ getBranchLinkHref(match, ROUTES.BRANCH_SPECIFIC_ACTIVITY_LOG, branchId) }
            iconKey={'list'}
            isActive={ match.path === ROUTES.BRANCH_SPECIFIC_ACTIVITY_LOG }
        />
        {
            hasMultipleBranches && <MenuIcon
                toolTip={ t('Change branch') }
                iconKey={'swap_horiz'}
                isClickable={true}
                onClick={onOpenChangeBranchDialog}
                hoverBackground={primaryHoverColor}
            />
        }
    </React.Fragment>
}

BranchSpecificAccordionMenu.propTypes = {
    match: PropTypes.object.isRequired,
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired,
    onOpenChangeBranchDialog: PropTypes.func,
    currentBranch: PropTypes.object,
    hasMultipleBranches: PropTypes.bool
}


export default BranchSpecificAccordionMenu


