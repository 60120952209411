import React, { useState, useEffect } from 'react'
import * as Fragment from './fragments'
import TruncateString from 'react-truncate-string'
import { useTranslation } from 'react-i18next'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import AttachmentViewer from 'application/components/building_blocks/attachments/attachment_viewer'
import CategorySelect from 'application/components/controls/form/category_select'
import { withTheme } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { useStatefulForm } from 'application/components/hooks'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box/pure_component'
import { DIALOG } from 'application/constants'
import { StyledCheckbox, StyledCheckBoxWithLabel, StyledLabel } from 'application/components/controls/form/checkboxes/checkbox_with_label/fragments'
import { ActionButton } from '@ec/ui-controls-react'
import { PushBottom16 } from 'application/components/pages/_common'





const DocumentSidebar = ({
    attachmentDetails,
    onClose,
    theme,
    showGallery,
    downloadAttachment,
    deleteAttachment,
    taskId = null,
    match,
    onChangeCategory,
    showCategorySelect = false,
    onOpenExportToLawfirmConfirmationDialog,
    hasLawfirm = false,
    onMarkExportToLawfirm
}) => {
    
    const { t } = useTranslation()
    const {register, setValue, updateFormState} = useStatefulForm()
    const damageId = attachmentDetails.damageId
    const attachment= attachmentDetails.attachment
    const [isExportedToLawFirm, setIsExportedToLawFirm] = useState(attachment.isExportedToLawFirm)
    const isExternal = match.params.sharedResourceId !== undefined    
    const displayExportToLawfirmConfirmation = hasLawfirm || isExternal

    //Must be updated after closing dialog
    useEffect(()=> {
        setIsExportedToLawFirm(attachment.isExportedToLawFirm)
    }, [attachment.isExportedToLawFirm])


    const handleDownloadAttachment = () => {
        downloadAttachment(
            attachment.id, 
            attachment.fileName, 
            damageId
        )
    }
    const handleDeleteAttachment = () => {
        deleteAttachment(
            attachment.id, 
            attachment.fileName, 
            damageId, 
            taskId, 
            match
        )
    }
    const handleShowGallery = () => {
        showGallery(
            damageId, 
            attachment.id, 
            attachment.fileName, 
            taskId, 
            match
        )
    }
    const handleChangeCategory = (option) => {
        updateFormState()
        onChangeCategory(
            damageId, 
            attachment.id, 
            {
                id: option.value,
                name: option.label
            }
        )
    }



    
    return <Fragment.Wrapper>
        <FlexBox 
            flexDirection='column'>
            <FlexBox 
                cssRules='overflow:hidden;' 
                flexGrow={0}
            >
                <FlexBox>
                    <FlexBox 
                        marginRight='8px' 
                        flexGrow={0} 
                        cssRules='overflow:hidden;'
                    >
                        <Fragment.StyledTitleOnReviewThumbNail>
                            <TruncateString 
                                text={attachment.fileName} 
                                truncateAt={100}
                            />
                        </Fragment.StyledTitleOnReviewThumbNail>
                    </FlexBox>
               
                    <FlexBox 
                        alignItems='flex-end' 
                        justifyContent='flex-end'
                    >
                        <Fragment.StyledHideDisplayOnReviewThumbNail onClick={onClose}>
                            {t('Hide')}
                        </Fragment.StyledHideDisplayOnReviewThumbNail>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <Fragment.BreakLine16 />
            <FlexBox>
                <FlexBox justifyContent='flex-end'>
                    <IconButtonWithLabel 
                        onButtonClick={handleDownloadAttachment} 
                        iconKey='get_app' 
                        iconSize={theme.fontSize.headline3} 
                    />
                    {
                        !isExternal && <IconButtonWithLabel 
                            onButtonClick={handleShowGallery} 
                            iconKey='zoom_out_map' 
                            iconSize={theme.fontSize.headline3} 
                        />
                    }
                    <IconButtonWithLabel 
                        onButtonClick={handleDeleteAttachment} 
                        iconKey='delete' 
                        iconSize={theme.fontSize.headline3} 
                    />
                </FlexBox>
            </FlexBox>
            <Fragment.BreakLine16 />
            {
                showCategorySelect && <FlexBox>
                    <CategorySelect 
                        onChange={handleChangeCategory} 
                        register={register} 
                        setValue={setValue} 
                        initialValue={attachment.category?.id || null}
                    />
                </FlexBox>
            }
            <Fragment.BreakLine16 />
            <Fragment.BreakLine16 />
            {
                !displayExportToLawfirmConfirmation &&  <PureCheckBox 
                    label={t('Make available for legal software')}
                    checked={isExportedToLawFirm}
                    onChange={()=>{
                        onMarkExportToLawfirm(
                            damageId, 
                            attachment.id,  
                            !isExportedToLawFirm
                        )
                    }}
                />            
            }
            {
                displayExportToLawfirmConfirmation && !isExportedToLawFirm && <StyledCheckBoxWithLabel>
                    <StyledCheckbox  
                        checked={false} 
                        isDisabled={false}
                        small={false}
                        cursor='pointer'
                        onClick={
                            ()=>{ 
                                onOpenExportToLawfirmConfirmationDialog(
                                    DIALOG.NAME.EXPORT_TO_LAWFIRM_CONFIRMATION,
                                    true, 
                                    { 
                                        files: [{attachment}],
                                        sendPayloadRequestData: {
                                            dialogName: DIALOG.NAME.EXPORT_TO_LAWFIRM_CONFIRMATION_FROM_DOCUMENT_SIDEBAR,
                                            payload: {
                                                attachment: {
                                                    ...attachmentDetails.attachment,
                                                    isExportedToLawFirm:true
                                                }
                                            },
                                            metaData: {
                                                attachmentId: attachment.id, 
                                                damageId,
                                                taskId: match.params.taskId,
                                                successMsg: 'Exported to lawfirm',
                                                dialogToClose: {
                                                    name: DIALOG.NAME.EXPORT_TO_LAWFIRM_CONFIRMATION
                                                }
                                            }
                                        }
                                    },
                                    'Confirmation needed'             
                                )
                            }
                        }
                    />
                    <StyledLabel fontSize={theme.fontSize.standard}>
                        {t('Make available for legal software')}
                    </StyledLabel>
                </StyledCheckBoxWithLabel>
            } 
            {
                displayExportToLawfirmConfirmation && isExportedToLawFirm && <React.Fragment>
                    <PureCheckBox 
                        label={t('Make available for legal software')}
                        checked={true}
                        onChange={() => {}}
                        disabled={true}
                    />
                    <PushBottom16/>
                    <ActionButton
                        buttonText={t('Make available for legal software again')}
                        onButtonClick={
                            ()=>{
                                onOpenExportToLawfirmConfirmationDialog(
                                    DIALOG.NAME.EXPORT_TO_LAWFIRM_CONFIRMATION,
                                    true, 
                                    { 
                                        files: [{attachment}],
                                        sendPayloadRequestData: {
                                            dialogName: DIALOG.NAME.RE_EXPORT_TO_LAWFIRM_CONFIRMATION_FROM_DOCUMENT_SIDEBAR,
                                            payload: {
                                                attachment: attachmentDetails.attachment
                                            },
                                            metaData: {
                                                attachmentId: attachment.id, 
                                                damageId,
                                                taskId: match.params.taskId,
                                                successMsg: 'Exported to lawfirm',
                                                dialogToClose: {
                                                    name: DIALOG.NAME.EXPORT_TO_LAWFIRM_CONFIRMATION
                                                }
                                            }
                                        }
                                    },
                                    'Make available for legal software again'            
                                )
                            }
                        }
                    />
                </React.Fragment>
            }
            <Fragment.BreakLine16 />
            <FlexBox justifyContent='center'>
                <AttachmentViewer 
                    attachmentId={attachment.id} 
                    attachmentName={attachment.fileName} 
                    damageId={damageId} 
                    taskId={taskId} 
                />
            </FlexBox>

        </FlexBox>
    </Fragment.Wrapper>
}

export default withRouter(withTheme(DocumentSidebar))