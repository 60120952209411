import styled from 'styled-components'

export const StyleButtonToggle = styled.button`
    background: transparent;
    color: ${props => props.theme.color.anthracite};
    cursor: pointer;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    font-size:${props => props.theme.fontSize.standard};
    font-weight:${props => props.theme.fontWeight.standardBold};
    width: 100%;
`

export const StyleDisplayToggle = styled.div`
    padding-left: 32px;
    display: ${props => props.active ? 'block' : 'none'}
`

export const StyledAttachmentsContainer = styled.div`
    background: ${props => props.theme.color.gray5};
    padding: 8px;
    margin-bottom: 16px;
    font-size:${props => props.theme.fontSize.standard};
    color: ${props => props.theme.color.anthracite50};
`

export const StyledToggleText = styled.div`
    padding-left: 16px;
`
export const StyledToggleTextSecondary = styled.span`
    font-weight:${props => props.theme.fontWeight.standardRegular};
    color: ${props => props.theme.color.anthracite50};
`

export const StyledWrapperSubCategory = styled.div`
    padding-left: 16px;
`
