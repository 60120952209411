import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { DialogMainContent } from 'application/components/fragments/dialog'
import TwoColumns from 'application/components/forms/_common'
import { ActionButton } from '@ec/ui-controls-react'
import { FlexBox } from 'application/components/fragments/flex_box'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { DIALOG, IMAGES, INFOBOX } from 'application/constants'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom24, Spacer } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'
import { TextInput, DatePicker, TextArea } from 'application/components/controls/form'
import { useStatefulForm } from 'application/components/hooks'
import { AddressDataPartialForm } from 'application/components/forms/_partial_forms'
import { FormHeadline } from 'application/components/fragments/typography'
import Collapsable from 'application/components/controls/collapsable'
import styled from 'styled-components'
import Image from 'application/components/controls/image'

const StyledLawyer = styled.div`
    width: 200px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid ${props => props.isActive ? props.theme.color.primary : props.theme.color.gray15};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & img{
        height: 90% !important;
    }
    & span{
        margin-top: 20px;
        color: ${props => props.theme.color.anthracite};
    }
`

export const CommissionStepComponent = (
    { 
        //from component
        abort, 
        onDataChange,
        damageId = null,
        dialogName,
        damageType,
        goToNextStep,
        dialog,
        //from container
        onSendCommission
    }
) => {

    const preloadedLawyersList = {
        webakteAccounts: [
            {
                name: 'Dr. Mielco und Kollegen',
                id: '12345',
                logo: IMAGES.LAWYER_1
            },
            {
                name: 'Petralli und Partner',
                id: '12346',
                logo: IMAGES.LAWYER_2
            },
            {
                name: 'Veugt Partner',
                id: '12347',
                logo: IMAGES.LAWYER_3
            },
            {
                name: 'Ossendorf und Coll.',
                id: '12348',
                logo: IMAGES.LAWYER_4
            },
            {
                name: 'Prof. Online und Kollegen',
                id: '12349',
                logo: IMAGES.LAWYER_5
            },
        ]
    }

    const { t } = useTranslation()
    const [commissionExpert, setCommissionExpert] = useState(false)
    const [commissionLawyer, setCommissionLawyer] = useState(false)
    const [selectedLawfirm, setSelectedLawfirm] = useState(null)
    const {
        mustBeEmail,
        notEmpty,
        mustBeGermanPostalCode
    } = validationMethods

    const {
        createdDamage,
        customer,
        currentBranch
    } = dialog.payload



  

    const defaultValues = {
        expert: {
            name: '',
            email: '',
            inspectionSite: {
                ...currentBranch.address
            },
            inspectionDate: null,
            inspectionName: currentBranch.name
        },
        lawyer:{
            id: null
        }
    }

    const { 
        register,
        errors, 
        handleSubmit, 
        getValues, 
        control, 
        updateFormState
    } = useStatefulForm({defaultValues})


    const onSubmitSuccess = () => {
        const payloadToSend = {
            lawfirm: {
                selectedLawfirm,
                commissionLawyer
            },
            expert: {
                commissionExpert,
                selectedExpert: getValues().expert
            },
            customer
        }
        const metaData = {
            createdDamage,
            customer,
            fromDialogName: dialogName
        }
        onSendCommission(
            DIALOG.NAME.CREATE_DAMAGE_QUICK_START_COMMISSION,
            payloadToSend, 
            metaData
        )
    }

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox>
                <InfoBox type={INFOBOX.TYPES.SUCCESS} direction='row' cssMargin='0'>
                    <FontIcon icon='directions_car' />
                    <FontIcon icon='done' />
                    <Spacer basis='10px' />
                    <span>
                        <strong>{t('Damage case has been successfully created."')}</strong>&nbsp;
                        <span>{t('You can send a commission data or click "cancel" to skip')}.</span>
                    </span>
                    <Spacer />
                </InfoBox>
            </FlexBox>
            <PushBottom24 />
            <FlexBox flexDirection='column'>

                <Collapsable
                    headline={t('Commission expert')} 
                    initiallyCollapsed={true}
                    onCollapseChange={collapsed => setCommissionExpert(!collapsed)}
                    cssOnly={false}
                    checkBoxStyle={true}
                >
                    <FlexBox flexDirection='column'>
                        <TwoColumns 
                            columnLeft={
                                <DatePicker
                                    label={t('Inspection date')}
                                    name='expert.inspectionDate'
                                    validate={
                                        {
                                            notEmpty: validationMethods.notEmpty
                                        }
                                    }
                                    error={errors.expert?.inspectionDate}
                                    onDateChange={updateFormState}
                                    control={control}
                                />
                            }
                        />

                        <TwoColumns 
                            columnLeft={
                                <TextInput
                                    label={t('Name')} 
                                    name='expert.name'
                                    register={register}
                                    validate={{notEmpty}}
                                    error={errors.expert?.name}
                                    autoFocus={true}
                                    onChange={onDataChange}
                                />
                            }
                            columnRight={
                                <TextInput
                                    label={t('E-Mail')} 
                                    name='expert.email'
                                    register={register}
                                    validate={{notEmpty, mustBeEmail}}
                                    error={errors.expert?.email}
                                    onChange={onDataChange}
                                />
                            }
                        />

                        <FormHeadline>{t('Inspection site')}</FormHeadline>
                        <TextInput
                            label={t('Name')} 
                            name='expert.inspectionName'
                            register={register}
                            validate={{notEmpty}}
                            error={errors.expert?.inspectionName}
                            onChange={onDataChange}
                        />
                        <AddressDataPartialForm
                            register={register}
                            errors={errors}
                            disabled={false}
                            nameStreet = 'expert.inspectionSite.line1'
                            namePostalCode = 'expert.inspectionSite.postalCode'
                            nameCity = 'expert.inspectionSite.locality'
                            validate={{notEmpty}}
                            validatePostalCode={{notEmpty, mustBeGermanPostalCode}}
                            errorNameStreet={errors.expert?.inspectionSite?.line1}
                            errorNamePostalCode={errors.expert?.inspectionSite?.postalCode}
                            errorNameCity={errors.expert?.inspectionSite?.locality}
                        />
                    </FlexBox>
                </Collapsable>
                
                <Collapsable
                    headline={t('Commission lawyer')} 
                    initiallyCollapsed={true}
                    onCollapseChange={collapsed => setCommissionLawyer(!collapsed)}
                    cssOnly={false}
                    checkBoxStyle={true}
                >
                    <FormHeadline>Kanzlei auswählen </FormHeadline>
                    <FlexBox flexWrap='wrap' flexDirection='row'>
                        {
                            preloadedLawyersList.webakteAccounts.map((lawfirm) => {
                                return <StyledLawyer onClick={() => {setSelectedLawfirm(lawfirm)}} key={lawfirm.id} isActive={selectedLawfirm?.id === lawfirm.id}>
                                    <Image data={lawfirm.logo} />
                                </StyledLawyer>
                            })
                        }
                    </FlexBox>
                    {
                        selectedLawfirm !== null && <React.Fragment>
                            <PushBottom24 />
                            <FormHeadline>Weitere Daten für {selectedLawfirm?.name} </FormHeadline>

                            <TwoColumns 
                                columnLeft={
                                    <TextInput
                                        label='KFZ Kennzeichen Unfallgegner' 
                                        name='opponent.licenseplate'
                                        register={register}
                                        validate={null}
                                        error={errors.opponent?.licenseplate}
                                        onChange={onDataChange}
                                    />
                                }
                                columnRight={
                                    <TextInput
                                        label='Mobilnummer Geschädigter' 
                                        name='phonenumber'
                                        register={register}
                                        validate={null}
                                        error={errors.phonenumber}
                                        onChange={onDataChange}
                                    />
                                }
                            />
                            <TextArea 
                                label='Unfallhergang'
                                name='noteContent'
                                register={register}
                                validate={null}
                                error={errors.noteContent}
                                onChange={onDataChange}
                            />
                        </React.Fragment>
                    }


                </Collapsable>
            </FlexBox>
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={abort} 
            />
            <FlexBox width='8px'/>
            <ActionButton
                buttonText={t('Hand over and finish')} 
                onButtonClick={handleSubmit(() => onSubmitSuccess(false))} 
                disabled={!commissionExpert && !commissionLawyer}
            />
        </StepsLayoutFooter>
    </React.Fragment>
}