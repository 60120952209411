import React from 'react'
import ActionLink from './action_link'
import styled from 'styled-components'

const StyledActionLink = styled.div`
    a {
        background-color: ${props => props.secondary ? 'transparent' : props.theme.color.gray70};
        padding: 0 16px ;
        margin-right: ${props => props.marginRight};
        :hover {
            background: ${props => props.hoverBackground ? props.hoverBackground : props.secondary ? props.theme.color.gray5 : props.theme.color.anthracite};
        }
        
        :active {
            background: ${props => props.hoverBackground ? props.hoverBackground : props.secondary ? props.theme.color.gray10 : props.theme.color.black};
                outline: none;
        }
        
        :focus {
            outline: none;
        }
    }   
    display: flex;
    width:${props => props.strechted ? '100%' : 'auto'};
    text-align: center;
`

const OverridedActionLink = ({
    hoverBackground = '',
    activeBackground = '',
    buttonClassName = '',
    linkText = '',
    strechted = false,
    disabled = false,
    to,
    secondary = false,
    icon = '',
    marginRight = '0'
}) => {
    return <StyledActionLink 
        strechted={strechted}
        marginRight={marginRight} 
        className="action-button" 
        hoverBackground={hoverBackground} 
        activeBackground={activeBackground}
        secondary={secondary}
    >
        <ActionLink
            className={buttonClassName}
            linkText={linkText}
            strechted={strechted}
            disabled={disabled}
            to={to}
            secondary={secondary} 
            icon={icon}
        />
    </StyledActionLink>
}

export default OverridedActionLink