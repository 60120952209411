import React from 'react'
import styled from 'styled-components'

const PercentageDisplay = styled.span`
    color: ${props => props.invertValue ? props.theme.color.anthracite50 : props.theme.color.primary};
    font-size: ${props => props.cssFontSize ? props.cssFontSize : props.theme.fontSize.medium};
`

export const Percentage = ({value1, value2, decimals = 1, cssFontSize, invertValue = true}) => {
    
    if(value1 + value2 === 0){ //prevent divide by zero
        return <React.Fragment />
    }

    const percentage = ((value1 * 100) / (value1 + value2))
    const fixed = Number(invertValue ? 100 - percentage : percentage).toFixed(decimals)
    return <PercentageDisplay invertValue={invertValue} cssFontSize={cssFontSize}>{fixed} %</PercentageDisplay>
}