import { put, select } from 'redux-saga/effects'

import { api } from 'application/api'
import { populateSelectedTask, updateTaskInList } from 'application/redux/actions/pages/damage_manager/tasks'
import { damageManagerSelectedTask, workflowPanelSelectedTask, getSharedResourceId } from 'application/redux/selectors'
import { exists } from 'application/common/data_helpers'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { populateSelectedTaskWorkflowPanel, updateTaskInListWorkflowPanel } from 'application/redux/actions/pages/workflow_panel/tasks'

export function* refreshTaskSaga(action) {
    const {taskId, damageId} = action.metaData
    
    const response = yield resourceCall(
        api.gateway.tasks.getTaskById, 
        {
            damageId,
            taskId
        }
    )



    const sharedResourceId = yield select(getSharedResourceId)
    const selectedTaskSelector = sharedResourceId === null ? damageManagerSelectedTask : workflowPanelSelectedTask
    const selectedTask = yield select(selectedTaskSelector)

    if(sharedResourceId !== null){
        yield put(
            updateTaskInListWorkflowPanel(
                taskId, 
                {
                    ...response,
                    assignee: exists(response.assignee)
                        ? response.assignee
                        : null
                }
            )
        )
    }else{
        yield put(
            updateTaskInList(
                taskId, 
                {
                    ...response,
                    assignee: exists(response.assignee)
                        ? response.assignee
                        : null
                }
            )
        )
    }

    if(selectedTask.id === taskId){
        if(sharedResourceId !== null){
            yield put(populateSelectedTaskWorkflowPanel(response))
        }else{
            yield put(populateSelectedTask(response))
        }
    }
}