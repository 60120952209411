import { connect } from 'react-redux'
import AddGroup from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
    return {
        onSaveGroup: (dialogName, groupData) => dispatch(sendPayloadRequest(dialogName, groupData))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddGroup)