import { connect } from 'react-redux'
import BranchSpecificAccordionMenu from './component'

const mapStateToProps = (state) => {
    return {
        currentBranch: state.auth.currentBranch,
        hasMultipleBranches: state.auth.hasMultipleBranches
    }
}

export default connect(
    mapStateToProps
)(BranchSpecificAccordionMenu)