import React from 'react'
import { ROUTE_PARAMETERS, USER_PERMISSONS } from 'application/constants'
import { SubPageLayout,PushBottom16 } from 'application/components/pages/_common'
import DamageSearchFilter from 'application/components/building_blocks/filters/damage_search_filter'
import UserFilter from 'application/components/building_blocks/filters/user_filter'
import {TableAllDamagesOverview} from 'application/components/tables/damages/table_all_damages'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import { FlexBox } from 'application/components/fragments/flex_box'
import { DamageStateFilterCheckboxGroup } from 'application/components/building_blocks/filters/checkboxes'
import { configuration } from 'application/components/tables/damages/table_all_damages/configuration'


const DamagesOverviewAllDamages = ({
    allDamages,
    onLoadDamagesList,
    updateConstraint
}) => {

    const { 
        damagesList, 
        damagesListLoading, 
        damagesListLoaded, 
        damagesListTotal 
    } = allDamages

    return <SubPageLayout>
        <DamageSearchFilter
            filterKey={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.SEARCH_BY.KEY}
            searchKey={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.SEARCH}
        />
        <ForCurrentBranchPermissions 
            permissions={[USER_PERMISSONS.USER.READ]}
        >
            <UserFilter
                routeParameters={ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.USER}
                filterTitle='Showing damages assigned to'
            />
        </ForCurrentBranchPermissions>
        <FlexBox>
            <FlexBox width='400px'>
                <DamageStateFilterCheckboxGroup
                    pagination = {configuration.pagination}
                    disabled = {damagesListLoading}
                />
            </FlexBox>
        </FlexBox>
        <PushBottom16 />
        <TableAllDamagesOverview
            damagesList={damagesList}
            damagesListLoaded={damagesListLoaded}
            damagesListLoading={damagesListLoading}
            damagesListTotal={damagesListTotal}
            onLoadDamages={onLoadDamagesList}
            updateConstraint={updateConstraint}
        />
    </SubPageLayout>
}

export default DamagesOverviewAllDamages