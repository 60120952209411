import { toggleRoleSidebar } from 'application/redux/actions/pages/branch_management'
import { connect } from 'react-redux'
import {RoleSidebar} from './component'

const mapStateToProps = state => ({
    isVisible: state.pages.branchManagement.roleSidebarVisible,
    roleData: state.common.roles.find(b => b.id === state.pages.branchManagement.roleSidebarDataId)
})


const mapDispatchToProps = dispatch => {
    return {
        onSidebarClose: () => dispatch(toggleRoleSidebar(false))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleSidebar)