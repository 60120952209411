import React from 'react'
import { Table } from 'application/components/tables/_common'
import { configuration } from './configuration'
import { useMassOperationsClose, useTableLoader } from 'application/components/hooks'
import { withMassOperations } from 'application/components/context/mass_operations'
import { withRouter } from 'react-router-dom'

const TableUsers = (
    {
        usersListLoading,
        usersListLoaded,
        usersListTotal,
        usersList,
        moName,
        match,
        closeMoByName,
        onLoadUsersList,
        updateConstraint,
    }
) => {
    useTableLoader(onLoadUsersList, match, configuration, updateConstraint)
    useMassOperationsClose(moName, closeMoByName, usersList)

    return <Table 
        configuration={configuration}
        elementsLoading={usersListLoading}
        elementsLoaded={usersListLoaded}
        elementsTotal={usersListTotal}
        elements={usersList}
        emptyMessage='No users found'
        moName={moName}
    />
}

export default withMassOperations(withRouter(TableUsers))