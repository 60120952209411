import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { ActionButton } from '@ec/ui-controls-react'
import IconButtonWithLabel from 'application/components/controls/icon_button_with_label/component'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { DAMAGE_TYPE, DIALOG} from 'application/constants'
import { useStatefulForm, useComposedFormSubmit } from 'application/components/hooks'
import { PartialForms } from 'application/components/forms'
import { comprehensiveDataStructure, liabilityDataStructure } from 'application/components/forms/_configurations'
import { useState } from 'react'
import { Headline2 } from 'application/components/fragments/typography'
import { PushBottom16 } from 'application/components/pages/_common'


export const EditInsuranceStepComponent = (
    { 
        //from component
        abort, 
        damageId = null,
        damageType,
        //from container
        onSave
    }
) => {
    const { t } = useTranslation()

    const isLiabilityDamage = damageType === DAMAGE_TYPE.LIABILITY 

    //ToDo: test if empty default values are necessary
    const {
        emptyValues,
        mapFormValuesToComprehensivePayload
    } = comprehensiveDataStructure
    const comprehensiveForm = useStatefulForm({emptyValues})
    const defaultLiabilityValues = liabilityDataStructure.emptyValues
    const liabilityForm = useStatefulForm(
        { defaultValues: defaultLiabilityValues }
    )

    const onSubmitSuccess = () => {  
        const payload = isLiabilityDamage
            ? {
                ...mapFormValuesToComprehensivePayload( comprehensiveForm.getValues() ),
                ...liabilityDataStructure.mapFormValuesToLiabilityPayload( liabilityForm.getValues() )
            }
            : {
                ...mapFormValuesToComprehensivePayload( comprehensiveForm.getValues() )
            } 

        const metaData = {damageId}

        onSave(
            DIALOG.NAME.CREATE_DAMAGE_UPDATE_INSURANCE_DATA,
            payload,
            metaData
        )
    } 

    const [insuranceSelectionFromList,setInsuranceSelectionFromList] = useState(true)
    const [liabilityInsuranceSelectionFromList,setLiabilityInsuranceSelectionFromList] = useState(true)

    const {
        handleComposedSubmit
    } = useComposedFormSubmit(onSubmitSuccess, liabilityForm, comprehensiveForm)

    return <React.Fragment>
        <DialogMainContent>
            <Headline2>{t('Information comprehensive (vehicle keeper)')}</Headline2>
            <PushBottom16 />
            <PartialForms.BasicDamageComprehensiveInsuranceDataForm
                setInsuranceSelectionFromList={setInsuranceSelectionFromList}
                insuranceSelectionFromList={insuranceSelectionFromList}
                stateFulFormHook={comprehensiveForm}
                resetForm={comprehensiveForm.reset}
            />
            {
                isLiabilityDamage && <React.Fragment>
                    <PushBottom16 />
                    <Headline2>{t('Information liability (opponent)')}</Headline2>
                    <PushBottom16 />
                    <PartialForms.LiabilitaInsurancePartialForm
                        stateFulFormHook={liabilityForm}
                        setInsuranceSelectionFromList={setLiabilityInsuranceSelectionFromList}
                        insuranceSelectionFromList={liabilityInsuranceSelectionFromList}
                        formTitle=''
                    />
                </React.Fragment>
            }
        </DialogMainContent>
        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='not_interested' 
                label={t('Cancel')} 
                onButtonClick={abort}
            />
            <ActionButton
                buttonText={t('Save insurance data')} 
                onButtonClick={handleComposedSubmit} 
            />     
        </StepsLayoutFooter>
    </React.Fragment>
}