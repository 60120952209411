import { connect } from 'react-redux'

import { ControllingVacantPositionsDamagesTable } from './component'
import { refreshWidgetByKey } from 'application/redux/actions/widgets'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (key) => dispatch(refreshWidgetByKey(key)),
        onOpenDamageInvoicesDialog: (damageId, licensePlate) => dispatch(
            openDialogByName(
                DIALOG.NAME.DAMAGE_INVOICES, 
                false, 
                {
                    damageId, 
                    licensePlate, 
                    initiallyCollapsed: true
                }, 
                'Invoices'
            )
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ControllingVacantPositionsDamagesTable)