import { connect } from 'react-redux'
import AccordionMenuEffects from './effects'
import { closeAccordions, openOnlyCurrentAccordeon, registerAccordeonMenuState} from 'application/redux/actions/main_navigation_sidebar'
import { isDefined } from 'application/common/data_helpers'

const mapStateToProps = (state, ownprops) => {
    return {
        accordionMenuState: state.mainNavigationSidebar.registeredAccordionMenus.find(
            (accordionMenu) => isDefined(accordionMenu) 
            && isDefined(ownprops.menuName) 
            && accordionMenu.name === ownprops.menuName
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onOpenOnlyCurrentAccordeon: (name) => { 
            dispatch(
                openOnlyCurrentAccordeon(name)
            )
        },

        onCloseAccordions: () => { 
            dispatch(
                closeAccordions()
            )
        },

        onRegisterAccordeonMenuState: (
            name,
            accordionOpen
        ) => {
            dispatch(
                registerAccordeonMenuState(
                    name,
                    accordionOpen
                )
            )
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccordionMenuEffects)