import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, deleteQuery } from 'application/components/hooks/use_query'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import InfoBox from 'application/components/controls/info_box'
import IconButton from 'application/components/controls/icon_button'
import FullName from 'application/components/controls/full_name/component'

const DamageCustomerIdFilter = (
    { 
        routeParameters
    }
) => {
    const query = useQuery()
    const location = useLocation()
    const history = useHistory()
    const { t } = useTranslation()

    const customer = {
        firstName: query.getStringParam(routeParameters.FIRST_NAME),
        lastName: query.getStringParam(routeParameters.LAST_NAME),
    }
    const filterKey = routeParameters.ID
    const filterValue = query.getStringParam(filterKey)

    const handleResetDamageCustomerIdFilter = () => {
        const paramKeys = [
            filterKey,
            routeParameters.FIRST_NAME,
            routeParameters.LAST_NAME
        ]
        deleteQuery(
            history, 
            location, 
            location.pathname, 
            paramKeys, 
            true
        )
    }

    if(filterValue === ''){
        return null
    }

    return <React.Fragment>
        <InfoBox direction='row' cssMargin='0'>
            <span>{t('Damages for customer')}:</span>
            <strong>
                <FullName person={customer} />
            </strong>
            <Spacer />
            <IconButton 
                iconKey='close' 
                width='12px' 
                height='16px' 
                onButtonClick={handleResetDamageCustomerIdFilter} 
            />
        </InfoBox>
        <PushBottom16/>
    </React.Fragment>
}

export default DamageCustomerIdFilter