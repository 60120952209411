import React from 'react'
import PropTypes from 'prop-types'
import TruncateString from 'react-truncate-string'
import { FontIcon, IconButton } from '@ec/ui-controls-react'
import Preloader from 'application/components/controls/themed_preloader'
import FallbackThumbnail from 'application/components/controls/fallback_thumbnail'
import * as Fragments from './fragments'
import { useTranslation } from 'react-i18next'



const AttachmentThumbnailComponent = ({ 
    attachment, 
    onAttachmentClick, 
    showCaption = true,
    isActive = false,
    removeFile = () => {},
    removableFiles = false
}) => {
    const {t} = useTranslation()
    return (
        <Fragments.StyledAttachment 
            onClick={
                onAttachmentClick !== undefined 
                    ? () => {  onAttachmentClick(attachment) } 
                    : undefined
            } 
            isActive={isActive} 
            className='jest-attachment-thumbnail'
        >
            <Fragments.StyledThumbnail>
                {
                    attachment.hasThumbnail 
                    && !attachment.thumbnailLaoadingFailed 
                        ? attachment.thumbnailIsLoading 
                            ? <Preloader />
                            : <Fragments.StyledThumbnailImage 
                                src={attachment.thumbnail} 
                                alt={attachment.fileName} 
                                isActive={isActive}
                            />
                        : <FallbackThumbnail 
                            fileExtension={attachment.fileExtension} 
                            isActive={isActive} 
                        />
                }
                
            </Fragments.StyledThumbnail>
            {showCaption && <Fragments.StyledCaption>
                <TruncateString 
                    text={attachment.fileName} 
                    truncateAt={40}
                />
            </Fragments.StyledCaption>}
            {
                attachment.isExportedToLawFirm 
                &&  <Fragments.StyledExportMarker>
                    <FontIcon
                        icon='sync'
                        title={t('Available for legal software')}
                    />
                </Fragments.StyledExportMarker>
            }
            {
                !attachment.isExportedToLawFirm 
                && removableFiles
                && <Fragments.StyledRemoveMarker>
                    <IconButton
                        iconKey='delete'
                        title={t('Remove file')}
                        onButtonClick={() => {
                            removeFile(attachment.fileName)
                        }}
                    />
                </Fragments.StyledRemoveMarker>
            }
        </Fragments.StyledAttachment>
    )

}

AttachmentThumbnailComponent.propTypes = {
    attachment: PropTypes.object,
    onAttachmentClick: PropTypes.func, 
    showCaption: PropTypes.bool,
    isActive: PropTypes.bool
}

export default AttachmentThumbnailComponent