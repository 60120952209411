import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

const mapStateToProps = state => ({
    branches: state.common.allBranches,
    accessibleBranchesLoaded: state.common.accessibleBranchesLoaded,
    accessibleBranchesLoading: state.common.accessibleBranchesLoading
})

const withBranchesComponent = Component => ({ ...props }) => {
    return <Component {...props} />
}

export const withBranches = compose(
    connect(
        mapStateToProps
    ),
    withBranchesComponent
)