import { ACTIONS } from 'application/constants'
import defaultStore from '../default_store'

export default (state = defaultStore.attachments, action) => {
    switch (action.type) {
    case ACTIONS.ATTACHMENTS_GET_THUMBNAIL_REQUEST: {
        return {
            ...state,
            thumbnails: state.thumbnails.filter(t => t.id === action.id).length > 0
                ? state.thumbnails
                : [
                    ...state.thumbnails,
                    {
                        id: action.id,
                        isLoaded: false,
                        loadingFailed: false,
                        name: action.attachmentName,
                        ...action.metaData
                    }
                ]
        }
    }
    case ACTIONS.ATTACHMENTS_GET_FULL_ATTACHMENT_REQUEST: {
        return {
            ...state,
            fullAttachments: state.fullAttachments.filter(t => t.id === action.id).length > 0
                ? state.fullAttachments
                : [
                    ...state.fullAttachments,
                    {
                        id: action.id,
                        isLoaded: false,
                        name: action.attachmentName,
                        ...action.metaData
                    }
                ]
        }
    }
    case ACTIONS.ATTACHMENTS_GET_META_REQUEST: {
        return {
            ...state,
            meta: state.meta.filter(m => m.id === action.id).length > 0
                ? state.meta
                : [
                    ...state.meta,
                    {
                        id: action.id,
                        isLoaded: false,
                        name: action.attachmentName,
                        ...action.metaData
                    }
                ]
        }
    }
    case ACTIONS.ATTACHMENTS_POPULATE_THUMBNAIL: {
        return {
            ...state,
            thumbnails: state.thumbnails.map((t, i) => (t.id === action.id ? {
                ...t,
                data: action.data,
                isLoaded: true,
                loadingFailed: action.loadingFailed
            } : t))
        }
    }

    case ACTIONS.ATTACHMENTS_POPULATE_FULL_ATTACHMENT: {
        return {
            ...state,
            fullAttachments: state.fullAttachments.map((t, i) => (t.id === action.id ? {
                ...t,
                data: action.data,
                isLoaded: true
            } : t))
        }
    }
    case ACTIONS.ATTACHMENTS_POPULATE_META: {
        return {
            ...state,
            meta: action.isLoaded && state.meta.filter(m => m.id === action.id).length < 1 ? [
                ...state.meta,
                {
                    ...action.meta,
                    isLoaded: true
                }
            ] : state.meta.map((m, i) => (m.id === action.id ? {
                ...m,
                ...action.meta,
                isLoaded: true
            } : m))
        }
    }
    case ACTIONS.ATTACHMENTS_DELETE_ATTACHMENT: {
        return {
            ...state,
            thumbnails: state.thumbnails.filter(t => t.id !== action.attachmentId),
            fullAttachments: state.fullAttachments.filter(t => t.id !== action.attachmentId),
            meta: state.meta.filter(t => t.id !== action.attachmentId),
        }
    }
    default:
        return state
    }
}