import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { TextInput } from 'application/components/controls/form'
import { useStatefulForm } from 'application/components/hooks'
import { VehicleDataPartialForm } from '../_partial_forms'

const DamageVehicleForm = ({formData = {}, damageId, onSave}) => {
    const {t} = useTranslation()
    
    const defaultValues = {
        licensePlate: '',
        chassisNumber: '', 
        make: '',
        model: '',
        ...formData
    }
    const {register, errors, handleSubmit, getValues, formStateValues, updateFormState} = useStatefulForm({ defaultValues })

    const onSubmitSuccess = () => { 
        onSave(damageId, {
            ...formStateValues, //include all values
            ...getValues()
        })
    }


    return <React.Fragment>

        <VehicleDataPartialForm 
            register={register}
            errors={errors}
            onChange={updateFormState}
        />
        <TextInput 
            label={t('Chassis number')}
            name='chassisNumber'
            register={register}
            validate={null}
            error={errors.chassisNumber}
            onChange={updateFormState}
        />
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save vehicle data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </React.Fragment>
}

export { DamageVehicleForm }