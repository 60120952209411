import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FontIcon } from '@ec/ui-controls-react'
import DamageTypeName from 'application/components/name_mappings/damage_type_name'
import { 
    WidgetHeadline, 
    WidgetErrorMessage, 
    WidgetCardLayout, 
    WidgetCardLayoutLeft, 
    WidgetCardLayoutRight,
    WidgetCardLayoutIcon,
    WidgetText
} from 'application/components/widgets/_common'
import DateComponent from 'application/components/controls/date'
import {ClaimNumber} from 'application/components/controls/claim_number'
import { DAMAGE_TYPE } from 'application/constants'



const DamageReport = (
    {
        loadingFailed, 
        damage, 
        payload
    }
) => {
    const {t} = useTranslation()
    if(loadingFailed){
        return <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
    }

    const claimNumber = damage.type === DAMAGE_TYPE.COMPREHENSIVE 
        ? damage?.comprehensiveData?.claim?.number
        : damage?.liabilityData?.claim?.number

    const claimNumberLabel = damage.type === DAMAGE_TYPE.LIABILITY 
        ? 'Damage number liability'
        : 'Claim number'

    return <WidgetCardLayout>
        <WidgetCardLayoutLeft>
            <WidgetCardLayoutIcon>
                <FontIcon icon='local_car_wash'/>
            </WidgetCardLayoutIcon>
        </WidgetCardLayoutLeft>
        <WidgetCardLayoutRight>
            <WidgetHeadline>{t('Damage report')}</WidgetHeadline>
            <br />
            <WidgetText><strong>
                {t(claimNumberLabel)}</strong>: <ClaimNumber number={claimNumber}  />
            </WidgetText>
            <br />
            <WidgetText>
                <strong>{t('Damage type')}</strong>: <DamageTypeName damageType={damage.type} />
                <br />
                <strong>{t('Date')}</strong>: {damage.incidentDate !== undefined && <DateComponent date={damage.incidentDate} />}
            </WidgetText>
            <br/>
            <WidgetText><strong>
                {t('Description')}</strong>: {damage.description}
            </WidgetText>
        </WidgetCardLayoutRight>
    </WidgetCardLayout>
}

DamageReport.propTypes = {
    loadingFailed: PropTypes.bool.isRequired,
    damage: PropTypes.object.isRequired
}

export { DamageReport }