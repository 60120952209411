import { connect } from 'react-redux'

import DamageAKInvoices from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

const mapDispatchToProps = dispatch => {
    return {
        addDocuments: (parentId, invoiceId, payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.ADD_DOCUMENTS_TO_INVOICE, 
                true, 
                { 
                    parentId, 
                    damageId: parentId,
                    invoiceId,
                    ...payload 
                }, 
                'Add documents to invoice'
            )
        ),
        addPayment: (parentId, payload)  => dispatch(
            openDialogByName(
                DIALOG.NAME.CREATE_PAYMENT_LAWYER_COMMUNICATION, 
                true, 
                {
                    parentId,
                    ...payload
                }
                , 
                'Add payment'
            )
        ),
        cancelPayment: (parentId, payload)  => dispatch(
            openDialogByName(
                DIALOG.NAME.CANCEL_PAYMENT_LAWYER_COMMUNICATION, 
                true, 
                {
                    parentId, 
                    ...payload
                }, 
                'Cancel payment'
            )
        ),
        deleteInvoice: (parentId, payload) => dispatch(
            openDialogByName(
                DIALOG.NAME.DELETE_INVOICE_LAWYER_COMMUNICATION, 
                true, 
                { 
                    parentId, 
                    ...payload 
                }, 
                'Delete payment'
            )
        ),

    }
}

export default connect(
    null,
    mapDispatchToProps
)(DamageAKInvoices)
