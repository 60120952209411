import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Doughnut } from 'react-chartjs-2'
import { withTheme } from 'styled-components'
import * as Legend from 'application/components/charts/_common/legend'
import { Percentage } from 'application/components/charts/_common/percentage'

const Outer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const Inner = styled.div`
    position: absolute;
    width: 100%;
    height: 160px;
    top: 40px;
    display: flex;
    align-items: ${props => props.cssAlign ? props.cssAlign : 'center'};
    justify-content: center;
`

const VacantPostionsChart = ({unpaidTotal, paidTotal, theme}) => {
    const {t} = useTranslation()

    const data = {
        datasets: [{
            data: [unpaidTotal, paidTotal],
            backgroundColor: [
                theme.color.gray20,
                theme.color.primary
            ],
            hoverBackgroundColor: [
                theme.color.gray20,
                theme.color.primary
            ]
        }]
    }

    const options = {
        cutoutPercentage: 65,
        responsive: false,
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    }

    return <Outer>
        <Legend.LegendContainer>
            <Legend.LegendIndicator 
                cssBackground={theme.color.primary} />
            <Legend.LegendLabel>
                {t('Paid off')}
            </Legend.LegendLabel>
            <Legend.LegendIndicator 
                cssBackground={theme.color.gray20} />
            <Legend.LegendLabel>
                {t('Opened')}
            </Legend.LegendLabel>
        </Legend.LegendContainer>
        <Inner cssAlign='flex-start'>
            <Doughnut data={data} options={options} />
        </Inner>
        <Inner>
            <Percentage 
                value1={paidTotal} 
                value2={unpaidTotal} 
            />
        </Inner>
    </Outer>
}

export default withTheme(VacantPostionsChart)