


/**
 * maps statusIconName to google icon name
 * @param {string} statusIconName 
 * @returns {string} googleIconName
 */
export const iconMapper = (statusIconName) => {
    switch (statusIconName) {
    case 'In Planung':
    case 'Warten auf Gutachten':
        return 'assignment' 
    case 'In Datenaufnahme':
        return 'edit'
    case 'In Reparatur':
        return 'build'
    case 'In Rechnungsstellung':
        return 'euro_symbol'    
    case 'Fertig zur Übergabe':
        return 'vpn_key'
    case 'Abgeschlossen': 
        return 'check'
    default:
        return 'star'
    }
}