
//image sources for static images
const ICONS_BASE_FOLDER = '/images'

export const IMAGES = {
    LOGO_36: {
        src: ICONS_BASE_FOLDER + '/logos/esy_360.svg',
        alt: 'e.sy 360',
        height: '36px'
    },
    LOGO_36_SECONDARY: {
        src: ICONS_BASE_FOLDER + '/logos/esy_360_secondary.svg',
        alt: 'e.sy 360',
        height: '36px'
    },
    LOGO_72: {
        src: ICONS_BASE_FOLDER + '/logos/esy_360.svg',
        alt: 'e.sy 360',
        height: '72px'
    },
    LOGO_192: {
        src: ICONS_BASE_FOLDER + '/logos/esy_360.svg',
        alt: 'e.sy 360',
        height: '192px'
    },
    LOGO_512: {
        src: ICONS_BASE_FOLDER + '/logos/esy_360.svg',
        alt: 'e.sy 360',
        height: '512px'
    },
    DASHBOARD_CONFIGURATION_DAMAGES: {
        src: ICONS_BASE_FOLDER + '/config_damages.png',
        alt: 'damages',
        height: '100px'
    },
    DASHBOARD_CONFIGURATION_CONTROLLING: {
        src: ICONS_BASE_FOLDER + '/config_controlling.png',
        alt: 'controlling',
        height: '100px'
    },
    DASHBOARD_CONFIGURATION_CUSTOMERS: {
        src: ICONS_BASE_FOLDER + '/config_customers.png',
        alt: 'customers',
        height: '100px'
    },
    ACCOUNT_ZYRULL: {
        src: ICONS_BASE_FOLDER + '/customer_logos/logo_zyrull_360.jpg',
        alt: 'Autohaus Zyrull',
        height: '32px'
    },
    ACCOUNT_EGGER: {
        src: ICONS_BASE_FOLDER + '/customer_logos/ah_egger.png',
        alt: 'Autohaus Egger',
        height: '32px'
    },
    ACCOUNT_NAHETAL: {
        src: ICONS_BASE_FOLDER + '/customer_logos/ah_nahetal.png',
        alt: 'Autohaus Nahetal',
        height: '32px'
    },
    ACCOUNT_KOENIG: {
        src: ICONS_BASE_FOLDER + '/customer_logos/ah_koenig.png',
        alt: 'Autohaus König',
        height: '32px'
    },
    USER_KE: {
        src: ICONS_BASE_FOLDER + '/user_image_ke.png',
        alt: 'Karsten Egger',
        height: '32px'
    },
    DEKRA_LOGO: {
        src: ICONS_BASE_FOLDER + '/DEKRA.svg',
        alt: 'DEKRA',
        height: '32px'
    },
    LAWYER_1: {
        src: ICONS_BASE_FOLDER + '/lawyer_1.png',
        alt: 'lawyer logo',
        height: '80px'
    },
    LAWYER_2: {
        src: ICONS_BASE_FOLDER + '/lawyer_2.png',
        alt: 'lawyer logo',
        height: '80px'
    },
    LAWYER_3: {
        src: ICONS_BASE_FOLDER + '/lawyer_3.png',
        alt: 'lawyer logo',
        height: '80px'
    },  
    LAWYER_4: {
        src: ICONS_BASE_FOLDER + '/lawyer_4.png',
        alt: 'lawyer logo',
        height: '80px'
    },  
    LAWYER_5: {
        src: ICONS_BASE_FOLDER + '/lawyer_5.png',
        alt: 'lawyer logo',
        height: '80px'
    },
    LAWYER_6: {
        src: ICONS_BASE_FOLDER + '/lawyer_6.png',
        alt: 'lawyer logo',
        height: '80px'
    },  
    
}