import { takeLeading, put } from 'redux-saga/effects'
import { api, dataMappers } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { createDamageSuccess, sendDocumentsSuccess } from 'application/redux/actions/ext_create_damage'

function* sendCreateDamageExternalSaga(action) {
    try {

        const usersResponse = yield resourceCall(
            api.gateway.users.getUsersList, 
            {}
        )

        const payload = dataMappers.exportMappers.damage.createDamageDataMapper(
            action.payload, 
            action.payload.customerId,
            usersResponse.users[0].id
        )

        const { id } = yield resourceCall(
            api.gateway.damage.createDamage,
            {
                branchId: action.payload.branchId
            },
            payload
        )

        yield put(createDamageSuccess(id))
    } catch (e) {
        yield handleError(e)
    }
}

function* sendCreateDamageDocumentsExternalSaga(action) {
    try {

        for(const file of action.files){
            yield resourceCall(
                api.gateway.attachments.postAttachment,
                {
                    damageId: action.damageId,
                    categoryId: action.categoryId
                },
                {
                    file: file.metaData
                }
            )
        }

        yield put(sendDocumentsSuccess())
    } catch (e) {
        yield handleError(e)
    }
}

export function* sendCreateDamageExternalWatcher() {
    yield takeLeading(ACTIONS.EXT_CREATE_DAMAGE_REQUEST, sendCreateDamageExternalSaga)
    yield takeLeading(ACTIONS.EXT_SEND_DOCUMENTS_REQUEST, sendCreateDamageDocumentsExternalSaga)
}