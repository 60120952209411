import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@ec/ui-controls-react'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { useStatefulForm } from 'application/components/hooks'
import { VehicleDataPartialForm } from '../_partial_forms'

const DamageOpponentVehicleForm = ({formData = {}, damageId, onSave}) => {
    const {t} = useTranslation()
    
    const defaultValues = {
        licensePlate: formData?.vehicle?.licensePlate || null,
        make: formData?.vehicle?.make || null,
        model: formData?.vehicle?.model || null
    }
    
    const {
        register, 
        errors, 
        handleSubmit, 
        getValues,
        formStateValues,
        updateFormState
    } = useStatefulForm({ defaultValues })

    const onSubmitSuccess = () => { 
        onSave(damageId, {
            ...formStateValues, //include all values
            ...getValues()
        })
    }

    return <FlexBox flexDirection='column'>

        <VehicleDataPartialForm 
            register={register}
            errors={errors}
            onChange={updateFormState}
        />

        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save opponent vehicle data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </FlexBox>
}

export { DamageOpponentVehicleForm }